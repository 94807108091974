import PropTypes from 'prop-types';
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import numeral from 'numeral';

// MUI
import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// MUI ICONS
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';

// OURS
import { API_HOST, genericPost } from 'iris-api'; // eslint-disable-line import/no-unresolved
import { TOP, LEFT_PANEL_WIDTH } from 'iris-config'; // eslint-disable-line import/no-unresolved
import { toUTCString } from 'iris-util'; // eslint-disable-line import/no-unresolved
import { Waiting } from '@premisedata/iris-components';
import VirtualizedList from '../VirtualizedList';
import WifiPaper from './components/WifiPaper';
import FilterPanel from './FilterPanel';

// CONSTANTS
const ROWS_PER_FETCH = 400;
const ROW_HEIGHT = 90;

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    left: 0,
    top: TOP,
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-start',
    height: `calc(100% - ${TOP}px)`,
    width: LEFT_PANEL_WIDTH
  },
  top: {
    position: 'relative',
    flex: '1 1 auto',
    width: '100%',
    zIndex: 1,
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)'
  },
  scroll: {
    position: 'relative',
    height: '100%',
    width: '100%'
  },

  normal: {
    border: `1px solid ${alpha(theme.palette.secondary.dark, 0.0)}`
  },
  row: {
    padding: theme.spacing(1),
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.dark, 0.1),
      border: `1px solid ${alpha(theme.palette.secondary.dark, 1.0)}`
    }
  },
  active: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.4),
    color: theme.palette.getContrastText(alpha(theme.palette.secondary.main, 0.4)),
    border: `1px solid ${theme.palette.secondary.main}`
  },
  inlineIcon: {
    width: '1rem',
    height: '1rem',
    fontSize: '1rem',
    marginRight: theme.spacing(0.5),
    verticalAlign: 'middle',
    display: 'inline-flex',
    paddingBottom: 2
  }
}));

const ResultPanel = ({
  id,
  dataKey,
  onSelect,
  onHover,
  selectedDoc,
  dataType,
  renderBothDataTypes,
  onRenderBothDataTypesChanged,
  onDataTypeChanged,
  updateRadioDataFilters,
  resetRadioDataFilters,
  radioDataFilters
}) => {
  // MUI
  const theme = useTheme();
  const classes = useStyles();

  // REF
  const autoSizerRef = useRef();
  const rowCount = useRef(0);
  const data = useRef();

  // REDUX
  const mapBounds = useSelector((state) => state.app.mapBounds);
  const mapZoom = useSelector((state) => state.app.mapZoom);

  // STATE
  const [loading, setLoading] = useState(false);

  const rowRenderer = useCallback(
    ({ key, index, style }) => {
      const d = data.current[index];
      if (!d) {
        return (
          <Paper key={key} style={{ ...style, /* give some padding */ height: ROW_HEIGHT - theme.spacing(1) }} className={clsx(classes.row, classes.normal)}>
            <Waiting />
          </Paper>
        );
      }
      const active = selectedDoc && d._id === selectedDoc._id;
      if (d.network_type)
        return (
          <Paper
            key={key}
            style={{ ...style, /* give some padding */ height: ROW_HEIGHT - theme.spacing(1) }}
            className={clsx({ [classes.row]: true, [classes.normal]: !active, [classes.active]: active })}
            onMouseOver={() => {
              if (onHover) onHover(d);
            }}
            onMouseOut={() => {
              if (onHover) onHover();
            }}
            onClick={() => {
              onSelect(d._id);
            }}
          >
            <Typography variant="body1" style={{ fontStyle: d.network_name ? 'normal' : 'italic' }} noWrap={true}>
              <SignalCellularAltIcon className={classes.inlineIcon} />
              {d.network_name ? `${d.network_name} - ${d.network_type}` : `Unknown - ${d.network_type}`}
            </Typography>
            <Typography variant="caption" display="block" noWrap={true}>
              {d.source_id}
            </Typography>
            <Typography variant="caption" display="block" noWrap={true}>{`${toUTCString(d.event_time_first_seen)} \u2194 ${toUTCString(d.event_time_last_seen)} (${numeral(
              d.n_observations
            ).format('0,0')})`}</Typography>
          </Paper>
        );

      return (
        <WifiPaper wifi={d} active={active} key={key} style={{ ...style, /* give some padding */ height: ROW_HEIGHT - theme.spacing(1) }} onHover={onHover} onSelect={onSelect} />
      );
    },
    [classes.active, classes.inlineIcon, classes.normal, classes.row, onHover, onSelect, selectedDoc, theme]
  );

  const loadMoreRows = useCallback(
    ({ startIndex, stopIndex }) => {
      startIndex = startIndex ?? 0;
      stopIndex = stopIndex ?? ROWS_PER_FETCH;

      if (!mapBounds) return Promise.reject(new Error('no mapBounds available'));

      // resize array so everything fits:
      while (stopIndex > data.current.length) data.current.push(null);

      return new Promise((resolve, reject) => {
        const url = `${API_HOST}/radio/v0/radio/data/within/${[mapBounds.minx, mapBounds.miny, mapBounds.maxx, mapBounds.maxy, mapZoom, startIndex, stopIndex].join('/')}`;
        const body = {
          ...radioDataFilters,
          last_seen: null,
          types: dataType
        };
        if (body.countries?.length) {
          body.countries = body.countries.map((d) => d.hasc);
        }
        if (body.manufacturer_countries?.length) {
          body.manufacturer_countries = body.manufacturer_countries.map((d) => d.id);
        }
        if (radioDataFilters.last_seen?.strStart) {
          body.last_seen = body.last_seen ?? {};
          body.last_seen.start = radioDataFilters.last_seen.strStart;
        }
        if (radioDataFilters.last_seen?.strEnd) {
          body.last_seen = body.last_seen ?? {};
          body.last_seen.end = radioDataFilters.last_seen.strEnd;
        }
        genericPost(url, body, null, (e, r) => {
          if (e) return reject(e);

          const { hits: rows, total } = r;
          for (let i = 0, j = startIndex; i < rows.length; i++, j++) {
            data.current[j] = rows[i];
          }
          if (rowCount.current === 0 && rows.length > 0) {
            rowCount.current = +total;
          }
          resolve();
        });
      });
    },
    [mapBounds, mapZoom, radioDataFilters, dataType]
  );

  const isRowLoaded = useCallback(({ index }) => {
    return !!data.current[index];
  }, []);

  useEffect(() => {
    autoSizerRef.current.forceUpdateGrid();
  }, [selectedDoc]);

  useEffect(() => {
    data.current = [];
    rowCount.current = 0;

    if (!mapBounds) return;

    setLoading(true);
    loadMoreRows({ startIndex: 0, stopIndex: ROWS_PER_FETCH })
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        data.current = [];
        rowCount.current = 0;

        if (e !== 404) console.error('ResultPanel: useEffect(): failed to loadMoreRows()', e);
      });
  }, [mapBounds, dataKey, loadMoreRows]);

  return (
    <Box className={classes.container} id={id}>
      <Box className={classes.top}>
        <FilterPanel
          dataType={dataType}
          onDataTypeChanged={onDataTypeChanged}
          radioDataFilters={radioDataFilters}
          updateRadioDataFilters={updateRadioDataFilters}
          resetRadioDataFilters={resetRadioDataFilters}
          renderBothDataTypes={renderBothDataTypes}
          onRenderBothDataTypesChanged={onRenderBothDataTypesChanged}
        />
      </Box>
      <Box className={classes.scroll}>
        <VirtualizedList
          loading={loading}
          // key={this.state.dataKey}
          ref={autoSizerRef}
          rowRenderer={rowRenderer}
          loadMoreRows={loadMoreRows}
          isRowLoaded={isRowLoaded}
          rowCount={rowCount.current ?? 0}
          rowHeight={ROW_HEIGHT}
          minimumBatchSize={ROWS_PER_FETCH}
        />
      </Box>
    </Box>
  );
};

ResultPanel.propTypes = {
  // data
  dataKey: PropTypes.number,
  selectedDoc: PropTypes.object,
  radioDataFilters: PropTypes.object,

  blur: PropTypes.bool,
  id: PropTypes.string,
  dataType: PropTypes.string,
  renderBothDataTypes: PropTypes.bool,

  // callbacks
  updateRadioDataFilters: PropTypes.func,
  resetRadioDataFilters: PropTypes.func,
  onRenderBothDataTypesChanged: PropTypes.func,
  onDataTypeChanged: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  onHover: PropTypes.func
};

ResultPanel.defaultProps = {
  blur: false
};
export default ResultPanel;
