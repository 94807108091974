import React, { useCallback } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { demoActions, demoSelectors } from './demographicsSlice';

// UI/UX
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

// Icons
import AutorenewIcon from '@material-ui/icons/Autorenew';

// Ours
import TextFieldDebounced from '../../components/TextFieldDebounced';

const useStyles = makeStyles(() => ({
  textFields: {}
}));

const RespondentsFilter = () => {
  const classes = useStyles();

  // Redux
  const dispatch = useDispatch();
  const maxValue = useSelector(demoSelectors.selectMaxResponses);
  const numResponsesRange = useSelector(demoSelectors.selectNumResponsesRange);

  const handleResetRange = useCallback(() => {
    dispatch(demoActions.setNumResponsesRange([0, maxValue]));
  }, [maxValue, dispatch]);

  const onLowerChanged = useCallback(
    (v) => {
      const range = numResponsesRange.length === 2 ? [v, numResponsesRange[1]] : [v, maxValue];
      dispatch(demoActions.setNumResponsesRange(range));
    },
    [maxValue, numResponsesRange, dispatch]
  );

  const onUpperChanged = useCallback(
    (v) => {
      const range = numResponsesRange.length === 2 ? [numResponsesRange[0], v] : [0, v];
      dispatch(demoActions.setNumResponsesRange(range));
    },
    [numResponsesRange, dispatch]
  );

  const lower = String(numResponsesRange?.[0] ?? '');
  const upper = String(numResponsesRange?.[1] ?? '');

  return (
    <Grid className={classes.textFields} container={true} spacing={2}>
      <Grid item={true} xs={5}>
        <TextFieldDebounced label={'Between'} value={lower} onChange={onLowerChanged} numbersonly={true} />
      </Grid>
      <Grid item={true} xs={5}>
        <TextFieldDebounced label="And" value={upper} onChange={onUpperChanged} numbersonly={true} />
      </Grid>
      <Grid item={true} xs={2}>
        <Tooltip title={`Reset response range to include all results. Current maximum is ${maxValue}`}>
          <span>
            <IconButton disabled={numResponsesRange?.[1] === maxValue && numResponsesRange?.[0] === 0} color="secondary" onClick={handleResetRange}>
              <AutorenewIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default React.memo(RespondentsFilter);
