// MODULES
import axios from 'axios';

// OURS
import { SEARCH_DISTANCE } from 'iris-config'; // eslint-disable-line import/no-unresolved

// GLOBALS
const API_HOST = process.env.REACT_APP_API_HOST;
const CancelToken = axios.CancelToken;

const wrapAxios = (callback, p, onCancel) => {
  return p
    .then((r) => {
      const { status, headers } = r;
      let data = r.data;
      if (status === 204) {
        data = null;
      }
      // special handling: first parameter "e" null if 200,201:
      if (callback) callback([200, 201].includes(status) ? null : status, data, headers);
      else return { status, data, headers };
    })
    .catch((e) => {
      if (!callback) {
        return Promise.reject(e); // eslint-disable-line promise/no-return-wrap
      }
      // logic for callbacks:
      if (!axios.isCancel(e)) {
        if (e.response) {
          const { status, headers, data } = e.response;
          callback(status, data, headers);

          if ([403, 401].includes(status)) {
            // trigger token refresh (and likely sign out)
            // fire & forget:
            window.getFirebaseToken(true);
          }
        } else {
          callback(e);
        }
      } else {
        onCancel && onCancel();
      }
    });
};

const withFilePost = async (url, data, token, callback, ignoreCredentials = false, asBuffer = false, onCancel) => {
  const firebaseToken = await window.getFirebaseToken();
  if (!firebaseToken) return callback(new Error('auth expired or invalid'));

  return wrapAxios(
    callback,
    axios({
      url,
      data,
      cancelToken:
        token &&
        new CancelToken((c) => {
          token.cancel = c;
        }),
      method: 'POST',
      withCredentials: !ignoreCredentials,
      headers: {
        // 'Content-Type': 'multipart/form-data',
        Authorization: ignoreCredentials ? undefined : `Bearer ${firebaseToken}`
      },
      responseType: asBuffer ? 'arraybuffer' : undefined
    }),
    onCancel
  );
};

const genericPost = async (url, data, token, callback, ignoreCredentials = false, asBuffer = false, onCancel) => {
  const firebaseToken = await window.getFirebaseToken();
  if (!firebaseToken) return callback(new Error('auth expired or invalid'));

  return wrapAxios(
    callback,
    axios({
      url,
      data,
      cancelToken:
        token &&
        new CancelToken((c) => {
          token.cancel = c;
        }),
      method: 'POST',
      withCredentials: !ignoreCredentials,
      headers: {
        'Content-Type': 'application/json',
        Authorization: ignoreCredentials ? undefined : `Bearer ${firebaseToken}`
      },
      responseType: asBuffer ? 'arraybuffer' : undefined
    }),
    onCancel
  );
};

const genericGet = async (url, token, callback, ignoreCredentials) => {
  const firebaseToken = await window.getFirebaseToken();
  if (!firebaseToken) return callback(new Error('auth expired or invalid'));

  return wrapAxios(
    callback,
    axios({
      url,
      method: 'GET',
      withCredentials: !ignoreCredentials,
      headers: {
        'Content-Type': 'application/json',
        Authorization: ignoreCredentials ? undefined : `Bearer ${firebaseToken}`
      },
      cancelToken:
        token &&
        new CancelToken((c) => {
          token.cancel = c;
        })
    })
  );
};

const genericDelete = async (url, token, callback, ignoreCredentials) => {
  const firebaseToken = await window.getFirebaseToken();
  if (!firebaseToken) return callback(new Error('auth expired or invalid'));

  return axios({
    url,
    method: 'DELETE',
    withCredentials: !ignoreCredentials,
    headers: {
      'Content-Type': 'application/json',
      Authorization: ignoreCredentials ? undefined : `Bearer ${firebaseToken}`
    },
    cancelToken:
      token &&
      new CancelToken((c) => {
        token.cancel = c;
      })
  })
    .then((r) => {
      const { data, status, headers } = r;
      callback && callback([200, 201].includes(status) ? null : status, data, headers);
    })
    .catch((e) => {
      if (!axios.isCancel(e)) {
        callback && callback(e);
      }
    });
};

const placesNearPlace = (latlon, callback) => {
  genericGet(`${API_HOST}/places/v0/places/near/${latlon.x_lon}/${latlon.y_lat}/${SEARCH_DISTANCE}`, null /* cancel token*/, callback);
};

export {
  // \n
  API_HOST,
  genericDelete,
  genericGet,
  genericPost,
  withFilePost,
  placesNearPlace
};
