import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const FlexGrid = withStyles(() => ({
  item: {
    display: 'flex'
  }
}))(Grid);

export default FlexGrid;
