export const viewportFromRef = (refDeckMap) => {
  const deck = refDeckMap.current;
  if (!deck || !deck.deck) return;

  const viewManager = deck.deck.viewManager;
  if (!viewManager) return;

  const viewports = viewManager.getViewports();
  if (!viewports || viewports.length === 0) return;

  return viewports[0];
};

export const mapStateFromRef = (refDeckMap) => {
  const viewport = viewportFromRef(refDeckMap);
  if (!viewport) return;

  const nw = viewport.unproject([0, 0]);
  const se = viewport.unproject([viewport.width, viewport.height]);
  const ne = viewport.unproject([viewport.width, 0]);
  const sw = viewport.unproject([0, viewport.height]);

  const mapPolygon = {
    nw,
    ne,
    se,
    sw
  };

  const { longitude, latitude, zoom } = viewport;

  const mapBounds = {
    minx: Math.min(nw[0], sw[0]),
    maxx: Math.max(ne[0], se[0]),

    miny: Math.min(se[1], sw[1]),
    maxy: Math.max(nw[1], ne[1])
  };

  const mapCenter = {
    x_lon: longitude,
    y_lat: latitude
  };

  return {
    mapBounds,
    mapCenter,
    mapZoom: zoom,
    mapPolygon
  };
};

export const polygonHasColor = ({ layer }) => {
  const fillColor = layer.paint ? layer.paint['fill-color'] : null;

  if (!fillColor) return false;

  return !(fillColor.r === 0 && fillColor.g === 0 && fillColor.b === 0 && fillColor.a === 0);
};
