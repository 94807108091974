import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { demoActions, demoSelectors } from './demographicsSlice';
import { getAllQuestionsWithAnswers } from './services';

// UI/UX
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

// OURS
import { QUESTION_LOOKUP } from '../../constants';

// GLOBALS
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(4)}px)`
  },
  select: {
    width: '100%'
  },
  container: {
    margin: theme.spacing(2)
  },
  toggleButtons: {
    marginLeft: theme.spacing(2)
  },
  selected: {
    '&&': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main
    }
  }
}));

const QAFilterTabPanel = ({ setActiveTabIndex, question, index, activeTabIndex }) => {
  const classes = useStyles();

  // Redux Toolkit
  const dispatch = useDispatch();

  // Selectors
  const selectedQuestion = useSelector(demoSelectors.selectSelectedQuestion);
  const filterSets = useSelector(demoSelectors.selectQAFilterChipSets);

  // Local State
  const [localFilterQuestion, setLocalFilterQuestion] = useState('');
  const [localFilterAnswers, setLocalFilterAnswers] = useState([]);
  const [localOnlyMostPrevalent, setLocalOnlyMostPrevalent] = useState(false);
  const [localAnswerInclusionSetting, setLocalAnswerInclusionSetting] = useState('any');

  // Queries
  const allQAQuery = getAllQuestionsWithAnswers.useQuery();

  // Methods

  const handleFilterQuestionChange = useCallback((event) => {
    setLocalFilterAnswers([]);
    setLocalFilterQuestion(event.target.value);
  }, []);

  const handleFilterAnswerChange = useCallback((event) => {
    setLocalFilterAnswers(event.target.value);
  }, []);

  const handlePrevalentSwitchChange = useCallback(
    (event) => {
      if (event.target.checked) {
        setLocalAnswerInclusionSetting(null);
      } else {
        setLocalAnswerInclusionSetting('any');
      }

      setLocalOnlyMostPrevalent(event.target.checked);
    },
    []
  );

  const handleAnswerInclusionChange = useCallback((event, newValue) => {
    if (newValue !== null) {
      setLocalAnswerInclusionSetting(newValue);
    }
  }, []);

  // Case where this is not the set creation tab
  const handleDelete = useCallback(() => {
    setActiveTabIndex(index - 1);
    dispatch(demoActions.clickedQAFilterChipDelete({ filterQuestion: localFilterQuestion }));
  }, [localFilterQuestion, index, setActiveTabIndex, dispatch]);

  const handleSave = useCallback(() => {
    dispatch(
      demoActions.clickedQAFilterChipSave({
        oldFilterQuestion: question,
        onlyMostPrevalent: localOnlyMostPrevalent,
        answerInclusionSetting: localAnswerInclusionSetting,
        filterQuestion: localFilterQuestion,
        filterAnswers: localFilterAnswers.reduce((map, currAnswer) => {
          map[currAnswer] = true;
          return map;
        }, {})
      })
    );
  }, [localFilterQuestion, localFilterAnswers, question, localOnlyMostPrevalent, localAnswerInclusionSetting, dispatch]);

  useEffect(() => {
    setLocalFilterQuestion(question);
    setLocalFilterAnswers(Object.keys(filterSets[question].filterAnswers));
    setLocalOnlyMostPrevalent(filterSets[question].onlyMostPrevalent);
    setLocalAnswerInclusionSetting(filterSets[question].answerInclusionSetting);
  }, [question, filterSets]);

  return (
    <Box className={classes.container} hidden={index !== activeTabIndex}>
      <Grid id="topRow" container={true} spacing={2} direction="row" alignItems="center" justifyContent="center">
        <Grid item={true} xs={12}>
          {allQAQuery.data && (
            <>
              <FormControl className={classes.formControl}>
                <Typography id="demo-label-sel-chip-question">Questions</Typography>
                <Select
                  MenuProps={{
                    getContentAnchorEl: () => null
                  }}
                  labelid="demo-label-sel-chip-question"
                  value={localFilterQuestion}
                  className={classes.select}
                  onChange={handleFilterQuestionChange}
                >
                  {Object.keys(allQAQuery.data)
                    .filter((elem) => {
                      return elem !== selectedQuestion && (elem === localFilterQuestion || !filterSets[elem]);
                    })
                    .map((elem) => (
                      <MenuItem key={elem} value={elem}>
                        {QUESTION_LOOKUP[elem]}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <Box style={{ display: 'flex', flexdirection: 'row' }}>
                  <Typography id="prevalent-switch-tab-typography">Only where answer is most prevalent:</Typography>
                  <Tooltip title="Only show regions whose most prevalent answer is the one you selected. If turned off, all regions whose answers include all of the ones selected will be displayed.">
                    <Switch labelid="prevalent-switch-tab-switch" checked={localOnlyMostPrevalent} onChange={handlePrevalentSwitchChange} />
                  </Tooltip>
                </Box>
              </FormControl>
              <FormControl className={classes.formControl}>
                <Box style={{ display: 'flex', flexdirection: 'row' }}>
                  <Typography id="answer-inclusion-switch-tab-typography">Answer inclusion setting:</Typography>
                  <Tooltip title="Display only regions whose answers include any of, all of, or only the ones selected">
                    <ToggleButtonGroup className={classes.toggleButtons} size="small" value={localAnswerInclusionSetting} exclusive={true} onChange={handleAnswerInclusionChange}>
                      <ToggleButton disabled={localOnlyMostPrevalent} value="any" classes={{ selected: classes.selected }}>
                        Any
                      </ToggleButton>
                      <ToggleButton disabled={localOnlyMostPrevalent} value="all" classes={{ selected: classes.selected }}>
                        All
                      </ToggleButton>
                      <ToggleButton disabled={localOnlyMostPrevalent} value="only" classes={{ selected: classes.selected }}>
                        Only
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Tooltip>
                </Box>
              </FormControl>
              <FormControl className={classes.formControl}>
                <Typography id="demo-label-sel-chip-answer">Answers</Typography>
                <Select
                  MenuProps={{
                    getContentAnchorEl: () => null
                  }}
                  labelid="demo-label-sel-chip-answer"
                  value={localFilterAnswers}
                  className={classes.select}
                  disabled={!localFilterQuestion}
                  defaultValue="Please select an answer"
                  onChange={handleFilterAnswerChange}
                  multiple={true}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {localFilterQuestion &&
                    [...allQAQuery.data[localFilterQuestion].answers].sort().map((elem) => (
                      <MenuItem key={elem} value={elem}>
                        {elem}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </>
          )}
        </Grid>
      </Grid>
      <Grid container={true} direction="row" spacing={2} className={classes.grid}>
        <Grid item={true} xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="outlined" onClick={handleDelete}>
            Delete
          </Button>
        </Grid>
        <Grid item={true} xs={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Button variant="outlined" color="secondary" disabled={!localFilterQuestion || localFilterAnswers.length === 0} onClick={handleSave}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

QAFilterTabPanel.propTypes = {
  index: PropTypes.number,
  activeTabIndex: PropTypes.number,
  question: PropTypes.string,
  setActiveTabIndex: PropTypes.func
};

export default QAFilterTabPanel;
