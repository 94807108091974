import React, { Component } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

// UI/UX
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';

// material icons
import Brightness6Icon from '@material-ui/icons/Brightness6';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InfoIcon from '@material-ui/icons/Info';
import MemoryIcon from '@material-ui/icons/Memory';
import RateReviewIcon from '@material-ui/icons/RateReview';
import SettingsIcon from '@material-ui/icons/Settings';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

// ours
import { Features as FEATURES, has } from '@premisedata/lib-features';
import { toggleTheme, toggleGPU } from './actions';

const styles = (theme) => ({
  root: {
    flexGrow: 1
  },
  menuItem: {
    cursor: 'pointer',
    '& svg': {
      marginRight: theme.spacing(1)
    }
  },
  iconDiv: {
    display: 'flex',
    alignItems: 'center'
  }
});

class AppMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.toggleOpen = this.toggleOpen.bind(this);
    this.onSettings = this.onSettings.bind(this);
    this.onAdminPanel = this.onAdminPanel.bind(this);
    this.onToggleTheme = this.onToggleTheme.bind(this);
    this.onToggleGPU = this.onToggleGPU.bind(this);
    this.onShareFeedback = this.onShareFeedback.bind(this);
    this.onAbout = this.onAbout.bind(this);
    this.onExport = this.onExport.bind(this);
    this.onLogout = this.onLogout.bind(this);
  }

  toggleOpen(e) {
    const { anchorEl } = this.state;

    if (anchorEl) {
      this.setState({
        anchorEl: null
      });
    } else {
      this.setState({
        anchorEl: e.currentTarget
      });
    }
  }
  onSettings() {
    this.toggleOpen();
    this.props.onSettings();
  }
  onAdminPanel() {
    this.toggleOpen();
    this.props.onAdminPanel();
  }
  onToggleTheme(e) {
    e.stopPropagation();
    e.preventDefault();
    this.props.toggleTheme();
  }
  onToggleGPU(e) {
    e.stopPropagation();
    e.preventDefault();
    this.props.toggleGPU();
  }
  onShareFeedback() {
    this.toggleOpen();
    this.props.onShareFeedback();
  }
  onAbout() {
    this.toggleOpen();
    this.props.onAbout();
  }
  onExport() {
    this.state.anchorEl && this.setState({ anchorEl: null });
    this.props.onExport();
  }
  onLogout() {
    this.toggleOpen();
    this.props.onLogout?.();
  }

  render() {
    const { theme, classes, onExport, hasGPU, hasGPUHardware, user, activeProduct } = this.props;
    const { anchorEl } = this.state;
    const showExportMenuItem =
      (activeProduct === 'places' && has(user.active_features, FEATURES.PLACES_EXPORTS)) ||
      (activeProduct === 'radio' && has(user.active_features, FEATURES.RADIO_EXPORTS));
    return (
      <React.Fragment>
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={this.toggleOpen}>
          <SettingsIcon />
        </IconButton>
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted={false} open={Boolean(anchorEl)} onClose={this.toggleOpen}>
          <MenuItem className={classes.menuItem} onClick={this.props.toggleTheme}>
            <FormControlLabel
              onClick={this.onToggleTheme}
              control={<Switch checked={theme.palette.type === 'dark'} value="theme-switch" />}
              label={
                <div className={classes.iconDiv}>
                  <Brightness6Icon />
                  Dark Mode
                </div>
              }
            />
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={this.onToggleGPU} disabled={!hasGPUHardware}>
            <FormControlLabel
              onClick={this.onToggleGPU}
              control={<Switch checked={hasGPU} value="theme-switch" />}
              label={
                <div className={classes.iconDiv}>
                  <MemoryIcon />
                  GPU Enabled
                </div>
              }
            />
          </MenuItem>

          <MenuItem onClick={this.onShareFeedback} className={classes.menuItem}>
            <RateReviewIcon />
            Share Feedback
          </MenuItem>

          {showExportMenuItem && (
            <MenuItem onClick={this.onExport} className={classes.menuItem} disabled={!onExport}>
              <CloudDownloadIcon /> Export Data
            </MenuItem>
          )}

          {user.is_admin && (
            <MenuItem onClick={this.onAdminPanel} className={classes.menuItem}>
              <SupervisorAccountIcon />
              Admin Panel
            </MenuItem>
          )}

          <MenuItem onClick={this.onSettings} className={classes.menuItem}>
            <SettingsIcon />
            Settings
          </MenuItem>

          <MenuItem onClick={this.onAbout} className={classes.menuItem}>
            <InfoIcon />
            About
          </MenuItem>

          <MenuItem onClick={this.onLogout} className={classes.menuItem}>
            <ExitToAppIcon /> Logout
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  hasGPU: state.app.hasGPU,
  hasGPUHardware: state.app.hasGPUHardware,
  user: state.app.user,
  activeProduct: state.app.activeProduct
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleTheme,
      toggleGPU
    },
    dispatch
  );

const withRedux = connect(mapStateToProps, mapDispatchToProps);

AppMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  hasGPU: PropTypes.bool,
  hasGPUHardware: PropTypes.bool,
  user: PropTypes.object,
  activeProduct: PropTypes.string,

  onLogout: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  onAdminPanel: PropTypes.func.isRequired,
  onSettings: PropTypes.func.isRequired,
  onAbout: PropTypes.func.isRequired,
  onShareFeedback: PropTypes.func.isRequired,
  toggleGPU: PropTypes.func.isRequired,
  toggleTheme: PropTypes.func.isRequired
};

AppMenu.defaultProps = {
  activeProduct: '',
  hasGPUHardware: false,
  hasGPU: false
};

export default withRedux(withTheme(withStyles(styles)(AppMenu)));
