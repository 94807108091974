import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import moment from 'moment';
import debounce from 'lodash.debounce';
import { Brush } from '@visx/brush';
import { scaleLinear, scaleTime } from '@visx/scale';

import { usePrevious } from 'iris-util'; // eslint-disable-line import/no-unresolved

const SmarterBrush = (props) => {
  const theme = useTheme();
  const { patternId, height, width, margin, selectedDateRange, temporalDomain, onBrushChange } = props;

  const buildSelectedBrushStyle = useCallback(() => ({
    fill: `url(#${patternId})`,
    stroke: theme.palette.text.primary
  }), [patternId, theme.palette.text.primary]);
  const [selectedBrushStyle, setSelectedBrushStyle] = useState(buildSelectedBrushStyle());
  useEffect(() => setSelectedBrushStyle(buildSelectedBrushStyle()), [buildSelectedBrushStyle]);

  const yScale = useMemo(
    () => scaleLinear({
      range: [height - margin.top - margin.bottom, 0],
      domain: [0, 1]
    }),
    [height, margin]
  );

  const xScale = useMemo(
    () => scaleTime({
      range: [0, width - margin.left - margin.right],
      domain: temporalDomain
    }),
    [width, margin, temporalDomain]
  );

  const [resizeTriggerAreas] = useState(['left', 'right']);

  const brushRef = useRef();
  const prevSelectedDateRange = usePrevious(selectedDateRange);
  useEffect(() => {
    if (!selectedDateRange && prevSelectedDateRange) {
      // date range cleared; could have been cleared externally:
      brushRef.current?.reset();
    }
  }, [selectedDateRange, prevSelectedDateRange]);

  const [initialBrushPosition] = useState(() => {
    if (selectedDateRange) {
      return {
        start: { x: xScale(selectedDateRange.start) },
        end: { x: xScale(selectedDateRange.end) }
      };
    } else {
      return null;
    }
  });
  const twoDays = useMemo(() => moment.duration(2, 'days').asMilliseconds(), []);
  const onChange = useMemo(
    () => debounce((domain) => {
      // RESET
      if (!domain) return onBrushChange(null);

      const { x0, x1 } = domain;

      // TWO SMALL, then RESET:
      if (Math.abs(x1 - x0) < twoDays) {
        brushRef.current?.reset();
        return onBrushChange(null);
      }

      onBrushChange({ x0, x1 });
    }, 333),
    [onBrushChange, twoDays]
  );

  return (
    <Brush
      // width & xScale accounts for margin
      xScale={xScale}
      width={width}
      // yScale & height must account for margin:
      yScale={yScale}
      height={height - margin.top - margin.bottom}
      // pass margin for brush calculations:
      margin={margin}
      initialBrushPosition={initialBrushPosition}
      handleSize={8}
      innerRef={brushRef}
      resizeTriggerAreas={resizeTriggerAreas}
      brushDirection="horizontal"
      onChange={onChange}
      selectedBoxStyle={selectedBrushStyle}
      useWindowMoveEvents={true}
    />
  );
};

SmarterBrush.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  margin: PropTypes.object.isRequired,
  patternId: PropTypes.string.isRequired,
  onBrushChange: PropTypes.func.isRequired,
  selectedDateRange: PropTypes.object,
  temporalDomain: PropTypes.array
};
SmarterBrush.defaultProps = {};
export default React.memo(SmarterBrush);
