import { withStyles, withTheme } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// UI/UX
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';

// ICONS
import SettingsIcon from '@material-ui/icons/Settings';
import WarningIcon from '@material-ui/icons/Warning';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import ShowChartIcon from '@material-ui/icons/ShowChart';

// OURS
import WorkIconButton from '../../../WorkIconButton';
import FlexGrid from './FlexGrid';

const styles = (theme) => ({
  formcontrollabel: {
    display: 'flex',
    alignItems: 'center',
    '& > span:not(:first-child)': {
      display: 'flex',
      width: '100%',
      alignItems: 'center'
    },
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.background.default
    },
    borderRadius: '5px',
    padding: `${theme.spacing(2)}px 0px`,
    marginLeft: 0,
    marginRight: 0,
    paddingRight: 9
  },
  adornmentBtn: {
    width: '40px',
    height: '40px',
    marginLeft: 'auto',
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'center'
  },
  slowAnimation: {
    animationDuration: '10s'
  }
});
class CheckQuestion extends React.PureComponent {
  render() {
    const { classes, question, selectedQuestions, onConfigureNewQuestion, onQuestionSelectionsChanged, selectedQuestionType } = this.props;
    const misconfiguredOrdering =
      !question.ordering || // malformed, no ordering object
      typeof question.ordering !== 'object' || // malformed
      Object.entries(question.ordering).length === 0; // misconfigured ordering
    const questionType = question.is_ordinal ? 'ordinal' : 'categorical';

    const disabled = !question.user_request_completed_on || misconfiguredOrdering || (selectedQuestionType && selectedQuestionType !== questionType);
    const base = (
      <div>
        {question.question_proper}
        <Grid spacing={2} container={true} direction="row" justifyContent="flex-start" alignItems="center">
          <FlexGrid item={true}>{question.is_ordinal ? <ShowChartIcon /> : <MultilineChartIcon />}</FlexGrid>
        </Grid>
      </div>
    );
    let label;
    if (misconfiguredOrdering && question.user_request_completed_on) {
      label = (
        <React.Fragment>
          {base}
          <Tooltip title="This question is misconfigured!">
            <WarningIcon fontSize="small" />
          </Tooltip>
        </React.Fragment>
      );
    } else if (!question.user_requested_on) {
      label = (
        <React.Fragment>
          {base}
          <WorkIconButton
            TooltipProps={{ title: 'Request analysis', arrow: true }}
            size="small"
            color="default"
            className={classes.adornmentBtn}
            onClick={(done) => onConfigureNewQuestion(question, done)}
          >
            <SettingsIcon fontSize="small" />
          </WorkIconButton>
        </React.Fragment>
      );
    } else if (question.user_requested_on && !question.user_request_completed_on) {
      label = (
        <React.Fragment>
          {base}
          <div className={classes.adornmentBtn}>
            <CircularProgress size="1.25rem" color="secondary" classes={{ indeterminate: classes.slowAnimation, circleIndeterminate: classes.slowAnimation }} />
          </div>
        </React.Fragment>
      );
    } else {
      label = base;
    }

    return (
      <FormControlLabel
        className={clsx('noselect', classes.formcontrollabel)}
        key={question.question_name}
        name={question.question_name}
        disabled={disabled}
        control={
          <Checkbox
            checked={selectedQuestions.includes(question)}
            onChange={(e) => {
              const { checked } = e.target;
              onQuestionSelectionsChanged(question, checked);
            }}
          />
        }
        label={label}
      />
    );
  }
}

CheckQuestion.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  question: PropTypes.object.isRequired,
  selectedQuestions: PropTypes.array,
  selectedQuestionType: PropTypes.string,

  onConfigureNewQuestion: PropTypes.func.isRequired,
  onQuestionSelectionsChanged: PropTypes.func.isRequired
};

CheckQuestion.defaultProps = {
  selectedQuestions: {}
};
export default withTheme(withStyles(styles)(CheckQuestion));
