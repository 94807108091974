import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// REDUX TOOLKIT
import { demoActions, demoSelectors } from './demographicsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getAllQuestionsWithAnswers } from './services';

// UI/UX
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

// ICONS
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';

// GLOBALS
const useStyles = makeStyles((theme) => ({
  popover: {
    padding: theme.spacing(2),
    textTransform: 'capitalize',
    minWidth: '350px',
    maxWidth: '450px'
  },
  formControl: {
    margin: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(4)}px)`
  },
  select: {
    minWidth: '325px',
    maxWidth: '400px'
  },
  toggleButtons: {
    marginLeft: theme.spacing(2)
  },
  selected: {
    '&&': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main
    }
  }
}));

/**
 *
 * @param chipName This name is used to identify the chip and locate it within the redux demographics slice state.
 * @param label String to be displayed as the chip's label
 * @returns {JSX.Element}
 * @constructor
 * @author Pau Lleonart Calvo <pau.calvo@premise.com>
 */
const AnswerFilterChip = ({ chipName, label }) => {
  // Redux Toolkit
  const dispatch = useDispatch();
  const classes = useStyles();

  // Local State
  const [anchorEl, setAnchorEl] = useState(null); // Used to manage popover on/off
  const [localFilterAnswers, setLocalFilterAnswers] = useState([]);
  const [localOnlyMostPrevalent, setLocalOnlyMostPrevalent] = useState(false);
  const [localAnswerInclusionSetting, setLocalAnswerInclusionSetting] = useState('any');

  const open = !!anchorEl;
  const id = open ? chipName : undefined;

  // Selectors
  const isActive = useSelector((state) => demoSelectors.selectConfigChipActiveByName(state, chipName));
  const selectedFilterAnswers = useSelector(demoSelectors.selectAnswerFilterChipAnswers);
  const onlyMostPrevalent = useSelector(demoSelectors.selectAnswerFilterChipOnlyMostPrevalent);
  const answerInclusionSetting = useSelector(demoSelectors.selectAnswerFilterChipInclusionSetting);
  const selectedQuestion = useSelector(demoSelectors.selectSelectedQuestion);

  // Queries
  const allQAQuery = getAllQuestionsWithAnswers.useQuery();

  // Methods

  /**
   * User clicked a filter answer -> Store the value and display it as the selected
   * filter answer.
   */
  const handleFilterAnswerChange = useCallback((event) => {
    setLocalFilterAnswers(event.target.value);
  }, []);

  const handlePrevalentSwitchChange = useCallback(
    (event) => {
      if (event.target.checked) {
        setLocalAnswerInclusionSetting(null);
      } else {
        setLocalAnswerInclusionSetting('any');
      }
      setLocalOnlyMostPrevalent(event.target.checked);
    },
    []
  );

  const handleAnswerInclusionChange = useCallback((_, newValue) => {
    if (newValue !== null) {
      setLocalAnswerInclusionSetting(newValue);
    }
  }, []);

  /**
   * User clicked chip -> Open the popover
   */
  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  /**
   * User closed the popover without clicking done -> Close the popover
   */
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  /**
   * User clicked DONE -> Take the most recently selected value and publish it to the redux state so that
   * it is applied as a filter. Then close the popover.
   */
  const handleDone = useCallback(() => {
    dispatch(
      demoActions.clickedAnswerFilterChipDone({
        filterAnswers: localFilterAnswers.reduce((map, currAnswer) => {
          map[currAnswer] = true;
          return map;
        }, {}),
        onlyMostPrevalent: localOnlyMostPrevalent,
        answerInclusionSetting: localAnswerInclusionSetting
      })
    );
    setAnchorEl(null);
  }, [localFilterAnswers, localOnlyMostPrevalent, localAnswerInclusionSetting, dispatch]);

  /**
   * User clicked CLEAR -> Both local and redux state values for answerFilter
   * should be set back to empty so that none are selected and none are applied.
   * Then close the popover.
   */
  const handleClear = useCallback(() => {
    dispatch(demoActions.clickedAnswerFilterChipClear());
    setLocalFilterAnswers([]);
    setLocalOnlyMostPrevalent(false);
    setLocalAnswerInclusionSetting('any');
    setAnchorEl(null);
  }, [dispatch]);

  useEffect(() => {
    setLocalFilterAnswers(Object.keys(selectedFilterAnswers));
    setLocalOnlyMostPrevalent(onlyMostPrevalent);
    setLocalAnswerInclusionSetting(answerInclusionSetting);
  }, [selectedFilterAnswers, onlyMostPrevalent, answerInclusionSetting]);

  // Render Component
  return (
    <React.Fragment>
      <Chip
        aria-describedby={id}
        variant="outlined"
        color={isActive ? 'secondary' : 'default'}
        label={label}
        deleteIcon={<ArrowDropDownIcon />}
        onClick={handleClick}
        onDelete={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleClose}
      >
        <Box className={classes.popover}>
          <Grid id="topRow" container={true} spacing={2} direction="row" alignItems="center" justifyContent="center">
            <Grid item={true}>
              <FormControl className={classes.formControl}>
                <Box style={{ display: 'flex', flexdirection: 'row' }}>
                  <Typography id="prevalent-switch-create-typography">Only where answer is most prevalent:</Typography>
                  <Tooltip title="Only show regions whose most prevalent answer is the one you selected.">
                    <Switch labelid="prevalent-switch-create" checked={localOnlyMostPrevalent} onChange={handlePrevalentSwitchChange} />
                  </Tooltip>
                </Box>
              </FormControl>
              <FormControl className={classes.formControl}>
                <Box style={{ display: 'flex', flexdirection: 'row' }}>
                  <Typography id="all-answers-switch-create-typography">Answer inclusion setting:</Typography>
                  <Tooltip title="Display only regions whose answers include any of, all of, or only the ones selected">
                    <ToggleButtonGroup className={classes.toggleButtons} size="small" value={localAnswerInclusionSetting} exclusive={true} onChange={handleAnswerInclusionChange}>
                      <ToggleButton disabled={localOnlyMostPrevalent} value="any" classes={{ selected: classes.selected }}>
                        Any
                      </ToggleButton>
                      <ToggleButton disabled={localOnlyMostPrevalent} value="all" classes={{ selected: classes.selected }}>
                        All
                      </ToggleButton>
                      <ToggleButton disabled={localOnlyMostPrevalent} value="only" classes={{ selected: classes.selected }}>
                        Only
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Tooltip>
                </Box>
              </FormControl>
              <FormControl className={classes.formControl}>
                <Typography id="demo-label-sel-chip-answer">Answer</Typography>
                <Select
                  MenuProps={{
                    getContentAnchorEl: () => null
                  }}
                  labelid="demo-label-sel-chip-answer"
                  value={localFilterAnswers}
                  className={classes.select}
                  defaultValue="Please select a question"
                  onChange={handleFilterAnswerChange}
                  multiple={true}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {selectedQuestion &&
                    allQAQuery.data &&
                    [...allQAQuery.data[selectedQuestion].answers]
                      .sort() // Sort the list of answer choices
                      .map((elem) => (
                        <MenuItem key={elem} value={elem}>
                          {elem}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Make a grid row for the Clear and Done buttons*/}
          <Grid container={true} direction="row" spacing={2}>
            <Grid item={true} xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="outlined" onClick={handleClear}>
                Clear
              </Button>
            </Grid>
            <Grid item={true} xs={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Button variant="outlined" color="secondary" disabled={localFilterAnswers.length === 0} onClick={handleDone}>
                Done
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </React.Fragment>
  );
};

// PROP DEFINITIONS
AnswerFilterChip.propTypes = {
  chipName: PropTypes.string,
  label: PropTypes.string
};

// EXPORT COMPONENT
export default React.memo(AnswerFilterChip);
