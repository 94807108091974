import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// MODULES
import clsx from 'clsx';
import { scaleLinear, interpolateHcl, rgb, scaleQuantile } from 'd3';
import { capitalCase } from 'change-case';

// MUI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from '@material-ui/core/IconButton';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

// OURS
import { getDateHistograms, getDemographics } from './services';
import ChipPopover from '../ChipPopover';
import Fetching from '../../components/Fetching';
import QAFilter from './QAFilter';
import ChipDateRangePicker from '../ChipDateRangePicker';

// GLOBALS
const MAX_DATE_CHIP_WIDTH = 130;
const MAX_DEMO_CHIP_WIDTH = 130;
const TOPBAR_HEIGHT = 48;
const REQ_CHIP_WIDTHS = MAX_DATE_CHIP_WIDTH + MAX_DEMO_CHIP_WIDTH;

const FormControlLabel = React.memo((props) => {
  const { datum, onChange, checked, ...otherProps } = props;
  const _onChange = useCallback(
    (e) => {
      onChange(e.target.checked, datum);
    },
    [onChange, datum]
  );
  return <MuiFormControlLabel control={<Checkbox checked={checked} onChange={_onChange} />} {...otherProps} />;
});
FormControlLabel.displayName = 'FormControlLabel';
FormControlLabel.propTypes = {
  datum: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired
};

const useStyles = makeStyles((theme) => ({
  box: {
    padding: theme.spacing(1),
    display: 'flex'
  },
  day: {
    transition: '250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: theme.palette.text.primary,
    width: 36,
    height: 36,
    margin: '0 2px',
    padding: 0,
    fontSize: '0.75rem',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: theme.palette.action.hover + ' !important',
      transform: 'scale(1) !important'
    }
  },
  dayHidden: {
    opacity: 0,
    pointerEvents: 'none'
  },
  daySelected: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    backgroundColor: theme.palette.text.primary
  },
  marginRight: {
    marginRight: theme.spacing(1)
  },
  marginRightBottom: {
    margin: theme.spacing(0, 1, 1, 0)
  },
  divider: {
    margin: theme.spacing(2)
  }
}));

const TopBarSide = (props) => {
  const { onSelectedDateRangeChanged } = props;
  const theme = useTheme();
  const classes = useStyles();
  const {
    width,
    selectedDateRange,
    selectedDemographics,
    selectedQAFilterForm,
    selectedQAFilterQuestion,
    selectedQAFilterAnswers,
    onQAFilterSelectedFormChanged,
    onQAFilterSelectedQuestionChanged,
    onQAFilterSelectedAnswersChanged,
    onQAFiltersReset
  } = props;

  // REDUX
  const selectedQuestions = useSelector((state) => state.sentiment.selectedQuestions);
  const selectedForms = useSelector((state) => state.sentiment.selectedForms);

  // QUERY
  const skipDateHistogram = selectedQuestions.length === 0 || selectedForms.length === 0;
  const dateHistogram = getDateHistograms.useQuery({ selectedForms, selectedQuestions }, { skip: skipDateHistogram });
  const skipDemographics = selectedForms.length === 0;
  const demographics = getDemographics.useQuery({ selectedForms }, { skip: skipDemographics });
  // USECALLBACK
  const dateScales = useMemo(() => {
    if (!dateHistogram.data || dateHistogram.isError) return null;
    return {
      color: scaleLinear()
        .domain([dateHistogram.data.min, dateHistogram.data.max])
        .interpolate(interpolateHcl)
        .range([rgb(theme.palette.background.default), rgb(theme.palette.secondary.main)]),
      size: scaleQuantile().domain(Object.values(dateHistogram.data.dateHisto)).range([0.5, 1.0])
    };
  }, [dateHistogram.data, dateHistogram.isError, theme.palette.secondary.main, theme.palette.background.default]);

  const onDateChanged = useCallback(
    (range) => {
      onSelectedDateRangeChanged(range);
    },
    [onSelectedDateRangeChanged]
  );

  // declaring this here to improve ESLINT parsing:
  const getContrastText = theme.palette.getContrastText;
  const renderDatePickerDay = useCallback(
    (date, _, dayInCurrentMonth, side) => {
      const k = 'str' + side;
      const fullDate = date.format('YYYY-MM-DD');
      const dateIsSelected = fullDate === selectedDateRange?.[k];
      const dateHisto = dateHistogram.data?.dateHisto ?? {};

      const style = {};
      if (dateScales && !dateIsSelected && fullDate in dateHisto) {
        const n = dateHisto[fullDate];
        style.backgroundColor = dateScales.color(n);
        style.color = getContrastText(style.backgroundColor);
        style.transform = `scale(${dateScales.size(n)})`;
      }
      return (
        <IconButton
          style={style}
          className={clsx({
            [classes.day]: true,
            [classes.dayHidden]: !dayInCurrentMonth,
            [classes.daySelected]: dateIsSelected
          })}
        >
          <Typography variant="body2" color="inherit">
            {date.format('D')}
          </Typography>
        </IconButton>
      );
    },
    [dateScales, dateHistogram.data, selectedDateRange, classes.day, classes.dayHidden, classes.daySelected, getContrastText]
  );
  const renderDatePickerDayStart = useCallback(
    (date, selectedDate, dayInCurrentMonth) => {
      return renderDatePickerDay(date, selectedDate, dayInCurrentMonth, 'START');
    },
    [renderDatePickerDay]
  );
  const renderDatePickerDayEnd = useCallback(
    (date, selectedDate, dayInCurrentMonth) => {
      return renderDatePickerDay(date, selectedDate, dayInCurrentMonth, 'STOP');
    },
    [renderDatePickerDay]
  );

  // USEMEMO
  const demographicsRenderDetails = useMemo(() => {
    if (!demographics.data || demographics.isError) return null;

    // -> Demographics, do calculations based on the wider chip (date)
    const idx = Math.max(0, Math.floor((width - REQ_CHIP_WIDTHS) / MAX_DEMO_CHIP_WIDTH));
    return { visibleDemographics: demographics.data.slice(0, idx), otherDemographics: demographics.data.slice(idx) };
  }, [demographics.data, demographics.isError, width]);

  const dateHistogramRenderDetails = useMemo(() => {
    if (!dateHistogram.data || dateHistogram.isError) return null;

    const dates = Object.keys(dateHistogram.data.dateHisto);
    return {
      initialFocusedDateStart: dates[0],
      initialFocusedDateEnd: dates[dates.length - 1]
    };
  }, [dateHistogram.data, dateHistogram.isError]);

  const popoverOrigins = useMemo(
    () => ({
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left'
      }
    }),
    []
  );

  if (dateHistogram.isFetching || demographics.isFetching) return <Fetching size={TOPBAR_HEIGHT / 2} />;
  if (!demographicsRenderDetails || !dateScales || !dateHistogramRenderDetails) return null;
  return (
    <Box className={classes.box}>
      <ChipDateRangePicker
        chipClass={classes.marginRight}
        maxWidth={MAX_DATE_CHIP_WIDTH}
        selectedDateRange={selectedDateRange}
        initialFocusedDateStart={dateHistogramRenderDetails.initialFocusedDateStart}
        initialFocusedDateEnd={dateHistogramRenderDetails.initialFocusedDateEnd}
        renderDayStart={renderDatePickerDayStart}
        renderDayEnd={renderDatePickerDayEnd}
        onRangeChanged={onDateChanged}
      />

      {demographicsRenderDetails && (
        <>
          {demographicsRenderDetails.visibleDemographics.map((d /* { variable, possible_options } */) => (
            <ChipPopover
              key={d.variable}
              className={classes.marginRight}
              active={!!selectedDemographics[d.variable]}
              clearDone={false}
              disabled={false}
              label={selectedDemographics[d.variable]?.length > 0 ? `${capitalCase(d.variable)} • ${selectedDemographics[d.variable].length}` : capitalCase(d.variable)}
              maxWidth={MAX_DEMO_CHIP_WIDTH}
              popoverOrigins={popoverOrigins}
            >
              <FormControl component="fieldset">
                <FormGroup>
                  {d.possible_options.map((answer /* Male */) => (
                    <FormControlLabel
                      onChange={props.onUpdateDemographic}
                      checked={Boolean(selectedDemographics?.[d.variable]?.includes(answer))}
                      datum={{ answer, demographic: d }}
                      key={answer}
                      label={answer}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </ChipPopover>
          ))}
          <ChipPopover
            className={classes.marginRight}
            active={false}
            clearDone={false}
            disabled={false}
            label={'More...'}
            maxWidth={MAX_DEMO_CHIP_WIDTH}
            popoverOrigins={popoverOrigins}
          >
            <Box>
              {demographicsRenderDetails.otherDemographics.map((d /* { variable, possible_options } */) => (
                <ChipPopover
                  key={d.variable}
                  className={classes.marginRightBottom}
                  active={!!selectedDemographics[d.variable]}
                  clearDone={false}
                  disabled={false}
                  label={selectedDemographics[d.variable]?.length > 0 ? `${capitalCase(d.variable)} • ${selectedDemographics[d.variable].length}` : capitalCase(d.variable)}
                  maxWidth={MAX_DEMO_CHIP_WIDTH * 2}
                  popoverOrigins={popoverOrigins}
                >
                  <FormControl component="fieldset">
                    <FormGroup>
                      {d.possible_options.map((answer /* Male */) => (
                        <FormControlLabel
                          onChange={props.onUpdateDemographic}
                          checked={Boolean(selectedDemographics?.[d.variable]?.includes(answer))}
                          datum={{ answer, demographic: d }}
                          key={answer}
                          label={answer}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </ChipPopover>
              ))}
              <Divider className={classes.divider} />
              <QAFilter
                selectedQAFilterForm={selectedQAFilterForm}
                selectedQAFilterQuestion={selectedQAFilterQuestion}
                selectedQAFilterAnswers={selectedQAFilterAnswers}
                onQAFilterSelectedFormChanged={onQAFilterSelectedFormChanged}
                onQAFilterSelectedQuestionChanged={onQAFilterSelectedQuestionChanged}
                onQAFilterSelectedAnswersChanged={onQAFilterSelectedAnswersChanged}
                onQAFiltersReset={onQAFiltersReset}
              />
            </Box>
          </ChipPopover>
        </>
      )}
    </Box>
  );
};

TopBarSide.propTypes = {
  width: PropTypes.number.isRequired,
  maxHeight: PropTypes.number.isRequired,
  onSelectedDateRangeChanged: PropTypes.func.isRequired,
  selectedDateRange: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number,
    strStart: PropTypes.string,
    strEnd: PropTypes.string
  }),
  selectedDemographics: PropTypes.object.isRequired,
  onUpdateDemographic: PropTypes.func.isRequired,
  selectedQAFilterForm: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selectedQAFilterQuestion: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selectedQAFilterAnswers: PropTypes.arrayOf(PropTypes.string),
  onQAFilterSelectedFormChanged: PropTypes.func.isRequired,
  onQAFilterSelectedQuestionChanged: PropTypes.func.isRequired,
  onQAFilterSelectedAnswersChanged: PropTypes.func.isRequired,
  onQAFiltersReset: PropTypes.func.isRequired
};
TopBarSide.defaultProps = {};

export default React.memo(TopBarSide);
