import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  }
}));

const SortOption = (props) => {
  const classes = useStyles();
  const onDesc = () => props.onSort(props.value, 'desc');
  const onAsc = () => props.onSort(props.value, 'asc');
  return (
    <Box className={classes.box}>
      <Typography>{props.label}</Typography>
      <IconButton color={props.active === 'desc' ? 'secondary' : 'inherit'} onClick={onDesc} size="small">
        <ArrowDropDownIcon />
      </IconButton>
      <IconButton color={props.active === 'asc' ? 'secondary' : 'inherit'} onClick={onAsc} size="small">
        <ArrowDropUpIcon />
      </IconButton>
    </Box>
  );
};

SortOption.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
  active: PropTypes.string
};

SortOption.defaultProps = {
  active: ''
};

export default React.memo(SortOption);
