import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import numeral from 'numeral';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';

// ICONS
import Brightness3Icon from '@material-ui/icons/Brightness3';
import Brightness1Icon from '@material-ui/icons/Brightness1';

// Redux
import { METRICS, signalsActions, signalsSelectors } from './signalsSlice';
import { useDispatch, useSelector } from 'react-redux';

// Graphics
import { LegendQuantile, LegendThreshold } from '@visx/legend';
import * as d3 from 'd3';

// Ours
import { ColorRamps, ColorScaleSelector } from '@premisedata/iris-components';

const LEGEND_WIDTH = 250;
const LEGEND_FORMAT = '0,0';

const useStyles = makeStyles((theme) => ({
  legend: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(2) + 20 /* map attribution height */,
    width: LEGEND_WIDTH,
    minHeight: 50,
    padding: theme.spacing(2, 2, 1, 2)
  },
  colorOptions: {
    width: '100%'
  },
  colorSelector: {
    height: 16,
    width: '100%'
  },
  controlBox: {}
}));

const Legend = ({ hexScale }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Redux
  const colorRamp = useSelector(signalsSelectors.selectColorRamp);
  const colorRampInverted = useSelector(signalsSelectors.selectColorRampInverted);
  const vectorOpacity = useSelector(signalsSelectors.selectVectorOpacity);
  const activeMetric = useSelector(signalsSelectors.selectActiveMetric);

  // Callbacks
  const onColorRampChanged = useCallback((cr) => dispatch(signalsActions.setColorRamp(cr.k)), [dispatch]);
  const onColorRampInvertedToggled = useCallback(() => dispatch(signalsActions.toggleBool('colorRampInverted')), [dispatch]);
  const onCategorizedToggled = useCallback(() => dispatch(signalsActions.toggleBool('categorizedMetric')), [dispatch]);
  const onOpacityChange = useCallback((_, v) => dispatch(signalsActions.setVectorOpacity(v / 100)), [dispatch]);
  const labelFormat = useCallback((d) => numeral(d).format(LEGEND_FORMAT), []);
  const fillValue = useCallback(({ value }) => {
    if (!value) return '#000000';

    return d3.color(value?.rgb).formatHex();
  }, []);
  const customTextLabel = useCallback(
    (label) => (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{ width: '15px', height: '15px', background: `${d3.color(label.value?.rgb ?? '#000000')}`, margin: '2px 4px 2px 0px' }} />
        <div style={{ marginLeft: '4px' }}>{label.value?.text}</div>
      </div>
    ),
    []
  );

  const isACategorizedMetric = useMemo(() => {
    return activeMetric === METRICS.MAX_SIGNAL || activeMetric === METRICS.MEAN_SIGNAL || activeMetric === METRICS.SIGNAL_CHANGE;
  }, [activeMetric]);

  if (!hexScale?.scale) return null;

  return (
    <Paper className={clsx('noselect', classes.legend)}>
      <Box className={classes.colorOptions}>
        <ColorScaleSelector className={clsx(classes.controlBox, classes.colorSelector)} ramp={colorRamp.v} availableRamps={ColorRamps} onRampChange={onColorRampChanged} />
        <Box className={'noselect'}>
          <Typography gutterBottom={true}>Opacity</Typography>
          <Grid container={true} spacing={2}>
            <Grid item={true}>
              <Brightness3Icon />
            </Grid>
            <Grid item={true} xs={true}>
              <Slider step={10} color="secondary" value={vectorOpacity * 100} onChange={onOpacityChange} />
            </Grid>
            <Grid item={true}>
              <Brightness1Icon />
            </Grid>
          </Grid>
        </Box>
        {isACategorizedMetric && <FormControlLabel control={<Checkbox checked={hexScale.categorized} onChange={onCategorizedToggled} />} label="Default Categories" />}
        <FormControlLabel control={<Checkbox checked={colorRampInverted} onChange={onColorRampInvertedToggled} />} label="Invert Colors" />
      </Box>
      {hexScale.categorized && (
        <LegendQuantile scale={hexScale.scale}>
          {(labels) =>
            // override labels to display our own text categories
            labels.map((label) => customTextLabel(label))
          }
        </LegendQuantile>
      )}
      {!hexScale.categorized && <LegendThreshold scale={hexScale.scale} fill={fillValue} labelFormat={labelFormat} />}
    </Paper>
  );
};

Legend.propTypes = {
  hexScale: PropTypes.shape({
    scale: PropTypes.func,
    categorized: PropTypes.bool
  })
};

export default React.memo(Legend);
