import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// REDUX TOOLKIT
import { useDispatch, useSelector } from 'react-redux';
import { demoActions, demoSelectors } from './demographicsSlice';

// UI/UX
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';

// ICONS
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PostAdd from '@material-ui/icons/PostAdd';

// OURS
import QAFilterTabPanel from './QAFilterTabPanel';
import QAFilterCreatePanel from './QAFilterCreatePanel';
import { QUESTION_LOOKUP } from '../../constants';
import ElevatedTabs from '../ElevatedTabs';

// GLOBALS
const useStyles = makeStyles(() => ({
  popover: {
    textTransform: 'capitalize',
    minWidth: '450px',
    maxWidth: '450px'
  }
}));

/**
 *
 * @param chipName This name is used to identify the chip and locate it within the redux demographics slice state.
 * @param label String to be displayed as the chip's label
 * @param classes
 * @returns {JSX.Element}
 * @constructor
 */
const QAFilterChip = ({ chipName, label }) => {
  // Redux Toolkit
  const classes = useStyles();
  const dispatch = useDispatch();

  // Local State
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const open = !!anchorEl;
  const id = open ? chipName : undefined;

  // Selectors
  const isActive = useSelector((state) => demoSelectors.selectConfigChipActiveByName(state, chipName));
  const filterSets = useSelector(demoSelectors.selectQAFilterChipSets);
  const selectedQuestion = useSelector(demoSelectors.selectSelectedQuestion);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  useEffect(() => {
    if (filterSets[selectedQuestion]) {
      // Remove filter set here
      dispatch(demoActions.clickedQAFilterChipDelete({ filterQuestion: selectedQuestion }));
      setActiveTabIndex(0);
    }
  }, [selectedQuestion, filterSets, dispatch]);

  // Render Component
  return (
    <React.Fragment>
      <Chip
        aria-describedby={id}
        variant="outlined"
        color={isActive ? 'secondary' : 'default'}
        label={label}
        deleteIcon={<ArrowDropDownIcon />}
        onClick={handleClick}
        onDelete={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleClose}
      >
        <Box className={classes.popover}>
          <ElevatedTabs
            value={activeTabIndex}
            onChange={(event, newValue) => {
              setActiveTabIndex(newValue);
            }}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab icon={<PostAdd />} />
            {Object.keys(filterSets).map((question) => {
              return <Tab wrapped={true} label={QUESTION_LOOKUP[question]} />;
            })}
          </ElevatedTabs>
          <QAFilterCreatePanel id="panel-0" setActiveTabIndex={setActiveTabIndex} index={0} activeTabIndex={activeTabIndex} />
          {Object.keys(filterSets).map((question, index) => {
            return <QAFilterTabPanel id={`panel-${index + 1}`} setActiveTabIndex={setActiveTabIndex} question={question} index={index + 1} activeTabIndex={activeTabIndex} />;
          })}
        </Box>
      </Popover>
    </React.Fragment>
  );
};

// PROP DEFINITIONS
QAFilterChip.propTypes = {
  chipName: PropTypes.string,
  label: PropTypes.string
};

// EXPORT COMPONENT
export default QAFilterChip;
