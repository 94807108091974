import React, { useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// MODULES
import clsx from 'clsx';
import { BlockPicker } from 'react-color';

// MUI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';

// ICONS
import Brightness3Icon from '@material-ui/icons/Brightness3';
import Brightness1Icon from '@material-ui/icons/Brightness1';

// OURS
import { ColorScaleSelector, ColorRamps } from '@premisedata/iris-components';
import { setSelectedAnswer, setColorRamp, toggleBool, updateColorOverrides, setVectorOpacity } from '../sentimentSlice';
import { colorRampLookup } from '../../ColorRamps';

const useStyles = makeStyles((theme) => ({
  legendHorz: {
    '& .MuiList-dense': {
      display: 'block'
    },
    '& .MuiListItem-dense.MuiListItem-root': {
      display: 'inline-flex',
      width: 'inherit',
      maxWidth: 200,
      maxHeight: 56
    },
    marginBottom: theme.spacing(2),
    '& .MuiListItemText-secondary': {
      maxHeight: 36,
      overflow: 'hidden'
    }
  },
  legendVert: {
    maxWidth: 500,
    minWidth: 280,
    zIndex: 10,
    bottom: 36 + theme.spacing(4) /* Map Attribution height + Legend Toggle Height */,
    right: theme.spacing(1),
    overflowX: 'hidden',
    overflowY: 'auto',
    width: 'calc(50% - 560px)',
    position: 'absolute',
    boxShadow: '0 1px 5px rgba(0,0,0,0.4)',
    maxHeight: `calc(100% - ${20 + 36 + theme.spacing(3)}px)`
    // transition: 'opacity 500ms cubic-bezier(0.755, 0.050, 0.855, 0.060)'
  },
  legend: {
    borderRadius: 5,
    '& .MuiTypography-body2': {
      fontSize: '0.8rem'
    },
    '& .MuiListItemIcon-root': {
      minWidth: 34
    },
    backgroundColor: theme.palette.background.paper
  },
  legendOpen: {
    opacity: 1,
    pointerEvents: 'auto'
  },
  legendClosed: {
    opacity: 0,
    pointerEvents: 'none'
  },
  legendItem: {
    borderRight: `1px solid ${theme.palette.primary.main}`,
    borderLeft: `1px solid ${theme.palette.primary.main}`
  },
  legendItemSel: {
    backgroundColor: theme.palette.background.paper,
    borderRight: `1px solid ${theme.palette.secondary.light}`,
    borderLeft: `1px solid ${theme.palette.secondary.light}`,
    '& .MuiListItemText-primary': {
      color: theme.palette.secondary[theme.palette.itype]
    },
    '& .MuiListItemText-secondary': {
      color: theme.palette.secondary[theme.palette.type]
    }
  },
  colorSelector: {
    height: 32,
    width: '100%'
  },
  controlBox: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0px ${theme.spacing(2)}px`
  }
}));

const Legend = (props) => {
  const { style, horizontal, mode } = props;
  // MUI + REDUX
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const colorRamp = useSelector((state) => state.sentiment.colorRamp);
  const colorRampInverted = useSelector((state) => state.sentiment.colorRampInverted);
  const colorOverrides = useSelector((state) => state.sentiment.colorOverrides);
  const selectedAnswer = useSelector((state) => state.sentiment.selectedAnswer);
  const selectedQuestionsOrdering = useSelector((state) => state.sentiment.selectedQuestionsOrdering);
  const selectedQuestionsOrderingRev = useSelector((state) => state.sentiment.selectedQuestionsOrderingRev);
  const selectedQuestionType = useSelector((state) => state.sentiment.selectedQuestionType);
  const selectedQuestions = useSelector((state) => state.sentiment.selectedQuestions);
  const showTitles = useSelector((state) => state.sentiment.showTitles);
  const vectorOpacity = useSelector((state) => state.sentiment.vectorOpacity);

  // LOCALS
  const ramp = colorRampLookup[colorRamp];

  // STATE
  const [pickerData, setPickerData] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const answerColors = useMemo(() => {
    if (!selectedQuestionsOrdering || !selectedQuestions) return null;
    const question_name = selectedQuestions[0]?.question_name;

    return selectedQuestionsOrdering.reduce((a, { order, label }) => {
      const v = colorRampInverted ? 1 - order : order;
      let c;
      if (question_name && colorOverrides[question_name]?.[label]) {
        c = colorOverrides[question_name][label];
      } else {
        c = ramp(v);
      }
      return {
        ...a,
        [order]: c
      };
    }, {});
  }, [selectedQuestionsOrdering, selectedQuestions, colorRampInverted, colorOverrides, ramp]);

  // CALLBACKS
  const onColorRampChanged = useCallback(
    (cr) => {
      dispatch(setColorRamp(cr.k));
    },
    [dispatch]
  );
  const onColorRampInvertedToggled = useCallback(() => {
    dispatch(toggleBool('colorRampInverted'));
  }, [dispatch]);
  const onShowTitlesToggled = useCallback(() => {
    dispatch(toggleBool('showTitles'));
  }, [dispatch]);
  const onPickerClose = useCallback(() => {
    setPickerData(null);
    setSelectedColor(null);
  }, []);
  const onPickerChange = useCallback(
    ({ hex }) => {
      if (hex === selectedColor || hex === pickerData?.selectedColor) {
        setSelectedColor(null);
        setPickerData(null);
        dispatch(
          updateColorOverrides({
            selectedQuestions,
            answer: pickerData.answer,
            hex: null
          })
        );
      } else {
        setSelectedColor(hex);
        dispatch(
          updateColorOverrides({
            selectedQuestions,
            answer: pickerData.answer,
            hex
          })
        );
      }
    },
    [selectedQuestions, pickerData, selectedColor, dispatch]
  );
  const onAnswerClicked = useCallback(
    (answer) => {
      dispatch(setSelectedAnswer(answer));
    },
    [dispatch]
  );
  const onClickListItemIcon = useCallback(
    (e, answer) => {
      e.preventDefault();
      e.stopPropagation();

      if (selectedQuestionType !== 'categorical') return;

      setPickerData({
        anchor: e.currentTarget,
        answer,
        selectedColor: answerColors[answer.order]
      });
    },
    [selectedQuestionType, answerColors]
  );
  const onOpacityChange = useCallback(
    (_, v) => {
      dispatch(setVectorOpacity(v / 100));
    },
    [dispatch]
  );

  // LOCALS
  const showControls = props.showControls && mode !== 'mini';
  if (!answerColors || !selectedQuestionType) return null;
  return (
    <Box
      style={style}
      className={clsx({
        [classes.legend]: true,
        [classes.legendHorz]: horizontal,
        [classes.legendVert]: !horizontal,
        [classes.legendOpen]: mode !== 'closed',
        [classes.legendClosed]: mode === 'closed'
      })}
    >
      {showControls ? (
        <React.Fragment>
          {selectedQuestionType === 'ordinal' && (
            <ColorScaleSelector className={clsx(classes.controlBox, classes.colorSelector)} ramp={ramp} availableRamps={ColorRamps} onRampChange={onColorRampChanged} />
          )}
          <Box className={classes.controlBox}>
            <Typography gutterBottom={true}>Opacity</Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true}>
                <Brightness3Icon />
              </Grid>
              <Grid item={true} xs={true}>
                <Slider step={10} color="secondary" value={vectorOpacity * 100} onChange={onOpacityChange} />
              </Grid>
              <Grid item={true}>
                <Brightness1Icon />
              </Grid>
            </Grid>
          </Box>
          <Box className={clsx(classes.controlBox, 'noselect')}>
            <FormControlLabel control={<Checkbox checked={showTitles} onChange={onShowTitlesToggled} />} label="Show Title(s)" />
            <FormControlLabel
              control={<Checkbox disabled={selectedQuestionType !== 'ordinal'} checked={colorRampInverted} onChange={onColorRampInvertedToggled} />}
              label="Invert Colors"
            />
          </Box>
        </React.Fragment>
      ) : null}
      {pickerData && (
        <Popover
          onClose={onPickerClose}
          open={true}
          anchorEl={pickerData.anchor}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <BlockPicker
            styles={{
              default: {
                card: {
                  background: theme.palette.background.paper
                },
                head: {
                  height: '64px'
                }
              }
            }}
            color={selectedColor ?? pickerData.selectedColor}
            onChange={onPickerChange}
          />
        </Popover>
      )}
      <List dense={true}>
        {selectedQuestionsOrderingRev.map((d) => (
          <ListItem
            className={clsx({
              [classes.legendItemSel]: d.label === selectedAnswer?.label,
              [classes.legendItem]: true
            })}
            button={true} // TODO ?
            key={d.label}
            onMouseUp={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onAnswerClicked(d);
            }}
          >
            <ListItemIcon
              onClick={(e) => onClickListItemIcon(e, d)}
              style={{
                color: answerColors[d.order]
              }}
            >
              <Brightness1Icon />
            </ListItemIcon>
            <ListItemText
              primary={d.label}
              secondaryTypographyProps={{ component: 'div' }}
              secondary={
                <React.Fragment>{d.responses.join(', ') !== d.label && (mode !== 'mini' || selectedAnswer?.label === d.label) ? d.responses.join(', ') : ''}</React.Fragment>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

Legend.propTypes = {
  mode: PropTypes.oneOf(['open', 'closed', 'mini']),
  style: PropTypes.object,
  horizontal: PropTypes.bool,
  showControls: PropTypes.bool
};

Legend.defaultProps = {
  mode: 'open',
  style: {},
  horizontal: false,
  showControls: true
};

export default React.memo(Legend);
