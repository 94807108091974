import { irisApi } from '../../services';

/**
 * Injects endpoints into the iris Api
 *
 * Add any endpoint definitions to the export object, and refer to the following link for how to
 * use the endpoints via different query formats:
 *
 * <https://redux-toolkit.js.org/rtk-query/api/created-api/hooks#feature-comparison>
 */
export const demographicsApi = irisApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllQuestionsWithAnswers: builder.query({
      query: () => '/demographics/v0/metadata/allQuestionsWithAnswers'
    }),
    getAnswerDistribution: builder.query({
      query: ({ bbox, level, question, answer }) => ({
        url: '/demographics/v0/data/answerDistribution',
        method: 'POST',
        body: {
          bbox,
          level,
          question,
          answer
        }
      }),
      keepUnusedDataFor: 30
    }),
    getAllCountries: builder.query({
      query: () => '/demographics/v0/metadata/allCountries'
    }),
    getBoundedRegions: builder.query({
      query: ({ bbox, level }) => ({
        url: '/demographics/v0/metadata/boundedRegions',
        method: 'POST',
        body: {
          bbox,
          level
        }
      }),
      keepUnusedDataFor: 5
    }),
    getBoundedRegionAnswerInfo: builder.query({
      query: ({ bbox, level, questions }) => ({
        url: '/demographics/v0/metadata/boundedRegions/QA',
        method: 'POST',
        body: {
          bbox,
          level,
          questions
        }
      })
    }),
    getBoundedRegionSummaries: builder.query({
      query: ({ bbox, level, question }) => ({
        url: '/demographics/v0/data/boundedRegionSummaries',
        method: 'POST',
        body: {
          bbox,
          level,
          question
        }
      }),
      keepUnusedDataFor: 30
    }),
    getCountries: builder.query({
      query: () => '/demographics/v0/metadata/countries'
    }),
    getResultSummaries: builder.query({
      query: ({ level, question, gid }) => ({
        url: '/demographics/v0/data/result_summaries',
        method: 'POST',
        body: { level, question, gid }
      })
    }),
    getAnswerData: builder.query({
      query: ({ level, question, gid, answer }) => ({
        url: '/demographics/v0/data/by_answer',
        method: 'POST',
        body: { level, question, gid, answer }
      })
    }),
    getDetails: builder.query({
      query: ({ question, level, gid, gid_0 }) => ({
        url: '/demographics/v0/metadata/details',
        method: 'POST',
        body: { question, level, gid, gid_0 }
      })
    }),
    getSummaryClick: builder.query({
      query: ({ x, y, question, level }) => ({
        url: '/demographics/v0/metadata/summaryClick',
        method: 'POST',
        body: { x, y, question, level }
      })
    }),
    getColors: builder.query({
      query: (body) => ({
        url: '/demographics/v0/metadata/colors',
        method: 'POST',
        body
      })
    }),
    getAgeAndGender: builder.query({
      query: ({ gid_0, gid, level }) => ({
        url: '/demographics/v0/metadata/ageAndGender',
        method: 'POST',
        body: { gid_0, gid, level }
      })
    })
  }),
  overrideExisting: false
});

// Export endpoints
export const {
  getAllQuestionsWithAnswers,
  getAnswerDistribution,
  getAllCountries,
  getBoundedRegions,
  getBoundedRegionAnswerInfo,
  getBoundedRegionSummaries,
  getCountries,
  getResultSummaries,
  getAnswerData,
  getDetails,
  getSummaryClick,
  getColors,
  getAgeAndGender
} = demographicsApi.endpoints;
