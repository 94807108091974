import React from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Modules
import { AutoSizer } from 'react-virtualized';

// UI/UX
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

// Icons
import StopIcon from '@material-ui/icons/Stop';

// Ours
import { demoSelectors, demoActions } from './demographicsSlice';
import { getAnswerDistribution } from './services';
import LegendScale from './LegendScale';

// Globals
const MIN_WIDTH = 250;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(1)}px 0px`,
    minWidth: MIN_WIDTH,
    maxHeight: '200px',
    overflowY: 'auto',
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(3),
    '& > button': {
      display: 'flex',
      borderRadius: 0
    },
    '& .MuiButton-label': {
      justifyContent: 'flex-start'
    }
  },
  selectedContainer: {
    display: 'block',
    width: '100%',
    borderColor: theme.palette.secondary.main,
    borderWidth: '0px 1px',
    borderStyle: 'solid',
    height: 32,
    padding: `0px ${theme.spacing(1)}px`,
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  }
}));

const Legend = () => {
  // REDUX
  const dispatch = useDispatch();

  // LOCAL STATE

  // SELECTORS
  const legendContent = useSelector(demoSelectors.selectLegendContent);
  const choroplethAnswer = useSelector(demoSelectors.selectChoroplethAnswer);
  const answerDistributionParams = useSelector(demoSelectors.answerDistributionParams);

  // QUERIES
  const answerDistributionQuery = getAnswerDistribution.useQueryState(answerDistributionParams, { skip: !answerDistributionParams });

  // Mui
  const classes = useStyles();

  if (legendContent && legendContent.length > 0) {
    return (
      <Paper className={classes.container}>
        {legendContent.map(([answer, color]) => (
          <React.Fragment key={answer}>
            <Button
              fullWidth={true}
              startIcon={<StopIcon transform="scale(2, 1)" style={{ color: color }} />}
              onClick={(e) => {
                e.stopPropagation();
                if (answer === choroplethAnswer) {
                  dispatch(demoActions.setChoroplethAnswer(null));
                } else {
                  dispatch(demoActions.setChoroplethAnswer(answer));
                }
              }}
              onMouseUp={(e) => {
                e.stopPropagation();
              }}
            >
              <Box style={{ width: '100%', display: 'flex', flexdirection: 'row' }}>{answer}</Box>
            </Button>
            {choroplethAnswer === answer && (
              <ButtonBase
                className={classes.selectedContainer}
                component="div"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(demoActions.setChoroplethAnswer(null));
                }}
                onMouseUp={(e) => {
                  e.stopPropagation();
                }}
              >
                {(!answerDistributionQuery.data || answerDistributionQuery.isFetching) && (
                  <AutoSizer>{(dim) => <CircularProgress size={dim.height} color="secondary" />}</AutoSizer>
                )}

                {answerDistributionQuery.data && !answerDistributionQuery.isFetching && <AutoSizer>{(dim) => <LegendScale dimensions={dim} />}</AutoSizer>}
              </ButtonBase>
            )}
          </React.Fragment>
        ))}
      </Paper>
    );
  } else {
    return null;
  }
};

export default Legend;
