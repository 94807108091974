import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import amplitude from 'amplitude-js';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

// OURS
import ConfigPanel from './ConfigPanel';
import ViewTemporal from './ViewTemporal';
import ViewCompare from './ViewCompare';

// REDUX
import { _legacyRestoreState, loadState, dumpState, restoreState, restoreQueryString } from './sentimentSlice';
import { getAvailableForms, getAvailableQuestions } from './services';
import { restoreStateGlobal } from '../../store';
import { setGlobal } from '../../actions';

// OURS: GLOBALS
import { PRODUCT_TEMPORAL, PRODUCT_COMPARE } from './constants';
import { TOP } from '../../config';

const useStyles = makeStyles({
  circularprogress: {
    position: 'absolute',
    top: TOP,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const View = React.forwardRef((_, ref) => {
  const dispatch = useDispatch();
  const viewRef = React.useRef();
  const classes = useStyles();

  // Redux state
  const activeView = useSelector((state) => state.sentiment.activeView);
  const configPanelOpen = useSelector((state) => state.app.configPanelOpen);
  const serializedState = useSelector((state) => state.app.serializedState);
  const initialQueryString = useSelector((state) => state.app.initialQueryString);
  const haveQueryString = initialQueryString.form_id && !isNaN(initialQueryString.question_id) && initialQueryString.hasc;
  let selectedForms;
  let configPanelShouldBeOpen = true;
  let isLegacyState;

  if (serializedState?.state_global) {
    // Support LEGACY State Restore
    isLegacyState = true;
    selectedForms = serializedState.state_view.formSelections.map((d) => ({ form_id: d }));
    configPanelShouldBeOpen = serializedState.state_global?.app_props?.sentimentActivePanel === 'config';
  } else if (serializedState?.productState) {
    // Active State Restore
    isLegacyState = false;
    selectedForms = serializedState.productState.selectedForms.map((d) => ({ form_id: d }));
    configPanelShouldBeOpen = serializedState.globalState.configPanelOpen === true;
  } else if (haveQueryString) {
    selectedForms = [{ form_id: initialQueryString.form_id }];
  } else {
    // Failure, this case `isLegacyState` & `selectedForms` will be undefined,
    // the following `useEffect` hook will catch that scenario and cancel
    // the view restore.
  }
  useEffect(() => {
    if (serializedState && (isLegacyState === undefined || !selectedForms || selectedForms.length === 0)) {
      amplitude.getInstance().logEvent('view_restore_failed', {
        product: 'sentiment',
        hash: serializedState.hash,
        scope_id: serializedState.scope_id,
        read_error: true
      });

      dispatch(
        setGlobal({
          notificationQueue: [{ type: 'error', message: 'Failed to load saved view: malformed state' }],
          serializedState: null
        })
      );
    }
  }, [
    // \n
    dispatch,
    serializedState,
    selectedForms,
    isLegacyState
  ]);

  // Support State Restore: Queries
  const availableForms = getAvailableForms.useQuery({});
  const availableQuestions = getAvailableQuestions.useQuery(selectedForms, { skip: !selectedForms || selectedForms.length === 0 });

  useEffect(() => {
    // componentDidMount()
    if (!serializedState) return;
    if (availableForms.data && availableQuestions.data) {
      const newState = isLegacyState
        ? _legacyRestoreState(serializedState, availableForms.data, availableQuestions.data)
        : restoreState(serializedState, availableForms.data, availableQuestions.data);

      const newStateGlobal = restoreStateGlobal(serializedState);

      if (newState.error) {
        amplitude.getInstance().logEvent('view_restore_failed', {
          product: 'sentiment',
          hash: serializedState.hash,
          scope_id: serializedState.scope_id
        });

        return dispatch(
          setGlobal({
            notificationQueue: [{ type: 'error', message: newState.error }],
            serializedState: null
          })
        );
      }

      // Update this products slice:
      dispatch(loadState(newState));

      amplitude.getInstance().logEvent('view_restored', {
        product: 'sentiment',
        hash: serializedState.hash,
        scope_id: serializedState.scope_id
      });

      // Update global state:
      dispatch(
        setGlobal({
          notificationQueue: [{ type: 'success', message: 'Saved view restored successfully!' }],
          serializedState: null, // load complete, clear it.
          configPanelOpen: configPanelShouldBeOpen,
          ...newStateGlobal
        })
      );
    }
  }, [
    // \n
    dispatch,
    configPanelShouldBeOpen,
    isLegacyState,
    serializedState,
    availableForms.data,
    availableQuestions.data,
    availableForms.isError,
    availableQuestions.isError
  ]);

  useEffect(() => {
    if (!haveQueryString) return;

    const { question_id, form_id, hasc } = initialQueryString;
    if (availableForms.isError || availableForms.data?.length === 0) {
      dispatch(
        setGlobal({
          notificationQueue: [{ type: 'error', message: 'Failed to load form & question, please try again later.' }],
          initialQueryString: {}
        })
      );
      return;
    }
    if (availableQuestions.isError || availableQuestions.data?.length === 0) {
      dispatch(
        setGlobal({
          notificationQueue: [{ type: 'error', message: 'Failed to load questions for form.' }],
          initialQueryString: {}
        })
      );
      return;
    }

    if (availableQuestions.data?.length > 0 && availableForms.data?.length > 0) {
      const question = availableQuestions.data.find((d) => d.id === question_id);
      const form = availableForms.data.find((d) => d.form_id === form_id);

      if (!question || !form) {
        dispatch(
          setGlobal({
            notificationQueue: [{ type: 'error', message: !question ? 'Question no longer available.' : 'Form no longer available' }],
            initialQueryString: {}
          })
        );
        return;
      }

      dispatch(
        setGlobal({
          initialQueryString: {}
        })
      );

      dispatch(
        restoreQueryString({
          question,
          form,
          hasc
        })
      );
    }
  }, [
    // \n
    dispatch,
    initialQueryString,
    haveQueryString,
    selectedForms,
    availableForms.data,
    availableQuestions.data,
    availableQuestions.isError,
    availableForms.isError
  ]);

  React.useImperativeHandle(
    ref,
    () => ({
      buildHashStateObject: (store) => {
        return dumpState(store);
      },
      fitBounds: viewRef.current?.fitBounds,
      setView: viewRef.current?.setView
    }),
    [viewRef]
  );

  // don't mount anything until we have restored state:
  if (serializedState)
    return (
      <Box className={classes.circularprogress}>
        <CircularProgress color="secondary" />
      </Box>
    );

  return (
    <>
      {configPanelOpen && <ConfigPanel />}
      {activeView === PRODUCT_TEMPORAL && <ViewTemporal ref={viewRef} />}
      {activeView === PRODUCT_COMPARE && <ViewCompare ref={viewRef} />}
    </>
  );
});

View.propTypes = {};
View.defaultProps = {};
View.displayName = 'View';
export default React.memo(View);
