import { withStyles, withTheme } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// UI/UX
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

// ICONS
import RotateIcon from '@material-ui/icons/Rotate90DegreesCcw';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

// MODULES
import throttle from 'lodash.throttle';

// ours
import { enlargePhoto as enlargePhotoAction } from '../actions';

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    flexFlow: 'row wrap'
  },
  imgContainer: {},
  img: {
    maxHeight: 'calc(100vh - 64px)',
    maxWidth: 'calc(100vw - 128px)'
  },
  photoGridItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start'
  }
});

class PhotoModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      degrees: 0,
      idx: 0
    };

    // BIND
    this.onClose = this.onClose.bind(this);
    this.rotateImage = this.rotateImage.bind(this);
    this.nextImage = this.nextImage.bind(this);
    this.prevImage = this.prevImage.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.captureClick = this.captureClick.bind(this);

    // THROTTLE
    this.nextImage = throttle(this.nextImage, 333);
    this.prevImage = throttle(this.prevImage, 333);
    this.rotateImage = throttle(this.rotateImage, 333);
  }
  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
    this.nextImage.cancel();
    this.prevImage.cancel();
    this.rotateImage.cancel();
  }
  onKeyDown(e) {
    if (!this.props.photos) return;

    if (e.keyCode === 37) {
      this.prevImage(e);
    } else if (e.keyCode === 39) {
      this.nextImage(e);
    } else if (e.keyCode === 38) {
      this.rotateImage(e);
    } else if (e.keyCode === 40) {
      this.rotateImage(e);
    }
  }
  rotateImage(e) {
    e.stopPropagation();
    e.preventDefault();
    const { degrees } = this.state;
    this.setState({ degrees: (degrees + 90) % 360 });
  }
  nextImage(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setState((s) => ({
      idx: s.idx === this.props.photos.length - 1 ? 0 : s.idx + 1,
      degrees: 0
    }));
  }
  prevImage(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setState((s) => ({
      idx: s.idx === 0 ? this.props.photos.length - 1 : s.idx - 1,
      degrees: 0
    }));
  }
  captureClick(e) {
    e.stopPropagation();
    e.preventDefault();
  }
  onClose() {
    this.props.enlargePhoto();

    this.setState({
      idx: 0
    });
  }
  render() {
    const { classes, photos } = this.props;
    const { degrees, idx } = this.state;
    const multiphoto = Array.isArray(photos);
    const src = multiphoto ? photos[idx] : photos;

    return (
      <Backdrop className={classes.backdrop} open={!!photos} onClick={this.onClose}>
        <Grid container={true} direction="row" justifyContent="center" alignItems="center">
          <Grid item={true}>
            {multiphoto && (
              <IconButton onClick={this.prevImage}>
                <ArrowLeftIcon />
              </IconButton>
            )}
          </Grid>
          <Grid item={true} className={classes.photoGridItem}>
            <img alt="enlarged view" className={classes.img} style={{ transform: `rotate(-${degrees}deg)` }} src={src} onClick={this.captureClick} />
          </Grid>
          <Grid item={true}>
            {multiphoto && (
              <IconButton onClick={this.nextImage}>
                <ArrowRightIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Box className={classes.editContainer}>
          <IconButton onClick={this.rotateImage}>
            <RotateIcon />
          </IconButton>
        </Box>
      </Backdrop>
    );
  }
}

const mapStateToProps = (state) => ({
  photos: state.app.enlargePhotoLink
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      enlargePhoto: enlargePhotoAction
    },
    dispatch
  );

PhotoModal.propTypes = {
  // ui / ux
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  enlargePhoto: PropTypes.func.isRequired,
  photos: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};

PhotoModal.defaultProps = {};

const withRedux = connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
});
export default withRedux(withTheme(withStyles(styles)(PhotoModal)));
