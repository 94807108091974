// essentials
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles, withTheme, alpha } from '@material-ui/core/styles';

// modules
import throttle from 'lodash.throttle';
import { inverse } from 'mgrs';

// ui / ux
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';

// ours
import { uuidv4 } from 'iris-util'; // eslint-disable-line import/no-unresolved
import { API_HOST, genericGet } from 'iris-api'; // eslint-disable-line import/no-unresolved

const styles = (theme) => ({
  tooltip: {
    color: 'red'
  },
  paper: {
    '& > ul': {
      maxHeight: '65vh'
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    color: theme.palette.text.secondary,
    marginLeft: 0,
    width: '100%'
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'relative',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary
  },
  inputRoot: {
    color: 'inherit',
    '& .MuiAutocomplete-endAdornment': {
      top: 'inherit'
    }
  },
  inputInput: {
    padding: `${theme.spacing(1.5, 1.5, 1.5, 0)} !important`,
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%'
  }
});

class GeoAutoComplete extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      id: `geo-ac-${uuidv4()}`,
      loading: false,
      text: '',
      opts: [],
      dynOpts: [],
      error: '',
      errorOpen: false
    };

    // BIND
    this.onSelect = this.onSelect.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onErrorClose = this.onErrorClose.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);

    // THROTTLE
    this.updateOptsThrottled = throttle(this.updateOpts.bind(this), 550, {
      trailing: true,
      leading: true
    });

    // XHR
    this._xhrGeonames = {};

    // REGEX
    this._reLatLon = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;
    this._reMGRS = /^\d\d\w\w\w\d{0,5}\d{0,5}$/;
  }

  componentWillUnmount() {
    this.updateOptsThrottled.cancel();
  }

  showError(error) {
    this.setState({
      error: error,
      errorOpen: true
    });
  }

  onSelect(_, v) {
    this.setState({
      text: '',
      opts: [],
      dynOpts: [],
      loading: false
    });
    this.props.onSelect(v);
  }

  dynamicSearchOptions(v) {
    const { withDynamicSearch, withLatLonSearch, withDropPin, withMGRS } = this.props;
    const dynamicOpts = [];
    const vNoSpaces = v.replace(/\s+/g, '');
    if (withDynamicSearch) {
      dynamicOpts.push({
        type: 'dynamic',
        action: 'near-here',
        name: `Search '${v}' near here`,
        val: v
      });
    }
    if (withLatLonSearch && v.match(this._reLatLon)) {
      dynamicOpts.push({
        type: 'dynamic',
        action: 'zoom-to',
        name: `Zoom to ${v}`,
        val: v
      });
    }
    if (withDropPin && v.match(this._reLatLon)) {
      dynamicOpts.push({
        type: 'dynamic',
        action: 'drop-pin',
        name: `Drop pin at ${v}`,
        val: v
      });
    }
    if (withMGRS && vNoSpaces.match(this._reMGRS)) {
      try {
        const extent = inverse(vNoSpaces);
        if (extent) {
          dynamicOpts.push({
            type: 'dynamic',
            action: 'mgrs',
            name: `🌐  Fit to MGRS Grid: ${vNoSpaces}`,
            val: { extent }
          });
        }
      } catch (_) {
        // pass
      }
    }
    return dynamicOpts;
  }
  updateOpts(v) {
    this._xhrGeonames.cancel && this._xhrGeonames.cancel();
    genericGet(`${API_HOST}/places/v0/metadata/geonames/by-name/${encodeURIComponent(v)}`, this._xhrGeonames, (error, result) => {
      this.setState({
        opts: result ?? [],
        loading: false
      });
    });
  }

  onChange(e) {
    const { dataFnMinLength } = this.props;
    const { value } = e.target;

    if (value.length <= dataFnMinLength) {
      return this.setState({
        loading: false,
        dynOpts: [],
        opts: [],
        text: value
      });
    }

    // real time changes
    this.setState({
      text: value,
      opts: [],
      dynOpts: this.dynamicSearchOptions(value),
      loading: value.length > dataFnMinLength
    });

    this.updateOptsThrottled(value);
  }

  onErrorClose() {
    this.setState({
      error: '',
      errorOpen: false
    });
  }

  onOpen() {
    this.onErrorClose();

    if (this.props.onOpen) {
      this.props.onOpen();
    }
  }

  onClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    const { opts, dynOpts, text, id, loading, error } = this.state;
    const { theme, classes, placeholder, popperPlacement } = this.props;
    return (
      <ClickAwayListener onClickAway={this.onErrorClose}>
        <Tooltip
          className={classes.tooltip}
          onClose={this.onErrorClose}
          open={!!error}
          arrow={true}
          placement="bottom"
          disableFocusListener={true}
          disableHoverListener={true}
          disableTouchListener={true}
          title={
            <React.Fragment>
              <span role="img" aria-label="sheep" style={{ marginRight: theme.spacing(1) }}>
                ⚠️
              </span>
              {error}
              <span role="img" aria-label="sheep" style={{ marginLeft: theme.spacing(1) }}>
                ⚠️
              </span>
            </React.Fragment>
          }
        >
          <div className={classes.search}>
            <Autocomplete
              // fullWidth={true}
              handleHomeEndKeys={true}
              PopperComponent={popperPlacement ? (props) => <Popper {...props} placement={popperPlacement} /> : undefined}
              filterOptions={(d) => d}
              value={null}
              className={classes.paper}
              inputValue={text}
              disableClearable={true}
              id={id}
              options={dynOpts.concat(opts)}
              groupBy={(option) => (option.type === 'dynamic' ? '🤖 ' : '📚 ') + option.type.toUpperCase()}
              getOptionLabel={(option) => option.name}
              onChange={this.onSelect}
              onOpen={this.onOpen}
              onClose={this.onClose}
              renderInput={(params) => (
                <TextField
                  onChange={this.onChange}
                  {...params}
                  // label={label}
                  placeholder={placeholder}
                  variant="standard"
                  className={classes.search}
                  InputProps={{
                    ...params.InputProps,
                    classes: {
                      root: classes.inputRoot,
                      input: classes.inputInput
                    },
                    disableUnderline: true,
                    startAdornment: (
                      <div className={classes.searchIcon}>
                        <SearchIcon />
                      </div>
                    ),
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />
          </div>
        </Tooltip>
      </ClickAwayListener>
    );
  }
}

GeoAutoComplete.propTypes = {
  // essentials
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  // data
  placeholder: PropTypes.string,
  label: PropTypes.string,

  withDynamicSearch: PropTypes.bool,
  withLatLonSearch: PropTypes.bool,
  withDropPin: PropTypes.bool,
  withMGRS: PropTypes.bool,

  popperPlacement: PropTypes.string,
  dataFnMinLength: PropTypes.number,

  // callbacks
  onSelect: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func
};

GeoAutoComplete.defaultProps = {
  placeholder: 'City, Country, or Lat, Lon',
  withDynamicSearch: true,
  withLatLonSearch: true,
  withDropPin: true,
  withMGRS: true,
  dataFnMinLength: 2
};

export default withTheme(withStyles(styles)(GeoAutoComplete));
