import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTheme, makeStyles } from '@material-ui/core/styles';

// modules
import { VariableSizeList } from 'react-window';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// ui/ux
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import ListSubheader from '@material-ui/core/ListSubheader';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// icons
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING
    }
  });
}

const OuterElementContext = React.createContext({});
const OuterElementType = forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});
OuterElementType.displayName = 'OuterElementType';
OuterElementType.propTypes = {};
OuterElementType.defaultProps = {};

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = forwardRef((props, ref) => {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.displayName = 'ListboxComponent';
ListboxComponent.propTypes = {
  children: PropTypes.node
};

const useStyles = makeStyles((theme) => ({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0
    }
  },
  paper: {
    backgroundColor: theme.palette.background.default
  }
}));

const renderGroup = (params) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children
];

function VirtualizedAutocomplete(props) {
  const classes = useStyles();

  const renderOption = (option, { selected }) => (
    <React.Fragment>
      <Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} style={{ marginRight: 8 }} checked={selected} />
      <Typography noWrap={true}>{props.getOptionLabel ? props.getOptionLabel(option) : option}</Typography>
    </React.Fragment>
  );

  const [value, setValue] = useState('');
  const onChange = (e, v) => {
    setValue('');
    props.onChange(e, v);
  };
  return (
    <Autocomplete
      disabled={props.disabled}
      onInputChange={(_, v, r) => {
        if (r !== 'reset') {
          setValue(v);
        }
      }}
      getOptionSelected={props.getOptionSelected}
      filterOptions={props.filterOptions}
      inputValue={value}
      clearOnBlur={false}
      onChange={onChange}
      classes={classes}
      className={props.className}
      disableCloseOnSelect={true}
      disableListWrap={true}
      fullWidth={true}
      groupBy={props.groupBy}
      getOptionLabel={props.getOptionLabel}
      limitTags={props.limitTags}
      ListboxComponent={ListboxComponent}
      multiple={props.multiple}
      options={props.options ?? []}
      renderGroup={renderGroup}
      renderInput={(params) => <TextField {...params} placeholder={props.placeholder} variant={props.variant} label={props.label} />}
      value={props.value ?? []}
      renderOption={props.renderOption ?? renderOption}
    />
  );
}

VirtualizedAutocomplete.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  options: PropTypes.array,
  className: PropTypes.string,
  onChange: PropTypes.func,
  variant: PropTypes.string,
  label: PropTypes.string,
  getOptionLabel: PropTypes.func,
  groupBy: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  renderOption: PropTypes.func,
  filterOptions: PropTypes.func,
  limitTags: PropTypes.number,
  getOptionSelected: PropTypes.func
};

VirtualizedAutocomplete.defaultProps = {
  disabled: false,
  variant: 'standard',
  multiple: true,
  limitTags: 1
};

export default VirtualizedAutocomplete;
