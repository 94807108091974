// essentials
import { withStyles, withTheme } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// modules
import debounce from 'lodash.debounce';
import * as d3 from 'd3';
import { h3SetToMultiPolygon /* , geoToH3 */ } from 'h3-js';
import equal from 'deep-equal';

// ui/ux
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

// ours
import { TOP } from 'iris-config'; // eslint-disable-line import/no-unresolved
import { updateMapPosition } from '../../actions';

// leaflet
import { Map, TileLayer, Marker, LayersControl, GeoJSON } from 'react-leaflet';
import L from 'leaflet';

const styles = (theme) => ({
  map: {
    position: 'absolute',
    right: '0px',
    backgroundColor: theme.palette.background.default,
    height: `calc(100% - ${TOP}px)`, // Top + marginTop
    top: `${TOP}px`,
    '& .leaflet-bar a': {
      backgroundColor: theme.palette.primary.main,
      borderBottom: `1px solid ${theme.palette.text.primary}`,
      color: theme.palette.text.primary
    },
    '& .leaflet-bar a:last-child': {
      borderBottom: 'none'
    }
  },
  popupImage: {
    maxWidth: '100%',
    maxHeight: '300px'
  },
  mapMarker: {
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main
  },
  progress: {
    position: 'absolute',
    right: '0px',
    top: `${TOP}px`,
    zIndex: 1001,
    margin: theme.spacing(2)
  }
});

class LeafletMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentBasemap: 'Mapbox // Streets' };
    // refs
    this._leafletMapRef = React.createRef();

    // callbacks
    this.onMoveEndThrottled = debounce(this.onMoveEnd.bind(this), 1000); // , { trailing: true, leading: false });
    this.onBaselayerchange = this.onBaselayerchange.bind(this);
    let w = 99 >> 3;
    let h = 73 >> 3;
    // data
    this.iconWifi = L.icon({
      iconUrl: '/images/radio-wifi-marker.png',
      iconSize: [w, h], // size of the icon
      iconAnchor: [w >> 1, h] // point of the icon which will correspond to marker's location
    });
    this.iconSelectedWifi = L.icon({
      iconUrl: '/images/radio-wifi-marker-green.png',
      iconSize: [w, h],
      iconAnchor: [w >> 1, h]
    });
    this.iconHoverWifi = L.icon({
      iconUrl: '/images/radio-wifi-marker-blue.png',
      iconSize: [w, h],
      iconAnchor: [w >> 1, h]
    });

    w = 92 >> 3;
    h = 84 >> 3;
    this.iconCell = L.icon({
      iconUrl: '/images/radio-cell-marker.png',
      iconSize: [w, h],
      iconAnchor: [w >> 1, h]
    });
    this.iconSelectedCell = L.icon({
      iconUrl: '/images/radio-cell-marker-green.png',
      iconSize: [w, h],
      iconAnchor: [w >> 1, h]
    });
    this.iconHoverCell = L.icon({
      iconUrl: '/images/radio-cell-marker-blue.png',
      iconSize: [w, h],
      iconAnchor: [w >> 1, h]
    });
    this.pinIcon = L.icon({
      iconUrl: '/images/marker-pin-leaflet.png',
      iconSize: [12, 32],
      iconAnchor: [6, 32]
    });
  }

  fitBounds({ minx, miny, maxx, maxy }) {
    this._leafletMapRef.current &&
      this._leafletMapRef.current.leafletElement.fitBounds([
        [minx, miny],
        [maxx, maxy]
      ]);
  }

  setView(x_lon, y_lat, z) {
    this._leafletMapRef.current && this._leafletMapRef.current.leafletElement.setView([y_lat, x_lon], z || 14);
  }
  componentWillUnmount() {
    this.onMoveEndThrottled.cancel();
  }
  componentDidUpdate(prevProps) {
    if (this._leafletMapRef.current && prevProps.left !== this.props.left) {
      this._leafletMapRef.current.leafletElement.invalidateSize();
    }

    this.onBaselayerchange();
  }
  componentDidMount() {
    this.onMoveEndThrottled();
  }
  onMoveEnd() {
    if (!this._leafletMapRef.current) return;
    const bounds = this._leafletMapRef.current.leafletElement.getBounds();
    const center = this._leafletMapRef.current.leafletElement.getCenter();
    const mapZoom = this._leafletMapRef.current.leafletElement.getZoom();
    const minx = bounds.getWest();
    const miny = bounds.getSouth();
    const maxx = bounds.getEast();
    const maxy = bounds.getNorth();

    const mapCenter = {
      x_lon: center.lng,
      y_lat: center.lat
    };

    const mapPolygon = {
      nw: [minx, maxy],
      ne: [maxx, maxy],
      se: [maxx, miny],
      sw: [minx, miny]
    };

    // don't rebroadcast needlessly:
    //   - caused by geojson vector layer changes
    if (equal(mapPolygon, this.props.mapPolygon)) {
      return;
    }

    this.props.updateMapPosition({ minx, miny, maxx, maxy }, mapCenter, mapZoom, mapPolygon);
  }

  onBaselayerchange(e) {
    const { mapZoom } = this.props;
    const { currentBasemap } = this.state;
    const name = e ? e.name : currentBasemap;

    const div = d3.select('div.leaflet-control-attribution.leaflet-control').classed('ok', true).html('');
    div.append('span').text(`Zoom @ ${mapZoom}`);
    div.append('span').text(' | ');

    if (name.toLowerCase().includes('mapbox')) {
      div.append('a').attr('href', 'https://www.mapbox.com/feedback/').text('© Mapbox');
      div.append('span').text(' | ');
      div.append('a').attr('href', 'http://www.openstreetmap.org/copyright').text('OpenStreetMap');
    } else if (name.toLowerCase().includes('google')) {
      div.append('span').text('Map data ©2019 ');
      div.append('a').attr('href', 'https://google.com/').text('Google');
    }
    div.append('span').text(' | ');
    div.append('a').attr('href', 'https://leafletjs.com').attr('title', 'A JS library for interactive maps').text('Leaflet');

    if (e) this.setState({ currentBasemap: name });
  }
  renderHex() {
    const { radioHexData, radioHexDataKey: dataKey } = this.props;
    if (!radioHexData) return null;

    const geojson = {
      type: 'MultiPolygon',
      coordinates: h3SetToMultiPolygon(
        radioHexData.map((d) => d.hash),
        true
      ).map((multipolygon) =>
        multipolygon.map((polygon) => {
          const longitudes = polygon.map((d) => d[0]);
          let invalid = false;
          for (let i = 1; i < longitudes.length; i++) {
            if (Math.abs(longitudes[i - 1] - longitudes[i]) > 180) {
              invalid = true;
              break;
            }
          }
          if (invalid) {
            // shove all -179.xxxx to > 180.xxxx
            return polygon.map((d) => {
              return d[0] < 0 ? [d[0] + 360, d[1]] : d;
            });
          }
          return polygon;
        })
      )
    };
    return <GeoJSON key={dataKey} data={geojson} style={{ stroke: false, fillColor: 'rgb(224, 78, 57)', fillOpacity: 1.0 }} />;
  }
  renderPoints() {
    const { radioPointData, onSelect, selectedDoc, hoverDoc } = this.props;
    if (!radioPointData) return null;
    let icon, zIndexOffset, isWifi;

    return radioPointData.map((d) => {
      // wifi or cell?
      isWifi = d.id[0] === 'w';

      if (selectedDoc && selectedDoc._id === d.id) {
        // selected?
        icon = isWifi ? this.iconSelectedWifi : this.iconSelectedCell;
        zIndexOffset = 40;
      } else if (hoverDoc && hoverDoc._id === d.id) {
        // hover?
        icon = isWifi ? this.iconHoverWifi : this.iconHoverCell;
        zIndexOffset = 80;
      } else {
        // regular
        icon = isWifi ? this.iconWifi : this.iconCell;
        zIndexOffset = 20;
      }

      return (
        <Marker
          zIndexOffset={zIndexOffset}
          key={d.id}
          position={[d.lat, d.lon]}
          icon={icon}
          onClick={() => {
            onSelect && onSelect(d.id);
          }}
        />
      );
    });
  }
  renderDroppedPin() {
    const { dropPin } = this.props;
    if (!dropPin) return null;
    return dropPin.map(({ lat, lon }) => <Marker key={`${lat}-${lon}`} zIndexOffset={100} position={[lat, lon]} icon={this.pinIcon} />);
  }
  render() {
    // ui / ux
    const { classes, theme, dataLoading, left, mapboxAccessToken } = this.props;
    const mapStyle = {
      width: `calc(100% - ${left}px)`,
      left: left
    };

    // data
    const { mapZoom, mapCenter } = this.props;
    const position = [mapCenter.y_lat, mapCenter.x_lon];

    const url = `https://api.mapbox.com/styles/v1/mapbox/${theme.palette.type}-v10/tiles/{z}/{x}/{y}?access_token=${mapboxAccessToken}`;
    return (
      <React.Fragment>
        <Map
          ref={this._leafletMapRef}
          className={classes.map}
          zoom={mapZoom}
          center={position}
          style={mapStyle}
          onmoveend={this.onMoveEndThrottled}
          zoomControl={false}
          onBaselayerchange={this.onBaselayerchange}
        >
          <LayersControl position="bottomleft">
            <LayersControl.BaseLayer name={'Mapbox // Streets'} checked={true}>
              <TileLayer url={url} />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name={'Google // Satellite'}>
              <TileLayer url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" subdomains={['mt0', 'mt1', 'mt2', 'mt3']} maxZoom={20} />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name={'Google // Satellite Hybrid'}>
              <TileLayer url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" subdomains={['mt0', 'mt1', 'mt2', 'mt3']} maxZoom={20} />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name={'Google // Terrain'}>
              <TileLayer url="http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}" subdomains={['mt0', 'mt1', 'mt2', 'mt3']} maxZoom={20} />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name={'ESRI // Satellite'}>
              <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" maxZoom={17} />
            </LayersControl.BaseLayer>
          </LayersControl>
          {this.renderHex()}
          {this.renderPoints()}
          {this.renderDroppedPin()}
        </Map>
        <Box className={classes.progress}>
          <CircularProgress
            size={20}
            color="secondary"
            style={{
              display: !dataLoading ? 'none' : null
            }}
          />
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  mapboxAccessToken: state.app.mapboxAccessToken,
  mapZoom: state.app.mapZoom,
  mapBounds: state.app.mapBounds,
  mapCenter: state.app.mapCenter,
  mapPolygon: state.app.mapPolygon,
  dropPin: state.app.dropPin
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateMapPosition: (mapBounds, mapCenter, mapZoom, mapPolygon) => dispatch(updateMapPosition(mapBounds, mapCenter, mapZoom, mapPolygon))
  };
};

LeafletMap.propTypes = {
  classes: PropTypes.object.isRequired, // material-ui
  theme: PropTypes.object.isRequired, // material-ui

  // ui / ux
  left: PropTypes.number.isRequired,
  dataLoading: PropTypes.bool,
  mapCenter: PropTypes.object,
  mapboxAccessToken: PropTypes.string,
  mapZoom: PropTypes.number,
  mapPolygon: PropTypes.object,
  dropPin: PropTypes.array,

  // data
  radioPointData: PropTypes.array,
  radioHexData: PropTypes.array,

  // keys
  radioHexDataKey: PropTypes.number,
  radioPointDataKey: PropTypes.number,

  // interaction
  selectedDoc: PropTypes.object, // "on click" selected cell radio object
  hoverDoc: PropTypes.object,

  // callbacks
  onSelect: PropTypes.func,
  updateMapPosition: PropTypes.func
};

LeafletMap.defaultProps = {
  dataLoading: false
};
const withRedux = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
export default withRedux(withTheme(withStyles(styles)(LeafletMap)));
