import React, { forwardRef, useImperativeHandle, useRef } from 'react';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

// REDUX
import { useSelector } from 'react-redux';

// Local
import { LEFT_PANEL_WIDTH } from 'iris-config'; // eslint-disable-line import/no-unresolved
import DeckMap from './DeckMap';
import LeftPanel from '../LeftPanel';
import ConfigPanel from './ConfigPanel';
import { TOP } from '../../config';

const useStyles = makeStyles({
  circularprogress: {
    position: 'absolute',
    top: TOP,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const SignalsView = forwardRef((_, ref) => {
  // MUI
  const classes = useStyles();
  // Refs
  const refMap = useRef(null);
  // Redux
  const configPanelOpen = useSelector((state) => state.app.configPanelOpen);
  const serializedState = useSelector((state) => state.app.serializedState);

  if (serializedState) {
    // TODO
  }

  // forwarded ref
  useImperativeHandle(
    ref,
    () => ({
      buildHashStateObject: () => ({}),
      fitBounds: refMap.current?.fitBounds,
      setView: refMap.current?.setView
    }),
    []
  );

  // don't mount anything until we have restored state:
  if (serializedState)
    return (
      <Box className={classes.circularprogress}>
        <CircularProgress color="secondary" />
      </Box>
    );

  return (
    <>
      <LeftPanel id={'signals-config-panel'} open={configPanelOpen} noSideMargin={true} flex={true}>
        <ConfigPanel />
      </LeftPanel>
      <DeckMap ref={refMap} left={configPanelOpen ? LEFT_PANEL_WIDTH : 0} />
    </>
  );
});

SignalsView.displayName = 'SignalsView';
export default SignalsView;
