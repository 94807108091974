import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

// modules
import debounce from 'lodash.debounce';

// MUI
import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker } from '@material-ui/pickers';

// Ours
import ChipPopover from './ChipPopover';

const ChipDateRangePicker = ({
  label,
  chipClass,
  maxWidth,
  selectedDateRange,
  initialFocusedDateStart,
  initialFocusedDateEnd,
  renderDayStart,
  renderDayEnd,
  onRangeChanged,
  orientation
}) => {
  const notifyParent = useMemo(
    () => debounce((dateRange) => {
      onRangeChanged(dateRange);
    }, 250), [onRangeChanged]
  );
  useEffect(() => {
    // componentDidMount
    return () => {
      // componentWillUnmount
      notifyParent.cancel();
    };
  }, [notifyParent]);

  const onChangeStart = useCallback((start) => {
    // ignore partial inputs
    if (start && !start.isValid()) return;

    notifyParent({
      ...selectedDateRange,
      start: start?.valueOf(),
      strStart: start?.format('YYYY-MM-DD')
    });
  }, [notifyParent, selectedDateRange]);
  const onChangeEnd = useCallback((end) => {
    // ignore partial inputs
    if (end && !end.isValid()) return;

    notifyParent({
      ...selectedDateRange,
      end: end?.valueOf(),
      strEnd: end?.format('YYYY-MM-DD')
    });
  }, [notifyParent, selectedDateRange]);

  return (
    <ChipPopover className={chipClass} active={!!(selectedDateRange?.start ?? selectedDateRange?.end ?? 0)} label={label} maxWidth={maxWidth}>
      <Grid container={true} direction={orientation} spacing={2}>
        <Grid item={true} xs={6}>
          <KeyboardDatePicker
            disableToolbar={true}
            variant="inline"
            format="MM/DD/YYYY"
            margin="normal"
            autoOk={true}
            disableFuture={true}
            label="From"
            initialFocusedDate={initialFocusedDateStart}
            renderDay={renderDayStart}
            value={selectedDateRange?.start ?? null}
            onChange={onChangeStart}
          />
        </Grid>

        <Grid item={true} xs={6}>
          <KeyboardDatePicker
            disableToolbar={true}
            variant="inline"
            format="MM/DD/YYYY"
            margin="normal"
            autoOk={true}
            disableFuture={true}
            label="To"
            initialFocusedDate={initialFocusedDateEnd}
            renderDay={renderDayEnd}
            value={selectedDateRange?.end ?? null}
            onChange={onChangeEnd}
          />
        </Grid>
      </Grid>
    </ChipPopover>
  );
};

ChipDateRangePicker.propTypes = {
  chipClass: PropTypes.string,
  initialFocusedDateEnd: PropTypes.string,
  initialFocusedDateStart: PropTypes.string,
  label: PropTypes.string,
  maxWidth: PropTypes.number,
  onRangeChanged: PropTypes.func.isRequired,
  orientation: PropTypes.string,
  renderDayEnd: PropTypes.func,
  renderDayStart: PropTypes.func,
  selectedDateRange: PropTypes.object
};
ChipDateRangePicker.defaultProps = {
  label: 'Date Range',
  orientation: 'row'
};

export default React.memo(ChipDateRangePicker);
