import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

// modules
import clsx from 'clsx';
import moment from 'moment';

// MUI
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

// MUI: ICONS
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

// OURS
import { genericGet, API_HOST } from 'iris-api'; // eslint-disable-line import/no-unresolved
import { pushSnackbar } from './actions';

const useStyles = makeStyles((theme) => ({
  avatar: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.getContrastText(theme.palette.secondary.main)
  },
  verticalDivider: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5)
  }
}));

const InboxItemExport = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { item, onDelete } = props;

  const xhrDownload = useRef();
  useEffect(() => {
    // componentDidMount
    return () => {
      // componentWillUnmount
      xhrDownload.current?.cancel?.();
    };
  }, []);

  const statusColor = (phase) => {
    if (phase === 'Failed') {
      return theme.palette.error.main;
    } else if (phase === 'Succeeded') {
      return theme.palette.success.main;
    } else {
      return 'inherit';
    }
  };

  const classes = useStyles();

  const onDeleteCallback = useCallback(() => {
    onDelete(item);
  }, [onDelete, item]);

  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Avatar className={clsx({ [classes.avatar]: !item.seen_on })}>
            <CloudDownloadIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`Export #${item.api_uid.substring(0, 7)}`}
          secondaryTypographyProps={{
            component: 'div'
          }}
          secondary={
            <ul>
              <li>
                Status: <span style={{ color: statusColor(item.phase) }}>{item.phase}</span>
              </li>
              {item.reason && (
                <li>
                  Reason: <span style={{ fontStyle: 'italic' }}>{item.reason}</span>
                </li>
              )}

              {item.created_on && <li>Submitted: {moment(item.created_on).fromNow()}</li>}
              {item.created_on && item.completed_on ? <li>Runtime: {moment.duration(moment(item.created_on).diff(moment(item.completed_on))).humanize()}</li> : null}
              {item.phase === 'Succeeded' ? (
                <li>
                  <Link
                    color="secondary"
                    component="button"
                    variant="body2"
                    onClick={() => {
                      xhrDownload.current?.cancel?.();
                      xhrDownload.current = {};
                      genericGet(`${API_HOST}/exports/v0/jobs/${item.api_uid ?? item.uid}`, xhrDownload.current, (e, r) => {
                        if (e) return dispatch(pushSnackbar({ type: 'error', message: 'Failed to download export.' }));

                        const { url } = r;
                        window.location.assign(url);
                      });
                    }}
                  >
                    Download
                  </Link>
                  <span className={classes.verticalDivider}>|</span>
                  <Link
                    color="secondary"
                    component="button"
                    variant="body2"
                    onClick={() => {
                      xhrDownload.current?.cancel?.();
                      xhrDownload.current = {};
                      genericGet(`${API_HOST}/exports/v0/jobs/${item.api_uid ?? item.uid}`, xhrDownload.current, (e, r) => {
                        if (e) return dispatch(pushSnackbar({ type: 'error', message: 'Failed to create export link.' }));

                        navigator.clipboard
                          .writeText(r.url)
                          .then(() => {
                            dispatch(pushSnackbar({ type: 'success', message: 'Link copied! (valid 8 hours)' }));
                          })
                          .catch(() => {
                            dispatch(pushSnackbar({ type: 'error', message: 'Failed to copy export link to clipboard' }));
                          });
                      });
                    }}
                  >
                    Copy Link
                  </Link>
                </li>
              ) : null}
            </ul>
          }
        />

        <IconButton onClick={onDeleteCallback}>
          <CloseIcon />
        </IconButton>
      </ListItem>
      <Divider />
    </>
  );
};

InboxItemExport.propTypes = {
  item: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default React.memo(InboxItemExport);
