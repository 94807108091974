import React, { useCallback, useState, useMemo } from 'react';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// ui/ux
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

// modules
import LeafletLayersIcon from 'leaflet/dist/images/layers.png';

// ours
import { MAP_STYLES_FLAT } from '../constants';

const CONTROL_HEIGHT = 36;

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius
    },
    '& span': {
      fontWeight: 600,
      lineHeight: '1em'
    }
  }
}))(ToggleButtonGroup);

const StyledToggleButton = withStyles((/* theme */) => ({
  label: {
    minWidth: 24
  },
  root: {
    border: 'none'
  }
}))(ToggleButton);

const useStyles = makeStyles((theme) => ({
  control: {
    position: 'absolute',
    zIndex: 100,
    display: 'block',
    cursor: 'pointer'
  },
  layerControl: {
    background: `${theme.palette.background.paper} url(${LeafletLayersIcon})`,
    backgroundSize: '26px 26px',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    borderRadius: 5,
    height: CONTROL_HEIGHT,
    width: CONTROL_HEIGHT,
    boxShadow: '0 1px 5px rgba(0,0,0,0.4)',
    '&:hover': {
      backgroundColor: theme.palette.background.default
    }
  },
  pitchLevelControl: {
    height: CONTROL_HEIGHT
  },
  paper: {
    display: 'flex',
    border: `1px solid ${theme.palette.divider}`,
    flexWrap: 'wrap'
  },
  popoverPaper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexFlow: 'column wrap',
    minWidth: 300
  },
  divider: {
    margin: theme.spacing(1, 0.5)
  }
}));

const DeckglLayerControl = (props) => {
  // MUI
  const theme = useTheme();
  const classes = useStyles();

  // LOCALS
  const { id, anchorOrigin, transformOrigin, disableOptions, currentBasemap, currentLevelToggle, currentPitchToggle, buttons, onBasemapChange, onPitchChange, onLevelChange } =
    props;

  // USE MEMO
  const spacing = theme.spacing;
  const basemapMenuStyle = useMemo(() => {
    if (props.style) return props.style;
    return { bottom: spacing(3), left: spacing(2) };
  }, [props.style, spacing]);

  const buttonsStyle = useMemo(() => {
    if (!basemapMenuStyle) return undefined;
    return {
      ...basemapMenuStyle,
      // overwrite left:
      left: currentBasemap ? basemapMenuStyle.left * 2 + CONTROL_HEIGHT : basemapMenuStyle.left
    };
  }, [basemapMenuStyle, currentBasemap]);

  // STATE
  const [anchorEl, setAnchorEl] = useState(null);

  // USECALLBACK
  const stopPropagation = useCallback((e) => {
    e.stopPropagation();
  }, []);
  const onBasemapMenuClosed = useCallback(
    (e) => {
      const i = e?.target?.value;
      if (!isNaN(i)) onBasemapChange(MAP_STYLES_FLAT[i]);
      setAnchorEl(null);
    },
    [onBasemapChange]
  );

  const onBasemapMenuOpened = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);
  const onPitchChangeCallback = useCallback(
    (_, v) => {
      if (v === '2D') onPitchChange(0);
      else if (v === '3D') onPitchChange(50); // value from src/store/index.js
    },
    [onPitchChange]
  );
  const onLevelChangeCallback = useCallback(
    (_, v) => {
      if (Number.isFinite(v)) onLevelChange(v);
    },
    [onLevelChange]
  );

  return (
    <div onClick={stopPropagation} onMouseUp={stopPropagation} onMouseDown={stopPropagation}>
      {currentBasemap && (
        <React.Fragment>
          <IconButton className={`${classes.layerControl} ${classes.control}`} onClick={onBasemapMenuOpened} style={basemapMenuStyle} />
          <Popover marginThreshold={0} open={!!anchorEl} anchorEl={anchorEl} onClose={onBasemapMenuClosed} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin}>
            <Paper elevation={3} variant="outlined" className={classes.popoverPaper}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Basemap</FormLabel>
                <RadioGroup value={MAP_STYLES_FLAT.indexOf(currentBasemap)} onChange={onBasemapMenuClosed}>
                  {MAP_STYLES_FLAT.map((d, i) => (
                    <FormControlLabel key={d.key} value={i} control={<Radio color="secondary" />} label={d.label} labelPlacement="end" />
                  ))}
                </RadioGroup>
              </FormControl>
            </Paper>
          </Popover>
        </React.Fragment>
      )}
      <div id={id} className={`${classes.pitchLevelControl} ${classes.control}`} style={buttonsStyle}>
        <Paper elevation={0} className={classes.paper}>
          {currentPitchToggle ? (
            <StyledToggleButtonGroup size="small" value={currentPitchToggle} exclusive={true} onChange={onPitchChangeCallback}>
              <StyledToggleButton value="2D" size="small" disabled={disableOptions['2D']}>
                2D
              </StyledToggleButton>
              <StyledToggleButton value="3D" size="small" disabled={disableOptions['3D']}>
                3D
              </StyledToggleButton>
            </StyledToggleButtonGroup>
          ) : null}
          {currentPitchToggle && currentLevelToggle >= 0 ? <Divider flexItem={true} orientation="vertical" className={classes.divider} /> : null}
          {currentLevelToggle >= 0 ? (
            <StyledToggleButtonGroup size="small" value={currentLevelToggle} exclusive={true} onChange={onLevelChangeCallback}>
              <StyledToggleButton value={0} size="small" disabled={disableOptions['L0']}>
                L0
              </StyledToggleButton>
              <StyledToggleButton value={1} size="small" disabled={disableOptions['L1']}>
                L1
              </StyledToggleButton>
              <StyledToggleButton value={2} size="small" disabled={disableOptions['L2']}>
                L2
              </StyledToggleButton>
            </StyledToggleButtonGroup>
          ) : null}
          {(currentLevelToggle >= 0 || currentPitchToggle) && buttons ? <Divider flexItem={true} orientation="vertical" className={classes.divider} /> : null}
          {buttons && (
            <StyledToggleButtonGroup size="small" exclusive={false} onChange={onLevelChangeCallback}>
              {buttons.map((d) => (
                <Tooltip key={d.elementId} title={d.tooltip}>
                  <span>
                    <StyledToggleButton value={d.value} size="small" selected={d.value} onClick={d.action} disabled={d.disabled}>
                      {d.display}
                    </StyledToggleButton>
                  </span>
                </Tooltip>
              ))}
            </StyledToggleButtonGroup>
          )}
        </Paper>
      </div>
    </div>
  );
};

DeckglLayerControl.propTypes = {
  id: PropTypes.string,

  // have defaults:
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  disableOptions: PropTypes.object, // e.g. disable 2D, disable L0

  currentBasemap: PropTypes.object,
  currentLevelToggle: PropTypes.number,
  currentPitchToggle: PropTypes.string,
  style: PropTypes.object,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      elementId: PropTypes.string.isRequired,
      value: PropTypes.bool.isRequired,
      action: PropTypes.func.isRequired,
      display: PropTypes.node.isRequired,
      tooltip: PropTypes.string.isRequired
    })
  ),

  // callbacks
  onPitchChange: PropTypes.func,
  onLevelChange: PropTypes.func,
  onBasemapChange: PropTypes.func
};

DeckglLayerControl.defaultProps = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  disableOptions: {}
};

export default React.memo(DeckglLayerControl);
