// essentials
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

// modules
import moment from 'moment';
import { capitalCase } from 'change-case';
import { alpha } from '@material-ui/core/styles/colorManipulator';

// ui / ux
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Tooltip from '@material-ui/core/Tooltip';

// ours
import { API_HOST } from 'iris-api'; // eslint-disable-line import/no-unresolved

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(1),
    zIndex: 0,
    position: 'relative'
  },

  paperBox: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    borderRadius: 4,
    top: 0,
    left: 0,
    zIndex: 10
  },

  enableHover: {
    '&:hover': {
      backgroundColor: `${alpha(theme.palette.secondary.dark, 0.1)} !important`,
      border: `1px solid ${alpha(theme.palette.secondary.dark, 1.0)} !important`
    }
  },
  image: {
    width: '100%',
    height: 112
  },
  img: {
    margin: 'auto',
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 4
  },
  grid: {
    zIndex: 20,
    position: 'relative',
    pointerEvents: 'none'
  },
  poiLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  pseudoIconBtn: {
    padding: 3,
    flex: '0 0 auto',
    display: 'inline-flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'middle'
  }
});

class PlacePaper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imgBroken: false
    };

    this.mouseOut = this.mouseOut.bind(this);
    this.mouseIn = this.mouseIn.bind(this);
    this.click = this.click.bind(this);
  }

  click(e) {
    this.props.onClick(this.props.place, e.shiftKey);
  }
  mouseOut() {
    this.props.onMouseOut();
  }
  mouseIn() {
    this.props.onMouseIn(this.props.place);
  }

  // going above & beyond here because of how we fetch data,
  // its super possible for this `place` prop to be the same
  // place, but different memory reference because we refresh
  // data frequently ~
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.imgBroken !== this.state.imgBroken ||
      nextProps.place.place_id !== this.props.place.place_id ||
      nextProps.active !== this.props.active ||
      nextProps.theme !== this.props.theme ||
      nextProps.disabled !== this.props.disabled
    );
  }

  conditonalDateSpanRender() {
    const { place } = this.props;
    const { first_seen, last_seen } = place;

    const firstSeen = moment(first_seen).format('YYYY-MM-DD');
    const lastSeen = moment(last_seen).format('YYYY-MM-DD');

    // ref https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
    if (firstSeen !== lastSeen) {
      return <Typography variant="body2" display="block" noWrap={true}>{`${firstSeen} \u2194 ${lastSeen}`}</Typography>;
    } else {
      return (
        <Typography variant="body2" display="block" noWrap={true}>
          {firstSeen}
        </Typography>
      );
    }
  }
  renderOSM() {
    // first_seen: "2021-04-07T20:27:37.618Z"
    // last_seen: "2021-04-07T20:27:37.618Z"
    // num_submissions: "1"
    // photo: null
    // place_id: "1093819196"
    // place_name: "Landesinstitut Für Rechtsmedizin"
    // topic: "government_building"

    const { theme, classes, place } = this.props;
    return (
      <React.Fragment>
        <Box className={classes.poiLabel}>
          <Box className={classes.pseudoIconBtn}>
            <img src="/images/osm-logo.svg" style={{ height: 24, width: 24 }} />
          </Box>
          <Typography variant="h6" noWrap={true} style={{ marginLeft: theme.spacing(0.5) }}>
            {place.place_name}
          </Typography>
        </Box>

        <Typography variant="body2" noWrap={true}>
          {`${capitalCase(place.topic)} · ${place.num_submissions}`}
        </Typography>
        {this.conditonalDateSpanRender()}
      </React.Fragment>
    );
  }
  renderInternal() {
    const { theme, classes, place } = this.props;
    return (
      <React.Fragment>
        <Box className={classes.poiLabel}>
          <Box className={classes.pseudoIconBtn}>
            <img crossOrigin="anonymous" src={`${API_HOST}/uploads/v0/icons/premise/${encodeURIComponent('#FF5B4A')}`} style={{ height: 24, width: 24 }} />
          </Box>
          <Typography variant="h6" noWrap={true} style={{ marginLeft: theme.spacing(0.5) }}>
            {place.place_name}
          </Typography>
        </Box>

        <Typography variant="body2" noWrap={true}>{`${capitalCase(place.topic)} · ${place.num_submissions}`}</Typography>
        {this.conditonalDateSpanRender()}
      </React.Fragment>
    );
  }
  renderUser() {
    const { theme, classes, place, userdataLayerMetadata } = this.props;
    if (!userdataLayerMetadata) {
      console.warn(`(placepaper) attempt to render user based "${place.type}" place failed: ${JSON.stringify(place)}`);
      return null;
    }
    const name = place.place_name ?? place.name;
    return (
      <React.Fragment>
        <Box className={classes.poiLabel}>
          <Box className={classes.pseudoIconBtn}>
            <img
              crossOrigin="anonymous"
              src={`${API_HOST}/uploads/v0/icons/${userdataLayerMetadata.icon}/${encodeURIComponent(userdataLayerMetadata.color)}`}
              style={{ height: 24, width: 24 }}
            />
          </Box>
          <Typography variant="h6" noWrap={true} style={{ marginLeft: theme.spacing(0.5) }}>
            {name ?? userdataLayerMetadata.name}
          </Typography>
        </Box>
      </React.Fragment>
    );
  }
  _render() {
    const { place } = this.props;
    if (place.type === 'osm') {
      return this.renderOSM();
    } else if (place.type === 'internal') {
      return this.renderInternal();
    } else {
      return this.renderUser();
    }
  }
  render() {
    const { imgBroken } = this.state;
    const { active, classes, disabled, enableHover, id, invertColors, onClick, onMouseIn, onMouseOut, place, style, theme, tooltip, userdataLayerMetadata } = this.props;
    if (!place) return null;

    // enable hover only if
    const willHover = !active && !disabled && enableHover && (onClick || onMouseIn);

    // change the background based on the `active` prop:
    const activeBackgroundColor = active ? alpha(theme.palette.secondary.main, 0.4) : null;
    const activeTextColor = active ? theme.palette.getContrastText(activeBackgroundColor) : null;

    // change the background color based on the `invertColors` prop:
    const backgroundColor = invertColors ? theme.palette.background.default : theme.palette.background.paper;
    const borderColor = active ? theme.palette.secondary.main : backgroundColor;

    // userdata will be numeric:
    const userDefinedPlace = !isNaN(place.type);

    // check for place.photo, don't display if partial metadata:
    const hasWorkingPhoto = place.photo && !imgBroken && (!userDefinedPlace || (userDefinedPlace && userdataLayerMetadata));

    const paper = (
      <Paper
        id={id}
        style={{
          border: `1px solid ${borderColor}`,
          backgroundColor: active ? activeBackgroundColor : backgroundColor,
          color: activeTextColor,
          cursor: willHover ? 'pointer' : null,
          opacity: disabled ? 0.5 : 1.0,
          ...style, // this is here for a reason:
          height: 128 // for use w/ react-window
        }}
        className={clsx(classes.paper, willHover && classes.enableHover)}
        onMouseOut={!disabled && onMouseOut ? this.mouseOut : null}
        onMouseOver={!disabled && onMouseIn ? this.mouseIn : null}
        onClick={!active && !disabled && onClick ? this.click : null}
      >
        <Grid container={true} direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1} className={classes.grid}>
          <Grid item={true} xs={hasWorkingPhoto ? 8 : 12} className="noselect nopointerevents">
            {this._render()}
          </Grid>
          {hasWorkingPhoto && (
            <Grid item={true} xs={4} className="noselect nopointerevents">
              <ButtonBase className={classes.image} disableRipple={true}>
                <img
                  onError={() => {
                    this.setState({
                      imgBroken: true
                    });
                  }}
                  className={classes.img}
                  alt="complex"
                  src={place.photo}
                />
              </ButtonBase>
            </Grid>
          )}
        </Grid>
      </Paper>
    );

    if (tooltip && !disabled && !active) {
      return (
        <Tooltip title={tooltip} placement="top" arrow={true}>
          {paper}
        </Tooltip>
      );
    } else {
      return paper;
    }
  }
}

PlacePaper.propTypes = {
  // essentials
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  style: PropTypes.object,

  // data
  place: PropTypes.object.isRequired,
  id: PropTypes.string,
  tooltip: PropTypes.string,

  // callbacks
  onClick: PropTypes.func,
  onMouseIn: PropTypes.func,
  onMouseOut: PropTypes.func,

  active: PropTypes.bool,
  enableHover: PropTypes.bool,
  disabled: PropTypes.bool,
  invertColors: PropTypes.bool,

  userdataLayerMetadata: PropTypes.shape({
    color: PropTypes.string,
    icon: PropTypes.string,
    name: PropTypes.string
  })
};

PlacePaper.defaultProps = {
  invertColors: false,
  active: false,
  enableHover: true,
  disabled: false,
  style: {}
};

export default withTheme(withStyles(styles)(PlacePaper));
