import { withStyles, withTheme, alpha } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';

// modules
import { AutoSizer } from 'react-virtualized';

// ui/ux
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// icons
import PlaceIcon from '@material-ui/icons/Place';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import WifiIcon from '@material-ui/icons/Wifi';

// Ours
import { API_HOST, genericGet } from 'iris-api'; // eslint-disable-line import/no-unresolved
import { NewPlacesTree } from '@premisedata/lib-iris-common';

const styles = (theme) => ({
  modal: {
    outline: 0,
    '& > *': {
      outline: 0
    }
  },
  paper: {
    left: '50%',
    top: '50%',
    padding: theme.spacing(2),
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    minWidth: '60vw',
    minHeight: '60vh',
    maxHeight: '80vh',
    maxWidth: '80vw'
  },
  tabPanel: {
    paddingBottom: theme.spacing(3),
    position: 'relative'
  },
  btnDateBox: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'baseline',
    '& .MuiFormControl-root': {
      paddingLeft: theme.spacing(1)
    }
  },
  treeBox: {
    width: '100%',
    height: '60vh'
  }
});

class Splash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      interval: 90,
      closed: false,
      type: 'new'
    };

    // XHR
    this._xhr = {};

    // BIND
    this.onClose = this.onClose.bind(this);
    this.onIntervalChange = this.onIntervalChange.bind(this);
    this.onTypeChanged = this.onTypeChanged.bind(this);

    this.circleStroke = alpha(props.theme.palette.secondary.main, 1.0);
    this.tickLabelProps = function () {
      return {
        fill: props.theme.palette.secondary.main,
        fontSize: 12,
        fontFamily: 'sans-serif',
        textAnchor: { bottom: 'middle', left: 'end', right: 'start' }[this]
      };
    };
  }
  loadData(callback) {
    const { interval } = this.state;
    this._xhr.cancel && this._xhr.cancel();
    genericGet(`${API_HOST}/iris/v0/profile/splash/${interval}`, this._xhr, (e, data) => {
      if (e) return callback(e);
      callback(null, data);
    });
  }
  componentDidMount() {
    this.loadData((e, data) => {
      if (!e) this.setState({ data });
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const { interval: prevInterval } = prevState;
    const { interval } = this.state;

    if (interval !== prevInterval) {
      this.loadData((e, data) => {
        if (!e) this.setState({ data });
      });
    }
  }
  onClose() {
    this.setState({
      closed: true
    });
    this.props.onClose?.();
  }
  onTypeChanged(e) {
    const type = e.target.value;
    if (this.state.type !== type) {
      this.setState({ type });
    }
  }
  onIntervalChange(e) {
    const interval = e.target.value;
    if (this.state.interval !== interval) {
      this.setState({ interval });
    }
  }

  render() {
    const { classes, user } = this.props;
    const { interval, closed, data, type } = this.state;

    if (closed || !data) return null;
    const canRenderRadio = !!data.radio;
    const canRenderPlaces = !!data.places;
    if (!canRenderRadio && !canRenderPlaces) return null;
    let placesVerbiage, wifiVerbiage, cellVerbiage;

    if (canRenderRadio) {
      const w_b = data.radio.firstSeen.wifi;
      const w_n = data.radio.lastSeen.wifi;
      const c_b = data.radio.firstSeen.cell;
      const c_n = data.radio.lastSeen.cell;

      const wifiDiff = w_n - w_b;
      const cellDiff = c_n - c_b;

      if (wifiDiff) {
        const wifi_pctChange = numeral(wifiDiff / w_b).format('0.0%');

        // prettier-ignore
        wifiVerbiage = `Access points ${wifiDiff >= 0 ? 'increased' : 'decreased'}` + ' ' +
        `${wifi_pctChange === '0.0%' && wifiDiff !== 0 ? '' : ' ' + wifi_pctChange}` +
        `${wifiDiff !== 0 ? ` from ${numeral(w_b).format(',')} to ${numeral(w_n).format(',')}.` : '.'}`;
      } else if (canRenderRadio) {
        wifiVerbiage = `No change in the number of wireless access points over the past ${interval} days. As of today, there are ${numeral(w_n).format(',')}.`;
      }

      if (cellDiff) {
        const cell_pctChange = numeral(cellDiff / c_b).format('0.0%');
        // prettier-ignore
        cellVerbiage = `Towers ${cellDiff >= 0 ? 'increased' : 'decreased'}` + ' ' +
        `${cell_pctChange === '0.0%' && cellDiff !== 0 ? '' : ' ' + cell_pctChange}` +
        `${cellDiff !== 0 ? ` from ${numeral(c_b).format(',')} to ${numeral(c_n).format(',')}.` : '.'}`;
      } else if (canRenderRadio) {
        cellVerbiage = `No change in the number of cellular towers over the past ${interval} days. As of today, there are ${numeral(c_n).format(',')}.`;
      }
    }

    let placesDiff = 0;
    if (canRenderPlaces) {
      placesDiff = Object.values(data.places[type]).reduce((a, b) => a + b, 0);
      if (placesDiff) {
        const estPrev = data.places.total - placesDiff;
        const pctChange = numeral(placesDiff / estPrev).format('0.0%');
        // prettier-ignore
        placesVerbiage = `Places of interest ${placesDiff >= 0 ? 'increased' : 'decreased'}` +
        `${pctChange === '0.0%' && placesDiff !== 0 ? '' : ' ' + pctChange}` +
        `${placesDiff !== 0 ? ` from ${numeral(estPrev).format(',')} to ${numeral(data.places.total).format(',')}.` : '.'}`;
      } else {
        // prettier-ignore
        placesVerbiage = `No changes in the number of places of interest over the past ${interval} days. ` +
        `${'As of today, there are ' + numeral(data.places.total).format(',')}.`;
      }
    }

    const since = moment(data.cacheAge).subtract(interval, 'days');
    return (
      <Modal className={classes.modal} open={true} onClose={this.onClose}>
        <Paper className={classes.paper}>
          <Typography variant="h4">{`Welcome back${!user.name ? '' : `, ${user.name}`}`}</Typography>
          <Box className={classes.btnDateBox}>
            <Typography variant="body1">Here's a summary of</Typography>
            <FormControl color="secondary" variant="standard">
              <Select value={type} onChange={this.onTypeChanged}>
                <MenuItem value="new">new data</MenuItem>
                <MenuItem value="updated">updated data</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="body1">in the past</Typography>
            <FormControl color="secondary" variant="standard">
              <Select value={interval} onChange={this.onIntervalChange}>
                <MenuItem value={7}>7 days</MenuItem>
                <MenuItem value={30}>30 days</MenuItem>
                <MenuItem value={90}>90 days</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <List dense={true}>
            {cellVerbiage && wifiVerbiage && (
              <React.Fragment>
                <ListItem>
                  <ListItemIcon>
                    <WifiIcon />
                  </ListItemIcon>
                  <ListItemText primary="Wifi" secondary={wifiVerbiage} />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <SignalCellularAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Cell" secondary={cellVerbiage} />
                </ListItem>
              </React.Fragment>
            )}
            {placesVerbiage && (
              <ListItem>
                <ListItemIcon>
                  <PlaceIcon />
                </ListItemIcon>
                <ListItemText primary="Places" secondary={placesVerbiage} />
              </ListItem>
            )}
          </List>
          {placesDiff > 0 && (
            <React.Fragment>
              <Typography variant="body1">{`Major changes in places of interest by topic, since ${since.format('dddd, MMMM D, YYYY')}`}</Typography>
              <Box className={classes.treeBox}>
                <AutoSizer>{(dim) => <NewPlacesTree cacheAge={data.cacheAge} dataKey={type} data={data.places} {...dim} />}</AutoSizer>
              </Box>
            </React.Fragment>
          )}
        </Paper>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.app.user
});

const mapDispatchToProps = (/* dispatch */) => {
  return {};
};

Splash.propTypes = {
  // ui / ux
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  user: PropTypes.object,

  // callback
  onClose: PropTypes.func
};

Splash.defaultProps = {};

const withRedux = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
export default withRedux(withTheme(withStyles(styles)(Splash)));
