// essentials
import { withTheme, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

// ui / ux
import Box from '@material-ui/core/Box';

// ours
import SortSubmissions from './SortSubmissions';
import SortRejected from './SortRejected';
import { placesNearPlace, API_HOST, genericPost } from 'iris-api'; // eslint-disable-line import/no-unresolved
import { pairsToAssociations } from 'iris-util'; // eslint-disable-line import/no-unresolved

const styles = (theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    position: 'absolute',
    padding: 10,
    zIndex: 1300,
    opacity: 1.0,
    userSelect: 'none',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    '& .MuiTooltip-popper': {
      zIndex: 1400
    }
  },
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 1200
  }
});

class ValidationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      accepted: [],
      rejected: []
    };

    this.onContinue = this.onContinue.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onFinish = this.onFinish.bind(this);

    // XHR
    this._xhrValidation = {};
  }

  renderLoading() {
    return null;
  }

  onBack() {
    this.setState((s) => ({
      page: s.page - 1
    }));
  }

  onContinue(accepted, rejected) {
    const { selectedPlace } = this.props;
    // TODO:
    // if no rejected, skip to final step
    if (this.state.page === 0) {
      this.setState({
        page: 1,
        rejected,
        accepted
      });

      placesNearPlace(selectedPlace, (e, nearbyPlaces) => {
        if (nearbyPlaces) {
          // filter out the place we are evaluating:
          nearbyPlaces = nearbyPlaces.filter((d) => d.place_id !== selectedPlace.place_id);

          this.setState({
            nearbyPlaces
          });
        } else {
          // TODO
        }
      });
    } else {
      // TODO
    }
  }
  onFinish({ unknown, discarded, pairs /* { sub_id : place_id } */ }, callback) {
    const { selectedPlace } = this.props;
    const associations = pairsToAssociations(pairs);
    associations.push({
      place_id: selectedPlace.place_id,
      submission_ids: this.state.accepted.map((d) => d.sub_id)
    });

    this._xhrValidation.cancel?.();
    genericPost(`${API_HOST}/places/v0/place-harmonizer/validation`, { unknown, destroyed: discarded, associations }, this._xhrValidation, (e, r) => {
      if (e) return callback(e);
      else this.props.pushSnackbar({ type: 'success', message: `"${selectedPlace.place_name}" validation successfully recorded.` });
      this.props.onComplete(r);
    });
  }
  renderPage() {
    const { page, accepted, rejected } = this.state;
    const { submissions, onCancel, selectedPlace } = this.props;
    if (page === 0) {
      return <SortSubmissions submissions={submissions} onCancel={onCancel} place={selectedPlace} onContinue={this.onContinue} accepted={accepted} rejected={rejected} />;
    } else if (page === 1) {
      return <SortRejected rejectedSubmissions={rejected} onCancel={this.props.onCancel} nearbyPlaces={this.state.nearbyPlaces} onBack={this.onBack} onFinish={this.onFinish} />;
    } else {
      return null;
    }
  }
  render() {
    const { classes, submissions, selectedPlace } = this.props;

    if (!submissions || !selectedPlace) {
      return this.renderLoading();
    }

    return (
      <React.Fragment>
        <Box className={classes.backdrop} />
        <Box className={classes.container}>{this.renderPage()}</Box>
      </React.Fragment>
    );
  }
}

ValidationModal.propTypes = {
  // ui / ux
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  top: PropTypes.number.isRequired,

  // callbacks
  onCancel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  pushSnackbar: PropTypes.func.isRequired,

  // data
  submissions: PropTypes.array,
  selectedPlace: PropTypes.object
};

ValidationModal.defaultProps = {};

export default withTheme(withStyles(styles)(ValidationModal));
