import googlePalette from 'google-palette';
import { ColorRamps } from '@premisedata/iris-components';

const buildConfig = function () {
  const { selectedQuestions, formSelections, geographies, polygonRenderLevel } = this.props;
  const { demographicsLeft, demographicsRight, dateRanges, focusGroup, qaFilterLeft, qaFilterRight } = this.state;
  const question_list = Object.keys(selectedQuestions);
  const hascs = geographies.map((d) => d.hasc);

  const config = {
    left: {
      focusGroup,
      geotype: 'actual',
      confidence: 0.95,
      level: polygonRenderLevel,
      form_list: formSelections.map((d) => d.form_id),
      question_list,
      demographics: demographicsLeft,
      hascs
      // ordering: {},
      // time_break
      // also
    },
    right: {
      focusGroup,
      geotype: 'actual',
      confidence: 0.95,
      level: polygonRenderLevel,
      form_list: formSelections.map((d) => d.form_id),
      question_list,
      ordering: {},
      demographics: demographicsRight,
      hascs
    }
  };

  const selectedQuestionsArr = Object.values(selectedQuestions);
  const orderMap = {};
  const ordering = [];

  // temporary fix for when q.ordering reappears
  for (const q of selectedQuestionsArr) {
    for (const [label, order] of Object.entries(q.ordering)) {
      if (order === null) {
        continue;
      }
      if (orderMap[order]) {
        orderMap[order].responses.push(label);
      } else {
        const newMap = {
          label,
          order,
          responses: [label]
        };
        ordering.push(newMap);
        orderMap[order] = newMap;
      }
    }
  }
  ordering.sort((a, b) => {
    if (a.order === b.order) return 0;
    if (a.order > b.order) return 1;
    return -1;
  });
  config.right.ordering = config.left.ordering = ordering;

  // whether right-side has response filter active, check for keys in:
  // - `demographicsRight`
  // - `qaFilterRight`
  // - ... and a few lines down `dateRanges``
  const rightDemographics = Object.values(demographicsRight)
    .map((d) => d && d.length)
    .reduce((a, b) => a + (b ?? 0), 0);
  let rightConfigured = (qaFilterRight && Object.keys(qaFilterRight).length > 0) || rightDemographics > 0;

  if (dateRanges) {
    if (dateRanges.leftStart && dateRanges.leftEnd) {
      config.left.time_break = [dateRanges.leftStart, dateRanges.leftEnd];
    }
    if (dateRanges.rightStart && dateRanges.rightEnd) {
      config.right.time_break = [dateRanges.rightStart, dateRanges.rightEnd];
      rightConfigured = true;
    }
  }

  if (qaFilterLeft && Object.keys(qaFilterLeft).length) {
    config.left.also = [
      {
        form_id: qaFilterLeft.qaFilterForm.form_id,
        form_name: qaFilterLeft.qaFilterForm.form_name,
        question_name: qaFilterLeft.qaFilterQuestion.question_name,
        question_proper: qaFilterLeft.qaFilterQuestion.question_proper,
        response_proper: qaFilterLeft.qaFilterResponses
      }
    ];
  }
  if (qaFilterRight && Object.keys(qaFilterRight).length) {
    config.right.also = [
      {
        form_id: qaFilterRight.qaFilterForm.form_id,
        form_name: qaFilterRight.qaFilterForm.form_name,
        question_name: qaFilterRight.qaFilterQuestion.question_name,
        question_proper: qaFilterRight.qaFilterQuestion.question_proper,
        response_proper: qaFilterRight.qaFilterResponses
      }
    ];
  }
  if (!rightConfigured) {
    delete config.right;
  }

  return config;
};

const buildConfigTemporal = function (polygonRenderLevel, formSelections, selectedQuestions, dateRanges, focusGroup, alert_settings, geographies) {
  const self = {
    props: {
      polygonRenderLevel,
      selectedQuestions,
      formSelections,
      geographies
    },
    state: {
      demographicsLeft: {},
      demographicsRight: {},
      focusGroup
    }
  };

  if (dateRanges) {
    self.state.dateRanges = {
      leftStart: dateRanges.start.format('YYYY-MM-DD'),
      leftEnd: dateRanges.end.format('YYYY-MM-DD')
    };
  }

  const config = buildConfig.call(self);
  config.left.alert_settings = alert_settings;
  return config;
};

const groupColorsFromConfig = (selectedQuestionType, config, colorRamp, colorRampRight, colorRampInverted, colorRampInvertedRight, sentimentColorOverrides) => {
  if (!config) return {};
  if (!sentimentColorOverrides) {
    sentimentColorOverrides = JSON.parse(window.localStorage.sentimentColorOverrides ?? '{}');
  }

  if (selectedQuestionType === 'categorical') {
    const mpnLeft = googlePalette('mpn65', config.left.ordering.length);
    colorRamp = (v, i) => '#' + mpnLeft[i];
  }

  const lq = config.left.question_list[0];

  const out = {};
  out.groupColors = config.left.ordering
    .sort((a, b) => {
      if (a.order === b.order) return 0;
      if (a.order > b.order) return 1;
      return -1;
    })
    .map((d, i) => ({
      label: d.label,
      color: sentimentColorOverrides[lq] && sentimentColorOverrides[lq][d.label] ? sentimentColorOverrides[lq][d.label] : colorRamp(colorRampInverted ? 1 - d.order : d.order, i)
    }))
    .reduce((a, b) => ({ ...a, [b.label]: b.color }), {});

  if (config.right) {
    if (selectedQuestionType === 'categorical') {
      const mpnRight = googlePalette('mpn65', config.right.ordering.length);
      colorRampRight = (v, i) => '#' + mpnRight[i];
    }
    const rq = config.right.question_list[0];
    out.groupColorsRight = config.right.ordering
      .sort((a, b) => {
        if (a.order === b.order) return 0;
        if (a.order > b.order) return 1;
        return -1;
      })
      .map((d, i) => ({
        label: d.label,
        color:
          sentimentColorOverrides[rq] && sentimentColorOverrides[rq][d.label]
            ? sentimentColorOverrides[rq][d.label]
            : colorRampRight(colorRampInvertedRight ? 1 - d.order : d.order, i)
      }))
      .reduce((a, b) => ({ ...a, [b.label]: b.color }), {});
  }
  return out;
};

const buildGroupColors = (question_name, ordering, colorRamp, colorRampInverted, sentimentColorOverrides) => {
  if (!sentimentColorOverrides) {
    sentimentColorOverrides = JSON.parse(window.localStorage.sentimentColorOverrides ?? '{}');
  }

  const colorRampFn = ColorRamps[ColorRamps.map((d) => d.k).indexOf(colorRamp)].v;

  return ordering
    .map((d, i) => ({
      label: d.label,
      color: sentimentColorOverrides?.[question_name]?.[d.label] ? sentimentColorOverrides[question_name][d.label] : colorRampFn(colorRampInverted ? 1 - d.order : d.order, i)
    }))
    .reduce((a, b) => ({ ...a, [b.label]: b.color }), {});
};

export { buildConfig, buildConfigTemporal, groupColorsFromConfig, buildGroupColors };
