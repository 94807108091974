// modules
import { useRef, useEffect } from 'react';
import queryString from 'query-string';
import deepEqual from 'deep-equal';
import * as d3 from 'd3';
import numeral from 'numeral';
import { forward } from 'mgrs';
import { genericPost, API_HOST } from './api';

const updateQueryStringValue = (k, v) => {
  const parsed = queryString.parse(window.location.search);
  if (v) {
    parsed[k] = v;
  } else {
    delete parsed[k];
  }
  if (Object.keys(parsed).length > 0) {
    const desiredSearch = queryString.stringify(parsed);
    window.history.replaceState(null, null, `?${desiredSearch}`);
  } else {
    window.history.replaceState(null, null, window.location.pathname);
  }
};

const uuidv4 = (sep = '-') => {
  return `xxxxxxxx${sep}xxxx${sep}4xxx${sep}yxxx${sep}xxxxxxxxxxxx`
    .replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    })
    .toUpperCase();
};

const pairsToAssociations = (pairs) => {
  const out = [];
  const kvps = {};

  const sub_ids = Object.keys(pairs);
  for (const sub_id of sub_ids) {
    const place_id = pairs[sub_id];
    if (kvps[place_id]) {
      kvps[place_id].push(sub_id);
    } else {
      kvps[place_id] = [sub_id];
    }
  }

  const place_ids = Object.keys(kvps);
  for (const place_id of place_ids) {
    const submission_ids = kvps[place_id];

    out.push({
      place_id,
      submission_ids
    });
  }

  return out;
};

const updateMapAttributionImmediate = ({ zoom, longitude, latitude }) => {
  const c = 'iris-location-attrib';
  const mapLayers = Array.from(d3.selectAll('div.mapboxgl-map'));
  const l = mapLayers.length;

  d3.selectAll('.mapboxgl-ctrl-attrib').classed('ok', false);

  for (let i = l - 1; i >= 0; i--) {
    const div = d3.select(mapLayers[i]);

    if (div.style('visibility') === 'hidden') {
      continue;
    }

    let ele = div.select(`span.${c}`);
    if (ele.size() === 0) {
      const attrib = div.select('div.mapboxgl-ctrl-attrib-inner');

      if (attrib.size() === 0) {
        continue;
      }

      ele = attrib.insert('span', ':first-child').classed(c, true);
    }

    const lon = numeral(longitude);
    const lat = numeral(latitude);
    const mgrs = forward([lon.value(), lat.value()]);

    ele.text(`${numeral(zoom).format('0.00')} @ ${lon.format('0.000')},${lat.format('0.000')} | ${mgrs} |`);
    div
      .select('.mapboxgl-ctrl-attrib')
      .classed('ok', true)
      .classed('mapboxgl-compact', false)
      .selectAll('a')
      .filter(function () {
        return d3.select(this).attr('title') === 'Improve this map';
      })
      .remove();

    return;
  }
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function arrayDeepEqual() {
  for (let i = 0; i < arguments.length; i += 2) {
    if (!deepEqual(arguments[i], arguments[i + 1])) {
      return false;
    }
  }

  return true;
}

// fire & forget
const reportIssue = (title, error, extras) =>
  genericPost(`${API_HOST}/iris/v0/profile/issue`, { issue: { title, error, componentStack: JSON.stringify(extras ?? {}, null, ' ') } }, null, () => {}).catch(() => {});

const toUTCString = (epoch) => {
  const a = new Date(epoch); // e.g. 1606348800000
  const mm = (a.getUTCMonth() + 1).toString().padStart(2, '0'); // add one because months are zero-indexed
  const dd = a.getUTCDate().toString().padStart(2, '0');
  return `${a.getUTCFullYear()}-${mm}-${dd}`;
};

export { arrayDeepEqual, updateQueryStringValue, pairsToAssociations, uuidv4, updateMapAttributionImmediate, usePrevious, reportIssue, toUTCString };
