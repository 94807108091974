import React, { Component } from 'react';
import { withStyles, withTheme, alpha } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// MODULES
import { capitalCase } from 'change-case';
import clsx from 'clsx';

// ui/ux
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

// icons
import CloseIcon from '@material-ui/icons/Close';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ClipboardIcon from '@material-ui/icons/Assignment';
import PublishIcon from '@material-ui/icons/Publish';
import SettingsIcon from '@material-ui/icons/Settings';
import MailOutline from '@material-ui/icons/MailOutline';

// ours
import { API_HOST, genericGet, genericPost, genericDelete } from 'iris-api'; // eslint-disable-line import/no-unresolved
import { pushSnackbar as pushSnackbarAction } from '../actions';
import { Features as FEATURES, has, list } from '@premisedata/lib-features';
import UserDataPages from './UserDataPages';
import { getUserdataMetadata } from './places/services';

// GLOBALS
const DRAWER_WIDTH = 300;

const styles = (theme) => ({
  root: { display: 'flex' },
  paper: {
    position: 'absolute',
    width: '90vw',
    height: '70vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: '50vh',
    left: '50vw',
    transform: 'translate(-50%, -50%)',
    outline: 0,
    overflowY: 'auto'
  },
  title: {
    flexGrow: 1
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0
  },
  drawerPaper: {
    width: DRAWER_WIDTH
  },
  drawerContainer: {
    overflow: 'auto'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1, 2, 0, 2),
    marginLeft: DRAWER_WIDTH,
    height: 'calc(100% - 72px)',
    position: 'relative'
  },
  pageHeader: {
    margin: theme.spacing(2, 0, 1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& .MuiTypography-h6': {
      lineHeight: 1.8
    }
  },
  pageHeaderActions: {
    '& button': {
      margin: theme.spacing(0, 1)
    }
  },
  pageAction: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  box: {
    padding: theme.spacing(2, 0)
  },
  listGrid: {
    border: '1px solid rgba(255, 255, 255, 0.12)',
    borderRadius: '10px',
    padding: theme.spacing(0),
    marginTop: theme.spacing(2)
  },
  secondaryAction: {
    float: 'right'
  },
  form: {
    marginTop: theme.spacing(2)
  },
  textField: {
    margin: theme.spacing(1, 0, 3),
    width: '35ch'
  },
  tokenListItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)'
    }
  },
  tokenItem: {
    marginBottom: 0,
    marginTop: 0
  },
  copyTokenAlert: {
    display: 'inline-flex',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.warning.light,
    borderRadius: '10px',
    marginTop: theme.spacing(1)
  },
  lastUsed: {
    float: 'right',
    marginRight: theme.spacing(1)
  },
  btnRootStyle: {
    padding: '6px 16px'
  },
  shake: {
    position: 'relative',
    animation: `$shakeFrames 5s ${theme.transitions.easing.easeInOut}`,
    animationIterationCount: 'infinite'
  },
  '@keyframes shakeFrames': {
    '0%': { left: '0' },
    '1%': { left: '-3px' },
    '2%': { left: '5px' },
    '3%': { left: '-8px' },
    '4%': { left: '8px' },
    '5%': { left: '-5px' },
    '6%': { left: '3px' },
    '7%': { left: '0' }
  }
});

const DeleteButton = withStyles(({ palette }) => ({
  root: {
    color: palette.error.main,
    border: `1px solid ${alpha(palette.error.main, 0.5)}`,
    '&:hover': {
      border: `1px solid ${palette.error.main}`,
      backgroundColor: alpha(palette.error.main, 0.08)
    }
  }
}))(Button);

class SettingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 'tokenList',
      pat: [],
      tokenName: '',
      latestToken: null,
      tokenId: null,
      shakeManage: false,
      emailOptOutChecked: null,
      emailOptOutError: false
    };

    this._xhrOnEmailOptOutToggle = {};
    this._xhrSettings = {};
    this._xhrEmailOptOutProfile = {};
    this._xhrGenerate = {};
    this._xhrDelete = {};

    // BIND
    this.generateNewToken = this.generateNewToken.bind(this);
    this.deleteToken = this.deleteToken.bind(this);
    this.onClose = this.onClose.bind(this);
    this.renderNewTokenForm = this.renderNewTokenForm.bind(this);
    this.renderTokenList = this.renderTokenList.bind(this);
    this.renderManageEmail = this.renderManageEmail.bind(this);
    this.onToggleEmailOptOut = this.onToggleEmailOptOut.bind(this);
    this.onManageExistingClicked = this.onManageExistingClicked.bind(this);
    this.onUploadNewClicked = this.onUploadNewClicked.bind(this);
    this.onManageEmailsClicked = this.onManageEmailsClicked.bind(this);
    this.onShakeManage = this.onShakeManage.bind(this);
    this.onZoomTo = this.onZoomTo.bind(this);
  }

  // Access Tokens
  loadPats() {
    this._xhrSettings.cancel && this._xhrSettings.cancel();
    genericGet(`${API_HOST}/iris/v0/profile/settings`, this._xhrSettings, (e, data) => {
      if (e) return console.error(e);
      const { pat } = data;
      const newestFirst = pat.sort((a, b) => b.id - a.id);
      this.setState({ pat: newestFirst, activePage: 'tokenList', latestToken: null, shakeManage: false });
    });
  }
  deleteToken(id, refresh) {
    this._xhrDelete.cancel && this._xhrDelete.cancel();
    genericDelete(`${API_HOST}/iris/v0/profile/pat${id ? '/' + id : ''}`, this._xhrDelete, (e, r) => {
      if (e) return console.error(e);
      if (refresh) {
        this.setState({ pat: r.pat, activePage: 'tokenList', latestToken: null, shakeManage: false });
      }
    });
  }
  generateNewToken() {
    const { tokenName, activePage, tokenId } = this.state;
    if (activePage === 'editToken' && !!tokenId) {
      this.deleteToken(tokenId, false);
    }

    this._xhrGenerate.cancel && this._xhrGenerate.cancel();
    genericPost(`${API_HOST}/iris/v0/profile/pat`, { name: tokenName }, this._xhrGenerate, (e, data) => {
      if (e) return console.error(e);
      const { pat } = data;
      const newestFirst = pat.sort((a, b) => b.id - a.id);
      this.setState({ pat: newestFirst, activePage: 'tokenList', tokenName: '', tokenId: null, latestToken: newestFirst[0], shakeManage: false });
    });
  }
  // Upload Layers
  onUploadNewClicked() {
    this.setState({
      activePage: 'upload',
      shakeManage: false
    });
  }
  // Manage Existing Layers
  onManageExistingClicked() {
    this.setState({
      activePage: 'manage',
      shakeManage: false
    });
  }
  // Manage Email Updates
  didUserOptOut() {
    // cancel any outbound request:
    this._xhrEmailOptOutProfile.cancel && this._xhrEmailOptOutProfile.cancel();
    // issue new request:
    genericGet(`${API_HOST}/iris/v0/profile`, this._xhrEmailOptOutProfile, (e, profile) => {
      if (e) {
        this.props.pushSnackbar({ type: 'error', message: 'Failed to get current opt-out status.' });
        return this.setState({ emailOptOutError: true });
      }
      this.setState({
        emailOptOutChecked: profile.email_optout
      });
    });
  }
  onManageEmailsClicked() {
    this.setState({
      activePage: 'email',
      shakeManage: false
    });
  }
  // React
  componentDidMount() {
    const { user, getUserdataMetadata } = this.props;
    if (has(user.active_features, FEATURES.DATA_UPLOAD) || has(user.active_features, FEATURES.DATA_UPLOAD_VIEWER)) {
      const { unsubscribe } = getUserdataMetadata();
      this.unsubscribeGetUserdataMetadata = unsubscribe;
    }

    this.loadPats();
    this.didUserOptOut();
  }
  componentWillUnmount() {
    this.unsubscribeGetUserdataMetadata?.();
    this._xhrOnEmailOptOutToggle.cancel && this._xhrOnEmailOptOutToggle.cancel();
    this._xhrEmailOptOutProfile.cancel && this._xhrEmailOptOutProfile.cancel();
    this._xhrSettings.cancel && this._xhrSettings.cancel();
    this._xhrGenerate.cancel && this._xhrGenerate.cancel();
    this._xhrDelete.cancel && this._xhrDelete.cancel();
  }
  // Utils
  onClose() {
    const { onClose } = this.props;
    this.setState({ activePage: 'tokenList', latestToken: null, tokenName: '', tokenId: null, shakeManage: false });
    onClose();
  }

  onShakeManage(shake) {
    this.setState({ shakeManage: shake ?? true });
  }

  onZoomTo(extent) {
    const { viewRef } = this.props;
    if (!viewRef?.current?.fitBounds) return;

    viewRef.current.fitBounds(extent);
    this.onClose();
  }
  // Render functions
  renderNewTokenForm() {
    const { tokenName, activePage } = this.state;
    const { classes } = this.props;
    let pageHeader, pageContext, pageAction;

    if (activePage === 'newToken') {
      pageHeader = 'New access token';
      pageContext = "Access token's scope will match your user scope.";
      pageAction = 'Generate';
    } else if (activePage === 'editToken') {
      pageHeader = 'Edit access token';
      pageContext = 'If this token is regenerated, any scripts or applications using the token will need to be updated.';
      pageAction = 'Regenerate';
    } else {
      return null;
    }

    return (
      <React.Fragment>
        <Box className={classes.pageHeader}>
          <Typography variant="h6">{pageHeader}</Typography>
        </Box>
        <Divider />
        <Box className={classes.box}>
          <Typography variant="body1">{pageContext}</Typography>
          <FormControl className={classes.form} autoComplete="off">
            <TextField
              id="token-name"
              label="Name"
              color="secondary"
              value={tokenName}
              onChange={(evt) => this.setState({ tokenName: evt.target.value })}
              required={true}
              autoFocus={true}
              variant="outlined"
              className={classes.textField}
            />
            <div className={classes.pageAction}>
              <Button variant="contained" color="secondary" onClick={this.generateNewToken} disabled={!tokenName}>
                {pageAction} token
              </Button>
              <Button
                className={classes.btnRootStyle}
                onClick={() => this.setState({ activePage: 'tokenList', tokenName: '', tokenId: '', latestToken: null, shakeManage: false })}
              >
                Cancel
              </Button>
            </div>
          </FormControl>
        </Box>
      </React.Fragment>
    );
  }
  renderTokenList() {
    const { pat, latestToken, activePage } = this.state;
    const { theme, classes, user } = this.props;

    if (activePage !== 'tokenList') return null;

    return (
      <React.Fragment>
        <Box className={classes.pageHeader}>
          <Typography variant="h6">Access Tokens</Typography>
          <div className={classes.pageHeaderActions}>
            <Button variant="outlined" color="secondary" onClick={() => this.setState({ activePage: 'newToken', latestToken: null, shakeManage: false })}>
              Generate new token
            </Button>
            <DeleteButton variant="outlined" onClick={() => this.deleteToken(null, true)}>
              Revoke all
            </DeleteButton>
          </div>
        </Box>
        <Divider />
        <Box className={classes.box}>
          {pat.length === 0 ? (
            <Typography variant="subtitle1">You haven't generated any tokens that can be used to access the Iris API.</Typography>
          ) : (
            <React.Fragment>
              <Typography variant="subtitle1">Tokens you have generated that can be used to access the Iris API.</Typography>
              {latestToken ? (
                <Box className={classes.copyTokenAlert}>
                  <Typography variant="subtitle1" color="primary">
                    Token will only be displayed once, do not share.
                  </Typography>
                </Box>
              ) : null}
              <List className={classes.listGrid}>
                {pat.map((t, i) => {
                  if (t && latestToken && t.id === latestToken.id) {
                    return (
                      <ListItem key={i} disabled={!t.enabled} className={classes.tokenListItem} style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}>
                        <ListItemText
                          className={classes.tokenItem}
                          primary={latestToken.token}
                          primaryTypographyProps={{ display: 'inline' }}
                          secondary={
                            <IconButton
                              style={{ marginLeft: theme.spacing(1.5) }}
                              onClick={() => {
                                navigator.clipboard.writeText(latestToken.token).catch((e) => {
                                  console.error(e);
                                });
                              }}
                            >
                              <ClipboardIcon />
                            </IconButton>
                          }
                          secondaryTypographyProps={{ display: 'inline' }}
                        />
                        <Button
                          className={classes.secondaryAction}
                          variant="outlined"
                          onClick={() => {
                            this.setState({ activePage: 'tokenList', latestToken: null, tokenName: '', tokenId: null, shakeManage: false });
                          }}
                        >
                          OK
                        </Button>
                      </ListItem>
                    );
                  }
                  return (
                    <ListItem key={i} disabled={!t.enabled} className={classes.tokenListItem}>
                      <ListItemText
                        primary={
                          <Link
                            component="button"
                            onClick={() => {
                              this.setState({ activePage: 'editToken', tokenName: t.name, tokenId: t.id, latestToken: null, shakeManage: false });
                            }}
                            variant="body1"
                            color="secondary"
                          >
                            {t.name}
                          </Link>
                        }
                        secondary={` — ${list(user.active_features)
                          .map((d) => capitalCase(d))
                          .join(',')}`}
                        primaryTypographyProps={{ display: 'inline' }}
                        secondaryTypographyProps={{ display: 'inline', style: { fontStyle: 'italic' } }}
                      />
                      {t.row_last_used ? (
                        <Typography variant="body2" color="textSecondary" className={classes.lastUsed}>
                          Last used in the last {t.row_last_used}
                        </Typography>
                      ) : null}
                      <DeleteButton
                        className={classes.secondaryAction}
                        variant="outlined"
                        onClick={() => {
                          this.deleteToken(t.id, true);
                        }}
                      >
                        Delete
                      </DeleteButton>
                    </ListItem>
                  );
                })}
              </List>
            </React.Fragment>
          )}
        </Box>
      </React.Fragment>
    );
  }
  onToggleEmailOptOut(event) {
    const emailOptOut = event.target.checked;
    // cancel any previous request:
    this._xhrOnEmailOptOutToggle.cancel && this._xhrOnEmailOptOutToggle.cancel();
    // issue request:
    genericPost(`${API_HOST}/iris/v0/profile/optout`, { emailOptOut }, this._xhrOnEmailOptOutToggle, (error) => {
      if (error) return this.props.pushSnackbar({ type: 'error', message: 'Failed to update email opt-out status' });

      // update state & notify user:
      this.setState({ emailOptOutChecked: emailOptOut });
      return this.props.pushSnackbar({ message: 'Opt-out status updated!', type: 'success' });
    });
  }
  renderManageEmail() {
    const { emailOptOutChecked, emailOptOutError } = this.state;
    return (
      <React.Fragment>
        <Box>
          <Typography variant="h6">Email Preferences</Typography>
        </Box>
        <Divider />
        <FormControlLabel
          disabled={emailOptOutError}
          control={<Checkbox checked={emailOptOutChecked} onChange={this.onToggleEmailOptOut} color="secondary" />}
          label="Opt-out of weekly iris growth summaries"
        />
      </React.Fragment>
    );
  }

  render() {
    const { user, classes, userdataMetadataQuery } = this.props;
    const { activePage, shakeManage } = this.state;

    const userdataDisabled = userdataMetadataQuery.isError || !userdataMetadataQuery.data;

    return (
      <Modal onClose={this.onClose} open={true}>
        <Paper className={classes.paper}>
          <AppBar position="fixed" color="secondary" className={classes.appBar}>
            <Toolbar>
              <Typography variant="h5" className={classes.title}>
                Settings
              </Typography>
              <IconButton onClick={this.onClose}>
                <CloseIcon color="primary" />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper
            }}
          >
            <Toolbar />
            <div className={classes.drawerContainer}>
              <List>
                <ListItem button={true} onClick={() => this.setState({ activePage: 'tokenList', latestToken: null })}>
                  <ListItemIcon>
                    <VpnKeyIcon />
                  </ListItemIcon>
                  <ListItemText primary="Access Tokens" />
                </ListItem>
                <ListItem disabled={userdataDisabled || !has(user.active_features, FEATURES.DATA_UPLOAD)} button={true} onClick={this.onUploadNewClicked}>
                  <ListItemIcon>
                    <PublishIcon />
                  </ListItemIcon>
                  <ListItemText primary="Upload Layers" />
                </ListItem>
                <ListItem disabled={userdataDisabled} className={clsx(shakeManage && classes.shake)} button={true} onClick={this.onManageExistingClicked}>
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manage Existing Layers" />
                </ListItem>
                <ListItem disabled={false} className={clsx(shakeManage && classes.shake)} button={true} onClick={this.onManageEmailsClicked}>
                  <ListItemIcon>
                    <MailOutline />
                  </ListItemIcon>
                  <ListItemText primary="Email Preferences" />
                </ListItem>
              </List>
            </div>
          </Drawer>
          <main className={classes.content}>
            <Toolbar />
            <this.renderTokenList />
            <this.renderNewTokenForm />
            {activePage === 'email' ? <this.renderManageEmail /> : ''}
            {!userdataDisabled && <UserDataPages activePage={activePage} onShakeManage={this.onShakeManage} onZoomTo={this.onZoomTo} />}
          </main>
        </Paper>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  userdataMetadataQuery: getUserdataMetadata.select()(state),
  user: state.app.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserdataMetadata: getUserdataMetadata.initiate,
      pushSnackbar: pushSnackbarAction
    },
    dispatch
  );

SettingsModal.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,

  onClose: PropTypes.func.isRequired,
  pushSnackbar: PropTypes.func,
  viewRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({
      current: PropTypes.oneOfType([
        // \n
        PropTypes.instanceOf(Element),
        PropTypes.object, // useImperativeHandle produces an object.
        PropTypes.oneOf([null]).isRequired // null before view is mounted
      ])
    })
  ]),

  userdataMetadataQuery: PropTypes.object,
  getUserdataMetadata: PropTypes.func.isRequired
};

SettingsModal.defaultProps = {};
const withRedux = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: false });
export default withRedux(withTheme(withStyles(styles)(SettingsModal)));
