import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// MODULES
import clsx from 'clsx';
import { capitalCase } from 'change-case';
import debounce from 'lodash.debounce';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// MUI: Icons
import ClearIcon from '@material-ui/icons/Clear';

// OURS
import { getAvailableForms, getAvailableQuestions } from './services';

const useStyles = makeStyles((theme) => ({
  captionWarning: {
    color: theme.palette.warning.main,
    marginBottom: theme.spacing(2)
  },
  // Make the <Select /> elements match the <ChipPopover /> display:
  formControl: {
    '& .MuiSelect-select:focus': {
      borderRadius: 16
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 16
    }
  },
  ulFormControl: {
    marginLeft: theme.spacing(1)
    // cursor: 'pointer'
  },
  selectContainer: {
    display: 'flex',
    flexFlow: 'column wrap',
    '& > div': {
      marginBottom: theme.spacing(2),
      width: '100%'
    }
  },
  popoverPaper: {
    maxWidth: '50vw'
  },
  halfOpacity: {
    opacity: 0.2
  },
  restBtnGridItem: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const QAFilter = (props) => {
  const classes = useStyles();
  const {
    selectedQAFilterForm,
    selectedQAFilterQuestion,
    selectedQAFilterAnswers,
    onQAFilterSelectedFormChanged,
    onQAFilterSelectedQuestionChanged,
    onQAFilterSelectedAnswersChanged,
    onQAFiltersReset
  } = props;

  // REDUX
  const selectedGeographies = useSelector((state) => state.sentiment.selectedGeographies);

  // STATE
  const [selectedAnswers, setSelectedAnswers] = useState(selectedQAFilterAnswers ?? []);

  // QUERIES
  const availableForms = getAvailableForms.useQuery({ selectedGeographies });
  const availableQuestions = getAvailableQuestions.useQuery([selectedQAFilterForm], { skip: !selectedQAFilterForm });

  // USECALLBACK
  const formRenderValue = useCallback((form) => {
    if (!form) return '';
    return form.form_id + ': ' + form.form_name;
  }, []);

  const questionRenderValue = useCallback((question) => {
    if (!question) return '';
    return question.question_proper;
  }, []);
  const answersRenderValue = useCallback((answers) => {
    if (!answers || answers.length === 0) return '';
    return answers.join(', ');
  }, []);

  const onFormChanged = useCallback(
    (e) => {
      onQAFilterSelectedFormChanged(e.target.value);
      setSelectedAnswers([]);
    },
    [onQAFilterSelectedFormChanged]
  );
  const onQuestionChanged = useCallback(
    (e) => {
      onQAFilterSelectedQuestionChanged(e.target.value);
      setSelectedAnswers([]);
    },
    [onQAFilterSelectedQuestionChanged]
  );

  const publishSelectedAnswers = useMemo(
    () => debounce((selectedAnswers) => {
      onQAFilterSelectedAnswersChanged(selectedAnswers);
    }, 333),
    [onQAFilterSelectedAnswersChanged]
  );
  const onReset = useCallback(() => {
    onQAFiltersReset();
    setSelectedAnswers([]);
  }, [onQAFiltersReset]);

  // USEEFFECT
  useEffect(() => {
    // componentDidMount()

    return () => {
      // componentWillUnmount()
      publishSelectedAnswers.cancel();
    };
  }, [publishSelectedAnswers]);

  const onAnswersChanged = useCallback((e) => {
    setSelectedAnswers(e.target.value);
    publishSelectedAnswers(e.target.value);
  }, [publishSelectedAnswers]);

  // USEMEMO
  const menuProps = useMemo(
    () => ({
      PopoverClasses: {
        paper: classes.popoverPaper
      }
    }),
    [classes.popoverPaper]
  );

  if (!availableForms.data || availableForms.isError) return null;

  const availableFormsDisabled = !availableForms.data || availableForms.isError || availableForms.isUninitialized;
  const availableQuestionsDisabled = !availableQuestions.data || availableQuestions.isError || availableQuestions.isUninitialized;
  return (
    <>
      <Grid container={true} direction="row" justifyContent="flex-start" alignItems="center">
        <Grid item={true}>
          <Typography className={classes.popperTypography} variant="h6">
            Filter by answer(s) to a different question
          </Typography>
        </Grid>
        <Grid item={true} xs={true} className={classes.restBtnGridItem}>
          <Button
            disabled={!selectedQAFilterForm && !selectedQAFilterQuestion && selectedQAFilterAnswers.length === 0}
            onClick={onReset}
            size="small"
            variant="contained"
            color="secondary"
            startIcon={<ClearIcon />}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      <Box className={classes.selectContainer}>
        <Typography variant="caption" className={classes.captionWarning} gutterBottom={true}>
          This filter dramatically increases response latency.
        </Typography>
        <FormControl className={clsx(classes.formControl, availableFormsDisabled && classes.halfOpacity)} variant="outlined">
          <InputLabel id="available-forms-label">Available Forms</InputLabel>
          <Select
            disabled={availableFormsDisabled}
            label="Available Forms"
            labelId="available-forms-label"
            value={selectedQAFilterForm}
            renderValue={formRenderValue}
            onChange={onFormChanged}
            MenuProps={menuProps}
          >
            {availableForms.data.map((d) => (
              <MenuItem key={d.form_id} value={d}>
                <Typography>{`${d.form_id}: ${d.form_name}`}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={clsx(classes.formControl, availableQuestionsDisabled && classes.halfOpacity)} variant="outlined">
          <InputLabel id="available-questions-label">Available Questions</InputLabel>
          <Select
            disabled={availableQuestionsDisabled}
            label="Available Questions"
            labelId="available-questions-label"
            value={selectedQAFilterQuestion}
            onChange={onQuestionChanged}
            renderValue={questionRenderValue}
            MenuProps={menuProps}
          >
            {(availableQuestions.data ?? []).map((question) => (
              <MenuItem key={question.question_name} value={question}>
                <Typography>{question.question_proper}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl disabled={false} className={clsx(classes.formControl, !selectedQAFilterQuestion?.responses && classes.halfOpacity)} variant="outlined">
          <InputLabel id="available-responses-label">Available Responses</InputLabel>
          <Select
            multiple={true}
            label="Available Responses"
            labelId="available-responses-label"
            value={selectedAnswers}
            renderValue={answersRenderValue}
            onChange={onAnswersChanged}
            MenuProps={menuProps}
          >
            {(selectedQAFilterQuestion?.responses ?? []).map((response) => (
              <li key={response} value={response}>
                <FormControlLabel
                  value={response}
                  checked={selectedAnswers.includes(response)}
                  classes={{ root: clsx(classes.ulFormControl, 'noselect') }}
                  label={capitalCase(response)}
                  control={<Checkbox />}
                />
              </li>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};
QAFilter.defaultProps = {};
QAFilter.propTypes = {
  // <Select /> component is looking for "''" or "{}"
  selectedQAFilterForm: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selectedQAFilterQuestion: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selectedQAFilterAnswers: PropTypes.arrayOf(PropTypes.string),

  onQAFilterSelectedFormChanged: PropTypes.func.isRequired,
  onQAFilterSelectedQuestionChanged: PropTypes.func.isRequired,
  onQAFilterSelectedAnswersChanged: PropTypes.func.isRequired,
  onQAFiltersReset: PropTypes.func.isRequired
};
export default React.memo(QAFilter);
