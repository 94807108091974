const space_factor = 1 / 8;
const zcand = [0.125, 0.25, 0.5, 0.75, 1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024];

export const generate_bounding_box = (x0, y0, x1, y1) => {
  const z = zcand.filter((d) => d >= Math.max(x1 - x0, y1 - y0))[0];

  const [x0g, y0g, x1g, y1g] = [(x0 + 180) / z, (y0 + 90) / z, (x1 + 180) / z, (y1 + 90) / z];

  //     # Find candidate cache buffers
  const ulx_candidates = [Math.floor(x0g), Math.floor(x0g) + space_factor * (1 - 2 * Math.floor(x0g - Math.floor(x0g)))];
  const uly_candidates = [Math.floor(y0g), Math.floor(y0g) + space_factor * (1 - 2 * Math.floor(y0g - Math.floor(y0g)))];
  const candidates = [
    (ulx_candidates[0], uly_candidates[0]),
    (ulx_candidates[1], uly_candidates[0]),
    (ulx_candidates[0], uly_candidates[1]),
    (ulx_candidates[1], uly_candidates[1])
  ];

  for (const c of candidates) {
    if (c[0] + 1 >= x1g && c[1] + 1 >= y1g) {
      return [c[0] * z - 180, c[1] * z - 90, (c[0] + 1) * z - 180, (c[1] + 1) * z - 90];
    }
  }

  return [Math.floor(x0g) * z - 180, Math.floor(y0g) * z - 90, (Math.floor(x0g) + 2) * z - 180, (Math.floor(y0g) + 2) * z - 90];
};
export const getBuffer = (mapPolygon) => {
  if (mapPolygon === undefined) {
    return null;
  }

  const minx = mapPolygon.nw[0];
  const miny = mapPolygon.sw[1];
  const maxx = mapPolygon.ne[0];
  const maxy = mapPolygon.se[1];

  const out = generate_bounding_box(minx, miny, maxx, maxy);
  return {
    minx: Math.max(-180, out[0]),
    miny: Math.max(-80, out[1]),
    maxx: Math.min(180, out[2]),
    maxy: Math.min(80, out[3])
  };
};

export const getCoordsFromBuffer = (buffer) => {
  if (buffer === null) {
    return null;
  }

  const { minx, miny, maxx, maxy } = buffer;
  return {
    nw: [minx, maxy],
    ne: [maxx, maxy],
    se: [maxx, miny],
    sw: [minx, miny]
  };
};

// Summaries Filtering Funcs

/**
 *
 * @param {*} summaries ets summary info for each region for the currently selected question and all filter questions
 * @param {*} answerInfo  QA information for multiple QA filter. Gets data for each question in the multiple QA filter.
 * @param {*} selectedQuestion The currently selected question
 * @param {*} filterAnswers A map of answers that should be considered when filtering for the currently selected question
 * @param {*} answerInclusionSetting The inclusion setting for filterAnswer for the currently selected question: 'any', 'all', or 'only'
 * @param {*} onlyMostPrevalent Boolean. If true, filterAnswers should have only one key, and regions displayed should have that answer as their most prevalent.
 * @param {*} filterSets A map of questions to a map of answers, inclusion settings, and onlyMostPrevalent booleans. Determines filter properties for each tab in the multiple QA filter.
 * @param {*} filterCountries A map of countries by which to filter.
 * @returns A filtered array of summaries.
 */
export const filterSummaries = (summaries, answerInfo, selectedQuestion, filterAnswers, answerInclusionSetting, onlyMostPrevalent, filterSets, filterCountries) => {
  let transform = [...summaries];

  // Filter by countries
  if (Object.keys(filterCountries).length > 0) {
    transform = transform.filter((summary) => {
      return filterCountries[summary.country];
    });
  }

  // Filter by filter answer (current question)
  if (filterAnswers && Object.keys(filterAnswers).length > 0) {
    transform = transform.filter((summary) => {
      if (onlyMostPrevalent) {
        return filterAnswers[summary.mostPrevalentAnswer];
      } else {
        switch (answerInclusionSetting) {
          // Include regions where any of the answers are present
          case 'any':
            return Object.keys(filterAnswers).some((answer) => {
              return answerInfo[selectedQuestion][summary.region] && answerInfo[selectedQuestion][summary.region].answers[answer];
            });
          // Include regions where all of the answers are present
          case 'all':
            return Object.keys(filterAnswers).every((answer) => {
              return answerInfo[selectedQuestion][summary.region] && answerInfo[selectedQuestion][summary.region].answers[answer];
            });
          // Include regions where all and only the selected answers are present
          case 'only':
            return (
              answerInfo[selectedQuestion][summary.region] &&
              Object.keys(filterAnswers).length === Object.keys(answerInfo[selectedQuestion][summary.region].answers).length &&
              Object.keys(filterAnswers).every((answer) => {
                return answerInfo[selectedQuestion][summary.region].answers[answer];
              })
            );
          default:
            break;
        }
      }
    });
  }

  if (filterSets && Object.keys(filterSets).length > 0) {
    // Filter out regions that dont include the required answers
    transform = transform.filter((summary) => {
      // TODO: This is a horrific but effective creation. Evalute inclusion for each q/a combination.
      return Object.keys(filterSets).every((question) => {
        // If the current question is only for most prevalent answers, check that the filter answer matches the summary region's most prevalent answer
        if (filterSets[question].onlyMostPrevalent) {
          return answerInfo[question][summary.region] && filterSets[question].filterAnswers[answerInfo[question][summary.region].most_prevalent];
        } else {
          // Filter by answer inclusion settings

          switch (filterSets[question].answerInclusionSetting) {
            // Include regions where any of the answers are present
            case 'any':
              return Object.keys(filterSets[question].filterAnswers).some((answer) => {
                return answerInfo[question][summary.region] && answerInfo[question][summary.region].answers[answer];
              });
            // Include regions where all of the answers are present
            case 'all':
              return Object.keys(filterSets[question].filterAnswers).every((answer) => {
                return answerInfo[question][summary.region] && answerInfo[question][summary.region].answers[answer];
              });
            // Include regions where all and only the selected answers are present
            case 'only':
              return (
                answerInfo[question][summary.region] &&
                Object.keys(filterSets[question].filterAnswers).length === Object.keys(answerInfo[question][summary.region].answers).length &&
                Object.keys(filterSets[question].filterAnswers).every((answer) => {
                  return answerInfo[question][summary.region].answers[answer];
                })
              );
            default:
              break;
          }
        }
      });
    });
  }

  return transform;
};
