import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// MODULES
import { matchSorter } from 'match-sorter';

// OURS
import VirtualizedAutocomplete from '../../../radio/components/VirtualizedAutocomplete';

function VACGeographies(props) {
  const { availableGeographies, selectedGeographies, onGeographiesChanged, label, className, disabled } = props;

  const geographiesVacFilterOptions = useCallback(
    (options, { inputValue }) => {
      let filteredOptions = options;
      if (selectedGeographies?.length > 0) {
        filteredOptions = options.filter((d) => d.level === selectedGeographies[0].level);
      }
      return matchSorter(filteredOptions, inputValue, { keys: ['name', 'hasc'] });
    },
    [selectedGeographies]
  );
  const geographiesVacGetOptionLabel = useCallback((v) => `${v.name} (${v.hasc})`, []);
  const geographiesVacOnChange = useCallback((_, v) => onGeographiesChanged(v), [onGeographiesChanged]);
  const getOptionSelected = useCallback((a, b) => a.hasc === b.hasc, []);

  return (
    <VirtualizedAutocomplete
      getOptionSelected={getOptionSelected}
      variant="outlined"
      disabled={disabled || availableGeographies.length === 0}
      limitTags={-1}
      options={availableGeographies}
      value={selectedGeographies}
      filterOptions={geographiesVacFilterOptions}
      getOptionLabel={geographiesVacGetOptionLabel}
      onChange={geographiesVacOnChange}
      label={label}
      placeholder="District of Columbia"
      className={className}
    />
  );
}

VACGeographies.propTypes = {
  availableGeographies: PropTypes.array,
  selectedGeographies: PropTypes.array,
  onGeographiesChanged: PropTypes.func.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool
};
VACGeographies.defaultProps = {
  label: 'Filter by Geography',
  disabled: false
};

export default React.memo(VACGeographies);
