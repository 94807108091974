// Core
import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import amplitude from 'amplitude-js';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux
import { demoSelectors, dumpState, restoreState, demoActions } from './demographicsSlice';
import { restoreStateGlobal } from '../../store';
import { getDetails } from './services';
import { setGlobal } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';

// OURS
import DeckMap from './DeckMap';
import LeftPanel from '../LeftPanel';
import DemoConfigPanel from './DemoConfigPanel';
import ResultsPanel from './ResultsPanel';
import DetailPanel from './DetailPanel';

// OURS: GLOBALS
import { LEFT_PANEL_WIDTH } from 'iris-config'; // eslint-disable-line import/no-unresolved
import { TOP } from '../../config';

const { loadState } = demoActions;

const useStyles = makeStyles({
  circularprogress: {
    position: 'absolute',
    top: TOP,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const DemographicsView = forwardRef((_, ref) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Refs
  const _refMap = useRef(null);

  // Redux
  const configPanelOpen = useSelector((state) => state.app.configPanelOpen);
  const serializedState = useSelector((state) => state.app.serializedState);

  // Redux: Selectors
  const selectedDetail = useSelector(demoSelectors.selectSelectedDetail);
  const getDetailsParams = demoSelectors.getDetailsQuery({
    demographics: serializedState?.productState
  });

  const skipDetailsQuery = !getDetailsParams;
  const answerDetailsQuery = getDetails.useQuery(getDetailsParams, { skip: skipDetailsQuery });

  useEffect(() => {
    if (serializedState && (skipDetailsQuery || answerDetailsQuery.data || answerDetailsQuery.isError)) {
      if (answerDetailsQuery.isError) {
        amplitude.getInstance().logEvent('view_restore_failed', {
          product: 'demographics',
          hash: serializedState.hash,
          scope_id: serializedState.scope_id,
          read_error: true
        });

        return dispatch(
          setGlobal({
            notificationQueue: [{ type: 'error', message: 'Failed to load saved view; unable to load details.' }],
            serializedState: null
          })
        );
      }

      const configPanelShouldBeOpen = serializedState.globalState?.configPanelOpen === true;
      const newState = restoreState(serializedState);
      const newStateGlobal = restoreStateGlobal(serializedState);

      // Update this products slice:
      dispatch(loadState(newState));

      amplitude.getInstance().logEvent('view_restored', {
        product: 'demographics',
        hash: serializedState.hash,
        scope_id: serializedState.scope_id
      });

      // Update global state:
      dispatch(
        setGlobal({
          notificationQueue: [{ type: 'success', message: 'Saved view restored successfully!' }],
          serializedState: null, // load complete, clear it.
          configPanelOpen: configPanelShouldBeOpen,
          ...newStateGlobal
        })
      );
    }
  }, [
    // \n
    dispatch,
    skipDetailsQuery,
    serializedState,
    answerDetailsQuery.isError,
    answerDetailsQuery.data
  ]);

  useImperativeHandle(
    ref,
    () => ({
      buildHashStateObject: (store) => dumpState(store),
      fitBounds: _refMap.current?.fitBounds,
      setView: _refMap.current?.setView
    }),
    []
  );

  // don't mount anything until we have restored state:
  if (serializedState)
    return (
      <Box className={classes.circularprogress}>
        <CircularProgress color="secondary" />
      </Box>
    );

  const detailPanelOpen = !!selectedDetail;
  const detailPanelOffset = configPanelOpen ? LEFT_PANEL_WIDTH : 0;
  let mapOffset = 0;
  if (configPanelOpen) mapOffset += LEFT_PANEL_WIDTH;
  if (detailPanelOpen) mapOffset += LEFT_PANEL_WIDTH;

  return (
    <>
      <LeftPanel id={'demo-config-panel'} open={configPanelOpen} noSideMargin={true} flex={true}>
        <DemoConfigPanel />
        <ResultsPanel />
      </LeftPanel>
      <LeftPanel id={'detail-config-panel'} open={detailPanelOpen} left={detailPanelOffset}>
        <DetailPanel />
      </LeftPanel>
      <DeckMap ref={_refMap} left={mapOffset} />
    </>
  );
});

DemographicsView.displayName = 'DemographicsView';
DemographicsView.propTypes = {};
//
export default DemographicsView;
