// essentials
import { withStyles, withTheme } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { renderToString } from 'react-dom/server';

// icons
import AddCircleIcon from '@material-ui/icons/Add';
import AlbumIcon from '@material-ui/icons/Album';

// leaflet
import { Map, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// ours
import { common } from '@premisedata/iris-components';

const { arrayNotEmpty, uniqueId } = common;

const ADD_CIRCLE_ICON_SVG = renderToString(<AddCircleIcon />);
const ALBUM_ICON_SVG = renderToString(<AlbumIcon />);

const fixSVG = (svg, color) => {
  return (
    'data:image/svg+xml;utf8,' +
    svg.replace('<svg ', '<svg xmlns="http://www.w3.org/2000/svg" ').replace('<path ', `<path stroke="${color.replace('#', '%23')}" fill="${color.replace('#', '%23')}" `)
  );
};

const styles = (/* theme */) => ({
  map: {
    height: '100%',
    width: '100%',
    cursor: 'default'
  }
});

class ValidationMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: uniqueId(),
      lat: 37.798056,
      lng: -122.465833,
      zoom: 12,

      activeView: null
    };

    this.l = React.createRef();
    this.createdIconsStyle = null;
  }
  createIcons() {
    const { theme } = this.props;
    // only create new icons when we need to, check global state vs
    // internal state:
    if (this.createdIconsStyle === theme.palette.type) {
      return;
    }

    this.createdIconsStyle = theme.palette.type;
    const colorMarker = theme.palette.text.primary;
    const colorMarkerHover = theme.palette.tertiary.main;

    this.submissionIcon = new L.Icon({
      iconUrl: fixSVG(ADD_CIRCLE_ICON_SVG, colorMarker),
      iconAnchor: [5, 5],
      popupAnchor: [0, 0],
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new L.Point(10, 10)
    });

    this.activeSubmissionIcon = new L.Icon({
      iconUrl: fixSVG(ADD_CIRCLE_ICON_SVG, colorMarkerHover),
      iconAnchor: [5, 5],
      popupAnchor: [0, 0],
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new L.Point(10, 10)
    });

    this.placeIcon = new L.Icon({
      iconUrl: fixSVG(ALBUM_ICON_SVG, colorMarker),
      iconAnchor: [5, 5],
      popupAnchor: [0, 0],
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new L.Point(10, 10)
    });

    this.activePlaceIcon = new L.Icon({
      iconUrl: fixSVG(ALBUM_ICON_SVG, colorMarkerHover),
      iconAnchor: [5, 5],
      popupAnchor: [0, 0],
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new L.Point(10, 10)
    });
  }
  renderPlaces() {
    const { activePlace, places, onClickPlace } = this.props;

    const havePlaces = places && places.length > 0;
    const haveActivePlace = !!activePlace;

    if (havePlaces && haveActivePlace) {
      const placesToRender = places.map((d) => d.place_id).indexOf(activePlace.place_id) === -1 ? [...places, activePlace] : places;
      // have an active place & other places
      const activePlaceId = activePlace.place_id;
      return placesToRender.map((d) => (
        <Marker
          zIndexOffset={activePlaceId === d.place_id ? 200 : 100}
          key={d.place_id}
          position={[d.y_lat, d.x_lon]}
          icon={activePlaceId === d.place_id ? this.activePlaceIcon : this.placeIcon}
          onClick={() => {
            onClickPlace && onClickPlace(d);
          }}
        />
      ));
    } else if (havePlaces) {
      // have places, no active place.
      return places.map((d) => (
        <Marker
          zIndexOffset={100}
          key={d.place_id}
          position={[d.y_lat, d.x_lon]}
          icon={activePlace === false ? this.activePlaceIcon : this.placeIcon}
          onClick={() => {
            onClickPlace && onClickPlace(d);
          }}
        />
      ));
    } else if (haveActivePlace) {
      // have only an active place, no places.
      return (
        <Marker
          zIndexOffset={100}
          key={activePlace.place_id}
          position={[activePlace.y_lat, activePlace.x_lon]}
          icon={this.placeIcon}
          onClick={() => {
            onClickPlace && onClickPlace(activePlace);
          }}
        />
      );
    }
  }
  renderSubmissions() {
    const { activeSubmission, onClickSubmission } = this.props;
    let { submissions } = this.props;

    if (!submissions && activeSubmission) {
      submissions = [activeSubmission];
    }

    if (!submissions) {
      return null;
    }

    const activeSubmissionId = activeSubmission ? activeSubmission.sub_id : null;

    return submissions.map((d) => {
      const active = activeSubmissionId === d.sub_id;

      return (
        <Marker
          zIndexOffset={active ? 80 : 70}
          key={d.sub_id}
          position={[d.y_lat, d.x_lon]}
          icon={active ? this.activeSubmissionIcon : this.submissionIcon}
          onClick={() => {
            onClickSubmission && onClickSubmission(d);
          }}
        />
      );
    });
  }
  componentDidMount() {
    this.map = this.l.current.leafletElement;
    this.updateMapBounds();
  }
  updateMapBounds() {
    const { activeSubmission, activePlace, submissions, places } = this.props;
    const { activeView } = this.state;
    const activeViewStr = JSON.stringify(activeView);

    // based on all these optional inputs, find the best fit:
    let data = [];
    if (activeSubmission) {
      data.push(activeSubmission);
    }
    if (activePlace) {
      data.push(activePlace);
    }

    if (arrayNotEmpty(submissions)) {
      data = data.concat(submissions);
    }

    if (data.length === 0 && arrayNotEmpty(places)) {
      data = data.concat(places);
    }

    if (data.length === 0) {
      return;
    } else if (data.length === 1) {
      const position = [data[0].y_lat, data[0].x_lon];
      const positionStr = JSON.stringify(position);

      if (activeViewStr !== positionStr) {
        this.setState({ activeView: position });
        this.map.setView(position);
      }

      return;
    }
    // compute a new bounding box for this data,
    let minLat = Infinity;
    let maxLat = -Infinity;
    let minLon = Infinity;
    let maxLon = -Infinity;

    let allDataVisible = true;
    const currentMapBounds = this.map.getBounds();

    for (const d of data) {
      if (d.y_lat < minLat) {
        minLat = d.y_lat;
      }
      if (d.y_lat > maxLat) {
        maxLat = d.y_lat;
      }
      if (d.x_lon < minLon) {
        minLon = d.x_lon;
      }
      if (d.x_lon > maxLon) {
        maxLon = d.x_lon;
      }
      if (allDataVisible && !currentMapBounds.contains([d.y_lat, d.x_lon])) {
        allDataVisible = false;
      }
    }

    const bounds = [
      [minLat, minLon],
      [maxLat, maxLon]
    ];

    const boundsStr = JSON.stringify(bounds);

    if (activeViewStr === boundsStr) {
      return;
    }

    this.setState({ activeView: bounds });
    this.map.fitBounds(bounds, {
      maxZoom: 16
    });
  }
  componentDidUpdate() {
    this.updateMapBounds();
  }
  render() {
    this.createIcons();
    const position = [this.state.lat, this.state.lng];
    const { theme, classes, submissions, places, activePlace, activeSubmission, children, mapboxAccessToken } = this.props;

    const hasData = activePlace || activeSubmission || arrayNotEmpty(submissions) || arrayNotEmpty(places);
    const filter = hasData ? null : 'blur(8px)';

    return (
      <Map id={this.state.id} ref={this.l} className={classes.map} zoom={this.state.zoom} center={position} attributionControl={false} zoomControl={true} style={{ filter }}>
        <TileLayer
          attribution={'© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'}
          url={`https://api.mapbox.com/styles/v1/mapbox/${theme.palette.type}-v10/tiles/{z}/{x}/{y}?access_token=${mapboxAccessToken}`}
        />
        {this.renderSubmissions()}
        {this.renderPlaces()}
        {children}
      </Map>
    );
  }
}

const mapStateToProps = (state) => ({
  mapboxAccessToken: state.app.mapboxAccessToken
});

ValidationMap.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  // data
  mapboxAccessToken: PropTypes.string.isRequired,
  //
  submissions: PropTypes.array,
  activeSubmission: PropTypes.object,
  places: PropTypes.array,
  activePlace: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  // callbacks
  onClickSubmission: PropTypes.func,
  onClickPlace: PropTypes.func
};

ValidationMap.defaultProps = {};

const withRedux = connect(mapStateToProps);
export default withRedux(withTheme(withStyles(styles)(ValidationMap)));
