import React, { PureComponent } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// UI/UX
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Popover from '@material-ui/core/Popover';

// ICONS
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const styles = (theme) => ({
  popover: {
    padding: theme.spacing(2),
    maxWidth: '35vw'
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  grid: {
    marginTop: theme.spacing(1.5)
  }
});

class ChipPopoverInstant extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      chipStyle: {
        maxWidth: props.maxWidth,
        opacity: props.disabled ? 0.5 : undefined
      }
    };

    this.deleteIcon = <ArrowDropDownIcon />;

    this.onClick = this.onClick.bind(this);
    this.onClose = this.onClose.bind(this);
  }
  onClick(e) {
    if (this.props.disabled) return;
    this.setState({
      anchorEl: e.target
    });
  }
  onClose() {
    this.setState({
      anchorEl: null
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.maxWidth !== this.props.maxWidth || prevProps.disabled !== this.props.disabled) {
      this.setState({
        chipStyle: {
          maxWidth: this.props.maxWidth,
          opacity: this.props.disabled ? 0.5 : undefined
        }
      });
    }
  }
  render() {
    const { classes, children, label, active, className, id, disabled, popoverStyle, popoverOrigins } = this.props;
    const { anchorEl, chipStyle } = this.state;

    return (
      <React.Fragment>
        <Chip
          id={id}
          style={chipStyle}
          className={clsx(chipStyle.maxWidth > 0 && classes.ellipsis, className, 'noselect')}
          variant="outlined"
          color={active ? 'secondary' : 'default'}
          label={label}
          deleteIcon={this.deleteIcon}
          onClick={disabled ? undefined : this.onClick}
          onDelete={disabled ? undefined : this.onClick}
        />
        {anchorEl &&
          <Popover open={!!anchorEl} anchorEl={anchorEl} onClose={this.onClose} {...popoverOrigins}>
            <Box className={classes.popover} style={popoverStyle}>
              {children}
            </Box>
          </Popover>
        }
      </React.Fragment>
    );
  }
}

ChipPopoverInstant.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,

  label: PropTypes.string,
  maxWidth: PropTypes.number,

  active: PropTypes.bool,

  className: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  popoverStyle: PropTypes.object,
  popoverOrigins: PropTypes.object
};

ChipPopoverInstant.defaultProps = {
  label: 'Untitled',
  active: false,
  popoverStyle: {},
  popoverOrigins: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center'
    }
  }
};
export default withTheme(withStyles(styles)(ChipPopoverInstant));
