import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// ui /ux
import List from '@material-ui/core/List';
import InboxItemExport from './InboxItemExport';
import InboxItemSentimentRequest from './InboxItemSentimentRequest';

const useStyles = makeStyles((theme) => ({
  listPopover: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    overflowY: 'auto'
  }
}));

const InboxPopover = (props) => {
  const { inbox, onDelete } = props;
  const classes = useStyles();

  return (
    <List disablePadding={true} className={classes.listPopover}>
      {inbox.map((item) => {
        if (item.api === 'exports') {
          return <InboxItemExport key={'export' + item.uid} item={item} onDelete={onDelete} />;
        } else if (item.api === 'sentiment_ts_requests') {
          return <InboxItemSentimentRequest key={'sentiment' + item.uid} item={item} onDelete={onDelete} />;
        } else {
          return null;
        }
      })}
    </List>
  );
};

InboxPopover.propTypes = {
  inbox: PropTypes.array,
  onDelete: PropTypes.func.isRequired
};

InboxPopover.defaultProps = {};

export default React.memo(InboxPopover);
