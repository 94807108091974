import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// UI/UX
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';

// visx
import { Pie } from '@visx/shape';
import { Group } from '@visx/group';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex'
  },
  pieContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 4
  },
  subtitle: {
    color: theme.palette.text.secondary
  },
  legend: {
    flex: 5,
    padding: theme.spacing(1)
  },
  legendHighlight: {
    fontWeight: 600,
    textDecoration: 'underline'
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center'
  },
  legendPercentage: {
    width: '60px',
    marginRight: theme.spacing(1),
    textAlign: 'right'
  },
  legendLabel: {
    // need this for wrapping answer, size will be static
    flexBasis: '60%'
  },
  legendIcon: {
    display: 'inline-block',
    width: '12px',
    height: '12px',
    borderRadius: '6px',
    marginRight: theme.spacing(1)
  },
  noData: {
    flex: 1,
    height: '138px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const PieChart = ({ title, pieSize, data, question, colorsByAnswer }) => {
  const [active, setActive] = useState(null);

  // memoized total used for percentage values
  const total = useMemo(() => data.map((row) => row.value).reduce((prev, curr) => prev + curr, 0), [data]);

  // MUI
  const classes = useStyles();
  const theme = useTheme();

  if (!data || data.length === 0)
    return (
      <Box className={classes.noData} variant="outlined">
        <Typography>No data: {title}</Typography>
      </Box>
    );

  return (
    <Box className={classes.container} variant="outlined">
      <Box className={classes.pieContainer} style={{ width: pieSize * 2 + theme.spacing(1) }}>
        {/* TO-DO: port to iris-data-components */}
        <svg width={pieSize * 2 + theme.spacing(1)} height={pieSize * 2 + theme.spacing(1) * 2}>
          <Group top={pieSize + theme.spacing(1)} left={pieSize + theme.spacing(1)}>
            <Pie
              data={data}
              pieValue={(arcData) => arcData.value}
              outerRadius={pieSize}
              innerRadius={({ data }) => {
                // 'highlights' the segment if active
                if (active === null) return pieSize / 2;

                const size = active && active === data.answer ? 5 : 0;
                return pieSize / 2 - size;
              }}
            >
              {(pie) =>
                pie.arcs.map((arc) => (
                  <g key={arc.data.answer} onMouseEnter={() => setActive(arc.data.answer)} onMouseLeave={() => setActive(null)}>
                    <path d={pie.path(arc)} fill={colorsByAnswer[question][arc.data.answer]} />
                  </g>
                ))
              }
            </Pie>
          </Group>
        </svg>
      </Box>

      <Box className={classes.legend}>
        <Typography variant="subtitle1" className={classes.subtitle} gutterBottom={true}>
          {title}
        </Typography>
        {data.map((row) => (
          <Box key={`legend-${row.answer}`} className={classes.legendItem} onMouseEnter={() => setActive(row.answer)} onMouseLeave={() => setActive(null)}>
            <Typography className={clsx(classes.legendPercentage, active === row.answer ? classes.legendHighlight : null)}>{((row.value / total) * 100).toFixed(2)}%</Typography>
            <Box className={classes.legendIcon} style={{ backgroundColor: colorsByAnswer[question][row.answer] }} />
            <Typography className={clsx(classes.legendLabel, active === row.answer ? classes.legendHighlight : null)}>{row.answer}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

PieChart.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  pieSize: PropTypes.number,
  question: PropTypes.string,
  colorsByAnswer: PropTypes.object
};

PieChart.defaultProps = {
  pieSize: 60
};

export default React.memo(PieChart);
