import React, { useEffect, useState, useCallback, useMemo } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getAvailableGeographies } from '../../sentiment/services';
import { getMetadata } from '../services';
import { signalsActions, signalsSelectors } from '../signalsSlice';
import { pushSnackbar } from '../../../actions';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Ours
import VACGeographies from '../../sentiment/components/config/VACGeographies';
import ResultSettings from '../../ResultSettings';

const useStyles = makeStyles((theme) => ({
  config: {
    margin: theme.spacing(2)
  },
  configItem: {
    marginBottom: theme.spacing(2)
  },
  settingsBlurb: { marginRight: theme.spacing(1), lineHeight: '1.2em', textAlign: 'end', fontSize: '0.6rem' },
  slider: {
    width: '80%',
    marginTop: 50,
    marginLeft: '10%'
  }
}));

const NetworkFilters = () => {
  const classes = useStyles();

  // State
  const [availableCountries, setAvailableCountries] = useState([]);
  const MIN_SLIDER_VALUE = -200;
  const MAX_SLIDER_VALUE = 0;
  const [sliderValue, setSliderValue] = useState([MIN_SLIDER_VALUE, MAX_SLIDER_VALUE]);

  // Redux
  const selectedNetworkName = useSelector(signalsSelectors.selectNetworkNameFilter);
  const selectedNetworkType = useSelector(signalsSelectors.selectNetworkTypeFilter);
  const selectedGeographyFilters = useSelector(signalsSelectors.selectGeographyFilters);

  // Redux
  const dispatch = useDispatch();

  // Queries
  const metaQuery = getMetadata.useQuery();
  const geoQuery = getAvailableGeographies.useQuery();

  // handle meta query
  useEffect(() => {
    if (metaQuery.isError) {
      dispatch(
        pushSnackbar({
          type: 'error',
          message: 'Error getting metadata for signal strength'
        })
      );
    }
  }, [metaQuery.isError, dispatch]);

  useEffect(() => {
    if (geoQuery.isError) {
      dispatch(
        pushSnackbar({
          type: 'error',
          message: 'Error getting geographies'
        })
      );

      // if error we cannot continue, bail out:
      return;
    }

    // get only level 0 regions
    if (geoQuery.isSuccess && geoQuery.data) {
      setAvailableCountries(geoQuery.data.filter((geo) => geo.level === 0));
    }
  }, [geoQuery, dispatch]);

  // PASSED FUNCTIONS
  const sliderOnChange = useCallback((_, newValue) => {
    setSliderValue(newValue);
  }, []);
  const sliderOnCommited = useCallback((_, newValue) => {
    dispatch(signalsActions.setThresholdFilter([...newValue]));
  }, [dispatch]);
  const networkNamesFilterOnChange = useCallback((_, v) => {
    dispatch(signalsActions.setNetworkNameFilter(v));
  }, [dispatch]);
  const networkTypesFilterOnChange = useCallback((_, v) => {
    dispatch(signalsActions.setNetworkTypeFilter(v));
  }, [dispatch]);
  const geographyFilterOnChange = useCallback((v) => {
    dispatch(signalsActions.setGeographyFilters(v));
  }, [dispatch]);

  const numActiveFilters = useMemo(() => {
    let count = Number(!!selectedNetworkName) + Number(!!selectedNetworkType) + selectedGeographyFilters.length;
    if (sliderValue[0] !== 0 || sliderValue[1] !== MAX_SLIDER_VALUE) count += 1;
    return count;
  }, [selectedNetworkType, selectedNetworkName, selectedGeographyFilters, sliderValue, MAX_SLIDER_VALUE]);

  return (
    <ResultSettings label="Network Filters" numActiveFilters={numActiveFilters}>
      <Box className={classes.config}>
        {/* dbm threshold */}
        <Typography variant="body2">dbm:</Typography>
        <Paper variant="outlined" className={classes.configItem}>
          <Slider
            className={classes.slider}
            value={sliderValue}
            min={MIN_SLIDER_VALUE}
            max={MAX_SLIDER_VALUE}
            aria-labelledby="signals-input-slider"
            onChange={sliderOnChange}
            onChangeCommitted={sliderOnCommited}
            valueLabelDisplay="on"
            color="secondary"
            size="small"
          />
        </Paper>

        {/* NETWORK NAMES */}
        <Box className={classes.configItem}>
          <Autocomplete
            disabled={metaQuery.data?.network_names?.length === 0}
            options={metaQuery.data?.network_names ?? []}
            value={selectedNetworkName}
            onChange={networkNamesFilterOnChange}
            multiple={false}
            renderInput={(params) => <TextField {...params} placeholder="AT&T" variant="outlined" label="Filter by Network Name" />}
          />
        </Box>

        {/* NETWORK TYPES */}
        <Box className={classes.configItem}>
          <Autocomplete
            disabled={metaQuery.data?.network_types?.length === 0}
            options={metaQuery.data?.network_types ?? []}
            value={selectedNetworkType}
            onChange={networkTypesFilterOnChange}
            multiple={false}
            renderInput={(params) => <TextField {...params} placeholder="LTE" variant="outlined" label="Filter by Network Type" />}
          />
        </Box>

        {/* GEOGRAPHIES (just reused, it already exists :p) */}
        <Box className={classes.configItem}>
          <VACGeographies
            availableGeographies={availableCountries}
            disabled={availableCountries.length === 0}
            onGeographiesChanged={geographyFilterOnChange}
            selectedGeographies={selectedGeographyFilters}
          />
        </Box>
      </Box>
    </ResultSettings>
  );
};

export default React.memo(NetworkFilters);
