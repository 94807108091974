import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// REDUX TOOLKIT
import { demoActions, demoSelectors } from './demographicsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCountries } from './services';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

// MUI: ICONS
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

// GLOBALS
const useStyles = makeStyles((theme) => ({
  popover: {
    padding: theme.spacing(2),
    textTransform: 'capitalize',
    minWidth: '350px',
    maxWidth: '450px'
  },
  formControl: {
    margin: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(4)}px)`
  },
  select: {
    minWidth: '325px',
    maxWidth: '400px'
  }
}));

const CountryFilterChip = ({ label, chipName }) => {
  // Redux Toolkit
  const dispatch = useDispatch();
  const classes = useStyles();

  // Local
  const [anchorEl, setAnchorEl] = useState(null); // Used to manage popover on/off
  const [localSelectedCountries, setLocalSelectedCountries] = useState([]);
  const open = !!anchorEl;
  const id = open ? chipName : undefined;

  // Selectors
  const selectedCountries = useSelector(demoSelectors.selectCountryFilterChipCountries);
  const isActive = useSelector(demoSelectors.selectCountryFilterChipActive);

  // Queries
  const allCountriesQuery = getAllCountries.useQuery();

  const handleSelectedCountriesChange = useCallback((event) => {
    setLocalSelectedCountries(event.target.value);
  }, []);

  /**
   * User clicked chip -> Open the popover
   */
  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  /**
   * User closed the popover without clicking done -> Set the local filter answer back to
   * the last value saved in the redux store.
   */
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  /**
   * User clicked DONE -> Take the most recently selected value and publish it to the redux state so that
   * it is applied as a filter. Then close the popover.
   */
  const handleDone = useCallback(() => {
    dispatch(
      demoActions.clickedCountryFilterChipDone(
        localSelectedCountries.reduce((map, country) => {
          map[country] = true;
          return map;
        }, {})
      )
    );
    setAnchorEl(null);
  }, [localSelectedCountries, dispatch]);

  /**
   * User clicked CLEAR -> Both local and redux state values for answerFilter
   * should be set back to empty so that none are selected and none are applied.
   * Then close the popover.
   */
  const handleClear = useCallback(() => {
    setLocalSelectedCountries([]);
    dispatch(demoActions.clickedCountryFilterChipClear());
    setAnchorEl(null);
  }, [dispatch]);

  useEffect(() => {
    setLocalSelectedCountries(Object.keys(selectedCountries));
  }, [selectedCountries]);

  return (
    <React.Fragment>
      <Chip
        aria-describedby={id}
        variant="outlined"
        color={isActive ? 'secondary' : 'default'}
        label={label}
        deleteIcon={<ArrowDropDownIcon />}
        onClick={handleClick}
        onDelete={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleClose}
      >
        <Box className={classes.popover}>
          <Grid id="topRow" container={true} spacing={2} direction="row" alignItems="center" justifyContent="center">
            <Grid item={true}>
              <FormControl className={classes.formControl}>
                <FormLabel id="demo-label-sel-chip-answer">Country</FormLabel>
                <Select
                  MenuProps={{
                    getContentAnchorEl: () => null
                  }}
                  labelid="demo-label-sel-chip-answer"
                  value={localSelectedCountries}
                  className={classes.select}
                  defaultValue="Please select a question"
                  onChange={handleSelectedCountriesChange}
                  multiple={true}
                  renderValue={(selected) => {
                    return typeof selected === 'string' ? selected : selected.join(', ');
                  }}
                >
                  {allCountriesQuery.data &&
                    [...allCountriesQuery.data].sort().map((elem) => (
                      <MenuItem key={elem} value={elem}>
                        {elem}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Make a grid row for the Clear and Done buttons*/}
          <Grid container={true} direction="row" spacing={2}>
            <Grid item={true} xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="outlined" onClick={handleClear}>
                Clear
              </Button>
            </Grid>
            <Grid item={true} xs={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Button variant="outlined" color="secondary" disabled={localSelectedCountries.length === 0} onClick={handleDone}>
                Done
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </React.Fragment>
  );
};

// PROP DEFINITIONS
CountryFilterChip.propTypes = {
  chipName: PropTypes.string,
  label: PropTypes.string
};

// EXPORT COMPONENT
export default React.memo(CountryFilterChip);
