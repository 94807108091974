// iris
export const UPDATE_MAP_POSITION = 'UPDATE_MAP_POSITION';
export const TOGGLE_THEME = 'TOGGLE_THEME';
export const TOGGLE_GPU = 'TOGGLE_GPU';
export const ENLARGE_PHOTO = 'ENLARGE_PHOTO';
export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION';
export const POP_NOTIFICATION = 'POP_NOTIFICATION';
export const UPDATE_GUIDE = 'UPDATE_GUIDE';
export const GENERIC = 'GENERIC';

// products
export const PLACES_ACTIVE_PANEL = 'PLACES_ACTIVE_PANEL';
export const SENTIMENT_ACTIVE_PANEL = 'SENTIMENT_ACTIVE_PANEL';
export const RADIO_ACTIVE_PANEL = 'RADIO_ACTIVE_PANEL';
export const DEMO_ACTIVE_PANEL = 'DEMO_ACTIVE_PANEL';

export const VECTOR_TILESETS = {
  0: { style: 'ckx6c6ito0df414padliht7hg', layers: ['outline', 'outline-thin', 'gadm-premise-l0'] },
  1: { style: 'cl3xi1y2m001914qj9oza3ke7', layers: ['outline', 'outline-thin', 'gadm-premise-l1'] },
  2: { style: 'ckx6c1m340d8z14oat5vzz7kl', layers: ['outline', 'outline-thin', 'gadm-premise-l2'] }
};

export const MAP_STYLES = {
  mapbox: {
    satellite: {
      baseLayer: 'mapbox://styles/mapbox/satellite-v9'
    },
    streets: {
      dark: {
        baseLayer: 'mapbox://styles/joeraii/ckfx71myy07qb19mj97yktd43',
        labelLayer: 'mapbox://styles/joeraii/ckfze2e5i04kc19qnbjhvmxmz'
      },
      light: {
        baseLayer: 'mapbox://styles/joeraii/ckfzdxdya0gos19o0p9aicrp1',
        labelLayer: 'mapbox://styles/joeraii/ckfze680p16ys19jorgjxpvnr'
      }
    }
  },
  osm: {
    standard: {
      baseLayer: {
        version: 8,
        sources: {
          'raster-tiles': {
            type: 'raster',
            tiles: ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
            tileSize: 256,
            attribution: '<a href="https://www.openstreetmap.org/copyright">&copy; OpenStreetMap</a> contributors'
          }
        },
        layers: [
          {
            id: 'simple-tiles',
            type: 'raster',
            source: 'raster-tiles',
            minzoom: 0,
            maxzoom: 22
          }
        ]
      }
    }
  }
};

function getMapStyle(theme) {
  const s = MAP_STYLES[this.owner][this.type];
  if (s.dark) {
    return theme.palette.type === 'dark' ? s.dark : s.light;
  } else {
    return s;
  }
}
export const MAP_STYLES_FLAT = [
  { owner: 'mapbox', type: 'streets', label: 'Mapbox Streets', key: 'mapbox-streets' },
  { owner: 'mapbox', type: 'satellite', label: 'Mapbox Satellite', key: 'mapbox-satellite' },
  { owner: 'osm', type: 'standard', label: 'OSM Streets', key: 'osm-standard' }
].map((d) => ({ ...d, getMapStyle: getMapStyle.bind(d) }));

export const QUESTION_LOOKUP = {
  including_yourself_how_many_people_live_in_your_household: 'How many people are in your household?',
  which_of_the_following_best_describes_the_area_where_you_live: 'Where do you live?',
  what_is_your_highest_level_of_education: 'What is your highest level of education?',
  which_of_the_following_best_describes_your_financial_situation: 'What is your financial situation?',
  what_is_your_current_employment_status: 'What is your employment status?',
  what_is_your_religion: 'What is your religion?',
  what_is_your_primary_language: 'What is your primary language?',
  what_is_your_ethnicity_or_ancestry_below_are_some_common_responses_in_your_area: 'What is your ethnicity?',
  what_is_your_gender: 'What is your gender?',
  year_of_birth: 'What is your age?',
  are_you_currently_looking_for_a_job: 'Are you currently looking for a job?',
  what_is_your_marital_status: 'What is your marital status?',
  please_describe_your_living_situation: 'What is your living situation?',
  how_long_have_you_been_unemployed: 'How long have you been unemployed?',
  what_is_your_country_of_origin: 'What is your country of origin?'
};

export const LOGINOVERLAY_IMAGE_LIST = [
  'https://storage.googleapis.com/prod-premise-android-observation-us/7f52050f-c28b-4d7b-906b-cb78982100c6.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/610f7b5d-ff6c-44af-b8b2-66a79fc58bf3.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/2853d442-e2a5-4fc6-9cb3-316eb2f9372b.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/9dc3e228-607c-4082-8f2d-9f90e818a457.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/7ce58edd-d0d4-4af0-ab9c-4e6a78c4ebe6.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/becec0ad-3096-4b16-a0ea-93c98153a612.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/6a879cf6-77dc-43a6-8540-44bf4e30afc1.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/38bcbb06-8df0-49d2-acc8-a3b57fba16ba.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/71cc74ee-abbf-48a5-825b-e3ba7d27f35d.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/97432ca4-4653-45c6-b574-3b02170ec678.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/f4f81d6f-ddbd-4ad0-8d60-39a694b2f33b.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/383fdd90-1d26-46cb-a209-f117e6bcf70a.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/a250aac2-e82e-4983-9622-1bb74d186616.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/7a0100e4-1b47-4f29-85ed-e7043d522c0a.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/fa98139d-9e08-4b57-8844-5633aef16a0e.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/5d97cb12-fa4f-43ef-a913-8d2c7cc14aaa.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/1f369358-3a37-455b-889e-32f812617364.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/774a8de8-4451-49b7-92ce-da27fef08a0b.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/cf65acc8-16d4-450f-9463-623ad4f6107c.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/bb94ed69-b652-46c0-af7c-fc181ceedf59.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/5606bf52-4c35-4d27-88f5-b27b2bae9b9f.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/a3f71f28-624b-4ba3-8c0d-a68eace96e69.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/bcbfd0ce-5c6b-446d-a510-a7070a442bf6.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/6120dc50-6fcf-4da7-8a69-2ff6b898563e.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/cbfed795-fc9d-42c1-a764-dd8df5f4c1d5.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/cd333309-106e-440a-b5db-48906ca94852.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/7c2deeb1-dcdb-410e-9d7f-b19aaa393e5b.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/875ee7b3-84d5-4ddd-97c4-f387233ad975.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/0e07c82a-cf26-4c54-a99e-48341dfe0b50.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/ce21c044-ff00-4982-be69-c7e26c8f0f2a.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/12eb9774-bd2d-454d-86cd-ab2ba117800b.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/7d777510-9b80-4cca-be71-75581afd4530.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/e30ff13f-3bce-4c2f-8e70-dead0fe7f28b.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/8fd21b9f-251f-4c01-a463-76173790201f.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/323e8ddc-3d4e-42a7-959d-61440245e47a.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/709ab526-4c79-4da1-addc-4364be5075d2.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/77b0ea13-2ac5-4670-a3a7-1fbc6cf9ca40.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/7055716f-3e30-435d-835c-dcf3dea71740.jpg'
];
