import React, { PureComponent } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// UI/UX
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Popover from '@material-ui/core/Popover';

// ICONS
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const styles = (theme) => ({
  popover: {
    padding: theme.spacing(2),
    maxWidth: '35vw'
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  grid: {
    marginTop: theme.spacing(1.5)
  }
});

class ChipPopover extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    };

    this.onCancel = this.onCancel.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onClick = this.onClick.bind(this);
  }
  onClick(e) {
    if (this.props.disabled) return;
    this.setState({
      anchorEl: e.target
    });
  }
  onCancel() {
    const { onCancel, onDone, ignoreClickAway } = this.props;
    if (ignoreClickAway) {
      return;
    }

    let shouldClose = true;

    // prefer cancel over done, if onCancel returns truthy
    // do not close the popover.
    if (onCancel) {
      if (onCancel()) {
        shouldClose = false;
      }
    } else if (onDone) {
      onDone();
    }

    if (shouldClose) {
      this.close();
    }
  }
  // public: for use w/ React.createRef()
  close() {
    this.setState({
      anchorEl: null
    });
  }
  onClose() {
    this.close();
    const { onDone } = this.props;
    if (onDone) {
      onDone();
    }
  }
  render() {
    const { classes, children, label, maxWidth, clearDone, doneDisabled, active, onClear, className, id, disabled, popoverStyle, popoverOrigins } = this.props;

    const { anchorEl } = this.state;
    const chipStyle = { maxWidth };

    if (disabled) {
      chipStyle.opacity = 0.5;
    }
    return (
      <React.Fragment>
        <Chip
          id={id}
          style={chipStyle}
          className={clsx(maxWidth > 0 && classes.ellipsis, className, 'noselect')}
          variant="outlined"
          color={active ? 'secondary' : 'default'}
          label={label}
          deleteIcon={<ArrowDropDownIcon />}
          onClick={disabled ? undefined : this.onClick}
          onDelete={disabled ? undefined : this.onClick}
        />
        <Popover open={!!anchorEl} anchorEl={anchorEl} onClose={this.onCancel} {...popoverOrigins}>
          <Box className={classes.popover} style={popoverStyle}>
            {children}
            {clearDone && (
              <Grid container={true} direction="row" spacing={2} className={classes.grid}>
                <Grid item={true} xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="outlined" onClick={onClear}>
                    Clear
                  </Button>
                </Grid>
                <Grid item={true} xs={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Button variant="outlined" color="secondary" disabled={doneDisabled} onClick={this.onClose}>
                    Done
                  </Button>
                </Grid>
              </Grid>
            )}
          </Box>
        </Popover>
      </React.Fragment>
    );
  }
}

ChipPopover.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,

  label: PropTypes.string,
  maxWidth: PropTypes.number,
  doneDisabled: PropTypes.bool,
  active: PropTypes.bool,
  clearDone: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  ignoreClickAway: PropTypes.bool,
  popoverStyle: PropTypes.object,
  popoverOrigins: PropTypes.object,

  onClear: PropTypes.func,
  onDone: PropTypes.func,
  onCancel: PropTypes.func
};

ChipPopover.defaultProps = {
  label: 'Untitled',
  doneDisabled: false,
  clearDone: false,
  active: false,
  ignoreClickAway: false,
  popoverStyle: {},
  popoverOrigins: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center'
    }
  }
};
export default withTheme(withStyles(styles)(ChipPopover));
