import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';

import ChipPopover from './ChipPopover';

const styles = (/* theme */) => ({});
class ChipPopoverRadioInstant extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};

    // BIND
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    this.props.onChange(e.target.value);
  }
  render() {
    const { options, value, maxWidth, label, disabled, popoverOrigins, active, renderOption } = this.props;

    return (
      <ChipPopover
        onClear={this.onClear}
        onDone={this.onDone}
        onCancel={this.onCancel}
        active={active}
        label={label}
        maxWidth={maxWidth}
        disabled={disabled}
        popoverOrigins={popoverOrigins}
      >
        <FormControl component="fieldset">
          <FormLabel component="legend">{label}</FormLabel>
          <RadioGroup value={value} onChange={this.onChange}>
            {options.map((d) => {
              const label = renderOption ? renderOption(d) : d;
              return <FormControlLabel key={label} value={d} control={<Radio />} label={label} />;
            })}
          </RadioGroup>
        </FormControl>
      </ChipPopover>
    );
  }
}

ChipPopoverRadioInstant.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  label: PropTypes.string,
  maxWidth: PropTypes.number,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  clearDone: PropTypes.bool,
  className: PropTypes.string,
  popoverOrigins: PropTypes.object,
  renderOption: PropTypes.func,

  onChange: PropTypes.func
};

ChipPopoverRadioInstant.defaultProps = {
  options: [],
  value: [],
  clearDone: true
};

export default withTheme(withStyles(styles)(ChipPopoverRadioInstant));
