import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// CSS
import '@fontsource/roboto';
import 'intro.js/introjs.css';
import 'react-virtualized/styles.css';
import 'leaflet/dist/leaflet.css';
import 'mapbox-gl/dist/mapbox-gl.css';

// MODULES
import L from 'leaflet';
import mapboxgl from 'mapbox-gl';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { load } from 'protobufjs';
import amplitude from 'amplitude-js';
import * as FullStory from '@fullstory/browser';

// MUI
import { ThemeProvider, makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { lightTheme, darkTheme } from './AppThemes';
import CssBaseline from '@material-ui/core/CssBaseline';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CachedIcon from '@material-ui/icons/Cached';

// OURS
import { BETA_PRODUCTS, BETA_USERS } from './beta-users';
import { Features as FEATURES, has } from '@premisedata/lib-features';
import { genericGet, API_HOST, genericPost } from 'iris-api'; // eslint-disable-line import/no-unresolved
import { updateQueryStringValue } from 'iris-util'; // eslint-disable-line import/no-unresolved
import { setGlobal, popSnackbar as popSnackbarAction, pushSnackbar as pushSnackbarAction } from './actions';
import store, { dumpStateglobal } from './store';
import { resendEmailVerification } from './services';

// OURS - COMPONENTS
import AboutModal from './components/AboutModal';
import AppBar from './AppBar';
import DemographicsView from './components/demographics/View';
import SignalsView from './components/signals/View';
import ErrorBoundary from './components/ErrorBoundary';
import FeedbackModal from './components/FeedbackModal';
import { Notification } from '@premisedata/lib-iris-common';
import PhotoModal from './components/PhotoModal';
import PlacesView from './components/places/View';
import RadioView from './components/radio/View';
import SentimentView from './components/sentiment/View';
import SettingsModal from './components/SettingsModal';
import Splash from './components/Splash';
import LoginOverlay from './LoginOverlay';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));

const VerifiedButton = withStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.getContrastText(theme.palette.success.main),
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.getContrastText(theme.palette.success.light)
    }
  }
}))(Button);

const ResendButton = withStyles((theme) => ({
  root: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.getContrastText(theme.palette.info.main),
    backgroundColor: theme.palette.info.main,
    '&:hover': {
      backgroundColor: theme.palette.info.light,
      color: theme.palette.getContrastText(theme.palette.info.light)
    }
  }
}))(Button);

const useStyles = makeStyles({
  centeredBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    flexDirection: 'column'
  }
});

const App = () => {
  // MUI
  const theme = useTheme();
  const classes = useStyles();

  // REDUX
  const dispatch = useDispatch();

  // Local Refs
  const viewRef = useRef();
  const xhrProfile = useRef({});
  const xhrHashShare = useRef({});
  const xhrHashLoad = useRef({});
  const refFirebaseUser = useRef(null);
  const refAmplitudeInitialized = useRef(false);

  // Local State
  const [aboutModalOpen, setAboutModalOpen] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [emailNeedsToBeVerified, setEmailNeedsToBeVerified] = useState(false);

  // Selectors
  const hasGPUHardware = useSelector((state) => state.app.hasGPUHardware);
  const activeProduct = useSelector((state) => state.app.activeProduct);
  const notificationQueue = useSelector((state) => state.app.notificationQueue);
  const user = useSelector((state) => state.app.user);
  const initialQueryString = useSelector((state) => state.app.initialQueryString);
  const palette = useSelector((state) => state.app.palette);

  // Services
  const [resendEmailVerificationQuery, resendEmailVerificationResult] = resendEmailVerification.useLazyQuery();

  // useCallback
  const popSnackbar = useCallback(() => {
    dispatch(popSnackbarAction());
  }, [dispatch]);
  const onSettingsModal = useCallback(() => {
    setSettingsModalOpen(true);
  }, []);
  const onSettingsModalClosed = useCallback(() => {
    setSettingsModalOpen(false);
  }, []);
  const onAboutModal = useCallback(() => {
    setAboutModalOpen(true);
  }, []);
  const onAboutModalClosed = useCallback(() => {
    setAboutModalOpen(false);
  }, []);
  const onShareFeedbackModal = useCallback(() => {
    setFeedbackModalOpen(true);
  }, []);
  const onShareFeedbackModalClosed = useCallback(() => {
    setFeedbackModalOpen(false);
  }, []);
  const onResendEmailVerification = useCallback(() => {
    resendEmailVerificationQuery(refFirebaseUser.current.uid);
  }, [resendEmailVerificationQuery]);
  const onIVerifiedMyEmail = useCallback(() => {
    if (!refFirebaseUser.current) return;
    refFirebaseUser.current
      .reload()
      .then(async () => {
        // verify the user's new status:
        if (refFirebaseUser.current.emailVerified === false) {
          return dispatch(pushSnackbarAction({ type: 'error', message: 'Please click the link in the verification email to continue.' }));
        }
        // we need to force the UI to get a new token w/ the updated
        // profile metadata:
        await window.getFirebaseToken(true);

        // reload the page:
        location.reload();
      })
      .catch((e) => {
        console.error(e);
      });
  }, [dispatch]);
  const onExport = useCallback(() => {
    viewRef.current?.doExportModal?.();
  }, []);
  const onDumpStateGlobal = useCallback(() => {
    return {
      state: {
        globalState: dumpStateglobal(),
        productState: viewRef.current.buildHashStateObject(store)
      }
    };
  }, []);
  const onShareLink = useCallback(
    (viewName, callback) => {
      xhrHashShare.current.cancel?.();

      const body = {
        state: {
          globalState: dumpStateglobal(),
          productState: viewRef.current.buildHashStateObject(store)
        },
        view_name: viewName ?? 'Untitled'
      };

      genericPost(`${API_HOST}/places/v0/hashes/share`, body, xhrHashShare.current, (e, r) => {
        if (callback) {
          callback(e, r.hash);
        } else {
          if (e) return dispatch(pushSnackbarAction({ type: 'error', message: 'Failed to generate link, please try again later.' }));
          navigator.clipboard
            .writeText(`${window.location.origin}/?h=${r.hash}`)
            .then(() => {
              dispatch(pushSnackbarAction({ message: 'Link copied to clipboard', type: 'success' }));
            })
            .catch((e) => {
              console.error(e);
              dispatch(pushSnackbarAction({ message: 'Failed to copy link to clipboard', type: 'error' }));
            });
        }
      });
    },
    [dispatch]
  );

  const onLogout = useCallback(() => {
    window.getFirebaseToken = null;
    FullStory.anonymize();
    signOut(getAuth()).catch((e) => {
      console.error(e);
    });
  }, []);

  // Helpers Functions
  const availableViewFromFeatures = (user) => {
    // take hints from query parameters,
    if (initialQueryString.form_id && has(user.active_features, FEATURES.SENTIMENT)) {
      return 'sentiment';
    }
    if (initialQueryString.place_id && has(user.active_features, FEATURES.PLACES)) {
      return 'places';
    }

    for (const p of ['PLACES', 'SENTIMENT', 'RADIO', 'DEMOGRAPHICS', 'SIGNALS']) {
      if (has(user.active_features, FEATURES[p])) {
        if (!BETA_USERS.includes(user.email) && BETA_PRODUCTS.includes(FEATURES[p])) continue;
        return p.toLowerCase();
      }
    }
    return null;
  };

  const loadWithoutState = (serializableUser, e) => {
    if (e) dispatch(pushSnackbarAction({ type: 'error', message: 'Failed to load the saved view.' }));
    dispatch(
      setGlobal({
        activeProduct: availableViewFromFeatures(serializableUser),
        user: serializableUser
      })
    );
  };

  const loadWithState = (serializableUser, serializedState) => {
    const activeProduct = serializedState?.state_global?.app_props?.activeProduct ?? serializedState?.globalState?.activeProduct;
    if (!activeProduct) return loadWithoutState(serializableUser, true);

    dispatch(
      setGlobal({
        activeProduct,
        user: serializableUser,
        serializedState
      })
    );
  };

  // useEffect
  useEffect(() => {
    if (resendEmailVerificationResult.status === 'fulfilled') {
      amplitude.getInstance().logEvent('email_verification_sent');
      dispatch(pushSnackbarAction({ type: 'success', message: 'Email Verification sent!' }));
    } else if (resendEmailVerificationResult.status === 'rejected') {
      amplitude.getInstance().logEvent('email_verification_failed_to_send', {
        status: resendEmailVerificationResult.error?.status,
        original_status: resendEmailVerificationResult.error?.originalStatus
      });
      if (resendEmailVerificationResult.error?.status === 429) {
        dispatch(pushSnackbarAction({ type: 'error', message: 'Failed to send Email Verification, please try again in a few minutes...' }));
      } else {
        dispatch(pushSnackbarAction({ type: 'error', message: 'Failed to send Email Verification, unknown error' }));
      }
    }
  }, [resendEmailVerificationResult.status, resendEmailVerificationResult.error, dispatch]);
  useEffect(() => {
    // componentDidMount
    window.theme = theme;
    const firebaseAuth = getAuth();

    load('static/iris.proto', (e, root) => {
      if (e) {
        return dispatch(pushSnackbarAction({ type: 'error', message: 'Fatal error, unable to recover. Please let us know.' }));
      }

      window.irisProto = {
        Points: root.lookupType('iris.Points'),
        Hexagons: root.lookupType('iris.Hexagons'),
        RadioPoints: root.lookupType('iris.RadioPoints'),
        ThinResponse: root.lookupType('iris.ThinResponse')
      };
    });

    onAuthStateChanged(firebaseAuth, (firebaseUser) => {
      refFirebaseUser.current = firebaseUser;
      if (!firebaseUser) {
        dispatch(
          setGlobal({
            user: null
          })
        );

        return;
      }

      window.getFirebaseToken = (validate) =>
        firebaseUser.getIdToken(validate).catch((e) => {
          console.error(e);
          dispatch(
            setGlobal({
              user: null,
              notificationQueue: []
            })
          );
          return '';
        });

      if (firebaseUser.emailVerified === false) {
        resendEmailVerificationQuery(refFirebaseUser.current.uid);
        return setEmailNeedsToBeVerified(true);
      }

      xhrProfile.current.cancel?.();
      genericGet(`${API_HOST}/iris/v0/profile`, xhrProfile.current, (e, user) => {
        if (e || !user) {
          // we have a user, but profile failed to fetch ~ we should display an error.
          return dispatch(pushSnackbarAction({ type: 'error', message: 'Authentication was successful, but failed to retrieve user profile, unable to continue.' }));
        }
        if (!refAmplitudeInitialized.current) {
          FullStory.setUserVars({
            scope_name: user.scope_name,
            scope_id: user.scope_id,
            gpu: hasGPUHardware,
            screen_width: window.screen?.width ?? 0,
            env: window.location.host === 'iris.premise.com' ? 'prod' : 'dev',
            user_id: firebaseUser.uid,
            is_admin: user.is_admin
          });
          amplitude.getInstance().init('4be4ca1645274a4c71701658166b05e1', firebaseUser.uid, {
            batchEvents: true,
            eventUploadPeriodMillis: 10000,
            eventUploadThreshold: 5
          });
          const identify = new amplitude.Identify()
            .set('is_admin', user.is_admin)
            .set('scope_id', user.scope_id)
            .set('scope_name', user.scope_name)
            .set('gpu', hasGPUHardware)
            .set('screen_width', window.screen?.width ?? 0)
            .set('env', window.location.host === 'iris.premise.com' ? 'prod' : 'dev');

          amplitude.getInstance().identify(identify);
          refAmplitudeInitialized.current = true;
        }

        const re = /^\/[\w/]+/;
        if (initialQueryString.redirect) {
          if (initialQueryString.redirect.match(re)?.[0] === initialQueryString.redirect) {
            window.location.pathname = initialQueryString.redirect;
          } else {
            updateQueryStringValue('redirect', '');
          }
        }

        const serializableUser = {
          active_features: user.active_features,
          intros: user.intros,
          is_admin: user.is_admin,
          name: user.name,
          scope_id: user.scope_id,
          scope_name: user.scope_name,
          uid: user.uid,
          email: user.email,
          email_verified: firebaseUser.emailVerified
        };

        const hash = initialQueryString.h;
        if (hash) {
          xhrHashLoad.current.cancel?.();
          genericPost(`${API_HOST}/places/v0/hashes`, { hash }, xhrHashLoad.current, (e, r) => {
            if (e) return loadWithoutState(serializableUser, e);
            loadWithState(serializableUser, { ...r.state, hash: r.hash, scope_id: r.scope_id });
          });
        } else {
          loadWithoutState(serializableUser);
        }
      }); // genericGet(/iris/v0/profile)
    }); // onAuthStateChanged()

    // https://stackoverflow.com/questions/67069827/cleanup-ref-issues-in-react
    const xhrHashLoadRef = xhrHashLoad.current ?? null;
    const xhrHashShareRef = xhrHashShare.current ?? null;
    const xhrProfileRef = xhrProfile.current ?? null;
    return () => {
      // componentWillUnmount
      xhrHashLoadRef?.cancel?.();
      xhrHashShareRef?.cancel?.();
      xhrProfileRef?.cancel?.();
    };
  }, []); //eslint-disable-line
  const activeTheme = palette === 'dark' ? darkTheme : lightTheme;
  const shouldRenderProduct = !!user && window.irisProto && activeProduct;
  const skipSplash = Object.keys(initialQueryString).length > 0 || process.env.NODE_ENV === 'development';
  return (
    <ThemeProvider theme={activeTheme}>
      <CssBaseline />
      <LoginOverlay />
      <Notification popSnackbar={popSnackbar} notificationQueue={notificationQueue} />
      {emailNeedsToBeVerified && (
        <Box className={classes.centeredBox}>
          <Alert
            variant="filled"
            severity="error"
            action={
              <>
                <ResendButton size="small" onClick={onResendEmailVerification}>
                  Resend
                </ResendButton>
                <Button size="small" onClick={onLogout}>
                  Logout
                </Button>
                <VerifiedButton size="small" onClick={onIVerifiedMyEmail} startIcon={<CachedIcon fontSize="small" />}>
                  I verified
                </VerifiedButton>
              </>
            }
          >
            Please verify your email address to continue.
          </Alert>
        </Box>
      )}

      {/* This is placed here to alert the user if they don't have access to Iris */}
      {user && !activeProduct && (
        <Box className={classes.centeredBox}>
          <Alert
            variant="filled"
            severity="error"
            action={
              <Button size="small" onClick={onLogout}>
                Logout
              </Button>
            }
          >
            Permissions not set. Please contact your Premise sales rep for support!
          </Alert>
        </Box>
      )}

      {shouldRenderProduct && (
        <>
          <AppBar
            onExport={onExport}
            onShareLink={onShareLink}
            onSettings={onSettingsModal}
            onAbout={onAboutModal}
            onLogout={onLogout}
            onShareFeedback={onShareFeedbackModal}
            currentViewRef={viewRef}
          />
          {activeProduct === 'places' && (
            <ErrorBoundary activeProduct={activeProduct}>
              <PlacesView ref={viewRef} />
            </ErrorBoundary>
          )}
          {activeProduct === 'sentiment' && (
            <ErrorBoundary activeProduct={activeProduct}>
              <SentimentView ref={viewRef} />
            </ErrorBoundary>
          )}
          {activeProduct === 'demographics' && (
            <ErrorBoundary activeProduct={activeProduct}>
              <DemographicsView ref={viewRef} />
            </ErrorBoundary>
          )}
          {activeProduct === 'radio' && (
            <ErrorBoundary activeProduct={activeProduct}>
              <RadioView ref={viewRef} />
            </ErrorBoundary>
          )}
          {activeProduct === 'signals' && (
            <ErrorBoundary activeProduct={activeProduct}>
              <SignalsView ref={viewRef} />
            </ErrorBoundary>
          )}

          <AboutModal
            open={aboutModalOpen}
            onClose={onAboutModalClosed}
            irisGitSha={process.env.REACT_APP_GIT_SHA}
            irisBuildDate={process.env.REACT_APP_BUILD_TIME}
            irisBuildOS={process.env.REACT_APP_BUILD_OS}
          />
          {settingsModalOpen && <SettingsModal viewRef={viewRef} onClose={onSettingsModalClosed} />}
          <FeedbackModal onSubmit={onShareFeedbackModalClosed} open={feedbackModalOpen} onClose={onShareFeedbackModalClosed} onDumpStateGlobal={onDumpStateGlobal} />
          <PhotoModal />
          {!skipSplash && (
            <ErrorBoundary activeProduct={'splash'}>
              <Splash />
            </ErrorBoundary>
          )}
        </>
      )}
    </ThemeProvider>
  );
};

export default App;
