// Core
import React, { useEffect, useMemo } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { demoActions, demoSelectors } from './demographicsSlice';
import { getBoundedRegionSummaries, getBoundedRegionAnswerInfo, getBoundedRegions, getAllQuestionsWithAnswers } from './services';

// UI/UX
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// Ours
import { DemographicResultsPaper } from '@premisedata/lib-iris-common';
import VirtualizedList from '../VirtualizedList';
import { filterSummaries } from './demographicsUtility';
import useIsMount from '../../hooks/useIsMount';

const useStyles = makeStyles(() => ({
  resultsPanel: {
    flex: 1,
    width: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  results: {
    flex: 1,
    width: '100%'
  },
  progress: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const ResultsPanel = () => {
  // Mui
  const classes = useStyles();
  const theme = useTheme();

  // Redux
  const dispatch = useDispatch();

  // Selectors
  const mapLevel = useSelector(demoSelectors.selectMapLevel);
  const selectedDetail = useSelector(demoSelectors.selectSelectedDetail);
  const selectedQuestion = useSelector(demoSelectors.selectSelectedQuestion);
  const mapPolygon = useSelector((state) => state.app.mapPolygon);
  const colorsByAnswer = useSelector(demoSelectors.selectColorsByAnswer);
  const choroplethAnswer = useSelector(demoSelectors.selectChoroplethAnswer);
  const boundedRegionAnswerInfoParams = useSelector(demoSelectors.boundedRegionAnswerInfoParams);
  const currentQuestionRegionSummariesParams = useSelector(demoSelectors.currentQuestionRegionSummariesParams);

  // Filters
  const numResponsesRange = useSelector(demoSelectors.selectNumResponsesRange);

  const sortOption = useSelector(demoSelectors.selectSortChipSortOptionName);
  const sortDirection = useSelector(demoSelectors.selectSortChipSortDirection);

  const filterAnswers = useSelector(demoSelectors.selectAnswerFilterChipAnswers);
  const answerInclusionSetting = useSelector(demoSelectors.selectAnswerFilterChipInclusionSetting);
  const onlyMostPrevalent = useSelector(demoSelectors.selectAnswerFilterChipOnlyMostPrevalent);

  const filterSets = useSelector(demoSelectors.selectQAFilterChipSets);
  const filterCountries = useSelector(demoSelectors.selectCountryFilterChipCountries);

  // Queries
  const allQAQuery = getAllQuestionsWithAnswers.useQuery();
  const visibleRegionsQuery = getBoundedRegions.useQuery({ bbox: mapPolygon, level: mapLevel }, { skip: mapPolygon === undefined });
  const answerInfoQuery = getBoundedRegionAnswerInfo.useQuery(boundedRegionAnswerInfoParams, { skip: !boundedRegionAnswerInfoParams });
  const currentQuestionRegionSummariesQuery = getBoundedRegionSummaries.useQuery(currentQuestionRegionSummariesParams, { skip: !currentQuestionRegionSummariesParams });

  /**
   * Once we have the raw API data, filter it by applying UI filters.
   */
  const filteredSummaries = useMemo(() => {
    if (
      answerInfoQuery.data &&
      !answerInfoQuery.isFetching &&
      currentQuestionRegionSummariesQuery.data &&
      !currentQuestionRegionSummariesQuery.isFetching &&
      allQAQuery.data &&
      !allQAQuery.isFetching &&
      colorsByAnswer
    ) {
      return filterSummaries(
        currentQuestionRegionSummariesQuery.data,
        answerInfoQuery.data,
        selectedQuestion,
        filterAnswers,
        answerInclusionSetting,
        onlyMostPrevalent,
        filterSets,
        filterCountries
      );
    }
    return null;
  }, [
    allQAQuery.data,
    answerInfoQuery.data,
    currentQuestionRegionSummariesQuery.data,
    selectedQuestion,
    colorsByAnswer,
    filterAnswers,
    filterSets,
    filterCountries,
    answerInclusionSetting,
    onlyMostPrevalent,
    allQAQuery.isFetching,
    answerInfoQuery.isFetching,
    currentQuestionRegionSummariesQuery.isFetching
  ]);

  /**
   * Once the summaries have been filtered, calculate a separate set of them which are only the
   * ones that are visible. We separate these out so that we can perform UI operations on both just the cached data
   * and also on the much more frequently updating visible data without coupling them together.
   */
  const visibleFilteredSummaries = useMemo(() => {
    if (visibleRegionsQuery.data && !visibleRegionsQuery.isFetching && filteredSummaries) {
      return filteredSummaries.filter((summary) => {
        return visibleRegionsQuery.data[summary.region];
      });
    }
    return null;
  }, [visibleRegionsQuery, filteredSummaries]);

  /**
   * Once we have the visible filtered summaries, make sure they are within the user's
   * specified range.
   */
  const visibleFilteredSummariesWithinRange = useMemo(() => {
    if (visibleFilteredSummaries && numResponsesRange.length === 2) {
      const upper = Math.max(...numResponsesRange);
      const lower = Math.min(...numResponsesRange);
      return visibleFilteredSummaries.filter((summary) => {
        return summary.respondents >= lower && summary.respondents <= upper;
      });
    }
    return null;
  }, [visibleFilteredSummaries, numResponsesRange]);

  /**
   * Once we have the filtered summaries within the specified range, make sure
   * they are sorted according to user preference. This is separate from the filters because
   * changing this value does not change the number of summaries displayed. By separating it we
   * reduce the frequency of expensive filtering operations.
   */
  const sortedVisibleFilteredSummariesWithinRange = useMemo(() => {
    if (visibleFilteredSummariesWithinRange) {
      if (sortDirection && sortOption) {
        if (sortDirection === 'ascending') {
          return visibleFilteredSummariesWithinRange.sort((summaryA, summaryB) => {
            return summaryA[sortOption] < summaryB[sortOption] ? -1 : 1;
          });
        } else if (sortDirection === 'descending') {
          return visibleFilteredSummariesWithinRange.sort((summaryA, summaryB) => {
            return summaryA[sortOption] > summaryB[sortOption] ? -1 : 1;
          });
        } else {
          return null;
        }
      }
      return visibleFilteredSummariesWithinRange;
    }
    return null;
  }, [visibleFilteredSummariesWithinRange, sortOption, sortDirection]);

  // Effects

  /**
   * If the filtered summaries or allowed range of responses change, we need to update the
   * deckmap. The reason we use filteredSummaries as an additional memoized value is that we want
   * to update the deckmap as little as possible. We can achieve this by changing the displayed regions
   * when filteredSummaries changes since is reflects the current cached data instead of the constantly changing
   * visible data.
   */
  useEffect(() => {
    if (filteredSummaries && numResponsesRange.length === 2) {
      const upper = Math.max(...numResponsesRange);
      const lower = Math.min(...numResponsesRange);
      dispatch(
        demoActions.setDisplayedRegions(
          filteredSummaries
            .filter((summary) => {
              return summary.respondents >= lower && summary.respondents <= upper;
            })
            .reduce((map, summary) => {
              map[summary.region] = colorsByAnswer[selectedQuestion][summary.mostPrevalentAnswer];
              return map;
            }, {})
        )
      );
    }
  }, [filteredSummaries, numResponsesRange, colorsByAnswer, dispatch, selectedQuestion]);

  /**
   * If the visible summaries within the specified range change, make sure the
   * necessary data is available and update the legend content to reflect all unique displayed
   * answers.
   */
  useEffect(() => {
    if (visibleFilteredSummariesWithinRange && allQAQuery.data && !allQAQuery.isFetching) {
      const visibleAnswers = {};

      visibleFilteredSummariesWithinRange.forEach((summary) => {
        if (!visibleAnswers[summary.mostPrevalentAnswer]) {
          visibleAnswers[summary.mostPrevalentAnswer] = colorsByAnswer?.[selectedQuestion]?.[summary.mostPrevalentAnswer];
        }
      });

      dispatch(
        demoActions.setLegendContent(
          allQAQuery.data[selectedQuestion].answers
            .filter((answer) => visibleAnswers[answer] || answer === choroplethAnswer)
            .map((answer) => [answer, visibleAnswers[answer] ?? colorsByAnswer?.[selectedQuestion]?.[answer]])
        )
      );
    }
  }, [visibleFilteredSummariesWithinRange, allQAQuery, selectedQuestion, choroplethAnswer, colorsByAnswer, dispatch]);

  /**
   * If the visible summaries change, the new maximum has to be calculated.
   * Calculate it and then if the state is new (numResponseRange will be []), set the response range to
   * include all results. Update the max value across the app.
   */
  useEffect(() => {
    if (visibleFilteredSummaries) {
      let maxValue = null;
      if (visibleFilteredSummaries.length === 0) {
        maxValue = 0;
      } else {
        maxValue =
          Math.ceil(
            Math.max.apply(
              Math,
              visibleFilteredSummaries.map((summary) => summary.respondents)
            ) / 100
          ) * 100;
      }

      dispatch(demoActions.setMaxResponses(maxValue));
      if (numResponsesRange.length < 2) {
        dispatch(demoActions.setNumResponsesRange([0, maxValue]));
      }
    }
  }, [visibleFilteredSummaries, dispatch, numResponsesRange.length]);

  /**
   * If the visible regions change, make sure the detail is one of them. If it isnt,
   * stop displaying by setting it to null.
   */
  useEffect(() => {
    if (visibleRegionsQuery.data && selectedDetail) {
      if (!visibleRegionsQuery.data[selectedDetail.region]) {
        dispatch(demoActions.setSelectedDetail(null));
      }
    }
  }, [visibleRegionsQuery, dispatch, selectedDetail]);

  /**
   * If mapLevel changes, set the selected detail to null
   */
  const isMount = useIsMount();
  useEffect(() => {
    if (isMount) return;

    dispatch(demoActions.setSelectedDetail(null));
    // isMount should not be in dependency array, its a hacky solution already
  }, [dispatch, mapLevel]); //eslint-disable-line

  /**
   *  Handle selected detail change
   * @param {*} data
   */
  const onClickResult = (data) => {
    dispatch(demoActions.setSelectedDetail(data));
  };

  // TODO: Separate numResponsesRange from filter. Use a new useEffect and local state that reacts to numResponses and filteredSummaries.
  if (!sortedVisibleFilteredSummariesWithinRange || allQAQuery.isFetching || currentQuestionRegionSummariesQuery.isFetching || answerInfoQuery.isFetching) {
    return (
      <div className={classes.progress}>
        <CircularProgress color="secondary" />
      </div>
    );
  }

  return (
    <div className={classes.resultsPanel}>
      <div className={classes.results}>
        <VirtualizedList
          // Loading doesnt matter since its the same condition as the above conditional render. Need to fix fetching component positioning
          // For now, the above condition renders a centered progress bar
          loading={false}
          rowHeight={95 + theme.spacing(1)}
          rowCount={sortedVisibleFilteredSummariesWithinRange.length}
          rowRenderer={({ index, style, key }) => {
            return (
              <DemographicResultsPaper
                name={sortedVisibleFilteredSummariesWithinRange[index].name}
                count={sortedVisibleFilteredSummariesWithinRange[index].respondents}
                color={colorsByAnswer[selectedQuestion][sortedVisibleFilteredSummariesWithinRange[index].mostPrevalentAnswer]}
                population={sortedVisibleFilteredSummariesWithinRange[index].population}
                onClick={() => onClickResult(sortedVisibleFilteredSummariesWithinRange[index])}
                key={key}
                style={style}
                active={selectedDetail?.region === sortedVisibleFilteredSummariesWithinRange[index].region}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default ResultsPanel;
