import React, { Component } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// beautiful-dnd
import { Droppable, DragDropContext, Draggable } from 'react-beautiful-dnd';

// UI/UX
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

// ICONS
import MenuIcon from '@material-ui/icons/Menu';

// OURS: REDUX
import { irisApi } from '../../../services';

// ours
import { genericPost, API_HOST } from 'iris-api'; // eslint-disable-line import/no-unresolved

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 0,
    overflowY: 'auto',

    // Positioning:
    width: '50%',
    maxHeight: '80%',
    top: '10%',
    left: '25%'
  },
  marginTopCompact: {
    marginTop: theme.spacing(1),
    '&:first-of-type': {
      marginTop: 0
    }
  },
  radioFormControl: {
    margin: theme.spacing(1, 0, 3)
  },
  dragDropContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  droppableContainer: {
    width: '50%',
    border: `1px solid ${theme.palette.divider}`,
    margin: theme.spacing(1, 2, 2, 0),
    height: '100%'
  },
  title: { margin: theme.spacing(1) },
  responseList: {
    padding: theme.spacing(1),
    transition: 'background-color 0.2s ease',
    flexGrow: 1,
    minHeight: 100
  }
});

class QuestionConfigModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responses: null,
      excludedResponses: [],
      type: 'ordered'
    };

    this.initialState = {
      responses: null,
      excludedResponses: [],
      type: 'ordered'
    };

    this.id2List = {
      consider: 'responses',
      exclude: 'excludedResponses'
    };

    // BIND
    this.onResponseDragEnd = this.onResponseDragEnd.bind(this);
    this.onRequest = this.onRequest.bind(this);
    this.onCancel = this.onCancel.bind(this);

    // XHR
    this._xhrRequestData = {};
  }
  componentWillUnmount() {
    this._xhrRequestData.cancel && this._xhrRequestData.cancel();
  }
  componentDidMount() {
    this.componentDidUpdate({}, {});
  }
  componentDidUpdate(prevProps) {
    if (this.props.question && this.props.question !== prevProps.question) {
      this.setState({
        ...this.initialState,
        responses: this.props.question.responses
      });
    }
    if (!this.props.question && this.state.responses) {
      this.setState(this.initialState);
    }
  }
  // helper for onResponseDragEnd
  getList(droppableId) {
    return this.state[this.id2List[droppableId]];
  }
  // helper for onResponseDragEnd
  reorder(list, srcIdx, destIdx) {
    const listClone = Array.from(list);
    // remove the target:
    const [removed] = listClone.splice(srcIdx, 1);
    // add it back in:
    listClone.splice(destIdx, 0, removed);

    return listClone;
  }
  // helper for onResponseDragEnd
  move(srcList, destList, src, dest) {
    const srcClone = Array.from(srcList);
    const destClone = Array.from(destList);

    const [removed] = srcClone.splice(src.index, 1);
    destClone.splice(dest.index, 0, removed);

    const result = {};
    result[src.droppableId] = srcClone;
    result[dest.droppableId] = destClone;
    return result;
  }
  onResponseDragEnd({ source, destination }) {
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const reordered = this.reorder(this.getList(source.droppableId), source.index, destination.index);
      let state = { responses: reordered };
      if (source.droppableId === 'exclude') {
        state = { excludedResponses: reordered };
      }
      this.setState(state);
    } else {
      const result = this.move(this.getList(source.droppableId), this.getList(destination.droppableId), source, destination);
      this.setState({ responses: result.consider, excludedResponses: result.exclude });
    }
  }
  onRequest() {
    const { formSelections, question } = this.props;
    const { responses, excludedResponses, type } = this.state;

    if (!formSelections || formSelections.length !== 1 || !question || !responses) {
      return console.error('could not complete request w/ current state');
    }

    const body = {
      form_id: formSelections[0].form_id,
      question_name: question.question_name,
      type,
      ordering: {
        ...responses.reduce(
          (a, b) => ({
            ...a,
            [b]: (responses.length - responses.indexOf(b) - 1) / (responses.length - 1)
          }),
          {}
        ),
        ...excludedResponses.reduce((a, b) => ({ ...a, [b]: null }), {})
      }
    };

    genericPost(`${API_HOST}/sentiment/v0/lookups/request-data`, body, this._xhrRequestData, (e) => {
      if (e) return console.error(e);
      this.setState(this.initialState);
      this.props.invalidateTags(['SentimentQuestions']);
      this.props.onComplete();
    });
  }
  onCancel() {
    this.setState(this.initialState);
    this.props.onCancel();
  }
  render() {
    const { theme, classes, question } = this.props;
    const { responses, excludedResponses, type } = this.state;
    // form_id: "4850691271294976"
    // question_name: "are_non_essential_businesses_closed_by_declaration_of_authorities_or_voluntarily"
    // responses: (3) ["By declaration of authorities", "I'm not sure", "Voluntarily"]

    return (
      <Modal open={!!responses} onClose={this.onCancel}>
        <Box className={classes.paper}>
          <Typography variant="h4">Configure Question for Sentiment Analysis</Typography>
          <div style={{ margin: theme.spacing(3, 0) }}>
            <Typography variant="h6" gutterBottom={true}>
              Question Text
            </Typography>
            {question && <Typography variant="body1">{question.question_proper}</Typography>}
          </div>
          <Typography variant="h6" gutterBottom={true}>
            Question Type
          </Typography>
          <FormHelperText id="my-helper-text">
            {
              "`Categorical` questions configured as `ordered` won't make any sense. An ordering question for example could have values such as `good`, `better`, and `best`, whereas `categorical` could have `dog`, `cat`, and `fox`."
            }
          </FormHelperText>
          <FormControl component="fieldset" className={classes.radioFormControl}>
            <RadioGroup name="question-config-modal-type" value={type} onChange={(e) => this.setState({ type: e.target.value })}>
              <FormControlLabel value="ordered" control={<Radio />} label="Ordered" />
              <FormControlLabel value="categorical" control={<Radio />} label="Categorical" />
            </RadioGroup>
          </FormControl>

          {responses && (
            <React.Fragment>
              <Typography variant="h6">{type === 'ordered' ? 'Order Responses' : 'Responses'}</Typography>
              <div className={classes.dragDropContainer}>
                <DragDropContext onDragEnd={this.onResponseDragEnd}>
                  <Box className={classes.droppableContainer}>
                    {type === 'ordered' && (
                      <FormHelperText className={classes.title}>Order from most positive to least positive. For example: Excellent, Good, Fair, Poor, Very Poor.</FormHelperText>
                    )}
                    <Droppable droppableId={'consider'}>
                      {(providedByDroppable, snapshot) => (
                        <Box
                          className={classes.responseList}
                          ref={providedByDroppable.innerRef}
                          style={{ backgroundColor: snapshot.isDraggingOver ? theme.palette.secondary.main : 'inherit' }}
                        >
                          {responses.map((d1, i1) => (
                            <Draggable key={d1} draggableId={d1} index={i1} {...providedByDroppable.droppableProps}>
                              {(providedByDraggable) => (
                                <Grid
                                  key={d1}
                                  container={true}
                                  className={clsx(classes.marginTopCompact)}
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  spacing={1}
                                  {...providedByDraggable.draggableProps}
                                  ref={providedByDraggable.innerRef}
                                >
                                  <Grid item={true}>
                                    <Box {...providedByDraggable.dragHandleProps}>
                                      <MenuIcon style={{ verticalAlign: 'bottom' }} /> {d1}
                                    </Box>
                                  </Grid>
                                </Grid>
                              )}
                            </Draggable>
                          ))}
                          {providedByDroppable.placeholder}
                        </Box>
                      )}
                    </Droppable>
                  </Box>
                  <Box className={classes.droppableContainer}>
                    <FormHelperText className={classes.title}>Drag responses here that have no apparent place.</FormHelperText>
                    {excludedResponses && (
                      <Droppable droppableId={'exclude'}>
                        {(providedByDroppable, snapshot) => (
                          <Box
                            className={classes.responseList}
                            ref={providedByDroppable.innerRef}
                            style={{
                              backgroundColor: snapshot.isDraggingOver ? theme.palette.secondary.main : 'inherit'
                            }}
                          >
                            {excludedResponses.map((d1, i1) => (
                              <Draggable key={d1} draggableId={d1} index={i1} {...providedByDroppable.droppableProps}>
                                {(providedByDraggable) => (
                                  <Grid
                                    key={d1}
                                    container={true}
                                    className={clsx(classes.marginTopCompact)}
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={1}
                                    {...providedByDraggable.draggableProps}
                                    ref={providedByDraggable.innerRef}
                                  >
                                    <Grid item={true}>
                                      <Box {...providedByDraggable.dragHandleProps}>
                                        <MenuIcon style={{ verticalAlign: 'bottom' }} /> {d1}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                )}
                              </Draggable>
                            ))}
                            {providedByDroppable.placeholder}
                          </Box>
                        )}
                      </Droppable>
                    )}
                  </Box>
                </DragDropContext>
              </div>
            </React.Fragment>
          )}

          <Grid container={true} direction="row" justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: theme.spacing(2) }}>
            <Grid item={true}>
              <Button variant="contained" onClick={this.onCancel}>
                Cancel
              </Button>
            </Grid>
            <Grid item={true}>
              <Button variant="contained" color={'secondary'} onClick={this.onRequest}>
                Request
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    );
  }
}

const mapStateToProps = (/* state */) => ({
  // hasGPU: state.app.hasGPU,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      invalidateTags: irisApi.util.invalidateTags
    },
    dispatch
  );

QuestionConfigModal.propTypes = {
  // UI/UX
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  // DATA
  formSelections: PropTypes.array,
  question: PropTypes.object,

  // REDUX
  invalidateTags: PropTypes.func.isRequired,

  // CALLBACKS
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
};

QuestionConfigModal.defaultProps = {
  onComplete: () => {},
  onCancel: () => {}
};
const withRedux = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
export default withRedux(withTheme(withStyles(styles)(QuestionConfigModal)));
