import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// MODULES
import { sum, max, hsl } from 'd3';
import emojiRegex from 'emoji-regex';

// MUI
import { alpha, useTheme } from '@material-ui/core/styles';

// VISX
import { Bar, Line } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Text } from '@visx/text';

// OURS
import { colorRampLookup } from '../../ColorRamps';

const PopupBarChart = ({ width: containerWidth, data, otherData }) => {
  const theme = useTheme();

  const selectedQuestionsOrderingRev = useSelector((state) => state.sentiment.selectedQuestionsOrderingRev);
  const colorRamp = useSelector((state) => state.sentiment.colorRamp);
  const colorRampInverted = useSelector((state) => state.sentiment.colorRampInverted);
  const vectorOpacity = useSelector((state) => state.sentiment.vectorOpacity);

  // CONSTANTS
  const eRegex = emojiRegex();
  const margin = { top: 0, right: 0, bottom: 0, left: 0 },
    width = containerWidth - margin.left - margin.right;
  const calcMOE = (sum, count, moe) => [Math.max(0, count - sum * moe), count + sum * moe];
  const barHeight = 16;
  const wrapLength = 38;
  const fontSize = '12px';
  const textHeight = 14;

  // USEMEMO
  const renderData = useMemo(() => {
    // sum response counts:
    const sumResponseCounts = sum(Object.values(data.response_counts));

    // build chart data:
    const memo = {
      data: selectedQuestionsOrderingRev
        .map(({ label, order } /* Concerned */) => {
          // make sure data aligns w/ request, bail if not,
          // ( don't think this can happen ):
          if (!data.response_counts[label]) return null;

          return {
            response: label,
            count: data.response_counts[label],
            moe: calcMOE(sumResponseCounts, data.response_counts[label], data.margins_of_error[label]),
            lines: label.length > wrapLength ? 2 : 1,
            order
          };
        })
        .filter((d) => d && d.count > 0)
    };

    // if data & request didn't align; don't attempt a render,
    // ( don't think this can happen ):
    if (memo.data.length === 0) {
      return null;
    }

    // extend domain to include the other side's domain:
    let otherDataMaxResponseCount = 0;
    if (otherData) {
      const s = sum(Object.values(otherData.response_counts));
      otherDataMaxResponseCount = max(
        Object.keys(otherData.response_counts).map((d /* Concerned */) => {
          return calcMOE(s, otherData.response_counts[d], otherData.margins_of_error[d])[1];
        })
      );
    }

    // build scale:
    memo.xScale = scaleLinear({
      range: [0, width],
      round: true,
      domain: [0, Math.max(otherDataMaxResponseCount, max(memo.data.map((d) => d.moe[1])))]
    });

    // pixel details:
    memo.lineSpace = sum(memo.data.map((d) => d.lines)) * (textHeight + 3);
    memo.barSpace = memo.data.length * (barHeight + theme.spacing(1));

    // colors:
    const ramp = colorRampLookup[colorRamp];
    memo.fillLookup = selectedQuestionsOrderingRev.reduce((a, b) => ({ ...a, [b.label]: alpha(ramp(colorRampInverted ? 1 - b.order : b.order), vectorOpacity) }), {});
    return memo;
  }, [
    theme,
    width,
    data,
    otherData,
    selectedQuestionsOrderingRev,
    colorRamp,
    colorRampInverted,
    vectorOpacity,
    // pseudo-constants, incl. for prosperity
    textHeight,
    barHeight,
    wrapLength
  ]);

  if (!renderData) return null;

  let y = 0;
  return (
    <React.Fragment>
      <svg width={containerWidth} height={renderData.lineSpace + renderData.barSpace}>
        <Group top={margin.top} left={margin.left}>
          {renderData.data.map((d) => {
            const barWidth = renderData.xScale(d.count) ?? 0;

            const textY = y + d.lines * textHeight;
            y = textY + 3;

            const barY = y;
            y += barHeight + theme.spacing(1);

            const barOverlayColor = hsl(renderData.fillLookup[d.response]).l > 0.5 ? theme.palette.common.black : theme.palette.common.white;
            const paperOverlayColor = theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.common.black;
            const whiskerXStart = Math.max(0, renderData.xScale(d.moe[0]));
            const whiskerXStop = Math.min(width, renderData.xScale(d.moe[1]));
            return (
              <React.Fragment key={`bar-${d.response}-${d.hasc_code}`}>
                <Text
                  fill={theme.palette.text.primary}
                  x={0}
                  y={textY}
                  fontSize={fontSize}
                  width={width}
                  lineHeight={textHeight}
                  textAnchor="start"
                  capHeight={textHeight * d.lines.length}
                >
                  {`${d.response.substring(0, wrapLength * 2).replace(eRegex, '')}  (${data.response_counts[d.response]})`}
                </Text>
                <Bar rx={3} x={0} y={barY} width={barWidth} height={barHeight} fill={renderData.fillLookup[d.response]} />
                <Line stroke={barOverlayColor} from={{ x: whiskerXStart, y: barY + barHeight / 2 }} to={{ x: barWidth, y: barY + barHeight / 2 }} />
                <Line stroke={paperOverlayColor} from={{ x: barWidth, y: barY + barHeight / 2 }} to={{ x: whiskerXStop, y: barY + barHeight / 2 }} />

                <Line stroke={barOverlayColor} from={{ x: whiskerXStart, y: barY + barHeight * 0.2 }} to={{ x: whiskerXStart, y: barY + barHeight * 0.8 }} />
                <Line stroke={paperOverlayColor} from={{ x: whiskerXStop, y: barY + barHeight * 0.2 }} to={{ x: whiskerXStop, y: barY + barHeight * 0.8 }} />
              </React.Fragment>
            );
          })}
        </Group>
      </svg>
    </React.Fragment>
  );
};

PopupBarChart.propTypes = {
  data: PropTypes.object.isRequired,
  otherData: PropTypes.object,
  width: PropTypes.number.isRequired
};
PopupBarChart.defaultProps = {};
export default React.memo(PopupBarChart);
