import { irisApi } from '../../services';

export const radioApi = irisApi.injectEndpoints({
  endpoints: (builder) => ({
    getRadioCountries: builder.query({
      query: () => ({
        url: '/radio/v0/radio/countries',
        method: 'get'
      })
    }),
    getRadioMetadata: builder.query({
      query: () => ({
        url: '/radio/v0/radio/meta',
        method: 'get'
      })
    })
  }),
  overrideExisting: false
});

export const { getRadioMetadata, getRadioCountries } = radioApi.endpoints;
