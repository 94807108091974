import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// modules
import clsx from 'clsx';
import moment from 'moment';
import introJs from 'intro.js';
import debounce from 'lodash.debounce';
import { irisApi } from '../../../services';

import { AutoSizer } from 'react-virtualized';
import { PatternLines } from '@visx/pattern';

// UI/UX
import Box from '@material-ui/core/Box';

// OURS: REDUX
import {
  setDateRange as setDateRangeAction,
  toggleAlertTimelineFilterType as toggleAlertTimelineFilterTypeAction,
  setAlertTimelineFilterSampleSize as setAlertTimelineFilterSampleSizeAction,
  setAlertTimelineFilterSignificance as setAlertTimelineFilterSignificanceAction,
  setAlertTimelineFilterPctChange as setAlertTimelineFilterPctChangeAction,
  setAlertTimelineFilterMinAlerts as setAlertTimelineFilterMinAlertsAction
} from '../sentimentSlice';

// OURS
import AreaChart from './AreaChart';
import RugPlot from './RugPlot';
import RugPlotTooltip from './RugPlotTooltip';
import ChartDrawer from './ChartDrawer';
import DebounceRender from '../../DebounceRender';
import SmarterBrush from './SmarterBrush';
import Fetching from '../../Fetching';

// GLOBALS
const PATTERN_ID = 'brush_pattern';
const PATTERN_ORIENTATION = ['diagonal'];
const DRAWER_WIDTH = 200;

const BRUSH_CHART_HEIGHT = 0.25;
const MAIN_CHART_HEIGHT = 0.65;
const RUG_PLOT_HEIGHT = 0.1;

const styles = (theme) => ({
  container: {
    right: 0,
    bottom: 0,
    position: 'absolute'
    // transition: 'left 1s'
  },
  loading: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: 0,
    right: 0
  },
  loadingOverlay: {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.8,
    zIndex: 5
  },
  validationGuideTooltip: {
    color: '#000000'
  }
});

class ChartPanel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      yScaleZoomed: true,
      drawerOpen: false,
      margin: {
        dataType: 'ordinal',
        top: 10,
        left: 130 + 24 /* dimensions: ArrowIcon button */,
        right: 10,
        bottom: 35
      }
    };

    // BIND
    this.onBrushChange = this.onBrushChange.bind(this);
    this.onYScaleZoomedToggled = this.onYScaleZoomedToggled.bind(this);
    this.onDrawerOpenToggled = this.onDrawerOpenToggled.bind(this);

    // DEBOUNCE & THROTTLE
    this.onBrushChangeDebounced = debounce(this.onBrushChange, 666);

    // TIMERS
    this._iIntroJsTimeout = null;

    // UPDATE ON...
    this.updateTimeseriesProps = ['selectedForms', 'selectedQuestions', 'selectedHasc'];
    this.updateTimeseriesAlertsProps = [
      'selectedForms',
      'selectedQuestions',
      'selectedHasc',
      'alertType',
      'alertSampleSize',
      'alertSignificance',
      'alertPctChange',
      'alertMinAlerts'
    ];
  }
  canUpdateTimeseries(f, q) {
    return f?.length > 0 && q?.length > 0;
  }
  componentDidMount() {
    const { getTimeseries, getTimeseriesAlerts, selectedForms, selectedQuestions, selectedHasc, alertType, alertSampleSize, alertSignificance, alertMinAlerts, alertPctChange } =
      this.props;

    if (this.canUpdateTimeseries(selectedForms, selectedQuestions)) {
      const { unsubscribe: unsubscribeGetTimeseries } = getTimeseries({
        selectedForms,
        selectedQuestions,
        selectedHasc
      });
      this.unsubscribeGetTimeseries = unsubscribeGetTimeseries;

      const { unsubscribe: unsubscribeGetTimeseriesAlerts } = getTimeseriesAlerts({
        selectedForms,
        selectedQuestions,
        selectedHasc,
        alertTimelineFilterType: alertType,
        alertTimelineFilterSampleSize: alertSampleSize,
        alertTimelineFilterSignificance: alertSignificance,
        alertTimelineFilterMinAlerts: alertMinAlerts,
        alertTimelineFilterPctChange: alertPctChange
      });
      this.unsubscribeGetTimeseriesAlerts = unsubscribeGetTimeseriesAlerts;
    }
  }
  componentWillUnmount() {
    if (this._iIntroJsTimeout) clearTimeout(this._iIntroJsTimeout);
    this.unsubscribeGetTimeseries?.();
    this.unsubscribeGetTimeseriesAlerts?.();
  }
  componentDidUpdate(prevProps) {
    const {
      classes,
      getTimeseries,
      getTimeseriesAlerts,
      selectedForms,
      selectedQuestions,
      selectedHasc,
      alertType,
      alertSampleSize,
      alertSignificance,
      alertMinAlerts,
      alertPctChange,
      timeseriesAlerts
    } = this.props;

    const { drawerOpen } = this.state;

    const updateTimeseries = this.updateTimeseriesProps.reduce((a, b) => a || this.props[b] !== prevProps[b], false);
    const updateTimeseriesAlerts = this.updateTimeseriesAlertsProps.reduce((a, b) => a || this.props[b] !== prevProps[b], false);

    if (this.canUpdateTimeseries(selectedForms, selectedQuestions)) {
      if (updateTimeseries) {
        this.unsubscribeGetTimeseries?.();
        const { unsubscribe } = getTimeseries({ selectedForms, selectedQuestions, selectedHasc });
        this.unsubscribeGetTimeseries = unsubscribe;
      }
      if (updateTimeseriesAlerts) {
        this.unsubscribeGetTimeseriesAlerts?.();

        const { unsubscribe } = getTimeseriesAlerts({
          selectedForms,
          selectedQuestions,
          selectedHasc,
          alertTimelineFilterType: alertType,
          alertTimelineFilterSampleSize: alertSampleSize,
          alertTimelineFilterSignificance: alertSignificance,
          alertTimelineFilterMinAlerts: alertMinAlerts,
          alertTimelineFilterPctChange: alertPctChange
        });
        this.unsubscribeGetTimeseriesAlerts = unsubscribe;
      }
    }

    const timeSeriesChanged = timeseriesAlerts !== prevProps.timeseriesAlerts;
    const timeSeriesLastReminder = Date.now() - Number(window.localStorage.intros_s_zc_saf ?? 0);
    const timeSeriesReminderEligible = timeseriesAlerts?.data?.more && !drawerOpen && timeSeriesLastReminder > moment.duration(1, 'day').asMilliseconds();

    if (timeSeriesChanged && timeSeriesReminderEligible) {
      if (this._iIntroJsTimeout) clearTimeout(this._iIntroJsTimeout);
      this._iIntroJsTimeout = setTimeout(() => {
        const element = document.querySelector('#expand-filters');
        if (!element) return;

        window.localStorage.intros_s_zc_saf = Date.now();
        introJs()
          .setOptions({
            showStepNumbers: false,
            overlayOpacity: 0.5,
            exitOnEsc: false,
            exitOnOverlayClick: false,
            disableInteraction: true,
            tooltipClass: classes.validationGuideTooltip
          })
          .addSteps([
            {
              element,
              intro: 'Hey 👋, your current filters are hiding some alerts, expand this panel to adjust alert filters.'
            }
          ])
          .start();
      }, 500);
    }
  }

  onBrushChange(domain) {
    const { onDateRangeChange } = this.props;
    if (!domain) return onDateRangeChange(null);

    onDateRangeChange({
      start: domain.x0,
      end: domain.x1,
      strStart: moment(domain.x0).format('YYYY-MM-DD'),
      strEnd: moment(domain.x1).format('YYYY-MM-DD')
    });
  }
  onYScaleZoomedToggled() {
    this.setState((s) => ({
      yScaleZoomed: !s.yScaleZoomed
    }));
  }
  onDrawerOpenToggled() {
    this.setState((s) => ({
      drawerOpen: !s.drawerOpen
    }));
  }
  render() {
    const {
      theme,
      classes,
      alertMinAlerts,
      alertPctChange,
      alertSampleSize,
      alertSignificance,
      alertType,

      height,
      left,
      onAlertMinAlertsChanged,
      onAlertPctChanged,
      onAlertSampleSizeChanged,
      onAlertSignificanceChanged,
      selectedQuestionsOrdering,
      selectedQuestionType,
      selectedQuestions,
      timeseries, // { data, name }
      timeseriesAlerts, // { alerts, more }
      selectedDateRange,
      toggleAlertType,
      colorRampInverted,
      colorRamp
    } = this.props;
    const { yScaleZoomed, drawerOpen, margin } = this.state;

    // if (timeseries.isLoading || timeseriesAlerts.isLoading) {
    //   return (
    //     <>
    //       <Box style={{ left, height }} className={classes.loading}>
    //         <CircularProgress color="secondary" />
    //       </Box>
    //     </>
    //   );
    // }
    const fetching = timeseries.isLoading || timeseriesAlerts.isLoading;
    return (
      <React.Fragment>
        <ChartDrawer
          alertMinAlerts={alertMinAlerts}
          alertPctChange={alertPctChange}
          alertSampleSize={alertSampleSize}
          alertSignificance={alertSignificance}
          alertType={alertType}
          onAlertMinAlertsChanged={onAlertMinAlertsChanged}
          onAlertPctChanged={onAlertPctChanged}
          onAlertSampleSizeChanged={onAlertSampleSizeChanged}
          onAlertSignificanceChanged={onAlertSignificanceChanged}
          toggleAlertType={toggleAlertType}
          width={DRAWER_WIDTH}
          left={left}
          height={height}
          yScaleZoomed={yScaleZoomed}
          onYScaleZoomedToggled={this.onYScaleZoomedToggled}
          drawerOpen={drawerOpen}
          onDrawerOpenToggled={this.onDrawerOpenToggled}
        />
        <Box id="sentiment_bottom-panel" className={clsx('noselect', classes.container)} style={{ height, left: drawerOpen ? left + DRAWER_WIDTH : left }}>
          {fetching && <Fetching />}
          {timeseries.status === 'fulfilled' && timeseries.data?.data && (
            <AutoSizer disableHeight={true}>
              {({ width }) => {
                if (width < 10) return null;
                return (
                  <DebounceRender width={width} drawerOpen={drawerOpen}>
                    <RugPlotTooltip top={height * MAIN_CHART_HEIGHT} left={margin.left - 30 - theme.spacing(1)} />
                    <svg width={width} height={height}>
                      <AreaChart
                        data={timeseries.data.data}
                        dataType={selectedQuestionType}
                        alertData={timeseriesAlerts.data?.alerts}
                        height={height * MAIN_CHART_HEIGHT}
                        width={width}
                        margin={margin}
                        top={0}
                        temporalDomain={timeseries.data.temporalDomain}
                        hideLeftAxis={false}
                        ordering={selectedQuestionsOrdering}
                        colorRampInverted={colorRampInverted}
                        colorRamp={colorRamp}
                        selectedQuestions={selectedQuestions}
                        yAxisTitle={selectedQuestionType === 'categorical' ? 'Share of Responses' : null}
                        yScaleZoomed={yScaleZoomed}
                        selectedDateRange={selectedDateRange}
                      />

                      <RugPlot
                        height={height * RUG_PLOT_HEIGHT}
                        width={width}
                        data={timeseries.data.data}
                        dataType={selectedQuestionType}
                        top={height * MAIN_CHART_HEIGHT}
                        margin={margin}
                        temporalDomain={timeseries.data.temporalDomain}
                      />
                      <AreaChart
                        data={timeseries.data.data}
                        dataType={selectedQuestionType}
                        alertData={timeseriesAlerts.data?.alerts}
                        height={height * BRUSH_CHART_HEIGHT}
                        width={width}
                        temporalDomain={timeseries.data.temporalDomain}
                        margin={margin}
                        top={height * (MAIN_CHART_HEIGHT + RUG_PLOT_HEIGHT)}
                        hideLeftAxis={true}
                        bottomAxisType="minimal"
                        ordering={selectedQuestionsOrdering}
                        colorRampInverted={colorRampInverted}
                        colorRamp={colorRamp}
                        selectedQuestions={selectedQuestions}
                      >
                        <PatternLines id={PATTERN_ID} height={8} width={8} stroke={theme.palette.secondary.main} strokeWidth={1} orientation={PATTERN_ORIENTATION} />
                        <SmarterBrush
                          width={width}
                          patternId={PATTERN_ID}
                          height={height * BRUSH_CHART_HEIGHT}
                          margin={margin}
                          onBrushChange={this.onBrushChangeDebounced}
                          selectedDateRange={selectedDateRange}
                          temporalDomain={timeseries.data.temporalDomain}
                        />
                      </AreaChart>
                    </svg>
                  </DebounceRender>
                );
              }}
            </AutoSizer>
          )}
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedForms: state.sentiment.selectedForms,
  selectedQuestions: state.sentiment.selectedQuestions,
  selectedHasc: state.sentiment.selectedHasc,

  selectedQuestionsOrdering: state.sentiment.selectedQuestionsOrdering,
  selectedQuestionsOrderingValues: state.sentiment.selectedQuestionsOrderingValues,

  selectedDateRange: state.sentiment.selectedDateRange,
  selectedQuestionType: state.sentiment.selectedQuestionType,

  alertType: state.sentiment.alertTimelineFilterType,
  alertSampleSize: state.sentiment.alertTimelineFilterSampleSize,
  alertSignificance: state.sentiment.alertTimelineFilterSignificance,
  alertPctChange: state.sentiment.alertTimelineFilterPctChange,
  alertMinAlerts: state.sentiment.alertTimelineFilterMinAlerts,

  colorRamp: state.sentiment.colorRamp,
  colorRampInverted: state.sentiment.colorRampInverted,

  timeseries: irisApi.endpoints.getTimeseries.select({
    selectedForms: state.sentiment.selectedForms,
    selectedQuestions: state.sentiment.selectedQuestions,
    selectedHasc: state.sentiment.selectedHasc
  })(state),
  timeseriesAlerts: irisApi.endpoints.getTimeseriesAlerts.select({
    selectedForms: state.sentiment.selectedForms,
    selectedQuestions: state.sentiment.selectedQuestions,
    selectedHasc: state.sentiment.selectedHasc,
    alertTimelineFilterType: state.sentiment.alertTimelineFilterType,
    alertTimelineFilterSampleSize: state.sentiment.alertTimelineFilterSampleSize,
    alertTimelineFilterSignificance: state.sentiment.alertTimelineFilterSignificance,
    alertTimelineFilterMinAlerts: state.sentiment.alertTimelineFilterMinAlerts,
    alertTimelineFilterPctChange: state.sentiment.alertTimelineFilterPctChange
  })(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // API
      getTimeseries: irisApi.endpoints.getTimeseries.initiate,
      getTimeseriesAlerts: irisApi.endpoints.getTimeseriesAlerts.initiate,

      // ACTIONS
      onDateRangeChange: setDateRangeAction,
      toggleAlertType: toggleAlertTimelineFilterTypeAction,
      onAlertSampleSizeChanged: setAlertTimelineFilterSampleSizeAction,
      onAlertSignificanceChanged: setAlertTimelineFilterSignificanceAction,
      onAlertPctChanged: setAlertTimelineFilterPctChangeAction,
      onAlertMinAlertsChanged: setAlertTimelineFilterMinAlertsAction
    },
    dispatch
  );

ChartPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  left: PropTypes.number,
  height: PropTypes.number.isRequired,

  selectedForms: PropTypes.array,
  selectedQuestions: PropTypes.array,
  selectedHasc: PropTypes.string,

  selectedQuestionsOrdering: PropTypes.array,
  selectedQuestionsOrderingValues: PropTypes.array,

  selectedDateRange: PropTypes.object,
  onDateRangeChange: PropTypes.func,

  selectedQuestionType: PropTypes.string,

  alertType: PropTypes.string,
  toggleAlertType: PropTypes.func,

  alertSampleSize: PropTypes.number,
  onAlertSampleSizeChanged: PropTypes.func,

  alertSignificance: PropTypes.number,
  onAlertSignificanceChanged: PropTypes.func,

  alertPctChange: PropTypes.number,
  onAlertPctChanged: PropTypes.func,

  alertMinAlerts: PropTypes.number,
  onAlertMinAlertsChanged: PropTypes.func,

  colorRamp: PropTypes.string,
  colorRampInverted: PropTypes.bool,

  getTimeseries: PropTypes.func.isRequired,
  timeseries: PropTypes.object,
  getTimeseriesAlerts: PropTypes.func.isRequired,
  timeseriesAlerts: PropTypes.object
};

ChartPanel.defaultProps = {
  left: 0
};
const withRedux = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
export default withRedux(withTheme(withStyles(styles)(ChartPanel)));
