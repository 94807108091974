import React from 'react';
import { withStyles, withTheme, alpha } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import clsx from 'clsx';

// MODULES
import moment from 'moment';
import numeral from 'numeral';
import Dropzone from 'react-dropzone';
import * as d3 from 'd3';
import { BlockPicker } from 'react-color';

// UI/UX
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// ICONS
import DeleteIcon from '@material-ui/icons/Delete';
import FolderIcon from '@material-ui/icons/Folder';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import StopIcon from '@material-ui/icons/Stop';
import ZoomInIcon from '@material-ui/icons/ZoomIn';

// OURS
import { pushSnackbar as pushSnackbarAction, setGlobal as setGlobalAction } from '../actions';
import { API_HOST, genericGet, genericPost, genericDelete } from 'iris-api'; // eslint-disable-line import/no-unresolved
import { Features as FEATURES, has } from '@premisedata/lib-features';
import { irisApi } from '../services';
import EditTextField from './EditTextField';
import VAC from './radio/components/VirtualizedAutocomplete';

const styles = (theme) => ({
  dropzone: {
    textAlign: 'center',
    background: theme.palette.background.default,
    borderRadius: '4px',
    border: `1px dashed ${theme.palette.text.primary}`,
    padding: '18.5px 14px', // based on textfield padding,
    position: 'relative',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dropzoneCaption: {
    display: 'block',
    fontStyle: 'italic'
  },
  progressBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  gridContainer: {
    height: '100%',
    width: '100%',
    position: 'relative'
  },
  tableHead_root: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.getContrastText(theme.palette.secondary.dark)
  },
  tableRow_root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  },
  iconPickerBox: {
    width: 300,
    padding: theme.spacing(2)
  },
  pseudoIconBtn: {
    padding: 3,
    flex: '0 0 auto',
    display: 'inline-flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'middle'
  }
});

class UserDataPages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 'upload',
      uploading: false,
      colorPickerAnchor: null,
      colorPickerData: null
    };
    // XHR
    this._xhrUpload = {};
    this._xhrSigned = {};
    this._xhrList = {};
    this._xhrToggleViz = {};
    this._xhrDelete = {};
    this._xhrUpdateColor = {};
    this._xhrIcons = {};
    this._xhrUpdateIcon = {};

    // REF
    this._refDropZone = React.createRef();
    this._refColorPickerPopover = React.createRef();
    this._refIconPickerPopover = React.createRef();

    // BIND
    this.handleDropAccepted = this.handleDropAccepted.bind(this);
    this.onUploadClicked = this.onUploadClicked.bind(this);
    this.onPickerClose = this.onPickerClose.bind(this);
    this.onPickerChange = this.onPickerChange.bind(this);
    this.onSelectColor = this.onSelectColor.bind(this);
    this.onSelectIcon = this.onSelectIcon.bind(this);
    this.onIconPickerClose = this.onIconPickerClose.bind(this);
    this.onChangeIcon = this.onChangeIcon.bind(this);

    this.listColumns = [
      {
        field: 'id',
        headerName: 'Id',
        width: 100
      },
      {
        field: 'name',
        headerName: 'Name',
        width: 200
      }
    ];
  }
  componentDidMount() {
    genericGet(`${API_HOST}/uploads/v0/icons`, this._xhrIcons, (e, r) => {
      if (e) {
        // TODO
        return;
      }
      this.setState({
        availableIcons: r
      });
    });

    const { unsubscribe } = this.props.getUserdataMetadata();
    this.unsubscribeGetUserdataMetadata = unsubscribe;
  }
  componentDidUpdate(prevProps) {
    const { activePage } = this.props;
    const { activePage: prevActivePage } = prevProps;

    if (activePage === 'manage' && activePage !== prevActivePage) {
      this.updateList();
    }
  }
  componentWillUnmount() {
    this.unsubscribeGetUserdataMetadata?.();
    this._xhrUpload.cancel && this._xhrUpload.cancel();
    this._xhrSigned.cancel && this._xhrSigned.cancel();
    this._xhrList.cancel && this._xhrList.cancel();
    this._xhrToggleViz.cancel && this._xhrToggleViz.cancel();
    this._xhrDelete.cancel && this._xhrDelete.cancel();
    this._xhrUpdateColor.cancel && this._xhrUpdateColor.cancel();
    this._xhrIcons.cancel && this._xhrIcons.cancel();
    this._xhrUpdateIcon.cancel && this._xhrUpdateIcon.cancel();
  }
  handleDropAccepted(file) {
    const { pushSnackbar, onShakeManage } = this.props;

    // cancel any outbound requests:
    this._xhrSigned.cancel && this._xhrSigned.cancel();
    this._xhrUpload.cancel && this._xhrUpload.cancel();

    this.setState({
      uploading: true
    });

    // get signed url
    genericGet(`${API_HOST}/uploads/v0/upload/signed-url`, this._xhrSigned, (e, r) => {
      if (e) {
        this.setState({ uploading: false });
        return pushSnackbar({ message: 'Failed to sign file upload, please try again later.', type: 'error' });
      }

      fetch(r.url, {
        method: 'PUT',
        mode: 'cors',
        body: file[0]
      })
        .then(() => {
          genericPost(`${API_HOST}/uploads/v0/upload`, { filename: r.filename }, this._xhrUpload, (e1) => {
            if (e1) {
              this.setState({ uploading: false });
              onShakeManage && onShakeManage(false);
              return pushSnackbar({ message: 'Failed to upload file (API), please try again later.', type: 'error' });
            }
            pushSnackbar({ message: "File uploaded successfully, we'll start processing it immediately.", type: 'success' });
            this.setState({ uploading: false });
            onShakeManage && onShakeManage(true);
          });
        })
        .catch(() => {
          pushSnackbar({ message: 'Failed to upload file (GCS), please try again later.', type: 'error' });
          this.setState({ uploading: false });
          onShakeManage && onShakeManage(false);
        });
    });
  }
  onUploadClicked() {
    this._refDropZone.current && this._refDropZone.current.open();
  }
  updateList() {
    this.props.invalidateTags(['UserdataMetadata']);
  }
  toggleLayerVisibility({ id }) {
    this._xhrToggleViz.cancel && this._xhrToggleViz.cancel();
    genericGet(`${API_HOST}/uploads/v0/manage/toggle-visibility/${id}`, this._xhrToggleViz, (e) => {
      if (e) {
        return this.props.pushSnackbar({ message: 'Failed to toggle layer visibility, please try again later.', type: 'error' });
      }

      this.updateList();
    });
  }
  deleteLayer({ id }) {
    this._xhrDelete.cancel && this._xhrDelete.cancel();
    genericDelete(`${API_HOST}/uploads/v0/manage/${id}`, this._xhrDelete, (e) => {
      if (e) {
        return this.props.pushSnackbar({ message: 'Failed to delete layer, please try again later.', type: 'error' });
      }
      this.updateList();
    });
  }
  onRename({ id }, name) {
    genericPost(`${API_HOST}/uploads/v0/manage/rename/${id}`, { name }, this._xhrRename, (e) => {
      if (e) {
        return this.props.pushSnackbar({ message: 'Failed to rename layer, please try again later.', type: 'error' });
      }

      this.updateList();
    });
  }
  onPickerClose() {
    this.setState({
      colorPickerAnchor: null
    });
  }
  onIconPickerClose() {
    this.setState({
      iconPickerAnchor: null
    });
  }
  onPickerChange({ hex }) {
    const { colorPickerData } = this.state;
    this.setState((s) => ({
      colorPickerData: {
        ...s.colorPickerData,
        color: hex
      }
    }));
    this._xhrUpdateColor.cancel && this._xhrUpdateColor.cancel();
    genericGet(`${API_HOST}/uploads/v0/manage/update-color/${colorPickerData.id}/${encodeURIComponent(hex)}`, this._xhrUpdateColor, (e) => {
      if (e) {
        return this.props.pushSnackbar({ message: 'Failed to update layer color, please try again later.', type: 'error' });
      }

      this.updateList();
    });
  }
  onChangeIcon(_, v) {
    if (!v) return;

    this.setState({
      iconPickerAnchor: null
    });
    this._xhrUpdateIcon.cancel && this._xhrUpdateIcon.cancel();
    genericGet(`${API_HOST}/uploads/v0/manage/update-icon/${this.state.iconPickerData.id}/${v}`, this._xhrUpdateIcon, (e) => {
      if (e) {
        return this.props.pushSnackbar({ message: 'Failed to update layer icon, please try again later.', type: 'error' });
      }

      this.updateList();
    });
  }
  onSelectColor(e, row) {
    this.setState({
      colorPickerAnchor: e.target,
      colorPickerData: row
    });
  }
  onSelectIcon(e, row) {
    this.setState({
      iconPickerAnchor: e.target,
      iconPickerData: row
    });
  }
  render() {
    const { theme, classes, user, activePage, userdataMetadataQuery, activeProduct, onZoomTo } = this.props;
    const { uploading, colorPickerAnchor, colorPickerData, iconPickerAnchor, availableIcons, iconPickerData } = this.state;
    const userdataLayers = userdataMetadataQuery.data?.layers ?? [];
    const cantManageUploads = !has(user.active_features, FEATURES.DATA_UPLOAD);
    return (
      <React.Fragment>
        {activePage === 'upload' && (
          <Dropzone
            disabled={uploading}
            ref={this._refDropZone}
            accept={['application/zip']}
            onDropAccepted={this.handleDropAccepted}
            noClick={true}
            maxFiles={1}
            maxSize={500 * 1024 * 1024}
          >
            {({ getRootProps, getInputProps }) => (
              <React.Fragment>
                <div className={clsx('noselect', classes.dropzone)}>
                  <div {...getRootProps()} style={{ opacity: uploading ? 0.5 : 1, maxWidth: '25%', minWidth: 250 }}>
                    <Typography variant="body2" gutterBottom={true}>
                      Drag & drop or browse for a file...
                    </Typography>
                    <input {...getInputProps()} />
                    <Button disabled={uploading} onClick={this.onUploadClicked} color="secondary" startIcon={<FolderIcon />}>
                      Browse
                    </Button>

                    <Typography variant="caption" className={classes.dropzoneCaption}>
                      Accepting compressed (.zip) files containing a single GeoJSON (.geojson) file; limited to 500MB. If properties "name"/"place_name", and "photo" are present
                      they will propagate appropriately throughout Iris.
                    </Typography>
                    <Link color="secondary" href="https://iris.premise.com/static/geojson-sample.geojson.zip">
                      Example Upload
                    </Link>
                  </div>
                  {uploading && (
                    <Box className={classes.progressBox}>
                      <CircularProgress color="secondary" />
                    </Box>
                  )}
                </div>
              </React.Fragment>
            )}
          </Dropzone>
        )}

        {activePage === 'manage' && (
          <Box className={classes.gridContainer}>
            <TableContainer component={Paper}>
              <Table size="small" className={classes.table}>
                <TableHead classes={{ root: classes.tableHead_root }}>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Phase</TableCell>
                    <TableCell align="right">Uploaded</TableCell>
                    <TableCell align="right">Features</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userdataLayers.map((row) => {
                    const style = { color: row.visible ? theme.palette.text.primary : alpha(theme.palette.text.primary, 0.2) };
                    const hsl = d3.hsl(row.color);
                    hsl.h += 120;
                    const selectedColor = hsl.formatHex();
                    hsl.h += 120;
                    const hoverColor = hsl.formatHex();
                    return (
                      <TableRow key={row.id} classes={{ root: classes.tableRow_root }}>
                        <TableCell style={style}>
                          <EditTextField
                            disabled={!row.visible || cantManageUploads}
                            value={row.name}
                            onChange={(v) => {
                              this.onRename(row, v);
                            }}
                          />
                        </TableCell>
                        <TableCell style={style} align="right">
                          {row.phase}
                        </TableCell>
                        <TableCell style={style} align="right">
                          {moment(row.row_created_at).fromNow()}
                        </TableCell>
                        <TableCell style={style} align="right">
                          {row.feature_count > 0 ? numeral(row.feature_count).format('0,0') : ''}
                        </TableCell>
                        <TableCell style={style}>
                          <IconButton disabled={!row.extent || activeProduct !== 'places'} size="small" onClick={() => onZoomTo(row.extent)}>
                            <ZoomInIcon />
                          </IconButton>

                          <IconButton disabled={cantManageUploads} size="small" onClick={() => this.toggleLayerVisibility(row)}>
                            {row.visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                          <IconButton disabled={cantManageUploads} size="small" onClick={() => this.deleteLayer(row)}>
                            <DeleteIcon />
                          </IconButton>
                          <IconButton disabled={cantManageUploads} size="small" onClick={(e) => this.onSelectColor(e, row)} style={{ color: row.color }}>
                            <StopIcon />
                          </IconButton>
                          <IconButton size="small" onClick={(e) => this.onSelectIcon(e, row)} disabled={!availableIcons || cantManageUploads}>
                            <img crossOrigin="anonymous" src={`${API_HOST}/uploads/v0/icons/${row.icon}/${encodeURIComponent(row.color)}`} style={{ height: 24, width: 24 }} />
                          </IconButton>
                          <Tooltip title="Selection Color" arrow={true} placement="top">
                            <Box className={classes.pseudoIconBtn}>
                              <img
                                crossOrigin="anonymous"
                                src={`${API_HOST}/uploads/v0/icons/${row.icon}/${encodeURIComponent(selectedColor)}`}
                                style={{ height: 24, width: 24 }}
                              />
                            </Box>
                          </Tooltip>
                          <Tooltip title="Hover Color" arrow={true} placement="top">
                            <Box className={classes.pseudoIconBtn}>
                              <img crossOrigin="anonymous" src={`${API_HOST}/uploads/v0/icons/${row.icon}/${encodeURIComponent(hoverColor)}`} style={{ height: 24, width: 24 }} />
                            </Box>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}

        {colorPickerAnchor && (
          <Popover
            ref={this._refColorPickerPopover}
            onClose={this.onPickerClose}
            open={true}
            anchorEl={colorPickerAnchor}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <BlockPicker
              styles={{
                default: {
                  card: {
                    background: theme.palette.background.paper
                  },
                  head: {
                    height: '64px'
                  }
                }
              }}
              color={colorPickerData.color}
              onChangeComplete={this.onPickerChange}
            />
          </Popover>
        )}
        {iconPickerData && availableIcons && iconPickerAnchor && (
          <Popover
            ref={this._refIconPickerPopover}
            onClose={this.onIconPickerClose}
            open={true}
            anchorEl={iconPickerAnchor}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <Box className={classes.iconPickerBox}>
              <VAC
                multiple={false}
                value={iconPickerData.icon}
                options={availableIcons}
                onChange={this.onChangeIcon}
                label="Icon"
                disabled={!availableIcons}
                renderOption={(icon) => (
                  <React.Fragment>
                    <img
                      crossOrigin="anonymous"
                      src={`${API_HOST}/uploads/v0/icons/${icon}/${encodeURIComponent(theme.palette.text.primary)}`}
                      style={{ height: 24, width: 24, marginRight: theme.spacing(1) }}
                    />
                    <Typography>
                      {icon
                        .replace(/-/g, ' ')
                        .split(' ')
                        .map((d) => d.charAt(0).toUpperCase() + d.substring(1))
                        .join(' ')}
                    </Typography>
                  </React.Fragment>
                )}
              />
            </Box>
          </Popover>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.app.user,
  userdataMetadataQuery: irisApi.endpoints.getUserdataMetadata.select()(state),
  activeProduct: state.app.activeProduct
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserdataMetadata: irisApi.endpoints.getUserdataMetadata.initiate,
      invalidateTags: irisApi.util.invalidateTags,
      pushSnackbar: pushSnackbarAction,
      setGlobal: setGlobalAction
    },
    dispatch
  );

UserDataPages.propTypes = {
  // REDUX:
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  pushSnackbar: PropTypes.func.isRequired,
  setGlobal: PropTypes.func.isRequired,
  activeProduct: PropTypes.string,
  userdataMetadataQuery: PropTypes.object,
  getUserdataMetadata: PropTypes.func.isRequired,
  invalidateTags: PropTypes.func.isRequired,

  // PROPS:
  activePage: PropTypes.string.isRequired,
  onShakeManage: PropTypes.func,
  onZoomTo: PropTypes.func.isRequired
};

UserDataPages.defaultProps = {};
const withRedux = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
export default withRedux(withTheme(withStyles(styles)(UserDataPages)));
