// Core
import { withStyles, withTheme } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactDOMServer from 'react-dom/server';

// ui/ux
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

// modules
import debounce from 'lodash.debounce';
import { v4 as uuidv4 } from 'uuid';
// import GL from '@luma.gl/constants';
import * as d3 from 'd3';
import throttle from 'lodash.throttle';
import numeral from 'numeral';
import { h3GetResolution, h3ToParent } from 'h3-js';

// deckgl
import { FlyToInterpolator } from '@deck.gl/core';
import { DeckGL } from '@deck.gl/react';
import { ScatterplotLayer, IconLayer } from '@deck.gl/layers';
import { H3HexagonLayer } from '@deck.gl/geo-layers';
import { StaticMap } from 'react-map-gl';
// ours
import DeckglLayerControl from '../DeckglLayerControl';
import { updateMapAttributionImmediate } from '../../util';
import { updateMapPosition as updateMapPositionAction, pushSnackbar as pushSnackbarAction } from '../../actions';
import { TOP } from 'iris-config'; // eslint-disable-line import/no-unresolved
import { API_HOST, genericGet } from 'iris-api'; // eslint-disable-line import/no-unresolved
import { Legend } from '@premisedata/lib-iris-radio';
import { MAP_STYLES_FLAT } from '../../constants';
import Fetching from '../Fetching';

// GLOBALS
const MAP_ID_SELECTOR = '#view-default-view';
const RF_PROP_COL_COUNT = 1;
const RF_PROP_COL_MEAN = 2;
const RF_PROP_RESIZE_ZOOM = 13.0;
const RF_PROP_RESIZED_RES = 9;
const styles = (theme) => ({
  map: {
    position: 'absolute',
    right: '0px',
    backgroundColor: theme.palette.background.default,
    height: `calc(100% - ${TOP}px)`, // Top + marginTop
    top: `${TOP}px`,
    overflow: 'hidden',
    // rearragne static maps:
    '& #view-default-view > div:last-child': {
      zIndex: '1 !important'
    }
  }
});

class RadioDeckMap extends React.PureComponent {
  constructor(props) {
    super(props);
    const { mapZoom, mapCenter } = props;
    this.state = {
      uuid: uuidv4().replace(/-/g, '_'),
      viewState: {
        longitude: mapCenter.x_lon,
        latitude: mapCenter.y_lat,
        zoom: mapZoom,
        pitch: 50,
        bearing: 0,
        maxPitch: 50,
        minPitch: 0
      },
      // webglParams: {
      //   [GL.DEPTH_TEST]: false,
      //   [GL.BLEND]: true
      // },
      currentBasemap: MAP_STYLES_FLAT[0],
      hideAggLayers: false,

      radioHexData: null,
      radioHexLegendScale: null,
      rfpropFilter: null,
      rfpropResized: false
    };

    this.transitionSettings = () => ({
      transitionDuration: 2000,
      transitionInterpolator: new FlyToInterpolator()
    });

    // TIMERS
    this._iOnLoad = null; // setInterval used in onLoad()
    this._iMount = null;

    // REFS
    this._ref = React.createRef();

    // DATA
    this._tooltipCache = {};

    // BIND
    this.updateViewport = this.updateViewport.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.onFilterRfProp = this.onFilterRfProp.bind(this);
    this.calculateRfProp = this.calculateRfProp.bind(this);
    this.updateMapAttribution = this.updateMapAttribution.bind(this);
    this.updateGlobalViewport = this.updateGlobalViewport.bind(this);
    this.onHideAggLayersChanged = this.onHideAggLayersChanged.bind(this);
    this.onBasemapChange = this.onBasemapChange.bind(this);
    this.onPitchChange = this.onPitchChange.bind(this);
    this.onDeckGLClicked = this.onDeckGLClicked.bind(this);
    this.onScatterplotLayerGetTooltip = this.onScatterplotLayerGetTooltip.bind(this);
    this.getDataForTooltip = this.getDataForTooltip.bind(this);

    // THROTTLE & DEBOUNCE
    this.calculateRfPropDebounced = debounce(this.calculateRfProp, 500, { trailing: true, leading: false });
    this.updateMapAttributionThrottled = throttle(this.updateMapAttribution, 100, { trailing: true, leading: false });
    this.updateGlobalViewportDebounced = debounce(this.updateGlobalViewport, 1000, { trailing: true, leading: false });
    this.getDataForTooltipDebounced = debounce(this.getDataForTooltip, 300, { trailing: true, leading: false });

    // XHR
    this._xhrTooltip = {};
    this._xhrTooltipOutboundFor = null;
  }
  componentWillUnmount() {
    // THROTTLE & DEBOUNCE
    this.getDataForTooltipDebounced.cancel();
    this.calculateRfPropDebounced.cancel();
    this.updateMapAttributionThrottled.cancel();
    this.updateGlobalViewportDebounced.cancel();

    // XHR
    this._xhrTooltip.cancel && this._xhrTooltip.cancel();

    // TIMERS
    if (this._iOnLoad) {
      clearInterval(this._iOnLoad);
      this._iOnLoad = null;
    }
    if (this._iMount) {
      clearInterval(this._iMount);
      this._iMount = null;
    }

    const e = document.querySelector(MAP_ID_SELECTOR);
    if (e) {
      e.removeEventListener('mousemove', this.updateMapAttributionThrottled);
    }
  }
  componentDidMount() {
    this._iMount = setInterval(() => {
      const e = document.querySelector(MAP_ID_SELECTOR);
      if (e) {
        e.addEventListener('mousemove', this.updateMapAttributionThrottled);
        clearInterval(this._iMount);
        this._iMount = null;
      }
    }, 250);

    this.componentDidUpdate(
      {
        theme: this.props.theme
      },
      {}
    );
  }
  updateMapAttribution({ offsetX, offsetY }) {
    const viewport = this.getViewport();
    const [x, y] = viewport.unproject([offsetX, offsetY]);
    const latitude = numeral(x).format('0[.]00');
    const longitude = numeral(y).format('0[.]00');
    // TODO
    updateMapAttributionImmediate({ zoom: viewport.zoom, latitude, longitude });
  }
  buildLegendScale(radioHexData, n = 5) {
    const interpolate = this.props.theme.palette.type === 'dark' ? d3.interpolateCool : d3.interpolateBuPu;
    const dataDomain = radioHexData.map((d) => d.count);
    const e = d3.extent(dataDomain);
    const now = new Date().getTime();
    // only consider moving to an Ordinal scale if depth === 1
    if (n === 5) {
      const uniqueValues = Array.from(new Set(dataDomain));
      const l = uniqueValues.length;
      if (l < 5) {
        const scale = d3
          .scaleOrdinal()
          .domain(uniqueValues)
          .range(
            uniqueValues.map((_, i) => {
              const x = (i + 1) / l;
              const c = d3.color(interpolate(x));
              return [c.r, c.g, c.b];
            })
          );
        scale.TYPE = 'ORDINAL';
        scale.NOW = now;
        return scale;
      }
    }

    let scale = d3
      .scaleQuantile()
      .domain(dataDomain)
      .range(
        [...new Array(n).keys()].map((d) => {
          const x = d / (n - 1);
          const c = d3.color(interpolate(x));
          return [c.r, c.g, c.b];
        })
      );

    // have to filter out '1' values because visx legend decides to add a 0 row
    const quantiles = [...scale.quantiles().filter((d) => d > 1), e[1]];
    const uniqueQuantiles = Array.from(new Set(quantiles));
    const uql = uniqueQuantiles.length;

    scale = d3
      .scaleThreshold()
      .domain(uniqueQuantiles)
      .range(
        uniqueQuantiles.map((_, i) => {
          const x = (i + 1) / uql;
          const c = d3.color(interpolate(x));
          return [c.r, c.g, c.b];
        })
      );

    scale.TYPE = 'THRESHOLD';
    return scale;
  }
  calculateRfProp(rfpropShouldBeResized) {
    const { selectedDoc } = this.props;
    const { rfpropFilter } = this.state;

    let rfprop = selectedDoc.rf_prop;

    // cancel resize if the h3 resolution is already large:
    rfpropShouldBeResized = rfpropShouldBeResized && h3GetResolution(rfprop[0][0]) > RF_PROP_RESIZED_RES;

    if (rfpropShouldBeResized) {
      const byHex = rfprop.reduce((a, b) => {
        const o = { ...a };
        const h = h3ToParent(b[0], RF_PROP_RESIZED_RES);

        o[h] = o[h] ?? { c: 0, m: 0, n: 0 };
        o[h].n += 1;
        o[h].m += b[RF_PROP_COL_MEAN];
        o[h].c += b[RF_PROP_COL_COUNT];

        return o;
      }, {});

      // [hex, RF_PROP_COL_COUNT, RF_PROP_COL_MEAN]
      rfprop = Object.keys(byHex).map((d) => [d, byHex[d].c, byHex[d].m / byHex[d].n]);
    }
    rfprop = !rfpropFilter ? rfprop : rfprop.filter((d) => d[RF_PROP_COL_MEAN] >= rfpropFilter[0] && d[RF_PROP_COL_MEAN] <= rfpropFilter[1]);

    if (rfprop && rfprop.length > 0) {
      let rfpropExtent = d3.extent(selectedDoc.rf_prop.map((d) => d[RF_PROP_COL_MEAN]));
      if (rfpropExtent[0] === rfpropExtent[1]) {
        rfpropExtent = [rfpropExtent[0] - 1, rfpropExtent[0]];
      }
      const rfpropScale = d3.scaleSequential(d3.interpolateRdPu).domain(rfpropExtent);
      const rfpropSteps = Math.min(Math.max(Math.floor(rfpropExtent[1] - rfpropExtent[0]), 2), 5);

      this.setState({
        rfpropResized: rfpropShouldBeResized,
        rfprop,
        rfpropSteps,
        rfpropD3Scale: rfpropScale,
        rfpropScale: (d) => {
          const c = d3.color(rfpropScale(d[RF_PROP_COL_MEAN]));
          return [c.r, c.g, c.b];
        }
      });
    } else {
      this.setState({
        rfpropSteps: 5,
        rfpropScale: null,
        rfpropD3Scale: null,
        rfprop: null,
        rfpropResized: false
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { theme, radioHexDataKey: hexKey, radioHexData, selectedDoc } = this.props;
    const { theme: prevTheme, radioHexDataKey: prevHexKey, selectedDoc: prevSelectedDoc } = prevProps;
    const themeChanged = theme.palette.type !== prevTheme.palette.type;
    const { rfpropFilter, rfpropResized, viewState } = this.state;
    const rfPropFilterChanged = rfpropFilter !== prevState.rfpropFilter;

    if ((!radioHexData || radioHexData.length === 0) && this.state.radioHexData) {
      this.setState({
        radioHexData: null,
        radioHexLegendScale: null
      });
    } else if (hexKey && (themeChanged || hexKey !== prevHexKey)) {
      const radioHexLegendScale = this.buildLegendScale(radioHexData);
      this.setState({
        radioHexData: radioHexData.map((d) => ({ ...d, color: radioHexLegendScale(d.count) })),
        radioHexLegendScale
      });
    }

    const rfpropShouldBeResized = viewState.zoom < RF_PROP_RESIZE_ZOOM;
    const rfpropSizeStale = rfpropShouldBeResized !== rfpropResized;

    if (selectedDoc && (selectedDoc !== prevSelectedDoc || rfPropFilterChanged || rfpropSizeStale)) {
      this.calculateRfPropDebounced(rfpropShouldBeResized);
    } else if (!selectedDoc && this.state.rfpropScale) {
      this.setState({
        rfpropSteps: 5,
        rfpropScale: null,
        rfpropD3Scale: null,
        rfpropFilter: null,
        rfprop: null,
        rfpropResized: false
      });
    }
  }
  fitBounds(bounds) {
    const viewport = this.getViewport();
    if (!viewport) return;

    this.setState((s) => ({
      viewState: {
        ...s.viewState,
        ...this.transitionSettings(),
        ...viewport.fitBounds([
          [bounds.maxx, bounds.maxy],
          [bounds.minx, bounds.miny]
        ])
      }
    }));
  }
  setView(x_lon, y_lat, z) {
    this.setState((s) => ({
      viewState: {
        ...s.viewState,
        ...this.transitionSettings(),
        latitude: y_lat,
        longitude: x_lon,
        zoom: z ? z : 17
      }
    }));
  }
  getViewport() {
    const deck = this._ref.current;
    if (!deck || !deck.deck) return;

    const viewManager = deck.deck.viewManager;
    if (!viewManager) return;

    const viewports = viewManager.getViewports();
    if (!viewports || viewports.length === 0) return;

    return viewports[0];
  }

  updateGlobalViewport() {
    const currentState = this.calculateState();
    if (currentState) {
      const { mapBounds, mapCenter, zoom, mapPolygon } = currentState;
      this.props.updateMapPosition(mapBounds, mapCenter, zoom, mapPolygon);
    }
  }

  updateViewport({ viewState }) {
    const viewport = this.getViewport();
    if (!viewport) return;

    const mapPolygon = this.calculateState(viewport)?.mapPolygon;
    this.setState({ mapPolygon, viewState });

    // throttle the announcements:
    this.updateGlobalViewportDebounced();
  }

  calculateState() {
    const viewport = this.getViewport();
    if (!viewport) return;

    const nw = viewport.unproject([0, 0]);
    const se = viewport.unproject([viewport.width, viewport.height]);
    const ne = viewport.unproject([viewport.width, 0]);
    const sw = viewport.unproject([0, viewport.height]);

    const mapPolygon = {
      nw,
      ne,
      se,
      sw
    };

    const { longitude, latitude, zoom } = viewport;

    const mapBounds = {
      minx: Math.min(nw[0], sw[0]),
      maxx: Math.max(ne[0], se[0]),

      miny: Math.min(se[1], sw[1]),
      maxy: Math.max(nw[1], ne[1])
    };

    const mapCenter = {
      x_lon: longitude,
      y_lat: latitude
    };

    return {
      mapBounds,
      mapCenter,
      zoom,
      mapPolygon
    };
  }

  onLoad() {
    this._iOnLoad = setInterval(() => {
      const s = this.calculateState();
      if (s) {
        clearInterval(this._iOnLoad);
        this._iOnLoad = null;
        this.props.updateMapPosition(s.mapBounds, s.mapCenter, s.zoom, s.mapPolygon);
      }
    }, 250);
  }
  onFilterRfProp(d) {
    if (!d || !d.xValues || d.xValues.length === 0) {
      this.setState({
        rfpropFilter: null
      });
    } else {
      this.setState({
        rfpropFilter: d3.extent(d.xValues)
      });
    }
  }
  onHideAggLayersChanged(hideAggLayers) {
    this.setState({ hideAggLayers });
  }
  onBasemapChange(basemap) {
    this.setState({
      currentBasemap: basemap
    });
  }
  onPitchChange(pitch) {
    this.setState((s) => ({
      viewState: {
        ...s.viewState,
        pitch
      }
    }));
  }

  onDeckGLClicked({ object }) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(object ? object.id : null);
    }
  }

  renderTooltipContents(r) {
    if (r.ssids) {
      return ReactDOMServer.renderToString(
        <React.Fragment>
          <Typography>{`${r.ssids} (${r.n_observations})`}</Typography>
          <Typography variant="caption">{`${r.source_id} (${r.source_type})`}</Typography>
        </React.Fragment>
      );
    } else {
      return ReactDOMServer.renderToString(
        <React.Fragment>
          <Typography>{`${r.network_name} (${r.n_observations})`}</Typography>
          <Typography variant="caption">{`${r.source_id} (${r.network_type} / ${r.source_type})`}</Typography>
        </React.Fragment>
      );
    }
  }
  getDataForTooltip(id, className) {
    this._xhrTooltip.cancel && this._xhrTooltip.cancel();
    const selector = `div.${className}`;
    genericGet(`${API_HOST}/radio/v0/radio/data/${id}`, this._xhrTooltip, (e, r) => {
      if (e) {
        d3.select(selector).html('').style('display', 'none');
        return this.props.pushSnackbar({ message: 'failed to fetch details for mouseover', type: 'error' });
      }
      const html = this.renderTooltipContents(r);
      this._tooltipCache[id] = html;
      d3.select(selector).html(html);
    });
  }
  onScatterplotLayerGetTooltip({ object }) {
    if (object && object.id) {
      const className = `DECKGL_TT_${this.state.uuid}_${object.id.replace(/[^\w]/, '')}`;
      if (this._tooltipCache[object.id]) {
        return {
          html: this._tooltipCache[object.id],
          className
        };
      } else {
        this.getDataForTooltipDebounced(object.id, className);
        return {
          html: ReactDOMServer.renderToString(<CircularProgress size={25} color="inherit" />),
          className
        };
      }
    } else {
      return null;
    }
  }
  render() {
    const { theme, classes, left, mapboxAccessToken, radioPointData, selectedDoc, hoverDoc, hasGPUHardware, dropPin, dataLoading } = this.props;
    const { viewState, currentBasemap, hideAggLayers, radioHexData, radioHexLegendScale, rfpropScale, rfpropD3Scale, rfprop, rfpropSteps, rfpropResized } = this.state;
    if (!hasGPUHardware) return null;
    const { baseLayer, labelLayer } = currentBasemap.getMapStyle(theme);
    return (
      <Box className={classes.map} style={{ left }}>
        <DeckglLayerControl
          currentBasemap={currentBasemap}
          onBasemapChange={this.onBasemapChange}
          onPitchChange={this.onPitchChange}
          currentPitchToggle={viewState.pitch === 0 ? '2D' : '3D'}
        />
        {dataLoading && <Fetching disablePointerEvents={true} />}
        <DeckGL
          onClick={this.onDeckGLClicked}
          glOptions={{ failIfMajorPerformanceCaveat: true }}
          getTooltip={this.onScatterplotLayerGetTooltip}
          ref={this._ref}
          viewState={viewState}
          onLoad={this.onLoad}
          controller={true}
          onViewStateChange={this.updateViewport}
        >
          <StaticMap key="baseLayer" mapStyle={baseLayer} mapboxApiAccessToken={mapboxAccessToken} />
          {!hideAggLayers && radioHexData && radioHexData.length > 0 && (
            <H3HexagonLayer
              id="radio-hex-lyr"
              data={radioHexData}
              pickable={false}
              filled={true}
              getHexagon={(d) => d.hash}
              getFillColor={(d) => d.color}
              extruded={false}
              wireframe={false}
              stroked={false}
              opacity={0.5}
              material={false}
              parameters={{
                blend: false,
                depthTest: false
              }}
            />
          )}
          {!hideAggLayers && radioPointData && (
            <ScatterplotLayer
              id="radio-scatter-lyr"
              data={radioPointData ?? []}
              pickable={true}
              getPosition={({ lon, lat }) => [lon, lat]}
              radiusScale={viewState.zoom > 17 ? 5 : viewState.zoom > 16 ? 10 : 20}
              radiusMinPixels={0.5}
              getFillColor={({ id }) => {
                return id[0] === 'w' ? [173, 221, 142, 200] : [254, 196, 79, 200];
              }}
              parameters={{
                blend: true,
                depthTest: false
              }}
            />
          )}
          {rfprop && rfpropScale && (
            <H3HexagonLayer
              id="radio-rfprop-hex-lyr"
              data={rfprop}
              pickable={false}
              filled={true}
              getHexagon={(d) => d[0]}
              getFillColor={rfpropScale}
              extruded={false}
              wireframe={false}
              stroked={false}
              opacity={0.5}
              material={false}
              parameters={{
                blend: false,
                depthTest: false
              }}
            />
          )}
          {selectedDoc && (
            <IconLayer
              id="radio-scatter-sel-lyr"
              data={[selectedDoc]}
              pickable={false}
              getPosition={({ location }) => [location.lon, location.lat]}
              getIcon={() => ({
                url: 'images/marker-red-high.png',
                width: 512,
                height: 512,
                anchorY: 512
              })}
              sizeScale={5}
              getSize={10}
            />
          )}
          {!hideAggLayers && hoverDoc && (
            <ScatterplotLayer
              id="radio-scatter-hover-lyr"
              data={(radioPointData ?? []).filter((d) => d.id === hoverDoc._id)}
              pickable={false}
              getPosition={({ lon, lat }) => [lon, lat]}
              radiusScale={viewState.zoom > 17 ? 5 : viewState.zoom > 16 ? 10 : 20}
              radiusMinPixels={0.5}
              getFillColor={({ id }) => {
                return id[0] === 'w' ? [49, 163, 84, 255] : [217, 95, 14, 255];
              }}
              // billboard={true}
            />
          )}
          <IconLayer
            data={dropPin}
            id="dropped-pin"
            pickable={false}
            getIcon={() => ({ url: 'images/marker-pin-gpu.png', width: 22, height: 62, anchorY: 62 })}
            getPosition={({ lon, lat }) => [lon, lat]}
            sizeScale={5}
            getSize={10}
          />
          <StaticMap visible={!!labelLayer} key="labelLayer" mapStyle={labelLayer} mapboxApiAccessToken={mapboxAccessToken} />
        </DeckGL>
        <Legend
          selectedDoc={selectedDoc}
          radioHexData={radioHexData}
          radioPointData={radioPointData}
          radioHexLegendScale={radioHexLegendScale}
          rfpropScale={rfpropD3Scale}
          rfpropSteps={rfpropSteps}
          rfpropResized={rfpropResized}
          hideAggLayers={hideAggLayers}
          onHideAggLayersChanged={this.onHideAggLayersChanged}
          onFilterRfProp={this.onFilterRfProp}
        />
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  // Settings
  hasGPU: state.app.hasGPU,
  hasGPUHardware: state.app.hasGPUHardware,
  // Map Details
  mapBounds: state.app.mapBounds,
  mapCenter: state.app.mapCenter,
  mapZoom: state.app.mapZoom,
  mapboxAccessToken: state.app.mapboxAccessToken,
  dropPin: state.app.dropPin
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushSnackbar: pushSnackbarAction,
      updateMapPosition: updateMapPositionAction
    },
    dispatch
  );

RadioDeckMap.propTypes = {
  // ui / ux
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  left: PropTypes.number,
  dataLoading: PropTypes.bool,

  // data
  radioPointData: PropTypes.array,
  radioHexData: PropTypes.array,
  selectedDoc: PropTypes.object,
  hoverDoc: PropTypes.object,
  radioHexDataKey: PropTypes.number,
  hasGPUHardware: PropTypes.bool,
  hasGPU: PropTypes.bool,
  mapZoom: PropTypes.number,
  mapCenter: PropTypes.object,
  mapboxAccessToken: PropTypes.string,
  dropPin: PropTypes.array,
  pushSnackbar: PropTypes.func.isRequired,

  // callbacks
  onSelect: PropTypes.func,
  updateMapPosition: PropTypes.func.isRequired
};

RadioDeckMap.defaultProps = {
  left: 0,
  dropPin: []
};

const withRedux = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
export default withRedux(withTheme(withStyles(styles)(RadioDeckMap)));
