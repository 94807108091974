import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';

const ElevatedTabs = withStyles(() => ({
  root: {
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%)',
    position: 'relative',
    zIndex: 1
  }
}))(Tabs);

export default ElevatedTabs;
