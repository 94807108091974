import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// MODULES
import { makeStyles } from '@material-ui/core/styles';

// MUI
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// REDUX
import { toggleBool as toggleBoolAction } from '../../sentimentSlice';

// OURS
import { PRODUCT_COMPARE } from '../../constants';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(2)
  }
}));

function Settings() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeView = useSelector((state) => state.sentiment.activeView);
  const significantResultsOnly = useSelector((state) => state.sentiment.significantResultsOnly);
  const questionMerging = useSelector((state) => state.sentiment.questionMerging);

  const onSignificantOnlyToggled = useCallback(() => dispatch(toggleBoolAction('significantResultsOnly')), [dispatch]);
  const onQuestionMergingToggled = useCallback(() => dispatch(toggleBoolAction('questionMerging')), [dispatch]);

  if (activeView !== PRODUCT_COMPARE) return null;

  return (
    <FormControl className={classes.formControl}>
      <FormControlLabel className="noselect" name="checkEnabled" control={<Checkbox checked={questionMerging} onChange={onQuestionMergingToggled} />} label="Question Merging" />

      <FormControlLabel
        className="noselect"
        name="significantOnly"
        control={<Checkbox checked={activeView === PRODUCT_COMPARE && significantResultsOnly} onChange={onSignificantOnlyToggled} />}
        label="Significant Findings Only"
      />
    </FormControl>
  );
}

Settings.propTypes = {};
Settings.defaultProps = {};
export default React.memo(Settings);
