import PropTypes from 'prop-types';
import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import numeral from 'numeral';

// ui/ux
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import CircularProgress from '@material-ui/core/CircularProgress';

// ICONS
import RefreshIcon from '@material-ui/icons/Refresh';

const styles = (theme) => ({
  top: {
    position: 'relative',
    flex: '1 1 auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0.5, 1, 1)
  },
  top_fixed: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1)
  },
  top_left: {
    display: 'flex',
    maxWidth: '70%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  top_right: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    color: theme.palette.text.primary,
    fill: theme.palette.text.primary
  },
  widen: { maxWidth: '60%' },
  settingsBlurb: { marginRight: theme.spacing(1), lineHeight: '1.2em', textAlign: 'end', fontSize: '0.6rem' },
  expandButton: {
    marginLeft: theme.spacing(1)
  },
  expandIcon: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandIconOpen: {
    transform: 'rotate(180deg)'
  },
  expandPanel: {
    padding: theme.spacing(2, 0, 0)
  },
  listPhotoToggle: {
    zIndex: 100,
    display: 'block',
    cursor: 'pointer',
    height: 36
  },
  progress: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

class ResultSettings extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      settingsExpanded: window.localStorage.places_settings_expanded === 'true'
    };
    this.handleExpandClick = this.handleExpandClick.bind(this);
  }
  handleExpandClick() {
    this.setState((s) => {
      const settingsExpanded = !s.settingsExpanded;
      window.localStorage.places_settings_expanded = settingsExpanded ? 'true' : 'false';
      return { settingsExpanded };
    });
  }
  render() {
    const { classes, numActiveFilters, resultCount, children, visibleChildren, label, boxShadow, countEstimated, loading, resultCountAccurate, onManualRecount } = this.props;
    const { settingsExpanded } = this.state;
    const topStyle = {};
    if (boxShadow) {
      topStyle.boxShadow = '0px 4px 4px rgb(0 0 0 / 25%)';
      topStyle.zIndex = 1;
    }
    const countStyle = {};
    if (!resultCountAccurate) {
      countStyle.opacity = 0.5;
    }

    const showResultCount = resultCount > 0;

    return (
      <div className={classes.top} style={topStyle}>
        <div className={classes.top_fixed}>
          <div className={classes.top_left}>
            {loading && (
              <Box className={classes.progress}>
                <CircularProgress size={20} color={'inherit'} />
              </Box>
            )}
            {!loading && showResultCount && (
              <>
                <Typography variant="body2" style={countStyle} className="noselect">
                  {`${numeral(resultCount).format(settingsExpanded ? '0,0' : '0.0a')}${countEstimated ? '+' : ''}`}&nbsp;{label}
                </Typography>
                {settingsExpanded && onManualRecount && (
                  <IconButton size="small" onClick={onManualRecount} disabled={resultCountAccurate}>
                    <RefreshIcon fontSize="small" />
                  </IconButton>
                )}
              </>
            )}
            {!loading && !showResultCount && label && (
              <Typography variant="body2" className="noselect">
                {label}
              </Typography>
            )}
          </div>
          <div className={clsx(classes.top_right, !visibleChildren && classes.widen)}>
            {visibleChildren}
            <IconButton size="small" className={classes.expandButton} onClick={this.handleExpandClick}>
              <Badge badgeContent={numActiveFilters} color="secondary">
                <ExpandMoreIcon className={clsx(classes.expandIcon, { [classes.expandIconOpen]: settingsExpanded })} />
              </Badge>
            </IconButton>
          </div>
        </div>
        <Collapse in={settingsExpanded} timeout="auto" unmountOnExit={true}>
          <div className={classes.expandPanel}>{children}</div>
        </Collapse>
      </div>
    );
  }
}

ResultSettings.propTypes = {
  // ui/ux
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,

  // data
  resultCount: PropTypes.number,
  resultCountAccurate: PropTypes.bool,
  countEstimated: PropTypes.bool,
  loading: PropTypes.bool,
  numActiveFilters: PropTypes.number,

  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onManualRecount: PropTypes.func,

  // (if toggles, buttons are wanted on the visible level)
  visibleChildren: PropTypes.node,
  boxShadow: PropTypes.bool
};

ResultSettings.defaultProps = {
  numActiveFilters: 0,
  boxShadow: true,
  countEstimated: false,
  loading: false
};

// const withRedux = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: false });
export default withTheme(withStyles(styles)(ResultSettings));
