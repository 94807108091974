import React, { Component } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// ui/ux
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';

// icons
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '50vw',
    maxHeight: '70vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50vh',
    left: '50vw',
    transform: 'translate(-50%, -50%)',
    outline: 0,
    overflowY: 'auto'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing(1)
    }
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  logoContainer: {
    marginTop: theme.spacing(2),
    marginLeft: 37.5,
    backgroundColor: 'red',
    width: '75%',
    '&:after': {
      content: '""',
      display: 'block',
      paddingBottom: '100%'
    }
  },
  rel: {
    position: 'relative'
  },
  logoInner: {
    height: '50%',
    width: '50%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    backgroundColor: 'white'
  }
});

class AboutModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, onClose, open, irisGitSha, irisBuildDate, irisBuildOS } = this.props;

    return (
      <Modal open={open} onClose={onClose}>
        <div className={classes.paper}>
          <Typography variant="h4" className={classes.title}>
            <RemoveRedEyeIcon /> Iris
          </Typography>
          <Typography variant="body1">Software to explore various geospatial crowd sourced data points.</Typography>
          <Grid container={true} spacing={1} className={classes.rel}>
            <Grid item={true} xs={6} className={classes.rel}>
              <div className={clsx(classes.logoContainer, classes.rel, 'MuiPaper-elevation4')}>
                <div className={classes.logoInner} />
              </div>
            </Grid>
            <Grid item={true} xs={6}>
              <List className={classes.list}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar>
                      <RemoveRedEyeIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Iris Interface"
                    secondary={
                      <React.Fragment>
                        <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                          {irisGitSha}
                        </Typography>
                        <br />
                        {irisBuildDate} <br />
                        {irisBuildOS}
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </div>
      </Modal>
    );
  }
}

AboutModal.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,

  irisGitSha: PropTypes.string,
  irisBuildDate: PropTypes.string,
  irisBuildOS: PropTypes.string
};

AboutModal.defaultProps = {
  open: true,
  irisGitSha: 'unknown',
  irisBuildDate: 'unknown',
  irisBuildOS: 'unknown'
};

export default withTheme(withStyles(styles)(AboutModal));
