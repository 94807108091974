import React, { useEffect, useState } from 'react';

// Modules
import numeral from 'numeral';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { demoActions, demoSelectors } from './demographicsSlice';
import { getAgeAndGender, getDetails } from './services';
import { pushSnackbar as pushSnackbarAction } from '../../actions';

// UI/UX
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// Icons
import CloseIcon from '@material-ui/icons/Close';

// Ours
import PieChart from './components/PieChart';
import { QUESTION_LOOKUP } from '../../constants';
import { Waiting } from '@premisedata/iris-components';
import ResponseBarChart from './components/ResponseBarChart';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1)
  },
  header: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  subtitle: {
    color: theme.palette.text.secondary
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  pieChart: {
    marginBottom: theme.spacing(1),
    minHeight: '140px',
    display: 'flex',
    alignItems: 'center'
  }
}));

const DetailPanel = () => {
  const [ageData, setAgeData] = useState([]);
  const [genderData, setGenderData] = useState([]);

  // Redux
  const dispatch = useDispatch();
  const selectedQuestion = useSelector(demoSelectors.selectSelectedQuestion);
  const colorsByAnswer = useSelector(demoSelectors.selectColorsByAnswer);
  const getDetailsParams = useSelector(demoSelectors.getDetailsQuery);
  const getAgeAndGenderParams = useSelector(demoSelectors.getAgeAndGenderQuery);

  // selectedDetail is guaranteed non-null here
  const { population, respondents, name } = useSelector(demoSelectors.selectSelectedDetail);

  // MUI
  const classes = useStyles();

  // Queries
  const ageAndGenderQuery = getAgeAndGender.useQuery(getAgeAndGenderParams, { skip: !getAgeAndGenderParams });
  const answerDetailsQuery = getDetails.useQuery(getDetailsParams, { skip: !getDetailsParams });

  useEffect(() => {
    if (ageAndGenderQuery.isError) {
      dispatch(pushSnackbarAction({ type: 'error', message: 'Failed to fetch age and gender data' }));
    }

    if (ageAndGenderQuery.isSuccess && !ageAndGenderQuery.isFetching && ageAndGenderQuery.data) {
      if (ageAndGenderQuery.data.age) setAgeData([...ageAndGenderQuery.data.age].sort((a, b) => b.value - a.value));
      if (ageAndGenderQuery.data.gender) setGenderData([...ageAndGenderQuery.data.gender].sort((a, b) => b.value - a.value));
    }
  }, [ageAndGenderQuery.data, ageAndGenderQuery.isError, ageAndGenderQuery.isFetching, ageAndGenderQuery.isSuccess, dispatch]);

  useEffect(() => {
    if (answerDetailsQuery.isError) {
      dispatch(pushSnackbarAction({ type: 'error', message: 'Failed to fetch answer detail data' }));
    }
  }, [answerDetailsQuery.data, answerDetailsQuery.isError, dispatch]);

  return colorsByAnswer ? (
    <Paper className={classes.paper}>
      <Box className={classes.header}>
        <Typography variant="h5" style={{ flex: 1 }}>
          {name}
        </Typography>
        <span>
          <IconButton
            size="small"
            onClick={() => {
              dispatch(demoActions.setSelectedDetail(null));
            }}
          >
            <CloseIcon />
          </IconButton>
        </span>
      </Box>

      <ResponseBarChart loading={answerDetailsQuery.isFetching} data={answerDetailsQuery.data} question={selectedQuestion} colorsByAnswer={colorsByAnswer} />

      <Box className={classes.section}>
        <Typography variant="subtitle1" className={classes.subtitle} gutterBottom={true}>
          Population
        </Typography>
        <Typography>{numeral(population).format(',')}</Typography>
      </Box>

      <Paper className={classes.pieChart} variant="outlined">
        {ageAndGenderQuery.isFetching ? <Waiting /> : <PieChart data={genderData} pieSize={60} title="Gender" question="what_is_your_gender" colorsByAnswer={colorsByAnswer} />}
      </Paper>
      <Paper className={classes.pieChart} variant="outlined">
        {ageAndGenderQuery.isFetching ? <Waiting /> : <PieChart data={ageData} pieSize={60} title="Age" question="year_of_birth" colorsByAnswer={colorsByAnswer} />}
      </Paper>

      <Box className={classes.section}>
        <Typography variant="subtitle1" className={classes.subtitle}>
          Question
        </Typography>
        <Typography>{QUESTION_LOOKUP[selectedQuestion]}</Typography>
      </Box>
      <Box className={classes.section}>
        <Typography variant="subtitle1" className={classes.subtitle}>
          Responses
        </Typography>
        <Typography>
          Total of {numeral(respondents).format(',')} {respondents === 1 ? 'respondent' : 'respondents'}
        </Typography>
      </Box>
    </Paper>
  ) : null;
};

DetailPanel.propTypes = {};
export default DetailPanel;
