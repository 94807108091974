import React from 'react';
import { useSelector } from 'react-redux';

// GLOBALS
import { LEFT_PANEL_WIDTH, TOP } from 'iris-config'; // eslint-disable-line import/no-unresolved

// OURS
import ChartPanel from './components/ChartPanel';
import DeckMapTemporal from './DeckMapTemporal';
import HascBreadCrumbs from './components/HascBreadCrumbs';
import DetailPanel from './DetailPanel';

const ViewTemporal = React.forwardRef((_, ref) => {
  const configPanelOpen = useSelector((state) => state.app.configPanelOpen);
  const selectedHasc = useSelector((state) => state.sentiment.selectedHasc);

  const mapRef = React.useRef();

  React.useImperativeHandle(
    ref,
    () => ({
      buildHashStateObject: () => ({}),
      fitBounds: mapRef.current?.fitBounds,
      setView: mapRef.current?.setView
    }),
    [mapRef]
  );

  const detailPanelOffset = configPanelOpen ? LEFT_PANEL_WIDTH : 0;

  let panelWidths = 0;
  if (configPanelOpen) panelWidths += LEFT_PANEL_WIDTH;
  if (selectedHasc) panelWidths += LEFT_PANEL_WIDTH;

  return (
    <>
      <HascBreadCrumbs bottom={300} left={panelWidths} />
      <ChartPanel height={300} left={panelWidths} />
      <DetailPanel left={detailPanelOffset} top={TOP} />
      <DeckMapTemporal ref={mapRef} left={panelWidths} top={TOP} bottom={300 + 24 /* ChartPanel Height + Breadcrumb height */} />
    </>
  );
});
ViewTemporal.propTypes = {};
ViewTemporal.defaultProps = {};
ViewTemporal.displayName = 'ViewTemporal';
export default React.memo(ViewTemporal);
