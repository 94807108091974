import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme, alpha } from '@material-ui/core/styles';

// UI/UX
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// ICONS
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

// MODULES
import Dropzone from 'react-dropzone';

// OURS
import { API_HOST, withFilePost } from 'iris-api'; // eslint-disable-line import/no-unresolved

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column'
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: '50vh',
    left: '50vw',
    transform: 'translate(-50%, -50%)',
    outline: 0,
    overflowY: 'auto',
    maxHeight: '80vh'
  },
  feedbackWrapper: {
    margin: theme.spacing(3, 0),
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    maxWidth: '50vw'
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  content: {
    marginBottom: theme.spacing(2)
  },
  thanks: {
    margin: theme.spacing(3)
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    '& button:first-of-type': {
      marginRight: theme.spacing(3)
    }
  },
  dropzone: {
    textAlign: 'center',
    background: theme.palette.background.default,
    borderRadius: '4px',
    padding: '18.5px 14px' // based on textfield padding,
  },
  droppedImages: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    '& img': {
      margin: theme.spacing(1, 1, 1, 0),
      height: 'auto',
      maxHeight: 150,
      maxWidth: '100%'
    }
  },
  imageWrapper: {
    position: 'relative',
    '&:hover': {
      '& button': {
        visibility: 'visible'
      }
    }
  },
  removeButton: {
    position: 'absolute',
    top: '-2px',
    right: '0px',
    visibility: 'hidden'
  },
  uploadButton: {
    margin: theme.spacing(1, 0, 2, 0)
  }
});

const RemoveButton = withStyles(({ palette }) => ({
  root: {
    color: palette.error.main,
    '&:hover': {
      backgroundColor: alpha(palette.error.main, 0.08)
    }
  }
}))(IconButton);

class FeedbackModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { feedback: null, title: null, reported: false, screenshots: [], screenshotUrls: [] };
    this._xhr = {};

    // REFS
    this.dropzoneRef = React.createRef();

    // BIND
    this.handleImgUpload = this.handleImgUpload.bind(this);
    this.createIssue = this.createIssue.bind(this);
    this.openDropzone = this.openDropzone.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  createIssue() {
    const { feedback, title, screenshots } = this.state;
    const { onDumpStateGlobal } = this.props;

    // Prep file(s) and fields
    const formData = new FormData();
    for (let i = 0, n = screenshots.length; i < n; ++i) {
      formData.append('uploadedFiles', new Blob([screenshots[i]]));
    }

    formData.append('feedback', feedback);
    formData.append('title', title);
    formData.append('viewState', JSON.stringify(onDumpStateGlobal(), null, ' '));

    this._xhr.cancel && this._xhr.cancel();
    this.setState({ reported: true });
    withFilePost(`${API_HOST}/iris/v0/profile/feedback`, formData, this._xhr, (e) => {
      if (e) return console.error(e);
    });
  }
  componentWillUnmount() {
    this._xhr.cancel && this._xhr.cancel();
  }
  handleImgUpload(images) {
    const add = Array.from(images);
    this.setState((prevState) => {
      const screenshots = [...prevState.screenshots, ...add];
      return { screenshots, screenshotUrls: screenshots.map((f) => URL.createObjectURL(f)) };
    });
  }
  removeUploadedImg(idx) {
    this.setState((prevState) => {
      const ss = [...prevState.screenshots];
      ss.splice(idx, 1);
      const ssUrls = [...prevState.screenshotUrls];
      ssUrls.splice(idx, 1);
      return { screenshots: ss, screenshotUrls: ssUrls };
    });
  }
  openDropzone() {
    this.dropzoneRef.current && this.dropzoneRef.current.open();
  }
  onCloseModal() {
    const { screenshotUrls } = this.state;
    for (let i = 0, n = screenshotUrls.length; i < n; ++i) {
      URL.revokeObjectURL(screenshotUrls[i]);
    }
    // to reset and enable user to submit more feedback in the same session
    this.setState({ reported: false, feedback: null, title: null, screenshots: [], screenshotUrls: [] });
    this.props.onClose();
  }
  render() {
    const { classes, open } = this.props;
    const { feedback, reported, title, screenshotUrls, screenshots } = this.state;
    return (
      <Modal open={open} onClose={this.onCloseModal}>
        <Paper elevation={3} className={classes.paper}>
          <div className={classes.container}>
            {!reported ? (
              <React.Fragment>
                <Typography variant="h6">Share your feedback to help us improve Iris!</Typography>
                <div className={classes.feedbackWrapper}>
                  <TextField
                    className={classes.title}
                    color="secondary"
                    label="What's your feedback about?"
                    value={title || ''}
                    variant="standard"
                    onChange={(evt) => {
                      this.setState({ title: evt.target.value });
                    }}
                  />
                  <TextField
                    className={classes.content}
                    color="secondary"
                    label="Feedback"
                    multiline={true}
                    rows={8}
                    placeholder="I've noticed..."
                    value={feedback || ''}
                    variant="outlined"
                    onChange={(evt) => {
                      this.setState({ feedback: evt.target.value });
                    }}
                  />
                  <Dropzone ref={this.dropzoneRef} accept={['image/png', 'image/jpeg']} onDropAccepted={this.handleImgUpload} noClick={true} maxFiles={5} maxSize={5 * 1024 * 1024}>
                    {({ getRootProps, getInputProps }) => (
                      <div className={classes.dropzone}>
                        <div {...getRootProps()}>
                          <Typography variant="body2">
                            Drag and drop screenshots (how to on{' '}
                            <Link rel="noopener noreferrer" target="_blank" color="secondary" href="https://www.take-a-screenshot.org/mac.html">
                              Mac
                            </Link>
                            ,{' '}
                            <Link rel="noopener noreferrer" target="_blank" color="secondary" href="https://www.take-a-screenshot.org/windows.html">
                              Windows
                            </Link>
                            ), or click to...
                          </Typography>
                          <input {...getInputProps()} />
                          <Button className={classes.uploadButton} onClick={this.openDropzone} color="secondary" startIcon={<PhotoCamera />}>
                            Upload
                          </Button>
                          <Typography variant="body2" style={{ fontStyle: 'italic' }}>
                            Max 5 images, each less than 5MB in file size
                          </Typography>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <Fade in={screenshotUrls.length > 0} timeout={2000}>
                    <div className={classes.droppedImages}>
                      {screenshotUrls.map((s, i) => (
                        <div key={`image-${i}`} className={classes.imageWrapper}>
                          <RemoveButton className={classes.removeButton} size="small" aria-label="delete" onClick={() => this.removeUploadedImg(i)}>
                            <RemoveCircleIcon />
                          </RemoveButton>
                          <img src={s} title={screenshots[i].name} />
                        </div>
                      ))}
                    </div>
                  </Fade>
                </div>
                <div className={classes.buttonWrapper}>
                  <Button variant="contained" onClick={this.onCloseModal}>
                    Cancel
                  </Button>
                  <Button color="secondary" variant="contained" disabled={!feedback || !title} onClick={this.createIssue}>
                    Submit
                  </Button>
                </div>
              </React.Fragment>
            ) : null}
            {reported ? (
              <Typography className={classes.thanks} variant="h6">
                Thanks for the feedback! We'll reach out as soon as possible. In the meantime, if you have any other comments, questions, or suggestions, feel free to return here
                and share them.
              </Typography>
            ) : null}
          </div>
        </Paper>
      </Modal>
    );
  }
}

FeedbackModal.propTypes = {
  // ui / ux
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDumpStateGlobal: PropTypes.func.isRequired
};

export default withTheme(withStyles(styles)(FeedbackModal));
