import PropTypes from 'prop-types';
import React from 'react';
import Box from '@material-ui/core/Box';

import { withStyles, withTheme } from '@material-ui/core/styles';
import { TOP, LEFT_PANEL_WIDTH } from 'iris-config'; // eslint-disable-line import/no-unresolved

const styles = (theme) => ({
  panel: {
    height: `calc(100% - ${TOP}px)`,
    bottom: '0px',
    left: '0px',
    position: 'absolute',
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(1)
  }
});

class LeftPanel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { open, theme, blur, children, classes, id, width, left, noSideMargin, flex } = this.props;

    if (!open) {
      return null;
    }

    const style = {
      width: width - (noSideMargin ? 0 : theme.spacing(2)),
      left,
      margin: noSideMargin ? 0 : theme.spacing(0, 1),
      display: flex ? 'flex' : 'block',
      flexDirection: flex ? 'column' : null
    };
    if (blur) {
      style.filter = 'blur(10px)';
      style.opacity = 1.0;
    }
    return (
      <Box mx={1} className={classes.panel} style={style} id={id}>
        {children}
      </Box>
    );
  }
}

LeftPanel.propTypes = {
  // ui/ux
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  width: PropTypes.number,
  left: PropTypes.number,
  noSideMargin: PropTypes.bool,
  // data
  open: PropTypes.bool,
  blur: PropTypes.bool,
  id: PropTypes.string,
  flex: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

LeftPanel.defaultProps = {
  open: false,
  blur: false,
  flex: false,
  width: LEFT_PANEL_WIDTH,
  left: 0
};

export default withTheme(withStyles(styles)(LeftPanel));
