// Core
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { demoActions, demoSelectors } from './demographicsSlice';
import { getSummaryClick, getDetails, getAnswerDistribution } from './services';
import { updateMapPosition, pushSnackbar, setGlobal } from '../../actions';

// UI/UX
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';

// Modules
import debounce from 'lodash.debounce';
import numeral from 'numeral';
import { scaleLinear, interpolatePuRd } from 'd3';

// DeckGL
import { DeckGL } from '@deck.gl/react';
import { FlyToInterpolator } from '@deck.gl/core';
import { StaticMap } from 'react-map-gl';

// Ours
import { getBuffer, getCoordsFromBuffer } from './demographicsUtility';
import DeckglLayerControl from '../DeckglLayerControl';
import { viewportFromRef } from '../DeckGLUtils';
import { updateMapAttributionImmediate } from 'iris-util'; // eslint-disable-line import/no-unresolved
import { TOP } from 'iris-config'; // eslint-disable-line import/no-unresolved
import MapStyleGen from './MapStyleGen';
import Legend from './Legend';
import { MAP_STYLES_FLAT, VECTOR_TILESETS } from '../../constants';
import useIsMount from '../../hooks/useIsMount';

// GLOBALS
const MAP_ID_SELECTOR = '#view-default-view';

const useStyles = makeStyles((theme) => ({
  map: {
    position: 'absolute',
    right: '0px',
    backgroundColor: theme.palette.background.default,
    height: `calc(100% - ${TOP}px)`, // Top + marginTop
    top: `${TOP}px`,
    overflow: 'hidden'
  }
}));

const DemographicsDeckMap = forwardRef(({ left }, ref) => {
  // Redux
  const dispatch = useDispatch();

  // Redux: Global
  const hasGPUHardware = useSelector((state) => state.app.hasGPUHardware);
  const mapZoom = useSelector((state) => state.app.mapZoom);
  const mapCenter = useSelector((state) => state.app.mapCenter);
  const mapPitch = useSelector((state) => state.app.mapPitch);
  const mapBearing = useSelector((state) => state.app.mapBearing);
  const mapboxAccessToken = useSelector((state) => state.app.mapboxAccessToken);
  const configPanelOpen = useSelector((state) => state.app.configPanelOpen);

  // Redux: Demographics Selectors
  const mapLevel = useSelector(demoSelectors.selectMapLevel);
  const selectedQuestion = useSelector(demoSelectors.selectSelectedQuestion);
  const displayedRegions = useSelector(demoSelectors.selectDisplayedRegions);
  const getDetailsParams = useSelector(demoSelectors.getDetailsQuery);
  const answerDistributionParams = useSelector(demoSelectors.answerDistributionParams);

  // Local State
  const [viewState, setViewState] = useState({
    longitude: mapCenter.x_lon,
    latitude: mapCenter.y_lat,
    zoom: mapZoom,
    maxZoom: 15,
    pitch: mapPitch ?? 50,
    bearing: mapBearing ?? 0,
    maxPitch: 50,
    minPitch: 0
  });
  const [currentBasemap, setCurrentBasemap] = useState(MAP_STYLES_FLAT[0]);
  const [dataStyle, setDataStyle] = useState(null);
  const [colorInterp, setColorInterp] = useState(null);

  // Mui
  const classes = useStyles();
  const theme = useTheme();

  // Refs
  const deckRef = useRef();
  const movedRef = useRef();
  const fitBoundsRef = useRef();

  // Timers
  const mountRef = useRef(null);

  // Queries
  const [sendGetSummaryClickRequest, summaryClickResult] = getSummaryClick.useLazyQuery();
  const answerDetailsQuery = getDetails.useQuery(getDetailsParams, { skip: !getDetailsParams });
  const answerDistributionQuery = getAnswerDistribution.useQuery(answerDistributionParams, { skip: !answerDistributionParams });

  useEffect(() => {
    if (!summaryClickResult) return;

    const { data, isError, isFetching, isSuccess } = summaryClickResult;
    if (isError) {
      return dispatch(pushSnackbar({ type: 'error', message: 'Failed to select detail on click' }));
    }

    if (!isFetching && isSuccess && data) {
      dispatch(demoActions.setSelectedDetail(data));

      // open left panel if not open already
      if (!configPanelOpen)
        dispatch(
          setGlobal({
            configPanelOpen: true
          })
        );
    }
  }, [summaryClickResult, configPanelOpen, dispatch]);

  /**
   * If a choropleth answer is defined, map the displayed regions to the choropleth colors instead.
   * If this choropleth data is available, display it instead of the regular answer colors.
   */
  const choroplethData = useMemo(() => {
    if (displayedRegions && answerDistributionParams && answerDistributionQuery.data) {
      const scale = scaleLinear().domain([0, 1]).range([0, 1]);
      const interp = (x) => interpolatePuRd(scale(x)).split(/\(|\)/)[1];

      return Object.keys(displayedRegions).reduce((obj, region) => {
        if (answerDistributionQuery.data[region]) {
          obj[region] = `rgb(${interp(answerDistributionQuery.data[region])})`;
        }
        return obj;
      }, {});
    } else {
      return null;
    }
  }, [answerDistributionParams, answerDistributionQuery, displayedRegions]);

  const getViewport = () => {
    const deck = deckRef.current;
    if (!deck || !deck.deck) return;

    const viewManager = deck.deck.viewManager;
    if (!viewManager) return;

    const viewports = viewManager.getViewports();
    if (!viewports || viewports.length === 0) return;

    return viewports[0];
  };

  const calculateState = useCallback(() => {
    const viewport = getViewport();
    if (!viewport) return;

    const nw = viewport.unproject([0, 0]);
    const se = viewport.unproject([viewport.width, viewport.height]);
    const ne = viewport.unproject([viewport.width, 0]);
    const sw = viewport.unproject([0, viewport.height]);

    const mapPolygon = {
      nw,
      ne,
      se,
      sw
    };

    const { longitude, latitude } = viewport;

    const mapBounds = {
      minx: Math.min(nw[0], sw[0]),
      maxx: Math.max(ne[0], se[0]),

      miny: Math.min(se[1], sw[1]),
      maxy: Math.max(nw[1], ne[1])
    };

    const mapCenter = {
      x_lon: longitude,
      y_lat: latitude
    };

    return {
      mapPitch: viewport.pitch,
      mapBearing: viewport.bearing,
      zoom: viewport.zoom,
      mapBounds,
      mapCenter,
      mapPolygon
    };
  }, []);

  // Throttle & Debounce
  const updateGlobalViewportThrottled = useMemo(
    () =>
      debounce(
        () => {
          const mapState = calculateState();
          // calculateState can fail when the app first loads:
          if (!mapState) return null;
          const { mapBounds, mapCenter, zoom, mapPolygon, mapPitch, mapBearing } = mapState;
          const mapPolygonBuffered = getCoordsFromBuffer(getBuffer(mapPolygon));
          dispatch(updateMapPosition(mapBounds, mapCenter, zoom, mapPolygon, mapPitch, mapBearing, mapPolygonBuffered));
        },
        250,
        { trailing: true, leading: false }
      ),
    [dispatch, calculateState]
  );

  const updateMapAttribution = useMemo(
    () =>
      debounce(
        ({ offsetX, offsetY }) => {
          const viewport = getViewport();
          const [x, y] = viewport.unproject([offsetX, offsetY]);
          const latitude = numeral(x).format('0[.]00');
          const longitude = numeral(y).format('0[.]00');
          // TO-DO:
          updateMapAttributionImmediate({ zoom: viewport.zoom, latitude, longitude });
        },
        100,
        { trailing: true, leading: false }
      ),
    []
  );

  const onBasemapChanged = useCallback((basemap) => {
    setCurrentBasemap(basemap);
  }, []);

  const onPitchChanged = useCallback(
    (pitch) => {
      setViewState({
        ...viewState,
        pitch
      });
      updateGlobalViewportThrottled();
    },
    [viewState, updateGlobalViewportThrottled]
  );

  useEffect(() => {
    // componentDidMount
    updateGlobalViewportThrottled();
    mountRef.current = setInterval(() => {
      const e = document.querySelector(MAP_ID_SELECTOR);
      if (e) {
        e.addEventListener('mousemove', updateMapAttribution);
        clearInterval(mountRef.current);
        clearInterval(fitBoundsRef.current);
        mountRef.current = null;
        fitBoundsRef.current = null;
      }
    }, 250);

    // componentWillUnmount
    return () => {
      updateGlobalViewportThrottled.cancel();
      updateMapAttribution.cancel();

      if (mountRef.current) {
        clearInterval(mountRef.current);
        mountRef.current = null;
      }

      const e = document.querySelector(MAP_ID_SELECTOR);
      if (e) {
        e.removeEventListener('mousemove', updateMapAttribution);
      }
    };
  }, []); //eslint-disable-line

  useEffect(() => {
    if (!displayedRegions) {
      setDataStyle(null);
      setColorInterp(null);
      return;
    }
    const opacity = ['streets', 'standard'].includes(currentBasemap.type) ? 1.0 : 0.8;
    const V = VECTOR_TILESETS[mapLevel];
    MapStyleGen(
      mapboxAccessToken,
      V.style,
      V.layers,
      choroplethData ?? displayedRegions,
      opacity,
      null, // color_scale
      getDetailsParams?.gid ?? null, // outline_key
      (e, dataStyle) => {
        if (e) return;
        setDataStyle(dataStyle);
        setColorInterp(null);
      }
    );
  }, [displayedRegions, choroplethData, setCurrentBasemap, mapLevel, getDetailsParams?.gid, currentBasemap.type, mapboxAccessToken]);

  const updateViewport = ({ viewState }) => {
    const viewport = getViewport();

    if (!viewport) return;

    setViewState(viewState);
    updateGlobalViewportThrottled();
  };

  const onMapClicked = useCallback(
    (e) => {
      if (!selectedQuestion) return;

      const viewport = getViewport();
      if (!viewport) return;
      const clientRect = e.target.getBoundingClientRect();
      const cx = e.clientX - clientRect.left;
      const cy = e.clientY - clientRect.top;
      const [x, y] = viewport.unproject([cx, cy]);
      sendGetSummaryClickRequest({ x, y, question: selectedQuestion, level: mapLevel });
    },
    [mapLevel, selectedQuestion, sendGetSummaryClickRequest]
  );

  // ref methods
  const fitBounds = ({ minx, miny, maxx, maxy }) => {
    const viewport = viewportFromRef(deckRef);
    if (!viewport) return;
    setViewState({
      ...viewport.fitBounds([
        [minx, miny],
        [maxx, maxy]
      ]),
      transitionDuration: 2000,
      transitionInterpolator: new FlyToInterpolator()
    });
  };
  useImperativeHandle(
    ref,
    () => ({
      fitBounds,
      setView: (x_lon, y_lat, z) => {
        if (deckRef.current) {
          setViewState({
            latitude: y_lat,
            longitude: x_lon,
            zoom: z ?? viewState.zoom,
            transitionDuration: 2000,
            transitionInterpolator: new FlyToInterpolator()
          });
        }
      }
    }),
    [viewState.zoom]
  );

  const isMount = useIsMount();
  useEffect(() => {
    if (
      // \n
      !isMount &&
      !answerDetailsQuery.isError &&
      answerDetailsQuery.data?.length > 0
    ) {
      fitBounds({
        minx: answerDetailsQuery.data[0].geometry[0],
        miny: answerDetailsQuery.data[0].geometry[1],
        maxx: answerDetailsQuery.data[0].geometry[2],
        maxy: answerDetailsQuery.data[0].geometry[3]
      });
    }
    // isMount should not be in dependency array, its a hacky solution already
  }, [answerDetailsQuery.data, answerDetailsQuery.isError]); //eslint-disable-line

  // event handlers
  const mapMouseMove = useCallback(() => (movedRef.current += 1), []);
  const mapMouseDown = useCallback(() => (movedRef.current = 0), []);
  const mapMouseUp = useCallback(
    (e) => {
      if (movedRef.current < 5) onMapClicked(e);
    },
    [onMapClicked]
  );

  // memoized object props
  const leftStyle = useMemo(() => ({ left }), [left]);
  const glOptions = useMemo(() => ({ failIfMajorPerformanceCaveat: true }), []);

  if (!hasGPUHardware) return null;

  return (
    <Box className={classes.map} style={leftStyle} onMouseMove={mapMouseMove} onMouseDown={mapMouseDown} onMouseUp={mapMouseUp}>
      {/* Wrap layer control in net to catch mouse events */}
      <DeckglLayerControl
        currentBasemap={currentBasemap}
        onBasemapChange={onBasemapChanged}
        onPitchChange={onPitchChanged}
        currentPitchToggle={viewState.pitch === 0 ? '2D' : '3D'}
      />
      <DeckGL glOptions={glOptions} ref={deckRef} viewState={viewState} controller={true} onViewStateChange={updateViewport}>
        <StaticMap key="baseLayer" mapStyle={currentBasemap.getMapStyle(theme).baseLayer} mapboxApiAccessToken={mapboxAccessToken} />
        {dataStyle ? <StaticMap mapStyle={dataStyle} mapboxApiAccessToken={mapboxAccessToken} /> : null}
        <StaticMap
          visible={!!currentBasemap.getMapStyle(theme).labelLayer}
          key="labelLayer"
          mapStyle={currentBasemap.getMapStyle(theme).labelLayer}
          mapboxApiAccessToken={mapboxAccessToken}
        />
      </DeckGL>
      <Legend selectedQuestion={selectedQuestion} colorInterp={colorInterp} />
    </Box>
  );
});

DemographicsDeckMap.displayName = 'DemographicsDeckMap';
DemographicsDeckMap.propTypes = {
  left: PropTypes.number
};

DemographicsDeckMap.defaultProps = {
  left: 0
};

export default DemographicsDeckMap;
