import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { scaleLinear, select, scaleBand, interpolatePuRd, axisBottom } from 'd3';
import numeral from 'numeral';
import { useTheme } from '@material-ui/core';

const LegendScale = ({ dimensions }) => {
  const theme = useTheme();

  const svgRef = useRef(null);

  useEffect(() => {
    const colorInterp = theme.palette.type === 'dark' ? interpolatePuRd : interpolatePuRd;

    const data = [0, 0.2, 0.4, 0.6, 0.8, 1.0];
    const scale = scaleLinear().domain([0, 1]).range([0, 1]);
    const interp = (x) => colorInterp(scale(x)).split(/\(|\)/)[1];
    const colors = data.map(interp);

    const svg = select(svgRef.current);
    svg.selectAll('*').remove();
    const margin = { top: 2, right: 2, bottom: 2, left: 2 },
      width = +svg.attr('width') - margin.left - margin.right;

    const x = scaleBand()
      .domain(colors)
      .rangeRound([margin.left, width - margin.right]);

    const rectWidth = x(colors[1]) - x(colors[0]);

    svg
      //   .html('')
      .append('g')
      .selectAll('rect')
      .data(colors)
      .join('rect')
      .attr('x', (d) => x(d))
      .attr('y', margin.top)
      .attr('width', rectWidth > 0 ? rectWidth : 0)
      .attr('height', 16)
      .attr('fill', (d) => {
        return `rgb(${d}, 0.85)`;
      });

    svg
      .append('g')
      .attr('transform', `translate(${rectWidth / 2.0},${margin.top})`)
      .call(
        axisBottom(x)
          .tickFormat((d, i) => numeral(data[i]).format('0.0%'))
          .ticks(colors.length)
          .tickSize(16)
          .tickValues(colors)
      )
      .call((g) => g.select('.domain').remove())
      .selectAll('text')
      .style('text-anchor', 'end');
  }, [dimensions, theme.palette.type]);

  return <svg ref={svgRef} width={dimensions.width} height={dimensions.height} />;
};

LegendScale.propTypes = {
  dimensions: PropTypes.object
};

export default LegendScale;
