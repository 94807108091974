import { irisApi } from '../../services';

export const placesApi = irisApi.injectEndpoints({
  endpoints: (builder) => ({
    getCategoriesWithFilters: builder.query({
      query: () => {
        const body = {};
        return {
          url: '/places/v0/segments/topics',
          method: 'post',
          body
        };
      }
    }),
    getCategories: builder.query({
      query: () => {
        return {
          url: '/places/v0/segments/topics',
          method: 'get'
        };
      }
    }),
    getGeographies: builder.query({
      query: ({ query }) => {
        return {
          url: `/places/v0/metadata/hascs/${encodeURIComponent(query)}`,
          method: 'get'
        };
      }
    }),
    getLanguages: builder.query({
      query: () => {
        return {
          url: '/places/v0/metadata/languages',
          method: 'get'
        };
      }
    }),
    getLanguagesWithinPolygon: builder.query({
      query: ({ mapPolygon }) => {
        return {
          url: '/places/v0/metadata/languages',
          method: 'post',
          body: mapPolygon
        };
      }
    }),
    getUserdataMetadata: builder.query({
      transformResponse: (r) => ({
        layers: r.layers,
        version: r.userdataVersion,
        layersById: r.layers.reduce((a, b) => ({ ...a, [b.id]: b }), {})
      }),
      query: () => {
        return {
          url: '/uploads/v0/view/layers',
          method: 'get'
        };
      },
      providesTags: ['UserdataMetadata']
    })
  }),
  overrideExisting: false
});

export const { getUserdataMetadata, getCategories, getCategoriesWithFilters, getGeographies, getLanguages, getLanguagesWithinPolygon } = placesApi.endpoints;
