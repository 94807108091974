import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

// MODULES
import clsx from 'clsx';
import moment from 'moment';

// MUI
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

// MUI: ICONS
import CloseIcon from '@material-ui/icons/Close';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';

const useStyles = makeStyles((theme) => ({
  avatar: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.getContrastText(theme.palette.secondary.main)
  },
  verticalDivider: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5)
  }
}));

const InboxItemSentimentRequest = (props) => {
  const theme = useTheme();
  const { item, onDelete } = props;

  const statusColor = (phase) => {
    if (phase === 'Failed') {
      return theme.palette.error.main;
    } else if (phase === 'Succeeded') {
      return theme.palette.success.main;
    } else {
      return 'inherit';
    }
  };

  const classes = useStyles();

  const onDeleteCallback = useCallback(() => {
    onDelete(item);
  }, [item, onDelete]);

  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Avatar className={clsx({ [classes.avatar]: !item.seen_on })}>
            <SentimentSatisfiedAltIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={<Typography noWrap={true}>{item.form_name}</Typography>}
          secondaryTypographyProps={{
            component: 'div'
          }}
          secondary={
            <React.Fragment>
              <Typography variant="caption">{item.question_proper}</Typography>
              <ul>
                <li>
                  Status: <span style={{ color: statusColor(item.phase) }}>{item.phase}</span>
                </li>
                <li>Created: {moment(item.created_on).fromNow()}</li>
                {item.phase === 'Complete' && (
                  <React.Fragment>
                    <li>Completed: {moment(item.completed_on).fromNow()}</li>
                    <li>Duration: {moment.duration(moment(item.created_on).diff(moment(item.completed_on))).humanize()}</li>
                  </React.Fragment>
                )}
              </ul>
            </React.Fragment>
          }
        />

        <IconButton onClick={onDeleteCallback}>
          <CloseIcon />
        </IconButton>
      </ListItem>
      <Divider />
    </>
  );
};

InboxItemSentimentRequest.propTypes = {
  item: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default React.memo(InboxItemSentimentRequest);
