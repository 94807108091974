import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';

// MODULES
import { extent, scaleLinear, scaleTime } from 'd3';

// VISX
import { Line } from '@visx/shape';
import { Group } from '@visx/group';

const RugPlot = (props) => {
  const theme = useTheme();
  const { width, height, top, margin, dataType, temporalDomain } = props;

  const xScale = useMemo(
    () => scaleTime()
      .range([0, width - margin.left - margin.right])
      .domain(temporalDomain),
    [width, margin, temporalDomain]
  );

  const [data, setData] = useState(null);
  useEffect(() => {
    if (!dataType || !props.data) {
      return setData(null);
    }

    if (dataType === 'ordinal') {
      const opacityScale = scaleLinear()
        .domain(extent(props.data.map((d) => d.num_samples)))
        .range([0.2, 1.0]);
      setData({
        opacityScale,
        data: props.data
      });
    } else {
      // Collapse categorical data:
      const samplesByDate = {};
      for (const d of props.data) {
        samplesByDate[d.date] = samplesByDate[d.date] > 0 ? samplesByDate[d.date] + d.num_samples : d.num_samples;
      }

      // convert back to an array:
      const flattenedData = Object.entries(samplesByDate).map((d) => ({ date: d[0], num_samples: d[1] }));

      // build opacity scale based on # samples:
      const opacityScale = scaleLinear()
        .domain(extent(flattenedData.map((d) => d.num_samples)))
        .range([0.2, 1.0]);

      setData({
        opacityScale,
        data: flattenedData
      });
    }
  }, [props.data, dataType]);

  if (!data || width < 10 || height < 10) {
    return null;
  }

  return (
    <Group top={top} left={margin.left}>
      {data.data.map((d) => (
        <Line
          key={d.date}
          stroke={theme.palette.text.primary}
          strokeOpacity={data.opacityScale(d.num_samples)}
          from={{ x: xScale(d.date), y: 0 }}
          to={{ x: xScale(d.date), y: height }}
        />
      ))}
    </Group>
  );
};

RugPlot.propTypes = {
  top: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  margin: PropTypes.object.isRequired,
  temporalDomain: PropTypes.array.isRequired,
  data: PropTypes.array,
  dataType: PropTypes.string
};
RugPlot.defaultProps = {};
export default React.memo(RugPlot);
