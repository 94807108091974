import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import numeral from 'numeral';

import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DoneIcon from '@material-ui/icons/Done';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

import IsolatedSlider from '../../IsolatedSlider';

const useStyles = makeStyles((theme) => ({
  drawer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    borderTop: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    // transition: 'left 1s',
    padding: theme.spacing(1) // `0px ${theme.spacing(1)}px`
  },
  controls: {
    position: 'absolute',
    overflow: 'hidden',
    // transition: 'left 1s',
    bottom: 0
  },
  divider: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5)
  },
  drawerButton: {
    position: 'absolute',
    border: `1px solid ${theme.palette.divider}`,
    borderLeft: 0,
    // transition: 'left 1s',
    zIndex: 10
  }
}));

const ChartDrawer = (props) => {
  const classes = useStyles();
  const {
    alertMinAlerts,
    alertPctChange,
    alertSampleSize,
    alertSignificance,
    alertType,
    left,
    onAlertMinAlertsChanged,
    onAlertPctChanged,
    onAlertSampleSizeChanged,
    onAlertSignificanceChanged,
    width,
    height,
    yScaleZoomed,
    onYScaleZoomedToggled,
    drawerOpen,
    onDrawerOpenToggled,
    toggleAlertType
  } = props;

  const btnStyle = {
    left: drawerOpen ? left + width : left,
    bottom: (height - 24) / 2
  };
  return (
    <React.Fragment>
      <ButtonBase id="expand-filters" className={classes.drawerButton} style={btnStyle} onClick={onDrawerOpenToggled}>
        {drawerOpen ? <ArrowLeftIcon /> : <ArrowRightIcon />}
      </ButtonBase>
      <Box className={classes.controls} style={{ left, height, width }}>
        <Box className={classes.drawer} style={{ left: drawerOpen ? '0' : 'calc(-100% + 1px)' }}>
          <Box>
            <Tooltip title={yScaleZoomed ? 'yScale: Zoom out' : 'yScale: Zoom in'}>
              <IconButton size="small" onClick={onYScaleZoomedToggled}>
                {yScaleZoomed ? <ZoomOutIcon /> : <ZoomInIcon />}
              </IconButton>
            </Tooltip>
            <Tooltip title={alertType === 'weekly' ? 'Switch to daily alerts' : 'Switch to weekly alerts'}>
              <IconButton size="small" onClick={toggleAlertType}>
                {alertType === 'weekly' ? <DateRangeIcon /> : <CalendarTodayIcon />}
              </IconButton>
            </Tooltip>
            <Divider variant="middle" className={classes.divider} />

            <Typography id="sample-size-slider" gutterBottom={true}>
              Sample Size
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true}>
                <PersonIcon />
              </Grid>
              <Grid item={true} xs={true}>
                <IsolatedSlider
                  min={5}
                  max={300}
                  value={alertSampleSize}
                  onChangeCommitted={(_, v) => {
                    onAlertSampleSizeChanged(v);
                  }}
                  aria-labelledby="sample-size-slider"
                  valueLabelDisplay="auto"
                  color="secondary"
                />
              </Grid>
              <Grid item={true}>
                <PeopleIcon />
              </Grid>
            </Grid>
            <Typography id="percent-change-slider" gutterBottom={true}>
              % Change
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true}>
                <TrendingFlatIcon />
              </Grid>
              <Grid item={true} xs={true}>
                <IsolatedSlider
                  step={0.001}
                  min={0}
                  max={0.1}
                  valueLabelFormat={(d) => numeral(d).format('0.0%')}
                  value={alertPctChange}
                  onChangeCommitted={(_, v) => {
                    onAlertPctChanged(v);
                  }}
                  aria-labelledby="percent-change-slider"
                  valueLabelDisplay="auto"
                  color="secondary"
                />
              </Grid>
              <Grid item={true}>
                <TrendingUpIcon />
              </Grid>
            </Grid>
            <Typography id="significance-slider" gutterBottom={true}>
              Significance
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true}>
                <DoneIcon />
              </Grid>
              <Grid item={true} xs={true}>
                <IsolatedSlider
                  min={90}
                  max={99}
                  valueLabelFormat={(d) => `${d}%`}
                  value={alertSignificance}
                  marks={[{ value: 90 }, { value: 95 }, { value: 99 }]}
                  step={null}
                  onChangeCommitted={(_, v) => {
                    onAlertSignificanceChanged(v);
                  }}
                  aria-labelledby="significance-slider"
                  valueLabelDisplay="auto"
                  color="secondary"
                />
              </Grid>
              <Grid item={true}>
                <DoneOutlineIcon />
              </Grid>
            </Grid>

            {alertType === 'weekly' && (
              <React.Fragment>
                <Typography id="min-alerts-slider" gutterBottom={true}>
                  Max Alerts{/* a cap for number of alerts in week user will see */}
                </Typography>
                <Grid container={true} spacing={2}>
                  <Grid item={true}>
                    <NotificationsNoneIcon />
                  </Grid>
                  <Grid item={true} xs={true}>
                    <IsolatedSlider
                      min={1}
                      max={7}
                      value={alertMinAlerts}
                      onChangeCommitted={(_, v) => onAlertMinAlertsChanged(v)}
                      aria-labelledby="min-alerts-slider"
                      valueLabelDisplay="auto"
                      color="secondary"
                    />
                  </Grid>
                  <Grid item={true}>
                    <NotificationsActiveIcon />
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};
ChartDrawer.propTypes = {
  width: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  yScaleZoomed: PropTypes.bool.isRequired,
  onYScaleZoomedToggled: PropTypes.func.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  onDrawerOpenToggled: PropTypes.func.isRequired,

  // Copied from <ChartPanel />
  // TODO: move to sentiment slice:
  alertType: PropTypes.string,
  toggleAlertType: PropTypes.func,
  alertSampleSize: PropTypes.number,
  onAlertSampleSizeChanged: PropTypes.func,
  alertSignificance: PropTypes.number,
  onAlertSignificanceChanged: PropTypes.func,
  alertPctChange: PropTypes.number,
  onAlertPctChanged: PropTypes.func,
  alertMinAlerts: PropTypes.number,
  onAlertMinAlertsChanged: PropTypes.func
};
ChartDrawer.defaultProps = {};
export default React.memo(ChartDrawer);
