import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// MODULES
import { useTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';

// MUI
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

// OURS
import { datePickerLightTheme, datePickerDarkTheme } from '../../../AppThemes';

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  card: {
    display: 'flex',
    flexFlow: 'column'
  }
}));

function DateAndTimeFrameSelector({ selectedStartDate, selectedEndDate, onDateRangeChange }) {
  const externalTheme = useTheme();
  const classes = useStyles();

  const onStartChanged = useCallback(
    (date) => {
      onDateRangeChange(date, 'start');
    },
    [onDateRangeChange]
  );

  const onEndChanged = useCallback(
    (date) => {
      onDateRangeChange(date, 'end');
    },
    [onDateRangeChange]
  );

  return (
    <Card variant="outlined">
      <CardContent classes={{ root: classes.card }}>
        <Typography color="textSecondary" gutterBottom={true}>
          Date Recency: Custom
        </Typography>
        <ThemeProvider theme={externalTheme.palette.type === 'dark' ? datePickerDarkTheme : datePickerLightTheme}>
          <KeyboardDatePicker
            disableToolbar={true}
            variant="inline"
            format="MM/DD/YYYY"
            margin="normal"
            autoOk={true}
            clearable={true}
            disableFuture={true}
            value={moment(selectedStartDate)}
            label="Start"
            onChange={onStartChanged}
            className={classes.marginBottom}
          />
          <KeyboardDatePicker
            disableToolbar={true}
            variant="inline"
            format="MM/DD/YYYY"
            margin="normal"
            value={moment(selectedEndDate)}
            label="End"
            onChange={onEndChanged}
            autoOk={true}
            clearable={true}
            disableFuture={true}
          />
        </ThemeProvider>
      </CardContent>
    </Card>
  );
}

DateAndTimeFrameSelector.propTypes = {
  selectedStartDate: PropTypes.number,
  selectedEndDate: PropTypes.number,
  onDateRangeChange: PropTypes.func
};

export default DateAndTimeFrameSelector;
