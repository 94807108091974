import React from 'react';

// modules
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';

// ui / ux
import { withStyles, withTheme, alpha } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';

// icons
import CheckIcon from '@material-ui/icons/Check';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(1)
  },
  imgBox: {
    position: 'relative'
  },
  img: {
    maxHeight: '50vh',
    minHeight: '300px',
    width: '100%',
    verticalAlign: 'middle',
    display: 'block',
    margin: 'auto',
    objectFit: 'contain',
    pointerEvents: 'none'
  },
  enlargeBtn: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2)
  },
  middleDivider: {
    margin: `${theme.spacing(1)}px 0`
  },
  btnOverlay: {
    position: 'absolute',
    width: '50%',
    height: '100%',
    top: 0
  },
  btnOverlayApprove: {
    right: 0
  },
  btnOverlayDisapprove: {
    left: 0
  },
  btn: {
    top: '50%',
    marginTop: -28 /* 50% of element height */
  },
  btnApprove: {
    position: 'absolute',
    left: '20%',
    backgroundColor: alpha('#00FF00', 0.3),
    '&:hover': {
      backgroundColor: alpha('#00FF00', 0.5)
    }
  },
  btnDisapprove: {
    position: 'absolute',
    right: '20%',
    backgroundColor: alpha('#FF0000', 0.3),
    '&:hover': {
      backgroundColor: alpha('#FF0000', 0.5)
    }
  },
  subName: {
    fontSize: '20px',
    lineHeight: '30px'
  },
  subSubmitted: {
    fontSize: '15px',
    lineHeight: '15px'
  }
});

class ValidateSubmissionPaper extends React.PureComponent {
  constructor(props) {
    super(props);

    this.accept = this.accept.bind(this);
    this.reject = this.reject.bind(this);
  }
  accept() {
    if (this.props.onAccept) {
      this.props.onAccept(this.props.submission);
    }
  }
  reject() {
    if (this.props.onReject) {
      this.props.onReject(this.props.submission);
    }
  }
  render() {
    const { classes, submission } = this.props;
    if (!submission) {
      return null;
    }
    return (
      <Paper className={classes.paper}>
        <Box className={classes.subName}>{submission.name}</Box>
        <Box className={classes.subSubmitted}>{`Submitted: ${moment(submission.timestamp).format('YYYY-MM-DD HH:MM ZZ')}`}</Box>
        <Divider variant="middle" className={classes.middleDivider} />
        <Box className={classes.imgBox}>
          <img alt={`submission #${submission.sub_id}`} key={submission.sub_id} src={submission.photo} className={classes.img} />
          <Box className={clsx(classes.btnOverlay, classes.btnOverlayDisapprove)}>
            <Tooltip placement="top" arrow={true} title="Reject Photo">
              <Fab className={clsx(classes.btn, classes.btnDisapprove)} onClick={this.props.onReject}>
                <NotInterestedIcon />
              </Fab>
            </Tooltip>
          </Box>
          <Box className={clsx(classes.btnOverlay, classes.btnOverlayApprove)}>
            <Tooltip placement="top" arrow={true} title="Approve Photo">
              <Fab className={clsx(classes.btn, classes.btnApprove)} onClick={this.props.onAccept}>
                <CheckIcon />
              </Fab>
            </Tooltip>
          </Box>
          <IconButton
            className={classes.enlargeBtn}
            onClick={() => {
              window.open(submission.photo, '_blank');
            }}
          >
            <OpenInNewIcon />
          </IconButton>
        </Box>
      </Paper>
    );
  }
}

// this component will render @ 50vh + 78px
ValidateSubmissionPaper.propTypes = {
  // material-ui
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  // data
  submission: PropTypes.object.isRequired,
  // callbacks
  onAccept: PropTypes.func,
  onReject: PropTypes.func
};

ValidateSubmissionPaper.defaultProps = {};

export default withTheme(withStyles(styles)(ValidateSubmissionPaper));
