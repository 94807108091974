import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// MODULES
import clsx from 'clsx';
import numeral from 'numeral';
import { range } from 'd3';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// MUI Icons
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import ClearIcon from '@material-ui/icons/Clear';
import WifiIcon from '@material-ui/icons/Wifi';

// OURS
import { LEFT_PANEL_WIDTH } from 'iris-config'; // eslint-disable-line import/no-unresolved
import * as radioUtils from './utils';
import ChipPopover from '../ChipPopover';
import ChipPopoverCheck from '../ChipPopoverCheck';
import SortChipPopover from '../SortChipPopover';
import VAC from './components/VirtualizedAutocomplete';
import ResultSettings from '../ResultSettings';
import IsolatedSlider from '../IsolatedSlider';
import ChipDateRangePicker from '../ChipDateRangePicker';
import TextFieldDebounced from '../../components/TextFieldDebounced';
import { getRadioMetadata, getRadioCountries } from './services';

// GLOBALS
const MARKS = range(0, radioUtils.MAX_ACCURACY + 25, 25).map((d) => ({ value: d, label: `${d}` }));
const POPOVER_ORIGINS_SIDE = {
  anchorOrigin: {
    vertical: 'center',
    horizontal: 'right'
  },
  transformOrigin: {
    vertical: 'center',
    horizontal: 'left'
  }
};

const useStyles = makeStyles((theme) => ({
  tabs: { borderBottom: `1px solid ${theme.palette.grey.A700}` },
  tab: {
    flexDirection: 'row',
    padding: theme.spacing(1.5, 0, 1)
  },
  tabIcon: {
    display: 'flex',
    marginRight: theme.spacing(1)
  },
  tabTypography: {
    display: 'flex'
  },
  marginTopBottom: {
    margin: theme.spacing(0.5, 0.5, 0.5, 0.5)
  },
  vac: {
    width: 300
  },
  textfield: {
    width: 300,
    marginBottom: theme.spacing(2)
  },
  popoverTypography: {
    maxWidth: 300
  },
  popoverBtn: {
    width: '100%',
    display: 'block'
  },
  tooltip: {
    maxWidth: 'none'
  },
  helperTextLink: {
    display: 'inline-block',
    textDecoration: 'underline',
    cursor: 'pointer',
    margin: theme.spacing(0.5, 0, 0.5, 0)
  },
  slider: {
    width: 300
  },
  sliderContainer: {
    margin: theme.spacing(0, 2, 0, 2)
  },
  otherFiltersBox: {
    maxWidth: LEFT_PANEL_WIDTH,
    display: 'flex',
    flexFlow: 'row wrap'
  },
  checkboxOption: {
    paddingLeft: theme.spacing(1.5)
  },
  valueLabel: {
    fontSize: '0.5em',
    fontWeight: theme.typography.fontWeightBold,
    '& > span > span': {
      color: theme.palette.getContrastText(theme.palette.secondary.main)
    }
  },
  resetChip: {
    transition: `border-color ${theme.transitions.duration.standard}ms ${theme.transitions.easing.sharp}`,
    '&:hover': {
      borderColor: theme.palette.error.main
    }
  }
}));

const FilterPanel = ({ dataType, radioDataFilters, renderBothDataTypes, onRenderBothDataTypesChanged, onDataTypeChanged, updateRadioDataFilters, resetRadioDataFilters }) => {
  const classes = useStyles();

  const getRadioMetadataQuery = getRadioMetadata.useQuery();
  const getRadioCountriesQuery = getRadioCountries.useQuery();

  // USECALLBACK
  const sortDisabled = useCallback(
    (type, dt = dataType) => {
      if (type === 'network_name' && dt === 'wifi') return true;
      if (type === 'ssids' && dt === 'cell') return true;
      return false;
    },
    [dataType]
  );
  const onDataTypeChangedCallback = useCallback(
    (_, dt) => {
      // sort might have to be reset, e.g. some sort options are only available on wifi
      const newSort = sortDisabled(radioDataFilters.sort, dt) ? 'n_observations' : radioDataFilters.sort;
      if (dt !== dataType) onDataTypeChanged(dt, newSort);
    },
    [onDataTypeChanged, dataType, radioDataFilters.sort, sortDisabled]
  );

  const getOptionLabelSort = useCallback((v) => {
    return radioUtils.sortTypesToNiceName[v];
  }, []);

  const onSortChanged = useCallback(
    ({ sort, sortDirection }) => {
      updateRadioDataFilters({
        sort,
        sortOrder: sortDirection
      });
    },
    [updateRadioDataFilters]
  );
  const onNetworkNamesChanged = useCallback(
    (_, network_names) => {
      updateRadioDataFilters({
        network_names
      });
    },
    [updateRadioDataFilters]
  );
  const onNetworkTypesChanged = useCallback(
    (network_types) => {
      updateRadioDataFilters({
        network_types
      });
    },
    [updateRadioDataFilters]
  );
  const onNetworkManufacturersChanged = useCallback(
    (_, manufacturers) => {
      updateRadioDataFilters({
        manufacturers
      });
    },
    [updateRadioDataFilters]
  );
  const onNetworkMovementTypeChanged = useCallback(
    (movement_type) => {
      if (!movement_type || movement_type.length === 0) return updateRadioDataFilters({ movement_type: null });
      updateRadioDataFilters({
        movement_type: movement_type.map((d) => radioUtils.movementTypesLookup[d])
      });
    },
    [updateRadioDataFilters]
  );
  const onNetworkDatesChanged = useCallback(
    (dates) => {
      updateRadioDataFilters({
        last_seen: dates
      });
    },
    [updateRadioDataFilters]
  );
  const onNetworkCountriesChanged = useCallback(
    (_, countries) => {
      updateRadioDataFilters({ countries });
    },
    [updateRadioDataFilters]
  );
  const onNetworkSSIDChanged = useCallback(
    (ssid) => {
      updateRadioDataFilters({
        ssid
      });
    },
    [updateRadioDataFilters]
  );
  const onNetworkBSSIDChanged = useCallback(
    (bssid) => {
      updateRadioDataFilters({
        bssid
      });
    },
    [updateRadioDataFilters]
  );
  const onNetworkManufacturersCountriesChanged = useCallback(
    (_, manufacturer_countries) => {
      updateRadioDataFilters({
        manufacturer_countries
      });
    },
    [updateRadioDataFilters]
  );
  const onNetworkAccuracyChanged = useCallback(
    (_, accuracy) => {
      updateRadioDataFilters({
        accuracy
      });
    },
    [updateRadioDataFilters]
  );
  const onNetworkAccuracyReset = useCallback(() => {
    updateRadioDataFilters({
      accuracy: radioUtils.DEFAULT_ACCURACY
    });
  }, [updateRadioDataFilters]);
  const onNetworkTowerIdChanged = useCallback(
    (tower_id) => {
      // handle clear
      if (typeof tower_id === 'string') {
        updateRadioDataFilters({ tower_id });
      } else {
        updateRadioDataFilters({ tower_id: undefined });
      }
    },
    [updateRadioDataFilters]
  );
  const onNetworkARFCNChanged = useCallback(
    (_, arfcn_downlink) => {
      updateRadioDataFilters({ arfcn_downlink });
    },
    [updateRadioDataFilters]
  );
  const onFilterReset = useCallback(() => {
    resetRadioDataFilters();
  }, [resetRadioDataFilters]);

  const downlinkValueFormat = (v) => {
    return numeral(v ?? 0).format('0.0a');
  };

  const arfcnMeta = getRadioMetadataQuery.data?.cell?.arfcn?.downlink;
  const arfcnValue = radioDataFilters.arfcn_downlink ?? arfcnMeta;
  let arfcnActive = false;
  if (arfcnMeta) {
    arfcnActive = arfcnValue && (arfcnMeta[0] !== arfcnValue[0] || arfcnMeta[1] !== arfcnValue[1]);
  }

  let numActiveFiltersOthers = 0;
  if (Number.isFinite(radioDataFilters.last_seen?.start) || Number.isFinite(radioDataFilters.last_seen?.end)) numActiveFiltersOthers++;
  if (radioDataFilters.countries?.length > 0) numActiveFiltersOthers++;
  if (radioDataFilters.ssid) numActiveFiltersOthers++;
  if (radioDataFilters.bssid) numActiveFiltersOthers++;
  if (radioDataFilters.manufacturer_countries?.length > 0) numActiveFiltersOthers++;
  if (Number.isFinite(radioDataFilters.accuracy) && radioDataFilters.accuracy !== radioUtils.DEFAULT_ACCURACY) numActiveFiltersOthers++;
  if (radioDataFilters.tower_id) numActiveFiltersOthers++;
  if (arfcnActive) numActiveFiltersOthers++;
  return (
    <>
      <Tabs className={classes.tabs} variant="fullWidth" value={dataType} onChange={onDataTypeChangedCallback}>
        <Tab
          classes={{
            wrapper: classes.tab
          }}
          label={
            <React.Fragment>
              <WifiIcon className={classes.tabIcon} />
              <Typography className={classes.tabTypography}>Wifi</Typography>
            </React.Fragment>
          }
          value="wifi"
        />
        <Tab
          classes={{
            wrapper: classes.tab
          }}
          label={
            <React.Fragment>
              <SignalCellularAltIcon className={classes.tabIcon} />
              <Typography className={classes.tabTypography}>Cell</Typography>
            </React.Fragment>
          }
          value="cell"
        />
      </Tabs>

      <ResultSettings label="transmitters" resultCount={0} countEstimated={false} loading={false} boxShadow={false}>
        <SortChipPopover
          getOptionLabel={getOptionLabelSort}
          sortDirection={radioDataFilters.sortOrder}
          sortDirectionDefault="desc"
          sort={radioDataFilters.sort}
          sortDefault="n_observations"
          className={classes.marginTopBottom}
          sortOpts={radioUtils.sortOptions}
          chipLabel="Sort"
          onSortChange={onSortChanged}
          checkDisabled={sortDisabled}
        />
        <ChipPopover
          active={radioDataFilters.network_names?.length > 0}
          className={classes.marginTopBottom}
          label={radioDataFilters.network_names?.length > 0 ? `Network Providers • ${radioDataFilters.network_names.length}` : 'Network Providers'}
          disabled={dataType === 'wifi'}
        >
          <VAC
            clearOnBlur={false}
            placeholder="MoviStar"
            value={radioDataFilters.network_names}
            options={getRadioMetadataQuery.data?.cell?.network_names ?? []}
            className={classes.vac}
            onChange={onNetworkNamesChanged}
          />
        </ChipPopover>
        <ChipPopoverCheck
          className={classes.marginTopBottom}
          active={radioDataFilters.network_types?.length > 0 && radioDataFilters.network_types?.length < 4}
          label={
            radioDataFilters.network_types?.length > 0 && radioDataFilters.network_types?.length < 4 ? `Network Types • ${radioDataFilters.network_types.length}` : 'Network Types'
          }
          disabled={dataType === 'wifi'}
          options={getRadioMetadataQuery.data?.cell?.network_types ?? []}
          value={radioDataFilters.network_types}
          clearDone={false}
          onChange={onNetworkTypesChanged}
        />

        <ChipPopover
          active={radioDataFilters.manufacturers && radioDataFilters.manufacturers.length > 0}
          className={classes.marginTopBottom}
          label={radioDataFilters.manufacturers && radioDataFilters.manufacturers.length > 0 ? `Manufacturers • ${radioDataFilters.manufacturers.length}` : 'Manufacturers'}
          disabled={dataType === 'cell'}
        >
          <VAC
            clearOnBlur={false}
            placeholder="Cisco Systems, Inc"
            value={radioDataFilters.manufacturers}
            options={getRadioMetadataQuery.data?.wifi?.manufacturers ?? []}
            className={classes.vac}
            onChange={onNetworkManufacturersChanged}
          />
        </ChipPopover>
        <ChipPopoverCheck
          className={classes.marginTopBottom}
          active={radioDataFilters.movement_type && radioDataFilters.movement_type !== 'all'}
          label={radioDataFilters.movement_type && radioDataFilters.movement_type !== 'all' ? 'Source Type • 1' : 'Source Type'}
          disabled={dataType === 'cell'}
          options={radioUtils.movementTypes}
          value={radioDataFilters.movement_type?.map((d) => radioUtils.movementTypesLookup[d])}
          clearDone={false}
          onChange={onNetworkMovementTypeChanged}
        />

        <ChipPopover
          active={numActiveFiltersOthers > 0}
          className={classes.marginTopBottom}
          clearDone={false}
          label={numActiveFiltersOthers > 0 ? `Other filters • ${numActiveFiltersOthers}` : 'Other filters'}
        >
          <Box className={classes.otherFiltersBox}>
            <ChipDateRangePicker chipClass={classes.marginTopBottom} label={'Date Range'} selectedDateRange={radioDataFilters.last_seen} onRangeChanged={onNetworkDatesChanged} />
            <ChipPopover
              active={radioDataFilters.countries?.length > 0}
              className={classes.marginTopBottom}
              popoverOrigins={POPOVER_ORIGINS_SIDE}
              clearDone={false}
              label={radioDataFilters.countries?.length > 0 ? `Country • ${radioDataFilters.countries?.length ?? 0}` : 'Country'}
            >
              <VAC
                disabled={!getRadioCountriesQuery.data || getRadioCountriesQuery.data.length === 0}
                getOptionLabel={(v) => v.country}
                getOptionSelected={(a, b) => a.hasc === b.hasc}
                value={radioDataFilters.countries}
                options={getRadioCountriesQuery.data ?? []}
                className={classes.vac}
                label="Country"
                onChange={onNetworkCountriesChanged}
              />
            </ChipPopover>
            <ChipPopover
              maxWidth={175}
              active={!!radioDataFilters.ssid}
              className={classes.marginTopBottom}
              popoverOrigins={POPOVER_ORIGINS_SIDE}
              disabled={dataType === 'cell'}
              clearDone={false}
              label={radioDataFilters.ssid ? `SSID • ${radioDataFilters.ssid}` : 'SSID'}
            >
              <Box>
                <Typography variant="caption" component="div" className={classes.popoverTypography}>
                  A unique name for a WLAN (wireless local area network) such as "Starbucks WiFi"
                </Typography>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={
                    <React.Fragment>
                      <Typography variant="caption">A unique name for a WLAN (wireless local area network) such as "Starbucks WiFi"</Typography>
                      <ul>
                        <li>{'+ signifies AND operation'}</li>
                        <li>{'| signifies OR operation'}</li>
                        <li>{'- negates a single token'}</li>
                        <li>{'" wraps a number of tokens to signify a phrase for searching'}</li>
                        <li>{'* at the end of a term signifies a prefix query'}</li>
                        <li>{'( and ) signify precedence'}</li>
                        <li>{'~N after a word signifies edit distance (fuzziness)'}</li>
                        <li>{'~N after a phrase signifies slop amount'}</li>
                      </ul>
                    </React.Fragment>
                  }
                >
                  <Typography variant="caption" component="div" className={classes.helperTextLink}>
                    Query Syntax
                  </Typography>
                </Tooltip>
                <div />
                <TextFieldDebounced label="SSID" className={classes.textfield} value={radioDataFilters.ssid} onChange={onNetworkSSIDChanged} />
              </Box>
            </ChipPopover>
            <ChipPopover
              maxWidth={175}
              active={!!radioDataFilters.bssid}
              className={classes.marginTopBottom}
              popoverOrigins={POPOVER_ORIGINS_SIDE}
              disabled={dataType === 'cell'}
              clearDone={false}
              label={radioDataFilters.bssid ? `BSSID • ${radioDataFilters.bssid}` : 'BSSID'}
            >
              <Box display="block">
                <Typography variant="caption" component="div" className={classes.popoverTypography}>
                  Prefix searching only. A 48-bit label for a wireless access point usually derived from its MAC address, or hardware identification number.
                </Typography>
                <TextFieldDebounced label="BSSID" className={classes.textfield} value={radioDataFilters.bssid} onChange={onNetworkBSSIDChanged} />
              </Box>
            </ChipPopover>
            <ChipPopover
              active={radioDataFilters.manufacturer_countries?.length > 0}
              className={classes.marginTopBottom}
              popoverOrigins={POPOVER_ORIGINS_SIDE}
              disabled={dataType === 'cell'}
              clearDone={false}
              label={
                radioDataFilters.manufacturer_countries?.length > 0 ? `Manufacturer Countries • ${radioDataFilters.manufacturer_countries?.length ?? 0}` : 'Manufacturer Countries'
              }
            >
              <VAC
                clearOnBlur={false}
                placeholder="Germany"
                value={radioDataFilters.manufacturer_countries}
                getOptionLabel={(d) => {
                  return d.name ?? getRadioMetadataQuery.data?.wifi?.manufacturer_countries?.find((d1) => d1.id === d.id)?.name ?? 'Unknown';
                }}
                getOptionSelected={(a, b) => a.id === b.id}
                options={getRadioMetadataQuery.data?.wifi?.manufacturer_countries ?? []}
                className={classes.vac}
                onChange={onNetworkManufacturersCountriesChanged}
              />
            </ChipPopover>
            <ChipPopover
              active={Number.isFinite(radioDataFilters.accuracy) && radioDataFilters.accuracy !== radioUtils.DEFAULT_ACCURACY}
              className={classes.marginTopBottom}
              popoverOrigins={POPOVER_ORIGINS_SIDE}
              disabled={dataType === 'cell'}
              clearDone={false}
              label={Number.isFinite(radioDataFilters.accuracy) && radioDataFilters.accuracy > 0 ? `Accuracy • ${radioDataFilters.accuracy}m` : 'Accuracy'}
            >
              <Box className={classes.sliderContainer}>
                <Typography component="div" className={classes.popoverTypography} gutterBottom={true}>
                  Positional Uncertainty (meters)
                </Typography>
                <Typography variant="caption" component="div" className={classes.popoverTypography} gutterBottom={true}>
                  ⚠️ This filter implies a "Source Type" of "stationary"
                </Typography>

                <IsolatedSlider
                  width={350}
                  className={classes.slider}
                  color="secondary"
                  value={radioDataFilters.accuracy}
                  marks={MARKS}
                  step={12.5}
                  min={0}
                  max={radioUtils.MAX_ACCURACY}
                  valueLabelDisplay="auto"
                  onChangeCommitted={onNetworkAccuracyChanged}
                  classes={{ valueLabel: classes.valueLabel }}
                />
                <Button className={classes.popoverBtn} onClick={onNetworkAccuracyReset} disabled={radioUtils.DEFAULT_ACCURACY === radioDataFilters.accuracy}>
                  Reset
                </Button>
              </Box>
            </ChipPopover>
            <ChipPopover
              maxWidth={175}
              active={!!radioDataFilters.tower_id}
              className={classes.marginTopBottom}
              popoverOrigins={POPOVER_ORIGINS_SIDE}
              disabled={dataType === 'wifi'}
              clearDone={false}
              label={radioDataFilters.tower_id ? `Tower Id • ${radioDataFilters.tower_id}` : 'Tower Id'}
            >
              <Typography variant="caption" component="div" className={classes.popoverTypography} gutterBottom={true}>
                Prefix searching only.
              </Typography>

              <TextFieldDebounced
                label="Tower Id"
                className={classes.textfield}
                value={radioDataFilters.tower_id}
                onChange={onNetworkTowerIdChanged}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={onNetworkTowerIdChanged} size="small" disabled={radioDataFilters.tower_id?.length === 0}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </ChipPopover>
            {arfcnMeta && (
              <ChipPopover
                popoverOrigins={POPOVER_ORIGINS_SIDE}
                disabled={dataType === 'wifi'}
                active={arfcnActive}
                className={classes.marginTopBottom}
                clearDone={false}
                label={arfcnActive ? `Downlink Freq • ${downlinkValueFormat(arfcnValue[0])} ~ ${downlinkValueFormat(arfcnValue[1])}` : 'Downlink Freq'}
              >
                <Box className={classes.sliderContainer}>
                  <Typography variant="caption" component="div" className={classes.popoverTypography} gutterBottom={true}>
                    ARFCN Downlink Freq (Mhz)
                  </Typography>
                  <IsolatedSlider
                    width={300}
                    className={classes.slider}
                    color="secondary"
                    value={arfcnValue}
                    classes={{ valueLabel: classes.valueLabel }}
                    min={arfcnMeta[0]}
                    max={arfcnMeta[1]}
                    valueLabelDisplay="auto"
                    valueLabelFormat={downlinkValueFormat}
                    onChangeCommitted={onNetworkARFCNChanged}
                  />
                  <Button className={classes.popoverBtn} onClick={onNetworkARFCNChanged} disabled={!arfcnActive}>
                    Reset
                  </Button>
                </Box>
              </ChipPopover>
            )}
          </Box>
        </ChipPopover>

        <Chip
          classes={{ outlined: classes.resetChip }}
          variant="outlined"
          label={'Reset All'}
          onClick={onFilterReset}
          onDelete={onFilterReset}
          deleteIcon={<ClearIcon color="inherit" />}
        />

        <Box className={classes.checkboxOption}>
          <FormControlLabel
            className={clsx('noselect')}
            control={<Checkbox size="small" checked={renderBothDataTypes} onChange={onRenderBothDataTypesChanged} />}
            label={<Typography variant="subtitle2">Render both wireless and cell data on map</Typography>}
          />
        </Box>
      </ResultSettings>
    </>
  );
};

FilterPanel.propTypes = {
  // data
  radioDataFilters: PropTypes.object.isRequired,
  dataType: PropTypes.string.isRequired,
  renderBothDataTypes: PropTypes.bool.isRequired,

  // callbacks
  updateRadioDataFilters: PropTypes.func.isRequired,
  resetRadioDataFilters: PropTypes.func.isRequired,
  onRenderBothDataTypesChanged: PropTypes.func.isRequired,
  onDataTypeChanged: PropTypes.func.isRequired
};
FilterPanel.defaultProps = {};
export default React.memo(FilterPanel);
