import React, { PureComponent } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// UI/UX
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ChipPopover from './ChipPopover';

// ICONS
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const styles = (theme) => ({
  sortOpts: {
    textTransform: 'capitalize',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: '275px'
  },
  directionBox: {
    marginLeft: 'auto'
  },
  disabled: {
    color: theme.palette.action.disabled
  }
});

class SortChipPopover extends PureComponent {
  constructor(props) {
    super(props);

    // REF
    this._ref = React.createRef();
  }
  render() {
    const { classes, sortOpts, sort, chipLabel, sortDefault, checkDisabled, className, id, sortDirection, sortDirectionDefault, onSortChange, getOptionLabel } = this.props;
    return (
      <ChipPopover ref={this._ref} active={sort !== sortDefault || sortDirection !== sortDirectionDefault} label={chipLabel} className={className} id={id}>
        {sortOpts.map((type) => {
          const disabled = checkDisabled ? checkDisabled(type) : false;
          return (
            <Box key={type} className={classes.sortOpts}>
              <Typography className={clsx(disabled && classes.disabled)}>{getOptionLabel ? getOptionLabel(type) : type}</Typography>
              <Box className={classes.directionBox}>
                <IconButton
                  color={sort === type && sortDirection === 'asc' ? 'secondary' : 'default'}
                  onClick={() => {
                    onSortChange({ sort: type, sortDirection: 'asc' });
                    this._ref.current?.close();
                  }}
                  disabled={disabled}
                >
                  <ArrowUpwardIcon fontSize="small" />
                </IconButton>
                <IconButton
                  color={sort === type && sortDirection === 'desc' ? 'secondary' : 'default'}
                  value={{ sort: type, sortDirection: 'desc' }}
                  onClick={() => {
                    onSortChange({ sort: type, sortDirection: 'desc' });
                    this._ref.current?.close();
                  }}
                  disabled={disabled}
                >
                  <ArrowDownwardIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          );
        })}
      </ChipPopover>
    );
  }
}

SortChipPopover.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  id: PropTypes.string,
  className: PropTypes.string,
  chipLabel: PropTypes.string.isRequired,
  onSortChange: PropTypes.func.isRequired,
  checkDisabled: PropTypes.func,

  sortDirection: PropTypes.string.isRequired,
  sortDirectionDefault: PropTypes.string.isRequired,
  sort: PropTypes.string,
  sortDefault: PropTypes.string.isRequired,
  sortOpts: PropTypes.array.isRequired,
  getOptionLabel: PropTypes.func
};

SortChipPopover.defaultProps = {};
export default withTheme(withStyles(styles)(SortChipPopover));
