import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(1, 1, 0, 1)
  }
}));

function TabPanel(props) {
  const theme = useTheme();
  const classes = useStyles();
  const { children, value, index, disablePadding, ...other } = props;

  return (
    <div className={clsx(!disablePadding && classes.padding)} role="tabpanel" hidden={value !== index} dir={theme.direction} {...other}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  disablePadding: PropTypes.bool
};

TabPanel.defaultProps = {
  disablePadding: false
};

export default TabPanel;
