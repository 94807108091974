import { withStyles, withTheme } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// UI/UX
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';

// ICONS
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import SettingsIcon from '@material-ui/icons/Settings';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import WarningIcon from '@material-ui/icons/Warning';

// OURS
import WorkIconButton from '../../../WorkIconButton';
import { PRODUCT_TEMPORAL } from '../../constants';
import FlexGrid from './FlexGrid';

const styles = (theme) => ({
  formcontrollabel: {
    display: 'flex',
    alignItems: 'center',
    '& > span:not(:first-child)': {
      display: 'flex',
      width: '100%',
      alignItems: 'center'
    },
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.background.default
    },
    borderRadius: '5px',
    padding: `${theme.spacing(2)}px 0px`,
    marginLeft: 0,
    marginRight: 0,
    paddingRight: 9
  },
  adornmentBtn: {
    width: '40px',
    height: '40px',
    marginLeft: 'auto',
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'center'
  },
  slowAnimation: {
    animationDuration: '10s'
  }
});
class RadioQuestion extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }
  alertIcon(n) {
    const { alertScale } = this.props;
    const level = alertScale(n);
    if (level === 'low') return <NotificationsNoneIcon />;
    else if (level === 'high') return <NotificationsActiveIcon />;
    else return <NotificationsIcon />;
  }
  render() {
    const { classes, question, onConfigureNewQuestion, activeView } = this.props;
    const misconfiguredOrdering =
      !question.ordering || // malformed, no ordering object
      typeof question.ordering !== 'object' || // malformed
      Object.entries(question.ordering).length === 0; // misconfigured ordering
    const disabled = !question.user_request_completed_on || misconfiguredOrdering;
    const base = (
      <Box>
        <Box>{question.question_proper}</Box>
        {!disabled && (
          <Grid spacing={1} container={true} justifyContent="flex-start" alignItems="center">
            <FlexGrid item={true}>{question.is_ordinal ? <ShowChartIcon /> : <MultilineChartIcon />}</FlexGrid>
            {activeView === PRODUCT_TEMPORAL && (
              <FlexGrid item={true} xs={true}>
                {question.alerts_daily_last_30 > 0 && <React.Fragment>{this.alertIcon(question.alerts_daily_last_30)}</React.Fragment>}
              </FlexGrid>
            )}
          </Grid>
        )}
      </Box>
    );
    let label;
    if (misconfiguredOrdering && question.user_request_completed_on) {
      label = (
        <React.Fragment>
          {base}
          <Tooltip title="This question is misconfigured!">
            <WarningIcon fontSize="small" />
          </Tooltip>
        </React.Fragment>
      );
    } else if (!question.user_requested_on) {
      label = (
        <React.Fragment>
          {base}
          <WorkIconButton
            TooltipProps={{ title: 'Request analysis', arrow: true }}
            size="small"
            color="default"
            className={classes.adornmentBtn}
            onClick={(done) => onConfigureNewQuestion(question, done)}
          >
            <SettingsIcon fontSize="small" />
          </WorkIconButton>
        </React.Fragment>
      );
    } else if (question.user_requested_on && !question.user_request_completed_on) {
      label = (
        <React.Fragment>
          {base}
          <Box className={classes.adornmentBtn}>
            <CircularProgress size="1.25rem" color="secondary" classes={{ indeterminate: classes.slowAnimation, circleIndeterminate: classes.slowAnimation }} />
          </Box>
        </React.Fragment>
      );
    } else {
      label = base;
    }
    return (
      <FormControlLabel
        disabled={disabled}
        className={clsx('noselect', classes.formcontrollabel)}
        key={question.question_name}
        value={question.question_name}
        control={<Radio />}
        label={label}
      />
    );
  }
}

RadioQuestion.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  question: PropTypes.object.isRequired,
  onConfigureNewQuestion: PropTypes.func.isRequired,
  activeView: PropTypes.number,
  alertScale: PropTypes.func.isRequired
};

RadioQuestion.defaultProps = {};
export default withTheme(withStyles(styles)(RadioQuestion));
