import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// MODULES
import { scaleThreshold, extent } from 'd3';

// MUI
import FormGroup from '@material-ui/core/FormGroup';
import RadioGroup from '@material-ui/core/RadioGroup';

// REDUX
import { selectQuestion as selectQuestionAction } from '../../sentimentSlice';

// OURS
import RadioQuestion from './RadioQuestion';
import CheckQuestion from './CheckQuestion';

function QuestionsWrapper(props) {
  const { onConfigureNewQuestion, freeTextFilter, availableQuestions } = props;
  const selectedQuestions = useSelector((state) => state.sentiment.selectedQuestions);
  const selectedQuestionType = useSelector((state) => state.sentiment.selectedQuestionType);
  const activeView = useSelector((state) => state.sentiment.activeView);
  const questionMerging = useSelector((state) => state.sentiment.questionMerging);

  const dispatch = useDispatch();

  const selectQuestionCallback = useCallback(
    (question, checked) => {
      dispatch(selectQuestionAction({ question, checked }));
    },
    [dispatch]
  );

  const availableQuestionsFiltered = useMemo(() => {
    if (freeTextFilter.length > 0) {
      return availableQuestions.filter((question) => {
        return selectedQuestions.includes(question) || question.question_proper.includes(freeTextFilter);
      });
    } else {
      return availableQuestions;
    }
  }, [freeTextFilter, availableQuestions, selectedQuestions]);

  if (questionMerging) {
    return (
      <FormGroup>
        {availableQuestionsFiltered.map((d) => (
          <CheckQuestion
            key={d.question_name}
            question={d}
            selectedQuestions={selectedQuestions}
            selectedQuestionType={selectedQuestionType}
            onConfigureNewQuestion={onConfigureNewQuestion}
            onQuestionSelectionsChanged={selectQuestionCallback}
          />
        ))}
      </FormGroup>
    );
  } else {
    const selectedQuestionNames = selectedQuestions.map((d) => d.question_name);
    const selQuestionName = selectedQuestionNames.length > 0 ? selectedQuestionNames[0] : null;
    const alerts_daily_last_30 = availableQuestionsFiltered.map((d) => Number(d.alerts_daily_last_30));
    const alertScale = scaleThreshold().domain(extent(alerts_daily_last_30)).range(['low', 'medium', 'high']);
    return (
      <RadioGroup
        name="questions"
        value={selQuestionName}
        onChange={(e) => {
          const d = availableQuestionsFiltered.filter((d) => d.question_name === e.target.value)[0];
          selectQuestionCallback(d, true);
        }}
      >
        {availableQuestionsFiltered.map((d) => (
          <RadioQuestion alertScale={alertScale} activeView={activeView} key={d.question_name} question={d} onConfigureNewQuestion={onConfigureNewQuestion} />
        ))}
      </RadioGroup>
    );
  }
}

QuestionsWrapper.propTypes = {
  availableQuestions: PropTypes.array.isRequired,
  onConfigureNewQuestion: PropTypes.func.isRequired,
  freeTextFilter: PropTypes.string
};

QuestionsWrapper.defaultProps = {
  freeTextFilter: ''
};

export default React.memo(QuestionsWrapper);
