import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// modules
import throttle from 'lodash.throttle';
import amplitude from 'amplitude-js';
import equal from 'deep-equal';

// MUI
import { withStyles, withTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

// Ours
import { genericPost, genericGet, API_HOST } from 'iris-api'; // eslint-disable-line import/no-unresolved
import { updateQueryStringValue } from 'iris-util'; // eslint-disable-line import/no-unresolved
import { pushSnackbar as pushSnackbarAction, setGlobal as setGlobalAction } from '../../actions';
import { TOP, LEFT_PANEL_WIDTH } from 'iris-config'; // eslint-disable-line import/no-unresolved
import { Features as FEATURES, has } from '@premisedata/lib-features';
import { irisApi } from '../../services';
import {
  // \n
  setFilterName as setFilterNameAction,
  setFilterCategories as setFilterCategoriesAction,
  loadState as loadStateAction,
  placesSelectors,
  _legacyRestoreState,
  dumpState,
  restoreState
} from './placesSlice';
import { restoreStateGlobal } from '../../store';

// Ours - Panels
import LeftPanel from '../LeftPanel';
import ResultPanel from './ResultPanel';
import DetailPanel from './DetailPanel';
import UserdataPanel from './UserdataPanel';

// Ours - Maps
import DeckMap from './DeckMap';
import LeafletMap from './LeafletMap';

// Ours - Modals
import DeleteModal from './modals/validation/DeleteModal';
import JoinModal from './modals/validation/JoinModal';
import ValidationModal from './modals/validation/ValidationModal';
import ExportModal from './modals/ExportModal';

const styles = () => ({
  circularprogress: {
    position: 'absolute',
    top: TOP,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

class View extends React.Component {
  constructor(props) {
    super(props);
    const qs = props.initialQueryString;

    this.state = {
      // ui / ux
      dataLoading: false,
      dataTimestamp: null,
      forceUpdate: 0,
      modal: null,

      placesHexData: null,
      placesHexDataPolygon: null,
      placesHexDataZoom: null,
      placesHexDataError: false,

      placesPointData: null,
      placesPointDataPolygon: null,
      placesPointDataZoom: null,
      placesPointDataError: false,

      hoverPlace: null,
      selectedPlace: null,

      submissions: [],
      hoverSubmission: null,
      selectedSubmission: null,

      joinPlace: null,
      joinSubmissions: [],

      nearbyWifi: null,
      nearbyWifiShown: false,
      hoverWifi: null,

      queryStringBounds: qs.bounds,
      queryStringPlaceId: qs.place_id
    };

    // BIND
    this.onValidationModalComplete = this.onValidationModalComplete.bind(this);
    this.onDeleteModalComplete = this.onDeleteModalComplete.bind(this);
    this.handleJoinModalComplete = this.handleJoinModalComplete.bind(this);
    this.selectPlace = this.selectPlace.bind(this);
    this.clearPlace = this.clearPlace.bind(this);
    this.onHoverWifi = this.onHoverWifi.bind(this);
    this.setNearbyWifiShown = this.setNearbyWifiShown.bind(this);
    this.toPlace = this.toPlace.bind(this);
    this.onSelectUserData = this.onSelectUserData.bind(this);
    this.onUserDataClosed = this.onUserDataClosed.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onHoverUserData = this.onHoverUserData.bind(this);
    this.onHoverSubmission = this.onHoverSubmission.bind(this);
    this.onSelectSubmission = this.onSelectSubmission.bind(this);

    // THROTTLE
    this.refreshDataThrottled = throttle(this.refreshData, 750, { trailing: true, leading: true });

    // Refs
    this._mapRef = React.createRef();

    // Axios XHR Tokens
    this._xhrHex = {}; // ---- Mutually
    this._xhrPoint = {}; // -- Exclusive
    this._lastMapRequest = 0;

    this._xhrPlace = {};
    this._xhrSubmissions = {};
    this._xhrUserData = {};

    this._xhrJoin = {};
    this._xhrDelete = {};
    this._xhrNearbyWifi = {};

    // Timers
    this._iMapAvailable = null;
  }
  componentWillUnmount() {
    this.unsubscribeGetUserdataMetadata?.();

    updateQueryStringValue('place_id');
    updateQueryStringValue('data_recency');

    // XHR
    this._xhrDelete.cancel && this._xhrDelete.cancel();
    this._xhrHex.cancel && this._xhrHex.cancel();
    this._xhrJoin.cancel && this._xhrJoin.cancel();
    this._xhrPlace.cancel && this._xhrPlace.cancel();
    this._xhrPoint.cancel && this._xhrPoint.cancel();
    this._xhrSubmissions.cancel && this._xhrSubmissions.cancel();
    this._xhrNearbyWifi.cancel && this._xhrNearbyWifi.cancel();
    this._xhrUserData.cancel && this._xhrUserData.cancel();

    // TIMERS
    if (this._iMapAvailable) {
      clearInterval(this._iMapAvailable);
      this._iMapAvailable = null;
    }

    // THROTTLE
    this.refreshDataThrottled.cancel();
  }
  componentDidMount() {
    const { serializedState, getUserdataMetadata, user, loadState, setGlobal } = this.props;
    if (has(user.active_features, FEATURES.DATA_UPLOAD) || has(user.active_features, FEATURES.DATA_UPLOAD_VIEWER)) {
      const { unsubscribe } = getUserdataMetadata();
      this.unsubscribeGetUserdataMetadata = unsubscribe;
    }

    if (serializedState) {
      let configPanelShouldBeOpen = true;
      let isLegacyState;
      if (serializedState?.state_global) {
        // Support LEGACY State Restore
        isLegacyState = true;

        configPanelShouldBeOpen = serializedState.state_global?.app_props?.placesActivePanel === 'results';
      } else if (serializedState?.productState) {
        // Active State Restore
        isLegacyState = false;

        configPanelShouldBeOpen = serializedState.globalState.configPanelOpen === true;
      } else {
        amplitude.getInstance().logEvent('view_restore_failed', {
          product: 'places',
          hash: serializedState.hash,
          scope_id: serializedState.scope_id
        });

        return setGlobal({
          serializedState: null,
          notificationQueue: [{ type: 'error', message: 'Failed to load saved view: malformed state' }]
        });
      }

      // if a place is selected, pre-fetch places & submissions for the restore:
      const place_id = serializedState.state_view?.selectedPlace?.place_id ?? serializedState.productState?.selectedPlace;

      const newStateGlobal = restoreStateGlobal(serializedState);
      const newState = isLegacyState ? _legacyRestoreState(serializedState) : restoreState(serializedState);

      if (place_id) {
        this.getPlaceData(place_id, (e, selectedPlace, submissions, nearbyWifi) => {
          amplitude.getInstance().logEvent('view_restored', {
            product: 'places',
            hash: serializedState.hash,
            scope_id: serializedState.scope_id,
            selection: place_id,
            selection_exists: !e
          });

          let notificationQueue, partialLoad;
          if (!e) {
            partialLoad = false;
            notificationQueue = [{ type: 'success', message: 'Saved view restored successfully!' }];
          } else {
            partialLoad = true;
            notificationQueue = [{ type: 'info', message: 'Saved view restored partially restored, selected place no longer exists.' }];
          }
          // fire new state to placesSlice
          loadState(newState);
          // fire new state to old redux,
          setGlobal({
            notificationQueue,
            serializedState: null, // load complete, clear it.
            configPanelOpen: configPanelShouldBeOpen,
            ...newStateGlobal
          });
          if (partialLoad) {
            this.setState((s) => ({
              forceUpdate: s.forceUpdate + 1
            }));
          } else {
            // fire new state to this component
            this.setState({
              selectedPlace,
              submissions,
              nearbyWifi
            });
          }
        });
      } else {
        amplitude.getInstance().logEvent('view_restored', {
          product: 'places',
          hash: serializedState.hash,
          scope_id: serializedState.scope_id
        });

        // fire new state to placesSlice
        loadState(newState);
        // fire new state to old redux,
        setGlobal({
          notificationQueue: [{ type: 'success', message: 'Saved view restored successfully!' }],
          serializedState: null, // load complete, clear it.
          configPanelOpen: configPanelShouldBeOpen,
          ...newStateGlobal
        });
      }
    } else {
      this.parseQueryParams();
    }
  }
  parseQueryParams() {
    const { queryStringBounds, queryStringPlaceId } = this.state;
    if (queryStringBounds) {
      const parsed = queryStringBounds.split(',').map((d) => +d);
      const bounds = { maxy: parsed[1], maxx: parsed[0], miny: parsed[3], minx: parsed[2] };
      this.fitBounds(bounds);
    } else if (queryStringPlaceId) {
      this.getPlaceData(queryStringPlaceId, (e, place, submissions, nearbyWifi) => {
        if (e) {
          return this.props.pushSnackbar({ type: 'error', message: "Failed to find the place you're looking for" });
        }
        this.toPlace(place, submissions, false, nearbyWifi, true /* force update */);
      });
    }
  }
  buildHashStateObject(store) {
    const outState = dumpState(store);
    // add some vars from this class,
    outState.selectedPlace = this.state.selectedPlace?.place_id;

    // pass up:
    return outState;
  }
  refreshData(mapMoveOnly) {
    const { placesHexDataZoom: mapZoomLoaded } = this.state;
    const { pushSnackbar, dataQueryObject } = this.props;
    if (!dataQueryObject) return;

    // zoom level 4 is the whole world, never re-fetch it:
    if (mapMoveOnly && dataQueryObject.mapZoom === 4 && dataQueryObject.mapZoom === mapZoomLoaded) return;

    const now = new Date().getTime();
    this._lastMapRequest = now;
    this.setState({ dataLoading: true });

    if (dataQueryObject.mapZoom < 11) {
      this._xhrHex.cancel && this._xhrHex.cancel();
      this._xhrPoint.cancel && this._xhrPoint.cancel();
      genericPost(
        `${API_HOST}/places/v0/places/h3/within/${dataQueryObject.mapZoom}`,
        dataQueryObject.body,
        this._xhrHex,
        (e, r) => {
          if (e) {
            pushSnackbar({ type: 'error', message: 'Failed to retrieve hex data' });
            return this.setState({
              placesHexData: null,
              placesHexDataPolygon: null,
              placesHexDataZoom: null,

              placesPointData: null,
              placesPointDataPolygon: null,
              placesPointDataZoom: null,

              dataTimestamp: null,
              dataLoading: false
            });
          }

          if (now !== this._lastMapRequest) return; // STALE

          this.setState({
            placesHexData: window.irisProto.Hexagons.decode(new Uint8Array(r)).hexagons,
            placesHexDataPolygon: dataQueryObject.body.polygon,
            placesHexDataZoom: dataQueryObject.mapZoom,
            dataTimestamp: new Date().getTime(),
            dataLoading: false,

            placesPointData: null,
            placesPointDataPolygon: null,
            placesPointDataZoom: null
          });
        },
        false /* do not disable authentication */,
        true /* enable arraybuffer response type */
      );
    } else if (dataQueryObject.mapZoom >= 11) {
      this._xhrHex.cancel && this._xhrHex.cancel();
      this._xhrPoint.cancel && this._xhrPoint.cancel();
      genericPost(
        `${API_HOST}/places/v0/places/point/within/${dataQueryObject.mapZoom}`,
        dataQueryObject.body,
        this._xhrPoint,
        (e, r, headers) => {
          if (e) {
            pushSnackbar({ type: 'error', message: 'Failed to retrieve point data' });
            return this.setState({
              placesPointData: null,
              placesPointDataPolygon: null,
              placesPointDataZoom: null,

              placesHexData: null,
              placesHexDataPolygon: null,
              placesHexDataZoom: null,

              dataTimestamp: null,
              dataLoading: false
            });
          }

          if (now !== this._lastMapRequest) return; // STALE
          const { data: userdataMetadata } = this.props.userdataMetadataQuery;

          if (headers['x-version'] && userdataMetadata) {
            if (userdataMetadata.version !== headers['x-version']) {
              console.warn('user uploads metadata out of sync with data, updating...');
              return this.props.invalidateTags(['UserdataMetadata']);
            }
          }

          this.setState({
            placesPointData: window.irisProto.Points.decode(new Uint8Array(r)).points,
            placesPointDataPolygon: dataQueryObject.body.polygon,
            placesPointDataZoom: dataQueryObject.mapZoom,
            dataTimestamp: new Date().getTime(),
            dataLoading: false,

            placesHexData: null,
            placesHexDataPolygon: null,
            placesHexDataZoom: null
          });
        },
        false /* do not disable authentication */,
        true /* enable arraybuffer response type */
      );
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { userdataMetadataQuery, dataQueryObject } = this.props;
    const { userdataMetadataQuery: prevUserdataMetadataQuery, dataQueryObject: prevDataQueryObject } = prevProps;

    if (!dataQueryObject) return;

    const { forceUpdate } = this.state;
    const { forceUpdate: prevForceUpdate } = prevState;

    // Map Movement
    const mapMoved = !equal(dataQueryObject.body.polygon, prevDataQueryObject?.body?.polygon);

    // Data Filters, shape, etc.
    const forcedUpdate = forceUpdate !== prevForceUpdate;
    const filtersChanged = !equal(dataQueryObject.body.filters, prevDataQueryObject?.body?.filters);
    const osmChanged = dataQueryObject.body.enable_osm !== prevDataQueryObject?.body?.enable_osm;
    const userdataMetadataChanged = userdataMetadataQuery.data !== prevUserdataMetadataQuery.data;

    const dataIsStale = forcedUpdate || filtersChanged || osmChanged || userdataMetadataChanged;

    if (dataQueryObject.body.polygon && (dataIsStale || mapMoved)) {
      this.refreshDataThrottled(mapMoved && !dataIsStale);
    }
  }
  toPlace(selectedPlace, submissions, updateTopic, nearbyWifi, forceUpdate) {
    // update query bar:
    updateQueryStringValue('place_id', selectedPlace.place_id);

    // update this components state:
    this.setState((s) => ({
      selectedPlace,
      nearbyWifi,
      submissions: submissions.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)),
      hoverSubmission: null,
      selectedSubmission: null,
      forceUpdate: forceUpdate ? s.forceUpdate + 1 : s.forceUpdate
    }));
    // update map state:
    this.setView(selectedPlace.x_lon, selectedPlace.y_lat, 17.0, true);
  }
  clearPlace() {
    updateQueryStringValue('place_id');
    this.setState({
      selectedPlace: null,
      submissions: [],
      hoverSubmission: null,
      selectedSubmission: null
    });
  }
  onValidationModalComplete(r) {
    const deleted = r.deleted ?? [];
    const { selectedPlace, forceUpdate } = this.state;
    if (deleted.includes(selectedPlace.place_id)) {
      updateQueryStringValue('place_id');
      this.setState((s) => ({
        modal: null,

        hoverPlace: null,
        selectedPlace: null,
        //
        submissions: [],
        hoverSubmission: null,
        selectedSubmission: null,
        //

        forceUpdate: s.forceUpdate + 1
      }));
    } /* active place still exists */ else {
      this.getPlaceData(selectedPlace.place_id, (e, selectedPlace, submissions, nearbyWifi) => {
        this.setState({
          modal: null,

          hoverPlace: null,
          selectedPlace,
          nearbyWifi,
          submissions,
          hoverSubmission: null,
          selectedSubmission: null,

          forceUpdate: forceUpdate + 1
        });
      });
    }
  }
  // () from result panel and map
  selectPlace(place_id, withShiftKey = false) {
    const clearedState = {
      // submissions:
      submissions: [],
      hoverSubmission: null,
      selectedSubmission: null,

      // places:
      joinPlace: null,
      joinSubmissions: [],
      hoverPlace: null,
      selectedPlace: null
    };

    if (!place_id) {
      updateQueryStringValue('place_id');
      return this.setState(clearedState);
    }

    const { selectedPlace } = this.state;
    const { user } = this.props;
    if (selectedPlace && place_id === selectedPlace.place_id) {
      return; // do nothing
    }

    if (selectedPlace && withShiftKey && has(user.active_features, FEATURES.PLACES_VALIDATION)) {
      this.getPlaceData(place_id, (e, place, submissions) => {
        this.setState({
          joinPlace: place,
          joinSubmissions: submissions,
          modal: 'join'
        });
      });
    } else {
      this.getPlaceData(place_id, (e, selectedPlace, submissions, nearbyWifi) => {
        if (e) return console.error(e);

        amplitude.getInstance().logEvent('selection', {
          product: 'places',
          selection: selectedPlace.place_id
        });

        updateQueryStringValue('place_id', place_id);

        this.setState({
          selectedPlace,
          nearbyWifi,
          submissions: submissions.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)),
          hoverSubmission: null,
          selectedSubmission: null
        });
      });
    }
  }
  onUserDataClosed() {
    this.setState({
      selectedUserData: null
    });
  }
  onSelectUserData(id) {
    if (!id) return this.setState({ selectedUserData: null });
    this._xhrUserData.cancel && this._xhrUserData.cancel();
    genericGet(`${API_HOST}/iris/v0/userdata/${id}`, this._xhrUserData, (e, selectedUserData) => {
      if (e) {
        return this.props.pushSnackbar({ type: 'error', message: 'failed to fetch details for place of interest.' });
      }
      this.setState({ selectedUserData });
    });
  }
  getPlaceData(place_id, callback) {
    this._xhrPlace.cancel && this._xhrPlace.cancel();
    genericGet(`${API_HOST}/places/v0/places/${place_id}`, this._xhrPlace, (e, selectedPlace) => {
      // bubble error up:
      if (e) return callback(e);

      this._xhrSubmissions.cancel && this._xhrSubmissions.cancel();
      this._xhrNearbyWifi.cancel && this._xhrNearbyWifi.cancel();

      // build a list of promises for submission + wifi data:
      const promises = [genericGet(`${API_HOST}/places/v0/submissions/place/${place_id}`, this._xhrSubmissions)];

      if (has(this.props.user.active_features, FEATURES.RADIO)) {
        promises.push(
          genericGet(`${API_HOST}/radio/v0/radio/near/${selectedPlace.x_lon}/${selectedPlace.y_lat}/100`, this._xhrNearbyWifi)
            // ignore failures here:
            .catch(() => ({
              data: []
            }))
        );
      }
      Promise.all(promises)
        .then(([submissions, nearbyWifi]) => {
          callback(null, selectedPlace, submissions.data, nearbyWifi?.data ?? null);
        })
        .catch((e) => {
          callback(e);
        });
    });
  }
  onDeleteModalComplete() {
    const { submissions, selectedPlace } = this.state;

    if (!submissions || submissions.length === 0) return this.props.pushSnackbar({ type: 'error', message: 'Failed to delete place, bad state.' });

    const url = `${API_HOST}/places/v0/place-harmonizer/validation`;
    this._xhrDelete.cancel && this._xhrDelete.cancel();

    genericPost(
      url,
      {
        unknown: [],
        destroyed: submissions.map((d) => d.sub_id),
        associations: []
      },
      this._xhrDelete,
      (e) => {
        if (e) this.props.pushSnackbar({ type: 'error', message: 'Failed to delete place, please try again later.' });
        else this.props.pushSnackbar({ type: 'success', message: `"${selectedPlace.place_name}" successfully deleted.` });
        updateQueryStringValue('place_id');
        this.setState((state) => ({
          modal: null,
          selectedPlace: null,
          hoverPlace: null,
          submissions: [],
          hoverSubmission: null,
          selectedSubmission: null,
          forceUpdate: state.forceUpdate + 1
        }));
      }
    );
  }
  handleJoinModalComplete() {
    const { selectedPlace, submissions, joinPlace, joinSubmissions, forceUpdate } = this.state;
    const { pushSnackbar } = this.props;

    const invalidState = { type: 'error', message: 'Invalid state' };
    if (!selectedPlace || !joinPlace) {
      pushSnackbar(invalidState);
      return console.error('invalid state: joinPlace & selectedPlace required');
    }
    if (!submissions || submissions.length === 0) {
      pushSnackbar(invalidState);
      return console.error('invalid state: submissions required');
    }
    if (!joinSubmissions || joinSubmissions.length === 0) {
      pushSnackbar(invalidState);
      return console.error('invalid state: joinSubmissions required');
    }

    const url = `${API_HOST}/places/v0/place-harmonizer/validation`;
    const associations = [
      {
        place_id: selectedPlace.place_id,
        // combine submissions from both places:
        submission_ids: submissions.map((d) => d.sub_id).concat(joinSubmissions.map((d) => d.sub_id))
      },
      {
        place_id: joinPlace.place_id,
        // empty the secondary place:
        submission_ids: []
      }
    ];

    this._xhrJoin.cancel && this._xhrJoin.cancel();
    genericPost(url, { unknown: [], destroyed: [], associations }, this._xhrJoin, (e) => {
      if (e) {
        pushSnackbar({ type: 'error', message: 'Joining these two places failed, please try again later.' });
        return console.error(e);
      }

      this.setState({
        modal: null,

        hoverPlace: null,
        selectedPlace: null,
        //
        submissions: [],
        hoverSubmission: null,
        selectedSubmission: null,
        //
        joinPlace: null,
        joinSubmissions: [],

        forceUpdate: forceUpdate + 1
      });
    });
  }
  onHoverWifi(id) {
    this.setState({
      hoverWifi: id
    });
  }
  setNearbyWifiShown(shown) {
    this.setState({
      nearbyWifiShown: shown
    });
  }
  onHover(place_id) {
    this.setState({ hoverPlace: place_id });
  }
  onHoverUserData(place_id) {
    this.setState({ hoverPlace: place_id });
  }
  onHoverSubmission(hoverSubmission) {
    this.setState({ hoverSubmission });
  }
  onSelectSubmission(selectedSubmission) {
    this.setState({
      selectedSubmission
    });
  }
  render() {
    const { classes, userdataMetadataQuery, configPanelOpen, hasGPU, serializedState } = this.props;
    const {
      dataLoading,
      hoverPlace,
      joinPlace,
      joinSubmissions,
      modal,
      selectedPlace,
      submissions,
      placesHexData,
      placesPointData,
      dataTimestamp,
      nearbyWifi,
      nearbyWifiShown,
      hoverWifi,
      hoverSubmission,
      selectedUserData,
      selectedSubmission
    } = this.state;

    let mapLeft = 0;
    let mapRight = 0;
    if (selectedPlace) mapLeft += LEFT_PANEL_WIDTH;
    if (configPanelOpen) mapLeft += LEFT_PANEL_WIDTH;

    let detailPanelLeft = 0;
    if (configPanelOpen) detailPanelLeft += LEFT_PANEL_WIDTH;
    if (selectedUserData) {
      mapRight += LEFT_PANEL_WIDTH;
    }

    const commonMapProps = {
      left: mapLeft,
      right: mapRight,
      dataLoading,
      onSelect: this.selectPlace,
      onSelectUserData: this.onSelectUserData,
      ref: this._mapRef,
      selectedPlace,
      selectedUserData,
      selectedPlaceId: selectedPlace ? selectedPlace.place_id : null,
      hoverPlaceId: hoverPlace,
      submissions,
      placesPointData,
      placesHexData,
      dataKey: dataTimestamp,
      nearbyWifi: nearbyWifiShown ? nearbyWifi : undefined,
      hoverWifi: hoverWifi ? [hoverWifi] : null,
      onSelectSubmission: this.onSelectSubmission,
      hoverSubmission,
      selectedSubmission
    };

    const { data: userdataMetadata } = userdataMetadataQuery;

    // don't mount anything until we have restored state:
    if (serializedState)
      return (
        <Box className={classes.circularprogress}>
          <CircularProgress color="secondary" />
        </Box>
      );

    return (
      <React.Fragment>
        <JoinModal
          userdataLayersById={userdataMetadata?.layersById}
          submissions={submissions}
          joinSubmissions={joinSubmissions}
          selectedPlace={selectedPlace}
          joinPlace={joinPlace}
          onComplete={this.handleJoinModalComplete}
          onCancel={() => this.setState({ modal: null, joinPlace: null, joinSubmissions: null })}
        />

        <LeftPanel id={'places_result-panel'} open={configPanelOpen} noSideMargin={true}>
          <ResultPanel
            selectedPlace={selectedPlace}
            onSelect={this.selectPlace}
            onSelectUserData={this.onSelectUserData}
            dataKey={dataTimestamp}
            onHover={this.onHover}
            onHoverUserData={this.onHoverUserData}
            toPlace={this.toPlace}
          />
        </LeftPanel>
        <LeftPanel id={'places_detail-panel'} open={!!selectedPlace} left={detailPanelLeft}>
          <DetailPanel
            nearbyWifiShown={nearbyWifiShown}
            setNearbyWifiShown={this.setNearbyWifiShown}
            place={selectedPlace}
            onValidate={() => this.setState({ modal: 'validation' })}
            onAutoValidate={this.onValidationModalComplete}
            onClose={this.clearPlace}
            doDelete={() => this.setState({ modal: 'delete_place' })}
            submissions={submissions}
            onZoomTo={(lon, lat) => this.setView(lon, lat, 17.0)}
            onPanTo={(lon, lat) => this.setView(lon, lat, false)}
            nearbyWifi={nearbyWifi}
            onHoverWifi={this.onHoverWifi}
            onHoverSubmission={this.onHoverSubmission}
            onSelectSubmission={this.onSelectSubmission}
            selectedSubmission={selectedSubmission}
            hoverSubmission={hoverSubmission}
          />
        </LeftPanel>
        {selectedUserData && (
          <UserdataPanel
            data={selectedUserData}
            onClose={this.onUserDataClosed}
            onZoomTo={(lon, lat) => this.setView(lon, lat, 17.0)}
            onPanTo={(lon, lat) => this.setView(lon, lat, false)}
          />
        )}
        {hasGPU ? <DeckMap {...commonMapProps} /> : <LeafletMap {...commonMapProps} />}
        {modal === 'validation' ? (
          <ValidationModal
            top={TOP}
            submissions={submissions}
            onCancel={() => this.setState({ modal: null })}
            selectedPlace={selectedPlace}
            onComplete={this.onValidationModalComplete}
            pushSnackbar={this.props.pushSnackbar}
          />
        ) : null}
        {modal === 'delete_place' ? (
          <DeleteModal
            userdataLayersById={userdataMetadata?.layersById}
            top={TOP}
            submissions={submissions}
            onCancel={() => this.setState({ modal: null })}
            selectedPlace={selectedPlace}
            onComplete={this.onDeleteModalComplete}
          />
        ) : null}
        {modal === 'export' ? <ExportModal onCancel={() => this.setState({ modal: null })} onComplete={() => this.setState({ modal: null })} /> : null}
      </React.Fragment>
    );
  }
  fitBounds(bounds, name) {
    if (name) this.props.setFilterName(name);

    if (this._iMapAvailable) {
      clearInterval(this._iMapAvailable);
      this._iMapAvailable = null;
    }
    this._iMapAvailable = setInterval(() => {
      if (this._mapRef.current) {
        clearInterval(this._iMapAvailable);
        this._iMapAvailable = null;
        this._mapRef.current.fitBounds(bounds);
      } else {
        console.debug('(places/View) map unavailable, will retry');
      }
    }, 100);
  }

  setView(x_lon, y_lat, z, noTransition) {
    if (this._iMapAvailable) {
      clearInterval(this._iMapAvailable);
      this._iMapAvailable = null;
    }
    this._iMapAvailable = setInterval(() => {
      if (this._mapRef.current) {
        clearInterval(this._iMapAvailable);
        this._iMapAvailable = null;
        this._mapRef.current.setView(x_lon, y_lat, z, noTransition);
      } else {
        console.debug('(places/View) map unavailable, will retry');
      }
    }, 100);
  }

  // used by App.js to trigger export modal:
  doExportModal() {
    if (!this.state.modal)
      this.setState({
        modal: 'export'
      });
  }
}

const mapStateToProps = (state) => ({
  // OLD REDUX:
  hasGPU: state.app.hasGPU,
  initialQueryString: state.app.initialQueryString,
  configPanelOpen: state.app.configPanelOpen,
  user: state.app.user,
  serializedState: state.app.serializedState,

  // NEW REDUX:
  filterStates: placesSelectors.filterStates(state),
  dataQueryObject: placesSelectors.dataQueryObject(state),

  // QUERY:
  userdataMetadataQuery: irisApi.endpoints.getUserdataMetadata.select()(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // QUERY:
      getUserdataMetadata: irisApi.endpoints.getUserdataMetadata.initiate,
      invalidateTags: irisApi.util.invalidateTags,
      getCategories: irisApi.endpoints.getCategories.initiate,

      // OLD REDUX:
      pushSnackbar: pushSnackbarAction,
      setGlobal: setGlobalAction,

      // NEW REDUX:
      setFilterName: setFilterNameAction,
      setFilterCategories: setFilterCategoriesAction,
      loadState: loadStateAction
    },
    dispatch
  );

View.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  // OLD REDUX: OBJECTS
  hasGPU: PropTypes.bool,
  initialQueryString: PropTypes.object,
  configPanelOpen: PropTypes.bool,
  user: PropTypes.object,
  serializedState: PropTypes.object,

  // OLD REDUX: ACTIONS
  pushSnackbar: PropTypes.func.isRequired,
  setGlobal: PropTypes.func.isRequired,

  // NEW REDUX: OBJECTS
  dataQueryObject: PropTypes.object,

  // NEW REDUX: ACTIONS
  setFilterName: PropTypes.func.isRequired,
  setFilterCategories: PropTypes.func.isRequired,
  loadState: PropTypes.func.isRequired,

  // QUERY:
  userdataMetadataQuery: PropTypes.object.isRequired,
  getUserdataMetadata: PropTypes.func.isRequired,
  invalidateTags: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired
};

View.defaultProps = {};

const withRedux = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
export default withRedux(withTheme(withStyles(styles)(View)));
