import { createSlice } from '@reduxjs/toolkit';
import { Features as FEATURES, has } from '@premisedata/lib-features';
import moment from 'moment';

const defaultFilters = {
  filterOnlyPlacesWithName: '',
  filterOnlyPlacesWithSimilarName: '',
  filterOnlyPlacesUnvalidated: false,
  filterOnlyPlacesWithCategory: [], // e.g. 'clinic',
  filterOnlyPlacesWithOCRLanguage: [],
  filterOnlyPlacesWithOCRContent: '',
  filterByOpenStatusOptions: ['open_or_closed', 'open', 'closed'],
  filterByOpenStatus: 'open_or_closed',
  filterByQuestionAndAnswer: [],
  filterByQuestionAndAnswerOR: false,
  filterByDataRecencyOptions: [0, 7, 30, 90, -1],
  filterByDataRecency: 0,
  filterByDateRange: null,
  filterByGeographies: [],
  sort: 'first seen',
  sortDirection: 'desc'
};

const initialState = {
  ...defaultFilters,
  showOSM: window.localStorage.osm === 'true',
  resultPanelListMode: window.localStorage.prpm ?? 'list', // or 'photo'

  sort: window.localStorage.places_sort ?? defaultFilters.sort,
  sortDirection: window.localStorage.places_sort_dir ?? defaultFilters.sortDirection,
  sortOptions: ['facility name', '# submissions', 'last seen', 'first seen', 'distance from center'],
  sortDefault: 'first seen',
  sortDirectionOptions: ['asc', 'desc'],
  sortDirectionDefault: 'desc'
};

const placesSlice = createSlice({
  name: 'places',
  initialState,
  reducers: {
    loadState(state, { payload }) {
      for (const [k, v] of Object.entries(payload)) {
        state[k] = v;
      }
    },
    resetFilters(state) {
      const keys = Object.keys(defaultFilters);
      for (const key of keys) {
        state[key] = defaultFilters[key];
      }
    },
    setResultPanelListMode(state, { payload }) {
      if (!['photo', 'list'].includes(payload)) {
        return console.warn(`(placesSlice) 'setResultPanelListMode' dispatched with unexpected data: "${payload}"`);
      }
      state.resultPanelListMode = payload;
      window.localStorage.prpm = payload;
    },
    setNameSimilar(state, { payload }) {
      if (state.filterOnlyPlacesWithSimilarName === payload) {
        state.filterOnlyPlacesWithSimilarName = '';
      } else {
        state.filterOnlyPlacesWithSimilarName = payload;
      }
    },
    updateDateRange(state, { payload }) {
      if (payload.start && isNaN(payload.start)) return console.warn(`(placesSlice) 'updateDateRange' dispatched with unexpected data type: "${JSON.stringify(payload)}"`);
      if (payload.startStr && typeof payload.startStr !== 'string')
        return console.warn(`(placesSlice) 'updateDateRange' dispatched with unexpected data type: "${JSON.stringify(payload)}"`);
      if (payload.end && isNaN(payload.end)) return console.warn(`(placesSlice) 'updateDateRange' dispatched with unexpected data type: "${JSON.stringify(payload)}"`);
      if (payload.endStr && typeof payload.endStr !== 'string')
        return console.warn(`(placesSlice) 'updateDateRange' dispatched with unexpected data type: "${JSON.stringify(payload)}"`);

      state.filterByDateRange = {
        ...state.filterByDateRange,
        ...payload
      };
    },
    setOperatingStatus(state, { payload }) {
      state.filterByOpenStatus = payload;
    },
    setShowOSM(state, { payload }) {
      state.showOSM = payload;
      window.localStorage.osm = payload ? 'true' : 'false';
    },
    setOnlyUnvalidated(state, { payload }) {
      state.filterOnlyPlacesUnvalidated = payload;
    },
    setDataRecency(state, { payload }) {
      if (payload !== -1) state.filterByDateRange = null;
      state.filterByDataRecency = payload;
    },
    setFilterName(state, { payload }) {
      state.filterOnlyPlacesWithName = payload;
    },
    setFilterQA(state, { payload }) {
      state.filterByQuestionAndAnswer = payload;
    },
    toggleFilterQAOR(state) {
      state.filterByQuestionAndAnswerOR = !state.filterByQuestionAndAnswerOR;
    },
    setFilterCategories(state, { payload }) {
      state.filterOnlyPlacesWithCategory = payload;
    },
    setFilterOCRLanguages(state, { payload }) {
      state.filterOnlyPlacesWithOCRLanguage = payload;
    },
    setFilterOCRContent(state, { payload }) {
      state.filterOnlyPlacesWithOCRContent = payload;
    },
    setFilterGeographies(state, { payload }) {
      state.filterByGeographies = payload;
    },
    setSort(state, { payload }) {
      state.sort = payload.sort;
      state.sortDirection = payload.sortDirection;
      window.localStorage.places_sort_dir = payload.sortDirection;
      window.localStorage.places_sort = payload.sort;
    }
  }
});

export const dumpState = (store) => {
  // grabs global redux data we need for serialization
  const reduxState = store.getState();
  const outKeys = [
    'filterOnlyPlacesWithName',
    'filterOnlyPlacesWithSimilarName',
    'filterOnlyPlacesUnvalidated',
    'filterOnlyPlacesWithCategory',
    'filterOnlyPlacesWithOCRLanguage',
    'filterOnlyPlacesWithOCRContent',
    'filterByOpenStatus',
    'filterByGeographies',
    'filterByDataRecency',
    'showOSM',
    'resultPanelListMode',
    'sort',
    'sortDirection',
    'filterByDateRange',
    'filterByQuestionAndAnswer'
  ];

  const out = {};
  for (const k of outKeys) out[k] = reduxState.places[k];
  return out;
};
export const restoreState = (serializedState) => {
  const state = {
    ...serializedState.productState
  };
  return state;
};
export const _legacyRestoreState = (serializedState) => {
  const state = {};
  const filters = serializedState.state_global?.app_props?.placeDataFilters ?? {};
  // filterOnlyPlacesWithName: '',
  state.filterOnlyPlacesWithName = filters.name ?? initialState.filterOnlyPlacesWithName;
  // filterOnlyPlacesUnvalidated: false,
  state.filterOnlyPlacesUnvalidated = filters.not_validated ?? initialState.filterOnlyPlacesUnvalidated;
  // filterOnlyPlacesWithCategory: [], // e.g. 'clinic',
  state.filterOnlyPlacesWithCategory = filters.categories?.map((d) => ({ topic: d })) ?? initialState.filterOnlyPlacesWithCategory;
  // filterByOpenStatus: 'open_or_closed',
  if (filters.is_closed === true) {
    state.filterByOpenStatus = 'closed';
  } else if (filters.is_closed === false) {
    state.filterByOpenStatus = 'open';
  }
  // filterByDataRecency: 0,
  if (Number.isFinite(filters.data_recency)) {
    state.filterByDataRecency = filters.data_recency;
  }
  // filterByDateRange: null,
  if (filters.date_range?.start && filters.date_range?.end) {
    state.filterByDataRecency = 'customize';
    const s = moment(filters.date_range.start);
    const e = moment(filters.date_range.end);
    state.filterByDateRange = {
      start: s.valueOf(),
      end: e.valueOf(),
      startStr: s.format('YYYY-MM-DD'),
      endStr: e.format('YYYY-MM-DD')
    };
  }
  // sort: window.localStorage.places_sort ?? 'first seen',
  // sortDirection: window.localStorage.places_sort_dir ?? 'desc',
  if (serializedState.state_view?.featureSort) {
    const parts = serializedState.state_view.featureSort.split('-');
    if (parts[0] === 'confidence') {
      state.sort = '# submissions';
    } else if (parts[0] === 'distance') {
      state.sort = 'distance from center';
    } else if (parts[0] === 'first') {
      state.sort = 'first seen';
    } else if (parts[0] === 'last') {
      state.sort = 'last seen';
    }
    const order = parts.pop();
    if (order === 'desc') {
      state.sortDirection = order;
    } else if (order === 'asc') {
      state.sortDirection = order;
    }
  }

  // { was never used } filterByQuestionAndAnswer: [],
  // { was never used } filterOnlyPlacesWithSimilarName: '',
  // { was never used } showOSM: window.localStorage.osm === 'true',
  // { was never used } resultPanelListMode: window.localStorage.prpm ?? 'list', // or 'photo'
  return state;
};

export const placesSelectors = {
  //
  // putting this here to have a central implementation
  // this logic will move to services when QUERY is integrated
  //

  // [x] categories -or- topics
  // [x] data_recency
  // [x] date_range
  // [x] has_enrichments
  // [x] name
  // [x] is_closed
  // [x] not_validated
  // [x] name_similar
  // [x] qa
  // [x] sort
  // [x] limit

  // [ ] hascs { added by <ExportModal /> }
  // [ ] wkt { added by <ExportModal /> }

  dataQueryObject: (state, enableSort) => {
    if (!state.app.mapPolygon) return null;

    const r = {
      body: {
        polygon: state.app.mapPolygon,
        filters: {}
      },
      mapZoom: Math.max(4, Math.round(state.app.mapZoom))
    };

    // disabled @ global scale:
    if (r.mapZoom > 4 && state.places.filterOnlyPlacesWithName) {
      r.body.filters.name = state.places.filterOnlyPlacesWithName;
    }

    if (has(state.app.user?.active_features, FEATURES.OSM_ACCESS) && state.places.showOSM) {
      r.body.enable_osm = true;
    }

    if (state.places.filterOnlyPlacesWithCategory.length > 0) {
      r.body.filters.topics = state.places.filterOnlyPlacesWithCategory.map((d) => d.topic);
    }

    if (state.places.filterOnlyPlacesWithEnrichments && has(state.app.user?.active_features, FEATURES.PLACES_ENRICHMENT)) {
      r.body.filters.has_enrichments = true;
    }

    if (state.places.filterByDataRecency > 0) {
      r.body.filters.data_recency = state.places.filterByDataRecency;
    }

    if (state.places.filterByDataRecency === -1 && (state.places.filterByDateRange?.startStr || state.places.filterByDateRange?.endStr)) {
      r.body.filters.date_range = {
        start: state.places.filterByDateRange.startStr ?? '1980-01-01',
        end: state.places.filterByDateRange.endStr ?? '2030-01-01'
      };
      r.body.filters.data_recency = 'custom';
    }

    if (state.places.filterByOpenStatus === 'open') {
      r.body.filters.is_closed = false;
    } else if (state.places.filterByOpenStatus === 'closed') {
      r.body.filters.is_closed = true;
    }

    if (state.places.filterOnlyPlacesUnvalidated) {
      r.body.filters.not_validated = true;
    }

    // disabled @ global scale:
    if (r.mapZoom > 4 && state.places.filterOnlyPlacesWithSimilarName) {
      r.body.filters.name_similar = state.places.filterOnlyPlacesWithSimilarName;
    }

    if (state.places.filterByQuestionAndAnswer?.length > 0) {
      r.body.filters.qa = state.places.filterByQuestionAndAnswer;
      if (state.places.filterByQuestionAndAnswerOR) {
        r.body.filters.qa_or = true;
      }
    }

    if (state.places.filterOnlyPlacesWithOCRLanguage?.length > 0) {
      r.body.filters.ocr_languages = state.places.filterOnlyPlacesWithOCRLanguage.map((d /* ['af', 'Afrikaans'] */) => d[0]);
    }
    if (state.places.filterOnlyPlacesWithOCRContent) {
      r.body.filters.ocr_content = state.places.filterOnlyPlacesWithOCRContent;
    }

    if (state.places.filterByGeographies?.length > 0) {
      const newHascs = new Set();

      [].concat
        .apply(
          [],
          state.places.filterByGeographies.map((d) => d.hascs)
        )
        .map((value) => value.split('.'))
        .sort((a, b) => b.length - a.length)
        .forEach((value) => {
          switch (value.length) {
            case 1:
              newHascs.add(value.join('.'));
              break;
            case 2:
              !newHascs.has(value[0]) && newHascs.add(value.join('.'));
              break;
            case 3:
            default:
              !newHascs.has(value[0]) && !newHascs.has(`${value[0]}.${value[1]}`) && newHascs.add(value.join('.'));
              break;
          }
        });

      r.body.filters.hascs = Array.from(newHascs);
    }

    if (enableSort) {
      switch (state.places.sort) {
        case 'facility name':
          r.body.sort = `alphabetical-${state.places.sortDirection}`;
          break;
        case '# submissions':
          r.body.sort = `confidence-${state.places.sortDirection}`;
          break;
        case 'last seen':
          r.body.sort = `last-seen-${state.places.sortDirection}`;
          break;
        case 'first seen':
          r.body.sort = `first-seen-${state.places.sortDirection}`;
          break;
        case 'distance from center':
          r.body.sort = 'distance-from-center'; // -${state.places.sortDirection}
          break;
        default:
          console.warn(`(placesSlice) 'sort' unexpected value: "${JSON.stringify({ sort: state.places.sort, sortDirection: state.places.sortDirection })}"`);
      }
    }

    // if (Number.isFinite(rowsStart) && Number.isFinite(rowsStop)) {
    //   r.body.start = rowsStop;
    //   r.body.stop = rowsStop;
    // }

    // if (Number.isFinite(limit)) {
    //   r.body.limit = limit;
    // }

    return r;
  },
  filterStates: (state) => ({
    filterOnlyPlacesWithName: state.places.filterOnlyPlacesWithName,
    filterOnlyPlacesWithSimilarName: state.places.filterOnlyPlacesWithSimilarName,
    filterOnlyPlacesWithEnrichments: state.places.filterOnlyPlacesWithEnrichments,
    filterOnlyPlacesUnvalidated: state.places.filterOnlyPlacesUnvalidated,
    filterOnlyPlacesWithCategory: state.places.filterOnlyPlacesWithCategory,
    filterOnlyPlacesWithOCRLanguage: state.places.filterOnlyPlacesWithOCRLanguage,
    filterOnlyPlacesWithOCRContent: state.places.filterOnlyPlacesWithOCRContent,
    filterByOpenStatusOptions: state.places.filterByOpenStatusOptions,
    filterByOpenStatus: state.places.filterByOpenStatus,
    filterByQuestionAndAnswer: state.places.filterByQuestionAndAnswer,
    filterByDataRecencyOptions: state.places.filterByDataRecencyOptions,
    filterByDataRecency: state.places.filterByDataRecency,
    filterByDateRange: state.places.filterByDateRange,
    filterByGeographies: state.places.filterByGeographies,
    showOSM: state.places.showOSM
  }),
  sortState: (state) => ({
    sort: state.places.sort,
    sortOptions: state.places.sortOptions,
    sortDefault: state.places.sortDefault,
    sortDirection: state.places.sortDirection,
    sortDirectionDefault: state.places.sortDirectionDefault
  })
};

export const {
  loadState,
  setResultPanelListMode,
  setNameSimilar,
  setSort,
  setFilterCategories,
  setFilterGeographies,
  setFilterOCRLanguages,
  setFilterOCRContent,
  setFilterQA,
  toggleFilterQAOR,
  setFilterName,
  setShowOSM,
  setOnlyUnvalidated,
  setDataRecency,
  updateDateRange,
  setOperatingStatus,
  resetFilters
} = placesSlice.actions;

/**
 * REDUCERS -> Use these by importing them into the store file and placing them in the configureStore's 'reducer'
 * property with the others.
 */
export default placesSlice.reducer;
