import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

// Modules
import debounce from 'lodash.debounce';

// MUI
import TextField from '@material-ui/core/TextField';

const TextFieldDebounced = (props) => {
  const { onChange, value, numbersonly, ...otherProps } = props;
  const [internalValue, setInternalValue] = useState(value);

  // Wrap this `onChange` prop w/ debounce to reduce chatter:
  const onChangeDebounced = useMemo(
    () => debounce(
      (v) => {
        if (numbersonly) {
          const n = isNaN(v) ? 0 : +v;
          onChange(n);
        } else {
          onChange(v);
        }
      },
      666,
      { trailing: true, leading: false }
    ),
    [onChange, numbersonly]
  );

  useEffect(() => {
    // componentDidMount()
    return () => {
      // componentWillUnmount()
      onChangeDebounced.cancel();
    };
  }, [onChangeDebounced]);

  // if value changes above this component, update this representation:
  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const internalOnChange = useCallback(
    (e) => {
      let v;
      if (numbersonly) {
        v = e.target.value.replace(/[^0-9]/g, '').replace(/^0+/, '');
      } else {
        v = e.target.value;
      }
      // update UI immediately:
      setInternalValue(v);
      // notify parent via debounce:
      onChangeDebounced(v);
    },
    [numbersonly, onChangeDebounced]
  );

  return <TextField {...otherProps} onChange={internalOnChange} value={internalValue} />;
};

TextFieldDebounced.defaultProps = {
  numbersonly: false,
  value: ''
};
TextFieldDebounced.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  numbersonly: PropTypes.bool
};

export default React.memo(TextFieldDebounced);
