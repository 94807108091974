import { createSlice } from '@reduxjs/toolkit';
import {
  // \n
  PRODUCT_TEMPORAL,
  PRODUCT_COMPARE,
  PRODUCT_TEMPORAL_BY_ALERT,
  PRODUCT_COMPARE_BY_FORM,
  PRODUCT_TEMPORAL_BY_FORM
} from './constants';
import helpers from './sentimentSliceHelpers';
import moment from 'moment';

const resetSelectedQuestions = {
  selectedQuestions: [],
  selectedQuestionsDict: {},
  selectedQuestionsOrdering: [],
  selectedQuestionsOrderingRev: [],
  selectedQuestionsOrderingValues: [],
  selectedQuestionType: '',

  selectedAnswer: null,

  // Temporal:
  selectedDateRange: null,

  // Compare: Left
  selectedDateRangeLeft: null,
  selectedDemographicsLeft: {},
  QAFilterSelectedFormLeft: '',
  QAFilterSelectedQuestionLeft: '',
  QAFilterSelectedAnswersLeft: [],

  // Compare: Right
  selectedDateRangeRight: null,
  selectedDemographicsRight: {},
  QAFilterSelectedFormRight: '',
  QAFilterSelectedQuestionRight: '',
  QAFilterSelectedAnswersRight: [],

  // Filters
  polygonSampleSizeMin: 0
};

const resetState = {
  // questions:
  ...resetSelectedQuestions,

  // forms:
  selectedForms: [],
  selectedFormsDict: {},

  // other:
  selectedHasc: null,
  selectedGeographies: [],
  selectedAlert: null
};

// DEFINE INITIAL SLICE STATE
const initialState = {
  ...resetState,
  activeView: PRODUCT_TEMPORAL,
  temporalActiveTab: PRODUCT_TEMPORAL_BY_ALERT,
  compareActiveTab: PRODUCT_COMPARE_BY_FORM,
  polygonRenderLevel: 0,
  significantResultsOnly: false,
  questionMerging: false,
  showTitles: false,
  vectorOpacity: 1.0,

  alertSortDefault: 'num_alerts',
  alertSort: 'num_alerts',

  alertSortDirectionDefault: 'desc',
  alertSortDirection: 'desc',

  alertAutoSetGeographyDefault: false,
  alertAutoSetGeography: false,

  alertDataRecencyDefault: 30,
  alertDataRecency: 30,

  colorRamp: 'interpolateViridis',
  colorRampInverted: false,
  colorOverrides: JSON.parse(window.localStorage.sentimentColorOverrides ?? '{}'),

  alertTimelineFilterType: 'daily',
  alertTimelineFilterSampleSize: 25,
  alertTimelineFilterSignificance: 99,
  alertTimelineFilterPctChange: 0.01,
  alertTimelineFilterMinAlerts: 1
};

const _replaceForms = (state, payload) => {
  state.selectedForms = payload;
  state.selectedFormsDict = payload.reduce((a, b) => ({ ...a, [b.form_id]: b }), {});
  // RESET: Question based data:
  for (const [k, v] of Object.entries(resetSelectedQuestions)) state[k] = v;
};
const _replaceQuestions = (state, payload) => {
  state.selectedQuestions = payload;
  state.selectedQuestionType = payload[0] ? (payload[0].is_ordinal ? 'ordinal' : 'categorical') : '';
  state.selectedQuestionsDict = payload.reduce((a, b) => ({ ...a, [b.question_name]: b }), {});
  const { ordering, orderingValues } = helpers.buildOrdering(payload);
  state.selectedQuestionsOrdering = ordering;
  state.selectedQuestionsOrderingRev = [...ordering].reverse();
  state.selectedQuestionsOrderingValues = orderingValues;
};

// GENERATE STATE SLICE
const sentimentSlice = createSlice({
  name: 'sentiment',
  initialState,
  reducers: {
    setCompareTab(state, { payload }) {
      state.compareActiveTab = payload;
    },
    setTemporalTab(state, { payload }) {
      state.temporalActiveTab = payload;
      state.selectedHasc = null;
      if (payload === PRODUCT_TEMPORAL_BY_FORM) {
        state.selectedAlert = null;
      }
    },
    loadState(state, { payload }) {
      for (const [k, v] of Object.entries(payload)) {
        state[k] = v;
      }
    },
    selectHasc(state, { payload }) {
      state.selectedHasc = payload;
    },
    resetQAFilterSelectionsLeft(state) {
      state.QAFilterSelectedFormLeft = '';
      state.QAFilterSelectedQuestionLeft = '';
      state.QAFilterSelectedAnswersLeft = [];
    },
    setQAFilterSelectedFormLeft(state, { payload }) {
      state.QAFilterSelectedFormLeft = payload ?? '';
      state.QAFilterSelectedQuestionLeft = '';
      state.QAFilterSelectedAnswersLeft = [];
    },
    setQAFilterSelectedQuestionLeft(state, { payload }) {
      state.QAFilterSelectedQuestionLeft = payload ?? '';
      state.QAFilterSelectedAnswersLeft = [];
    },
    setQAFilterSelectedAnswersLeft(state, { payload }) {
      state.QAFilterSelectedAnswersLeft = payload ?? [];
    },
    resetQAFilterSelectionsRight(state) {
      state.QAFilterSelectedFormRight = '';
      state.QAFilterSelectedQuestionRight = '';
      state.QAFilterSelectedAnswersRight = [];
    },
    setQAFilterSelectedFormRight(state, { payload }) {
      state.QAFilterSelectedFormRight = payload ?? '';
      state.QAFilterSelectedQuestionRight = '';
      state.QAFilterSelectedAnswersRight = [];
    },
    setQAFilterSelectedQuestionRight(state, { payload }) {
      state.QAFilterSelectedQuestionRight = payload ?? '';
      state.QAFilterSelectedAnswersRight = [];
    },
    setQAFilterSelectedAnswersRight(state, { payload }) {
      state.QAFilterSelectedAnswersRight = payload ?? [];
    },
    updateDemographicsRight(state, { payload: { enabled, answer, demographic } }) {
      if (enabled) {
        if (state.selectedDemographicsRight[demographic]) {
          if (!state.selectedDemographicsRight[demographic].includes(answer)) {
            state.selectedDemographicsRight[demographic].push(answer);
          }
        } else {
          state.selectedDemographicsRight[demographic] = [answer];
        }
      } else {
        if (state.selectedDemographicsRight[demographic]) {
          const idx = state.selectedDemographicsRight[demographic].indexOf(answer);
          if (idx > -1) {
            state.selectedDemographicsRight[demographic].splice(idx, 1);
            if (state.selectedDemographicsRight[demographic].length === 0) {
              delete state.selectedDemographicsRight[demographic];
            }
          }
        }
      }
    },
    updateDemographicsLeft(state, { payload: { enabled, answer, demographic } }) {
      if (enabled) {
        if (state.selectedDemographicsLeft[demographic]) {
          if (!state.selectedDemographicsLeft[demographic].includes(answer)) {
            state.selectedDemographicsLeft[demographic].push(answer);
          }
        } else {
          state.selectedDemographicsLeft[demographic] = [answer];
        }
      } else {
        if (state.selectedDemographicsLeft[demographic]) {
          const idx = state.selectedDemographicsLeft[demographic].indexOf(answer);
          if (idx > -1) {
            state.selectedDemographicsLeft[demographic].splice(idx, 1);
            if (state.selectedDemographicsLeft[demographic].length === 0) {
              delete state.selectedDemographicsLeft[demographic];
            }
          }
        }
      }
    },
    updateSelectedDateRangeRight(state, { payload }) {
      if (!payload) {
        state.selectedDateRangeRight = null;
      } else {
        state.selectedDateRangeRight = {
          ...state.selectedDateRangeRight,
          ...payload
        };
      }
    },
    updateSelectedDateRangeLeft(state, { payload }) {
      if (!payload) {
        state.selectedDateRangeLeft = null;
      } else {
        state.selectedDateRangeLeft = {
          ...state.selectedDateRangeLeft,
          ...payload
        };
      }
    },
    reset(state) {
      for (const [k, v] of Object.entries(resetState)) state[k] = v;
    },
    updateColorOverrides(state, { payload: { selectedQuestions, answer, hex } }) {
      // read overrides from localStorage
      const sentimentColorOverrides = JSON.parse(window.localStorage.sentimentColorOverrides ?? '{}');

      if (hex) {
        for (const q of selectedQuestions) {
          sentimentColorOverrides[q.question_name] = sentimentColorOverrides[q.question_name] ?? {};
          sentimentColorOverrides[q.question_name][answer.label] = hex;
        }
      } else {
        for (const q of selectedQuestions) {
          if (sentimentColorOverrides[q.question_name]) {
            delete sentimentColorOverrides[q.question_name][answer.label];
            if (Object.keys(sentimentColorOverrides[q.question_name]).length === 0) {
              delete sentimentColorOverrides[q.question_name];
            }
          }
        }
      }
      // write overrides to localStorage
      window.localStorage.sentimentColorOverrides = JSON.stringify(sentimentColorOverrides);
      state.colorOverrides = sentimentColorOverrides;
    },
    setVectorOpacity(state, { payload }) {
      if (Number.isFinite(payload) && payload >= 0 && payload <= 1) {
        state.vectorOpacity = payload;
      } else {
        console.warn(`(sentimentSlice) 'setVectorOpacity' dispatched with unexpected data range: "${payload}"`);
      }
    },
    setColorRamp(state, { payload }) {
      if (typeof payload === 'string') {
        state.colorRamp = payload;
      } else {
        console.warn(`(sentimentSlice) 'setColorRamp' dispatched with unexpected data type: "${payload}"`);
      }
    },
    setSelectedAnswer(state, { payload }) {
      if (payload?.label === state.selectedAnswer?.label) {
        state.selectedAnswer = null;
      } else {
        state.selectedAnswer = payload;
      }
    },
    setPolygonSampleSizeMin(state, { payload }) {
      if (Number.isFinite(payload)) {
        state.polygonSampleSizeMin = payload;
      }
    },
    setPolygonRenderLevel(state, { payload }) {
      if (Number.isFinite(payload) && [0, 1, 2].includes(payload)) {
        state.polygonRenderLevel = payload;
      } else {
        console.warn(`(sentimentSlice) 'setPolygonRenderLevel' dispatched with unexpected data type: "${payload}"`);
      }
    },
    setDateRange(state, { payload }) {
      if (payload && (!Number.isFinite(payload.start) || !Number.isFinite(payload.end))) {
        console.warn(`(sentimentSlice) 'setDateRange' dispatched with unexpected data types (start, end): "${JSON.stringify(payload)}"`);
      }
      if (payload && (typeof payload.strStart !== 'string' || typeof payload.strEnd !== 'string')) {
        console.warn(`(sentimentSlice) 'setDateRange' dispatched with unexpected data types (strStart, strEnd): "${JSON.stringify(payload)}"`);
      }
      state.selectedDateRange = payload;
    },
    setAlertTimelineFilterMinAlerts(state, { payload }) {
      state.alertTimelineFilterMinAlerts = payload;
    },
    setAlertTimelineFilterPctChange(state, { payload }) {
      state.alertTimelineFilterPctChange = payload;
    },
    setAlertTimelineFilterSignificance(state, { payload }) {
      state.alertTimelineFilterSignificance = payload;
    },
    setAlertTimelineFilterSampleSize(state, { payload }) {
      state.alertTimelineFilterSampleSize = payload;
    },
    toggleAlertTimelineFilterType(state) {
      state.alertTimelineFilterType = state.alertTimelineFilterType === 'daily' ? 'weekly' : 'daily';
    },
    toggleBool(state, { payload: variableName }) {
      if (typeof state[variableName] !== 'boolean') {
        console.warn(`(sentimentSlice) 'toggleBool' dispatched for non-boolean type variable: "${variableName}"; ignoring...`);
      } else {
        state[variableName] = !state[variableName];
      }
    },
    setAlertSort(state, { payload: { alertSort, alertSortDirection } }) {
      state.alertSort = alertSort;
      state.alertSortDirection = alertSortDirection;
    },
    setAlertDataRecency(state, { payload }) {
      state.alertDataRecency = payload;
    },
    setActiveView(state, { payload }) {
      state.activeView = payload;
    },
    selectAlert(state, { payload }) {
      if (!payload) {
        state.selectedAlert = null;
        return;
      }
      const { selectedAlert, selectedGeographies, selectedForms, polygonRenderLevel } = payload;

      state.selectedAlert = selectedAlert;
      state.selectedForms = selectedForms;
      state.selectedHasc = selectedAlert.hasc_code;

      if (selectedGeographies) state.selectedGeographies = selectedGeographies;
      if (Number.isFinite(polygonRenderLevel)) state.polygonRenderLevel = polygonRenderLevel;

      // RESET: Question based data:
      for (const [k, v] of Object.entries(resetSelectedQuestions)) state[k] = v;
    },
    selectGeographies(state, { payload }) {
      state.selectedGeographies = payload;
    },
    deselectForm(state, { payload }) {
      if (!state.selectedForms) return;
      const form_ids = state.selectedForms.map((d) => d.form_id);
      const idx = form_ids.indexOf(payload.form_id);
      if (idx > -1) {
        delete state.selectedFormsDict[payload.form_id];
        // [!] splice changes original array:
        state.selectedForms.splice(idx, 1);
      }
    },
    selectForm(state, { payload }) {
      if (!state.selectedForms) {
        state.selectedForms = [payload];
        state.selectedFormsDict = payload.reduce((a, b) => ({ ...a, [b.form_id]: b }), {});
      } else {
        const form_ids = state.selectedForms.map((d) => d.form_id);
        if (!form_ids.includes(payload.form_id)) {
          state.selectedForms.push(payload);
          state.selectedFormsDict[payload.form_id] = payload;
        }
      }
      // RESET: Question based data:
      for (const [k, v] of Object.entries(resetSelectedQuestions)) state[k] = v;
    },
    restoreQueryString(state, { payload }) {
      const { form /* :object */, question /* :object */, hasc /* :string */ } = payload;
      _replaceForms(state, [form]);
      _replaceQuestions(state, [question]);
      state.selectedHasc = hasc;
      const renderLevel = hasc.split('.').length - 1;
      state.polygonRenderLevel = Math.min(Math.max(renderLevel, 0), 2);
    },
    replaceForms(state, { payload }) {
      _replaceForms(state, payload);
    },
    replaceQuestions(state, { payload }) {
      _replaceQuestions(state, payload);
    },
    selectQuestion(state, { payload: { question, overwrite, checked } }) {
      // if we
      // - can only have one question selected
      // - have question merging disabled
      // - have instruction to overwrite
      if (state.activeView === PRODUCT_TEMPORAL || !state.questionMerging || overwrite) {
        state.selectedQuestions = [question];
      } else {
        const questionTypes = state.selectedQuestions.map((question) => question.is_ordinal);
        // sanity check:
        if (!questionTypes.includes(question.is_ordinal)) return console.warn("(sentimentSlice) 'selectQuestion' dispatched w/ mixed question types, ignoring...");
        const idx = state.selectedQuestions.map((d) => d.question_name).indexOf(question.question_name);
        if (checked) {
          if (idx !== -1) return;
          state.selectedQuestions = [...state.selectedQuestions, question];
        } else {
          if (idx === -1) return;
          state.selectedQuestions.splice(idx, 1);
        }
      }

      // build the remaining metadata from `state.selectedQuestions`:
      state.selectedQuestionsDict = state.selectedQuestions.reduce((a, b) => ({ ...a, [b.question_name]: b }), {});
      state.selectedQuestionType = question.is_ordinal ? 'ordinal' : 'categorical';

      const { ordering, orderingValues } = helpers.buildOrdering(state.selectedQuestions);
      state.selectedQuestionsOrdering = ordering;
      state.selectedQuestionsOrderingRev = [...ordering].reverse();
      state.selectedQuestionsOrderingValues = orderingValues;
    }
  }
});

export const sentimentSelectors = {
  selectTimeseries: (state) => ({
    // required:
    selectedForms: state.sentiment.selectedForms,
    selectedQuestions: state.sentiment.selectedQuestions,
    // optional
    selectedHasc: state.sentiment.selectedHasc
  }),
  selectAlerts: (state) => ({
    // required:
    alertSort: state.sentiment.alertSort ?? 'num_alerts',
    alertSortDirection: state.sentiment.alertSortDirection ?? 'desc',
    alertDataRecency: state.sentiment.alertDataRecency ?? '30',
    // optional:
    selectedGeographies: state.sentiment.selectedGeographies,
    selectedForms: state.sentiment.selectedForms,
    selectedQuestions: state.sentiment.selectedQuestions
  }),
  // used by <DeckMapTemporal />
  selectMapData: (state) => ({
    polygonRenderLevel: state.sentiment.polygonRenderLevel,
    selectedForms: state.sentiment.selectedForms,
    selectedQuestions: state.sentiment.selectedQuestions,
    selectedQuestionsOrdering: state.sentiment.selectedQuestionsOrdering,
    selectedAnswer: state.sentiment.selectedAnswer,
    selectedGeographies: state.sentiment.selectedGeographies,
    selectedDateRange: state.sentiment.selectedDateRange,

    alertTimelineFilterType: state.sentiment.alertTimelineFilterType,
    alertTimelineFilterSampleSize: state.sentiment.alertTimelineFilterSampleSize,
    alertTimelineFilterSignificance: state.sentiment.alertTimelineFilterSignificance,
    alertTimelineFilterPctChange: state.sentiment.alertTimelineFilterPctChange,
    alertTimelineFilterMinAlerts: state.sentiment.alertTimelineFilterMinAlerts
  }),
  selectMapDataCompare: (state) => ({
    polygonRenderLevel: state.sentiment.polygonRenderLevel,
    selectedForms: state.sentiment.selectedForms,
    selectedQuestions: state.sentiment.selectedQuestions,
    selectedQuestionsOrdering: state.sentiment.selectedQuestionsOrdering,
    selectedAnswer: state.sentiment.selectedAnswer,
    selectedGeographies: state.sentiment.selectedGeographies,

    // LEFT SPECIFIC:
    selectedDateRangeLeft: state.sentiment.selectedDateRangeLeft,
    selectedDemographicsLeft: state.sentiment.selectedDemographicsLeft,
    QAFilterSelectedFormLeft: state.sentiment.QAFilterSelectedFormLeft,
    QAFilterSelectedQuestionLeft: state.sentiment.QAFilterSelectedQuestionLeft,
    QAFilterSelectedAnswersLeft: state.sentiment.QAFilterSelectedAnswersLeft,

    // RIGHT SPECIFIC:
    selectedDateRangeRight: state.sentiment.selectedDateRangeRight,
    selectedDemographicsRight: state.sentiment.selectedDemographicsRight,
    QAFilterSelectedFormRight: state.sentiment.QAFilterSelectedFormRight,
    QAFilterSelectedQuestionRight: state.sentiment.QAFilterSelectedQuestionRight,
    QAFilterSelectedAnswersRight: state.sentiment.QAFilterSelectedAnswersRight,

    alertTimelineFilterType: state.sentiment.alertTimelineFilterType,
    alertTimelineFilterSampleSize: state.sentiment.alertTimelineFilterSampleSize,
    alertTimelineFilterSignificance: state.sentiment.alertTimelineFilterSignificance,
    alertTimelineFilterPctChange: state.sentiment.alertTimelineFilterPctChange,
    alertTimelineFilterMinAlerts: state.sentiment.alertTimelineFilterMinAlerts
  }),
  selectDetailPanel: (state) => ({
    selectedHasc: state.sentiment.selectedHasc,
    polygonRenderLevel: state.sentiment.polygonRenderLevel,
    selectedForms: state.sentiment.selectedForms,
    selectedQuestions: state.sentiment.selectedQuestions,
    selectedQuestionsOrdering: state.sentiment.selectedQuestionsOrdering,
    selectedGeographies: state.sentiment.selectedGeographies,
    selectedAnswer: state.sentiment.selectedAnswer,
    activeView: state.sentiment.activeView,

    // LEFT SPECIFIC:
    selectedDateRangeLeft: state.sentiment.selectedDateRangeLeft,
    selectedDemographicsLeft: state.sentiment.selectedDemographicsLeft,
    QAFilterSelectedFormLeft: state.sentiment.QAFilterSelectedFormLeft,
    QAFilterSelectedQuestionLeft: state.sentiment.QAFilterSelectedQuestionLeft,
    QAFilterSelectedAnswersLeft: state.sentiment.QAFilterSelectedAnswersLeft,
    // RIGHT SPECIFIC:
    selectedDateRangeRight: state.sentiment.selectedDateRangeRight,
    selectedDemographicsRight: state.sentiment.selectedDemographicsRight,
    QAFilterSelectedFormRight: state.sentiment.QAFilterSelectedFormRight,
    QAFilterSelectedQuestionRight: state.sentiment.QAFilterSelectedQuestionRight,
    QAFilterSelectedAnswersRight: state.sentiment.QAFilterSelectedAnswersRight
  })
};

export const dumpState = (store) => {
  // grabs global redux data we need for serialization
  const reduxState = store.getState();
  const out = {
    ...reduxState.sentiment,
    QAFilterSelectedFormLeft: reduxState.sentiment.QAFilterSelectedFormLeft?.form_id ?? initialState.QAFilterSelectedFormLeft,
    QAFilterSelectedQuestionLeft: reduxState.sentiment.QAFilterSelectedQuestionLeft?.question_name ?? initialState.QAFilterSelectedQuestionLeft,
    QAFilterSelectedFormRight: reduxState.sentiment.QAFilterSelectedFormRight?.form_id ?? initialState.QAFilterSelectedFormRight,
    QAFilterSelectedQuestionRight: reduxState.sentiment.QAFilterSelectedQuestionRight?.question_name ?? initialState.QAFilterSelectedQuestionRight,
    selectedForms: reduxState.sentiment.selectedForms.map((d) => d.form_id),
    selectedQuestions: reduxState.sentiment.selectedQuestions.map((d) => d.question_name)
  };

  // these values are generated on load:
  delete out.colorOverrides;
  delete out.selectedFormsDict;
  delete out.selectedQuestionType;
  delete out.selectedQuestionsDict;
  delete out.selectedQuestionsOrdering;
  delete out.selectedQuestionsOrderingRev;
  delete out.selectedQuestionsOrderingValues;

  return out;
};

export const restoreState = (serializedState, availableForms, availableQuestions) => {
  const state = {
    ...serializedState.productState
  };
  const serializedSelectedQuestions = serializedState.productState.selectedQuestions;
  const serializedSelectedForms = serializedState.productState.selectedForms;
  const selectedForms = availableForms.filter(({ form_id }) => serializedSelectedForms.includes(form_id));
  if (selectedForms.length === 0) {
    return {
      error: 'The form referenced in this saved view is no longer available'
    };
  }
  state.selectedForms = selectedForms;
  state.selectedFormsDict = selectedForms.reduce((a, b) => ({ ...a, [b.form_id]: b }), {});

  // try to find the question referenced in the serializedState:
  const selectedQuestions = availableQuestions.filter(({ question_name }) => serializedSelectedQuestions.includes(question_name));
  if (selectedQuestions.length === 0) {
    return {
      error: 'The question referenced in this saved view is no longer available'
    };
  }
  state.selectedQuestions = selectedQuestions;
  // all questions types must be the same,
  if (!selectedQuestions.every(({ is_ordinal }, _, arr) => is_ordinal === arr[0].is_ordinal)) {
    return {
      error: 'The questions referenced in this saved view have been reconfigured and are no longer compatible.'
    };
  }

  state.selectedQuestionType = selectedQuestions[0].is_ordinal ? 'ordinal' : 'categorical';
  state.selectedQuestionsDict = state.selectedQuestions.reduce((a, b) => ({ ...a, [b.question_name]: b }), {});

  const { ordering, orderingValues } = helpers.buildOrdering(state.selectedQuestions);
  state.selectedQuestionsOrdering = ordering;
  state.selectedQuestionsOrderingRev = [...ordering].reverse();
  state.selectedQuestionsOrderingValues = orderingValues;

  return state;
};

export const _legacyRestoreState = (serializedState, availableForms, availableQuestions) => {
  // => "currentBasemap": "streets", => still in local state
  // => "legendModeLeft": "closed", => still in local state
  const state = {};
  const serializedSelectedQuestions = serializedState.state_view.selectedQuestions;
  const serializedSelectedForms = serializedState.state_view.formSelections;
  const selectedForms = availableForms.filter(({ form_id }) => serializedSelectedForms.includes(form_id));
  if (selectedForms.length === 0) {
    return {
      error: 'The form referenced in this saved view is no longer available'
    };
  }
  state.selectedForms = selectedForms;
  state.selectedFormsDict = selectedForms.reduce((a, b) => ({ ...a, [b.form_id]: b }), {});

  // try to find the question referenced in the serializedState:
  const selectedQuestions = availableQuestions.filter(({ question_name }) => serializedSelectedQuestions.includes(question_name));
  if (selectedQuestions.length === 0) {
    return {
      error: 'The question referenced in this saved view is no longer available'
    };
  }
  state.selectedQuestions = selectedQuestions;
  // all questions types must be the same,
  if (!selectedQuestions.every(({ is_ordinal }, _, arr) => is_ordinal === arr[0].is_ordinal)) {
    return {
      error: 'The questions referenced in this saved view have been reconfigured and are no longer compatible.'
    };
  }

  state.selectedQuestionType = selectedQuestions[0].is_ordinal ? 'ordinal' : 'categorical';
  state.selectedQuestionsDict = state.selectedQuestions.reduce((a, b) => ({ ...a, [b.question_name]: b }), {});

  const { ordering, orderingValues } = helpers.buildOrdering(state.selectedQuestions);
  state.selectedQuestionsOrdering = ordering;
  state.selectedQuestionsOrderingRev = [...ordering].reverse();
  state.selectedQuestionsOrderingValues = orderingValues;

  if (serializedState.state_view.activeView === 0 || serializedState.state_view.activeView !== 'compare') {
    // ☁️ Load State Specific to TEMPORAL Sentiment
    state.activeView = PRODUCT_TEMPORAL;

    const serializedDateRanges = serializedState.state_view.subview.dateRanges;

    if (serializedDateRanges && serializedDateRanges.start) {
      const m = moment(serializedDateRanges.start);
      state.selectedDateRange = state.selectedDateRange ?? {};
      state.selectedDateRange.start = m.valueOf();
      state.selectedDateRange.strStart = m.format('YYYY-MM-DD');
    }
    if (serializedDateRanges && serializedDateRanges.end) {
      const m = moment(serializedDateRanges.end);
      state.selectedDateRange = state.selectedDateRange ?? {};
      state.selectedDateRange.end = m.valueOf();
      state.selectedDateRange.strEnd = m.format('YYYY-MM-DD');
    }
    state.questionMerging = false;
    state.temporalActiveTab = PRODUCT_TEMPORAL_BY_FORM;
  } else {
    // ☁️ Load State Specific to COMPARE Sentiment
    state.activeView = PRODUCT_COMPARE;

    // //////////////////////////////////////////////////////////////////
    // won't support this functionality for LEGACY hashes,
    // I checked the database & no saved hashes utilize QA Filters.
    // qaFilterLeft
    // qaFilterRight
    // => QAFilterSelectedFormRight: '',
    // => QAFilterSelectedQuestionRight: '',
    // => QAFilterSelectedAnswersRight: []
    // //////////////////////////////////////////////////////////////////

    state.selectedDemographicsLeft = serializedState.state_view.subview?.demographicsLeft ?? initialState.selectedDemographicsLeft;
    state.selectedDemographicsRight = serializedState.state_view.subview?.demographicsRight ?? initialState.selectedDemographicsRight;

    const serializedDateRanges = serializedState.state_view.subview?.dateRanges;
    if (serializedDateRanges) {
      if (serializedDateRanges.leftStart) {
        const m = moment(serializedDateRanges.leftStart);
        state.selectedDateRangeLeft = state.selectedDateRangeLeft ?? {};
        state.selectedDateRangeLeft.start = m.valueOf();
        state.selectedDateRangeLeft.strStart = m.format('YYYY-MM-DD');
      }
      if (serializedDateRanges.leftEnd) {
        const m = moment(serializedDateRanges.leftEnd);
        state.selectedDateRangeLeft = state.selectedDateRangeLeft ?? {};
        state.selectedDateRangeLeft.end = m.valueOf();
        state.selectedDateRangeLeft.strEnd = m.format('YYYY-MM-DD');
      }
      if (serializedDateRanges.rightStart) {
        const m = moment(serializedDateRanges.rightStart);
        state.selectedDateRangeRight = state.selectedDateRangeRight ?? {};
        state.selectedDateRangeRight.start = m.valueOf();
        state.selectedDateRangeRight.strStart = m.format('YYYY-MM-DD');
      }
      if (serializedDateRanges.rightEnd) {
        const m = moment(serializedDateRanges.rightEnd);
        state.selectedDateRangeRight = state.selectedDateRangeRight ?? {};
        state.selectedDateRangeRight.end = m.valueOf();
        state.selectedDateRangeRight.strEnd = m.format('YYYY-MM-DD');
      }
    }
  }

  // from state_view:
  state.significantResultsOnly = serializedState.state_view.significantOnly === true;

  // from mapview:
  state.vectorOpacity = serializedState.state_view.subview.mapview?.vectorOpacityLeft ?? initialState.vectorOpacity;
  state.showTitles = serializedState.state_view.subview.mapview?.showTitles === true;

  // from subview:
  if ([0, 1, 2].includes(serializedState.state_view.subview.polygonRenderLevel)) {
    state.polygonRenderLevel = serializedState.state_view.subview.polygonRenderLevel;
  } else if (serializedState.state_global?.app_props?.mapZoom >= 4.5) {
    state.polygonRenderLevel = 2;
  } else {
    state.polygonRenderLevel = 1;
  }
  state.selectedAnswer = serializedState.state_view.subview.focusGroup ?? initialState.selectedAnswer;
  state.selectedHasc = serializedState.state_view.subview.selectedHasc ?? initialState.selectedHasc;
  state.alertTimelineFilterType = serializedState.state_view.subview.alertType ?? initialState.alertTimelineFilterType;
  state.alertTimelineFilterSampleSize = serializedState.state_view.subview.alertSampleSize ?? initialState.alertTimelineFilterSampleSize;
  state.alertTimelineFilterSignificance = serializedState.state_view.subview.alertSignificance ?? initialState.alertTimelineFilterSignificance;
  state.alertTimelineFilterPctChange = serializedState.state_view.subview.alertPctChange ?? initialState.alertTimelineFilterPctChange;
  state.alertTimelineFilterMinAlerts = serializedState.state_view.subview.alertMinAlerts ?? initialState.alertTimelineFilterMinAlerts;
  state.colorRampInverted = serializedState.state_view.subview.colorRampInverted ?? initialState.colorRampInverted;
  state.colorRamp = serializedState.state_view.subview.colorRamp ?? serializedState.state_view.subview.colorRampLeft ?? initialState.colorRamp;

  // Return an object that matches this slice's state:
  return state;
};

/**
 * ACTIONS -> Use these by importing them where they are needed and use redux's 'useDispatch'
 * ex: 'import { setMapLevel } from '../sentimentSlice'
 *     'const dispatch = useDispatch();
 *     'dispatch(setMapLevel(<payload>))
 */
export const {
  loadState,
  deselectForm,
  replaceForms,
  replaceQuestions,
  restoreQueryString,
  reset,
  resetQAFilterSelectionsLeft,
  resetQAFilterSelectionsRight,
  selectAlert,
  selectForm,
  selectHasc,
  selectGeographies,
  selectQuestion,
  setActiveView,
  setCompareTab,
  setTemporalTab,
  setAlertDataRecency,
  setAlertSort,
  setAlertTimelineFilterMinAlerts,
  setAlertTimelineFilterPctChange,
  setAlertTimelineFilterSampleSize,
  setAlertTimelineFilterSignificance,
  setColorRamp,
  setDateRange,
  setPolygonRenderLevel,
  setPolygonSampleSizeMin,
  setQAFilterSelectedAnswersLeft,
  setQAFilterSelectedFormLeft,
  setQAFilterSelectedQuestionLeft,
  setQAFilterSelectedAnswersRight,
  setQAFilterSelectedFormRight,
  setQAFilterSelectedQuestionRight,
  setSelectedAnswer,
  setVectorOpacity,
  toggleAlertTimelineFilterType,
  toggleBool,
  updateColorOverrides,
  updateDemographicsLeft,
  updateDemographicsRight,
  updateSelectedDateRangeLeft,
  updateSelectedDateRangeRight
} = sentimentSlice.actions;

/**
 * REDUCERS -> Use these by importing them into the store file and placing them in the configureStore's 'reducer'
 * property with the others.
 */
export default sentimentSlice.reducer;
