import React from 'react';
import PropTypes from 'prop-types';

// MUI
import { makeStyles, alpha } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.secondary.main,
    position: 'absolute',
    backgroundColor: alpha(theme.palette.background.default, 0.5)
  }
}));

const Fetching = (props) => {
  const classes = useStyles();
  const transitionDuration = React.useMemo(() => ({ enter: 666, exit: 0 }), []);
  let style = undefined;
  if (props.disablePointerEvents) {
    style = {
      pointerEvents: 'none'
    };
  }
  return (
    <Backdrop style={style} className={classes.backdrop} open={true} transitionDuration={transitionDuration}>
      <CircularProgress size={props.size} color="inherit" />
    </Backdrop>
  );
};

Fetching.propTypes = {
  size: PropTypes.number,
  disablePointerEvents: PropTypes.bool
};
Fetching.defaultProps = {
  size: 40,
  disablePointerEvents: false
};

export default React.memo(Fetching);
