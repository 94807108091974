import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// REDUX TOOLKIT
import { useDispatch, useSelector } from 'react-redux';
import { demoActions, demoSelectors } from './demographicsSlice';

// UI/UX
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

// ICONS
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

// GLOBALS
const useStyles = makeStyles(() => ({
  optionRow: {
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

const SortPopoverOption = ({ optionName, label }) => {
  // UI/UX
  const classes = useStyles();

  // Redux Toolkit
  const dispatch = useDispatch();

  // Selectors
  const activeOptionName = useSelector(demoSelectors.selectSortChipSortOptionName);
  const activeOptionOrder = useSelector(demoSelectors.selectSortChipSortDirection);

  // Methods
  const handleSortOptionClick = useCallback(
    (direction) => {
      dispatch(demoActions.setSortChipSortOption({ sortOptionName: optionName, sortDirection: direction }));
    },
    [optionName, dispatch]
  );

  // Render Component
  return (
    <Grid id="option-row" className={classes.optionRow} container={true} direction="row" spacing={2}>
      <Grid item={true}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item={true}>
        <Box>
          <IconButton onClick={() => handleSortOptionClick('ascending')}>
            <ArrowUpwardIcon fontSize="small" color={optionName === activeOptionName && 'ascending' === activeOptionOrder ? 'secondary' : 'inherit'} />
          </IconButton>
          <IconButton onClick={() => handleSortOptionClick('descending')}>
            <ArrowDownwardIcon fontSize="small" color={optionName === activeOptionName && 'descending' === activeOptionOrder ? 'secondary' : 'inherit'} />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

SortPopoverOption.propTypes = {
  optionName: PropTypes.string,
  label: PropTypes.string
};

// Export Component
export default React.memo(SortPopoverOption);
