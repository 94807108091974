// essentials
import React from 'react';
import PropTypes from 'prop-types';

// ui/ux
import { withStyles, withTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

// icons
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

// ours
import { API_HOST, genericPost } from 'iris-api'; // eslint-disable-line import/no-unresolved

// pkgs
import StackTrace from 'stacktrace-js';

const styles = (theme) => ({
  container: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    bottom: 0
  },
  paper: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  info: {
    margin: theme.spacing(1, 3, 3),
    textAlign: 'center'
  },
  thanks: {
    margin: theme.spacing(3)
  }
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, reported: false };
    this._xhr = {};
  }

  createIssue(error, stackframes) {
    this._xhr.cancel && this._xhr.cancel();
    genericPost(`${API_HOST}/iris/v1/profile/issue`, { error, stackframes }, this._xhr, (e) => {
      if (e) return console.error(e);
    });
  }

  componentWillUnmount() {
    this._xhr.cancel && this._xhr.cancel();
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      const { classes } = this.props;
      const { error, reported } = this.state;
      return (
        <div className={classes.container}>
          <Paper elevation={3} className={classes.paper}>
            {!reported ? (
              <React.Fragment>
                <ReportProblemIcon />
                <Typography className={classes.info} variant="h6">
                  Something went wrong. We're sorry about that. Please report the issue to notify us.
                </Typography>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    StackTrace.fromError(error).then((stackframes) => {
                      this.createIssue(error.stack ?? error.message ?? error.toString(), stackframes);
                    });
                    this.setState({ reported: true });
                  }}
                >
                  Report issue
                </Button>
              </React.Fragment>
            ) : null}
            {reported ? (
              <Typography className={classes.thanks} variant="h6">
                Thank you! In the meantime, please reload and navigate elsewhere in Iris.
              </Typography>
            ) : null}
          </Paper>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  // ui / ux
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  activeProduct: PropTypes.string.isRequired
};

ErrorBoundary.defaultProps = {};

export default withTheme(withStyles(styles)(ErrorBoundary));
