import React from 'react';
import PropTypes from 'prop-types';

// MODULES
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

// MUI
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// ICONS
import ViewListIcon from '@material-ui/icons/ViewList';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles((theme) => ({
  body2Override: {
    fontSize: '0.6rem',
    color: theme.palette.text.secondary
  },
  body2Hint: {
    color: theme.palette.text.hint
  }
}));
function Typographies(props) {
  const classes = useStyles();
  const { option } = props;
  return (
    <React.Fragment>
      <Typography className={classes.body2Override} variant="body2" gutterBottom={true}>
        {option.form_id}
      </Typography>

      <Typography className={clsx(classes.body2Override, classes.body2Hint)} variant="body2">
        {`${option.project_name} (${option.project_id})`}
      </Typography>

      <Typography className={clsx(classes.body2Override, classes.body2Hint)} variant="body2">{`${moment(option.data_starts).format('YYYY-MM-DD')} \u2194 ${moment(
        option.data_ends
      ).format('YYYY-MM-DD')}`}</Typography>
      <Typography
        className={clsx(classes.body2Override, classes.body2Hint)}
        variant="body2"
      >{`${option.completed_questions} of ${option.available_questions} Questions Active`}</Typography>
    </React.Fragment>
  );
}
Typographies.propTypes = {
  option: PropTypes.object.isRequired
};
const TypographiesMemoized = React.memo(Typographies);

function ACOption(props) {
  const { option, selected } = props;

  if (Number.isFinite(option.numHidden)) {
    return <Box width="100%" alignItems="center" justifyContent="center" display="flex">{`${option.numHidden} Forms Hidden`}</Box>;
  }

  const onRemove = () => {
    props.onRemove(option);
  };

  if (selected) {
    return (
      <ListItem>
        <ListItemIcon>
          <ViewListIcon />
        </ListItemIcon>
        <ListItemText disableTypography={true} primary={<Typography variant="body1">{option.form_name}</Typography>} secondary={<TypographiesMemoized option={option} />} />
        <IconButton onClick={onRemove}>
          <HighlightOffIcon />
        </IconButton>
      </ListItem>
    );
  }

  return (
    <ListItem dense={true} component="div">
      <ListItemText disableTypography={true} primary={<Typography variant="body1">{option.form_name}</Typography>} secondary={<TypographiesMemoized option={option} />} />
    </ListItem>
  );
}
ACOption.propTypes = {
  option: PropTypes.object,
  selected: PropTypes.bool,
  onRemove: PropTypes.func
};

export default React.memo(ACOption);
