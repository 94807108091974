// Core
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// MUI
import { withStyles, withTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

// modules
import amplitude from 'amplitude-js';
import equal from 'deep-equal';
import throttle from 'lodash.throttle';
import { h3ToGeo } from 'h3-js';
import * as d3 from 'd3';

// ours
import { LEFT_PANEL_WIDTH, TOP } from 'iris-config'; // eslint-disable-line import/no-unresolved
import { genericPost, genericGet, API_HOST } from 'iris-api'; // eslint-disable-line import/no-unresolved
import { pushSnackbar as pushSnackbarAction, setGlobal as setGlobalAction } from '../../actions';
import { DEFAULT_ACCURACY } from './utils';

import ExportModal from './modals/ExportModal';
import LeftPanel from '../LeftPanel';
import DeckMap from './DeckMap';
import LeafletMap from './LeafletMap';
import ResultPanel from './ResultPanel';
import DetailPanel from './DetailPanel';
import { restoreStateGlobal } from '../../store';

// GLOBALS
const POINT_LAYER_THRESHOLD = 14.0;

const DATA_SENSITIVE_STATE = ['dataType', 'renderBothDataTypes'];
const DATA_SENSITIVE_PROPS = ['mapPolygon', 'mapZoom'];
const DATA_SENSITIVE_FILTERS = [
  'countries',
  'movement_type',
  'last_seen',
  'network_types',
  'network_names',
  'tower_id',
  'manufacturers',
  'ssid',
  'bssid',
  'accuracy',
  'manufacturer_countries',
  'sort',
  'sortOrder',
  'arfcn_downlink'
];

const styles = () => ({
  circularprogress: {
    position: 'absolute',
    top: TOP,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});
class RadioView extends React.Component {
  constructor(props) {
    super(props);

    this.defaultRadioDataFilters = {
      sort: 'n_observations',
      sortOrder: 'desc',
      accuracy: DEFAULT_ACCURACY
    };

    this._initialState = {
      dataType: 'wifi',
      renderBothDataTypes: false,
      loadedMapZoom: null,

      radioPointData: null,
      radioPointDataBounds: null,
      radioPointDataTimestamp: null,

      radioHexData: null,
      radioHexDataBounds: null,
      radioHexDataTimestamp: null,

      selectedDoc: null,
      hoverDoc: null,

      cellTowerFocus: null,
      dataLoading: !!props.initialQueryString.bounds,

      exportModal: false,

      radioDataFilters: this.defaultRadioDataFilters
    };
    this.state = this._initialState;

    // REF
    this._mapRef = React.createRef();

    // TIMERS
    this._iMapAvailable = null;

    // XHR
    this._xhrRadioPoints = {};
    this._xhrRadioHex = {};
    this._xhrSelectedDoc = {};

    // BIND
    this.onSelect = this.onSelect.bind(this);
    this.onHover = this.onHover.bind(this);
    this.setView = this.setView.bind(this);
    this.onZoomTo = this.onZoomTo.bind(this);
    this.onPanTo = this.onPanTo.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.onCellTowerFocus = this.onCellTowerFocus.bind(this);
    this.onRenderBothDataTypesToggled = this.onRenderBothDataTypesToggled.bind(this);
    this.onDataTypeChanged = this.onDataTypeChanged.bind(this);
    this.updateRadioDataFilters = this.updateRadioDataFilters.bind(this);
    this.resetRadioDataFilters = this.resetRadioDataFilters.bind(this);
    this.onExportModalComplete = this.onExportModalComplete.bind(this);
    this.onExportModalCancel = this.onExportModalCancel.bind(this);

    // THROTTLE
    this.refreshDataThrottled = throttle(this.refreshData, 750, { trailing: true, leading: true });
  }
  shouldRadioFiltersUpdate(f) {
    const pf = this.state.radioDataFilters;
    for (const e of Object.entries(f)) {
      const k = e[0];
      let v = e[1];
      if (Array.isArray(v) && v.length === 0) v = undefined;
      if (!v && !pf[k]) continue;
      if (equal(v, pf[k])) continue;

      return true;
    }

    return false;
  }
  resetRadioDataFilters() {
    this.setState({
      radioDataFilters: this.defaultRadioDataFilters
    });
  }
  updateRadioDataFilters(f) {
    if (!this.shouldRadioFiltersUpdate(f)) {
      return;
    }
    this.setState((s) => ({
      radioDataFilters: {
        ...s.radioDataFilters,
        ...f
      }
    }));
  }
  buildHashStateObject() {
    // Interesting State:
    const { selectedDoc, dataType, radioDataFilters, renderBothDataTypes } = this.state;
    const stateObj = {
      radioDataFilters,
      selectedDoc: selectedDoc?._id,
      dataType,
      renderBothDataTypes
    };

    return stateObj;
  }
  componentDidMount() {
    const { setGlobal, serializedState } = this.props;
    if (serializedState) {
      const apply = (selectedDoc, partialLoad, doc_id) => {
        const filters =
          // views on & after 2021-06-24:
          serializedState.state_global?.app_props?.radioDataFilters ??
          // views prior to 2021-06-24:
          serializedState.state_view?.radioDataFilters ??
          // current implementation:
          serializedState.productState?.radioDataFilters;

        if (filters.manufacturer_countries?.length > 0 && typeof filters.manufacturer_countries[0] === 'string') {
          filters.manufacturer_countries = filters.manufacturer_countries.map((d) => ({ id: d }));
        }

        // fire new state to this component
        this.setState({
          radioDataFilters: {
            ...this._initialState.radioDataFilters,
            ...filters
          },
          selectedDoc: partialLoad ? null : selectedDoc,
          renderBothDataTypes:
            // \n
            serializedState.state_global?.app_props?.radioDataFilters?.types === 'both' ||
            serializedState.state_view?.renderBothDataTypes === true ||
            serializedState.productState?.renderBothDataTypes === true,
          dataType: serializedState.state_view?.dataType ?? serializedState.productState?.dataType ?? this._initialState.dataType
        });
        let notificationQueue;
        if (partialLoad) {
          notificationQueue = [{ type: 'info', message: 'Saved view restored partially restored, selected radio no longer available.' }];
        } else {
          notificationQueue = [{ type: 'success', message: 'Saved view restored successfully!' }];
        }

        amplitude.getInstance().logEvent('view_restored', {
          product: 'radio',
          hash: serializedState.hash,
          scope_id: serializedState.scope_id,
          selection: doc_id,
          selection_exists: !!selectedDoc
        });

        // fire new state to old redux,
        const newStateGlobal = restoreStateGlobal(serializedState);
        const configPanelOpen = serializedState.globalState?.configPanelOpen === true || ['results', 'filter'].includes(serializedState.state_global?.app_props?.radioActivePanel);
        setGlobal({
          notificationQueue,
          serializedState: null, // load complete, clear it.
          configPanelOpen,
          ...newStateGlobal
        });
      };
      // if a place is selected, pre-fetch places & submissions for the restore:
      const doc_id = serializedState.state_view?.selectedDoc?._id ?? serializedState.productState?.selectedDoc;

      if (doc_id) {
        genericGet(`${API_HOST}/radio/v0/radio/data/${doc_id}`, this._xhrSelectedDoc, (e, r) => {
          apply(r, !!e, doc_id);
        });
      } else {
        apply();
      }
    } else {
      // incur an update on mount
      this.componentDidUpdate({}, {});
      this.parseQueryParams();
    }
  }
  componentWillUnmount() {
    // XHR
    this._xhrRadioPoints.cancel && this._xhrRadioPoints.cancel();
    this._xhrRadioHex.cancel && this._xhrRadioHex.cancel();
    this._xhrSelectedDoc.cancel && this._xhrSelectedDoc.cancel();

    // TIMERS
    if (this._iMapAvailable) {
      clearInterval(this._iMapAvailable);
      this._iMapAvailable = null;
    }

    // THROTTLE
    this.refreshDataThrottled.cancel();
  }
  parseQueryParams() {
    const { initialQueryString } = this.props;
    if (initialQueryString.bounds) {
      const parts = initialQueryString.bounds.split(',');
      if (parts.length === 4) {
        const [minx, maxx, miny, maxy] = parts.map((d) => +d);
        this.fitBounds({ minx, maxx, miny, maxy });
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { radioDataFilters: f } = this.state;
    const { radioDataFilters: pf } = prevState;

    let dirty;
    for (const k of DATA_SENSITIVE_STATE) {
      if (prevState[k] !== this.state[k]) {
        dirty = true;
        break;
      }
    }
    for (const k of DATA_SENSITIVE_PROPS) {
      if (prevProps[k] !== this.props[k]) {
        dirty = true;
        break;
      }
    }
    if (!dirty) {
      for (const k of DATA_SENSITIVE_FILTERS) {
        if (!equal(f[k], pf[k])) {
          dirty = true;
          break;
        }
      }
    }
    if (dirty && this.props.mapBounds) {
      return this.refreshDataThrottled();
    }
  }
  refreshData() {
    const NOW = new Date().getTime();
    const { mapZoom, mapBounds, pushSnackbar } = this.props;
    const { dataType, renderBothDataTypes, radioDataFilters } = this.state;
    const types = renderBothDataTypes ? 'both' : dataType;

    this._xhrRadioHex.cancel && this._xhrRadioHex.cancel();
    this._xhrRadioPoints.cancel && this._xhrRadioPoints.cancel();
    this.setState({ dataLoading: true });

    const requestBody = {
      ...radioDataFilters,
      last_seen: null,
      types
    };
    if (radioDataFilters.manufacturer_countries?.length) {
      requestBody.manufacturer_countries = radioDataFilters.manufacturer_countries.map((d) => d.id);
    }
    if (radioDataFilters.countries?.length) {
      requestBody.countries = radioDataFilters.countries.map((d) => d.hasc);
    }
    if (radioDataFilters.last_seen?.strStart) {
      requestBody.last_seen = requestBody.last_seen ?? {};
      requestBody.last_seen.start = radioDataFilters.last_seen.strStart;
    }
    if (radioDataFilters.last_seen?.strEnd) {
      requestBody.last_seen = requestBody.last_seen ?? {};
      requestBody.last_seen.end = radioDataFilters.last_seen.strEnd;
    }

    if (mapZoom < POINT_LAYER_THRESHOLD) {
      const requestedZoom = Math.min(Math.max(parseInt(mapZoom - 1), 4), 10);
      genericPost(
        `${API_HOST}/radio/v0/radio/pbh3/within/${[mapBounds.minx, mapBounds.miny, mapBounds.maxx, mapBounds.maxy, requestedZoom].join('/')}`,
        requestBody,
        this._xhrRadioHex,
        (e, r) => {
          if (e) {
            pushSnackbar({
              type: 'error',
              key: new Date().getTime(),
              message: e === 401 ? 'Failed to fetch data from server, Unauthorized!' : 'Failed to fetch data from server'
            });
            return this.setState({
              radioPointData: [],
              radioPointDataBounds: null,
              radioPointDataTimestamp: NOW,

              radioHexData: [],
              radioHexDataBounds: null,
              radioHexDataTimestamp: NOW,

              loadedMapZoom: null,
              dataLoading: false
            });
          }

          const { hexagons } = window.irisProto.Hexagons.decode(new Uint8Array(r));
          this.setState({
            radioPointData: [],
            radioPointDataBounds: null,
            radioPointDataTimestamp: NOW,

            radioHexData: hexagons,
            radioHexDataBounds: mapBounds,
            radioHexDataTimestamp: NOW,

            loadedMapZoom: mapZoom,
            dataLoading: false
          });
        },
        false /* do not disable authentication */,
        true /* enable arraybuffer response type */
      );
    } else {
      genericPost(
        `${API_HOST}/radio/v0/radio/pb/within/${[mapBounds.minx, mapBounds.miny, mapBounds.maxx, mapBounds.maxy, mapZoom].join('/')}`,
        requestBody,
        this._xhrRadioPoints,
        (e, r) => {
          if (e) {
            pushSnackbar({
              type: 'error',
              key: new Date().getTime(),
              message: e === 401 ? 'Failed to fetch data from server, Unauthorized!' : 'Failed to fetch data from server'
            });
            return this.setState({
              radioPointData: [],
              radioPointDataBounds: null,
              radioPointDataTimestamp: NOW,

              radioHexData: [],
              radioHexDataBounds: null,
              radioHexDataTimestamp: NOW,

              loadedMapZoom: null,
              dataLoading: false
            });
          }

          const { points } = window.irisProto.RadioPoints.decode(new Uint8Array(r));
          this.setState({
            radioPointData: points,
            radioPointDataBounds: mapBounds,
            radioPointDataTimestamp: NOW,

            radioHexData: [],
            radioHexDataBounds: null,
            radioHexDataTimestamp: NOW,

            loadedMapZoom: mapZoom,
            dataLoading: false
          });
        },
        false /* do not disable authentication */,
        true /* enable arraybuffer response type */
      );
    }
  }
  onSelect(source_id) {
    const { selectedDoc } = this.state;
    if (selectedDoc && selectedDoc._id === source_id) {
      return;
    }

    this._xhrSelectedDoc.cancel && this._xhrSelectedDoc.cancel();
    if (source_id) {
      genericGet(`${API_HOST}/radio/v0/radio/data/${source_id}`, this._xhrSelectedDoc, (e, r) => {
        if (e) return this.props.pushSnackbar({ type: 'error', message: 'Failed to retrieve data on source' });

        amplitude.getInstance().logEvent('selection', {
          product: 'radio',
          selection: source_id
        });

        this.setState({
          selectedDoc: r
        });
      });
    } else {
      this.setState({
        selectedDoc: null,
        rfpropThresholds: null,
        rfpropScale: null
      });
    }
  }
  onHover(hoverDoc) {
    this.setState({ hoverDoc });
  }

  fitBounds(bounds) {
    if (this._iMapAvailable) {
      clearInterval(this._iMapAvailable);
      this._iMapAvailable = null;
    }
    this._iMapAvailable = setInterval(() => {
      if (this._mapRef.current) {
        clearInterval(this._iMapAvailable);
        this._iMapAvailable = null;
        this._mapRef.current.fitBounds(bounds);
      } else {
        console.warn('(radio/View) map unavailable, will retry...');
      }
    }, 100);
  }
  setView(x_lon, y_lat, z) {
    if (this._iMapAvailable) {
      clearInterval(this._iMapAvailable);
      this._iMapAvailable = null;
    }
    this._iMapAvailable = setInterval(() => {
      if (this._mapRef.current) {
        clearInterval(this._iMapAvailable);
        this._iMapAvailable = null;
        this._mapRef.current.setView(x_lon, y_lat, z);
      } else {
        console.warn('(radio/View) map unavailable, will retry...');
      }
    }, 100);
  }
  onPanTo(d) {
    this.setView(d.location.lon, d.location.lat, false);
  }
  onZoomTo(d) {
    if (d.rf_prop) {
      const rfPoints = d.rf_prop.map((d1) => h3ToGeo(d1[0]));
      const x = rfPoints.map((d1) => d1[1]);
      const y = rfPoints.map((d1) => d1[0]);
      const maxx = d3.max(x);
      const minx = d3.min(x);
      const maxy = d3.max(y);
      const miny = d3.min(y);
      const outx = Math.abs((maxx - minx) * 0.2);
      const outy = Math.abs((maxy - miny) * 0.2);
      const bounds = {
        minx: minx - outx,
        maxx: maxx + outx,
        miny: miny - outy,
        maxy: maxy + outy
      };
      this.fitBounds(bounds);
    } else {
      this.setView(d.location.lon, d.location.lat);
    }
  }
  onCellTowerFocus(/* place */) {
    // const { cellTowerFocus } = this.state;
    // if (!place && cellTowerFocus) {
    //   return this.setState({ cellTowerFocus: null });
    // }
    // if (place && (!cellTowerFocus || (cellTowerFocus && place.place_id !== cellTowerFocus.place_id))) {
    //   return this.setState({ cellTowerFocus: place });
    // }
  }
  doExportModal() {
    if (!this.state.exportModal) {
      this.setState({
        exportModal: true
      });
    }
  }
  onExportModalComplete() {
    if (this.state.exportModal) this.setState({ exportModal: false });
  }
  onExportModalCancel() {
    if (this.state.exportModal) this.setState({ exportModal: false });
  }
  onRenderBothDataTypesToggled() {
    this.setState((s) => ({
      renderBothDataTypes: !s.renderBothDataTypes
    }));
  }
  onDataTypeChanged(dataType, sort) {
    this.setState((s) => ({
      dataType,
      radioDataFilters: {
        ...s.radioDataFilters,
        sort
      }
    }));
  }
  render() {
    const { classes, configPanelOpen, hasGPU, serializedState } = this.props;

    const {
      radioPointData,
      radioHexData,
      radioHexDataTimestamp,
      radioPointDataTimestamp,
      selectedDoc,
      hoverDoc,
      cellTowerFocus,
      dataLoading,
      exportModal,
      renderBothDataTypes,
      dataType,
      radioDataFilters
    } = this.state;

    let mapLeft = 0;
    let panelLeft = 0;
    if (configPanelOpen) {
      mapLeft += LEFT_PANEL_WIDTH;
      panelLeft += LEFT_PANEL_WIDTH;
    }
    if (selectedDoc) {
      mapLeft += LEFT_PANEL_WIDTH;
    }

    const commonMapProps = {
      ref: this._mapRef,
      // data: point
      radioPointData: radioPointData,
      radioPointDataKey: radioPointDataTimestamp,
      // data: hex
      radioHexData: radioHexData,
      radioHexDataKey: radioHexDataTimestamp,
      // data: selected
      selectedDoc: selectedDoc,
      hoverDoc: hoverDoc,
      cellTowerFocus: cellTowerFocus,
      // ui/ux
      left: mapLeft,
      dataLoading: dataLoading,
      // callbacks
      onSelect: this.onSelect
    };

    // don't mount anything until we have restored state:
    if (serializedState)
      return (
        <Box className={classes.circularprogress}>
          <CircularProgress color="secondary" />
        </Box>
      );

    return (
      <React.Fragment>
        {configPanelOpen && (
          <ResultPanel
            id="radio_result-panel"
            dataKey={radioPointDataTimestamp}
            onSelect={this.onSelect}
            onHover={this.onHover}
            selectedDoc={selectedDoc}
            dataType={dataType}
            renderBothDataTypes={renderBothDataTypes}
            onRenderBothDataTypesChanged={this.onRenderBothDataTypesToggled}
            onDataTypeChanged={this.onDataTypeChanged}
            updateRadioDataFilters={this.updateRadioDataFilters}
            resetRadioDataFilters={this.resetRadioDataFilters}
            radioDataFilters={radioDataFilters}
          />
        )}

        <LeftPanel open={!!selectedDoc} left={panelLeft}>
          <DetailPanel onSelect={this.onSelect} selectedDoc={selectedDoc} onZoomTo={this.onZoomTo} onPanTo={this.onPanTo} onCellTowerFocus={this.onCellTowerFocus} />
        </LeftPanel>

        {hasGPU ? <DeckMap {...commonMapProps} /> : <LeafletMap {...commonMapProps} />}
        {exportModal && (
          <ExportModal
            renderBothDataTypes={renderBothDataTypes}
            onRenderBothDataTypesToggled={this.onRenderBothDataTypesToggled}
            dataType={dataType}
            radioDataFilters={radioDataFilters}
            onCancel={this.onExportModalCancel}
            onComplete={this.onExportModalComplete}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  hasGPU: state.app.hasGPU,
  configPanelOpen: state.app.configPanelOpen,
  mapPolygon: state.app.mapPolygon,
  mapBounds: state.app.mapBounds,
  mapCenter: state.app.mapCenter,
  mapZoom: state.app.mapZoom,
  initialQueryString: state.app.initialQueryString,
  serializedState: state.app.serializedState
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushSnackbar: pushSnackbarAction,
      setGlobal: setGlobalAction
    },
    dispatch
  );

RadioView.propTypes = {
  // ui / ux
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  setGlobal: PropTypes.func.isRequired,
  configPanelOpen: PropTypes.bool,
  serializedState: PropTypes.object,
  hasGPU: PropTypes.bool,
  mapBounds: PropTypes.object,
  mapPolygon: PropTypes.object,
  mapZoom: PropTypes.number,
  pushSnackbar: PropTypes.func,
  initialQueryString: PropTypes.object
};

RadioView.defaultProps = {};

const withRedux = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
export default withRedux(withTheme(withStyles(styles)(RadioView)));
