import { createTheme } from '@material-ui/core/styles';

const overrides = (theme) => ({
  ...theme,
  overrides: {
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: `2px solid ${theme.palette.secondary.main}`
        }
      }
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: theme.palette.secondary.main
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: theme.palette.secondary.main
        }
      }
    },
    MuiSelect: {
      select: {
        '&:focus': {
          // the full theme object has not been created yet; I fetched these
          // values manually from the google chrome console:
          backgroundColor: theme.palette.type === 'dark' ? '#424242' : '#FFFFFF'
        }
      }
    }
  }
});

const darkTheme = createTheme(
  overrides({
    palette: {
      primary: {
        main: '#333333'
      },
      secondary: {
        main: '#82a8e7'
      },
      type: 'dark',
      itype: 'light'
    }
  })
);

const lightTheme = createTheme(
  overrides({
    palette: {
      primary: {
        main: '#eeeeee'
      },
      secondary: {
        main: '#82a8e7'
      },
      type: 'light',
      itype: 'dark'
    }
  })
);

const datePickerDarkTheme = createTheme({
  palette: {
    primary: {
      main: '#82a8e7'
    },
    secondary: {
      main: '#333333'
    },
    type: 'dark',
    itype: 'light'
  }
});

const datePickerLightTheme = createTheme({
  palette: {
    primary: {
      main: '#82a8e7'
    },
    secondary: {
      main: '#eeeeee'
    },
    type: 'light',
    itype: 'dark'
  }
});

darkTheme.palette.tertiary = lightTheme.palette.tertiary = {
  dark: '#a84819',
  main: '#f16824',
  light: '#f3864f'
};

darkTheme.palette.tertiary.contrastText = darkTheme.palette.getContrastText('#f16824');
lightTheme.palette.tertiary.contrastText = lightTheme.palette.getContrastText('#f16824');

export { lightTheme, darkTheme, datePickerDarkTheme, datePickerLightTheme };
