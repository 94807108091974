import {
  mean,
  color,
  interpolateBlues,
  interpolateBrBG,
  interpolateBuGn,
  interpolateBuPu,
  interpolateCividis,
  interpolateCool,
  interpolateCubehelixDefault,
  interpolateGnBu,
  interpolateGreens,
  interpolateGreys,
  interpolateInferno,
  interpolateMagma,
  interpolateOranges,
  interpolateOrRd,
  interpolatePiYG,
  interpolatePlasma,
  interpolatePRGn,
  interpolatePuBu,
  interpolatePuBuGn,
  interpolatePuOr,
  interpolatePuRd,
  interpolatePurples,
  interpolateRainbow,
  interpolateRdBu,
  interpolateRdGy,
  interpolateRdPu,
  interpolateRdYlBu,
  interpolateRdYlGn,
  interpolateReds,
  interpolateSinebow,
  interpolateSpectral,
  interpolateTurbo,
  interpolateViridis,
  interpolateWarm,
  interpolateYlGn,
  interpolateYlGnBu,
  interpolateYlOrBr,
  interpolateYlOrRd
} from 'd3';

const colorRamps = [
  { k: 'interpolateBlues', v: interpolateBlues },
  { k: 'interpolateBrBG', v: interpolateBrBG },
  { k: 'interpolateBuGn', v: interpolateBuGn },
  { k: 'interpolateBuPu', v: interpolateBuPu },
  { k: 'interpolateCividis', v: interpolateCividis },
  { k: 'interpolateCool', v: interpolateCool },
  { k: 'interpolateCubehelixDefault', v: interpolateCubehelixDefault },
  { k: 'interpolateGnBu', v: interpolateGnBu },
  { k: 'interpolateGreens', v: interpolateGreens },
  { k: 'interpolateGreys', v: interpolateGreys },
  { k: 'interpolateInferno', v: interpolateInferno },
  { k: 'interpolateMagma', v: interpolateMagma },
  { k: 'interpolateOranges', v: interpolateOranges },
  { k: 'interpolateOrRd', v: interpolateOrRd },
  { k: 'interpolatePiYG', v: interpolatePiYG },
  { k: 'interpolatePlasma', v: interpolatePlasma },
  { k: 'interpolatePRGn', v: interpolatePRGn },
  { k: 'interpolatePuBu', v: interpolatePuBu },
  { k: 'interpolatePuBuGn', v: interpolatePuBuGn },
  { k: 'interpolatePuOr', v: interpolatePuOr },
  { k: 'interpolatePuRd', v: interpolatePuRd },
  { k: 'interpolatePurples', v: interpolatePurples },
  { k: 'interpolateRainbow', v: interpolateRainbow },
  { k: 'interpolateRdBu', v: interpolateRdBu },
  { k: 'interpolateRdGy', v: interpolateRdGy },
  { k: 'interpolateRdPu', v: interpolateRdPu },
  { k: 'interpolateRdYlBu', v: interpolateRdYlBu },
  { k: 'interpolateRdYlGn', v: interpolateRdYlGn },
  { k: 'interpolateReds', v: interpolateReds },
  { k: 'interpolateSinebow', v: interpolateSinebow },
  { k: 'interpolateSpectral', v: interpolateSpectral },
  { k: 'interpolateTurbo', v: interpolateTurbo },
  { k: 'interpolateViridis', v: interpolateViridis },
  { k: 'interpolateWarm', v: interpolateWarm },
  { k: 'interpolateYlGn', v: interpolateYlGn },
  { k: 'interpolateYlGnBu', v: interpolateYlGnBu },
  { k: 'interpolateYlOrBr', v: interpolateYlOrBr },
  { k: 'interpolateYlOrRd', v: interpolateYlOrRd }
]
  .map((d) => ({
    ...d,
    a: mean(Object.values(color(d.v(0))))
  }))
  .sort(({ a }, { a: b }) => {
    if (a > b) return 1;
    if (b > a) return -1;
    return 0;
  });

export default colorRamps;

export const colorRampLookup = colorRamps.reduce((a, b) => ({ ...a, [b.k]: b.v }), {});
