import * as c from '../constants';

const updateMapPosition = (mapBounds, mapCenter, mapZoom, mapPolygon, mapPitch, mapBearing, mapPolygonBuffered) => ({
  type: c.UPDATE_MAP_POSITION,
  payload: {
    mapBounds,
    mapCenter,
    mapZoom,
    mapPolygon,
    mapPitch,
    mapBearing,
    mapPolygonBuffered
  }
});

const toggleGPU = () => ({
  type: c.TOGGLE_GPU,
  payload: {}
});

const toggleTheme = () => ({
  type: c.TOGGLE_THEME,
  payload: {}
});

const enlargePhoto = (link) => ({
  type: c.ENLARGE_PHOTO,
  payload: link
});

const pushSnackbar = (notification) => ({
  type: c.PUSH_NOTIFICATION,
  payload: notification
});

const popSnackbar = () => ({
  type: c.POP_NOTIFICATION
});

const setGlobal = (payload) => ({
  type: c.GENERIC,
  payload
});

export { enlargePhoto, setGlobal, toggleGPU, toggleTheme, updateMapPosition, pushSnackbar, popSnackbar };
