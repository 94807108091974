import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

// MUI: ICONS
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

// OURS
import { sentimentSelectors, selectHasc } from '../sentimentSlice';
import { getTimeseries } from '../services';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    padding: `0px ${theme.spacing(1)}px`,
    right: 0,
    display: 'flex',
    height: 24
  },
  ol: {
    height: 24
  }
}));

function HascBreadCrumbs(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { left, bottom } = props;

  // REDUX:
  const selectedHasc = useSelector((state) => state.sentiment.selectedHasc);
  const timeseriesQueryArgs = useSelector(sentimentSelectors.selectTimeseries);

  // QUERY
  const { isSuccess, data } = getTimeseries.useQuery(timeseriesQueryArgs, {
    skip: !timeseriesQueryArgs.selectedForms?.length || !timeseriesQueryArgs.selectedQuestions?.length
  });

  // USECALLBACK
  const onClick = useCallback(
    (e) => {
      const i = +e.target.value;
      const parts = ['global', ...selectedHasc.split('.')];
      if (i === parts.length - 1) return;
      if (i === 0) return dispatch(selectHasc('global'));
      dispatch(selectHasc(parts.slice(1, i + 1).join('.')));
    },
    [dispatch, selectedHasc]
  );

  // LOCALS
  const placeNames = isSuccess && data ? [...data.name].reverse() : ['Global'];
  if (!placeNames.includes('Global')) placeNames.unshift('Global');
  return (
    <Box id="sentiment_breadcrumbs" className={classes.container} style={{ left, bottom }}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} classes={{ ol: classes.ol }}>
        {placeNames.map((d, i) => (
          <Link key={d} color="inherit" component="button" variant="body2" value={i} onClick={onClick}>
            {d}
          </Link>
        ))}
      </Breadcrumbs>
    </Box>
  );
}

HascBreadCrumbs.propTypes = {
  left: PropTypes.number,
  bottom: PropTypes.number
};
HascBreadCrumbs.defaultProps = {
  left: 0,
  bottom: 0
};

export default React.memo(HascBreadCrumbs);
