import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';

// REDUX
import { demoSelectors, demoActions } from './demographicsSlice';
import { getAllQuestionsWithAnswers } from './services';
import { useDispatch, useSelector } from 'react-redux';
import { pushSnackbar as pushSnackbarAction, pushSnackbar } from '../../actions';

// MODULES
import { color } from 'd3';
import palette from 'google-palette';

// UI/ UX
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';

// OURS
import SortChip from './SortChip';
import { QUESTION_LOOKUP } from '../../constants';
import ElevatedTabs from '../ElevatedTabs';
import AnswerFilterChip from './AnswerFilterChip';
import QAFilterChip from './QAFilterChip';
import RespondentsFilter from './RespondentsFilter';
import CountryFilterChip from './CountryFilterChip';

// GLOBALS
const ZOOM_L2_THRESHOLD = 5;
const MAX_DISTINCT_COLORS = 65;
const COLOR_OPACITY = 'b3';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(4)}px)`
  },
  waitingBox: {
    width: '100%',
    height: 200
  },
  answerButton: {
    margin: theme.spacing(0.5, 0)
  },
  slider: {
    marginTop: 40
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: 'calc(50% - 25px)'
  },
  list: {
    display: 'flex',
    flexDirection: 'row',
    'align-Items': 'center',
    'justify-Content': 'center"'
  },
  alert: {
    margin: theme.spacing(1)
  },
  tab: {
    minWidth: 50
  },
  tabLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 30
  },
  tabIcon: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.text.primary
  },
  l0Icon: {
    height: 16,
    width: 16,
    borderRadius: 2
  },
  l1Icon: {
    height: 12,
    width: 12,
    borderRadius: 2
  },
  l2Icon: {
    height: 8,
    width: 8,
    borderRadius: 2
  }
}));

/**
 * Configuration panel on the left hand side of the screen which allows users
 * to tweak parameters in order to gain personalized insight about Demographics data.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const DemoConfigPanel = () => {
  // UI
  const classes = useStyles();

  // Redux Toolkit
  const dispatch = useDispatch();

  // Selectors
  const selectedQuestion = useSelector(demoSelectors.selectSelectedQuestion);
  const zoom = useSelector((state) => state.app.mapZoom);
  const mapLevel = useSelector(demoSelectors.selectMapLevel);

  // Queries
  const allQAQuery = getAllQuestionsWithAnswers.useQuery();

  // Methods

  /**
   * Sets the demographics map level depending on the user's newly selected tab.
   *
   * @param event
   * @param value
   * @returns {*}
   *
   * @author Daee Kang <daee.kang@premise.com>
   */
  const setLevel = (event, value) => {
    if (![0, 1, 2].includes(value)) {
      return dispatch(pushSnackbar({ type: 'error', message: 'Attempted to change to an incorrect level' }));
    }
    dispatch(demoActions.setMapLevel(value));
  };

  /**
   * Generates a palette for each demographics question. Once all unique question and answer combinations have been
   * fetched from the server, this function should be called. It will iterate over questions, and create a maximum of
   * MAX_DISTINCT_COLORS unique colors. If a question has more than MAX_DISTINCT_COLORS unique answers, answers after the
   * MAX_DISTINCT_COLORS most prevalent wll be colored in grey (#6e6e6e).
   *
   * Pre: Answer arrays must be sorted in order of most prevalent (most often displayed), descending.
   *
   * @param qaData Question/Answer data fetched from the server in the form {q0: [a0,...aN], ...qN}
   *
   * @author Pau Lleonart Calvo <pau.calvo@premise.com>
   */
  const generateColors = useCallback((qaData) => {
    if (!qaData) {
      console.error('QAData is null, so colors cannot be generated!');
      return;
    }

    const colors = {};
    for (const question in qaData) {
      colors[question] = {};
      const numColors = qaData[question].answers.length;

      let generatedPalette;
      const greenGradient = function (x) {
        return palette.rgbColor(200, x, 0);
      };

      if (qaData[question].isOrdinal) {
        generatedPalette = palette.generate(greenGradient, numColors <= MAX_DISTINCT_COLORS ? numColors : MAX_DISTINCT_COLORS);
      } else {
        generatedPalette = palette('mpn65', numColors <= MAX_DISTINCT_COLORS ? numColors : MAX_DISTINCT_COLORS);
      }

      qaData[question].answers.forEach((answer, index) => {
        colors[question][answer] = index < MAX_DISTINCT_COLORS ? color(`#${generatedPalette[index]}${COLOR_OPACITY}`).toString() : color(`#6e6e6e${COLOR_OPACITY}`).toString();
      });
    }

    return colors;
  }, []);

  /**
   * Once question/answer data is loaded, this effect will generate color palettes
   * based on the results used for deckmap and legend
   *
   * @author Pau Lleonart Calvo <pau.calvo@premise.com>
   */
  useEffect(() => {
    if (allQAQuery.data && Object.keys(allQAQuery.data).length > 0) {
      // TODO: Batch these dispatches
      dispatch(demoActions.setColorsByAnswer(generateColors(allQAQuery.data)));
    }
  }, [allQAQuery.data, generateColors, dispatch]);

  /**
   * Handle query errors.
   *
   * @author Pau Lleonart Calvo <pau.calvo@premise.com>
   */
  useEffect(() => {
    if (allQAQuery.error) {
      dispatch(pushSnackbarAction({ type: 'error', message: 'Question/Answer data could not be fetched!' }));
    }
  }, [allQAQuery.error, dispatch]);

  /**
   * If zooming out and user goes below zoom level for L2, switch
   * them back to L1.
   *
   * @author Daee Kang <daee.kang@premise.com>
   */
  useEffect(() => {
    if (mapLevel === 2 && zoom < ZOOM_L2_THRESHOLD) {
      dispatch(demoActions.setMapLevel(1));
    }
  }, [zoom, dispatch, mapLevel]);

  const onHandleChangeSelectedQuestion = useCallback(
    (event) => {
      dispatch(demoActions.setSelectedQuestion(event.target.value));
      dispatch(demoActions.clickedAnswerFilterChipClear());
    },
    [dispatch]
  );

  // Render Component
  return (
    <React.Fragment>
      {allQAQuery.isSuccess && allQAQuery.data && (
        <>
          <FormControl className={classes.formControl}>
            <Typography id="demo-label-sel-question" gutterBottom={true}>
              Questions
            </Typography>
            <Select labelid="demo-label-sel-question" value={selectedQuestion} className={classes.textfield} onChange={onHandleChangeSelectedQuestion}>
              {Object.keys(allQAQuery.data).map((elem) => (
                <MenuItem key={elem} value={elem}>
                  {QUESTION_LOOKUP[elem]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Tooltip title="Filter by the number of responses. Press Enter to apply changes.">
              <Typography id="demo-input-slider" gutterBottom={true}>
                Number of Responses
              </Typography>
            </Tooltip>

            <RespondentsFilter />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Grid container={true} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <Grid item={true}>
                <SortChip chipName="sortChip" label="Sort Results" />
              </Grid>
              <Grid item={true}>
                <AnswerFilterChip chipName="answerFilterChip" label="Filter by Answer" />
              </Grid>
              <Grid item={true}>
                <QAFilterChip chipName="qaFilterChip" label="Filter by Other Q&A" />
              </Grid>
              <Grid item={true}>
                <CountryFilterChip chipName="countryFilterChip" label="Filter by Country" />
              </Grid>
            </Grid>
          </FormControl>
          <ElevatedTabs value={mapLevel} onChange={setLevel} variant="fullWidth">
            <Tab
              value={0}
              className={classes.tab}
              label={
                <Box className={classes.tabLabel}>
                  <Box className={clsx(classes.tabIcon, classes.l0Icon)} />
                  <Typography>L0</Typography>
                </Box>
              }
            />
            <Tab
              value={1}
              className={classes.tab}
              label={
                <Box className={classes.tabLabel}>
                  <Box className={clsx(classes.tabIcon, classes.l1Icon)} />
                  <Typography>L1</Typography>
                </Box>
              }
            />
            {zoom >= ZOOM_L2_THRESHOLD && (
              <Tab
                className={classes.tab}
                value={2}
                label={
                  <Box className={classes.tabLabel}>
                    <Box className={clsx(classes.tabIcon, classes.l2Icon)} />
                    <Typography>L2</Typography>
                  </Box>
                }
              />
            )}
          </ElevatedTabs>
        </>
      )}
    </React.Fragment>
  );
};

// EXPORT COMPONENT
export default React.memo(DemoConfigPanel);
