import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import { getAuth, GoogleAuthProvider, EmailAuthProvider, onAuthStateChanged } from 'firebase/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

// MUI
import { makeStyles, alpha, useTheme } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';

// OURS
import { LOGINOVERLAY_IMAGE_LIST } from './constants';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 10,
    color: theme.palette.secondary.main,
    position: 'absolute',
    backgroundColor: alpha(theme.palette.background.default, 0.8)
  },
  logo: {
    width: '20vw',
    padding: theme.spacing(2, 0)
  },
  authBox: {
    zIndex: theme.zIndex.drawer + 12,
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius
  },
  imageListContainer: {
    position: 'absolute',
    width: '50vw',
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center'
  },
  imageList: {
    overflow: 'hidden',
    width: '100%'
  },
  img: {
    borderRadius: theme.shape.borderRadius
  }
}));

const LoginOverlay = () => {
  const theme = useTheme();
  const classes = useStyles();
  const firebaseAuth = getAuth();
  const transitionDuration = React.useMemo(() => ({ enter: 666, exit: 0 }), []);
  const user = useSelector((state) => state.app.user);
  const [showAuth, setShowAuth] = useState(false);

  useEffect(() => {
    // componentDidMount
    onAuthStateChanged(firebaseAuth, (firebaseUser) => {
      setShowAuth(!firebaseUser);
    });
  }, []); //eslint-disable-line

  const uiConfig = useMemo(
    () => ({
      signInFlow: 'popup',
      signInOptions: [
        {
          provider: GoogleAuthProvider.PROVIDER_ID,
          scopes: ['https://www.googleapis.com/auth/userinfo.profile', 'https://www.googleapis.com/auth/userinfo.email'],
          customParameters: {
            // Forces account selection even when one account
            // is available.
            prompt: 'select_account'
          }
        },
        {
          provider: EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: true
        }
      ]
    }),
    []
  );

  if (!showAuth) return null;
  const logoSrc = theme.palette.type === 'dark' ? 'Premise_Logo_Red_RGB.svg' : 'Premise_Logo_Black_RGB.svg';
  return (
    <Backdrop className={classes.backdrop} open={true} transitionDuration={transitionDuration}>
      {!user && (
        <Box className={classes.imageListContainer}>
          <img alt="premise-logo" className={classes.logo} src={`/images/${logoSrc}`} />
          <ImageList rowHeight={160} className={classes.imageList} cols={8}>
            {LOGINOVERLAY_IMAGE_LIST.map((src, i) => (
              <ImageListItem key={src} cols={i % 2 === 0 || i % 5 === 0 ? 2 : 1} classes={{ item: classes.img }}>
                <img src={src} />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      )}
      <Box className={clsx(!user && classes.authBox)}>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseAuth} />
      </Box>
    </Backdrop>
  );
};

LoginOverlay.propTypes = {};

export default React.memo(LoginOverlay);
