import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * THIS IS SIMILAR TO THE EXISTING POPOVER BUT LEVERAGES RTK.
 */

// REDUX TOOLKIT
import { demoSelectors } from './demographicsSlice';
import { useSelector } from 'react-redux';

// UI/UX
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';

// ICONS
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SortPopoverOption from './SortPopoverOption';

// GLOBALS
const useStyles = makeStyles((theme) => ({
  sortOpts: {
    padding: theme.spacing(2),
    textTransform: 'capitalize',
    minWidth: '275px',
    maxWidth: '400px'
  }
}));

/**
 *
 * @param chipName This name is used to identify the chip and locate it within the redux demographics slice state.
 * @param label String to be displayed as the chip's label
 * @param classes
 * @returns {JSX.Element}
 * @constructor
 */
const SortChip = ({ chipName, label }) => {
  const classes = useStyles();

  // Local State
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;
  const id = open ? chipName : undefined;

  // Selectors
  const isActive = useSelector((state) => demoSelectors.selectConfigChipActiveByName(state, chipName));

  // Methods
  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  // Render Component
  return (
    <React.Fragment>
      <Chip
        aria-describedby={id}
        variant="outlined"
        color={isActive ? 'secondary' : 'default'}
        label={label}
        deleteIcon={<ArrowDropDownIcon />}
        onClick={handleClick}
        onDelete={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleClose}
      >
        <Box className={classes.sortOpts}>
          <Grid id="topRow" container={true} spacing={2} direction="column" alignItems="center" justifyContent="space-between">
            {/* The SortPopoverOption component will automatically plug into the demographics state slice such that only 1 sort is applied at a time.
            For more sorting options, simply add another SortPopoverOption in parallel with a different optionName and label*/}
            <SortPopoverOption optionName="respondents" label="Respondents" />
            <SortPopoverOption optionName="population" label="Population" />
            <SortPopoverOption optionName="name" label="Name" />
            <SortPopoverOption optionName="country" label="Country" />
          </Grid>
        </Box>
      </Popover>
    </React.Fragment>
  );
};

SortChip.propTypes = {
  chipName: PropTypes.string,
  label: PropTypes.string
};

export default SortChip;
