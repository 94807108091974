import { withTheme, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

// ours
import PlacePaper from '../../../PlacePaper';
import IrisModal from './IrisModal';
import { ValidationMap } from '@premisedata/iris-components';
import { LEFT_PANEL_WIDTH } from 'iris-config'; // eslint-disable-line import/no-unresolved

const styles = (theme) => ({
  box: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    '& > button': {
      margin: `0 ${theme.spacing(2)}px`
    }
  },
  full: {
    height: 'calc(100% - 52px)',
    width: '100%'
  }
});

class DeleteModal extends React.PureComponent {
  renderLoading() {
    return (
      <Box display="flex" className={this.props.classes.full} alignItems="center" justifyContent="center">
        <CircularProgress color="inherit" />
      </Box>
    );
  }
  renderContent() {
    const { selectedPlace, submissions, userdataLayersById } = this.props;

    return (
      <Grid
        container={true}
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        style={{
          // 52px : 32px button height + margins
          height: 'calc(100% - 52px)'
        }}
      >
        <Grid item={true} xs={6}>
          <Grid container={true} direction="column" justifyContent="flex-start" alignItems="center" spacing={2}>
            <Grid item={true} xs={12}>
              <PlacePaper place={selectedPlace} enableHover={false} userdataLayerMetadata={userdataLayersById?.[selectedPlace.type]} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xs={6} style={{ height: '100%', width: '100%' }}>
          <ValidationMap activePlace={false} submissions={submissions} places={[selectedPlace]} />
        </Grid>
      </Grid>
    );
  }
  render() {
    const { selectedPlace, classes, submissions } = this.props;

    return (
      <IrisModal width={LEFT_PANEL_WIDTH * 2} height={500}>
        {selectedPlace && submissions ? this.renderContent() : this.renderLoading()}
        <Box className={classes.box}>
          <Button variant="outlined" color="default" onClick={this.props.onCancel}>
            Cancel
          </Button>
          <Button variant="outlined" color="secondary" onClick={this.props.onComplete}>
            Delete
          </Button>
        </Box>
      </IrisModal>
    );
  }
}

DeleteModal.propTypes = {
  // ui / ux
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  // callbacks
  onCancel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,

  // data
  selectedPlace: PropTypes.object,
  submissions: PropTypes.array,
  userdataLayersById: PropTypes.object
};

DeleteModal.defaultProps = {
  submissions: []
};

export default withTheme(withStyles(styles)(DeleteModal));
