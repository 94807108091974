import { withTheme, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import Box from '@material-ui/core/Box';

const styles = (theme) => ({
  container: {
    position: 'absolute',
    zIndex: 2100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
  },
  backdrop: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2000,
    opacity: 0.8,
    backgroundColor: theme.palette.background.default
  },
  modal: {
    backgroundColor: theme.palette.background.default,
    userSelect: 'none',
    padding: theme.spacing(2),
    opacity: 1.0
  }
});

class IrisModal extends React.Component {
  render() {
    const { classes, children, width, height } = this.props;

    return (
      <React.Fragment>
        <Box className={classes.backdrop} />
        <Box className={classes.container}>
          <Box
            className={classes.modal}
            style={{
              width,
              height
            }}
          >
            {children}
          </Box>
        </Box>
      </React.Fragment>
    );
  }
}

IrisModal.propTypes = {
  // ui / ux
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

IrisModal.defaultProps = {
  height: null,
  width: null
};

export default withTheme(withStyles(styles)(IrisModal));
