/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// module
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { List, AutoSizer } from 'react-virtualized';
import { Polyline } from 'react-leaflet';

// MUI
import { withTheme, withStyles, alpha } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// MUI: Icons
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import PlaceIcon from '@material-ui/icons/Place';
import BlockIcon from '@material-ui/icons/Block';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';

// ours
import { pushSnackbar as pushSnackbarAction } from '../.../../../../../actions';
import ValidationMap from './ValidationMap';
import PlacePaper from '../../../PlacePaper';

const smartGridListStyles = (/* theme */) => ({
  gridList: {
    height: 'calc(50% - 170px)'
  }
});

class SmartGridList extends React.Component {
  constructor(props) {
    super(props);
    this.maxCols = 3;
  }
  shouldComponentUpdate(nextProps) {
    // only update if data changes:
    if (nextProps.theme !== this.props.theme) {
      return true;
    }
    if (nextProps.submissions !== this.props.submissions) {
      return true;
    }

    return false;
  }
  render() {
    const { submissions, classes } = this.props;

    // some logic to prevent gaps in images:
    // - only ~1 in 5 should be "wide"
    const maxWide = Math.ceil(submissions.length * 0.2);
    let curRow = 0;
    let numWide = 0;

    return (
      <GridList cellHeight={120} className={classes.gridList} cols={this.maxCols}>
        {submissions.map((sub) => {
          let cols = 1;
          // some logic to prevent gaps in images:
          if (numWide < maxWide) {
            cols = Math.random() > 0.5 ? 2 : 1;
            if (curRow === this.maxCols) {
              curRow = cols;
            } else if (curRow + cols > this.maxCols) {
              cols = 1;
              curRow += 1;
            } else {
              curRow += cols;
            }

            numWide += cols - 1;
          }
          return (
            <GridListTile key={sub.sub_id} cols={cols}>
              <img src={sub.photo} alt={sub.sub_id} />
            </GridListTile>
          );
        })}
      </GridList>
    );
  }
}

SmartGridList.propTypes = {
  // essentials
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  // data
  submissions: PropTypes.array
};

SmartGridList.defaultProps = {
  submissions: []
};

const IrisSmartGridList = withTheme(withStyles(smartGridListStyles)(SmartGridList));

const styles = (theme) => ({
  height100: {
    height: 'calc(100% - 16px)'
  },
  toolBar: {
    '& .MuiButtonGroup-root + .MuiButtonGroup-root': {
      marginLeft: 10
    }
  },
  leftPanel: {
    height: '100%',
    width: 'calc(100% - 16px)',
    overflow: 'scroll',
    backgroundColor: theme.palette.background.default
  },
  submissionsColumn: {
    position: 'relative',
    padding: theme.spacing(1),
    height: '100%',
    '& h4': {
      fontSize: '40px',
      lineHeight: '50px'
    },
    '& h6': {
      fontSize: '15px',
      lineHeight: '15px',
      marginBottom: '20px'
    },
    '& hr': {
      margin: `${theme.spacing(1)}px 0px`
    }
  },
  submissionImage: {
    margin: 'auto',
    display: 'block',
    width: '100%',
    height: '50%',
    objectFit: 'contain'
  },
  dangerBtn: {
    '&:hover': {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
      backgroundColor: alpha(theme.palette.error.main, 0.1)
    }
  },
  blip: {
    animation: `$blip 3000ms ${theme.transitions.easing.easeInOut}`
  },
  '@keyframes blip': {
    '0%': {
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${alpha(theme.palette.tertiary.main, 0.0)}`
    },
    '50%': {
      backgroundColor: alpha(theme.palette.tertiary.main, 0.1),
      border: `1px solid ${alpha(theme.palette.tertiary.main, 1.0)}`
    },
    '100%': {
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${alpha(theme.palette.tertiary.main, 0.0)}`
    }
  },
  btnGridContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    '& button': {}
  },
  resetBtn: {
    position: 'absolute',
    top: 52,
    right: theme.spacing(1)
  },
  resetBtnComplete: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    marginLeft: -75,
    width: 150
  },
  completeBox: {
    height: '100%',
    width: '100%'
  }
});

class SortRejected extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queue: props.rejectedSubmissions.slice(1),

      activeSub: props.rejectedSubmissions[0], // the current submission we are trying to associate
      activePlace: null, // when user hovers their mouse over a place

      associations: {
        /* e.g. "1234" : "5678" */
      },
      unknown: [
        /* e.g. "1234" */
      ],
      discarded: [
        /* e.g. "1234" */
      ],
      undoList: [
        /* e.g. { sub_id: "1234", etc. } */
      ],
      finished: false,
      scrollToIndex: -1,
      loading: false
    };

    this.finish = this.finish.bind(this);
    this.placeMouseIn = this.placeMouseIn.bind(this);
    this.placeMouseOut = this.placeMouseOut.bind(this);
    this.placeClicked = this.placeClicked.bind(this);
    this.undoLast = this.undoLast.bind(this);
    this.onReset = this.onReset.bind(this);
    this.discard = this.discard.bind(this);
    this.unknown = this.unknown.bind(this);
    this.documentKeydown = this.documentKeydown.bind(this);
    this.mapPlaceClicked = this.mapPlaceClicked.bind(this);
  }
  documentKeydown(k) {
    if (k.key === 'z' && (k.metaKey || k.ctrlKey)) {
      this.undoLast();
    }
  }
  componentDidMount() {
    document.addEventListener('keydown', this.documentKeydown);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.documentKeydown);
  }

  finish() {
    const { pushSnackbar } = this.props;
    this.setState({ finished: true, loading: true });

    this.props.onFinish(
      {
        pairs: this.state.associations,
        discarded: this.state.discarded,
        unknown: this.state.unknown
      },
      (e) => {
        if (e) {
          pushSnackbar({ type: 'error', message: 'Failed to send validation data' });
          this.setState({
            finished: false,
            loading: false
          });
        } else {
          this.setState({ finished: true, loading: false });
        }
      }
    );
  }
  renderToolbar() {
    const { classes } = this.props;
    const { finished } = this.state;
    // <IconButton color="secondary" aria-label="help" component="span">
    //   <HelpIcon />
    // </IconButton>
    return (
      <Grid container={true} className={classes.toolBar} direction="row" justifyContent="flex-end" alignItems="flex-start">
        <Grid item={true}>
          <ButtonGroup>
            <Button variant="outlined" color="secondary" onClick={this.props.onBack} className={classes.dangerBtn}>
              Back
            </Button>
            <Button variant="outlined" color="secondary" onClick={this.props.onCancel} className={classes.dangerBtn}>
              Cancel
            </Button>
            <Button variant="outlined" color="secondary" onClick={this.finish} disabled={finished || !!this.state.activeSub}>
              Finish
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  }
  shouldComponentUpdate(nextProps, nextState) {
    const { activeSub, activePlace, finished, scrollToIndex } = this.state;
    const { theme, nearbyPlaces } = this.props;

    // Props:
    if (nextProps.theme !== theme) {
      return true;
    }
    if (nextProps.nearbyPlaces !== nearbyPlaces) {
      return true;
    }

    // State:
    if (nextState.activeSub !== activeSub) {
      return true;
    }

    if (nextState.activePlace !== activePlace) {
      return true;
    }

    if (nextState.finished !== finished) {
      return true;
    }

    if (nextState.scrollToIndex !== scrollToIndex) {
      return true;
    }

    return false;
  }
  placeMouseIn(place) {
    this.setState({
      activePlace: place
    });
  }
  placeMouseOut() {
    this.setState({
      activePlace: null
    });
  }
  placeClicked(place) {
    if (!this.state.activeSub) {
      return;
    }
    this.setState({
      undoList: [...this.state.undoList, this.state.activeSub],
      activeSub: this.state.queue[0],
      queue: this.state.queue.slice(1),
      associations: {
        ...this.state.associations,
        [this.state.activeSub.sub_id]: place.place_id
      },
      activePlace: this.state.queue.length > 0 ? this.state.activePlace : null
    });
  }
  mapPlaceClicked(clickedPlace) {
    const { nearbyPlaces, classes } = this.props;
    const placeIdx = nearbyPlaces.indexOf(clickedPlace);
    if (placeIdx === -1) {
      return;
    }
    for (const ele of document.getElementsByClassName(classes.blip)) {
      ele.classList.remove(classes.blip);
    }
    this.setState({
      scrollToIndex: placeIdx
    });
    setTimeout(() => {
      document.getElementById(`place-paper-box-${clickedPlace.place_id}`).classList.add(classes.blip);
    }, 60);
  }
  discard() {
    if (!this.state.activeSub) {
      return;
    }

    this.setState({
      undoList: [...this.state.undoList, this.state.activeSub],
      activeSub: this.state.queue[0],
      queue: this.state.queue.slice(1),
      discarded: [...this.state.discarded, this.state.activeSub.sub_id]
    });
  }
  unknown() {
    if (!this.state.activeSub) {
      return;
    }

    this.setState({
      undoList: [...this.state.undoList, this.state.activeSub],
      activeSub: this.state.queue[0],
      queue: this.state.queue.slice(1),
      unknown: [...this.state.unknown, this.state.activeSub.sub_id]
    });
  }
  undoLast() {
    const l = this.state.undoList.length;
    if (l === 0) {
      return;
    }

    const t = this.state.undoList[l - 1].sub_id;

    const associations = {
      ...this.state.associations
    };

    delete associations[t];

    const unkIdx = this.state.unknown.indexOf(t);
    let unknown;
    if (unkIdx > -1) {
      unknown = [...this.state.unknown];
      unknown.splice(unkIdx, 1);
    } else {
      // TODO
    }
    const disIdx = this.state.discarded.indexOf(t);
    let discarded;
    if (disIdx > -1) {
      discarded = [...this.state.discarded];
      discarded.splice(disIdx, 1);
    } else {
      // TODO
    }

    this.setState({
      undoList: this.state.undoList.slice(0, l - 1),
      activeSub: this.state.undoList[l - 1],
      queue: [this.state.activeSub, ...this.state.queue],
      associations,
      discarded: discarded || this.state.discarded,
      unknown: unknown || this.state.unknown
    });
  }
  onReset() {
    this.setState({
      undoList: [],
      activeSub: this.props.rejectedSubmissions[0],
      queue: this.props.rejectedSubmissions.slice(1),
      associations: {},
      discarded: [],
      unknown: []
    });
  }
  renderNearbyPlaces() {
    const { classes, nearbyPlaces } = this.props;
    const { scrollToIndex } = this.state;
    if (!nearbyPlaces || nearbyPlaces.length === 0) {
      return null;
    }
    return (
      <Box mx={1} className={classes.leftPanel}>
        <AutoSizer>
          {({ width, height }) => (
            // TODO: fix crazy 2px problem:
            <List
              ref={this._placesListRef}
              height={height}
              width={width - 2}
              rowCount={nearbyPlaces.length}
              rowHeight={128 + 8 /* this provides bottom margin */}
              scrollToIndex={scrollToIndex}
              rowRenderer={({ key, index, style }) => (
                <PlacePaper
                  style={style}
                  tooltip={'Associate'}
                  key={key}
                  place={nearbyPlaces[index]}
                  onMouseIn={this.placeMouseIn}
                  onMouseOut={this.placeMouseOut}
                  onClick={this.state.activeSub ? this.placeClicked : null}
                  disabled={!this.state.activeSub}
                />
              )}
            />
          )}
        </AutoSizer>
      </Box>
    );
  }
  calcProgress() {
    // include the submission that is currently being considered:
    return 100 - ((this.state.queue.length + 1) / this.props.rejectedSubmissions.length) * 100;
  }
  renderSubmission() {
    const { activeSub, queue } = this.state;
    const { classes } = this.props;

    if (!activeSub) {
      return null;
    }

    return (
      <Paper className={classes.submissionsColumn}>
        <Button variant="outlined" color="secondary" startIcon={<RotateLeftIcon />} className={clsx(classes.resetBtn, classes.dangerBtn)} onClick={this.onReset}>
          Reset Photos
        </Button>
        <Typography title={activeSub.name} variant="h4" noWrap={true}>
          {activeSub.name}
        </Typography>
        <Typography variant="subtitle1">Submitted on {moment(activeSub.timestamp).format('YYYY-MM-DD HH:MM ZZ')}</Typography>
        <Divider variant="middle" />
        <img alt={'active submission'} src={activeSub.photo} className={classes.submissionImage} />

        <Grid container={true} direction="row" justifyContent="center" alignItems="center" spacing={2} className={classes.btnGridContainer}>
          <Grid item={true} xs={6}>
            <Button onClick={this.unknown} variant="outlined" color="secondary" fullWidth={true} startIcon={<PlaceIcon />}>
              New Location
            </Button>
          </Grid>
          <Grid item={true} xs={6}>
            <Button onClick={this.discard} variant="outlined" color="secondary" fullWidth={true} startIcon={<BlockIcon />} className={classes.dangerBtn}>
              Inappropriate
            </Button>
          </Grid>
        </Grid>
        <IrisSmartGridList submissions={queue} />
      </Paper>
    );
  }
  renderComplete() {
    const { classes } = this.props;
    const { loading } = this.state;

    return (
      <React.Fragment>
        <Grid className={classes.completeBox} container={true} direction="column" justifyContent="center" alignItems="center">
          {!loading && (
            <React.Fragment>
              <Grid item={true}>
                <DoneOutlineIcon fontSize="large" />
              </Grid>
              <Grid item={true}>
                <Typography variant="body1">You've successfully reviewed all the remaining photos.</Typography>
              </Grid>
            </React.Fragment>
          )}

          {loading && (
            <React.Fragment>
              <Grid item={true}>
                <CircularProgress color="secondary" />
              </Grid>
              <Grid item={true}>
                <Typography variant="body1">Harmonizing data</Typography>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
        <Button variant="outlined" color="secondary" startIcon={<RotateLeftIcon />} className={clsx(classes.dangerBtn, classes.resetBtnComplete)} onClick={this.onReset}>
          Reset
        </Button>
      </React.Fragment>
    );
  }
  renderGrid() {
    const { theme, classes, nearbyPlaces, rejectedSubmissions } = this.props;
    const { activeSub, activePlace } = this.state;
    let line = [];

    if (activeSub && activePlace) {
      line = [
        [activeSub.y_lat, activeSub.x_lon],
        [activePlace.y_lat, activePlace.x_lon]
      ];
    }

    return (
      <Grid container={true} className={classes.height100} spacing={2}>
        <Grid item={true} xs={4} className={classes.height100}>
          {this.renderSubmission()}
        </Grid>

        <Grid item={true} xs={4} className={classes.height100}>
          {this.renderNearbyPlaces()}
        </Grid>

        <Grid item={true} xs={4} className={classes.height100}>
          <ValidationMap
            submissions={rejectedSubmissions}
            activeSubmission={activeSub}
            places={activeSub ? nearbyPlaces : []}
            activePlace={activePlace}
            onClickPlace={this.mapPlaceClicked}
          >
            <Polyline positions={line} dashArray={'1 8'} color={theme.palette.error.main} />;
          </ValidationMap>
        </Grid>
      </Grid>
    );
  }
  render() {
    const { activeSub } = this.state;

    return (
      <React.Fragment>
        {this.renderToolbar()}
        {activeSub ? this.renderGrid() : this.renderComplete()}
      </React.Fragment>
    );
  }
}
//     <Grid container className={classes.mainGridContainer} spacing={2}>
//       </Grid>
//       <Grid item xs className={classes.height100}>
//       </Grid>
//     </Grid>
//   </Grid>
// </Grid>
//        <Grid item xs={4} className={classes.height100}>
//        </Grid>

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushSnackbar: pushSnackbarAction
    },
    dispatch
  );

SortRejected.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  // Callback functions
  onCancel: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,

  // Data
  rejectedSubmissions: PropTypes.array,
  nearbyPlaces: PropTypes.array,

  // Notifications
  pushSnackbar: PropTypes.func
};

SortRejected.defaultProps = {
  nearbyPlaces: [],
  rejectedSubmissions: []
};

const withRedux = connect(null, mapDispatchToProps, null, { forwardRef: true });
export default withRedux(withTheme(withStyles(styles)(SortRejected)));
