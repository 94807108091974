import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ChipPopover from './ChipPopover';
import VirtualizedAutocomplete from './radio/components/VirtualizedAutocomplete';

const styles = (theme) => ({
  box: {
    width: '30vw',
    padding: theme.spacing(1)
  }
});

class ChipPopoverVAC extends React.Component {
  render() {
    // eslint-disable-next-line no-unused-vars
    const { theme, classes, instructions, vacProps, options, value, disabled, ...other } = this.props;

    return (
      <ChipPopover disabled={disabled || !options || options.length === 0} {...other}>
        <Box className={classes.box}>
          {instructions && <Typography variant="body2">{instructions}</Typography>}
          <VirtualizedAutocomplete value={value} options={options} {...vacProps} />
        </Box>
      </ChipPopover>
    );
  }
}

ChipPopoverVAC.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  instructions: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  vacProps: PropTypes.object
};

ChipPopoverVAC.defaultProps = {
  vacProps: {},
  disabled: false
};

export default withTheme(withStyles(styles)(ChipPopoverVAC));
