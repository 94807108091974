// essentials
import { withStyles, withTheme } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// modules
import debounce from 'lodash.debounce';
import * as d3 from 'd3';
import { h3SetToMultiPolygon, geoToH3 } from 'h3-js';
import equal from 'deep-equal';

// ui/ux
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

// ours
import { TOP } from 'iris-config'; // eslint-disable-line import/no-unresolved
import { updateMapPosition } from '../../actions';

// leaflet
import { Map, TileLayer, Marker, LayersControl, GeoJSON } from 'react-leaflet';
import L from 'leaflet';

// GLOBALS
const MAX_FEATURES_RENDERED = 1000;

const styles = (theme) => ({
  map: {
    position: 'absolute',
    right: '0px',
    backgroundColor: theme.palette.background.default,
    height: `calc(100% - ${TOP}px)`, // Top + marginTop
    top: `${TOP}px`,
    '& .leaflet-bar a': {
      backgroundColor: theme.palette.primary.main,
      borderBottom: `1px solid ${theme.palette.text.primary}`,
      color: theme.palette.text.primary
    },
    '& .leaflet-bar a:last-child': {
      borderBottom: 'none'
    }
  },
  popupImage: {
    maxWidth: '100%',
    maxHeight: '300px'
  },
  mapMarker: {
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main
  },
  progress: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

class LeafletMap extends React.Component {
  constructor(props) {
    super(props);

    this._leafletMapRef = React.createRef();
    this.onMoveEndThrottled = debounce(this.onMoveEnd.bind(this), 1000); // , { trailing: true, leading: false });
    this.icon = L.icon({
      iconUrl: '/images/marker-red-high.png',
      iconSize: [16, 16], // size of the icon
      iconAnchor: [8, 16] // point of the icon which will correspond to marker's location
    });
    this.iconSelected = L.icon({
      iconUrl: '/images/marker-green-high.png',
      iconSize: [16, 16], // size of the icon
      iconAnchor: [8, 16] // point of the icon which will correspond to marker's location
    });
    this.iconHover = L.icon({
      iconUrl: '/images/marker-blue-high.png',
      iconSize: [16, 16], // size of the icon
      iconAnchor: [8, 16] // point of the icon which will correspond to marker's location
    });
    this.submissionIcon = L.icon({
      iconUrl: '/images/marker-sub.png',
      iconSize: [6, 6], // size of the icon
      iconAnchor: [3, 6] // point of the icon which will correspond to marker's location
      // popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });
    this.pinIcon = L.icon({
      iconUrl: '/images/marker-pin-leaflet.png',
      iconSize: [12, 32],
      iconAnchor: [6, 32]
    });

    this.onBaselayerchange = this.onBaselayerchange.bind(this);

    this.state = { currentBasemap: 'Mapbox // Streets' };
  }

  fitBounds({ minx, maxx, miny, maxy }) {
    this._leafletMapRef.current &&
      this._leafletMapRef.current.leafletElement.fitBounds([
        [miny, minx],
        [maxy, maxx]
      ]);
  }

  setView(x_lon, y_lat, z) {
    this._leafletMapRef.current && this._leafletMapRef.current.leafletElement.setView([y_lat, x_lon], z || 14);
  }
  componentWillUnmount() {
    this.onMoveEndThrottled.cancel();
  }
  componentDidUpdate(prevProps) {
    if (this._leafletMapRef.current && prevProps.left !== this.props.left) {
      this._leafletMapRef.current.leafletElement.invalidateSize();
    }

    // const { selectedPlace } = this.props;
    // const { selectedPlace: prevSelectedPlace } = prevProps;

    // if (selectedPlace !== prevSelectedPlace && selectedPlace) {
    //   this._leafletMapRef.current && this._leafletMapRef.current.leafletElement.setView([selectedPlace.y_lat, selectedPlace.x_lon], Math.max(14, this.props.mapZoom));
    // }
    this.onBaselayerchange();
  }
  componentDidMount() {
    this.onMoveEndThrottled();
  }
  onMoveEnd() {
    if (!this._leafletMapRef.current) return;
    const bounds = this._leafletMapRef.current.leafletElement.getBounds();
    const center = this._leafletMapRef.current.leafletElement.getCenter();
    const mapZoom = this._leafletMapRef.current.leafletElement.getZoom();
    const minx = bounds.getWest();
    const miny = bounds.getSouth();
    const maxx = bounds.getEast();
    const maxy = bounds.getNorth();

    const mapCenter = {
      x_lon: center.lng,
      y_lat: center.lat
    };

    const mapPolygon = {
      nw: [minx, maxy],
      ne: [maxx, maxy],
      se: [maxx, miny],
      sw: [minx, miny]
    };

    // don't rebroadcast needlessly:
    //   - caused by geojson vector layer changes
    if (equal(mapPolygon, this.props.mapPolygon)) {
      return;
    }

    this.props.updateMapPosition({ minx, miny, maxx, maxy }, mapCenter, mapZoom, mapPolygon);
  }
  renderDroppedPin() {
    const { dropPin } = this.props;
    if (!dropPin) return null;
    return dropPin.map(({ lat, lon }) => <Marker key={`${lat}-${lon}`} zIndexOffset={100} position={[lat, lon]} icon={this.pinIcon} />);
  }
  renderSubmissionMarkers() {
    const { submissions, selectedPlace } = this.props;
    if (!submissions || !selectedPlace || submissions.length === 0) return null;

    if (submissions.length > 100) {
      const hashes = [];
      for (const s of submissions) {
        const h = geoToH3(s.y_lat, s.x_lon, 13);
        if (!hashes.includes(h)) hashes.push(h);
      }

      const geojson = {
        type: 'MultiPolygon',
        coordinates: h3SetToMultiPolygon(hashes, true)
      };

      return <GeoJSON zIndexOffset={10} key={'sub-hex-' + selectedPlace.place_id} data={geojson} style={{ stroke: false, fillColor: 'rgb(243, 150, 31)', fillOpacity: 0.8 }} />;
    } else {
      return submissions.map((d) => <Marker zIndexOffset={10} key={d.sub_id} position={[d.y_lat, d.x_lon]} icon={this.submissionIcon} />);
    }
  }
  renderPlaces() {
    const { placesPointData, selectedPlace, hoverPlaceId, placesHexData, dataKey } = this.props;

    if (placesHexData && placesHexData.length > 0) {
      const geojson = {
        type: 'MultiPolygon',
        coordinates: h3SetToMultiPolygon(
          placesHexData.map((d) => d.hash),
          true
        )
      };

      return <GeoJSON key={dataKey} data={geojson} style={{ stroke: false, fillColor: 'rgb(224, 78, 57)', fillOpacity: 1.0 }} />;
    }

    // too many features, won't render:
    if (!placesPointData || placesPointData.length > MAX_FEATURES_RENDERED) {
      // render the selected place, if we have one:
      if (!selectedPlace) return null;

      return <Marker zIndexOffset={0} key={selectedPlace.place_id} position={[selectedPlace.y_lat, selectedPlace.x_lon]} icon={this.iconSelected} />;
    }

    if (!placesPointData) return null;

    return placesPointData.map((d) => {
      let icon = this.icon;
      let zIndexOffset = 20;

      if (selectedPlace && selectedPlace.place_id === d.id) {
        icon = this.iconSelected;
        zIndexOffset = 40;
      }
      if (hoverPlaceId && hoverPlaceId === d.id) {
        icon = this.iconHover;
        zIndexOffset = 80;
      }

      return (
        <Marker
          zIndexOffset={zIndexOffset}
          key={d.id}
          position={[d.lat, d.lon]}
          icon={icon}
          onClick={() => {
            if (this.props.onSelect) {
              this.props.onSelect(d.id);
            }
          }}
        />
      );
    });
  }

  onBaselayerchange(e) {
    const { mapZoom } = this.props;
    const { currentBasemap } = this.state;
    const name = e ? e.name : currentBasemap;

    // let leafletAttrColor;
    // if (currentViewFeatureCount > MAX_FEATURES_RENDERED) {
    //   leafletAttrColor = 'red';
    // } else if (currentViewFeatureCount > MAX_FEATURES_RENDERED * 0.6) {
    //   leafletAttrColor = '#c62828';
    // } else {
    //   leafletAttrColor = 'black';
    // }

    const div = d3.select('div.leaflet-control-attribution.leaflet-control').classed('ok', true).html('');

    div.append('span').text(`Zoom @ ${mapZoom}`);
    div.append('span').text(' | ');
    // div.append('span').style('color', leafletAttrColor).text(`~${currentViewFeatureCount} Features`);
    // div.append('span').text(' | ');

    if (name.toLowerCase().includes('mapbox')) {
      div.append('a').attr('href', 'https://www.mapbox.com/feedback/').text('© Mapbox');
      div.append('span').text(' | ');
      div.append('a').attr('href', 'http://www.openstreetmap.org/copyright').text('OpenStreetMap');
    } else if (name.toLowerCase().includes('google')) {
      div.append('span').text('Map data ©2019 ');
      div.append('a').attr('href', 'https://google.com/').text('Google');
    }
    div.append('span').text(' | ');
    div.append('a').attr('href', 'https://leafletjs.com').attr('title', 'A JS library for interactive maps').text('Leaflet');

    if (e) this.setState({ currentBasemap: name });
  }

  render() {
    // ui / ux
    const { classes, theme, dataLoading, left, mapboxAccessToken /* currentViewFeatureCount */ } = this.props;
    const mapStyle = {
      width: `calc(100% - ${left}px)`,
      left: left
    };

    // data
    const { mapZoom, mapCenter } = this.props;
    const position = [mapCenter.y_lat, mapCenter.x_lon];

    const url = `https://api.mapbox.com/styles/v1/mapbox/${theme.palette.type}-v10/tiles/{z}/{x}/{y}?access_token=${mapboxAccessToken}`;
    return (
      <React.Fragment>
        <Map
          ref={this._leafletMapRef}
          className={classes.map}
          zoom={mapZoom}
          center={position}
          style={mapStyle}
          onmoveend={this.onMoveEndThrottled}
          zoomControl={false}
          onBaselayerchange={this.onBaselayerchange}
        >
          <LayersControl position="bottomleft">
            <LayersControl.BaseLayer name={'Mapbox // Streets'} checked={true}>
              <TileLayer url={url} />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name={'Google // Satellite'}>
              <TileLayer url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" subdomains={['mt0', 'mt1', 'mt2', 'mt3']} maxZoom={20} />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name={'Google // Satellite Hybrid'}>
              <TileLayer url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" subdomains={['mt0', 'mt1', 'mt2', 'mt3']} maxZoom={20} />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name={'Google // Terrain'}>
              <TileLayer url="http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}" subdomains={['mt0', 'mt1', 'mt2', 'mt3']} maxZoom={20} />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name={'ESRI // Satellite'}>
              <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" maxZoom={17} />
            </LayersControl.BaseLayer>
          </LayersControl>
          {this.renderSubmissionMarkers()}
          {this.renderPlaces()}
          {this.renderDroppedPin()}
        </Map>
        <Backdrop open={dataLoading} style={{ position: 'absolute', left: `${mapStyle.left}px`, right: '0px', top: `${TOP}px`, zIndex: 1001, color: '#fff' }}>
          <Box className={classes.progress}>
            <CircularProgress
              size={50}
              color="secondary"
              style={{
                display: !dataLoading ? 'none' : null
              }}
            />
          </Box>
        </Backdrop>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  mapboxAccessToken: state.app.mapboxAccessToken,
  mapZoom: state.app.mapZoom,
  mapBounds: state.app.mapBounds,
  mapCenter: state.app.mapCenter,
  mapPolygon: state.app.mapPolygon,
  dropPin: state.app.dropPin
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateMapPosition: (mapBounds, mapCenter, mapZoom, mapPolygon) => dispatch(updateMapPosition(mapBounds, mapCenter, mapZoom, mapPolygon))
  };
};

LeafletMap.propTypes = {
  classes: PropTypes.object.isRequired, // material-ui
  theme: PropTypes.object.isRequired, // material-ui

  // ui / ux
  left: PropTypes.number.isRequired,

  // data
  dataLoading: PropTypes.bool,
  hoverPlaceId: PropTypes.string,
  placesPointData: PropTypes.array,
  selectedPlace: PropTypes.object,
  submissions: PropTypes.array,
  placesHexData: PropTypes.array,
  dataKey: PropTypes.number,
  mapCenter: PropTypes.object,
  mapPolygon: PropTypes.object,
  currentViewFeatureCount: PropTypes.number,
  mapboxAccessToken: PropTypes.string,
  updateMapPosition: PropTypes.func,
  mapZoom: PropTypes.number,
  dropPin: PropTypes.array,

  // callbacks
  onSelect: PropTypes.func
};

LeafletMap.defaultProps = {};
const withRedux = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
export default withRedux(withTheme(withStyles(styles)(LeafletMap)));
