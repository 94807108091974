import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// MODULES
import { AutoSizer } from 'react-virtualized';

// UI/UX
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

// OURS
import { TOP } from 'iris-config'; // eslint-disable-line import/no-unresolved
import TopBarSide from './TopBarSide';

import {
  resetQAFilterSelectionsRight,
  setQAFilterSelectedAnswersRight,
  setQAFilterSelectedFormRight,
  setQAFilterSelectedQuestionRight,
  resetQAFilterSelectionsLeft,
  setQAFilterSelectedAnswersLeft,
  setQAFilterSelectedFormLeft,
  setQAFilterSelectedQuestionLeft,
  updateDemographicsLeft,
  updateDemographicsRight,
  updateSelectedDateRangeLeft,
  updateSelectedDateRangeRight
} from './sentimentSlice';

// GLOBALS
const TOPBAR_HEIGHT = 48;

const useStyles = makeStyles((/* theme */) => ({
  box: {
    position: 'absolute',
    right: 0,
    height: TOPBAR_HEIGHT,
    top: TOP
  },
  item: {
    position: 'relative',
    height: TOPBAR_HEIGHT
  }
}));

const TopBar = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();

  //  ___      _______  _______  _______
  // |   |    |       ||       ||       |
  // |   |    |    ___||    ___||_     _|
  // |   |    |   |___ |   |___   |   |
  // |   |___ |    ___||    ___|  |   |
  // |       ||   |___ |   |      |   |
  // |_______||_______||___|      |___|
  // REDUX
  const QAFilterSelectedAnswersLeft = useSelector((state) => state.sentiment.QAFilterSelectedAnswersLeft);
  const QAFilterSelectedFormLeft = useSelector((state) => state.sentiment.QAFilterSelectedFormLeft);
  const QAFilterSelectedQuestionLeft = useSelector((state) => state.sentiment.QAFilterSelectedQuestionLeft);
  const selectedDateRangeLeft = useSelector((state) => state.sentiment.selectedDateRangeLeft);
  const selectedDemographicsLeft = useSelector((state) => state.sentiment.selectedDemographicsLeft);

  const onUpdateDemographicLeft = useCallback((enabled, datum) => {
    const { answer, demographic } = datum;
    dispatch(updateDemographicsLeft({ enabled, answer, demographic: demographic.variable }));
  }, [dispatch]);
  const onSelectedDateRangeChangedLeft = useCallback((selectedDates) => {
    dispatch(updateSelectedDateRangeLeft(selectedDates));
  }, [dispatch]);

  const onQAFilterSelectedFormLeftChanged = useCallback((form) => {
    dispatch(setQAFilterSelectedFormLeft(form));
  }, [dispatch]);
  const onQAFilterSelectedQuestionLeftChanged = useCallback((question) => {
    dispatch(setQAFilterSelectedQuestionLeft(question));
  }, [dispatch]);
  const onQAFilterSelectedAnswersLeftChanged = useCallback((answers) => {
    dispatch(setQAFilterSelectedAnswersLeft(answers));
  }, [dispatch]);
  const onQAFilterResetLeft = useCallback(() => {
    dispatch(resetQAFilterSelectionsLeft());
  }, [dispatch]);

  //  ______    ___   _______  __   __  _______
  // |    _ |  |   | |       ||  | |  ||       |
  // |   | ||  |   | |    ___||  |_|  ||_     _|
  // |   |_||_ |   | |   | __ |       |  |   |
  // |    __  ||   | |   ||  ||       |  |   |
  // |   |  | ||   | |   |_| ||   _   |  |   |
  // |___|  |_||___| |_______||__| |__|  |___|
  const QAFilterSelectedAnswersRight = useSelector((state) => state.sentiment.QAFilterSelectedAnswersRight);
  const QAFilterSelectedFormRight = useSelector((state) => state.sentiment.QAFilterSelectedFormRight);
  const QAFilterSelectedQuestionRight = useSelector((state) => state.sentiment.QAFilterSelectedQuestionRight);
  const selectedDateRangeRight = useSelector((state) => state.sentiment.selectedDateRangeRight);
  const selectedDemographicsRight = useSelector((state) => state.sentiment.selectedDemographicsRight);

  const onUpdateDemographicRight = useCallback((enabled, datum) => {
    const { answer, demographic } = datum;
    dispatch(updateDemographicsRight({ enabled, answer, demographic: demographic.variable }));
  }, [dispatch]);
  const onSelectedDateRangeChangedRight = useCallback((selectedDates) => {
    dispatch(updateSelectedDateRangeRight(selectedDates));
  }, [dispatch]);
  const onQAFilterSelectedFormRightChanged = useCallback((form) => {
    dispatch(setQAFilterSelectedFormRight(form));
  }, [dispatch]);
  const onQAFilterSelectedQuestionRightChanged = useCallback((question) => {
    dispatch(setQAFilterSelectedQuestionRight(question));
  }, [dispatch]);
  const onQAFilterSelectedAnswersRightChanged = useCallback((answers) => {
    dispatch(setQAFilterSelectedAnswersRight(answers));
  }, [dispatch]);
  const onQAFilterResetRight = useCallback(() => {
    dispatch(resetQAFilterSelectionsRight());
  }, [dispatch]);

  // LOCALS
  const { left } = props;
  return (
    <Box className={classes.box} style={{ left }}>
      <AutoSizer disableHeight={true}>
        {({ width }) => (
          <Grid container={true} direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ width }}>
            <Grid item={true} xs={6} className={classes.item}>
              <TopBarSide
                width={width / 2 - theme.spacing(4)}
                maxHeight={TOPBAR_HEIGHT - theme.spacing(2)}
                //
                selectedDateRange={selectedDateRangeLeft}
                selectedDemographics={selectedDemographicsLeft}
                selectedQAFilterForm={QAFilterSelectedFormLeft}
                selectedQAFilterQuestion={QAFilterSelectedQuestionLeft}
                selectedQAFilterAnswers={QAFilterSelectedAnswersLeft}
                //
                onSelectedDateRangeChanged={onSelectedDateRangeChangedLeft}
                onUpdateDemographic={onUpdateDemographicLeft}
                onQAFilterSelectedFormChanged={onQAFilterSelectedFormLeftChanged}
                onQAFilterSelectedQuestionChanged={onQAFilterSelectedQuestionLeftChanged}
                onQAFilterSelectedAnswersChanged={onQAFilterSelectedAnswersLeftChanged}
                onQAFiltersReset={onQAFilterResetLeft}
              />
            </Grid>
            <Grid item={true} xs={6} className={classes.item}>
              <TopBarSide
                width={width / 2 - theme.spacing(4)}
                maxHeight={TOPBAR_HEIGHT - theme.spacing(2)}
                //
                selectedDateRange={selectedDateRangeRight}
                selectedDemographics={selectedDemographicsRight}
                // use left here:
                selectedQAFilterForm={QAFilterSelectedFormRight}
                selectedQAFilterQuestion={QAFilterSelectedQuestionRight}
                selectedQAFilterAnswers={QAFilterSelectedAnswersRight}
                //
                onSelectedDateRangeChanged={onSelectedDateRangeChangedRight}
                onUpdateDemographic={onUpdateDemographicRight}
                // use left here:
                onQAFilterSelectedFormChanged={onQAFilterSelectedFormRightChanged}
                onQAFilterSelectedQuestionChanged={onQAFilterSelectedQuestionRightChanged}
                onQAFilterSelectedAnswersChanged={onQAFilterSelectedAnswersRightChanged}
                onQAFiltersReset={onQAFilterResetRight}
              />
            </Grid>
          </Grid>
        )}
      </AutoSizer>
    </Box>
  );
};

TopBar.propTypes = {
  left: PropTypes.number
};
TopBar.defaultProps = {
  left: 0
};

export default React.memo(TopBar);
