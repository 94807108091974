import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// UI/UX
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

// ICONS
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';

const styles = (theme) => ({
  marginLeft: {
    marginLeft: theme.spacing(1)
  },
  textfield: {
    width: 'calc(100% - 76px)'
  }
});

class EditTextField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'display'
    };

    this.onEdit = this.onEdit.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        mode: 'display'
      });
    }
  }
  onEdit() {
    this.setState({
      mode: 'edit',
      value: this.props.value
    });
  }
  onSave() {
    this.props.onChange(this.state.value);
  }
  onChange(e) {
    this.setState({
      value: e.target.value
    });
  }
  onKeyDown(e) {
    if (e.keyCode === 13) {
      this.onSave();
    }
  }
  onCancel() {
    this.setState({
      mode: 'display'
    });
  }
  render() {
    const { classes, disabled } = this.props;
    const { mode } = this.state;

    if (mode === 'display') {
      return (
        <React.Fragment>
          {this.props.value}
          <IconButton disabled={disabled} size="small" className={classes.marginLeft} onClick={this.onEdit}>
            <EditIcon />
          </IconButton>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <TextField disabled={disabled} autoFocus={true} className={classes.textfield} size="small" value={this.state.value} onChange={this.onChange} onKeyDown={this.onKeyDown} />
          <IconButton size="small" onClick={this.onSave} disabled={this.state.value === this.props.value || disabled || this.state.value.length === 0}>
            <CheckIcon />
          </IconButton>
          <IconButton size="small" onClick={this.onCancel} disabled={disabled}>
            <CancelIcon />
          </IconButton>
        </React.Fragment>
      );
    }
  }
}

EditTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

EditTextField.defaultProps = {
  value: '',
  disabled: false
};

export default withTheme(withStyles(styles)(EditTextField));
