import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// MODULES
import { capitalCase } from 'change-case';

// UI/UX
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// ICONS
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import { API_HOST, genericPost } from 'iris-api'; // eslint-disable-line import/no-unresolved
import { pushSnackbar as pushSnackbarAction } from '../../../actions';
import { toggleFilterQAOR as toggleFilterQAORAction } from '../placesSlice';

const styles = (theme) => ({
  qaChip: {
    minHeight: 32,
    height: 'fit-content',
    padding: theme.spacing(1.5),
    maxWidth: '100%',
    borderRadius: 32
  },
  qaChipLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  stickyListSubHeader: {
    backgroundColor: theme.palette.background.paper
  },
  wrappedMenuItem: {
    whiteSpace: 'pre-wrap',
    maxWidth: 600
  },
  container: {
    '& > div': {
      marginBottom: theme.spacing(1.5)
    }
  }
});

class QAFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedQuestion: '',
      availableQuestions: null,
      // availableAnswers: []
      selectedAnswers: [],
      pairs: props.qa ?? []
    };

    // XHR
    this._xhrQuestions = {};

    // BIND
    this.onQuestionChanged = this.onQuestionChanged.bind(this);
    this.onAnswersChanged = this.onAnswersChanged.bind(this);
    this.clear = this.clear.bind(this);
    this.cancel = this.cancel.bind(this);
    this.commit = this.commit.bind(this);
  }
  componentWillUnmount() {
    this._xhrQuestions.cancel && this._xhrQuestions.cancel();
  }
  componentDidMount() {
    this.componentDidUpdate({}, {});
  }
  componentDidUpdate(prevProps) {
    const { selectedCategories, pushSnackbar } = this.props;
    const { selectedCategories: psc } = prevProps;

    if (psc !== selectedCategories) {
      if (selectedCategories.length > 0) {
        genericPost(`${API_HOST}/places/v0/metadata/questions`, { topics: selectedCategories.map((d) => d.topic) }, this._xhrQuestions, (e, availableQuestions) => {
          if (e) {
            return pushSnackbar({ type: 'error', message: 'Failed to fetch questions for selected topics.' });
          }

          this.setState({
            availableQuestions,
            availableQuestionsByTopicQuestion: availableQuestions.reduce((a, b) => {
              const topic = b.topic;
              const existing = a[topic] ? { ...a[topic], [b.id]: b } : { [b.id]: b };
              return {
                ...a,
                [topic]: existing
              };
            }, {}),
            availableQuestionsByTopic: availableQuestions.reduce((a, b) => {
              const topic = b.topic;
              const existing = a[topic];
              const v = existing ? [...existing, b] : [b];
              return {
                ...a,
                [topic]: v
              };
            }, {})
          });
        });
      } else {
        this.setState({
          availableQuestions: [],
          availableQuestionsByTopic: null
        });
      }
    }
  }
  onQuestionChanged(e) {
    const selectedQuestion = e.target.value;
    this.setState({ selectedQuestion, selectedAnswers: [], availableAnswers: null });

    if (selectedQuestion) {
      const { selectedCategories } = this.props;
      genericPost(
        `${API_HOST}/places/v0/metadata/answers`,
        { topics: selectedCategories.map((d) => d.topic), question: selectedQuestion.id },
        this._xhrLoadAnswers,
        (e, availableAnswers) => {
          if (e) return this.props.pushSnackbar({ type: 'error', message: 'Failed to load answers for this question.' });
          this.setState({ availableAnswers });
        }
      );
    }
  }
  onAnswersChanged(_, selectedAnswers) {
    this.setState(
      {
        selectedAnswers
      },
      this.commit
    );
  }
  cancel() {
    this.setState({
      pairs: this.props.qa ?? []
    });
  }
  commit() {
    const { pairs, selectedQuestion, selectedAnswers } = this.state;

    // make a copy of `pairs` so we can edit it:
    let newPairs = [...pairs];

    // if a question is selected, remove it:
    if (selectedQuestion) {
      newPairs = newPairs.filter((q) => !(selectedQuestion.topic === q.t && selectedQuestion.id === q.q));
    }

    // if we have answers for this question, add it back:
    if (selectedQuestion && selectedAnswers && selectedAnswers.length > 0) {
      newPairs.push({
        t: selectedQuestion.topic,
        q: selectedQuestion.id,
        a: selectedAnswers
      });
    }

    this.setState({
      pairs: newPairs
    });

    this.props.onChange([...newPairs]);
  }
  clear() {
    this.setState({
      selectedQuestion: '',
      selectedAnswers: [],
      pairs: []
    });

    this.props.onChange([]);
  }
  render() {
    const { theme, classes, selectedCategories, filterByQuestionAndAnswerOR, toggleFilterQAOR } = this.props;
    const { selectedQuestion, availableAnswers, selectedAnswers, availableQuestionsByTopic, availableQuestionsByTopicQuestion, pairs } = this.state;

    return (
      <React.Fragment>
        <Box className={classes.container}>
          <Typography variant="caption" component="div">
            When filtering places by topics, you can choose to filter places also by multiple pairs of a question and answer(s).
          </Typography>

          <Box>
            <FormControl variant="standard" fullWidth={true} disabled={!availableQuestionsByTopic}>
              <InputLabel id="places-qa-label" variant="standard">
                Questions related to selected topic(s)
              </InputLabel>
              <Select label="Question" labelid="places-qa-label" id="places-qa-sel" value={selectedQuestion} onChange={this.onQuestionChanged}>
                {availableQuestionsByTopic &&
                  selectedCategories.map(({ topic }) => [
                    <ListSubheader className={classes.stickyListSubHeader}>{capitalCase(topic)}</ListSubheader>,
                    availableQuestionsByTopic[topic] &&
                      availableQuestionsByTopic[topic].map((d) => (
                        <MenuItem key={d.id} value={d}>
                          <Typography variant="inherit" className={classes.wrappedMenuItem}>
                            {d.question}
                          </Typography>
                        </MenuItem>
                      ))
                  ])}
              </Select>
            </FormControl>
          </Box>

          <Box>
            <Autocomplete
              multiple={true}
              id="places-qa-ac"
              options={availableAnswers ?? []}
              disabled={!availableAnswers}
              disableCloseOnSelect={true}
              onChange={this.onAnswersChanged}
              value={selectedAnswers}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checked={selected} style={{ marginRight: 8 }} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                  <Typography variant="inherit" className={classes.wrappedMenuItem}>
                    {option}
                  </Typography>
                </React.Fragment>
              )}
              renderInput={(params) => <TextField {...params} label="Answer(s)" variant="standard" fullWidth={true} />}
            />
          </Box>
          <Box>
            <FormControlLabel control={<Checkbox checked={filterByQuestionAndAnswerOR} onChange={toggleFilterQAOR} color="secondary" />} label="Any matches" />
          </Box>
        </Box>
        <List>
          {pairs &&
            availableQuestionsByTopicQuestion &&
            pairs.map((d) => (
              <ListItem key={`${d.t}-${d.q}`}>
                <Chip
                  label={
                    <React.Fragment>
                      <Typography variant="overline" style={{ lineHeight: 2 }}>
                        {capitalCase(d.t)}
                      </Typography>
                      <Typography variant="body2" className={classes.qaChipLabel}>
                        {availableQuestionsByTopicQuestion[d.t][d.q].question}
                      </Typography>
                      <Typography variant="body2" style={{ color: theme.palette.text.secondary }}>
                        {d.a.join(', ')}
                      </Typography>
                    </React.Fragment>
                  }
                  onDelete={() => {
                    const idx = pairs.indexOf(d);
                    this.setState((s) => {
                      const removedPair = s.pairs[idx];
                      const newState = {
                        pairs: [...s.pairs.slice(0, idx), ...s.pairs.slice(idx + 1)]
                      };
                      if (removedPair.q === s.selectedQuestion.id) {
                        newState.selectedQuestion = '';
                        newState.selectedAnswers = [];
                      }
                      this.props.onChange(newState.pairs);
                      return newState;
                    });
                  }}
                  className={classes.qaChip}
                />
              </ListItem>
            ))}
        </List>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  filterByQuestionAndAnswerOR: state.places.filterByQuestionAndAnswerOR
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushSnackbar: pushSnackbarAction,
      toggleFilterQAOR: toggleFilterQAORAction
    },
    dispatch
  );
QAFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  pushSnackbar: PropTypes.func.isRequired,
  filterByQuestionAndAnswerOR: PropTypes.bool,
  toggleFilterQAOR: PropTypes.func.isRequired,

  // callbacks
  onChange: PropTypes.func.isRequired,

  // data in:
  selectedCategories: PropTypes.array,
  qa: PropTypes.array
};
QAFilter.defaultProps = {};
const withRedux = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: false });
export default withRedux(withTheme(withStyles(styles)(QAFilter)));
