import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import debounce from 'lodash.debounce';

import { usePrevious } from 'iris-util'; // eslint-disable-line import/no-unresolved

const DebounceRender = (props) => {
  const { children, ...other } = props;

  const [render, setRender] = useState(true);

  const resumeRender = useMemo(
    () => debounce(() => {
      setRender(true);
    }, 250),
    []
  );

  useEffect(() => {
    return () => {
      resumeRender.cancel();
    };
  }, [resumeRender]);

  // do shallow equal against all other props:
  const prevOther = usePrevious(other);

  if (render && prevOther) {
    for (const key in other) {
      if (prevOther[key] !== other[key]) {
        setRender(false);
        resumeRender();
        return null;
      }
    }
  }
  if (!render) {
    resumeRender();
  }
  if (!render) return null;
  return children;
};

DebounceRender.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};
DebounceRender.defaultProps = {};
export default React.memo(DebounceRender);
