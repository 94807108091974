import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import amplitude from 'amplitude-js';

// MODULE
import debounce from 'lodash.debounce';

// MUI
import Box from '@material-ui/core/Box';

// REDUX
import { getAvailableForms, getAvailableGeographies, getAvailableQuestions, getAlerts } from '../../services';
import { selectGeographies, replaceQuestions, setAlertSort, setAlertDataRecency, toggleBool, selectAlert } from '../../sentimentSlice';

import { pushSnackbar } from '../../../../actions';

// OURS
import { SentimentAlertPaper } from '@premisedata/lib-iris-common';
import ChipBoolean from '../../../ChipBoolean';
import ChipPopover from '../../../ChipPopover';
import ChipPopoverRadioInstant from '../../../ChipPopoverRadioInstant';
import ChipPopoverVAC from '../../../ChipPopoverVAC';
import SortOption from './SortOption';
import VACGeographies from '../../components/config/VACGeographies';
import VirtualizedList from '../../../VirtualizedList';

const useStyles = makeStyles((theme) => ({
  loadingBox: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: alpha(theme.palette.background.default, 0.5)
  },
  filters: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(2, 1, 1, 1),
    flexFlow: 'row wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  results: {
    position: 'relative',
    display: 'flex',
    flexGrow: 1
  }
}));
const AlertListConstants = {
  chipPopoverStyle: { display: 'flex', flexFlow: 'column wrap' },
  dataRecencyOptions: [7, 14, 30, 90]
};
const AlertList = () => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();

  // REF
  const refVirtualList = useRef();
  const firstUpdate = useRef(true);

  // STATE
  const [filterForms, setFilterForms] = useState([]);
  const [filterQuestions, setFilterQuestions] = useState([]);

  // REDUX
  const alertAutoSetGeography = useSelector((state) => state.sentiment.alertAutoSetGeography);
  const alertAutoSetGeographyDefault = useSelector((state) => state.sentiment.alertAutoSetGeographyDefault);
  const alertDataRecency = useSelector((state) => state.sentiment.alertDataRecency);
  const alertDataRecencyDefault = useSelector((state) => state.sentiment.alertDataRecencyDefault);
  const alertSort = useSelector((state) => state.sentiment.alertSort);
  const alertSortDefault = useSelector((state) => state.sentiment.alertSortDefault);
  const alertSortDirection = useSelector((state) => state.sentiment.alertSortDirection);
  const alertSortDirectionDefault = useSelector((state) => state.sentiment.alertSortDirectionDefault);
  const selectedAlert = useSelector((state) => state.sentiment.selectedAlert);
  const selectedGeographies = useSelector((state) => state.sentiment.selectedGeographies);
  const selectedQuestions = useSelector((state) => state.sentiment.selectedQuestions);
  const selectedForms = useSelector((state) => state.sentiment.selectedForms);

  // QUERY
  const availableGeographies = getAvailableGeographies.useQuery();
  const availableForms = getAvailableForms.useQuery({ selectedGeographies, withAlertsOnly: true });
  const availableQuestions = getAvailableQuestions.useQuery(filterForms, { skip: filterForms.length === 0 });
  const availableQuestionsGlobal = getAvailableQuestions.useQuery(selectedForms, { skip: selectedForms.length === 0 });
  const alerts = getAlerts.useQuery({ alertSort, alertSortDirection, alertDataRecency, selectedGeographies, selectedForms: filterForms, selectedQuestions: filterQuestions });

  // USECALLBACK
  const onFilterForms = useMemo(
    () => debounce((_, forms) => {
      setFilterForms(forms);
    }, 333), []
  );
  const onFilterQuestions = useMemo(
    () => debounce((_, questions) => {
      setFilterQuestions(questions);
    }, 333), []
  );

  // {
  //     "form_name": "HADR: Baseline",
  //     "form_id": 4894188987219968,
  //     "question_proper": "In your community, does foreign aid/international assistance provide any services not offered by your government?",
  //     "question_name": "in_your_community_does_foreign_aid_international_assistance_provide_any_services_not_offered_by_your_government",
  //     "hasc_code": "MY",
  //     "hasc_proper": "Malaysia",
  //     "num_alerts": 4,
  //     "alert_level": "ci95_alert",
  //     "iconIndex": 2,
  //     "key": "3fb11acc9fcc917544410f2e00614bc5593949b723064e43ce4c7f967e6b1c5b"
  // }
  const onSelectAlert = useCallback(
    (selectedAlert) => {
      if (!availableForms.data) return console.warn('AlertList::onSelectAlert(): forms unavailable, unable to complete request:', availableForms.data);
      const payload = { selectedAlert };
      const form_id = selectedAlert.form_id.toString();
      const forms = availableForms.data.filter((d) => d.form_id === form_id);
      if (forms.length === 0) return console.warn("AlertList::onSelectAlert(): forms don't match, unable to complete request");
      payload.selectedForms = forms;
      payload.polygonRenderLevel = selectedAlert.hasc_code.split('.').length - 1;
      if (alertAutoSetGeography && availableGeographies.data) {
        const hasc_code = selectedAlert.hasc_code.split('.').slice(0, 2).join('.');
        const matchingGeography = availableGeographies.data.filter((d) => d.hasc === hasc_code);
        if (matchingGeography.length > 0) {
          payload.selectedGeographies = matchingGeography;
        }
      }

      amplitude.getInstance().logEvent('selection', {
        product: 'sentiment',
        selection: 'alert',
        form_id: selectedAlert.form_id,
        question_name: selectedAlert.question_name,
        hasc_code: selectedAlert.hasc_code
      });

      dispatch(selectAlert(payload));
    },
    [availableForms.data, availableGeographies.data, alertAutoSetGeography, dispatch]
  );
  const renderAlert = useCallback(
    ({ index, key, style }) => {
      return <SentimentAlertPaper active={selectedAlert?.key === alerts.data[index].key} style={style} key={key} alert={alerts.data[index]} onClick={onSelectAlert} />;
    },
    [alerts, selectedAlert, onSelectAlert]
  );
  const onGeographiesChanged = useCallback((geographies) => {
    dispatch(selectGeographies(geographies));
  }, [dispatch]);
  const onSortChanged = useCallback((alertSort, alertSortDirection) => {
    dispatch(setAlertSort({ alertSort, alertSortDirection }));
  }, [dispatch]);
  const renderDataRecencyOption = useCallback((d) => `${d} days`, []);
  const onFilterDataRecency = useCallback((recency) => {
    dispatch(setAlertDataRecency(+recency));
  }, [dispatch]);
  const onAutoGeographyChange = useCallback(() => {
    dispatch(toggleBool('alertAutoSetGeography'));
  }, [dispatch]);

  // MEMO
  const vacFormProps = useMemo(
    () => ({
      getOptionLabel: (form) => `${form.form_name} (${form.form_id})`,
      onChange: onFilterForms,
      limitTags: -1,
      label: 'Form(s)',
      placeholder: 'Community Safety'
    }),
    [onFilterForms]
  );
  const vacQuestionProps = useMemo(
    () => ({
      getOptionLabel: (question) => question.question_proper,
      onChange: onFilterQuestions,
      limitTags: -1,
      label: 'Questions(s)',
      placeholder: 'How safe is this area?'
    }),
    [onFilterQuestions]
  );

  // USEEFFECT
  useEffect(() => {
    if (firstUpdate) firstUpdate.current = false;
    else refVirtualList.current?.forceUpdateGrid();
  }, [selectedAlert]);

  // JANKY:
  // we do not have the question data we need when selecting an alert,
  // because selecting an alert means selecting a form + question.
  //
  // this functions waits for the incoming question data before
  // updating the selected question:
  useEffect(() => {
    if (selectedAlert && availableQuestionsGlobal.data) {
      if (!selectedQuestions.map((d) => d.question_name).includes(selectedAlert.question_name)) {
        const replacementQuestions = availableQuestionsGlobal.data.filter((d) => d.question_name === selectedAlert.question_name);

        if (replacementQuestions.length > 0) {
          // if the questions are available for this form & alert,
          // update the questions to match:
          dispatch(replaceQuestions(replacementQuestions));
        } else {
          // if not, clear the alert; we are unable to render it:
          dispatch(selectAlert(null));
        }
      }
    }
  }, [selectedAlert, availableQuestionsGlobal.data, selectedQuestions, dispatch]);

  useEffect(() => {
    if (alerts.isError) {
      dispatch(pushSnackbar({ type: 'error', message: 'Failed to load alerts!' }));
    }
  }, [alerts.isError, dispatch]);

  useEffect(() => {
    if (alerts.data?.length === 0) {
      dispatch(pushSnackbar({ type: 'info', message: 'No alerts found with these filters.' }));
    }
  }, [alerts.data, dispatch]);

  // interaction with alerts requires form data, tie the two together for state:
  const alertsFetching = availableForms.isFetching || alerts.isFetching;
  const alertsError = alerts.isError || availableForms.isError;
  const alertsRowCount = !alertsError && alerts.data?.length > 0 ? alerts.data.length : 0;
  return (
    <React.Fragment>
      <Box className={classes.filters}>
        <VACGeographies
          disabled={availableGeographies.isFetching || !availableGeographies.data || availableGeographies.isError}
          availableGeographies={availableGeographies.data}
          selectedGeographies={selectedGeographies}
          onGeographiesChanged={onGeographiesChanged}
        />
        <ChipPopover label="Sort" popoverStyle={AlertListConstants.chipPopoverStyle} active={alertSort !== alertSortDefault || alertSortDirection !== alertSortDirectionDefault}>
          <SortOption label="# Alerts" value="num_alerts" onSort={onSortChanged} active={alertSort === 'num_alerts' ? alertSortDirection : null} />
          <SortOption label="Form Id" value="form_id" onSort={onSortChanged} active={alertSort === 'form_id' ? alertSortDirection : null} />
          {/* Possible to sort by an field @ https://github.com/premisedata/api-geosentiment/blob/43cc3f547a13ff6fc2315c371c9c50289d4d6529/sentiment/application/usecases.py#L143 */}
        </ChipPopover>
        <ChipPopoverVAC
          disabled={!availableForms.data || availableForms.isFetching || availableForms.isError}
          active={Boolean(filterForms.length > 0)}
          label="Form(s)"
          options={availableForms.data}
          value={filterForms}
          vacProps={vacFormProps}
          instructions="Only alerts from these forms:"
        />
        <ChipPopoverVAC
          active={Boolean(filterQuestions?.length > 0)}
          label="Question(s)"
          disabled={!availableQuestions.data || availableQuestions.isFetching}
          options={availableQuestions.data}
          value={filterQuestions}
          vacProps={vacQuestionProps}
          instructions="Only alerts from these questions:"
        />
        <ChipPopoverRadioInstant
          options={AlertListConstants.dataRecencyOptions}
          value={alertDataRecency}
          renderOption={renderDataRecencyOption}
          onChange={onFilterDataRecency}
          label="Data Recency"
          active={alertDataRecency !== alertDataRecencyDefault}
        />
        <ChipBoolean
          label="Auto Geography"
          active={alertAutoSetGeography !== alertAutoSetGeographyDefault}
          onChange={onAutoGeographyChange}
          tooltip="Enable this to automatically filter alerts and render to the geography of interest."
        />
      </Box>
      <Box className={classes.results}>
        <VirtualizedList
          loading={alertsFetching}
          passedKey={alerts.requestId}
          ref={refVirtualList}
          rowCount={alertsRowCount}
          rowHeight={128 + theme.spacing(1)}
          rowRenderer={renderAlert}
        />
      </Box>
    </React.Fragment>
  );
};

AlertList.defaultProps = {};
AlertList.propTypes = {};

export default React.memo(AlertList);

//   if (prevProps.selectedAlert !== this.props.selectedAlert) {
//     if (this._refList.current) {
//       this._refList.current.forceUpdateGrid();
//     }
//   }
