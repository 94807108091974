import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
  infoPopover: {
    pointerEvents: 'none'
  },
  infoPopoverPaper: {
    maxWidth: 400,
    padding: theme.spacing(2),
    '& img': {
      height: 20,
      display: 'flex'
    }
  },
  fauxInfoBtn: {
    position: 'absolute',
    zIndex: 5
  }
}));

const RugPlotTooltip = (props) => {
  const { top, left } = props;
  const classes = useStyles();
  const [anchor, setAnchor] = useState(null);

  const onClose = () => setAnchor(null);
  const onOpen = (e) => {
    if (!anchor) setAnchor(e.target);
  };

  if (!anchor) {
    return (
      <IconButton size="small" className={classes.fauxInfoBtn} style={{ top, left }} onMouseOver={onOpen} onMouseLeave={onClose}>
        <InfoIcon />
      </IconButton>
    );
  }

  return (
    <React.Fragment>
      <IconButton size="small" className={classes.fauxInfoBtn} style={{ top, left }} onMouseOver={onOpen} onMouseLeave={onClose}>
        <InfoIcon />
      </IconButton>

      <Popover
        className={classes.infoPopover}
        disableRestoreFocus={true}
        onClose={onClose}
        open={!!anchor}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
      >
        <Paper className={classes.infoPopoverPaper}>
          <Typography gutterBottom={true}>This rug plot is intended to give you some idea of user contributions over time.</Typography>
          <Grid container={true} direction="row" justifyContent="center" alignItems="center" spacing={1}>
            <Grid item={true} xs={6}>
              <img src="/images/sentiment-thin-data.png" />
            </Grid>
            <Grid item={true} xs={6}>
              Few
            </Grid>
            <Grid item={true} xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <ArrowDownwardIcon style={{ display: 'flex' }} />
            </Grid>
            <Grid item={true} xs={6}></Grid>
            <Grid item={true} xs={6}>
              <img src="/images/sentiment-dense-data.png" />
            </Grid>
            <Grid item={true} xs={6}>
              Many
            </Grid>
          </Grid>
        </Paper>
      </Popover>
    </React.Fragment>
  );
};
RugPlotTooltip.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number
};
RugPlotTooltip.defaultProps = {};
export default React.memo(RugPlotTooltip);
