// import { Features as FEATURES } from '@premisedata/lib-features';

const BETA_USERS = [
  'pau.calvo@premise.com',
  'devon@premise.com',
  'bobby.simic@premise.com',
  'arni@premise.com',
  'yves@premise.com',
  'tim.schwuchow@premise.com',
  'andy.eschbacher@premise.com',
  'lou.paladino@premise.com',
  'daee.kang@premise.com',
  'mike.taylor@premise.com',
  'tim.ludolph@premise.com',
  'rebecca.rice@premise.com',
  'youness.bella@premise.com'
];

const BETA_PRODUCTS = [];

export { BETA_USERS, BETA_PRODUCTS };
