import React from 'react';
import PropTypes from 'prop-types';

// UI/UX
import Slider from '@material-ui/core/Slider';

class IsolatedSlider extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value
    };

    this.onChange = this.onChange.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
      this.setState({ value: this.props.value });
    }
  }
  onChange(_, value) {
    this.setState({
      value
    });
  }
  render() {
    const { width, ...otherProps } = this.props;
    return <Slider style={{ width }} {...otherProps} value={this.state.value} onChange={this.onChange} />;
  }
}

IsolatedSlider.propTypes = {
  width: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  onChange: PropTypes.func
};

IsolatedSlider.defaultProps = {
};

export default IsolatedSlider;
