import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// MUI
import { useTheme, makeStyles, alpha } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// MUI: ICONS
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PeopleIcon from '@material-ui/icons/People';

// DECKGL
import { DeckGL } from '@deck.gl/react';
import { StaticMap } from 'react-map-gl';
import { FlyToInterpolator } from '@deck.gl/core';

// MODULES
import { color, interpolateRgbBasis, max } from 'd3';
import debounce from 'lodash.debounce';
import clsx from 'clsx';

// OURS: SLICES & SERVICES
import { getMapData, getMapboxStyle, getBoundsForHasc } from './services';
import { sentimentSelectors, setPolygonRenderLevel, selectHasc, setPolygonSampleSizeMin } from './sentimentSlice';
import { updateMapPosition } from '../../actions';

// OURS
import { colorRampLookup } from '../ColorRamps';
import { MAP_STYLES_FLAT, VECTOR_TILESETS } from '../../constants';
import MapStyleGen from './MapStyleGen';
import { mapStateFromRef, viewportFromRef, polygonHasColor } from '../DeckGLUtils';
import DeckglLayerControl from '../DeckglLayerControl';
import Legend from './components/Legend';
import Fetching from '../Fetching';
import useIsMount from '../../hooks/useIsMount';
import SampleSizeSlider from './components/SampleSizeSlider';

const useStyles = makeStyles((theme) => ({
  map: {
    position: 'absolute',
    right: 0,
    backgroundColor: theme.palette.background.default,
    overflow: 'hidden',
    '& #view-sentiment-mapview-right': {
      cursor: 'default !important'
    }
  },
  legendBtn: {
    transition: 'opacity 500ms cubic-bezier(0.755, 0.050, 0.855, 0.060)',
    height: 36,
    width: 36,
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    '& svg.MuiSvgIcon-root': {
      height: 30,
      width: 30
    },
    boxShadow: '0 1px 5px rgba(0,0,0,0.4)',
    padding: 3,
    borderRadius: 5,
    position: 'absolute',
    zIndex: 9,
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.background.default
    }
  },
  titleBox: {
    backgroundColor: alpha(theme.palette.primary.main, 0.8),
    pointerEvents: 'none',
    position: 'absolute',
    width: '100%',
    zIndex: 9,
    opacity: 1.0,
    top: 0,
    left: 0,
    color: theme.palette.text.primary
  }
}));

const DeckMapTemporal = React.forwardRef((props, ref) => {
  const theme = useTheme();
  const classes = useStyles();
  const refDeckMap = useRef();
  const refDataLayer = useRef();
  const dispatch = useDispatch();

  // REDUX: w/ ext. selector
  const mapDataQueryArgs = useSelector(sentimentSelectors.selectMapData);

  // REDUX:
  const mapCenter = useSelector((state) => state.app.mapCenter);
  const mapZoom = useSelector((state) => state.app.mapZoom);
  const mapboxAccessToken = useSelector((state) => state.app.mapboxAccessToken);
  const vectorOpacity = useSelector((state) => state.sentiment.vectorOpacity);
  const colorRamp = useSelector((state) => state.sentiment.colorRamp);
  const colorRampInverted = useSelector((state) => state.sentiment.colorRampInverted);
  const colorOverrides = useSelector((state) => state.sentiment.colorOverrides);
  const showTitles = useSelector((state) => state.sentiment.showTitles);
  const selectedAlert = useSelector((state) => state.sentiment.selectedAlert);
  const selectedHasc = useSelector((state) => state.sentiment.selectedHasc);
  const polygonSampleSizeMin = useSelector((state) => state.sentiment.polygonSampleSizeMin);

  // REDUX: derived
  const { polygonRenderLevel, selectedQuestions, selectedQuestionsOrdering, selectedAnswer, selectedForms } = mapDataQueryArgs;

  // STATE
  const [legendMode, setLegendMode] = useState('open');
  const [withAlertsOnly, setWithAlertsOnly] = useState(false);
  const [polygonSampleSizePopperAnchorEl, setPolygonSampleSizePopperAnchorEl] = useState(null);
  const [polygonSampleSizeRange, setPolygonSampleSizeRange] = useState(null);

  const [viewState, setViewState] = useState({
    longitude: mapCenter.x_lon,
    latitude: mapCenter.y_lat,
    zoom: mapZoom,
    maxZoom: 15,
    minZoom: 1,
    pitch: 0,
    bearing: 0,
    maxPitch: 50,
    minPitch: 0
  });

  // QUERIES
  const mapboxStyleDocQuery = getMapboxStyle.useQuery({ style_id: VECTOR_TILESETS[polygonRenderLevel].style, mapboxAccessToken }, { skip: !mapboxAccessToken });
  const skipMapDataQuery = !selectedForms?.length || !selectedQuestions?.length;
  const mapDataQuery = getMapData.useQuery(mapDataQueryArgs, { skip: skipMapDataQuery });
  const boundsForHascQuery = getBoundsForHasc.useQuery({ hasc: selectedAlert?.hasc_code }, { skip: !selectedAlert?.hasc_code });

  const dataStyle = useMemo(() => {
    if (
      skipMapDataQuery ||
      mapDataQuery.isError ||
      mapboxStyleDocQuery.isError ||
      !mapDataQuery.data ||
      !mapboxStyleDocQuery.data ||
      mapDataQuery.isUninitialized ||
      mapDataQuery.data.length === 0
    )
      return null;

    if (!colorRampLookup[colorRamp]) {
      console.warn('Sentiment::DeckMapTemporal unknown color ramp; unable to render');
      return null;
    }

    // build lookup dictionary to find color overrides by value:
    const question_name = selectedQuestions[0]?.question_name;
    const colorOverridesLookup = {};

    if (question_name && colorOverrides[question_name]) {
      for (const { label, order } of selectedQuestionsOrdering) {
        if (colorOverrides[question_name][label]) {
          colorOverridesLookup[order] = color(colorOverrides[question_name][label]);
        }
      }
    }

    let ramp = colorRampLookup[colorRamp];

    // override the ramp if an answer is selected:
    if (selectedAnswer) {
      ramp = interpolateRgbBasis(['#FFFFFF', colorOverridesLookup[selectedAnswer.order] ?? ramp(selectedAnswer.order)]);
    }

    const rgb = (v) => {
      const { r, g, b } = colorOverridesLookup[v] ?? color(ramp(colorRampInverted ? 1 - v : v));
      return `${r},${g},${b}`;
    };
    return MapStyleGen(
      mapboxStyleDocQuery.data,
      VECTOR_TILESETS[polygonRenderLevel].layers,
      mapDataQuery.data,
      rgb,
      0,
      vectorOpacity,
      withAlertsOnly ? 'hasAlert' : '',
      polygonSampleSizeMin,
      undefined,
      selectedHasc
    );
  }, [
    skipMapDataQuery,
    mapDataQuery.data,
    mapDataQuery.isError,
    mapboxStyleDocQuery.data,
    mapboxStyleDocQuery.isError,
    polygonSampleSizeMin,
    colorRamp,
    colorRampInverted,
    withAlertsOnly,
    vectorOpacity,
    colorOverrides,
    selectedHasc,
    mapDataQuery.isUninitialized,
    polygonRenderLevel,
    selectedAnswer,
    selectedQuestions,
    selectedQuestionsOrdering
  ]);

  // USECALLBACK
  const updateGlobalMapPosition = useMemo(
    () =>
      debounce(() => {
        const { mapBounds, mapCenter, mapZoom, mapPolygon } = mapStateFromRef(refDeckMap);
        dispatch(updateMapPosition(mapBounds, mapCenter, mapZoom, mapPolygon));
      }, 666),
    [refDeckMap, dispatch]
  );

  const onMapClick = ({ coordinate }) => {
    if (!coordinate || coordinate.length !== 2) return;
    const viewport = viewportFromRef(refDeckMap);
    if (!viewport) return;

    const projectedCoordinate = viewport.project(coordinate);
    const hits = refDataLayer.current?.queryRenderedFeatures(projectedCoordinate);
    const hasc = hits && hits[0] && polygonHasColor(hits[0]) ? hits[0].properties.premise_id : null;

    dispatch(selectHasc(hasc));
  };
  const killAction = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const onViewStateChange = ({ viewState }) => {
    setViewState(viewState);
    updateGlobalMapPosition();
  };
  const onPolygonRenderLevelChanged = useCallback(
    (level) => {
      dispatch(setPolygonRenderLevel(level));
    },
    [dispatch]
  );

  const toggleWithAlertsOnly = useCallback(() => {
    setWithAlertsOnly(!withAlertsOnly);
  }, [withAlertsOnly]);

  const polygonSampleSizePopperClosed = useCallback(() => {
    setPolygonSampleSizePopperAnchorEl(null);
  }, []);
  const polygonSampleSizePopperOpened = useCallback((e) => {
    e.stopPropagation();

    // loop up to a `<Button />` element:
    let target = e.target;
    while (target && target.tagName !== 'BUTTON') target = target.parentNode;

    setPolygonSampleSizePopperAnchorEl(target);
  }, []);

  const onToggleLegend = useCallback(
    (e) => {
      e.stopPropagation();
      if (legendMode === 'closed') {
        setLegendMode('open');
      } else if (legendMode === 'open') {
        setLegendMode('mini');
      } else {
        setLegendMode('closed');
      }
    },
    [legendMode]
  );

  const onPolygonSampleSizeChanged = useCallback(
    (v) => {
      dispatch(setPolygonSampleSizeMin(v));
    },
    [dispatch]
  );

  const DeckglLayerControlButtons = useMemo(
    () => [
      {
        value: withAlertsOnly,
        action: toggleWithAlertsOnly,
        display: <NotificationsIcon />,
        propKey: 'withAlertsOnly',
        elementId: 'with-alerts-only',
        tooltip: 'Fade polygons w/o alerts'
      },
      {
        value: polygonSampleSizeMin !== 0,
        action: polygonSampleSizePopperOpened,
        display: <PeopleIcon />,
        propKey: 'polygonSampleSize',
        elementId: 'polygon-sample-size',
        tooltip: 'Fade polygons based on sample size',
        disabled: !polygonSampleSizeRange
      }
    ],
    // Normally useCallback is stable! However I saw some problems as this object is passed
    // to a child component. Force `DeckglLayerControlButtons` to be rebuilt when/if
    // these functions & values update:
    [withAlertsOnly, polygonSampleSizeMin, polygonSampleSizePopperOpened, toggleWithAlertsOnly, polygonSampleSizeRange]
  );

  // USEEFFECT
  useEffect(() => {
    if (mapDataQuery.isError || !mapDataQuery.data || mapDataQuery.isUninitialized) return setPolygonSampleSizeRange(null);

    setPolygonSampleSizeRange([0, max(mapDataQuery.data.map((d) => d.sampleSize).flatMap((d) => d))]);
  }, [mapDataQuery.isError, mapDataQuery.data, mapDataQuery.isUninitialized]);

  useEffect(() => {
    // componentDidMount

    return () => {
      // componentWillUnmount
      updateGlobalMapPosition.cancel();
    };
  }, [updateGlobalMapPosition]);

  const fitBounds = ({ minx, miny, maxx, maxy }) => {
    const viewport = viewportFromRef(refDeckMap);
    if (!viewport) return;
    setViewState({
      ...viewport.fitBounds([
        [minx, miny],
        [maxx, maxy]
      ]),
      transitionDuration: 2000,
      transitionInterpolator: new FlyToInterpolator()
    });
  };

  const isMount = useIsMount();
  useEffect(() => {
    // always skip this effect on first mount,
    if (isMount) return;

    if (!selectedAlert) {
      if (!selectedQuestions || selectedQuestions.length === 0) return;

      const { max_lat, max_lon, min_lat, min_lon } = selectedQuestions[0];

      // don't fitBounds() for the entire world:
      if (max_lon > 170 && min_lon < -170) return;

      fitBounds({
        minx: min_lon,
        miny: min_lat,
        maxx: max_lon,
        maxy: max_lat
      });
    } else {
      // wait for data to be available:
      if (boundsForHascQuery.isFetching) return;
      // check for failure
      if (!boundsForHascQuery.data) return;

      const [min_lon, min_lat, max_lon, max_lat] = boundsForHascQuery.data;
      fitBounds({
        minx: min_lon,
        miny: min_lat,
        maxx: max_lon,
        maxy: max_lat
      });
    }
    // isMount should not be in dependency array, its a hacky solution already
  }, [selectedAlert, selectedQuestions, boundsForHascQuery.data, boundsForHascQuery.isFetching]); //eslint-disable-line

  React.useImperativeHandle(
    ref,
    () => ({
      buildHashStateObject: () => ({}),
      fitBounds,
      setView: (x_lon, y_lat, z) => {
        if (refDeckMap.current) {
          setViewState({
            latitude: y_lat,
            longitude: x_lon,
            zoom: z ?? viewState.zoom,
            transitionDuration: 2000,
            transitionInterpolator: new FlyToInterpolator()
          });
        }
      }
    }),
    [viewState.zoom]
  );

  // LOCALS
  const question_proper = selectedQuestions[0]?.question_proper;
  const form_name = selectedForms[0]?.form_name;
  const renderTitles = showTitles && question_proper && form_name;
  const { baseLayer, labelLayer } = MAP_STYLES_FLAT[0].getMapStyle(theme);
  const { left, top, bottom } = props;
  const boxStyle = React.useMemo(() => ({ left, top, bottom }), [left, top, bottom]);

  return (
    <Box className={classes.map} style={boxStyle}>
      <SampleSizeSlider
        anchorEl={polygonSampleSizePopperAnchorEl}
        onChange={onPolygonSampleSizeChanged}
        onClickAway={polygonSampleSizePopperClosed}
        defaultValue={polygonSampleSizeMin}
        polygonSampleSizeRange={polygonSampleSizeRange}
      />
      {mapDataQuery.isFetching && <Fetching disablePointerEvents={true} />}
      <DeckGL
        onClick={onMapClick}
        glOptions={{ failIfMajorPerformanceCaveat: true }}
        ref={refDeckMap}
        viewState={viewState}
        controller={true}
        onViewStateChange={onViewStateChange}
      >
        <StaticMap key="baseLayer" mapStyle={baseLayer} mapboxApiAccessToken={mapboxAccessToken} />
        <StaticMap ref={refDataLayer} key="data-layer" visible={!!dataStyle} mapStyle={dataStyle} mapboxApiAccessToken={mapboxAccessToken} />
        <StaticMap visible={!!labelLayer} key="labelLayer" mapStyle={labelLayer} mapboxApiAccessToken={mapboxAccessToken} />
      </DeckGL>
      <DeckglLayerControl onLevelChange={onPolygonRenderLevelChanged} currentLevelToggle={polygonRenderLevel} buttons={DeckglLayerControlButtons} />
      <Legend mode={legendMode} />
      <Box onMouseDown={killAction} onMouseUp={killAction} onClick={onToggleLegend} className={classes.legendBtn}>
        <BubbleChartIcon />
      </Box>
      {renderTitles && (
        <React.Fragment>
          <Box className={clsx('noselect', classes.titleBox)}>
            <Typography variant="h6">{question_proper}</Typography>
            <Typography variant="body1">{form_name}</Typography>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
});
DeckMapTemporal.displayName = 'DeckMapTemporal';
DeckMapTemporal.propTypes = {
  bottom: PropTypes.number,
  left: PropTypes.number,
  top: PropTypes.number
};
DeckMapTemporal.defaultProps = {
  bottom: 0,
  left: 0,
  top: 0
};

export default React.memo(DeckMapTemporal);
