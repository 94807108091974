import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(() => ({
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

const ChipBoolean = (props) => {
  const classes = useStyles();

  const chipStyle = {};
  if (props.disabled) {
    chipStyle.opacity = 0.5;
  }

  const chip = (
    <Chip
      style={chipStyle}
      className={clsx(classes.ellipsis, props.className, 'noselect')}
      variant="outlined"
      color={props.active ? 'secondary' : 'default'}
      label={props.label}
      onClick={props.disabled ? undefined : props.onChange}
    />
  );

  return props.tooltip ? (
    <Tooltip arrow={true} title={props.tooltip}>
      {chip}
    </Tooltip>
  ) : (
    chip
  );
};

ChipBoolean.propTypes = {
  label: PropTypes.string.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  tooltip: PropTypes.string
};

ChipBoolean.defaultProps = {
  active: false,
  disabled: false
};

export default React.memo(ChipBoolean);
