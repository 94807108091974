import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// MODULES
import { forward } from 'mgrs';
import numeral from 'numeral';

// UI/UX
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';

// ICONS
import AssignmentIcon from '@material-ui/icons/Assignment';
import CloseIcon from '@material-ui/icons/Close';
import FolderIcon from '@material-ui/icons/Folder';
import LanguageIcon from '@material-ui/icons/Language';
import RoomIcon from '@material-ui/icons/Room';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';

// OURS
import { getUserdataMetadata } from './services';
import { LEFT_PANEL_WIDTH, TOP } from 'iris-config'; // eslint-disable-line import/no-unresolved
import { enlargePhoto as enlargePhotoAction, pushSnackbar as pushSnackbarAction } from '../../actions';

const styles = (theme) => ({
  box: {
    position: 'absolute',
    top: TOP,
    right: 0,
    width: LEFT_PANEL_WIDTH,
    bottom: 0,
    padding: theme.spacing(1, 1, 0, 1),
    overflowY: 'auto'
  },
  paper: {
    padding: theme.spacing(1, 1, 0, 1)
  },
  imgPaper: {
    borderRadius: '4px 4px 0px 0px'
  },
  image: {
    height: Math.round(LEFT_PANEL_WIDTH / 1.77),
    padding: theme.spacing(1)
  },
  img: {
    display: 'block',
    height: '100%',
    margin: 'auto',
    objectFit: 'contain',
    width: '100%',
    zIndex: 10,
    cursor: 'pointer'
  },
  closeBtn: {
    position: 'absolute',
    zIndex: 20,
    top: theme.spacing(2),
    right: theme.spacing(2)
  },
  listItemIcon: {
    minWidth: 36
  }
});

class UserdataPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photoFailure: false
    };

    // BIND
    this.onCopyCoords = this.onCopyCoords.bind(this);
    this.skipAttrs = ['x_lon', 'y_lat', 'id', 'type', 'photo', 'name', 'gadm_place_name'];
  }
  onCopyCoords() {
    const { x_lon, y_lat } = this.props.data;
    navigator.clipboard.writeText(`${y_lat},${x_lon}`).catch(() => {
      this.props.pushSnackbar({ type: 'error', message: 'Failed to copy export link to clipboard' });
    });
  }
  render() {
    const { classes, data, enlargePhoto, onClose, onZoomTo, onPanTo, userdataMetadataQuery } = this.props;
    const { photoFailure } = this.state;
    if (!data) return null;

    const metadata = userdataMetadataQuery.data?.layersById?.[data.type];
    if (!metadata) return null;

    const attributes = Object.keys(data).filter((d) => !this.skipAttrs.includes(d));
    const name = data.place_name ?? data.name;
    return (
      <Box className={classes.box}>
        <Tooltip placement="top" arrow={true} title="Close">
          <span className={classes.closeBtn}>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </span>
        </Tooltip>

        <Paper className={classes.imgPaper}>
          {data.photo && !photoFailure ? (
            <ButtonBase
              className={classes.image}
              disableRipple={true}
              onClick={() => {
                enlargePhoto(data.photo);
              }}
            >
              <img
                alt="user data photo"
                className={classes.img}
                src={data.photo}
                onError={() => {
                  this.setState({ photoFailure: true });
                }}
              />
            </ButtonBase>
          ) : (
            <Box className={classes.image} />
          )}
        </Paper>
        <Paper className={classes.paper}>
          {name && <Typography variant="h4">{name}</Typography>}
          <List>
            <ListItem>
              <ListItemIcon classes={{ root: classes.listItemIcon }}>
                <FolderIcon />
              </ListItemIcon>
              <ListItemText primary={metadata.name} />
            </ListItem>
            <ListItem>
              <ListItemIcon classes={{ root: classes.listItemIcon }}>
                <LanguageIcon />
              </ListItemIcon>
              <ListItemText primary={data.gadm_place_name} />
            </ListItem>
            <ListItem id="poi-zoom">
              <ListItemIcon classes={{ root: classes.listItemIcon }}>
                <RoomIcon />
              </ListItemIcon>
              <ListItemText title={[data.y_lat, data.x_lon].join(', ')} primary={[numeral(data.y_lat).format('0.0000'), numeral(data.x_lon).format('0.0000')].join(', ')} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  onClick={() => {
                    onZoomTo && onZoomTo(data.x_lon, data.y_lat);
                  }}
                >
                  <ZoomInIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  onClick={() => {
                    onPanTo && onPanTo(data.x_lon, data.y_lat);
                  }}
                >
                  <ZoomOutMapIcon />
                </IconButton>
                <IconButton edge="end" onClick={this.onCopyCoords}>
                  <AssignmentIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemIcon classes={{ root: classes.listItemIcon }}>
                <ViewColumnIcon />
              </ListItemIcon>
              <ListItemText title={forward([+data.y_lat, +data.x_lon])} primary={forward([+data.y_lat, +data.x_lon])} />
            </ListItem>
            {attributes.map((d) => (
              <ListItem key={d}>
                <ListItemText primary={d} secondary={data[d]} />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Box>
    );
  }
}

// {
//     "id": "148",
//     "type": "25",
//     "x_lon": 14.5349753089488,
//     "y_lat": 52.3600039957622,
//     "photo": "https://dev.iris.premise.com/crazy.gif",
//     "name": "do ea eiusmod",
//     "user_attr_long": "",
//     "user_attr_short": "",
//     "gadm_place_name": "Frankfurt (Oder), Frankfurt (Oder), Frankfurt (Oder), Brandenburg, Germany"
// }

const mapStateToProps = (state) => ({
  // Custom user data support:
  userdataMetadataQuery: getUserdataMetadata.select()(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      enlargePhoto: enlargePhotoAction,
      pushSnackbar: pushSnackbarAction
    },
    dispatch
  );

UserdataPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  uploadsMetadata: PropTypes.object,
  data: PropTypes.object,
  enlargePhoto: PropTypes.func.isRequired,
  pushSnackbar: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onPanTo: PropTypes.func.isRequired,
  onZoomTo: PropTypes.func.isRequired,
  userdataMetadataQuery: PropTypes.object
};

UserdataPanel.defaultProps = {};
const withRedux = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
export default withRedux(withTheme(withStyles(styles)(UserdataPanel)));
