// essentials
import { withTheme, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

// modules
import { alpha } from '@material-ui/core/styles/colorManipulator';

// ui / ux
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Tooltip from '@material-ui/core/Tooltip';

// icons
// import HelpIcon from '@material-ui/icons/Help';
import UndoIcon from '@material-ui/icons/Undo';

// ours
import ValidateSubmissionPaper from './ValidateSubmissionPaper';
import PlacePaper from '../../../PlacePaper';
import ValidationMap from './ValidationMap';

const styles = (theme) => ({
  toolBar: {
    display: 'block',
    position: 'relative',
    textAlign: 'right'
  },
  pushRight: {
    marginLeft: 'auto'
  },
  mainGrid: {
    height: 'calc(100% - 48px)' // 43px is the height of the tool bar
  },
  height100: {
    height: '100%'
  },
  img: {
    width: '100%',
    verticalAlign: 'middle',
    objectFit: 'contain'
  },
  imageQueue: {
    width: '100%',
    height: '100%',
    '& .MuiGridListTile-tile': {
      borderRadius: 4,
      border: `1px solid ${theme.palette.primary.dark}`
    }
  },
  imageQueuePaper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    position: 'relative',
    height: 'calc(100% - (50vh + 86px))' // 78 + 8 margin
  },
  dangerBtn: {
    '&:hover': {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main
    }
  },
  paperColumns: {
    padding: theme.spacing(1),
    height: '100%',
    overflow: 'scroll',
    userSelect: 'none'
  },
  paperColumnsBox: {
    width: '100%',
    position: 'relative'
  },
  paperColumnsBtn: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    marginTop: '-28px',
    marginLeft: '-28px',
    backgroundColor: alpha(theme.palette.common.white, 0.3),
    color: alpha(theme.palette.common.black, 0.3),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.5),
      color: alpha(theme.palette.common.black, 1.0)
    }
  },
  paperRejected: {
    backgroundColor: alpha('#FF0000', 0.3)
  },
  paperAccepted: {
    backgroundColor: alpha('#00FF00', 0.3)
  },
  mapGrid: {
    width: '100%',
    height: `calc(100% - 128px - 8px - ${theme.spacing(2)}px)`, // 8px is to match grid spacing on left :|
    marginTop: theme.spacing(2)
  },
  imgLarge: {
    height: '100%',
    width: '100%',
    verticalAlign: 'middle',
    objectFit: 'contain'
  }
});

class SortSubmissions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accepted: [],
      rejected: [],
      queue: [],

      undoList: [],

      panelMode: window.localStorage.validation_initial_panel || 'map'
    };

    this.resetAllQueues = this.resetAllQueues.bind(this);
    this.documentKeydown = this.documentKeydown.bind(this);

    this.acceptCurrent = this.acceptCurrent.bind(this);
    this.rejectCurrent = this.rejectCurrent.bind(this);
    this.undoLast = this.undoLast.bind(this);
    this.continue = this.continue.bind(this);

    this.smallImages = this.adjImageSize.bind(this, 4);
    this.normalImages = this.adjImageSize.bind(this, 6);
    this.largeImages = this.adjImageSize.bind(this, 8);

    this.calcProgress = this.calcProgress.bind(this);

    this.undoRejectedAtIndex = this.undoRejectedAtIndex.bind(this);
    this.undoAcceptedAtIndex = this.undoAcceptedAtIndex.bind(this);

    this.maxCols = 4;
  }

  calcProgress() {
    return 100 - (this.state.queue.length / this.props.submissions.length) * 100;
  }

  renderQueue() {
    const { classes } = this.props;
    const { queue } = this.state;

    if (queue.length === 0) {
      return;
    }
    // render the first submission special,
    const topSub = queue[0];
    const submissions = queue.slice(1);

    // some logic to prevent gaps in images:
    // - only ~1 in 5 should be "wide"
    const maxWide = Math.ceil(submissions.length * 0.2);
    let curRow = 0;
    let numWide = 0;

    return (
      <React.Fragment>
        <ValidateSubmissionPaper submission={topSub} onAccept={this.acceptCurrent} onReject={this.rejectCurrent} />
        <Paper className={classes.imageQueuePaper}>
          <GridList cellHeight={120} className={classes.imageQueue} cols={this.maxCols}>
            {submissions.map((sub) => {
              let cols = 1;
              // some logic to prevent gaps in images:
              if (numWide < maxWide) {
                cols = Math.random() > 0.5 ? 2 : 1;
                if (curRow === this.maxCols) {
                  curRow = cols;
                } else if (curRow + cols > this.maxCols) {
                  cols = 1;
                  curRow += 1;
                } else {
                  curRow += cols;
                }

                numWide += cols - 1;
              }
              return (
                <GridListTile key={sub.sub_id} cols={cols}>
                  <img src={sub.photo} alt={sub.sub_id} />
                </GridListTile>
              );
            })}
          </GridList>
        </Paper>
      </React.Fragment>
    );
  }
  renderSubmissionPhotos(q, onUndo) {
    const { classes } = this.props;
    return q.map((d, i) => {
      return (
        <Box key={d.sub_id} className={classes.paperColumnsBox}>
          <img alt={`submission #${d.sub_id}`} src={d.photo} className={classes.img} />
          <Tooltip title="Undo" placement="top" arrow={true}>
            <Fab
              className={classes.paperColumnsBtn}
              onClick={() => {
                onUndo(i);
              }}
            >
              <UndoIcon />
            </Fab>
          </Tooltip>
        </Box>
      );
    });
  }
  continue() {
    this.props.onContinue(this.state.accepted, this.state.rejected);
  }
  documentKeydown(k) {
    if (k.key === 'ArrowLeft') {
      this.rejectCurrent();
    } else if (k.key === 'ArrowRight') {
      this.acceptCurrent();
    } else if (k.key === 'ArrowUp' || k.key === 'ArrowDown') {
      this.undoLast();
    } else if (k.key === 'z' && (k.metaKey || k.ctrlKey)) {
      this.undoLast();
      k.stopPropagation();
    }
  }
  componentDidMount() {
    document.addEventListener('keydown', this.documentKeydown);
    if (this.props.submissions) {
      this.resetAllQueues();
    }
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.documentKeydown);
  }
  componentDidUpdate(prevProps) {
    window.localStorage.validation_initial_panel = this.state.panelMode;

    if (prevProps.submissions !== this.props.submissions || prevProps.rejected !== this.props.rejected || prevProps.accepted !== this.props.accepted) {
      this.resetAllQueues();
    }
  }
  adjImageSize(imageSize) {
    window.localStorage.validationImageSize = imageSize;
    this.setState({ imageSize });
  }
  rejectCurrent() {
    this.setState((s) => {
      if (s.queue.length === 0) return {};
      return {
        queue: s.queue.slice(1),
        rejected: [s.queue[0], ...s.rejected],
        undoList: [...s.undoList, { sub: s.queue[0], action: 'reject' }]
      };
    });
  }
  acceptCurrent() {
    this.setState((s) => {
      if (s.queue.length === 0) return {};
      return {
        queue: s.queue.slice(1),
        accepted: [s.queue[0], ...s.accepted],
        undoList: [...s.undoList, { sub: s.queue[0], action: 'accept' }]
      };
    });
  }
  resetAllQueues(event) {
    if (!this.props.submissions || this.props.submissions.length === 0) {
      this.setState({
        queue: [],
        rejected: [],
        accepted: []
      });

      return;
    }
    if (event) {
      this.setState({
        queue: [...this.props.submissions],
        rejected: [],
        accepted: []
      });

      return;
    }
    const acceptedIds = this.props.accepted.map((d) => d.sub_id);
    const rejectedIds = this.props.rejected.map((d) => d.sub_id);
    const queue = this.props.submissions.filter((d) => !acceptedIds.includes(d.sub_id) && !rejectedIds.includes(d.sub_id));
    this.setState({
      queue,
      rejected: this.props.rejected,
      accepted: this.props.accepted
    });
  }
  undoLast() {
    const l = this.state.undoList.length;
    if (l === 0) {
      return;
    }

    const { action, sub } = this.state.undoList[l - 1];

    if (action === 'accept') {
      const idx = this.state.accepted.indexOf(sub);
      if (idx > -1) {
        this.undoAcceptedAtIndex(idx);
      } else {
        console.error('found bad state while executing undoLast');
      }
    } else {
      const idx = this.state.rejected.indexOf(sub);
      if (idx > -1) {
        this.undoRejectedAtIndex(idx);
      } else {
        console.error('found bad state while executing undoLast');
      }
    }
  }
  undoRejectedAtIndex(idx) {
    this.setState((s) => {
      const undoIdx = this.state.undoList.map((d) => d.sub).indexOf(this.state.rejected[idx]);
      if (undoIdx === -1) return {};
      return {
        queue: [s.rejected[idx], ...s.queue],
        rejected: [...s.rejected.slice(0, idx), ...s.rejected.slice(idx + 1)],
        undoList: [...s.undoList.slice(0, undoIdx), ...s.undoList.slice(undoIdx + 1)]
      };
    });
  }
  undoAcceptedAtIndex(idx) {
    this.setState((s) => {
      const undoIdx = s.undoList.map((d) => d.sub).indexOf(s.accepted[idx]);
      if (undoIdx === -1) return {};

      return {
        queue: [s.accepted[idx], ...s.queue],
        accepted: [...s.accepted.slice(0, idx), ...s.accepted.slice(idx + 1)],
        undoList: [...s.undoList.slice(0, undoIdx), ...s.undoList.slice(undoIdx + 1)]
      };
    });
  }
  render() {
    const { classes, place } = this.props;
    const { accepted, rejected, panelMode, queue } = this.state;
    return (
      <React.Fragment>
        <Box className={classes.toolBar}>
          <ButtonGroup>
            <Button variant="outlined" color="secondary" onClick={this.props.onCancel}>
              Cancel
            </Button>
            <Button variant="outlined" color="secondary" disabled={this.state.queue.length !== 0} onClick={this.continue}>
              Continue
            </Button>
          </ButtonGroup>
        </Box>
        <Grid container={true} className={classes.mainGrid} spacing={1}>
          <Grid item={true} xs={8} className={classes.height100}>
            <Grid container={true} className={classes.height100} spacing={1}>
              <Grid item={true} xs={2} className={classes.height100}>
                <Paper className={clsx(classes.paperRejected, classes.paperColumns)}>{this.renderSubmissionPhotos(rejected, this.undoRejectedAtIndex)}</Paper>
              </Grid>
              <Grid item={true} xs={8} className={classes.height100}>
                {this.renderQueue()}
              </Grid>
              <Grid item={true} xs={2} className={classes.height100}>
                <Paper className={clsx(classes.paperAccepted, classes.paperColumns)}>{this.renderSubmissionPhotos(accepted, this.undoAcceptedAtIndex)}</Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={4} className={classes.height100}>
            <Grid container={true} direction="column" justifyContent="flex-start" alignItems="stretch" className={classes.height100}>
              <Grid item={true}>
                <PlacePaper
                  tooltip={'Toggle Map & Photo'}
                  place={place}
                  onClick={() => {
                    this.setState({
                      panelMode: panelMode === 'map' ? 'photo' : 'map'
                    });
                  }}
                />
              </Grid>
              <Grid item={true} className={classes.mapGrid}>
                {panelMode === 'map' ? (
                  <ValidationMap activePlace={place} activeSubmission={queue.length > 0 ? queue[0] : null} />
                ) : (
                  <img alt={`place #${place.place_id}`} src={place.photo} className={classes.imgLarge} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

SortSubmissions.propTypes = {
  // essentials
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  // callback functions
  onCancel: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,

  // data
  accepted: PropTypes.array,
  place: PropTypes.object,
  rejected: PropTypes.array,
  submissions: PropTypes.array
};

SortSubmissions.defaultProps = {
  accepted: [],
  rejected: []
};

export default withTheme(withStyles(styles)(SortSubmissions));
