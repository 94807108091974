import React, { PureComponent } from 'react';
import { withStyles, withTheme, alpha } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// MODULE
import moment from 'moment';
import equal from 'deep-equal';
import clsx from 'clsx';

// UI/UX
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import MUIAppBar from '@material-ui/core/AppBar';

// ICONS
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/EditOutlined';
import FolderIcon from '@material-ui/icons/Folder';
import LinkIcon from '@material-ui/icons/Link';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import PeopleIcon from '@material-ui/icons/People';
import PlaceIcon from '@material-ui/icons/Place';
import ReplyIcon from '@material-ui/icons/Reply';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import SignalCellularAlt from '@material-ui/icons/SignalCellularAlt';
import WifiIcon from '@material-ui/icons/Wifi';

// OURS
import { API_HOST, genericGet, genericPost, genericDelete } from 'iris-api'; // eslint-disable-line import/no-unresolved
import { pushSnackbar as pushSnackbarAction, setGlobal as setGlobalAction } from './actions';
import { Features as FEATURES, has } from '@premisedata/lib-features';
import { SEARCH_BOX_WIDTH } from 'iris-config'; // eslint-disable-line import/no-unresolved
import { BETA_USERS, BETA_PRODUCTS } from './beta-users';

// OURS - COMPONENTS
import InboxPopover from './InboxPopover';
import AppMenu from './AppMenu';
import GeoAutoComplete from './components/GeoAutoComplete';

// GLOBALS
const INBOX_REFRESH_INTERVAL = 5000; // ms

const hasFeatureFlag = (user, product) => {
  if (!BETA_USERS.includes(user.email) && BETA_PRODUCTS.includes(product)) return false;

  return has(user.active_features, product);
};

const DeleteButton = withStyles(({ palette }) => ({
  root: {
    color: palette.error.main,
    border: `1px solid ${alpha(palette.error.main, 0.5)}`,
    '&:hover': {
      border: `1px solid ${palette.error.main}`,
      backgroundColor: alpha(palette.error.main, 0.08)
    }
  }
}))(Button);

const styles = (theme) => ({
  toolBar: {
    padding: '0px 8px',
    '& button': {
      margin: theme.spacing(0.5),
      borderRadius: '35px'
    }
  },
  savedViewLink: {
    textAlign: 'start',
    lineHeight: '1.2rem !important'
  },
  popoverContainer: {
    width: 450,
    maxHeight: 450,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    overflowY: 'auto',
    '& .MuiFormControl-root': {
      width: '100%',
      margin: `${theme.spacing(2)}px 0 ${theme.spacing(4)}px`
    }
  },
  popoverContainer_btns: {
    display: 'flex',
    justifyContent: 'center',
    '& button:first-of-type': {
      marginRight: theme.spacing(3)
    }
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.palette.background.default}`,
    '& .MuiTypography-subtitle1': {
      lineHeight: '2rem'
    }
  },
  paperEdit: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    border: `1px solid ${theme.palette.background.default}`,

    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  appbarDivider: {
    height: 28,
    margin: `auto ${theme.spacing(2)}px`
  },
  toolbarGrid: {
    flexWrap: 'noWrap',
    width: '100%',
    '& > div': {
      display: 'flex'
    }
  },
  logo: {
    cursor: 'pointer',
    height: 32
  },
  logoGridItem: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  searchBox: {
    display: 'flex',
    width: `${SEARCH_BOX_WIDTH}px`,
    marginLeft: theme.spacing(1),
    alignItems: 'center'
  }
});

class AppBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // DOM References for Modeless:
      shareViewDropdownAnchorEl: null,
      savedViewsDropdownAnchorEl: null,
      inboxAnchorEl: null,

      //  Actively edited hash:
      editSavedViewOpen: null,

      // New Hash name:
      viewName: '',

      // From API:
      inbox: null,
      savedViews: []
    };

    // BIND
    this.clearSavedViews = this.clearSavedViews.bind(this);
    this.onInboxTick = this.onInboxTick.bind(this);
    this.clearViewNameAnchor = this.clearViewNameAnchor.bind(this);
    this.onAdminPanel = this.onAdminPanel.bind(this);
    this.zoomToWorld = this.zoomToWorld.bind(this);
    this.onInboxDelete = this.onInboxDelete.bind(this);
    this.toggleConfigPanel = this.toggleConfigPanel.bind(this);
    this.onSearchSelection = this.onSearchSelection.bind(this);

    // TIMERS
    this._iInbox = null;

    // XHR
    this._xhrInbox = {};
    this._xhrShareLink = {};
    this._xhrSavedViews = {};
    this._xhrDeleteSavedView = {};
    this._xhrUpdateSavedView = {};
    this._xhrClearSavedViews = {};
    this._xhrNearHere = {};
  }

  componentDidMount() {
    this.onInboxTick();
    if (!this._iInbox) {
      this._iInbox = setInterval(this.onInboxTick, INBOX_REFRESH_INTERVAL);
    }
  }

  componentWillUnmount() {
    if (this._iInbox) {
      clearInterval(this._iInbox);
      this._iInbox = null;
    }

    this._xhrShareLink.cancel && this._xhrShareLink.cancel();
    this._xhrSavedViews.cancel && this._xhrSavedViews.cancel();
    this._xhrDeleteSavedView.cancel && this._xhrDeleteSavedView.cancel();
    this._xhrUpdateSavedView.cancel && this._xhrUpdateSavedView.cancel();
    this._xhrClearSavedViews.cancel && this._xhrClearSavedViews.cancel();
    this._xhrNearHere.cancel && this._xhrNearHere.cancel();
  }

  copyLinkToClipboard(hash) {
    const { pushSnackbar } = this.props;
    navigator.clipboard
      .writeText(`${window.location.origin}/?h=${hash}`)
      .then(() => pushSnackbar({ message: 'Link copied to clipboard', type: 'info' }))
      .catch(() => {
        return pushSnackbar({ message: 'Failed to copy link to clipboard', type: 'error' });
      });
  }

  editSavedView(v) {
    const { classes } = this.props;
    const { viewName } = this.state;

    return (
      <Paper className={classes.paperEdit} elevation={3}>
        <Grid item={true} xs={12}>
          <TextField
            label="Name of view"
            value={viewName}
            error={viewName.length > 50}
            helperText={`${viewName.length} / 50`}
            onKeyDown={(e) => {
              if (e.keyIdentifier === 'U+000A' || e.keyIdentifier === 'Enter' || e.keyCode === 13) {
                viewName !== v.view_name ? this.updateSavedViewName(viewName, v.hash) : this.setState({ editSavedViewOpen: null, viewName: '' });
              }
            }}
            onChange={({ target }) => {
              this.setState({
                viewName: target.value
              });
            }}
          />
        </Grid>
        <Grid item={true} xs={12} className={classes.popoverContainer_btns}>
          <Button variant="outlined" color="default" onClick={() => this.setState({ editSavedViewOpen: null, viewName: '' })}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            disabled={viewName.length > 50}
            onClick={() => {
              viewName !== v.view_name ? this.updateSavedViewName(viewName, v.hash) : this.setState({ editSavedViewOpen: null, viewName: '' });
            }}
          >
            Update
          </Button>
        </Grid>
      </Paper>
    );
  }
  getSavedViews() {
    this._xhrSavedViews.cancel && this._xhrSavedViews.cancel();
    genericGet(`${API_HOST}/places/v0/hashes`, this._xhrSavedViews, (e, r) => {
      if (e) return console.error(e);
      this.setState({ savedViews: r });
    });
  }
  deleteSavedView(hash) {
    this._xhrDeleteSavedView.cancel && this._xhrDeleteSavedView.cancel();
    genericDelete(`${API_HOST}/places/v0/hashes/${hash}`, this._xhrDeleteSavedView, (e, r) => {
      if (e) return console.error(e);
      this.setState({ savedViews: r });
    });
  }
  clearSavedViews() {
    this._xhrClearSavedViews.cancel && this._xhrClearSavedViews.cancel();
    genericDelete(`${API_HOST}/places/v0/hashes`, this._xhrClearSavedViews, (e) => {
      if (e) return console.error(e);
      this.setState({ savedViews: [] });
    });
  }
  updateSavedViewName(view_name, hash) {
    this._xhrUpdateSavedView.cancel && this._xhrUpdateSavedView.cancel();
    genericPost(`${API_HOST}/places/v0/hashes/update`, { view_name, hash }, this._xhrUpdateSavedView, (e, r) => {
      if (e) return console.error(e);
      this.setState({ savedViews: r, editSavedViewOpen: null, viewName: '' });
    });
  }
  clearViewNameAnchor() {
    this.setState({ shareViewDropdownAnchorEl: null, viewName: '' });
  }
  onInboxTick() {
    const inboxError = () => {
      if (this._iInbox) {
        clearInterval(this._iInbox);
        this._iInbox = null;
      }
      this.setState({ inbox: null });
      this.props.pushSnackbar({ message: 'inbox currently unavailable; refresh page to try again.', type: 'error' });
    };

    // skip this update if the request is still pending:
    // skip updates if we don't have an auth token,
    if (this._xhrInbox.cancel || !window.getFirebaseToken) return;

    genericGet(`${API_HOST}/iris/v0/profile/inbox`, this._xhrInbox, (e, newInboxData) => {
      // XHR complete, remove cancel callback:
      delete this._xhrInbox.cancel;

      // handle errors:
      if (e) return inboxError();

      const { inbox, inboxAnchorEl } = this.state;
      // don't update the inbox while its open:
      if (inboxAnchorEl) return;
      // don't update the inbox needlessly:
      if (!inbox || !equal(inbox, newInboxData)) {
        this.setState({ inbox: newInboxData });
      }
    });
  }
  zoomToWorld() {
    if (this.props.currentViewRef?.current?.setView) {
      this.props.currentViewRef.current.setView(10, -20, 2);
    }
  }
  onAdminPanel() {
    window.location = '/admin';
  }
  onInboxDelete(d) {
    // immediately remove this element from the list:
    const newInbox = this.state.inbox.filter((d1) => d1.uid !== d.uid);
    if (newInbox.length === 0) {
      this.setState({
        inbox: [],
        inboxAnchorEl: null
      });
    } else {
      this.setState({
        inbox: newInbox
      });
    }
    // update the server:
    genericDelete(`${API_HOST}/iris/v0/profile/inbox/${d.uid}`);
  }
  onSearchSelection({ extent, type, action, val }) {
    // document.getElementsByTagName('BODY')[0].focus();
    const { mapCenter, currentViewRef, setGlobal, pushSnackbar } = this.props;
    if (type === 'dynamic' && action === 'near-here') {
      this._xhrNearHere.cancel && this._xhrNearHere.cancel();
      genericGet(`${API_HOST}/places/v0/places/search/${mapCenter.x_lon}/${mapCenter.y_lat}/100000/${val}`, this._xhrNearHere, (e, r) => {
        if (e || !r) return pushSnackbar({ type: 'error', message: 'Failed to search for places near here...' });
        if (!r.extent) return pushSnackbar({ type: 'info', message: 'No places matching that name near here...' });
        const [minx, miny, maxx, maxy] = r.extent;
        currentViewRef.current.fitBounds({ minx, miny, maxx, maxy }, val);
      });
    } else if (type === 'dynamic' && (action === 'zoom-to' || action === 'drop-pin')) {
      const [lat, lon] = val.split(',').map((d) => +d);
      currentViewRef.current.setView(lon, lat, 15);
      if (action === 'drop-pin') {
        setGlobal({ dropPin: [{ lon, lat }] });
      }
    } else if (type === 'dynamic' && action === 'mgrs') {
      const [minx, miny, maxx, maxy] = val.extent;
      currentViewRef.current.fitBounds({ minx, miny, maxx, maxy });
    } else if (extent) {
      const [minx, miny, maxx, maxy] = extent;
      currentViewRef.current.fitBounds({ minx, miny, maxx, maxy });
    }
  }

  toggleConfigPanel() {
    this.props.setGlobal({
      configPanelOpen: !this.props.configPanelOpen
    });
  }
  render() {
    const { savedViewsDropdownAnchorEl, inboxAnchorEl, shareViewDropdownAnchorEl, inbox, viewName, savedViews, editSavedViewOpen } = this.state;
    const { theme, classes, hasGPUHardware, user, onExport, onAbout, onSettings, onShareFeedback, activeProduct, configPanelOpen, onLogout } = this.props;

    const badgeCount = inbox ? inbox.map((d) => d.seen_on).filter((d) => !d).length : 0;
    const logoSrc = theme.palette.type === 'dark' ? 'Premise_Logo_Red_RGB.svg' : 'Premise_Logo_Black_RGB.svg';

    // <GeoAutoComplete /> Options...
    const withDropPin = ['radio', 'places'].includes(activeProduct);
    const withDynamicSearch = ['radio', 'places'].includes(activeProduct);
    const withMGRS = ['radio', 'places'].includes(activeProduct);

    return (
      <MUIAppBar>
        <Toolbar className={classes.toolBar}>
          <Grid container={true} direction="row" justifyContent="flex-start" alignItems="center" className={classes.toolbarGrid}>
            <Grid item={true}>
              <Tooltip placement="bottom" arrow={true} title="Toggle configuration panel">
                <IconButton disabled={false} edge="start" color={configPanelOpen ? 'secondary' : 'inherit'} onClick={this.toggleConfigPanel}>
                  <MenuIcon />
                </IconButton>
              </Tooltip>

              <Box className={classes.searchBox} id="search-iris">
                <GeoAutoComplete withLatLonSearch={true} withDropPin={withDropPin} withDynamicSearch={withDynamicSearch} onSelect={this.onSearchSelection} withMGRS={withMGRS} />
              </Box>
            </Grid>

            <Grid item={true} xs={true} className={classes.logoGridItem}>
              <img alt={'premise-logo'} className={clsx(classes.logo, 'smallhide')} src={`/images/${logoSrc}`} onClick={this.zoomToWorld} />
            </Grid>

            <Grid item={true}>
              {hasFeatureFlag(user, FEATURES.PLACES) && (
                <Tooltip placement="bottom" arrow={true} title="Places Product">
                  <IconButton
                    edge="start"
                    color={activeProduct === 'places' ? 'secondary' : 'inherit'}
                    onClick={() => {
                      if (activeProduct !== 'places') {
                        this.props.setGlobal({ activeProduct: 'places' });
                      }
                    }}
                  >
                    <PlaceIcon />
                  </IconButton>
                </Tooltip>
              )}

              {hasFeatureFlag(user, FEATURES.SENTIMENT) && (
                <Tooltip placement="bottom" arrow={true} title={hasGPUHardware ? 'Sentiment Product' : 'Sentiment Product requires a GPU'}>
                  <span>
                    <IconButton
                      edge="start"
                      disabled={!hasGPUHardware}
                      color={activeProduct === 'sentiment' ? 'secondary' : 'inherit'}
                      onClick={() => {
                        if (activeProduct !== 'sentiment') {
                          this.props.setGlobal({ activeProduct: 'sentiment' });
                        }
                      }}
                    >
                      <SentimentVerySatisfiedIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}

              {hasFeatureFlag(user, FEATURES.RADIO) && (
                <Tooltip placement="bottom" arrow={true} title="Wireless Product">
                  <IconButton
                    color={activeProduct === 'radio' ? 'secondary' : 'inherit'}
                    edge="start"
                    onClick={() => {
                      if (activeProduct !== 'radio') {
                        this.props.setGlobal({ activeProduct: 'radio' });
                      }
                    }}
                  >
                    <WifiIcon />
                  </IconButton>
                </Tooltip>
              )}

              {hasFeatureFlag(user, FEATURES.SIGNALS) && (
                <Tooltip placement="bottom" arrow={true} title={hasGPUHardware ? 'Signal Strength Product' : 'Signal Strength Product requires a GPU'}>
                  <span>
                    <IconButton
                      disabled={!hasGPUHardware}
                      color={activeProduct === 'signals' ? 'secondary' : 'inherit'}
                      edge="start"
                      onClick={() => {
                        if (activeProduct !== 'signals') {
                          this.props.setGlobal({ activeProduct: 'signals' });
                        }
                      }}
                    >
                      <SignalCellularAlt />
                    </IconButton>
                  </span>
                </Tooltip>
              )}

              {hasFeatureFlag(user, FEATURES.DEMOGRAPHICS) && (
                <Tooltip placement="bottom" arrow={true} title={hasGPUHardware ? 'Demographics Product' : 'Demographics Product requires a GPU'}>
                  <span>
                    <IconButton
                      disabled={!hasGPUHardware}
                      color={activeProduct === 'demographics' ? 'secondary' : 'inherit'}
                      edge="start"
                      onClick={() => {
                        if (activeProduct !== 'demographics') {
                          this.props.setGlobal({ activeProduct: 'demographics' });
                        }
                      }}
                    >
                      <PeopleIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}

              <Divider className={classes.appbarDivider} orientation="vertical" />

              <Tooltip placement="bottom" arrow={true} title="Share current view">
                <IconButton
                  color="inherit"
                  edge="start"
                  onClick={({ currentTarget }) => {
                    this.setState({ shareViewDropdownAnchorEl: currentTarget });
                  }}
                  id="share-link"
                >
                  <ReplyIcon style={{ display: 'flex', transform: 'scaleX(-1)' }} />
                </IconButton>
              </Tooltip>
              <Popover
                open={Boolean(shareViewDropdownAnchorEl)}
                anchorEl={shareViewDropdownAnchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                onClose={this.clearViewNameAnchor}
              >
                <Box className={classes.popoverContainer} id="share-popover">
                  <Typography variant="h6">Share current view</Typography>
                  <Typography variant="subtitle1">This view and its link will be added to saved views.</Typography>
                  <TextField label="Name of view" required={true} autoFocus={true} value={viewName} onChange={(evt) => this.setState({ viewName: evt.target.value })} />
                  <div className={classes.popoverContainer_btns}>
                    <Button variant="outlined" onClick={this.clearViewNameAnchor}>
                      Cancel
                    </Button>
                    <Button
                      disabled={!viewName}
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        this.props.onShareLink(viewName);
                        this.clearViewNameAnchor();
                      }}
                    >
                      Copy Link
                    </Button>
                  </div>
                </Box>
              </Popover>

              <Tooltip placement="bottom" arrow={true} title="Saved views">
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={({ currentTarget }) => {
                    this.setState({ savedViewsDropdownAnchorEl: currentTarget });
                    this.getSavedViews();
                  }}
                >
                  <FolderIcon />
                </IconButton>
              </Tooltip>
              <Popover
                open={Boolean(savedViewsDropdownAnchorEl)}
                anchorEl={savedViewsDropdownAnchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                onClose={() => this.setState({ savedViewsDropdownAnchorEl: null })}
              >
                <Box className={classes.popoverContainer}>
                  {savedViews.length === 0 ? (
                    <React.Fragment>
                      <Typography variant="h6">Saved views</Typography>
                      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
                        <ReplyIcon style={{ display: 'flex', transform: 'scaleX(-1)' }} />
                        <Typography variant="subtitle1">Save a configuration, give the view a name, and the view's name and link will pop up here for your reference.</Typography>
                      </div>
                    </React.Fragment>
                  ) : (
                    <Grid container={true} direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
                      <Grid container={true} item={true} xs={12} style={{ marginBottom: theme.spacing(2) }}>
                        <Grid item={true} xs={12} sm={6}>
                          <Typography variant="h6">Saved views</Typography>
                        </Grid>
                        <Grid item={true} xs={12} sm={6}>
                          <DeleteButton style={{ float: 'right' }} variant="outlined" onClick={this.clearSavedViews}>
                            Delete All
                          </DeleteButton>
                        </Grid>
                      </Grid>
                      {savedViews.map((v) => (
                        <Grid container={true} item={true} key={`row-${v.hash}`} xs={12}>
                          {editSavedViewOpen && editSavedViewOpen === v.hash ? (
                            this.editSavedView(v)
                          ) : (
                            <Paper className={classes.paper} elevation={0}>
                              <Grid item={true} xs={12} sm={8}>
                                <Link
                                  component="button"
                                  className={classes.savedViewLink}
                                  onClick={() => {
                                    window.open(`?h=${v.hash}`, '_blank');
                                  }}
                                  variant="subtitle1"
                                  color="secondary"
                                >
                                  {v.view_name}
                                </Link>
                              </Grid>
                              <Grid item={true} xs={12} sm={4} style={{ textAlign: 'end' }}>
                                <Tooltip placement="top" arrow={true} title="Copy link">
                                  <IconButton size="small" onClick={() => this.copyLinkToClipboard(v.hash)}>
                                    <LinkIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip placement="top" arrow={true} title="Edit name">
                                  <IconButton size="small" onClick={() => this.setState({ editSavedViewOpen: v.hash, viewName: v.view_name })}>
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip placement="top" arrow={true} title="Delete view">
                                  <IconButton size="small" onClick={() => this.deleteSavedView(v.hash)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Paper>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Box>
              </Popover>

              <Divider className={classes.appbarDivider} orientation="vertical" />

              <IconButton
                disabled={!inbox || inbox.length === 0}
                edge="start"
                onClick={(e) => {
                  // open inbox:
                  this.setState({ inboxAnchorEl: e.target });

                  // mark messages as read:
                  genericGet(`${API_HOST}/iris/v0/profile/inbox/opened`, null, () => {});
                }}
              >
                <Badge badgeContent={badgeCount} color="error">
                  <MailIcon />
                </Badge>
              </IconButton>
              <Popover
                open={Boolean(inboxAnchorEl)}
                anchorEl={inboxAnchorEl}
                onClose={() => {
                  this.setState({
                    inboxAnchorEl: null,
                    inbox: inbox.map((d) => ({ ...d, seen_on: moment().toISOString() }))
                  });
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                {inbox && <InboxPopover inbox={inbox} onDelete={this.onInboxDelete} />}
              </Popover>

              <AppMenu onSettings={onSettings} onExport={onExport} onLogout={onLogout} onAdminPanel={this.onAdminPanel} onAbout={onAbout} onShareFeedback={onShareFeedback} />
            </Grid>
          </Grid>
        </Toolbar>
      </MUIAppBar>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.app.user,
  hasGPUHardware: state.app.hasGPUHardware,
  activeProduct: state.app.activeProduct,
  configPanelOpen: state.app.configPanelOpen,
  mapCenter: state.app.mapCenter
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushSnackbar: pushSnackbarAction,
      setGlobal: setGlobalAction
    },
    dispatch
  );

AppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  user: PropTypes.object,
  hasGPUHardware: PropTypes.bool,
  activeProduct: PropTypes.string,
  currentViewRef: PropTypes.object,
  mapCenter: PropTypes.object,

  pushSnackbar: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  setGlobal: PropTypes.func.isRequired,
  onShareLink: PropTypes.func.isRequired,
  onAbout: PropTypes.func.isRequired,
  onSettings: PropTypes.func.isRequired,
  onShareFeedback: PropTypes.func.isRequired,
  configPanelOpen: PropTypes.bool.isRequired
};

AppBar.defaultProps = {};
const withRedux = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
export default withRedux(withTheme(withStyles(styles)(AppBar)));
