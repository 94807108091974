import React from 'react';
import PropTypes from 'prop-types';

// MODULES
import { makeStyles, useTheme } from '@material-ui/core/styles';

// MUI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

// ICONS
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles((theme) => ({
  formOptionGroupBtn: {
    marginRight: theme.spacing(1)
  }
}));

function ACGroupByHeader(props) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Box display="flex" alignItems="center">
      <IconButton className={classes.formOptionGroupBtn} size="small" style={{ color: theme.palette.text.secondary }} onClick={props.onClick}>
        {props.filter ? <ArrowRightIcon /> : <ArrowDropDownIcon />}
      </IconButton>
      <Typography style={{ display: 'inline-flex' }} onClick={props.onClick}>
        {props.title}
      </Typography>
    </Box>
  );
}

ACGroupByHeader.propTypes = {
  title: PropTypes.string,
  filter: PropTypes.bool,
  onClick: PropTypes.func
};

export default React.memo(ACGroupByHeader);
