import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// MODULES
import { withStyles, withTheme } from '@material-ui/core/styles';
import { matchSorter } from 'match-sorter';

// MUI
import Autocomplete from '@material-ui/lab/Autocomplete';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import { irisApi } from '../../../../services';
import {
  selectForm as selectFormAction,
  deselectForm as deselectFormAction,
  selectGeographies as selectGeographiesAction,
  selectQuestion as selectQuestionAction
} from '../../sentimentSlice';

// OURS
import ACOption from './ACOption';
import ACGroupByHeader from './ACGroupByHeader';
import QuestionsWrapper from './QuestionsWrapper';
import VACGeographies from '../../components/config/VACGeographies';

// GLOBALS
import { PRODUCT_TEMPORAL } from '../../constants';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(1)
  },
  freeTextFilter: {
    margin: `${theme.spacing(2)}px 0px`
  },
  divider: {
    margin: theme.spacing(1, 4, 3, 4),
    backgroundColor: theme.palette.background.main
  },
  vacGeography: {
    margin: theme.spacing(1, 0)
  }
});
class FormQuestionSelector extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      freeTextFilter: '',
      formACValue: '',

      hideClientForms: false,
      hideAnchorForms: false
    };

    // BIND
    this.formACFilter = this.formACFilter.bind(this);
    this.onQuestionFilterChange = this.onQuestionFilterChange.bind(this);
    this.hideAnchorForms = this.hideAnchorForms.bind(this);
    this.hideClientForms = this.hideClientForms.bind(this);
    this.onSelectForm = this.onSelectForm.bind(this);
    this.onRemoveForm = this.onRemoveForm.bind(this);
    this.onAutocompleteInputChanged = this.onAutocompleteInputChanged.bind(this);
    this.onGeographiesChanged = this.onGeographiesChanged.bind(this);
  }
  componentDidMount() {
    const { getAvailableForms, getAvailableGeographies, selectedForms, getAvailableQuestions, selectedGeographies } = this.props;
    // Start a subscription for the component to the cached data
    const { unsubscribe: unsubscribeGetAvailableForms } = getAvailableForms({ selectedGeographies });
    const { unsubscribe: unsubscribeGetAvailableGeographies } = getAvailableGeographies();

    // Store the unsubscribe promise for later use
    this.unsubscribeGetAvailableForms = unsubscribeGetAvailableForms;
    this.unsubscribeGetAvailableGeographies = unsubscribeGetAvailableGeographies;

    // not sure: if i need this, maybe for state restore?
    if (selectedForms?.length > 0) {
      const { unsubscribe: unsubscribeGetAvailableQuestions } = getAvailableQuestions(selectedForms, selectedGeographies);
      this.unsubscribeGetAvailableQuestions = unsubscribeGetAvailableQuestions;
    }
    // not sure: do i need to do automatic question selection here?
  }
  componentWillUnmount() {
    this.unsubscribeGetAvailableForms?.();
    this.unsubscribeGetAvailableGeographies?.();
    this.unsubscribeGetAvailableQuestions?.();
  }
  componentDidUpdate(prevProps) {
    const { getAvailableForms, selectedForms, getAvailableQuestions, selectedGeographies, selectedQuestions, availableQuestions, selectQuestion } = this.props;
    if (selectedForms !== prevProps.selectedForms) {
      // cancel any previous request:
      this.unsubscribeGetAvailableQuestions?.();
      // trigger new request:
      const { unsubscribe } = getAvailableQuestions(selectedForms);
      // update saved request:
      this.unsubscribeGetAvailableQuestions = unsubscribe;
    }
    if (selectedGeographies !== prevProps.selectedGeographies) {
      // cancel any previous request:
      this.unsubscribeGetAvailableForms?.();
      // trigger new request:
      const { unsubscribe } = getAvailableForms({ selectedGeographies });
      // update saved request:
      this.unsubscribeGetAvailableForms = unsubscribe;
    }

    if (selectedQuestions?.length === 0 && availableQuestions.data?.length > 0) {
      for (const question of availableQuestions.data) {
        if (question.user_request_completed_on && question.ordering && Object.keys(question.ordering).length > 0) {
          selectQuestion({
            question,
            checked: true,
            overwrite: true
          });
          break;
        }
      }
    }
  }
  formACFilter(options, { inputValue }) {
    const { selectedForms } = this.props;
    // don't include forms already selected:
    const form_ids = selectedForms.map((d) => d.form_id);
    options = options.filter((d) => !form_ids.includes(d.form_id));

    // bypass matchSorters for click + scroll folks:
    if (!inputValue) return options;

    const anchorOptions = options.filter((d) => d.is_anchor);
    const clientOptions = options.filter((d) => !d.is_anchor);
    const matchSorterParams = { keys: ['form_name', 'form_id', 'project_name'] };

    const terms = inputValue.split(' ');
    const anchorSorted = terms.reduceRight((results, term) => matchSorter(results, term, matchSorterParams), anchorOptions);
    const clientSorted = terms.reduceRight((results, term) => matchSorter(results, term, matchSorterParams), clientOptions);

    return clientSorted.concat(anchorSorted);
  }

  onAutocompleteInputChanged(e) {
    this.setState({
      formACValue: e.target.value
    });
  }

  onQuestionFilterChange(e) {
    this.setState({
      freeTextFilter: e.target.value
    });
  }

  updateFilteredForms(hideAnchorForms, hideClientForms) {
    const availableForms = this.props.availableForms.data ?? [];
    let filteredForms = [];
    const anchorForms = [];
    const clientForms = [];

    // sort forms into client,anchor:
    for (const form of availableForms) {
      if (form.is_anchor) anchorForms.push(form);
      else clientForms.push(form);
    }

    if (!hideAnchorForms) {
      filteredForms = [...filteredForms, ...anchorForms];
    } else {
      filteredForms.push({
        is_anchor: true,
        numHidden: anchorForms.length
      });
    }
    if (!hideClientForms) {
      filteredForms = [...filteredForms, ...clientForms];
    } else {
      filteredForms.push({
        is_anchor: false,
        numHidden: clientForms.length
      });
    }

    this.setState({
      hideAnchorForms,
      hideClientForms,
      filteredForms
    });
  }
  hideAnchorForms() {
    const { hideAnchorForms, hideClientForms } = this.state;
    this.updateFilteredForms(!hideAnchorForms, hideClientForms);
  }
  hideClientForms() {
    const { hideAnchorForms, hideClientForms } = this.state;
    this.updateFilteredForms(hideAnchorForms, !hideClientForms);
  }
  onSelectForm(_, form) {
    this.props.selectForm(form);
  }
  onRemoveForm(form) {
    this.props.deselectForm(form);
  }
  onGeographiesChanged(selectedGeographies) {
    // { selectedGeographies, resetPolygonRenderLevel }
    this.props.selectGeographies(selectedGeographies);
  }
  render() {
    const { classes, availableForms, availableQuestions, activeView, selectedForms, onConfigureNewQuestion, availableGeographies, selectedGeographies } = this.props;
    const { freeTextFilter, formACValue, hideClientForms, hideAnchorForms, filteredForms } = this.state;

    // [!] do not move. These need to be "constant" for grouping to work within <AutoComplete />
    const acHeaderAnchor = <ACGroupByHeader key="anchor" title="🌎 Foundational Forms" filter={hideAnchorForms} onClick={this.hideAnchorForms} />;
    const acHeaderClient = <ACGroupByHeader key="client" title="🏢 Client Forms" filter={hideClientForms} onClick={this.hideClientForms} />;

    const { data: availableGeographiesData = [] } = availableGeographies;
    const { data: availableFormsData = [] } = availableForms;
    const { data: availableQuestionsData = [], isLoading: availableQuestionsLoading } = availableQuestions;

    const formOptions = filteredForms ?? availableFormsData ?? [];

    return (
      <Paper className={classes.paper}>
        <VACGeographies
          className={classes.vacGeography}
          availableGeographies={availableGeographiesData}
          selectedGeographies={selectedGeographies}
          onGeographiesChanged={this.onGeographiesChanged}
          label="Geography"
        />
        <Autocomplete
          groupBy={(form) => {
            if (form.is_anchor) return acHeaderAnchor;
            else return acHeaderClient;
          }}
          disabled={formOptions.length === 0 || (activeView === PRODUCT_TEMPORAL && selectedForms.length > 0)}
          filterOptions={this.formACFilter}
          className={classes.marginTop}
          value={null}
          inputValue={formACValue}
          disableClearable={true}
          options={formOptions}
          getOptionLabel={(option) => option.form_name} // `${option.form_id} | ${common.middleTrunc(option.form_name, 30)}`}
          getOptionDisabled={(option) => Number.isFinite(option.numHidden)}
          renderOption={(option) => <ACOption option={option} />}
          onChange={this.onSelectForm}
          renderInput={(params) => <TextField onChange={this.onAutocompleteInputChanged} {...params} label={'Form'} placeholder={'Search by name or id'} variant="outlined" />}
        />
        {selectedForms.length > 0 && (
          <List>
            {selectedForms.map((d) => (
              <ACOption key={d.form_id} option={d} selected={true} onRemove={this.onRemoveForm} />
            ))}
          </List>
        )}

        {availableQuestionsLoading && (
          <Box display="flex" justifyContent="center" alignItems="center" width="100%" height={107}>
            <CircularProgress color="secondary" />
          </Box>
        )}

        {!availableQuestionsLoading && (
          <React.Fragment>
            <Divider classes={{ root: classes.divider }} />
            <Typography variant="h5">Available Questions</Typography>
            <Typography variant="caption" color="textSecondary">
              Data coverage is a calculation of whether the aggregated, or smoothed, product is likely to display well based on total volumes, geographic expanse, and the period of
              time over which the data are spread.
            </Typography>
            <TextField
              fullWidth={true}
              size="small"
              className={classes.freeTextFilter}
              label="Filter Questions"
              variant="outlined"
              value={freeTextFilter}
              onChange={this.onQuestionFilterChange}
            />
            <QuestionsWrapper availableQuestions={availableQuestionsData} onConfigureNewQuestion={onConfigureNewQuestion} freeTextFilter={freeTextFilter} />
          </React.Fragment>
        )}
      </Paper>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      selectForm: selectFormAction,
      deselectForm: deselectFormAction,
      selectGeographies: selectGeographiesAction,
      selectQuestion: selectQuestionAction,

      getAvailableForms: irisApi.endpoints.getAvailableForms.initiate,
      getAvailableQuestions: irisApi.endpoints.getAvailableQuestions.initiate,
      getAvailableGeographies: irisApi.endpoints.getAvailableGeographies.initiate
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  activeView: state.sentiment.activeView,
  questionMerging: state.sentiment.questionMerging,

  availableForms: irisApi.endpoints.getAvailableForms.select({ selectedGeographies: state.sentiment.selectedGeographies })(state),
  availableQuestions: irisApi.endpoints.getAvailableQuestions.select(state.sentiment.selectedForms)(state),
  availableGeographies: irisApi.endpoints.getAvailableGeographies.select()(state),

  selectedForms: state.sentiment.selectedForms,
  selectedQuestions: state.sentiment.selectedQuestions,
  selectedGeographies: state.sentiment.selectedGeographies
});

FormQuestionSelector.propTypes = {
  // MUI
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  // REDUX: RTK Requests
  getAvailableForms: PropTypes.func.isRequired,
  getAvailableQuestions: PropTypes.func.isRequired,
  getAvailableGeographies: PropTypes.func.isRequired,

  // REDUX: RTK Responses
  availableForms: PropTypes.object,
  availableQuestions: PropTypes.object,
  availableGeographies: PropTypes.object,

  // REDUX: Data
  activeView: PropTypes.number.isRequired,
  questionMerging: PropTypes.bool.isRequired,
  selectedForms: PropTypes.array,
  selectedQuestions: PropTypes.array,
  selectedGeographies: PropTypes.array,

  // REDUX: Callbacks
  selectForm: PropTypes.func.isRequired,
  deselectForm: PropTypes.func.isRequired,
  selectGeographies: PropTypes.func.isRequired,
  selectQuestion: PropTypes.func.isRequired,

  // PROPS:
  onConfigureNewQuestion: PropTypes.func.isRequired
};

FormQuestionSelector.defaultProps = {};
const withRedux = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
export default withRedux(withTheme(withStyles(styles)(FormQuestionSelector)));
