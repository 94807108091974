import { createSlice } from '@reduxjs/toolkit';

export const METRICS = {
  MEAN_SIGNAL: 'mean_signal',
  MAX_SIGNAL: 'max_signal',
  SIGNAL_CHANGE: 'signal_change',
  NUM_OF_USERS: 'n_user',
  NUM_OF_OBSERVATIONS: 'n_observations'
};

const initialState = {
  // Filters
  networkNameFilter: null,
  networkTypeFilter: null,
  geographyFilters: [],
  thresholdFilter: [-200, 0],
  // Render
  activeMetric: METRICS.MEAN_SIGNAL,
  categorizedMetric: true,
  h3level: 4,
  // Legend Settings
  colorRamp: 'interpolateGreens',
  colorRampInverted: false,
  vectorOpacity: 0.5
};

const signalsSlice = createSlice({
  name: 'signals',
  initialState,
  reducers: {
    setNetworkNameFilter(state, action) {
      state.networkNameFilter = action.payload;
    },
    setNetworkTypeFilter(state, action) {
      state.networkTypeFilter = action.payload;
    },
    setGeographyFilters(state, action) {
      state.geographyFilters = action.payload;
    },
    setThresholdFilter(state, action) {
      state.thresholdFilter = action.payload;
    },
    setActiveMetric(state, action) {
      state.activeMetric = action.payload;
      if (action.payload === METRICS.MAX_SIGNAL || action.payload === METRICS.MEAN_SIGNAL || action.payload === METRICS.SIGNAL_CHANGE) {
        // by default, we want this to be true for these metrics
        state.categorizedMetric = true;
      } else {
        state.categorizedMetric = false;
      }
    },
    setCategorizedMetric(state, action) {
      state.categorizedMetric = action.payload;
    },
    setH3Level(state, action) {
      state.h3level = action.payload;
    },
    setColorRamp(state, action) {
      state.colorRamp = action.payload;
    },
    setColorRampInverted(state, action) {
      state.colorRampInverted = action.payload;
    },
    setVectorOpacity(state, action) {
      state.vectorOpacity = action.payload;
    },
    // helper
    toggleBool(state, { payload: variableName }) {
      if (typeof state[variableName] !== 'boolean') {
        console.warn(`(signalsSlice) 'toggleBool' dispatched for non-boolean type variable: "${variableName}"; ignoring...`);
      } else {
        state[variableName] = !state[variableName];
      }
    }
  }
});

export const signalsSelectors = {
  selectNetworkNameFilter: (state) => state.signals.networkNameFilter,
  selectNetworkTypeFilter: (state) => state.signals.networkTypeFilter,
  selectGeographyFilters: (state) => state.signals.geographyFilters,
  selectThresholdFilter: (state) => state.signals.thresholdFilter,
  selectActiveMetric: (state) => state.signals.activeMetric,
  selectCategorizedMetric: (state) => state.signals.categorizedMetric,
  selectH3level: (state) => state.signals.h3level,
  selectColorRamp: (state) => state.signals.colorRamp,
  selectColorRampInverted: (state) => state.signals.colorRampInverted,
  selectVectorOpacity: (state) => state.signals.vectorOpacity
};

export const signalsActions = signalsSlice.actions;

export default signalsSlice.reducer;
