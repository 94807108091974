const buildOrdering = (selectedQuestions) => {
  const orderMap = {};
  const ordering = [];

  for (const q of selectedQuestions) {
    for (const [label, order] of Object.entries(q.ordering)) {
      if (order === null) {
        continue;
      }
      if (orderMap[order]) {
        orderMap[order].responses.push(label);
      } else {
        const newMap = {
          label,
          order: +order,
          responses: [label]
        };
        ordering.push(newMap);
        orderMap[order] = newMap;
      }
    }
  }

  ordering.sort((a, b) => {
    if (a.order === b.order) return 0;
    if (a.order > b.order) return 1;
    return -1;
  });

  const orderingValues = ordering
    .map((d) => d.order)
    .sort((a, b) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    });

  // orderingValues e.g.:
  // [
  //  0,
  //  0.25,
  //  0.5,
  //  0.75,
  //  1
  // ]

  // ordering .e.g:
  // [
  //  {
  //   "label": "Very unconcerned",
  //   "order": 0,
  //   "responses": [
  //    "Very unconcerned"
  //   ]
  //  }
  // ]

  return { ordering, orderingValues };
};

export default { buildOrdering };
