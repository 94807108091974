import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { signalsActions, signalsSelectors, METRICS } from '../signalsSlice';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Slider from '@material-ui/core/Slider';

// Ours
import ResultSettings from '../../ResultSettings';

const useStyles = makeStyles((theme) => ({
  renderSettings: {
    margin: theme.spacing(2)
  },
  h3: {
    width: '100%',
    marginTop: theme.spacing(2)
  },
  slider: {
    width: '80%',
    marginTop: 50,
    marginLeft: '10%'
  },
  disabled: {
    opacity: 0.5
  }
}));

const RenderSettings = () => {
  const [manualAggregation, setManualAggregation] = useState(false);
  const [h3Level, setH3Level] = useState(4);

  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedMetric = useSelector(signalsSelectors.selectActiveMetric);
  const zoomLevel = useSelector((state) => state.app.mapZoom);

  const changeMetric = (e) => {
    dispatch(signalsActions.setActiveMetric(e.target.value));
  };

  useEffect(() => {
    if (!manualAggregation) {
      // Mike's h3 level algo
      let h3l = +zoomLevel - 2;
      // clip upper & lower bounds of the zoom level
      if (Number.isFinite(h3l)) {
        h3l = Math.round(zoomLevel);
        if (h3l < 4) {
          h3l = 4;
        } else if (h3l > 8) {
          h3l = 9;
        }
      } else {
        h3l = 4;
      }
      setH3Level(h3l);
      dispatch(signalsActions.setH3Level(parseInt(h3l)));
    }
  }, [zoomLevel, manualAggregation, dispatch]);

  const onChangeAggregationSetting = useCallback((_, checked) => setManualAggregation(checked), []);
  const onChangeH3 = useCallback((_, value) => setH3Level(value), []);
  const onChangeH3Commited = useCallback((_, value) => dispatch(signalsActions.setH3Level(parseInt(value))), [dispatch]);

  return (
    <ResultSettings label="Render Settings">
      <Box className={classes.renderSettings}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Metric</FormLabel>
          <RadioGroup aria-label="metric" name="metric-radio-group" value={selectedMetric} onChange={changeMetric}>
            <FormControlLabel value={METRICS.MEAN_SIGNAL} control={<Radio />} label="Mean Signal" />
            <FormControlLabel value={METRICS.MAX_SIGNAL} control={<Radio />} label="Max Signal" />
            <FormControlLabel value={METRICS.SIGNAL_CHANGE} control={<Radio />} label="Signal Change (90 days)" />
            <FormControlLabel value={METRICS.NUM_OF_USERS} control={<Radio />} label="Number of Users" />
            <FormControlLabel value={METRICS.NUM_OF_OBSERVATIONS} control={<Radio />} label="Number of Observations" />
          </RadioGroup>
        </FormControl>

        <Box className={classes.h3}>
          <FormGroup>
            <FormControlLabel control={<Checkbox />} label="Manual Aggregation Level" checked={manualAggregation} onChange={onChangeAggregationSetting} />
          </FormGroup>
          <Paper variant="outlined" className={clsx(classes.configItem, !manualAggregation && classes.disabled)}>
            <Slider
              className={classes.slider}
              disabled={!manualAggregation}
              value={h3Level}
              min={4}
              max={9}
              onChange={onChangeH3}
              onChangeCommitted={onChangeH3Commited}
              valueLabelDisplay="on"
              color="secondary"
              size="small"
              marks={true}
            />
          </Paper>
        </Box>
      </Box>
    </ResultSettings>
  );
};

export default React.memo(RenderSettings);
