export const sortTypesToNiceName = {
  n_observations: 'Observations',
  event_time_first_seen: 'First Seen',
  event_time_last_seen: 'Last Seen',
  network_name: 'Network Provider',
  ssids: 'Network Name (SSID)'
};
export const sortOptions = Object.keys(sortTypesToNiceName);

export const MAX_ACCURACY = 250;
export const DEFAULT_ACCURACY = MAX_ACCURACY;

export const movementTypesLookup = {
  'non-stationary': 'Mobile hotspot',
  stationary: 'Stationary',
  unknown: 'Unknown',
  Unknown: 'unknown',
  Stationary: 'stationary',
  'Mobile hotspot': 'non-stationary'
};
export const movementTypes = ['Stationary', 'Mobile hotspot', 'Unknown'];

export const dataTypesToFilters = {
  wifi: ['last_seen', 'countries', 'ssid', 'bssid', 'manufacturers', 'manufacturer_countries', 'movement_type', 'accuracy'],
  cell: ['last_seen', 'countries', 'network_names', 'network_types', 'tower_id']
};

export const filterForWhichDataType = (filter) => {
  const inW = dataTypesToFilters.wifi.includes(filter);
  const inC = dataTypesToFilters.cell.includes(filter);
  if (inW && inC) {
    return 'both';
  } else if (inW) {
    return 'wifi';
  } else {
    return 'cell';
  }
};
