import React, { useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// MODULES
import moment from 'moment';
import { capitalCase } from 'change-case';

// MUI
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

// MUI: ICONS
import CloseIcon from '@material-ui/icons/Close';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import HelpIcon from '@material-ui/icons/Help';

// Ours
import Fetching from '../Fetching';
import PopupBarChart from './components/PopupBarChart';
import { getDetailPanelData } from './services';
import { sentimentSelectors, selectHasc } from './sentimentSlice';
import { PRODUCT_COMPARE, PRODUCT_TEMPORAL } from './constants';
// import { pushSnackbar as pushSnackbarAction } from '../../actions';
// import { reportIssue } from 'iris-util'; // eslint-disable-line import/no-unresolved

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    width: 400,
    bottom: 0,
    padding: theme.spacing(1),
    overflowY: 'auto'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 9, // (48 - 30) / 2
    zIndex: 2
  },
  formContainer: {
    marginBottom: theme.spacing(2)
  },
  detailContainer: {
    margin: theme.spacing(2, 0)
  },
  accordionHeader: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  chartGutters: {
    margin: theme.spacing(0, 2)
  },
  buttonGutters: {
    margin: theme.spacing(0, 2)
  }
}));

const DetailPanel = (props) => {
  const { left, top } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  // STATE
  const [showDetails, setShowDetails] = useState(window.localStorage['detailPanel_showDetails'] === 'true');

  // REDUX
  const selectedHasc = useSelector((state) => state.sentiment.selectedHasc);
  const detailPanelQueryArgs = useSelector(sentimentSelectors.selectDetailPanel);

  // REDUX: DERIVED
  const { selectedForms, selectedQuestions, activeView, selectedDateRange, selectedDateRangeLeft, selectedDateRangeRight, selectedDemographicsLeft, selectedDemographicsRight } =
    detailPanelQueryArgs;

  // QUERIES
  const skipTemporal = !selectedHasc || !selectedForms?.length || !selectedQuestions?.length;
  const dataQuery = getDetailPanelData.useQuery(detailPanelQueryArgs, { skip: skipTemporal });

  // USECALLBACK
  const onClose = useCallback(() => {
    dispatch(selectHasc(null));
  }, [dispatch]);
  const onToggleDetails = useCallback(() => {
    setShowDetails(!showDetails);
  }, [showDetails]);

  const summaryFromDateRange = (dr) => {
    const ff = 'MMMM D, YYYY';
    const fs = 'MMMM D';

    if (dr.start && dr.end) {
      const s = moment(dr.start);
      const e = moment(dr.end);
      return ['Data between', s.year() === e.year() ? `${s.format(fs)} - ${e.format(ff)}` : `${s.format(ff)} - ${e.format(ff)}`];
    } else if (dr.start) {
      const s = moment(dr.start);
      return ['Data after', s.format(ff)];
    } else if (dr.end) {
      const e = moment(dr.end);
      return ['Data before', e.format(ff)];
    } else {
      return '';
    }
  };

  // USEMEMO
  const combinedDetails = useMemo(() => {
    if (skipTemporal) return null;

    const details = {
      formIds: selectedForms.map((d) => d.form_id).join(', '),
      formName: selectedForms[0].form_name,
      questionName: selectedQuestions[0].question_proper,
      left: {
        summary: []
      },
      right: {
        summary: []
      }
    };

    if (activeView === PRODUCT_TEMPORAL) {
      if (selectedDateRange) {
        const dateRangeSummary = summaryFromDateRange(selectedDateRange);
        if (dateRangeSummary) details.left.summary.push(dateRangeSummary);
      }
    } else {
      // Dates:
      if (selectedDateRangeLeft) {
        const dateRangeSummary = summaryFromDateRange(selectedDateRangeLeft);
        if (dateRangeSummary) details.left.summary.push(dateRangeSummary);
      }
      if (selectedDateRangeRight) {
        const dateRangeSummary = summaryFromDateRange(selectedDateRangeRight);
        if (dateRangeSummary) details.right.summary.push(dateRangeSummary);
      }

      // Demographics:
      const demoKeysLeft = Object.keys(selectedDemographicsLeft ?? {}).sort();
      const demoKeysRight = Object.keys(selectedDemographicsRight ?? {}).sort();

      for (const k of demoKeysLeft) {
        details.left.summary.push([capitalCase(k), selectedDemographicsLeft[k].join(', ')]);
      }

      for (const k of demoKeysRight) {
        details.right.summary.push([capitalCase(k), selectedDemographicsRight[k].join(', ')]);
      }
    }

    return details;
  }, [
    selectedDateRange,
    selectedDateRangeLeft,
    selectedDateRangeRight,
    activeView,
    skipTemporal,
    selectedForms,
    selectedQuestions,
    selectedDemographicsLeft,
    selectedDemographicsRight
  ]);

  const isFetching = dataQuery.isFetching;

  if (!selectedHasc || !dataQuery.data || dataQuery.isError || !combinedDetails) return null;
  const sides = Object.keys(dataQuery.data).includes('right') ? ['left', 'right'] : ['left'];
  return (
    <>
      {isFetching && (
        <Box className={classes.container} style={{ left, top }}>
          <Fetching />
        </Box>
      )}
      <Box className={classes.container} style={{ left, top }}>
        <IconButton size="small" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" noWrap={true}>
          {combinedDetails.formName}
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <FingerprintIcon />
            </ListItemIcon>
            <ListItemText primary="Form Id(s)" secondary={combinedDetails.formIds} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary="Question" secondary={combinedDetails.questionName} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <GpsFixedIcon />
            </ListItemIcon>
            <ListItemText primary="Location" secondary={dataQuery.data.left?.name?.join(', ') ?? selectedHasc} />
          </ListItem>
        </List>

        {sides.map((side) => {
          return (
            <Box key={side}>
              <Box className={classes.formContainer}>
                {selectedForms.map(({ form_id }) => (
                  <React.Fragment key={form_id}>
                    <Box className={classes.detailContainer}>
                      {activeView === PRODUCT_COMPARE && <Typography variant="h6">{`${capitalCase(side)} Details`}</Typography>}

                      {combinedDetails[side]?.summary?.length > 0 && (
                        <List>
                          {combinedDetails[side].summary.map((d) => (
                            <ListItem key={d[0]}>
                              <ListItemText primary={d[0]} secondary={d[1]} />
                            </ListItem>
                          ))}
                        </List>
                      )}

                      <Box className={classes.chartGutters}>
                        <PopupBarChart
                          data={dataQuery.data[side]}
                          otherData={dataQuery.data[side === 'left' ? 'right' : 'left']}
                          width={400 - theme.spacing(6 /* container padding px  + gutters */)}
                        />
                      </Box>
                    </Box>
                  </React.Fragment>
                ))}
              </Box>
            </Box>
          );
        })}
        <Typography variant="h6">Calculations</Typography>
        <List>
          {Object.keys(dataQuery.data.text.basic).map((d) => (
            <ListItem key={d}>
              <ListItemText primary={d} secondary={dataQuery.data.text.basic[d]} />
            </ListItem>
          ))}
          {showDetails &&
            Object.keys(dataQuery.data.text.advanced).map((d) => (
              <ListItem key={d}>
                <ListItemText primary={d} secondary={dataQuery.data.text.advanced[d]} />
              </ListItem>
            ))}
        </List>

        <Button size="small" color="secondary" onClick={onToggleDetails} className={classes.buttonGutters}>
          {showDetails ? 'Show Less' : 'Show More'}
        </Button>
      </Box>
    </>
  );
};

DetailPanel.propTypes = {
  left: PropTypes.number,
  top: PropTypes.number
};
DetailPanel.defaultProps = {};

export default React.memo(DetailPanel);
