import { irisApi } from '../../services';

export const signalsApi = irisApi.injectEndpoints({
  endpoints: (builder) => ({
    // Returns hexagon data for deckmap based on metric
    getH3: builder.query({
      query: ({ mapZoom, metric, mapBounds, networkNamesFilter, networkTypesFilter, geographyFilters, thresholdFilter, h3l }) => ({
        url: `/signals/v0/signals/h3/within/${mapZoom}`,
        method: 'POST',
        body: {
          metric,
          geom: mapBounds,
          network_name: networkNamesFilter,
          network_type: networkTypesFilter,
          countries: geographyFilters,
          threshold: thresholdFilter,
          h3l
        },
        responseHandler: async (response) => window.irisProto.Hexagons.decode(new Uint8Array(await response.arrayBuffer())).hexagons.map((d) => ({ ...d }))
      })
    }),
    // Returns metadata like all network names and all network types
    getMetadata: builder.query({
      query: () => ({
        url: '/signals/v0/signals/meta',
        method: 'GET'
      })
    })
  })
});

export const { getH3, getMetadata } = signalsApi.endpoints;
