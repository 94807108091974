import { withTheme, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

// ours
import PlacePaper from '../../../PlacePaper';
import IrisModal from './IrisModal';
import { ValidationMap } from '@premisedata/iris-components';
import { LEFT_PANEL_WIDTH } from 'iris-config'; // eslint-disable-line import/no-unresolved

const styles = (theme) => ({
  box: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    '& > button': {
      margin: `0 ${theme.spacing(2)}px`
    }
  },
  paperSpacer: {
    '& > div': {
      marginBottom: theme.spacing(2)
    }
  }
});

class JoinModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      complete: false
    };
  }
  componentDidUpdate(prevProps) {
    const { complete } = this.state;
    const { joinPlace } = this.props;
    const { joinPlace: prevJoinPlace } = prevProps;
    if (complete && joinPlace !== prevJoinPlace) {
      this.setState({
        complete: false
      });
    }
  }
  render() {
    const { complete } = this.state;
    const { selectedPlace, joinPlace, classes, submissions, joinSubmissions, userdataLayersById } = this.props;
    if (!selectedPlace || !joinPlace) return null;

    return (
      <IrisModal width={LEFT_PANEL_WIDTH * 2} height={500}>
        <Grid
          container={true}
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          style={{
            // 52px : 32px button height + margins
            height: 'calc(100% - 52px)'
          }}
        >
          <Grid item={true} xs={6} className={classes.paperSpacer}>
            <PlacePaper place={selectedPlace} enableHover={false} userdataLayerMetadata={userdataLayersById?.[selectedPlace.type]} />
            <PlacePaper place={joinPlace} enableHover={false} userdataLayerMetadata={userdataLayersById?.[joinPlace.type]} />
          </Grid>
          <Grid item={true} xs={6} style={{ height: '100%', width: '100%' }}>
            <ValidationMap
              activePlace={false}
              submissions={submissions.concat(joinSubmissions)}
              places={[
                { ...selectedPlace, place_id: '1' },
                { ...joinPlace, place_id: '2' }
              ]}
            />
          </Grid>
        </Grid>

        <Box className={classes.box}>
          <Button variant="outlined" color="default" onClick={this.props.onCancel}>
            Cancel
          </Button>
          <Button
            disabled={complete}
            variant="outlined"
            color="secondary"
            onClick={() => {
              this.setState({
                complete: true
              });
              this.props.onComplete();
            }}
          >
            Join
          </Button>
        </Box>
      </IrisModal>
    );
  }
}

JoinModal.propTypes = {
  // ui / ux
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  // callbacks
  onCancel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,

  // data
  selectedPlace: PropTypes.object,
  joinPlace: PropTypes.object,

  submissions: PropTypes.array,
  joinSubmissions: PropTypes.array,

  userdataLayersById: PropTypes.object
};

JoinModal.defaultProps = {
  submissions: [],
  joinSubmissions: []
};

export default withTheme(withStyles(styles)(JoinModal));
