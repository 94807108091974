import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';

// MODULES
import { InfiniteLoader, List, AutoSizer } from 'react-virtualized';

// UI/UX
import { useTheme } from '@material-ui/core/styles';

// OURS
import Fetching from './Fetching';

// eslint-disable-next-line react/display-name
const VirtualizedList = forwardRef(({ isRowLoaded, loadMoreRows, rowRenderer, rowCount, rowHeight, minimumBatchSize, loading, passedKey }, ref) => {
  // Refs
  const refList = useRef();

  // Mui
  const theme = useTheme();

  // UI/UX
  const listStyle = { padding: theme.spacing(1) };

  // forwarded ref
  useImperativeHandle(
    ref,
    () => ({
      forceUpdateGrid: () => {
        if (refList.current) {
          refList.current.forceUpdateGrid();
        } else {
          console.warn('VirtualizedList::forceUpdateGrid(): ref missing');
        }
      }
    }),
    []
  );

  const returnList = (width, height) => {
    if (isRowLoaded && loadMoreRows) {
      return (
        <InfiniteLoader isRowLoaded={isRowLoaded} loadMoreRows={loadMoreRows} rowCount={rowCount} minimumBatchSize={minimumBatchSize}>
          {({ onRowsRendered, registerChild }) => (
            <List
              style={listStyle}
              ref={(r) => {
                refList.current = r;
                registerChild(r);
              }}
              key={passedKey}
              onRowsRendered={onRowsRendered}
              height={height - theme.spacing(1)}
              width={width}
              rowCount={rowCount}
              rowHeight={rowHeight}
              rowRenderer={rowRenderer}
            />
          )}
        </InfiniteLoader>
      );
    } else {
      return (
        <List
          style={listStyle}
          ref={refList}
          key={passedKey}
          height={height - theme.spacing(1)}
          width={width}
          rowCount={rowCount}
          rowHeight={rowHeight}
          rowRenderer={rowRenderer}
        />
      );
    }
  };

  if (loading) return <Fetching />;
  if (rowCount === 0) return null;
  return <AutoSizer>{({ width, height }) => returnList(width, height)}</AutoSizer>;
});

VirtualizedList.propTypes = {
  rowCount: PropTypes.number,
  rowHeight: PropTypes.number,
  minimumBatchSize: PropTypes.number,
  loading: PropTypes.bool,
  passedKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  rowRenderer: PropTypes.func,
  loadMoreRows: PropTypes.func,
  isRowLoaded: PropTypes.func
};

VirtualizedList.defaultProps = {};

export default VirtualizedList;
