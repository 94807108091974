import React, { PureComponent } from 'react';
import { withStyles, withTheme, alpha } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import numeral from 'numeral';

// UI/UX
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// ICONS
import WifiIcon from '@material-ui/icons/Wifi';

// OURS
import { toUTCString } from 'iris-util'; // eslint-disable-line import/no-unresolved

const styles = (theme) => ({
  active: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.4),
    color: theme.palette.getContrastText(alpha(theme.palette.secondary.main, 0.4)),
    border: `1px solid ${theme.palette.secondary.main}`
  },
  normal: {
    border: `1px solid ${alpha(theme.palette.secondary.dark, 0.0)}`
  },
  inlineIcon: {
    width: '1rem',
    height: '1rem',
    fontSize: '1rem',
    marginRight: theme.spacing(0.5),
    verticalAlign: 'middle',
    display: 'inline-flex',
    paddingBottom: 2
  },
  row: {
    padding: theme.spacing(1),
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.dark, 0.1),
      border: `1px solid ${alpha(theme.palette.secondary.dark, 1.0)}`
    }
  }
});

class WifiPaper extends PureComponent {
  constructor(props) {
    super(props);

    // BIND
    this.onMouseOut = this.onMouseOut.bind(this);
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onClick = this.onClick.bind(this);
  }
  onMouseOver() {
    if (this.props.onHover) {
      this.props.onHover(this.props.wifi);
    }
  }
  onMouseOut() {
    if (this.props.onHover) {
      this.props.onHover();
    }
  }
  onClick() {
    if (this.props.onSelect) {
      this.props.onSelect(this.props.wifi._id);
    }
  }
  render() {
    const { classes, style, active, wifi } = this.props;
    return (
      <Paper
        style={style}
        className={clsx({ [classes.row]: true, [classes.normal]: !active, [classes.active]: active })}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        onClick={this.onClick}
      >
        <Typography variant="body1" style={{ fontStyle: wifi.ssids ? 'normal' : 'italic' }} noWrap={true}>
          <WifiIcon className={classes.inlineIcon} />
          {wifi.ssids ? wifi.ssids : 'Untitled / Hidden'}
        </Typography>
        <Typography variant="caption" display="block" noWrap={true}>
          {wifi.source_id}
        </Typography>
        <Typography variant="caption" display="block" noWrap={true}>{`${toUTCString(wifi.event_time_first_seen)} \u2194 ${toUTCString(wifi.event_time_last_seen)} (${numeral(
          wifi.n_observations
        ).format('0,0')})`}</Typography>
      </Paper>
    );
  }
}

const mapStateToProps = (/* state */) => ({
  // hasGPU: state.app.hasGPU,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      /* function names */
    },
    dispatch
  );

WifiPaper.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  wifi: PropTypes.object.isRequired,
  style: PropTypes.object,
  active: PropTypes.bool,

  onHover: PropTypes.func,
  onSelect: PropTypes.func
};

WifiPaper.defaultProps = {
  active: false,
  style: {}
};
const withRedux = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
export default withRedux(withTheme(withStyles(styles)(WifiPaper)));
