import React from 'react';
import { useSelector } from 'react-redux';

// GLOBALS
import { LEFT_PANEL_WIDTH, TOP } from 'iris-config'; // eslint-disable-line import/no-unresolved

// OURS
import DeckMapCompare from './DeckMapCompare';
import TopBar from './TopBar';
import DetailPanel from './DetailPanel';

const ViewCompare = React.forwardRef((_, ref) => {
  const configPanelOpen = useSelector((state) => state.app.configPanelOpen);
  const selectedHasc = useSelector((state) => state.sentiment.selectedHasc);

  const mapRef = React.useRef();

  React.useImperativeHandle(
    ref,
    () => ({
      buildHashStateObject: () => ({}),
      fitBounds: mapRef.current?.fitBounds,
      setView: mapRef.current?.setView
    }),
    [mapRef]
  );

  const detailPanelOffset = selectedHasc ? LEFT_PANEL_WIDTH : 0;
  const configPanelOffset = configPanelOpen ? LEFT_PANEL_WIDTH : 0;

  return (
    <>
      <TopBar left={detailPanelOffset + configPanelOffset} />
      <DetailPanel left={LEFT_PANEL_WIDTH} top={TOP} />
      <DeckMapCompare ref={mapRef} left={detailPanelOffset + configPanelOffset} top={TOP + 48} bottom={0} />
    </>
  );
});
ViewCompare.propTypes = {};
ViewCompare.defaultProps = {};
ViewCompare.displayName = 'ViewCompare';
export default React.memo(ViewCompare);
