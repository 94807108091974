import React, { Component } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// UI/UX
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';

// Icons
import DoneIcon from '@material-ui/icons/Done';

const OptionalTooltip = (props) => {
  return !props.disabled && props.title ? (
    <Tooltip placement="right" arrow={true} title={props.title}>
      {props.children}
    </Tooltip>
  ) : (
    props.children
  );
};

OptionalTooltip.propTypes = {
  disabled: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  progress: {
    position: 'absolute',
    color: theme.palette.secondary.main,
    top: 0,
    left: 0,
    zIndex: 1
  },
  doneIcon: {
    color: theme.palette.secondary.main
  }
});

class WorkIconButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      working: false,
      done: false
    };

    // BIND
    this.onClick = this.onClick.bind(this);
  }
  onClick() {
    const { onClick } = this.props;
    if (!this.state.working) {
      this.setState({ working: true, done: false });
    }

    onClick((success) => {
      this.setState({ working: false, done: success === true });
    });
  }
  render() {
    const { children, onClick, classes, theme, transformIcon, disableOnDone, TooltipProps, ...other } = this.props; // eslint-disable-line no-unused-vars
    const { working, done } = this.state;

    return (
      <OptionalTooltip {...TooltipProps} disabled={done && disableOnDone}>
        <IconButton {...other} onClick={this.onClick} disabled={done && disableOnDone}>
          {working ? (
            <div className={classes.wrapper}>
              {children}
              <CircularProgress className={classes.progress} size={this.props.size === 'small' ? 40 : 48} />
            </div>
          ) : transformIcon && done ? (
            <DoneIcon className={classes.doneIcon} />
          ) : (
            children
          )}
        </IconButton>
      </OptionalTooltip>
    );
  }
}

WorkIconButton.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  transformIcon: PropTypes.bool,
  disableOnDone: PropTypes.bool,
  size: PropTypes.string,
  TooltipProps: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

WorkIconButton.defaultProps = {
  transformIcon: true,
  disableOnDone: true
};

export default withTheme(withStyles(styles)(WorkIconButton));
