import { withStyles, withTheme, alpha } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import amplitude from 'amplitude-js';

// material-ui
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

// icons
import CompareIcon from '@material-ui/icons/Compare';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import SettingsIcon from '@material-ui/icons/Settings';
import TimelineIcon from '@material-ui/icons/Timeline';

// OURS: REDUX
import { irisApi } from '../../services';
import { pushSnackbar as pushSnackbarAction } from '../../actions';
import {
  // \n
  setActiveView as setActiveViewAction,
  reset as resetAction,
  setCompareTab as setCompareTabAction,
  setTemporalTab as setTemporalTabAction
} from './sentimentSlice';

// OURS
import ElevatedTabs from '../ElevatedTabs';
import { API_HOST, genericPost } from 'iris-api'; // eslint-disable-line import/no-unresolved
import AlertList from './components/config/AlertList';
import FormQuestionSelector from './components/config/FormQuestionSelector';
import QuestionConfigModal from './components/QuestionConfigModal';
import Settings from './components/config/Settings';
import TabPanel from '../TabPanel';

// GLOBALS
import { PRODUCT_TEMPORAL, PRODUCT_COMPARE, PRODUCT_TEMPORAL_BY_FORM, PRODUCT_TEMPORAL_BY_ALERT, PRODUCT_COMPARE_BY_FORM, PRODUCT_COMPARE_SETTINGS } from './constants';
import { TOP, LEFT_PANEL_WIDTH } from 'iris-config'; // eslint-disable-line import/no-unresolved

const styles = (theme) => ({
  container: {
    height: `calc(100% - ${TOP}px)`,
    bottom: '0px',
    left: '0px',
    position: 'absolute',
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(1),
    width: LEFT_PANEL_WIDTH
  },
  loadingBox: {
    zIndex: theme.zIndex.tooltip + 1,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: alpha(theme.palette.background.default, 0.7)
  },
  tabPanelAbsolute: {
    position: 'absolute',
    width: '100%',
    height: 'calc(100% - 128px)',
    display: 'flex',
    flexDirection: 'column'
  },
  tabPaper: {
    padding: theme.spacing(1, 1, 1, 1)
  },
  muiTab: {
    '@media (min-width:600px)': {
      minWidth: 24
    }
  },
  flex: {
    display: 'flex'
  }
});

class ConfigPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      configureNewQuestion: null // controls modal
    };

    // BIND
    this.onConfigureNewQuestion = this.onConfigureNewQuestion.bind(this);
    this.onToggleProduct = this.onToggleProduct.bind(this);
    this.onToggleTemporalMode = this.onToggleTemporalMode.bind(this);
    this.onTwoQuestionModeChanged = this.onTwoQuestionModeChanged.bind(this);
    this.onToggleCompareMode = this.onToggleCompareMode.bind(this);

    // TIMERS
    this.iRefreshQuestions = null;

    // XHR
    this.xhrRequestData = {};
    this.xhrOrdering = {};
  }
  componentWillUnmount() {
    if (this.iRefreshQuestions) {
      clearTimeout(this.iRefreshQuestions);
      this.iRefreshQuestions = null;
    }
    this.xhrRequestData.cancel && this.xhrRequestData.cancel();
    this.xhrOrdering.cancel && this.xhrOrdering.cancel();
  }
  onConfigureNewQuestion(question, done) {
    // see if we can resolve this AUTOMATICALLY,
    const { selectedForms } = this.props;
    const body = {
      form_id: selectedForms[0].form_id,
      question_name: question.question_name
    };
    genericPost(`${API_HOST}/sentiment/v0/geo-sentiment/ordering`, body, this.xhrOrdering, (e, r) => {
      if (e) {
        done(false);
        // FAILURE, use modal dialog:
        return this.setState({ configureNewQuestion: question });
      }

      const body1 = {
        ...body,
        ordering: r.ordering,
        type: r.is_ordinal ? 'ordered' : 'categorical'
      };

      genericPost(`${API_HOST}/sentiment/v0/lookups/request-data`, body1, this.xhrRequestData, (e1) => {
        if (e1) {
          done(false);
          // FAILURE, use modal dialog:
          return this.setState({ configureNewQuestion: question });
        }

        done(true);
        this.props.invalidateTags(['SentimentQuestions']);
        this.props.pushSnackbar({ message: 'Request received, check the inbox for status updates.', type: 'success' });
      });
    });
  }
  onToggleProduct(_, product) {
    this.props.setActiveView(product);
    if (product === PRODUCT_COMPARE) {
      amplitude.getInstance().logEvent('sentiment_product_compare');
    }
  }
  onToggleTemporalMode(_, mode) {
    if (mode !== this.props.temporalActiveTab) {
      this.props.setTemporalTab(mode);
    }
    if (mode === PRODUCT_TEMPORAL_BY_FORM) {
      amplitude.getInstance().logEvent('sentiment_product_temporal_byform');
    }
  }
  onToggleCompareMode(_, mode) {
    if (mode !== this.props.compareActiveTab) {
      this.props.setCompareTab(mode);
    }
  }
  onTwoQuestionModeChanged(twoQuestionMode) {
    this.setState({ twoQuestionMode });
  }
  render() {
    const { classes, activeView, selectedForms, temporalActiveTab, compareActiveTab } = this.props;
    const { configureNewQuestion } = this.state;

    return (
      <Box flexDirection="column" className={classes.container}>
        <Tabs value={activeView} onChange={this.onToggleProduct} variant="fullWidth">
          <Tab index={PRODUCT_TEMPORAL} icon={<TimelineIcon />} />
          <Tab index={PRODUCT_COMPARE} icon={<CompareIcon />} />
        </Tabs>
        {/*
         ██████╗ ██████╗ ███╗   ███╗██████╗  █████╗ ██████╗ ███████╗
        ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔══██╗██╔══██╗██╔════╝
        ██║     ██║   ██║██╔████╔██║██████╔╝███████║██████╔╝█████╗
        ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██╔══██║██╔══██╗██╔══╝
        ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ██║  ██║██║  ██║███████╗
         ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝     ╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝
        */}
        <TabPanel value={activeView} index={PRODUCT_COMPARE} disablePadding={true}>
          <ElevatedTabs value={compareActiveTab} onChange={this.onToggleCompareMode} variant="fullWidth">
            <Tab index={PRODUCT_COMPARE_BY_FORM} icon={<MenuIcon />} label="By Form" />
            <Tab index={PRODUCT_COMPARE_SETTINGS} icon={<SettingsIcon />} label="Settings" />
          </ElevatedTabs>
          <TabPanel value={compareActiveTab} index={PRODUCT_COMPARE_BY_FORM} disablePadding={true}>
            <FormQuestionSelector onConfigureNewQuestion={this.onConfigureNewQuestion} />
          </TabPanel>
          <TabPanel value={compareActiveTab} index={PRODUCT_COMPARE_SETTINGS} disablePadding={true}>
            <Paper className={classes.tabPaper}>
              <Settings />
            </Paper>
          </TabPanel>
        </TabPanel>
        {/*
        ████████╗███████╗███╗   ███╗██████╗  ██████╗ ██████╗  █████╗ ██╗
        ╚══██╔══╝██╔════╝████╗ ████║██╔══██╗██╔═══██╗██╔══██╗██╔══██╗██║
           ██║   █████╗  ██╔████╔██║██████╔╝██║   ██║██████╔╝███████║██║
           ██║   ██╔══╝  ██║╚██╔╝██║██╔═══╝ ██║   ██║██╔══██╗██╔══██║██║
           ██║   ███████╗██║ ╚═╝ ██║██║     ╚██████╔╝██║  ██║██║  ██║███████╗
           ╚═╝   ╚══════╝╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝
        */}
        <TabPanel value={activeView} index={PRODUCT_TEMPORAL} disablePadding={true}>
          <ElevatedTabs value={temporalActiveTab} onChange={this.onToggleTemporalMode} variant="fullWidth">
            <Tab classes={{ root: classes.muiTab }} index={PRODUCT_TEMPORAL_BY_ALERT} icon={<NotificationsActiveIcon />} label="By Alerts" />
            <Tab classes={{ root: classes.muiTab }} index={PRODUCT_TEMPORAL_BY_FORM} icon={<MenuIcon />} label="By Form" />
          </ElevatedTabs>
          <TabPanel className={classes.tabPanelAbsolute} value={temporalActiveTab} index={PRODUCT_TEMPORAL_BY_ALERT} disablePadding={true}>
            <AlertList />
          </TabPanel>
          <TabPanel value={temporalActiveTab} index={PRODUCT_TEMPORAL_BY_FORM} disablePadding={true}>
            <FormQuestionSelector onConfigureNewQuestion={this.onConfigureNewQuestion} />
          </TabPanel>
        </TabPanel>

        <QuestionConfigModal
          formSelections={selectedForms}
          question={configureNewQuestion}
          onComplete={() => {
            this.setState({
              configureNewQuestion: null
            });

            this.props.pushSnackbar({ message: 'Request received, check inbox for status updates.', type: 'success' });
          }}
          onCancel={() => {
            this.setState({ configureNewQuestion: null });
          }}
        />
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  activeView: state.sentiment.activeView,
  selectedForms: state.sentiment.selectedForms,
  temporalActiveTab: state.sentiment.temporalActiveTab,
  compareActiveTab: state.sentiment.compareActiveTab
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushSnackbar: pushSnackbarAction,
      setActiveView: setActiveViewAction,
      setCompareTab: setCompareTabAction,
      setTemporalTab: setTemporalTabAction,
      reset: resetAction,
      invalidateTags: irisApi.util.invalidateTags
    },
    dispatch
  );

ConfigPanel.propTypes = {
  // MUI
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  // REDUX
  activeView: PropTypes.number.isRequired,
  selectedForms: PropTypes.array,
  reset: PropTypes.func.isRequired,
  temporalActiveTab: PropTypes.oneOf([PRODUCT_TEMPORAL_BY_ALERT, PRODUCT_TEMPORAL_BY_FORM]),
  compareActiveTab: PropTypes.oneOf([PRODUCT_COMPARE_BY_FORM, PRODUCT_COMPARE_SETTINGS]),
  invalidateTags: PropTypes.func.isRequired,

  // REDUX: DISPATCH
  pushSnackbar: PropTypes.func.isRequired,
  setActiveView: PropTypes.func.isRequired,
  setCompareTab: PropTypes.func.isRequired,
  setTemporalTab: PropTypes.func.isRequired
};

ConfigPanel.defaultProps = {};

const withRedux = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: false });
export default withRedux(withTheme(withStyles(styles)(ConfigPanel)));
