import React from 'react';

// MUI
import { Box, useTheme } from '@material-ui/core';

// Ours
import NetworkFilters from './components/NetworkFilters';
import RenderSettings from './components/RenderSettings';

const ConfigPanel = () => {
  const theme = useTheme();
  return (
    <Box>
      <RenderSettings />
      <div style={{ marginTop: theme.spacing(1) }} />
      <NetworkFilters />
    </Box>
  );
};

export default React.memo(ConfigPanel);
