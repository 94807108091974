// Core
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { updateMapPosition, pushSnackbar as pushSnackbarAction } from '../../actions';
import { getH3 } from './services';
import { METRICS, signalsSelectors } from './signalsSlice';

// UI/UX
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';

// Modules
import debounce from 'lodash.debounce';
import * as d3 from 'd3';
import numeral from 'numeral';

// DeckGL
import { DeckGL } from '@deck.gl/react';
import { FlyToInterpolator } from '@deck.gl/core';
import { StaticMap } from 'react-map-gl';
import { H3HexagonLayer } from '@deck.gl/geo-layers';

// Ours
import DeckglLayerControl from '../DeckglLayerControl';
import { viewportFromRef } from '../DeckGLUtils';
import { updateMapAttributionImmediate } from 'iris-util'; // eslint-disable-line import/no-unresolved
import { TOP } from 'iris-config'; // eslint-disable-line import/no-unresolved
import { MAP_STYLES_FLAT } from '../../constants';
import { Waiting } from '@premisedata/iris-components';
import Legend from './Legend';
import { colorRampLookup } from '../ColorRamps';

// GLOBALS
const MAP_ID_SELECTOR = '#view-default-view';

const useStyles = makeStyles((theme) => ({
  map: {
    position: 'absolute',
    right: '0px',
    backgroundColor: theme.palette.background.default,
    height: `calc(100% - ${TOP}px)`, // Top + marginTop
    top: `${TOP}px`,
    overflow: 'hidden'
  },
  progress: {
    zIndex: 1,
    height: '100%',
    width: '100%',
    backgroundColor: alpha(theme.palette.primary.main, 0.5),
    position: 'absolute',
    top: 0,
    left: 0,
    pointerEvents: 'none'
  }
}));

const SignalsDeckMap = forwardRef(({ left }, ref) => {
  // Redux
  const dispatch = useDispatch();

  // Selectors
  const hasGPUHardware = useSelector((state) => state.app.hasGPUHardware);
  const mapZoom = useSelector((state) => state.app.mapZoom);
  const mapCenter = useSelector((state) => state.app.mapCenter);
  const mapboxAccessToken = useSelector((state) => state.app.mapboxAccessToken);

  const networkNamesFilter = useSelector(signalsSelectors.selectNetworkNameFilter);
  const networkTypesFilter = useSelector(signalsSelectors.selectNetworkTypeFilter);
  const geographyFilters = useSelector(signalsSelectors.selectGeographyFilters);
  const thresholdFilter = useSelector(signalsSelectors.selectThresholdFilter);
  const activeMetric = useSelector(signalsSelectors.selectActiveMetric);
  const categorized = useSelector(signalsSelectors.selectCategorizedMetric);
  const h3level = useSelector(signalsSelectors.selectH3level);

  const colorRamp = useSelector(signalsSelectors.selectColorRamp);
  const colorRampInverted = useSelector(signalsSelectors.selectColorRampInverted);
  const vectorOpacity = useSelector(signalsSelectors.selectVectorOpacity);

  // Local State
  const [viewState, setViewState] = useState({
    longitude: mapCenter.x_lon,
    latitude: mapCenter.y_lat,
    zoom: mapZoom,
    maxZoom: 15,
    pitch: 50,
    bearing: 0,
    maxPitch: 50,
    minPitch: 0
  });
  const [currentBasemap, setCurrentBasemap] = useState(MAP_STYLES_FLAT[0]);
  const [hexData, setHexData] = useState(null);
  const [legendScale, setLegendScale] = useState(null); // passed to legend

  // Queries
  const [h3QueryTrigger, h3QueryResult] = getH3.useLazyQuery();

  // Mui
  const classes = useStyles();
  const theme = useTheme();

  // Refs
  const deckRef = useRef();
  const movedRef = useRef();
  const fitBoundsRef = useRef();

  // Timers
  const mountRef = useRef(null);

  // copied and modified from radio
  const buildLegendScale = useCallback(
    (hexData, n = 5) => {
      const interpolate = colorRampLookup[colorRamp];
      const dataDomain = hexData.map((d) => d.count);
      const e = d3.extent(dataDomain);

      if (categorized) {
        if (activeMetric === METRICS.MAX_SIGNAL || activeMetric === METRICS.MEAN_SIGNAL) {
          const scale = d3
            .scaleQuantile()
            .domain([-200, -105, -90, -75, 0])
            .range([
              { rgb: d3.color(interpolate(colorRampInverted ? 1 - 0 : 0)), text: 'Poor' },
              { rgb: d3.color(interpolate(colorRampInverted ? 1 - 1 / 3 : 1 / 3)), text: 'Fair' },
              { rgb: d3.color(interpolate(colorRampInverted ? 1 - 2 / 3 : 2 / 3)), text: 'Good' },
              { rgb: d3.color(interpolate(colorRampInverted ? 1 - 1 : 1)), text: 'Excellent' }
            ]);
          return scale;
        }
        if (activeMetric === METRICS.SIGNAL_CHANGE) {
          const scale = d3
            .scaleQuantile()
            .domain([-200, -20, -5, 5, 20, 200])
            .range([
              { rgb: d3.color(interpolate(colorRampInverted ? 1 - 0 : 0)), text: '>20 dBm decrease' },
              { rgb: d3.color(interpolate(colorRampInverted ? 1 - 1 / 5 : 1 / 5)), text: '5-20 dBm decrease' },
              { rgb: d3.color(interpolate(colorRampInverted ? 1 - 2 / 5 : 2 / 5)), text: 'No Change' },
              { rgb: d3.color(interpolate(colorRampInverted ? 1 - 3 / 5 : 3 / 5)), text: '5-20 dBm increase' },
              { rgb: d3.color(interpolate(colorRampInverted ? 1 - 3 / 5 : 3 / 5)), text: '>20 dBm increase' }
            ]);
          return scale;
        }
      }

      // Quantile is good for all data coming in from api-signals
      const scale = d3
        .scaleQuantile()
        .domain(dataDomain)
        .range(
          [...new Array(n).keys()].map((d) => {
            const x = d / (n - 1);
            return { rgb: d3.color(interpolate(colorRampInverted ? 1 - x : x)) };
          })
        );
      const q = scale.quantiles();
      const uq = Array.from(new Set(q.filter((d) => !e.includes(d))));
      const uql = uq.length;

      if (q.length !== uql && n > 2) {
        return buildLegendScale(hexData, n - 1);
      }

      return scale;
    },
    [activeMetric, categorized, colorRamp, colorRampInverted]
  );

  // h3 query
  useEffect(() => {
    if (h3QueryResult.isError) {
      dispatch(
        pushSnackbarAction({
          type: 'error',
          message: 'Error getting hexagon data for signal strength product'
        })
      );
      return;
    }

    if (h3QueryResult.isSuccess && h3QueryResult.data) {
      setLegendScale(() => {
        const l = buildLegendScale(h3QueryResult.data);
        setHexData(
          h3QueryResult.data.map((d) => {
            const rgb = l?.(d.count).rgb;
            return { ...d, color: [rgb.r, rgb.g, rgb.b] };
          })
        );

        // make sure these values are updated at the same time for legend, so we just have it in the same object to pass
        // don't want to use the wrong scale for the wrong legend type
        return {
          scale: l,
          categorized
        };
      });
    }
  }, [h3QueryResult, colorRamp, colorRampInverted, categorized, dispatch, buildLegendScale]);

  const getViewport = () => {
    const deck = deckRef.current;
    if (!deck || !deck.deck) return;

    const viewManager = deck.deck.viewManager;
    if (!viewManager) return;

    const viewports = viewManager.getViewports();
    if (!viewports || viewports.length === 0) return;

    return viewports[0];
  };

  const calculateState = useCallback(() => {
    const viewport = getViewport();
    if (!viewport) {
      return;
    }

    const nw = viewport.unproject([0, 0]);
    const se = viewport.unproject([viewport.width, viewport.height]);
    const ne = viewport.unproject([viewport.width, 0]);
    const sw = viewport.unproject([0, viewport.height]);

    const mapPolygon = {
      nw,
      ne,
      se,
      sw
    };

    const { longitude, latitude, zoom } = viewport;

    const mapBounds = {
      minx: Math.min(nw[0], sw[0]),
      maxx: Math.max(ne[0], se[0]),

      miny: Math.min(se[1], sw[1]),
      maxy: Math.max(nw[1], ne[1])
    };

    const mapCenter = {
      x_lon: longitude,
      y_lat: latitude
    };

    return {
      mapBounds,
      mapCenter,
      zoom,
      mapPolygon
    };
  }, []);

  const fetchHex = useCallback(
    (mapBounds, zoom) => {
      h3QueryTrigger({
        mapZoom: zoom,
        metric: activeMetric,
        mapBounds,
        networkNamesFilter,
        networkTypesFilter,
        geographyFilters: geographyFilters.map((g) => g.hasc),
        thresholdFilter: thresholdFilter,
        h3l: h3level
      });
    },
    [activeMetric, geographyFilters, h3QueryTrigger, h3level, networkNamesFilter, networkTypesFilter, thresholdFilter]
  );

  useEffect(() => {
    const res = calculateState();
    if (res) {
      const { mapBounds, zoom } = res;
      fetchHex(mapBounds, zoom);
    }
  }, [calculateState, fetchHex]);

  // Throttle & Debounce
  const updateGlobalViewportThrottled = useMemo(
    () =>
      debounce(
        () => {
          const { mapBounds, mapCenter, zoom, mapPolygon } = calculateState();
          dispatch(updateMapPosition(mapBounds, mapCenter, zoom, mapPolygon));
          fetchHex(mapBounds, zoom);
        },
        1000,
        { trailing: true, leading: false }
      ),
    [calculateState, dispatch, fetchHex]
  );

  const updateMapAttribution = useMemo(
    () =>
      debounce(
        ({ offsetX, offsetY }) => {
          const viewport = getViewport();
          const [x, y] = viewport.unproject([offsetX, offsetY]);
          const latitude = numeral(x).format('0[.]00');
          const longitude = numeral(y).format('0[.]00');
          // TO-DO:
          updateMapAttributionImmediate({ zoom: viewport.zoom, latitude, longitude });
        },
        100,
        { trailing: true, leading: false }
      ),
    []
  );

  const onBasemapChanged = useCallback((basemap) => {
    setCurrentBasemap(basemap);
  }, []);

  const onPitchChanged = useCallback(
    (pitch) => {
      setViewState({
        ...viewState,
        pitch
      });
    },
    [viewState]
  );

  useEffect(() => {
    // componentDidMount
    updateGlobalViewportThrottled();
    mountRef.current = setInterval(() => {
      const e = document.querySelector(MAP_ID_SELECTOR);
      if (e) {
        e.addEventListener('mousemove', updateMapAttribution);
        clearInterval(mountRef.current);
        clearInterval(fitBoundsRef.current);
        mountRef.current = null;
        fitBoundsRef.current = null;
      }
    }, 250);

    // componentWillUnmount
    return () => {
      updateGlobalViewportThrottled.cancel();
      updateMapAttribution.cancel();

      if (mountRef.current) {
        clearInterval(mountRef.current);
        mountRef.current = null;
      }

      const e = document.querySelector(MAP_ID_SELECTOR);
      if (e) {
        e.removeEventListener('mousemove', updateMapAttribution);
      }
    };
  }, []); //eslint-disable-line

  const updateViewport = ({ viewState }) => {
    const viewport = getViewport();
    if (!viewport) return;

    setViewState(viewState);
    updateGlobalViewportThrottled();
  };

  // ref methods
  const fitBounds = ({ minx, miny, maxx, maxy }) => {
    const viewport = viewportFromRef(deckRef);
    if (!viewport) return;
    setViewState({
      ...viewport.fitBounds([
        [minx, miny],
        [maxx, maxy]
      ]),
      transitionDuration: 2000,
      transitionInterpolator: new FlyToInterpolator()
    });
  };

  // forwarded ref
  useImperativeHandle(
    ref,
    () => ({
      buildHashStateObject: () => ({}),
      fitBounds,
      setView: (x_lon, y_lat, z) => {
        if (deckRef.current) {
          setViewState({
            latitude: y_lat,
            longitude: x_lon,
            zoom: z ?? viewState.zoom,
            transitionDuration: 2000,
            transitionInterpolator: new FlyToInterpolator()
          });
        }
      }
    }),
    [viewState.zoom]
  );

  if (!hasGPUHardware) return null;

  return (
    <Box
      className={classes.map}
      style={{ left }}
      onMouseMove={() => {
        movedRef.current += 1;
      }}
      onMouseDown={() => {
        movedRef.current = 0;
      }}
    >
      <DeckglLayerControl
        currentBasemap={currentBasemap}
        onBasemapChange={onBasemapChanged}
        onPitchChange={onPitchChanged}
        currentPitchToggle={viewState.pitch !== 0 ? '2D' : '3D'}
      />

      {h3QueryResult.isFetching && (
        <Box className={classes.progress}>
          <Waiting />
        </Box>
      )}

      <DeckGL glOptions={{ failIfMajorPerformanceCaveat: true }} ref={deckRef} viewState={viewState} controller={true} onViewStateChange={updateViewport}>
        <StaticMap key="baseLayer" mapStyle={currentBasemap.getMapStyle(theme).baseLayer} mapboxApiAccessToken={mapboxAccessToken} />
        <StaticMap
          visible={!!currentBasemap.getMapStyle(theme).labelLayer}
          key="labelLayer"
          mapStyle={currentBasemap.getMapStyle(theme).labelLayer}
          mapboxApiAccessToken={mapboxAccessToken}
        />
        {hexData && (
          <H3HexagonLayer
            id="signals-hex-lyr"
            data={hexData}
            pickable={false}
            filled={true}
            getHexagon={(d) => d.hash}
            getFillColor={(d) => d.color}
            extruded={false}
            wireframe={false}
            stroked={false}
            opacity={vectorOpacity}
            material={false}
            parameters={{ blend: false, depthTest: false }}
          />
        )}
      </DeckGL>
      {h3QueryResult.data?.length !== 0 && <Legend hexScale={legendScale} />}
    </Box>
  );
});
SignalsDeckMap.displayName = 'SignalsDeckMap';
SignalsDeckMap.propTypes = {
  // ui / ux
  left: PropTypes.number
};
SignalsDeckMap.defaultProps = {
  left: 0
};

export default SignalsDeckMap;
