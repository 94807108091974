// Core
import { withStyles, withTheme, alpha, hexToRgb } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// ui/ux
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

// modules
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import GL from '@luma.gl/constants';
import * as d3 from 'd3';
import numeral from 'numeral';

// deckgl
import { DeckGL } from '@deck.gl/react';
import { FlyToInterpolator } from '@deck.gl/core';
import { IconLayer } from '@deck.gl/layers';
import { H3HexagonLayer } from '@deck.gl/geo-layers';
import { StaticMap } from 'react-map-gl';

// ours
import { updateMapPosition as updateMapPositionAction } from '../../actions';
import { MAP_STYLES_FLAT } from '../../constants';
import { TOP } from 'iris-config'; // eslint-disable-line import/no-unresolved
import DeckglLayerControl from '../DeckglLayerControl';
import { updateMapAttributionImmediate } from 'iris-util'; // eslint-disable-line import/no-unresolved
import { API_HOST } from 'iris-api'; // eslint-disable-line import/no-unresolved
import { getUserdataMetadata } from './services';

// GLOBALS
const MAP_ID_SELECTOR = '#view-default-view';

const styles = (theme) => ({
  map: {
    position: 'absolute',
    right: '0px',
    backgroundColor: theme.palette.background.default,
    height: `calc(100% - ${TOP}px)`, // Top + marginTop
    top: `${TOP}px`,
    overflow: 'hidden',
    // rearragne static maps:
    '& #view-default-view > div:last-child': {
      zIndex: '1 !important'
    }
  },
  progress: {
    zIndex: 1,
    height: '100%',
    width: '100%',
    backgroundColor: alpha(theme.palette.primary.main, 0.5),
    position: 'absolute',
    top: 0,
    left: 0,
    pointerEvents: 'none'
  }
});

class PlacesDeckMap extends React.PureComponent {
  constructor(props) {
    super(props);
    const { mapZoom, mapCenter } = props;
    this.state = {
      viewState: {
        longitude: mapCenter.x_lon,
        latitude: mapCenter.y_lat,
        zoom: mapZoom,
        pitch: 50,
        bearing: 0,
        maxPitch: 50,
        minPitch: 0
      },
      currentBasemap: MAP_STYLES_FLAT[0],
      hideAggLayers: false,
      removeAll: false
    };

    // TIMERS
    this._iOnLoad = null; // setInterval used in onLoad()
    this._iMount = null;

    // BIND
    this.updateViewport = this.updateViewport.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onBasemapChange = this.onBasemapChange.bind(this);
    this.onPitchChange = this.onPitchChange.bind(this);

    // THROTTLE & DEBOUNCE
    this.updateGlobalViewportThrottled = debounce(this.updateGlobalViewport, 1000, { trailing: true, leading: false });
    this.updateMapAttribution = throttle(this.updateMapAttribution.bind(this), 100, { trailing: true, leading: false });

    // REFS
    this._ref = React.createRef();

    this.NODATA = [];

    this.transitionSettings = () => ({
      transitionDuration: 2000,
      transitionInterpolator: new FlyToInterpolator()
    });
  }
  componentWillUnmount() {
    this.updateGlobalViewportThrottled.cancel();
    this.updateMapAttribution.cancel();

    const e = document.querySelector(MAP_ID_SELECTOR);
    if (e) {
      e.removeEventListener('mousemove', this.updateMapAttribution);
    }

    if (this._iMount) {
      clearInterval(this._iMount);
      this._iMount = null;
    }
    if (this._iOnLoad) {
      clearInterval(this._iOnLoad);
      this._iOnLoad = null;
    }
  }
  componentDidMount() {
    this.componentDidUpdate({}, {});

    this._iMount = setInterval(() => {
      const e = document.querySelector(MAP_ID_SELECTOR);
      if (e) {
        e.addEventListener('mousemove', this.updateMapAttribution);
        clearInterval(this._iMount);
        this._iMount = null;
      }
    }, 250);
  }
  updateMapAttribution({ offsetX, offsetY }) {
    const viewport = this.getViewport();
    const [x, y] = viewport.unproject([offsetX, offsetY]);
    const latitude = numeral(x).format('0[.]00');
    const longitude = numeral(y).format('0[.]00');

    // TODO
    updateMapAttributionImmediate({ zoom: viewport.zoom, latitude, longitude });
  }
  componentDidUpdate(prevProps) {
    const { placesHexData, theme } = this.props;
    const { placesHexData: prevPlacesHexData } = prevProps;

    if (!placesHexData && this.state.placesHexData) {
      this.setState({
        placesHexData: null
      });
    } else if (placesHexData && prevPlacesHexData !== placesHexData) {
      const colorInterpolatorData = theme.palette.type === 'dark' ? d3.interpolatePuRd : d3.interpolatePuRd;
      const quantileRange = [0.35, 0.45, 0.55, 0.65, 0.75, 0.85, 1.0].map(colorInterpolatorData).map((d) => {
        if (d.includes('#')) {
          d = hexToRgb(d);
        }
        return d
          .split(/\(|\)/)[1]
          .split(/,\s/)
          .map((d) => +d);
      });

      const counts = placesHexData.map((d) => d.count);
      const scale = d3.scaleQuantile().domain(counts).range(quantileRange);
      const legendValues = [...new Set(scale.quantiles())];
      const legendColors = legendValues.map((d) => scale(d));

      this.setState({
        placesHexData: placesHexData.map((d) => ({ ...d, color: scale(d.count) })),
        placesHexScale: scale,
        placesHexLegendValues: legendValues,
        placesHexLegendColors: legendColors,
        colorInterpolatorData
      });
    }
  }
  fitBounds(bounds) {
    const viewport = this.getViewport();
    if (!viewport) return;

    this.setState((s) => ({
      viewState: {
        ...s.viewState,
        ...this.transitionSettings(),
        ...viewport.fitBounds([
          [bounds.maxx, bounds.maxy],
          [bounds.minx, bounds.miny]
        ])
      }
    }));
  }
  setView(x_lon, y_lat, z) {
    this.setState((s) => ({
      viewState: {
        ...s.viewState,
        ...this.transitionSettings(),
        latitude: y_lat,
        longitude: x_lon,
        zoom: z ? z : 17
      }
    }));
  }
  getViewport() {
    const deck = this._ref.current;
    if (!deck || !deck.deck) return;

    const viewManager = deck.deck.viewManager;
    if (!viewManager) return;

    const viewports = viewManager.getViewports();
    if (!viewports || viewports.length === 0) return;

    return viewports[0];
  }
  updateGlobalViewport() {
    const currentState = this.calculateState();
    if (currentState) {
      const { mapBounds, mapCenter, zoom, mapPolygon } = currentState;
      this.props.updateMapPosition(mapBounds, mapCenter, zoom, mapPolygon);
    }
  }

  updateViewport({ viewState }) {
    const viewport = this.getViewport();
    if (!viewport) return;

    const mapPolygon = this.calculateState(viewport)?.mapPolygon;
    this.setState({ mapPolygon, viewState });

    // throttle the announcements:
    this.updateGlobalViewportThrottled();
  }

  calculateState() {
    const viewport = this.getViewport();
    if (!viewport) return;

    const nw = viewport.unproject([0, 0]);
    const se = viewport.unproject([viewport.width, viewport.height]);
    const ne = viewport.unproject([viewport.width, 0]);
    const sw = viewport.unproject([0, viewport.height]);

    const mapPolygon = {
      nw,
      ne,
      se,
      sw
    };

    const { longitude, latitude, zoom } = viewport;

    const mapBounds = {
      minx: Math.min(nw[0], sw[0]),
      maxx: Math.max(ne[0], se[0]),

      miny: Math.min(se[1], sw[1]),
      maxy: Math.max(nw[1], ne[1])
    };

    const mapCenter = {
      x_lon: longitude,
      y_lat: latitude
    };

    return {
      mapBounds,
      mapCenter,
      zoom,
      mapPolygon
    };
  }

  onLoad() {
    this._iOnLoad = setInterval(() => {
      const s = this.calculateState();
      if (s) {
        clearInterval(this._iOnLoad);
        this._iOnLoad = null;
        this.props.updateMapPosition(s.mapBounds, s.mapCenter, s.zoom, s.mapPolygon);
      }
    }, 250);
  }
  onSelect({ object }) {
    const { selectedPlaceId, selectedUserData, onSelect, onSelectUserData, onSelectSubmission } = this.props;
    if (!object) {
      if (selectedPlaceId) this.props.onSelect();
      if (selectedUserData) this.props.onSelectUserData();
      return;
    }

    if (object?.sub_id) {
      onSelectSubmission(object);
    } else if (object.type < 2) {
      onSelect(object.id);
    } else {
      onSelectUserData(object.id);
    }
  }

  onBasemapChange(currentBasemap) {
    this.setState({
      currentBasemap
    });
  }

  onPitchChange(pitch) {
    this.setState((s) => ({
      viewState: {
        ...s.viewState,
        pitch
      }
    }));
  }

  render() {
    const {
      classes,
      dataLoading,
      dropPin,
      hasGPUHardware,
      hoverSubmission,
      hoverPlaceId,
      hoverWifi,
      left,
      mapboxAccessToken,
      nearbyWifi,
      placesPointData,
      right,
      selectedPlaceId,
      selectedUserData,
      selectedSubmission,
      submissions,
      theme,
      userdataMetadataQuery
    } = this.props;
    const { currentBasemap, viewState, placesHexData } = this.state;
    if (!hasGPUHardware) return null;

    // TODO: redo this, pass full place on hover,select:
    const hoverPlaces = [];
    const selectedPlaces = [];
    if (placesPointData && (selectedPlaceId || hoverPlaceId || selectedUserData)) {
      for (const pnt of placesPointData) {
        if (pnt.id === selectedPlaceId) {
          selectedPlaces.push(pnt);
        } else if (pnt.id === hoverPlaceId) {
          hoverPlaces.push(pnt);
        } else if (selectedUserData && pnt.id === `${selectedUserData.type}-${selectedUserData.id}`) {
          selectedPlaces.push(pnt);
        }
      }
    }

    const { baseLayer, labelLayer } = currentBasemap.getMapStyle(theme);
    const renderIcons = !!placesPointData;
    const userdataLayerMetadata = userdataMetadataQuery.data?.layersById ?? {};
    return (
      <Box className={classes.map} style={{ left, right }}>
        <DeckglLayerControl
          currentBasemap={currentBasemap}
          onBasemapChange={this.onBasemapChange}
          onPitchChange={this.onPitchChange}
          currentPitchToggle={viewState.pitch === 0 ? '2D' : '3D'}
        />
        <Box
          className={classes.progress}
          style={{
            display: dataLoading ? null : 'none'
          }}
        >
          <Box display="flex" style={{ height: '100%', width: '100%' }} alignItems="center" justifyContent="center">
            <CircularProgress size={50} color="secondary" />
          </Box>
        </Box>

        <DeckGL
          glOptions={{ failIfMajorPerformanceCaveat: true }}
          ref={this._ref}
          viewState={viewState}
          parameters={{
            // [GL.DEPTH_TEST]: true,
            [GL.BLEND]: true
          }}
          getCursor={({ isDragging }) => {
            if (isDragging) return 'grabbing';
            else return 'pointer';
          }}
          onLoad={this.onLoad}
          controller={true}
          onViewStateChange={this.updateViewport}
          onClick={this.onSelect}
        >
          <StaticMap key="baseLayer" mapStyle={baseLayer} mapboxApiAccessToken={mapboxAccessToken} />
          <H3HexagonLayer
            data={placesHexData}
            id="places-hex-lyr"
            pickable={false}
            filled={true}
            getHexagon={(d) => d.hash}
            getFillColor={(d) => d.color}
            extruded={false}
            wireframe={false}
            stroked={false}
            opacity={0.5}
            material={false}
            visible={!!placesHexData}
          />
          <StaticMap visible={!!labelLayer} key="labelLayer" mapStyle={labelLayer} mapboxApiAccessToken={mapboxAccessToken} />

          {/* submission icons */}
          <IconLayer
            parameters={{ depthTest: false }}
            billboard={false}
            data={submissions}
            visible={renderIcons}
            id="submissions-point-lyr"
            pickable={true}
            getSize={10}
            wrapLongitude={true}
            getPosition={(d) => [d.x_lon, d.y_lat]}
            getIcon={() => ({
              url: `${API_HOST}/uploads/v0/icons/hospital/${encodeURIComponent('#FFA500')}.png`,
              width: 512,
              height: 512,
              anchorY: 512
            })}
          />
          <IconLayer
            billboard={false}
            data={hoverSubmission ? [hoverSubmission] : this.NODATA}
            visible={!!hoverSubmission}
            id="submissions-point-hover-lyr"
            pickable={false}
            getSize={10}
            wrapLongitude={true}
            getPosition={(d) => [d.x_lon, d.y_lat]}
            getIcon={() => ({
              url: `${API_HOST}/uploads/v0/icons/hospital/${encodeURIComponent('#ABEC43')}.png`,
              width: 512,
              height: 512,
              anchorY: 512
            })}
          />
          <IconLayer
            billboard={false}
            data={selectedSubmission ? [selectedSubmission] : this.NODATA}
            visible={!!selectedSubmission}
            id="submissions-point-selected-lyr"
            pickable={false}
            getSize={10}
            wrapLongitude={true}
            getPosition={(d) => [d.x_lon, d.y_lat]}
            getIcon={() => ({
              url: `${API_HOST}/uploads/v0/icons/hospital/${encodeURIComponent('#308C9D')}.png`,
              width: 512,
              height: 512,
              anchorY: 512
            })}
          />
          {/* nearby wifi */}
          <IconLayer
            id="places-radio-icon-lyr"
            visible={renderIcons && nearbyWifi && nearbyWifi.length > 0}
            data={nearbyWifi ?? this.NODATA}
            pickable={false}
            getIcon={() => ({
              url: `${API_HOST}/uploads/v0/icons/viewpoint/${encodeURIComponent('#FF5C49')}.png?icon-only`,
              width: 512,
              height: 512,
              anchorY: 512
            })}
            getPosition={({ location }) => [location.lon, location.lat]}
            getSize={15}
          />
          {/* nearby wifi: hover */}
          <IconLayer
            id="places-radio-hover-icon-lyr"
            visible={renderIcons && hoverWifi}
            data={hoverWifi}
            pickable={false}
            getIcon={() => ({
              url: `${API_HOST}/uploads/v0/icons/viewpoint/${encodeURIComponent('#ABEC43')}.png?icon-only`,
              width: 512,
              height: 512,
              anchorY: 512
            })}
            getPosition={({ location }) => [location.lon, location.lat]}
            getSize={15}
          />
          {/* place icons */}
          <IconLayer
            data={placesPointData}
            id="places-point-lyr"
            pickable={true}
            getSize={20}
            wrapLongitude={true}
            getPosition={(d) => [d.lon, d.lat]}
            visible={renderIcons}
            getIcon={(d) => {
              const lmd = userdataLayerMetadata[d.type];
              if (d.type === 1) {
                return {
                  url: `${API_HOST}/uploads/v0/icons/town-hall/${encodeURIComponent('#FF5C49')}.png`,
                  width: 512,
                  height: 512,
                  anchorY: 512
                };
              } else if (lmd) {
                return {
                  url: `${API_HOST}/uploads/v0/icons/${lmd.icon}/${encodeURIComponent(lmd.color)}.png`,
                  width: 512,
                  height: 512,
                  id: `${lmd.icon}-${lmd.color}`
                };
              }
              // d.type === 0
              return {
                url: `${API_HOST}/uploads/v0/icons/marker/${encodeURIComponent('#FF5C49')}.png?icon-only`,
                width: 512,
                height: 512,
                anchorY: 512
              };
            }}
          />
          {/* place icons: hover */}
          <IconLayer
            data={hoverPlaces}
            visible={renderIcons}
            id="hover-place-point-lyr"
            pickable={false}
            getSize={20}
            wrapLongitude={true}
            getPosition={(d) => [d.lon, d.lat]}
            getIcon={(d) => {
              const lmd = userdataLayerMetadata[d.type];

              if (d.type === 1) {
                return {
                  url: `${API_HOST}/uploads/v0/icons/town-hall/${encodeURIComponent('#ABEC43')}.png`,
                  width: 512,
                  height: 512,
                  anchorY: 512
                };
              } else if (lmd) {
                const hsl = d3.hsl(lmd.color);
                hsl.h += 240;
                return {
                  url: `${API_HOST}/uploads/v0/icons/${lmd.icon}/${encodeURIComponent(hsl.formatHex())}.png`,
                  width: 512,
                  height: 512,
                  id: `${lmd.icon}-${lmd.color}`
                };
              }
              // d.type === 0
              return {
                url: `${API_HOST}/uploads/v0/icons/marker/${encodeURIComponent('#ABEC43')}.png?icon-only`,
                width: 512,
                height: 512,
                anchorY: 512
              };
            }}
          />
          {/* place icons: selected */}
          <IconLayer
            data={selectedPlaces}
            visible={renderIcons}
            id="selected-place-point-lyr"
            pickable={false}
            getSize={20}
            wrapLongitude={true}
            getPosition={(d) => [d.lon, d.lat]}
            getIcon={(d) => {
              const lmd = userdataLayerMetadata[d.type];
              if (d.type === 1) {
                return {
                  url: `${API_HOST}/uploads/v0/icons/town-hall/${encodeURIComponent('#308C9D')}.png`,
                  width: 512,
                  height: 512,
                  anchorY: 512
                };
              } else if (lmd) {
                const hsl = d3.hsl(lmd.color);
                hsl.h += 120;
                return {
                  url: `${API_HOST}/uploads/v0/icons/${lmd.icon}/${encodeURIComponent(hsl.formatHex())}.png`,
                  width: 512,
                  height: 512,
                  id: `${lmd.icon}-${lmd.color}`
                };
              }
              // d.type === 0
              return {
                url: `${API_HOST}/uploads/v0/icons/marker/${encodeURIComponent('#308C9D')}.png?icon-only`,
                width: 512,
                height: 512,
                anchorY: 512
              };
            }}
          />
          {/* dropped pin */}
          <IconLayer
            key={theme.palette.text.primary}
            data={dropPin}
            id={`dropped-pin-${theme.palette.type}`}
            pickable={false}
            getIcon={() => ({
              url: `${API_HOST}/uploads/v0/icons/marker/${encodeURIComponent(d3.color(theme.palette.text.primary).hex())}.png?icon-only`,
              width: 512,
              height: 512,
              anchorY: 512
            })}
            getPosition={({ lon, lat }) => [lon, lat]}
            sizeScale={5}
            getSize={10}
          />
        </DeckGL>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  // Settings
  hasGPU: state.app.hasGPU,
  hasGPUHardware: state.app.hasGPUHardware,
  // Map Details
  mapBounds: state.app.mapBounds,
  mapCenter: state.app.mapCenter,
  mapZoom: state.app.mapZoom,
  mapboxAccessToken: state.app.mapboxAccessToken,
  dropPin: state.app.dropPin,

  // Custom user data support:
  userdataMetadataQuery: getUserdataMetadata.select()(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateMapPosition: updateMapPositionAction
    },
    dispatch
  );

PlacesDeckMap.propTypes = {
  // ui / ux
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  left: PropTypes.number,
  right: PropTypes.number,
  dataLoading: PropTypes.bool,

  // data
  placesHexData: PropTypes.array,
  placesPointData: PropTypes.array,
  hoverPlaceId: PropTypes.string, // feature currently being hovered on in the UI
  selectedPlaceId: PropTypes.string, // feature that is currently selected
  submissions: PropTypes.array,
  mapboxAccessToken: PropTypes.string,
  mapZoom: PropTypes.number,
  mapCenter: PropTypes.object,
  mapBounds: PropTypes.object,
  hasGPUHardware: PropTypes.bool,
  hasGPU: PropTypes.bool,
  nearbyWifi: PropTypes.array,
  hoverWifi: PropTypes.array,
  hoverSubmission: PropTypes.object,
  updateMapPosition: PropTypes.func,
  dropPin: PropTypes.array,
  selectedUserData: PropTypes.object,
  selectedSubmission: PropTypes.object,

  // Custom user data support:
  userdataMetadataQuery: PropTypes.object,

  // callbacks
  onSelect: PropTypes.func,
  onSelectUserData: PropTypes.func,
  onSelectSubmission: PropTypes.func
};

PlacesDeckMap.defaultProps = {
  left: 0,
  right: 0,
  dropPin: []
};

const withRedux = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
export default withRedux(withTheme(withStyles(styles)(PlacesDeckMap)));
