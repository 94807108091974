import React, { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// MODULES
import { capitalCase } from 'change-case';
import debounce from 'lodash.debounce';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';

// MUI: ICONS
import CommentIcon from '@material-ui/icons/Comment';
import ClearIcon from '@material-ui/icons/Clear';

// OURS
import { common as irisComponentsCommon } from '@premisedata/iris-components';
import ChipPopoverInstant from '../../ChipPopoverInstant';
import ChipPopover from '../../ChipPopover';
import SortChipPopover from '../../SortChipPopover';
import QAFilter from './QAFilter';
import DateAndTimeFrameSelector from './DateAndTimeFrameSelector';
import TextFieldDebounced from '../../TextFieldDebounced';
import {
  placesSelectors,
  setSort,
  setFilterCategories,
  setFilterOCRLanguages,
  setFilterOCRContent,
  setFilterQA,
  setFilterName,
  setDataRecency,
  setShowOSM,
  setOnlyUnvalidated,
  setFilterGeographies,
  updateDateRange,
  setOperatingStatus,
  resetFilters
} from '../placesSlice';
import { getCategories, getGeographies, getLanguages, getLanguagesWithinPolygon } from '../services';

const { middleTrunc } = irisComponentsCommon;
const POPOVER_STYLE = { maxWidth: 400 };
const POPOVER_STYLE_WIDE = { width: 400 };
const PADDING_POPOVER = { padding: '5px 0px' };
const POPOVER_ORIGINS_SIDE = {
  anchorOrigin: {
    vertical: 'center',
    horizontal: 'right'
  },
  transformOrigin: {
    vertical: 'center',
    horizontal: 'left'
  }
};

const useStyles = makeStyles((theme) => ({
  marginRightBottom: {
    margin: theme.spacing(0, 1, 1, 0)
  },
  formControl: {
    margin: theme.spacing(1),
    width: POPOVER_STYLE.maxWidth
  },
  flexTypography: {
    maxWidth: POPOVER_STYLE.maxWidth,
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      paddingRight: theme.spacing(1)
    }
  },
  otherFiltersBox: {
    display: 'flex',
    flexFlow: 'row wrap'
  },
  customCalendarDivider: {
    margin: theme.spacing(1, 2, 2, 2),
    display: 'flex'
  },
  languagesAutoCompleteContainer: {
    width: POPOVER_STYLE.maxWidth,
    padding: theme.spacing(2)
  },
  resetChip: {
    transition: `border-color ${theme.transitions.duration.standard}ms ${theme.transitions.easing.sharp}`,
    '&:hover': {
      borderColor: theme.palette.error.main
    }
  }
}));

const FilterChips = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // REDUX
  const mapZoom = useSelector((state) => state.app.mapZoom);
  const mapPolygon = useSelector((state) => state.app.mapPolygon);

  const { sort, sortOptions, sortDefault, sortDirection, sortDirectionDefault } = useSelector(placesSelectors.sortState);
  const {
    filterOnlyPlacesWithCategory,
    filterByQuestionAndAnswer,
    filterOnlyPlacesWithName,
    filterOnlyPlacesWithOCRLanguage,
    filterOnlyPlacesWithOCRContent,
    filterByDataRecencyOptions,
    filterByDataRecency,
    filterByGeographies,
    filterByOpenStatusOptions,
    filterByOpenStatus,
    filterOnlyPlacesUnvalidated,
    filterByDateRange,
    showOSM
  } = useSelector(placesSelectors.filterStates);

  // LOCAL STATE
  const [geographiesQueryInput, setGeographiesQueryInput] = useState('');

  // QUERIES
  const availableCategoriesQuery = getCategories.useQuery();
  const availableGeographiesQuery = getGeographies.useQuery({ query: geographiesQueryInput }, { skip: geographiesQueryInput.length < 2 });
  const availableLanguagesQuery = getLanguages.useQuery();
  const languagesWithinMapPolygon = getLanguagesWithinPolygon.useQuery({ mapPolygon }, { skip: mapZoom < 4 || !mapPolygon });

  // USECALLBACK
  const sortDisabled = useCallback(
    (mode) => {
      if (mapZoom >= 4.0) return false;
      if (mode === 'distance from center') return true;
      return false;
    },
    [mapZoom]
  );

  const onSortChange = useCallback(
    (e /* { sort, sortDirection } */) => {
      dispatch(setSort(e));
    },
    [dispatch]
  );
  const onCategoriesChanged = useCallback(
    (_, categories) => {
      dispatch(setFilterCategories(categories));
    },
    [dispatch]
  );
  const onQAChanged = useCallback(
    (qa) => {
      dispatch(setFilterQA(qa));
    },
    [dispatch]
  );
  const onPlaceNameChanged = useCallback(
    (v) => {
      dispatch(setFilterName(v));
    },
    [dispatch]
  );
  const onPlaceNameCleared = useCallback(() => {
    dispatch(setFilterName(''));
  }, [dispatch]);
  const onDataRecencyChanged = useCallback(
    (e) => {
      dispatch(setDataRecency(e.target.value));
    },
    [dispatch]
  );

  const onShowOSMToggled = useCallback(
    (e) => {
      dispatch(setShowOSM(e.target.checked));
    },
    [dispatch]
  );

  const onShowOnlyUnvalidatedPlacesToggled = useCallback(
    (e) => {
      dispatch(setOnlyUnvalidated(e.target.checked));
    },
    [dispatch]
  );

  const onPlaceOCRLanguagesChanged = useCallback(
    (_, languages) => {
      dispatch(setFilterOCRLanguages(languages));
    },
    [dispatch]
  );

  const onGeographiesChanged = useCallback(
    (_, geographies) => {
      dispatch(setFilterGeographies(geographies));
    },
    [dispatch]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onGeographiesInputChanged = useCallback(
    debounce((_, query) => {
      setGeographiesQueryInput(query);
    }, 650),
    []
  );

  const onPlaceOCRContentChanged = useCallback(
    (v) => {
      dispatch(setFilterOCRContent(v));
    },
    [dispatch]
  );

  const onPlaceOCRContentCleared = useCallback(() => {
    dispatch(setFilterOCRContent(''));
  }, [dispatch]);
  const onFilterReset = useCallback(() => {
    dispatch(resetFilters());
  }, [dispatch]);

  const onDateRangeChanged = useCallback(
    (momentObj, side) => {
      if (!momentObj) {
        return dispatch(
          updateDateRange({
            [side]: undefined,
            [side + 'Str']: undefined
          })
        );
      }
      dispatch(
        updateDateRange({
          [side]: momentObj.valueOf(),
          [side + 'Str']: momentObj.format('YYYY-MM-DD')
        })
      );
    },
    [dispatch]
  );

  const onOperatingStatusChanged = useCallback(
    (e) => {
      if (e?.target?.dataset?.opt_value) dispatch(setOperatingStatus(e.target.dataset.opt_value));
    },
    [dispatch]
  );

  // const availableLanguagesAutocompleteGroupBy = useCallback(
  //   (d) => {
  //     if (
  //       // \n
  //       !languagesWithinMapPolygon.data ||
  //       languagesWithinMapPolygon.isError ||
  //       languagesWithinMapPolygon.isUninitialized
  //     )
  //       return undefined;
  //     return languagesWithinMapPolygon.data.includes(d[0]) ? 'In This Area' : 'Not In This Area';
  //   },
  //   [
  //     // \n
  //     languagesWithinMapPolygon.data,
  //     languagesWithinMapPolygon.isError,
  //     languagesWithinMapPolygon.isUninitialized
  //   ]
  // );

  // USEMEMO
  const availableLanguagesJoin = useMemo(() => {
    if (
      // don't have data:
      !languagesWithinMapPolygon.data ||
      !availableLanguagesQuery.data ||
      // an error with either request:
      languagesWithinMapPolygon.isError ||
      availableLanguagesQuery.isError ||
      // or zoom level too high:
      languagesWithinMapPolygon.isUninitialized
    ) {
      return null;
    }

    return {
      groupBy: (d) => {
        return d[2] ? 'In This Area' : 'Not In This Area';
      },
      withinPolygon: languagesWithinMapPolygon.data,
      availableLanguages: availableLanguagesQuery.data
        .map((d) => [...d, languagesWithinMapPolygon.data.includes(d[0])])
        .sort((a, b) => {
          if (a[2] === b[2]) return 0;
          if (a[2] && !b[2]) return -1;
          return 1;
        })
    };
  }, [
    // \n
    languagesWithinMapPolygon.data,
    languagesWithinMapPolygon.isError,
    languagesWithinMapPolygon.isUninitialized,
    availableLanguagesQuery.data,
    availableLanguagesQuery.isError
  ]);

  const availableCategoriesDisabled = !availableCategoriesQuery.data || availableCategoriesQuery.isError;
  const availableLanguagesDisabled = !availableLanguagesQuery.data || availableLanguagesQuery.isError;
  const otherIsActive =
    !!filterOnlyPlacesWithName ||
    filterByDataRecency > 0 ||
    filterByOpenStatus !== 'open_or_closed' ||
    filterOnlyPlacesWithOCRLanguage?.length > 0 ||
    filterOnlyPlacesWithOCRContent?.length > 0 ||
    filterByDateRange ||
    showOSM ||
    filterOnlyPlacesUnvalidated;
  return (
    <>
      <SortChipPopover
        id="places-sort"
        className={classes.marginRightBottom}
        sortOpts={sortOptions}
        chipLabel="Sort"
        onSortChange={onSortChange}
        checkDisabled={sortDisabled}
        sort={sort}
        sortDefault={sortDefault}
        sortDirection={sortDirection}
        sortDirectionDefault={sortDirectionDefault}
      />

      <ChipPopoverInstant
        id="places-filter-categories"
        disabled={availableCategoriesDisabled}
        className={classes.marginRightBottom}
        active={filterOnlyPlacesWithCategory.length > 0}
        label="Topics"
        popoverStyle={POPOVER_STYLE}
      >
        <Typography variant="caption" component="div">
          A type of place or subject matter (e.g. clinic, pollution)
        </Typography>
        <Box>
          <Autocomplete
            getOptionSelected={(a, b) => a.topic === b.topic}
            renderInput={(params) => <TextField {...params} variant="standard" placeholder="Clinic" fullWidth={true} autoFocus={true} />}
            getOptionLabel={(d) => capitalCase(d.topic)}
            multiple={true}
            options={availableCategoriesQuery.data}
            value={filterOnlyPlacesWithCategory}
            onChange={onCategoriesChanged}
          />
        </Box>
      </ChipPopoverInstant>

      <ChipPopoverInstant
        id="places-filter-qa"
        disabled={filterOnlyPlacesWithCategory.length === 0}
        className={classes.marginRightBottom}
        active={filterByQuestionAndAnswer.length > 0}
        label="Q & A"
        popoverStyle={POPOVER_STYLE_WIDE}
      >
        <QAFilter qa={filterByQuestionAndAnswer} selectedCategories={filterOnlyPlacesWithCategory} onChange={onQAChanged} />
      </ChipPopoverInstant>

      <ChipPopoverInstant label="Name" className={classes.marginRightBottom} active={!!filterOnlyPlacesWithName} popoverOrigins={POPOVER_ORIGINS_SIDE} maxWidth={200}>
        <Typography className={classes.flexTypography} variant="caption">
          <CommentIcon />
          <span>For performance, the place name filter will not be applied at the global scale.</span>
        </Typography>
        <TextFieldDebounced
          fullWidth={true}
          label="Place Name"
          placeholder="🧋 Starbucks"
          onChange={onPlaceNameChanged}
          value={filterOnlyPlacesWithName}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton disabled={filterOnlyPlacesWithName.length === 0} size="small" onClick={onPlaceNameCleared}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </ChipPopoverInstant>

      <ChipPopoverInstant label="Operating Status" className={classes.marginRightBottom} active={filterByOpenStatus !== 'open_or_closed'} popoverStyle={PADDING_POPOVER}>
        {filterByOpenStatusOptions.map((option /* open */) => (
          <MenuItem key={option} selected={filterByOpenStatus === option} data-opt_value={option} onClick={onOperatingStatusChanged}>
            {capitalCase(option)}
          </MenuItem>
        ))}
      </ChipPopoverInstant>
      <ChipPopoverInstant label="Other" className={classes.marginRightBottom} active={otherIsActive} spacer={true} clearDone={false} popoverStyle={POPOVER_STYLE_WIDE}>
        <Box className={classes.otherFiltersBox}>
          <ChipPopoverInstant
            label="Data recency"
            className={classes.marginRightBottom}
            active={filterByDataRecency > 0}
            popoverOrigins={POPOVER_ORIGINS_SIDE}
            popoverStyle={PADDING_POPOVER}
          >
            {filterByDataRecencyOptions.map((n /* 30 */) => {
              let label;
              switch (n) {
                case 0:
                  label = 'Any';
                  break;
                case -1:
                  label = 'Custom Date Range';
                  break;
                default:
                  label = `${n} Days`;
              }
              return (
                <MenuItem key={n} selected={n === filterByDataRecency} value={n} onClick={onDataRecencyChanged}>
                  {label}
                </MenuItem>
              );
            })}
          </ChipPopoverInstant>

          <ChipPopoverInstant active={filterByGeographies?.length > 0} className={classes.marginRightBottom} label="Geographies" popoverStyle={PADDING_POPOVER}>
            <Box className={classes.languagesAutoCompleteContainer}>
              <Autocomplete
                autoComplete={true}
                includeInputInList={true}
                filterOptions={(x) => x}
                getOptionLabel={(d) => d.name}
                getOptionSelected={(a, b) => a.name === b.name}
                multiple={true}
                onChange={onGeographiesChanged}
                onInputChange={onGeographiesInputChanged}
                options={availableGeographiesQuery.data ?? []}
                renderInput={(params) => <TextField {...params} variant="standard" placeholder="Switzerland" fullWidth={true} autoFocus={true} />}
                value={filterByGeographies}
              />
            </Box>
          </ChipPopoverInstant>

          <ChipPopoverInstant
            disabled={availableLanguagesDisabled}
            label="Photo Language"
            className={classes.marginRightBottom}
            active={filterOnlyPlacesWithOCRLanguage?.length > 0}
            popoverStyle={PADDING_POPOVER}
          >
            <Box className={classes.languagesAutoCompleteContainer}>
              <Autocomplete
                disableCloseOnSelect={true}
                getOptionSelected={(a, b) => a[0] === b[0]}
                renderInput={(params) => <TextField {...params} variant="standard" placeholder="English" fullWidth={true} autoFocus={true} />}
                // in list:
                renderOption={(d) => `${middleTrunc(d[1], 35)} (${d[0]})`}
                // selected pill:
                getOptionLabel={(d) => {
                  let asterisk = '';
                  if (availableLanguagesJoin?.withinPolygon && !availableLanguagesJoin.withinPolygon.includes(d[0])) asterisk = '*';
                  return `${middleTrunc(d[1], 35)}${asterisk} (${d[0]})`;
                }}
                multiple={true}
                groupBy={availableLanguagesJoin?.groupBy}
                options={availableLanguagesJoin?.availableLanguages ?? availableLanguagesQuery.data}
                value={filterOnlyPlacesWithOCRLanguage}
                onChange={onPlaceOCRLanguagesChanged}
              />
              <FormHelperText>Geographic context available below zoom level 4. Asterisk denote selection not in area.</FormHelperText>
            </Box>
          </ChipPopoverInstant>
          <ChipPopoverInstant label="Photo Contents" className={classes.marginRightBottom} active={filterOnlyPlacesWithOCRContent?.length > 0} popoverStyle={PADDING_POPOVER}>
            <Box className={classes.languagesAutoCompleteContainer}>
              <TextFieldDebounced
                variant="standard"
                placeholder="atm"
                fullWidth={true}
                autoFocus={true}
                value={filterOnlyPlacesWithOCRContent}
                onChange={onPlaceOCRContentChanged}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={onPlaceOCRContentCleared} size="small" disabled={!filterOnlyPlacesWithOCRContent}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
          </ChipPopoverInstant>
          <ChipPopover label="Advanced" active={showOSM || filterOnlyPlacesUnvalidated} popoverStyle={POPOVER_STYLE} className={classes.marginRightBottom}>
            <FormControlLabel control={<Checkbox checked={showOSM} onChange={onShowOSMToggled} />} label="Show OSM Places" />
            <FormControlLabel control={<Checkbox checked={filterOnlyPlacesUnvalidated} onChange={onShowOnlyUnvalidatedPlacesToggled} />} label="Show Only Unvalidated Places" />
          </ChipPopover>
        </Box>
        {filterByDataRecency === -1 && (
          <React.Fragment>
            <Divider className={classes.customCalendarDivider} />
            <DateAndTimeFrameSelector selectedStartDate={filterByDateRange?.start} selectedEndDate={filterByDateRange?.end} onDateRangeChange={onDateRangeChanged} />
          </React.Fragment>
        )}
      </ChipPopoverInstant>
      <Chip
        className={classes.marginRightBottom}
        classes={{ outlined: classes.resetChip }}
        variant="outlined"
        label={'Reset All'}
        onClick={onFilterReset}
        onDelete={onFilterReset}
        deleteIcon={<ClearIcon color="inherit" />}
      />
    </>
  );
};

FilterChips.propTypes = {};
FilterChips.defaultProps = {};

export default React.memo(FilterChips);
