import { irisApi } from '../../services';

// MODULES
import { scaleQuantile, extent } from 'd3';
import shajs from 'sha.js';
import equal from 'deep-equal';

// OURS
import { PRODUCT_COMPARE } from './constants';

const requestFromArgs = (shared, args /* optional, side specific data */) => {
  // `args` will only be specified if we are passing "left" & "right"
  const temporalRequest = !args;

  args = args ?? shared;

  const {
    polygonRenderLevel,
    selectedForms,
    selectedQuestions,
    selectedQuestionsOrdering,
    selectedAnswer,
    selectedGeographies,
    alertTimelineFilterType,
    alertTimelineFilterSampleSize,
    alertTimelineFilterSignificance,
    alertTimelineFilterPctChange,
    alertTimelineFilterMinAlerts
  } = shared;

  const {
    // \n
    QAFilterSelectedAnswers,
    QAFilterSelectedForm,
    QAFilterSelectedQuestion,
    selectedDateRange,
    selectedDemographics
  } = args;

  const body = {
    level: polygonRenderLevel ?? 0,
    form_list: selectedForms.map((d) => d.form_id),
    question_list: selectedQuestions.map((d) => d.question_name),
    ordering: selectedQuestionsOrdering,
    focusGroup: selectedAnswer?.label,
    hascs: selectedGeographies.map((d) => d.hasc)
  };

  if (temporalRequest) {
    body.alert_settings = {
      mode: alertTimelineFilterType,
      min_sample_size: alertTimelineFilterSampleSize,
      significance_level: alertTimelineFilterSignificance,
      pct_change: alertTimelineFilterPctChange,
      min_daily_alerts: alertTimelineFilterMinAlerts
    };
  }

  if (selectedDateRange) {
    const s = selectedDateRange.strStart ?? '1980-01-01';
    const e = selectedDateRange.strEnd ?? '2030-01-01';
    body.time_break = [s, e];
  }

  if (selectedDemographics) {
    for (const [demographic, values] of Object.entries(selectedDemographics)) {
      body.demographics = body.demographics ?? {};
      body.demographics[demographic] = values;
    }
  }

  if (QAFilterSelectedForm && QAFilterSelectedQuestion && QAFilterSelectedAnswers?.length > 0) {
    body.also = [
      {
        form_id: QAFilterSelectedForm.form_id,
        form_name: QAFilterSelectedForm.form_name,
        question_name: QAFilterSelectedQuestion.question_name,
        question_proper: QAFilterSelectedQuestion.question_proper,
        response_proper: QAFilterSelectedAnswers
      }
    ];
  }

  return body;
};

// Injects endpoints into the iris Api
export const sentimentApi = irisApi.injectEndpoints({
  endpoints: (builder) => ({
    getDetailPanelData: builder.query({
      query: ({
        selectedForms,
        selectedQuestions,
        selectedQuestionsOrdering,
        selectedGeographies,
        selectedAnswer,
        polygonRenderLevel,

        // TEMPORAL:
        selectedDateRange,

        // LEFT SPECIFIC:
        selectedDateRangeLeft,
        selectedDemographicsLeft,
        QAFilterSelectedFormLeft,
        QAFilterSelectedQuestionLeft,
        QAFilterSelectedAnswersLeft,
        // RIGHT SPECIFIC:
        selectedDateRangeRight,
        selectedDemographicsRight,
        QAFilterSelectedFormRight,
        QAFilterSelectedQuestionRight,
        QAFilterSelectedAnswersRight,

        selectedHasc,
        activeView
      }) => {
        const body = {
          left: {
            level: polygonRenderLevel ?? 0,
            form_list: selectedForms.map((d) => d.form_id),
            question_list: selectedQuestions.map((d) => d.question_name),
            ordering: selectedQuestionsOrdering,
            focusGroup: selectedAnswer?.label,
            hascs: selectedGeographies.map((d) => d.hasc)
          }
        };

        if (selectedDateRangeLeft) {
          const s = selectedDateRangeLeft.strStart ?? '1980-01-01';
          const e = selectedDateRangeLeft.strEnd ?? '2030-01-01';
          body.left.time_break = [s, e];
        } else if (selectedDateRange) {
          const s = selectedDateRange.strStart ?? '1980-01-01';
          const e = selectedDateRange.strEnd ?? '2030-01-01';
          body.left.time_break = [s, e];
        }

        if (selectedDemographicsLeft) {
          for (const [demographic, values] of Object.entries(selectedDemographicsLeft)) {
            body.left.demographics = body.demographics ?? {};
            body.left.demographics[demographic] = values;
          }
        }

        if (QAFilterSelectedFormLeft && QAFilterSelectedQuestionLeft && QAFilterSelectedAnswersLeft?.length > 0) {
          body.left.also = [
            {
              form_id: QAFilterSelectedFormLeft.form_id,
              form_name: QAFilterSelectedFormLeft.form_name,
              question_name: QAFilterSelectedQuestionLeft.question_name,
              question_proper: QAFilterSelectedQuestionLeft.question_proper,
              response_proper: QAFilterSelectedAnswersLeft
            }
          ];
        }

        if (activeView === PRODUCT_COMPARE) {
          const right = {
            level: polygonRenderLevel ?? 0,
            form_list: selectedForms.map((d) => d.form_id),
            question_list: selectedQuestions.map((d) => d.question_name),
            ordering: selectedQuestionsOrdering,
            hascs: selectedGeographies.map((d) => d.hasc)
          };

          if (selectedDateRangeRight) {
            const s = selectedDateRangeRight.strStart ?? '1980-01-01';
            const e = selectedDateRangeRight.strEnd ?? '2030-01-01';
            right.time_break = [s, e];
          }

          if (selectedDemographicsRight) {
            for (const [demographic, values] of Object.entries(selectedDemographicsRight)) {
              right.demographics = body.demographics ?? {};
              right.demographics[demographic] = values;
            }
          }

          if (QAFilterSelectedFormRight && QAFilterSelectedQuestionRight && QAFilterSelectedAnswersRight?.length > 0) {
            right.also = [
              {
                form_id: QAFilterSelectedFormRight.form_id,
                form_name: QAFilterSelectedFormRight.form_name,
                question_name: QAFilterSelectedQuestionRight.question_name,
                question_proper: QAFilterSelectedQuestionRight.question_proper,
                response_proper: QAFilterSelectedAnswersRight
              }
            ];
          }

          if (!equal(body.left, right)) body.right = right;
        }

        return {
          method: 'post',
          url: `/sentiment/v0/geo-sentiment/popup/${selectedHasc}`,
          body
        };
      }
    }),
    getMapboxStyle: builder.query({
      query: ({ style_id, mapboxAccessToken }) => {
        return {
          method: 'get',
          url: `https://api.mapbox.com/styles/v1/joeraii/${style_id}?access_token=${mapboxAccessToken}`
        };
      }
    }),
    getAvailableForms: builder.query({
      transformResponse: (forms) => {
        return forms.sort((a, b) => {
          if (a.is_anchor === b.is_anchor) return 0;
          if (a.is_anchor) return 1;
          return -1;
        });
      },
      query: ({ selectedGeographies, withAlertsOnly = false }) => {
        const body = { withAlertsOnly };
        if (selectedGeographies?.length > 0) {
          body.geographies = selectedGeographies.map((d) => d.hasc);
        }
        return { url: '/sentiment/v0/lookups/forms', method: 'post', body };
      }
    }),
    getAvailableQuestions: builder.query({
      query: (selectedForms) => ({ url: '/sentiment/v0/lookups/questions', method: 'post', body: { form_ids: selectedForms.map((d) => d.form_id) } }),
      providesTags: ['SentimentQuestions']
    }),
    getAvailableGeographies: builder.query({
      query: () => '/sentiment/v0/lookups/gadm'
    }),
    getAlerts: builder.query({
      transformResponse: (alerts) => {
        const alertCounts = alerts.map((alert) => alert.num_alerts);
        const iconScale = scaleQuantile().domain(alertCounts).range([0, 1, 2]);
        return alerts.map((alert) => ({
          ...alert,
          iconIndex: iconScale(alert.num_alerts),
          // TODO: ask tim s. for a unique identifier:
          key: shajs('sha256')
            .update(
              Object.keys(alert)
                .sort()
                .map((k) => alert[k] ?? '')
                .join('')
            )
            .digest('hex')
        }));
      },
      query: ({ alertSort, alertSortDirection, alertDataRecency, selectedGeographies, selectedForms, selectedQuestions }) => {
        const body = {
          max_records: 500,
          sort_fields: [alertSort],
          sort_direction: [alertSortDirection],
          last_seen: alertDataRecency,
          filters: {}
        };
        if (selectedForms?.length > 0) {
          body.filters.form_list = selectedForms.map((d) => d.form_id);
        }
        if (selectedQuestions?.length > 0) {
          body.filters.question_list = selectedQuestions.map((d) => d.question_name);
        }
        if (selectedGeographies?.length > 0) {
          body.filters.hascs = selectedGeographies.map((d) => d.hasc);
        }
        return { url: '/sentiment/v0/geo-sentiment/alerts_filter', method: 'post', body };
      }
    }),
    getTimeseries: builder.query({
      transformResponse: (r) => {
        if (!r || !r.data) return null;
        return {
          data: r.data,
          name: r.name,
          temporalDomain: extent(r.data.map((d) => d.date))
        };
      },
      query: ({ selectedForms, selectedQuestions, selectedHasc }) => {
        const body = {
          form_id: selectedForms[0].form_id,
          question_name: selectedQuestions[0].question_name,
          hasc: !selectedHasc || selectedHasc === 'global' ? 'ALL' : selectedHasc
        };
        return { url: '/sentiment/v0/geo-sentiment/tseries', method: 'post', body };
      }
    }),
    getTimeseriesAlerts: builder.query({
      query: ({
        selectedForms,
        selectedQuestions,
        selectedHasc,
        alertTimelineFilterType,
        alertTimelineFilterSampleSize,
        alertTimelineFilterSignificance,
        alertTimelineFilterMinAlerts,
        alertTimelineFilterPctChange
      }) => {
        const body = {
          forms: selectedForms.map((d) => d.form_id),
          questions: selectedQuestions.map((d) => d.question_name),
          hasc: selectedHasc,
          min_sample_size: alertTimelineFilterSampleSize,
          significance_level: alertTimelineFilterSignificance,
          min_daily_alerts: alertTimelineFilterMinAlerts,
          pct_change: alertTimelineFilterPctChange
        };
        return { url: `/sentiment/v0/lookups/alerts/${alertTimelineFilterType /* "daily", "weekly" */}`, method: 'post', body };
      }
    }),
    getMapDataCompare: builder.query({
      query: ({
        // LEFT SPECIFIC:
        selectedDateRangeLeft,
        selectedDemographicsLeft,
        QAFilterSelectedFormLeft,
        QAFilterSelectedQuestionLeft,
        QAFilterSelectedAnswersLeft,
        // RIGHT SPECIFIC:
        selectedDateRangeRight,
        selectedDemographicsRight,
        QAFilterSelectedFormRight,
        QAFilterSelectedQuestionRight,
        QAFilterSelectedAnswersRight,
        ...shared
      }) => {
        const argsLeft = {
          selectedDateRange: selectedDateRangeLeft,
          selectedDemographics: selectedDemographicsLeft,
          QAFilterSelectedForm: QAFilterSelectedFormLeft,
          QAFilterSelectedQuestion: QAFilterSelectedQuestionLeft,
          QAFilterSelectedAnswers: QAFilterSelectedAnswersLeft
        };
        const argsRight = {
          selectedDateRange: selectedDateRangeRight,
          selectedDemographics: selectedDemographicsRight,
          QAFilterSelectedForm: QAFilterSelectedFormRight,
          QAFilterSelectedQuestion: QAFilterSelectedQuestionRight,
          QAFilterSelectedAnswers: QAFilterSelectedAnswersRight
        };
        return {
          url: '/sentiment/v0/geo-sentiment/thin-geo',
          method: 'post',
          body: {
            left: requestFromArgs(shared, argsLeft),
            right: equal(argsLeft, argsRight) ? null : requestFromArgs(shared, argsRight)
          },
          responseHandler: async (response) => window.irisProto.ThinResponse.decode(new Uint8Array(await response.arrayBuffer())).response.map((d) => ({ ...d }))
        };
      }
    }),
    getMapData: builder.query({
      query: (args) => {
        return {
          url: '/sentiment/v0/geo-sentiment/thin-geo',
          method: 'post',
          body: {
            left: requestFromArgs(args)
          },
          responseHandler: async (response) => window.irisProto.ThinResponse.decode(new Uint8Array(await response.arrayBuffer())).response.map((d) => ({ ...d }))
        };
      }
    }),
    getDateHistograms: builder.query({
      query: ({ selectedForms, selectedQuestions }) => {
        const body = {
          forms: selectedForms.map((d) => d.form_id),
          questions: selectedQuestions.map((d) => d.question_name)
        };
        return {
          url: '/sentiment/v0/lookups/dates',
          method: 'post',
          body
        };
      }
    }),
    getDemographics: builder.query({
      query: ({ selectedForms }) => {
        const body = {
          forms: selectedForms.map((d) => d.form_id)
        };
        return {
          url: '/sentiment/v0/lookups/demographics',
          method: 'post',
          body
        };
      }
    }),
    getBoundsForHasc: builder.query({
      query: ({ hasc }) => ({
        url: `/sentiment/v0/lookups/bounds-for/${hasc}`,
        method: 'get'
      })
    })
  }),
  overrideExisting: false
});

// Must use this naming scheme because the hooks are autogenerated.
export const {
  getDetailPanelData,
  getDemographics,
  getDateHistograms,
  getMapboxStyle,
  getMapData,
  getMapDataCompare,
  getForms,
  getAlerts,
  getAvailableForms,
  getAvailableQuestions,
  getAvailableGeographies,
  getTimeseries,
  getTimeseriesAlerts,
  getBoundsForHasc
} = sentimentApi.endpoints;
