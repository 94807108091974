import { genericGet } from 'iris-api'; // eslint-disable-line import/no-unresolved

const KEY = 'premise_id';

export default (access_token, style_id, layer_ids, data, color_opacity, color_scale, outline_key, callback) => {
  const url = `https://api.mapbox.com/styles/v1/joeraii/${style_id}?access_token=${access_token}`;
  genericGet(
    url,
    null,
    (e, r) => {
      if (e) return callback(e);

      /*
      How does this work?
      If layer.paint['fill-color'] is a string, it paints all regions the color of that string.
      Otherwise,
      it should be an array, where the first two args are ['match', ['get', KEY]], every other arg
      will now have to be in the pattern of pairs such as ['US.NV', 'rgb(0,0,255,0.2)'] and mapbox will take care of that.
      And then stick a string at the end that tells mapbox to color every other region not listed to be this (this will
      most likely just be transparent 'rgba(0,0,0,0)')
      This should be applicable to all style properties for mapbox paint
      https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/
      */

      for (const layer of r.layers) {
        if (layer.id === 'outline') {
          if (outline_key !== null) {
            layer.paint['line-color'] = ['match', ['get', KEY], outline_key, 'rgba(0,0,255,1)', 'rgba(0,0,0,0)'];
          } else {
            // paint everything transparent
            layer.paint['line-color'] = 'rgba(0,0,0,0)';
          }
        } else if (layer.id === 'outline-thin') {
          let expression = [];
          if (data && Object.keys(data).length > 0) {
            expression = ['match', ['get', KEY]].concat(Object.keys(data).flatMap((key) => [key, 1]));
            expression.push(0);
          }
          layer.paint['line-opacity'] = expression.length === 0 ? 0 : expression;
        } else if (layer.id === layer_ids[2]) {
          let expression = [];
          if (data && Object.keys(data).length > 0) {
            expression = ['match', ['get', KEY]].concat(Object.keys(data).flatMap((key) => [key, data[key]]));
            expression.push('rgba(0,0,0,0)');
          }
          layer.paint['fill-color'] = expression.length === 0 ? 'rgba(0,0,0,0)' : expression;
        }
      }
      callback(null, r);
    },
    true /* ignore credentials */
  );
};
