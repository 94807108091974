import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// Module
import numeral from 'numeral';
import { scaleLinear } from 'd3';
import debounce from 'lodash.debounce';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles((theme) => ({
  polygonSampleSizePopper: {
    zIndex: theme.zIndex.modal
  },
  polygonSampleSizePopperPaper: {
    height: 250,
    padding: theme.spacing(2),
    minWidth: 100
  },
  valueLabel: {
    fontSize: '0.5em',
    fontWeight: theme.typography.fontWeightBold,
    '& > span > span': {
      color: theme.palette.getContrastText(theme.palette.secondary.main)
    }
  }
}));

const SampleSizeSlider = (props) => {
  const { anchorEl, onClickAway, polygonSampleSizeRange, onChange } = props;
  const [value, setValue] = useState(props.defaultValue);
  const classes = useStyles();

  const broadcastChange = useMemo(
    () => debounce((v) => {
      onChange(v);
    }, 333),
    [onChange]
  );

  const valueLabelFormat = useCallback((v) => {
    return Number.isFinite(v) ? numeral(v).format('0.0a') : v;
  }, []);

  const onChangeCallback = useCallback((_, v) => {
    setValue(v);
    broadcastChange(v);
  }, [broadcastChange]);

  const marks = useMemo(() => {
    const max = polygonSampleSizeRange?.[1] ?? 10;
    const s = scaleLinear().domain([0, 1]).range([0, max]);
    return [0, s(0.2), s(0.4), s(0.6), s(0.8), max].map((d) => ({ value: d, label: numeral(d).format('0.0a') }));
  }, [polygonSampleSizeRange]);

  return (
    <Popper placement="top" open={!!anchorEl} anchorEl={anchorEl} className={classes.polygonSampleSizePopper}>
      <ClickAwayListener onClickAway={onClickAway}>
        <Paper className={classes.polygonSampleSizePopperPaper}>
          <Slider
            classes={{ valueLabel: classes.valueLabel }}
            valueLabelDisplay="on"
            valueLabelFormat={valueLabelFormat}
            marks={marks ?? false}
            disabled={!polygonSampleSizeRange}
            min={polygonSampleSizeRange?.[0] ?? 0}
            max={polygonSampleSizeRange?.[1] ?? 10}
            orientation="vertical"
            // step={(polygonSampleSizeRange?.[1] ?? 200) / 20}
            color="secondary"
            value={value}
            onChange={onChangeCallback}
          />
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

SampleSizeSlider.propTypes = {
  anchorEl: PropTypes.instanceOf(Element),
  polygonSampleSizeRange: PropTypes.array,
  defaultValue: PropTypes.number,

  onClickAway: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default React.memo(SampleSizeSlider);
