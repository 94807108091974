import * as c from '../constants';
import moment from 'moment';
import amplitude from 'amplitude-js';

const save = {
  includeOSM: 'osm'
};

const rootReducer = (state = {}, action) => {
  if (action.type === c.UPDATE_MAP_POSITION) {
    const { mapBounds, mapCenter, mapZoom, mapPolygon, mapPolygonBuffered, mapPitch, mapBearing } = action.payload;
    const placesActivePanel = state.placesActivePanel;
    return { ...state, mapBounds, mapCenter, mapZoom, placesActivePanel, mapPolygon, mapPolygonBuffered, mapPitch, mapBearing };
  } else if (action.type === c.TOGGLE_GPU) {
    const hasGPU = !state.hasGPU;
    // save this users GPU preference:
    window.localStorage.hasGPU = hasGPU;
    return { ...state, hasGPU };
  } else if (action.type === c.TOGGLE_THEME) {
    const palette = state.palette === 'light' ? 'dark' : 'light';
    window.localStorage.theme = palette;
    return { ...state, palette };
  } else if (action.type === c.DEMO_ACTIVE_PANEL) {
    return {
      ...state,
      demoActivePanel: state.demoActivePanel === action.payload.demoActivePanel ? null : action.payload.demoActivePanel
    };
  } else if (action.type === c.SENTIMENT_ACTIVE_PANEL) {
    return {
      ...state,
      sentimentActivePanel: state.sentimentActivePanel === action.payload.sentimentActivePanel ? null : action.payload.sentimentActivePanel
    };
  } else if (action.type === c.PLACES_ACTIVE_PANEL) {
    return {
      ...state,
      placesActivePanel: state.placesActivePanel === action.payload.placesActivePanel ? null : action.payload.placesActivePanel
    };
  } else if (action.type === c.RADIO_ACTIVE_PANEL) {
    return {
      ...state,
      radioActivePanel: state.radioActivePanel === action.payload.radioActivePanel ? null : action.payload.radioActivePanel
    };
  } else if (action.type === c.ENLARGE_PHOTO) {
    return {
      ...state,
      enlargePhotoLink: action.payload
    };
  } else if (action.type === c.PUSH_NOTIFICATION) {
    const key = new Date().getTime();
    return {
      ...state,
      notificationQueue: [...state.notificationQueue, { ...action.payload, key }]
    };
  } else if (action.type === c.POP_NOTIFICATION) {
    if (state.notificationQueue.length === 0) return state;
    return {
      ...state,
      notificationQueue: state.notificationQueue.slice(1)
    };
  } else if (action.type === c.UPDATE_GUIDE) {
    return {
      ...state,
      guide: action.payload
    };
  } else if (action.type === c.GENERIC) {
    const payloadKeys = Object.keys(action.payload);
    const outState = {
      ...state,
      ...action.payload
    };
    const k = payloadKeys[0];
    if (payloadKeys.length === 1 && save[k]) {
      window.localStorage[k] = action.payload[k];
    }
    if (state.activeProduct !== null && payloadKeys.includes('activeProduct')) {
      const now = Date.now();
      outState.productTimer = now;

      const duration = now - state.productTimer;
      const humanDuration = moment.duration(duration).humanize(false);

      amplitude.getInstance().logEvent('product_duration', {
        product: state.activeProduct,
        ms: duration,
        duration: humanDuration
      });
    }
    if (
      // \n
      payloadKeys.includes('initialQueryString') &&
      typeof action.payload.initialQueryString === 'object' &&
      Object.keys(action.payload.initialQueryString).length === 0
    ) {
      window.history.replaceState(null, null, window.location.pathname);
    }
    return outState;
  } // end of ( action.type === c.GENERIC )

  return state;
};

export default rootReducer;
