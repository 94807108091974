import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTheme, alpha } from '@material-ui/core/styles';

// modules
import { scaleLinear, timeFormat, scaleLog, extent, scaleTime } from 'd3';

// VISX
import { Group } from '@visx/group';
import { LinePath, Circle, AreaStack } from '@visx/shape';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { Text } from '@visx/text';
import { curveLinear } from '@visx/curve';

// Ours
import { common as irisComponentsCommon } from '@premisedata/iris-components';
import { buildGroupColors } from '../common';

const { middleTrunc } = irisComponentsCommon;

const AreaChart = (props) => {
  const theme = useTheme();
  const {
    // Positional & Display Props
    margin,
    left,
    top,
    width,
    height,
    bottomAxisType /* full, minimal */,
    colorRamp /* "interpolateViridis" */,
    colorRampInverted /* false */,
    curve /* curveLinear */,
    hideLeftAxis /* false */,
    yAxisTitle /* "fancy title" */,
    children
  } = props;

  // axisLeftTickLabelProps & axisBottomTickLabelProps:
  const buildAxisProps = (fill) => ({
    axisBottomTickLabelProps: {
      textAnchor: 'middle',
      // fontFamily: 'Roboto',
      fontSize: 10,
      fill
    },
    axisLeftTickLabelProps: {
      dx: '-0.25em',
      dy: '0.25em',
      // fontFamily: 'Roboto',
      fontSize: '10px',
      textAnchor: 'end',
      fill
    }
  });
  const fill = theme.palette.text.primary;
  const [axisProps, setAxisProps] = useState(buildAxisProps(fill));
  useEffect(() => {
    setAxisProps(buildAxisProps(fill));
  }, [fill]);
  const { axisLeftTickLabelProps, axisBottomTickLabelProps } = axisProps;

  // alertColorScale:
  const alertColorScale = useCallback((v) => {
    if (v < 0) {
      return '#E9A3C9';
    } else {
      return '#A1D76A';
    }
  }, []);

  // Render Data Mutations & Calculations:
  const buildRenderData = useCallback(
    (
      selectedQuestions,
      ordering,
      data,
      alertData,
      dataType,
      height,
      width,
      selectedDateRange,
      temporalDomain,
      colorRamp = 'interpolateViridis',
      colorRampInverted = false,
      yScaleZoomed = false
    ) => {
      // if (!selectedQuestions || selectedQuestions.length === 0 || !ordering || !alertData) return {};
      if (!selectedQuestions?.length || !data?.length || !ordering?.length || height < 10 || width < 10) {
        return {};
      }

      // `data` example, ordinal:
      // {
      //   date: 1586563200000;
      //   lower_bound: 0.7884669989242065;
      //   num_samples: 18558;
      //   response: 'ordinal_sentiment';
      //   smoothed_mean: 0.808066638769607;
      //   upper_bound: 0.8276662786150076;
      // }

      // `data` example, categorical:
      // {
      //   date: 1586563200000;
      //   lower_bound: 0.5635346564992975;
      //   num_samples: 38485;
      //   response: 'No (tested negative or have shown no symptoms)';
      //   smoothed_mean: 0.583134296344698;
      //   upper_bound: 0.6027339361900986;
      // }

      // `alertData` example:
      // {
      //   date: 1587686400000;
      //   id: '2020-04-24 00:00:00+00:00_ALL_4850691271294976_how_concerned_are_you_abo_ovid_19_in_your_community';
      //   probability: -0.002095079668294586;
      //   samples: 2994;
      // }

      let filteredData = data;
      let filteredAlertData = alertData ?? [];

      if (selectedDateRange) {
        filteredData = data.filter((d) => {
          return d.date >= selectedDateRange.start && d.date < selectedDateRange.end;
        });

        filteredAlertData = filteredAlertData.filter((d) => {
          return d.date >= selectedDateRange.start && d.date < selectedDateRange.end;
        });
      }

      // rewrite if the question is categorical,
      let dataCategorical, dataCategoricalKeys, smoothedMeanLookup;
      if (dataType === 'categorical') {
        dataCategorical = filteredData.reduce(
          (a, b) => ({
            ...a,
            [b.response]: a[b.response] ? [...a[b.response], b] : [b]
          }),
          {}
        );
        dataCategoricalKeys = Object.keys(dataCategorical);
        smoothedMeanLookup = {};
      } else {
        smoothedMeanLookup = filteredData.reduce((a, b) => ({ ...a, [b.date]: b.smoothed_mean }), {});
      }

      const orderingValues = ordering.map((d) => d.order);
      let yScaleDomain, yScaleTicks;
      if (yScaleZoomed) {
        const yScaleExtent = extent(filteredData.map((d) => d.smoothed_mean));
        const lower = orderingValues.filter((d) => d <= yScaleExtent[0]).slice(-1)[0] ?? 0;
        const upper = orderingValues.filter((d) => d >= yScaleExtent[1])[0] ?? 1;
        yScaleDomain = [lower, upper];
        yScaleTicks = orderingValues.filter((d) => d >= yScaleDomain[0] && d <= yScaleDomain[1]);
      } else {
        yScaleDomain = [0, 1];
        yScaleTicks = orderingValues;
      }

      return {
        groupColors: buildGroupColors(selectedQuestions[0]?.question_name, ordering, colorRamp, colorRampInverted),
        alertRadiusScale: scaleLog()
          .domain(extent(filteredAlertData.map((d) => Math.abs(d.probability))))
          .range([4, 8]),
        dataCategoricalKeys: dataCategoricalKeys,
        data: dataCategorical ?? filteredData,
        alertData: filteredAlertData.map((d) => ({ ...d, smoothed_mean: smoothedMeanLookup[d.date] ?? 0 })),
        dataType,
        ordering,
        orderingLookup: ordering.reduce((a, b) => ({ ...a, [b.order]: b.label }), {}),
        yScale: scaleLinear()
          .domain(yScaleDomain)
          .range([height - margin.top - margin.bottom, 0]),
        xScale: scaleTime()
          .domain(selectedDateRange ? [selectedDateRange.start, selectedDateRange.end] : temporalDomain)
          .range([0, width - margin.left - margin.right]),
        yScaleDomain,
        yScaleTicks
      };
    },
    [margin.bottom, margin.left, margin.right, margin.top]
  );
  const [renderData, setRenderData] = useState(
    buildRenderData(
      props.selectedQuestions,
      props.ordering,
      props.data,
      props.alertData,
      props.dataType,
      height,
      width,
      props.selectedDateRange,
      props.temporalDomain,
      colorRamp,
      colorRampInverted,
      props.yScaleZoomed
    )
  );
  useEffect(() => {
    setRenderData(
      buildRenderData(
        props.selectedQuestions,
        props.ordering,
        props.data,
        props.alertData,
        props.dataType,
        height,
        width,
        props.selectedDateRange,
        props.temporalDomain,
        colorRamp,
        colorRampInverted,
        props.yScaleZoomed
      )
    );
  }, [
    props.selectedQuestions,
    props.ordering,
    props.data,
    props.alertData,
    props.dataType,
    height,
    width,
    props.selectedDateRange,
    props.temporalDomain,
    colorRamp,
    colorRampInverted,
    props.yScaleZoomed,
    buildRenderData
  ]);

  const { groupColors, alertRadiusScale, alertData, dataType, dataCategoricalKeys, data, yScale, yScaleTicks, orderingLookup, yScaleDomain, xScale } = renderData;

  if (!dataType) return null;

  const axisColor = theme.palette.text.primary;
  const xScaleDomain = xScale.domain();

  return (
    <Group left={left + margin.left} top={top + margin.top}>
      {dataType === 'ordinal' && (
        <>
          <LinePath
            curve={curve}
            data={data}
            x={(d) => xScale(d.date) ?? 0}
            y={(d) => yScale(d.smoothed_mean) ?? 0}
            stroke={theme.palette.secondary.main}
            strokeWidth={2}
            strokeOpacity={1}
            shapeRendering="geometricPrecision"
          />
          <AreaStack
            keys={['single_stack...']}
            data={data}
            x={(_, i) => xScale(data[i].date) ?? 0}
            y0={(_, i) => yScale(Math.min(Math.max(data[i].lower_bound, yScaleDomain[0]), yScaleDomain[1])) ?? 0}
            y1={(_, i) => yScale(Math.min(Math.max(data[i].upper_bound, yScaleDomain[0]), yScaleDomain[1])) ?? 0}
          >
            {({ stacks, path }) =>
              stacks.map((stack) => <path key={`stack-${stack.key}`} d={path(stack) || ''} stroke="transparent" fill={alpha(theme.palette.secondary.main, 0.3)} />)
            }
          </AreaStack>
        </>
      )}
      {dataType === 'categorical' &&
        dataCategoricalKeys.map((k) => (
          <LinePath
            key={k}
            curve={curve}
            data={data[k]}
            x={(d) => xScale(d.date) ?? 0}
            y={(d) => yScale(d.smoothed_mean) ?? 0}
            stroke={groupColors[k]}
            strokeWidth={2}
            strokeOpacity={1}
            shapeRendering="geometricPrecision"
          />
        ))}

      {alertData.map((alert) => {
        const p = Math.abs(alert.probability);
        let radius = alertRadiusScale(p) ?? 0;

        // render @ half size for minimal version:
        if (children) radius /= 2.0;

        const stroke = {};
        if (p > alertRadiusScale.invert(6 /* domain hard coded to [4,8] pixels */)) {
          stroke.strokeWidth = 1;
          stroke.stroke = theme.palette.text.primary;
          stroke.strokeDasharray = 1;
        }
        return <Circle fill={alpha(alertColorScale(alert.probability), 0.7)} key={alert.id} r={radius} cx={xScale(alert.date) ?? 0} cy={yScale(alert.smoothed_mean)} {...stroke} />;
      })}

      {bottomAxisType === 'full' && (
        <AxisBottom
          tickFormat={timeFormat('%m/%d %Y')}
          top={height - margin.bottom - margin.top}
          scale={xScale}
          stroke={axisColor}
          tickStroke={axisColor}
          tickLabelProps={() => axisBottomTickLabelProps}
          tickComponent={(tickComponentProps) => {
            const formattedValues = tickComponentProps.formattedValue.split(' ');
            delete tickComponentProps.formattedValue;
            return (
              <React.Fragment>
                <Text {...tickComponentProps}>{formattedValues[0]}</Text>
                <Text {...tickComponentProps} dy="1.0em">
                  {formattedValues[1]}
                </Text>
              </React.Fragment>
            );
          }}
        />
      )}
      {bottomAxisType === 'minimal' && (
        <AxisBottom
          tickValues={xScaleDomain}
          tickFormat={timeFormat('%m/%d %Y')}
          top={height - margin.bottom - margin.top}
          scale={xScale}
          stroke={axisColor}
          tickStroke={axisColor}
          tickLabelProps={(_, i) => ({ ...axisBottomTickLabelProps, textAnchor: i === 0 ? 'start' : 'end' })}
          tickComponent={(tickComponentProps) => {
            const formattedValues = tickComponentProps.formattedValue.split(' ');
            delete tickComponentProps.formattedValue;
            return (
              <React.Fragment>
                <Text {...tickComponentProps}>{formattedValues[0]}</Text>
                <Text {...tickComponentProps} dy="1.0em">
                  {formattedValues[1]}
                </Text>
              </React.Fragment>
            );
          }}
        />
      )}
      {!hideLeftAxis && (
        <AxisLeft
          tickValues={yScaleTicks}
          scale={yScale}
          stroke={axisColor}
          tickStroke={axisColor}
          tickLabelProps={() => axisLeftTickLabelProps}
          tickFormat={(d) => {
            if (dataType === 'categorical') {
              return Math.round(d * 100) / 100;
            } else {
              return middleTrunc(orderingLookup[d] ?? '', 20);
            }
          }}
        />
      )}
      {yAxisTitle && (
        <text fill={axisColor} x="0" y="5" transform="rotate(-90)" fontSize={10} dominantBaseline="hanging" textAnchor="end">
          {yAxisTitle}
        </text>
      )}
      {children}
    </Group>
  );
};

AreaChart.propTypes = {
  // positioning
  margin: PropTypes.object.isRequired,
  height: PropTypes.number,
  left: PropTypes.number,
  top: PropTypes.number,
  width: PropTypes.number.isRequired,

  // display
  bottomAxisType: PropTypes.oneOf(['full', 'minimal']),
  colorRamp: PropTypes.string,
  colorRampInverted: PropTypes.bool,
  curve: PropTypes.func,
  hideLeftAxis: PropTypes.bool,
  yAxisTitle: PropTypes.string,
  yScaleZoomed: PropTypes.bool,

  // data
  alertData: PropTypes.array,
  data: PropTypes.array,
  dataType: PropTypes.string,
  id: PropTypes.string,
  ordering: PropTypes.array,
  selectedDateRange: PropTypes.object,
  selectedQuestions: PropTypes.array,
  temporalDomain: PropTypes.array,

  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

AreaChart.defaultProps = {
  hideLeftAxis: false,
  bottomAxisType: 'full',
  top: 0,
  left: 0,
  curve: curveLinear,
  yScaleZoomed: false
};

export default React.memo(AreaChart);
