import PropTypes from 'prop-types';
import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// modules
import numeral from 'numeral';
import { forward } from 'mgrs';

// ui/ux
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

// icons
import AssignmentIcon from '@material-ui/icons/Assignment';
import BuildIcon from '@material-ui/icons/Build';
import BusinessIcon from '@material-ui/icons/Business';
import CloseIcon from '@material-ui/icons/Close';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import GpsIcon from '@material-ui/icons/GpsFixed';
import GridOnIcon from '@material-ui/icons/GridOn';
import LabelIcon from '@material-ui/icons/Label';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import RoomIcon from '@material-ui/icons/Room';
import SignalCellular3BarIcon from '@material-ui/icons/SignalCellular3Bar';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import VisibilityIcon from '@material-ui/icons/Visibility';
import WifiIcon from '@material-ui/icons/Wifi';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';

// ours
import PlacePaper from '../PlacePaper';
import { API_HOST, genericPost } from 'iris-api'; // eslint-disable-line import/no-unresolved
import { toUTCString } from 'iris-util'; // eslint-disable-line import/no-unresolved
import { enlargePhoto as enlargePhotoAction, pushSnackbar as pushSnackbarAction } from '../../actions';
import { Waiting } from '@premisedata/iris-components';
import HelpTooltip from '../HelpTooltip';
import VirtualizedList from '../VirtualizedList';
import { irisApi } from '../../services';
import { Features as FEATURES, has } from '@premisedata/lib-features';

const styles = (theme) => ({
  btnBox: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between'
  },
  pushRight: {
    marginLeft: 'auto'
  },
  paper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  inlineIcon: {
    paddingBottom: 3,
    fontSize: '2.125rem',
    display: 'inline-flex',
    verticalAlign: 'middle',
    marginRight: theme.spacing(0.5)
  },
  nested: {
    paddingLeft: 72
  },
  nearbyPlaces: {
    width: '100%'
  },
  nearbyCellTowers: {
    width: '100%'
  },
  disableOutline: {
    outline: 0
  },
  girdListTile: {
    cursor: 'pointer'
  }
});

class RadioDetailPanel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      cidExpanded: false,
      nearbyPlaces: []
    };

    // XHR
    this._xhrNearbyPlaces = {};
    this._xhrNearbyPlacesLR = null;

    this._xhrNearbyCellTowers = {};
    this._xhrNearbyCellTowersLR = null;

    // BIND
    this.renderWifi = this.renderWifi.bind(this);
    this.renderCell = this.renderCell.bind(this);
    this.onCopyCoords = this.onCopyCoords.bind(this);
  }
  componentDidMount() {
    const { user, getUserdataMetadata } = this.props;
    if (has(user.active_features, FEATURES.DATA_UPLOAD) || has(user.active_features, FEATURES.DATA_UPLOAD_VIEWER)) {
      const { unsubscribe } = getUserdataMetadata();
      this.unsubscribeGetUserdataMetadata = unsubscribe;
    }

    this.componentDidUpdate({}, {});
  }
  componentWillUnmount() {
    this._xhrNearbyPlacesLR = null;
    this._xhrNearbyCellTowersLR = null;
    this._xhrNearbyPlaces.cancel && this._xhrNearbyPlaces.cancel();
    this._xhrNearbyCellTowers.cancel && this._xhrNearbyCellTowers.cancel();
    this.unsubscribeGetUserdataMetadata?.();
    this.props.onCellTowerFocus();
  }
  componentDidUpdate(prevProps) {
    const NOW = new Date().getTime();
    const { selectedDoc } = this.props;
    const { selectedDoc: prevSelectedDoc } = prevProps;
    const docChanged = selectedDoc !== prevSelectedDoc;

    if (selectedDoc && selectedDoc.oui && docChanged) {
      this._xhrNearbyPlacesLR = NOW;
      this._xhrNearbyPlaces.cancel && this._xhrNearbyPlaces.cancel();

      this.setState({
        nearbyPlaces: [],
        errorNearbyPlaces: false,
        loadingNearbyPlaces: true
      });
      genericPost(
        `${API_HOST}/places/v0/places/near/${selectedDoc.location.lon}/${selectedDoc.location.lat}/500`,
        {
          limit: 25,
          categories: [
            'food_shop',
            'church',
            'restaurant',
            'mobile_money_operator',
            'mobile_phone_store',
            'hawala',
            'clinic',
            'mosque',
            'hospital',
            'vocational_school',
            'street_market',
            'school',
            'currency_exchange',
            'bank',
            'internet_cafe',
            'red_cross_or_red_crescent',
            'pharmacy',
            'gathering_place',
            'university'
          ]
        },
        this._xhrNearbyPlaces,
        (e, r) => {
          // Error!
          if (e) {
            return this.setState({
              loadingNearbyPlaces: false,
              errorNearbyPlaces: true,
              nearbyPlaces: []
            });
          }

          // Stale!
          if (NOW !== this._xhrNearbyPlacesLR) return;

          // Success!
          this.setState({
            loadingNearbyPlaces: false,
            errorNearbyPlaces: false,
            nearbyPlaces: r
          });
        }
      );
    }

    // {
    //   "mcc": "610",
    //   "mnc": "2",
    //   "source_id": "610-2-0-1",
    //   "source_type": "stationary",
    //   "n_observations": 8347183,
    //   "span": 1260,
    //   "max_observed_power": -85,
    //   "area_code": "0",
    //   "cell_ids": [],
    //   "rf_prop": [],
    //   "event_time_first_seen": 1420070400000,
    //   "event_time_last_seen": 2143238400000,
    //   "network_type": "WCDMA",
    //   "network_name": "Orange ML",
    //   "n_user": 1459,
    //   "location": {
    //     "lat": 12.616926896812128,
    //     "lon": -7.9913531599723475
    //   },
    //   "_id": "c___AczK_nMB2k6xpHW-OsHe"
    // }
    if (selectedDoc && selectedDoc.mcc && docChanged) {
      this._xhrNearbyCellTowers.cancel && this._xhrNearbyCellTowers.cancel();
      this._xhrNearbyCellTowersLR = NOW;

      this.setState({
        nearbyCellTowers: [],
        nearbyCellTowersError: false,
        nearbyCellTowersLoading: true
      });

      genericPost(
        `${API_HOST}/places/v0/places/near/${selectedDoc.location.lon}/${selectedDoc.location.lat}/500`,
        {
          limit: 25,
          categories: ['cell_tower']
        },
        this._xhrNearbyCellTowers,
        (e, r) => {
          // Error!
          if (e) {
            return this.setState({
              nearbyCellTowers: [],
              nearbyCellTowersError: true,
              nearbyCellTowersLoading: false
            });
          }

          // Stale!
          if (NOW !== this._xhrNearbyCellTowersLR) return;

          this.setState({
            nearbyCellTowers: r || [],
            nearbyCellTowersError: false,
            nearbyCellTowersLoading: false
          });
        }
      );
    }
  }
  closeButton() {
    const { classes, onSelect } = this.props;
    return (
      <Box>
        <IconButton
          size="small"
          className={classes.pushRight}
          onClick={() => {
            onSelect();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    );
  }
  sourceType() {
    const { selectedDoc: d } = this.props;
    let source_type = 'Unknown';
    if (d.source_type === 'stationary') {
      source_type = 'Stationary';
    }
    if (d.source_type === 'non-stationary') {
      source_type = 'Mobile hotspot';
    }
    return source_type;
  }
  onCopyCoords() {
    const { pushSnackbar, selectedDoc: s } = this.props;
    navigator.clipboard
      .writeText([s.location.lat, s.location.lon].join(', '))
      .then(() => {
        pushSnackbar({ type: 'success', message: 'Location copied!' });
      })
      .catch(() => {
        pushSnackbar({ type: 'error', message: 'Failed to access clipboard' });
      });
  }
  renderWifi() {
    // {
    //   "oui": "000B82",
    //   "source_id": "00:0b:82:fc:89:95",
    //   "source_type": "stationary",
    //   "n_observations": 372,
    //   "span": 0,
    //   "max_observed_power": 3.981071705534969E-8,
    //   "event_time_first_seen": 1579046400000,
    //   "event_time_last_seen": 1594512000000,
    //   "ssids": "STCLARECOLLEGEINTERNET",
    //   "channel_width": 0,
    //   "capabilities": "[ESS]",
    //   "n_user": 9,
    //   "manufacturer": "Grandstream Networks, Inc.",
    //   "manufacturer_country": "US",
    //   "location": {
    //     "lat": 14.755349341518379,
    //     "lon": 121.04372497446077
    //   },
    //   "_id": "XTZDcXMBQ_M8IzcLOGbW"
    // }
    const { nearbyPlaces } = this.state;
    const { classes, onZoomTo, onPanTo, selectedDoc: d, userdataMetadataQuery } = this.props;

    let fs = 'Unknown',
      ls = 'Unknown';
    if (d.event_time_first_seen) {
      if (d.event_time_first_seen > 18000000) {
        fs = toUTCString(d.event_time_first_seen);
      }
    }

    if (d.event_time_last_seen) {
      if (d.event_time_last_seen > 18000000) {
        ls = toUTCString(d.event_time_last_seen);
      }
    }

    const sourceType = this.sourceType();
    // Manufacturer: Hewlett Packard
    // Capabilities: [WPA2-PSK-CCMP][WPS][ESS]
    // Channel Width: 1
    // Oui: 000456

    return (
      <React.Fragment>
        <Paper className={classes.paper}>
          <Box className={classes.btnBox}>
            <Typography variant="h4" noWrap={true}>
              <WifiIcon className={classes.inlineIcon} />
              {`${d.ssids}`}
            </Typography>
            {this.closeButton()}
          </Box>
          <List dense={true}>
            <ListItem>
              <ListItemIcon>
                <LabelIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <HelpTooltip label="BSSID" definition={'A 48-bit label for a wireless access point usually derived from its MAC address, or hardware identification number.'} />
                }
                secondary={d.source_id}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <TransferWithinAStationIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <HelpTooltip
                    label="Source Type"
                    definition={`Derived from a process that attempts to determine the actual location a of a wireless access point using all passive data registered with the BSSID${
                      sourceType === 'Unknown' ? '. “Unknown” indicates the process was unable to localize.' : ''
                    }`}
                  />
                }
                secondary={sourceType}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary="Manufacturer and Origin Country" secondary={`${d.manufacturer} (${d.manufacturer_country})`} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <BuildIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <HelpTooltip
                    label="Capabilities"
                    definition="Refers to security protocol and encryption type (e.g. WPA, WPA2, AES, TKIP), network type (e.g. ESS, BSS), and/or wireless technology type (e.g. BLE)"
                  />
                }
                secondary={d.capabilities.join(', ')}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FingerprintIcon />
              </ListItemIcon>
              <ListItemText primary={<HelpTooltip label="OUI" definition="A 24-bit globally unique number assigned by the IEEE to an organization/company" />} secondary={d.oui} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <RoomIcon />
              </ListItemIcon>
              <ListItemText
                title={[d.location.lat, d.location.lon].join(', ')}
                primary={
                  d.source_type === 'non-stationary' ? (
                    <HelpTooltip label="Location" definition="For mobile hotspots source types, the location is the median of all points collected." />
                  ) : (
                    'Location'
                  )
                }
                secondary={[numeral(d.location.lat).format('0.0000'), numeral(d.location.lon).format('0.0000')].join(', ')}
              />
              <ListItemSecondaryAction>
                <Tooltip title="Zoom to location, including RF Propagation if applicable">
                  <IconButton
                    edge="end"
                    onClick={() => {
                      onZoomTo && onZoomTo(d);
                    }}
                  >
                    <ZoomInIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Pan to Location">
                  <IconButton
                    edge="end"
                    onClick={() => {
                      onPanTo && onPanTo(d);
                    }}
                  >
                    <ZoomOutMapIcon />
                  </IconButton>
                </Tooltip>
                <IconButton edge="end" onClick={this.onCopyCoords}>
                  <AssignmentIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ViewColumnIcon />
              </ListItemIcon>
              <ListItemText
                title={forward([+d.location.lat, +d.location.lon])}
                primary={
                  d.source_type === 'non-stationary' ? (
                    <HelpTooltip label="Location" definition="For mobile hotspots source types, the location is the median of all points collected." />
                  ) : (
                    'MGRS Coordinate'
                  )
                }
                secondary={forward([+d.location.lat, +d.location.lon])}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <SignalCellular3BarIcon />
              </ListItemIcon>
              <ListItemText
                primary={<HelpTooltip label="Max Observed Power (dBm)" definition="The greater the value, the stronger the signal" />}
                secondary={numeral(Math.log10(d.max_observed_power) * 10).format('0,0')}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <GpsIcon />
              </ListItemIcon>
              <ListItemText primary="Positional Uncertainty (meters)" secondary={numeral(d.accuracy).format('0,0')} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PhoneIphoneIcon />
              </ListItemIcon>
              <ListItemText primary="Reporting Devices" secondary={numeral(d.n_user).format('0,0')} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <VisibilityIcon />
              </ListItemIcon>
              <ListItemText primary="Observations" secondary={numeral(d.n_observations).format('0,0')} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <DateRangeIcon />
              </ListItemIcon>
              <ListItemText primary="First Seen ↔ Last Seen" secondary={fs !== ls ? `${fs} ↔ ${ls}` : fs} />
            </ListItem>
          </List>
        </Paper>
        {nearbyPlaces && nearbyPlaces.length > 0 ? (
          <Paper className={classes.paper}>
            <Typography gutterBottom={true} variant="h6">
              Nearby Places
            </Typography>
            <Box className={classes.nearbyPlaces} style={{ height: Math.min(500, nearbyPlaces.length * 130) }}>
              <VirtualizedList
                className={classes.disableOutline}
                rowCount={nearbyPlaces.length}
                rowHeight={128 + 8 /* this provides bottom margin */}
                rowRenderer={({ key, index, style }) => (
                  <PlacePaper
                    invertColors={true}
                    key={key}
                    style={style}
                    place={nearbyPlaces[index]}
                    userdataLayerMetadata={userdataMetadataQuery.data?.layersById?.[nearbyPlaces[index]?.type]}
                    // onClick={onClickFeature}
                    // key={places[index].place_id}
                    // active={!!selectedPlace && places[index].place_id === selectedPlace.place_id}
                    // onMouseIn={this.onHoverFeature}
                    // onMouseOut={this.onHoverFeature}
                  />
                )}
              />
            </Box>
          </Paper>
        ) : null}
      </React.Fragment>
    );
  }
  renderCell() {
    // {
    //   "mcc": "515",
    //   "mnc": "3",
    //   "source_id": "515-3-55023-18353519",
    //   "source_type": "stationary",
    //   "n_observations": 388923,
    //   "span": 20,
    //   "max_observed_power": -53,
    //   "area_code": "55023",
    //   "cell_ids": [
    //     "183535191",
    //     "183535193",
    //     "183535192"
    //   ],
    //   "event_time_first_seen": 1262304000000,
    //   "event_time_last_seen": 1594857600000,
    //   "network_type": "WCDMA",
    //   "network_name": "GLOBE",
    //   "n_user": 158,
    //   "location": {
    //     "lat": 7.059977709540557,
    //     "lon": 125.60896852872978
    //   },
    //   "_id": "IjlFcXMBQ_M8IzcL8kxW"
    // }
    const { cidExpanded, nearbyCellTowers } = this.state;
    const { classes, onZoomTo, onPanTo, selectedDoc: d } = this.props;
    let fs = 'Unknown',
      ls = 'Unknown';
    if (d.event_time_first_seen) {
      if (d.event_time_first_seen > 18000000) {
        fs = toUTCString(d.event_time_first_seen);
      }
    }

    if (d.event_time_last_seen) {
      if (d.event_time_last_seen > 18000000) {
        ls = toUTCString(d.event_time_last_seen);
      }
    }
    const cidListItemPadding = {};
    const cidListItemBtnPadding = {};
    if (cidExpanded) {
      cidListItemPadding.paddingTop = 0;
      cidListItemBtnPadding.paddingBottom = 0;
    }
    return (
      <React.Fragment>
        <Paper className={classes.paper}>
          <Box className={classes.btnBox}>
            <Typography variant="h4">
              <SignalCellularAltIcon className={classes.inlineIcon} />
              {`${d.network_name} - ${d.network_type}`}
            </Typography>
            {this.closeButton()}
          </Box>
          <List dense={true}>
            <ListItem>
              <ListItemIcon>
                <LabelIcon />
              </ListItemIcon>
              <ListItemText primary="Tower Id" secondary={d.source_id} />
            </ListItem>
            <ListItem
              button={true}
              style={cidListItemBtnPadding}
              onClick={() => {
                this.setState({
                  cidExpanded: !cidExpanded
                });
              }}
            >
              <ListItemIcon>
                <GridOnIcon />
              </ListItemIcon>
              <ListItemText
                primary={<HelpTooltip label="Cell Ids" definition="A cell id is a generally unique number that identifies an antenna, its location, and the direction it faces" />}
              />
              {cidExpanded ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={cidExpanded} timeout="auto" unmountOnExit={false}>
              <List component="div" disablePadding={true} dense={true}>
                <ListItem className={classes.nested} style={cidListItemPadding}>
                  <Typography variant="body2" color="textSecondary">
                    {d.cell_ids.join(', ')}
                  </Typography>
                </ListItem>
              </List>
            </Collapse>
            <ListItem>
              <ListItemIcon>
                <RoomIcon />
              </ListItemIcon>
              <ListItemText
                title={[d.location.lat, d.location.lon].join(', ')}
                primary="Location"
                secondary={[numeral(d.location.lat).format('0.0000'), numeral(d.location.lon).format('0.0000')].join(', ')}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  onClick={() => {
                    onZoomTo && onZoomTo(d);
                  }}
                >
                  <ZoomInIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  onClick={() => {
                    onPanTo && onPanTo(d);
                  }}
                >
                  <ZoomOutMapIcon />
                </IconButton>
                <IconButton
                  className="btn-clip-src"
                  edge="end"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <AssignmentIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <SignalCellular3BarIcon />
              </ListItemIcon>
              <ListItemText
                primary={<HelpTooltip label="Max Observed Power (dBm)" definition="The greater the value, the stronger the signal" />}
                secondary={d.max_observed_power}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PhoneIphoneIcon />
              </ListItemIcon>
              <ListItemText primary="Reporting Devices" secondary={numeral(d.n_user).format('0,0')} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <VisibilityIcon />
              </ListItemIcon>
              <ListItemText primary="Observations" secondary={numeral(d.n_observations).format('0,0')} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <DateRangeIcon />
              </ListItemIcon>
              <ListItemText primary="First Seen ↔ Last Seen" secondary={fs !== ls ? `${fs} ↔ ${ls}` : fs} />
            </ListItem>
          </List>
        </Paper>
        {nearbyCellTowers && nearbyCellTowers.length > 0 ? (
          <Paper className={classes.paper}>
            <Typography gutterBottom={true} variant="h6">
              Nearby Cell Towers
            </Typography>

            <ImageList rowHeight={160} className={classes.nearbyCellTowers} cols={3} style={{ height: Math.min(500, Math.ceil(nearbyCellTowers.length / 3) * 164) }}>
              {nearbyCellTowers.map((d) => (
                <ImageListItem
                  className={classes.girdListTile}
                  key={d.place_id}
                  // onMouseOut={() => {
                  //   this.props.onCellTowerFocus();
                  // }}
                  // onMouseOver={() => {
                  //   this.props.onCellTowerFocus(d);
                  // }}
                  onClick={() => {
                    this.props.enlargePhoto(d.photo);
                  }}
                >
                  <img alt={`nearby cell tower: ${d.place_name}`} src={d.photo} />
                </ImageListItem>
              ))}
            </ImageList>
          </Paper>
        ) : null}
      </React.Fragment>
    );
  }
  render() {
    const { loading } = this.state;
    const { selectedDoc } = this.props;
    if (loading) {
      return <Waiting height="100%" width="100%" />;
    }
    if (!selectedDoc) {
      return null;
    }

    const Renderer = selectedDoc.oui ? this.renderWifi : this.renderCell;
    return <Renderer />;
  }
}

const mapStateToProps = (state) => ({
  user: state.app.user,
  // Custom user data support:
  userdataMetadataQuery: irisApi.endpoints.getUserdataMetadata.select()(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserdataMetadata: irisApi.endpoints.getUserdataMetadata.initiate,
      pushSnackbar: pushSnackbarAction,
      enlargePhoto: enlargePhotoAction
    },
    dispatch
  );

RadioDetailPanel.propTypes = {
  // ui/ux
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,

  pushSnackbar: PropTypes.func.isRequired,
  enlargePhoto: PropTypes.func.isRequired,

  // data
  selectedDoc: PropTypes.object,

  // callbacks
  onSelect: PropTypes.func.isRequired,
  onZoomTo: PropTypes.func,
  onCellTowerFocus: PropTypes.func,
  onPanTo: PropTypes.func,
  // Custom user data support:
  userdataMetadataQuery: PropTypes.object,
  getUserdataMetadata: PropTypes.func.isRequired
};

RadioDetailPanel.defaultProps = {};

const withRedux = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: false });
export default withRedux(withTheme(withStyles(styles)(RadioDetailPanel)));
