import { withStyles, withTheme } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import clsx from 'clsx';
import amplitude from 'amplitude-js';

// ui/ux
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

// icons
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// ours
import { GeoAutocomplete } from '@premisedata/iris-components';
import { pushSnackbar as pushSnackbarAction } from '../../../actions';

import { API_HOST, genericGet, genericPost } from 'iris-api'; // eslint-disable-line import/no-unresolved
import { placesSelectors } from '../placesSlice';

const styles = (theme) => ({
  body: {
    position: 'absolute',
    overflowY: 'auto',
    outline: 0,
    padding: theme.spacing(2),

    maxWidth: 500,
    width: '50%',
    left: '50%',

    maxHeight: '70%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  formControl: {
    margin: theme.spacing(3)
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  indent: {
    paddingLeft: theme.spacing(4)
  },
  btnGroup: {
    marginTop: theme.spacing(3),
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(1)
    }
  },
  chips: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
      maxWidth: '100%'
    }
  },
  accordionHeader: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  removeBefore: {
    '&:before': {
      backgroundColor: theme.palette.background.paper
    }
  }
});

class ExportModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      includeFilters: true,
      photoLimit: 5,
      includeSubmissions: false,
      aoi: 'cv', // "current view"
      format: 'csv',
      selectedHascs: [],
      downloadPhotos: false
    };

    this.onOptionChanged = this.onOptionChanged.bind(this);
    this.onAoiChange = this.onAoiChange.bind(this);
    this.onFormatChanged = this.onFormatChanged.bind(this);
    this.onExport = this.onExport.bind(this);

    this._xhrGeoSearch = {};
    this._xhrExportSubmit = {};
  }
  componentWillUnmount() {
    this._xhrGeoSearch.cancel && this._xhrGeoSearch.cancel();
    this._xhrExportSubmit.cancel && this._xhrExportSubmit.cancel();
  }
  onExport() {
    const { includeFilters, includeSubmissions, format, aoi, photoLimit, selectedHascs, downloadPhotos } = this.state;
    const { dataQueryObject, onComplete } = this.props;

    // start w/ a copy of the filter object if applicable:
    const requestBody = includeFilters ? { ...dataQueryObject.body } : {};

    // state from checkbox:
    requestBody.includePhotos = downloadPhotos;
    // state from dropdown:
    requestBody.placePhotoLimit = photoLimit;

    switch (aoi) {
      case 'boundaries':
        requestBody.hascs = selectedHascs.flatMap((d) => d.hascs);
        break;
      default:
        // (current view)
        requestBody.wkt =
          'POLYGON((' +
          [
            // \n
            dataQueryObject.body.polygon.nw,
            dataQueryObject.body.polygon.sw,
            dataQueryObject.body.polygon.se,
            dataQueryObject.body.polygon.ne,
            dataQueryObject.body.polygon.nw
          ]
            .map((d) => `${d[0]} ${d[1]}`)
            .join(',') +
          '))';
        break;
    }

    const requestPath = 'places' + (includeSubmissions ? ',submissions' : '');
    this._xhrExportSubmit.cancel && this._xhrExportSubmit.cancel();

    genericPost(`${API_HOST}/exports/v0/jobs/${requestPath}.${format}`, requestBody, this._xhrExportSubmit, (e) => {
      if (e) {
        amplitude.getInstance().logEvent('export_failed', {
          product: 'places',
          filters: requestBody.filters,
          includePhotos: requestBody.includePhotos,
          placePhotoLimit: requestBody.placePhotoLimit,
          polygon: requestBody.polygon
        });

        return this.props.pushSnackbar({ type: 'error', message: 'Failed to submit export, please try again later.' });
      }

      amplitude.getInstance().logEvent('export_succeeded', {
        product: 'places',
        filters: requestBody.filters,
        includePhotos: requestBody.includePhotos,
        placePhotoLimit: requestBody.placePhotoLimit,
        polygon: requestBody.polygon
      });

      onComplete();
    });
  }
  onOptionChanged(e) {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked
    });
  }
  onAoiChange(e) {
    const aoi = e.target.value;
    this.setState({ aoi });
  }
  formatHelperText() {
    let formattedText = '';
    const { format } = this.state;
    const F = FormHelperText;
    switch (format) {
      case 'json':
        formattedText += 'Line delimited JSON, useful for map reduce workflows.';
        break;
      case 'geojson':
        formattedText += 'Line delimited GeoJSON, useful for map reduce workflows.';
        break;
      case 'gdb':
        formattedText += 'ESRI file geodatabase, for use with ESRI software suites.';
        break;
    }

    if (['kml', 'kmz', 'gdb'].includes(this.state.format)) {
      formattedText += 'Due to data format issues we are unable to incorporate submission data in the following formats: kml, kmz, and gdb';
    }

    return <F>{formattedText}</F>;
  }
  onFormatChanged(e) {
    this.setState({
      format: e.target.value
    });
  }
  render() {
    const { theme, classes, onCancel, user } = this.props;
    const { photoLimit, includeFilters, includeSubmissions, aoi, format, selectedHascs, downloadPhotos } = this.state;

    return (
      <Modal
        open={true}
        onClose={(_, reason) => {
          if (reason === 'backdropClick') return;
          onCancel();
        }}
      >
        <Paper className={classes.body}>
          <Typography variant="h4">Export Data</Typography>
          <Typography variant="body2">
            Once exports are submitted job status and job download links will be available in the Iris inbox.
            {user && user.scope_id === -1 ? (
              <React.Fragment>
                &nbsp;You are currently in the <i>global</i> scope, this export's scope will be coerced to the <i>premise</i> scope.
              </React.Fragment>
            ) : (
              ''
            )}
          </Typography>

          <FormControl component="fieldset" className={clsx(classes.formControl, 'noselect')} style={{ width: `calc(100% - ${theme.spacing(6)}px` }}>
            <FormLabel component="legend">Area of Interest</FormLabel>
            <RadioGroup value={aoi} onChange={this.onAoiChange}>
              <FormControlLabel value="cv" control={<Radio />} label="Current View" />
              <FormControlLabel value="boundaries" control={<Radio />} label="Boundaries" />
              {aoi === 'boundaries' ? (
                <Grid container={true} direction="row" justifyContent="center" alignItems="center" className={classes.indent} spacing={2}>
                  <Grid item={true} xs={12}>
                    <GeoAutocomplete
                      dataFnMinLength={0}
                      withDynamicSearch={false}
                      withLatLonSearch={false}
                      popperPlacement={'right'}
                      label={'Search'}
                      placeholder={'"Switzerland" or "CH"'}
                      onSelect={(r) => {
                        const { selectedHascs: sh } = this.state;
                        if (!sh.map((d) => d.name).includes(r.name)) {
                          this.setState({
                            selectedHascs: [...sh, r]
                          });
                        }
                      }}
                      dataFn={(x, cb) => {
                        this._xhrGeoSearch.cancel && this._xhrGeoSearch.cancel();
                        genericGet(`${API_HOST}/places/v0/metadata/hascs/${x}`, this._xhrGeoSearch, (e, r) => {
                          if (e) return cb(e);
                          cb(
                            null,
                            r.map((d) => ({
                              ...d,
                              type: d.from_hasc ? 'Premise Hasc' : `Premise GADM L${d.level}`,
                              name: `${d.name} (${d.hascs.join(',')})`
                            }))
                          );
                        });
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={12} className={classes.chips}>
                    {selectedHascs.map((d) => (
                      <Chip
                        key={d.name}
                        label={d.name}
                        onDelete={() => {
                          const { selectedHascs: sh } = this.state;
                          this.setState({
                            selectedHascs: sh.filter((d1) => d1.name !== d.name)
                          });
                        }}
                      />
                    ))}
                  </Grid>
                </Grid>
              ) : null}
            </RadioGroup>
          </FormControl>

          <FormControl component="fieldset" className={classes.formControl} style={{ width: `calc(100% - ${theme.spacing(6)}px`, marginTop: 0 }}>
            <InputLabel id="label-data-format">File Format</InputLabel>
            <Select labelId="label-data-format" id="data-format" value={format} onChange={this.onFormatChanged}>
              <MenuItem value="geojson">NDGEOJSON</MenuItem>
              <MenuItem value="json">NDJSON</MenuItem>
              <MenuItem value="csv">CSV</MenuItem>
              <MenuItem value="kml">KML</MenuItem>
              <MenuItem value="kmz">KMZ</MenuItem>
              <MenuItem value="gpkg">QGIS GEOPACKAGE</MenuItem>
              <MenuItem value="gdb">ESRI FGDB</MenuItem>
            </Select>
            {this.formatHelperText()}
          </FormControl>

          <Accordion elevation={0} className={classes.removeBefore}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography className={classes.accordionHeader}>Advanced Options</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ paddingBottom: 0 }}>
              <FormControl component="fieldset" className={clsx(classes.formControl, 'noselect')} style={{ marginTop: 0 }}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox checked={includeFilters} onChange={this.onOptionChanged} name="includeFilters" />} label="Include Filters" />
                  <FormHelperText>Limit data to filters currently active within the user interface.</FormHelperText>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={includeSubmissions}
                        onChange={(e) => {
                          this.onOptionChanged(e);
                        }}
                      />
                    }
                    name="includeSubmissions"
                    label="Include Submissions"
                  />
                  <FormHelperText>Include individual contributions in addition to conflated places.</FormHelperText>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={downloadPhotos}
                        onChange={(e) => {
                          this.onOptionChanged(e);
                        }}
                      />
                    }
                    name="downloadPhotos"
                    label="Download Photos"
                  />
                  <FormHelperText>Download photos instead of just providing URL.</FormHelperText>

                  <FormControl className={classes.marginTop}>
                    <InputLabel id="il-photo-limit">Place Photo Limit</InputLabel>
                    <Select
                      labelId="il-photo-limit"
                      value={photoLimit}
                      onChange={(e) => {
                        this.setState({ photoLimit: e.target.value });
                      }}
                    >
                      <MenuItem value={1}>1 Photo</MenuItem>
                      <MenuItem value={5}>5 Photos</MenuItem>
                      <MenuItem value={10}>10 Photos</MenuItem>
                      <MenuItem value={20}>20 Photos</MenuItem>
                      <MenuItem value={1000}>1000 Photos</MenuItem>
                    </Select>
                    <FormHelperText>Limit the maximum number of photos to include per place.</FormHelperText>
                  </FormControl>
                </FormGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>

          <Box className={clsx(classes.box, classes.btnGroup)}>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
            <Button disabled={aoi !== 'cv' && selectedHascs.length === 0} color="secondary" variant="outlined" startIcon={<CloudDownloadIcon />} onClick={this.onExport}>
              Export
            </Button>
          </Box>
        </Paper>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  dataQueryObject: placesSelectors.dataQueryObject(state),
  user: state.app.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushSnackbar: pushSnackbarAction
    },
    dispatch
  );

ExportModal.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  user: PropTypes.object,
  dataQueryObject: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  pushSnackbar: PropTypes.func.isRequired
};

ExportModal.defaultProps = {};

const withRedux = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: false });
export default withRedux(withTheme(withStyles(styles)(ExportModal)));
