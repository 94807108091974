import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { withStyles, withTheme, alpha } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

// MODULE
import debounce from 'lodash.debounce';
import { capitalCase } from 'change-case';

// UI/UX
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';

// icons
import OSMIcon from '@material-ui/icons/AccountBalance';
import ListIcon from '@material-ui/icons/ViewList';
import PhotoIcon from '@material-ui/icons/Apps';
import { irisApi } from '../../services';

// ours
import ResultSettings from '../../components/ResultSettings.js';
import PlacePaper from '../PlacePaper';
import { API_HOST, genericPost } from 'iris-api'; // eslint-disable-line import/no-unresolved
import { Waiting } from '@premisedata/iris-components';
import { pushSnackbar as pushSnackbarAction, setGlobal as setGlobalAction } from '../../actions';
import {
  // \n
  placesSelectors,
  setResultPanelListMode as setResultPanelListModeAction
} from './placesSlice';
import VirtualizedList from '../VirtualizedList';
import FilterChips from './components/FilterChips';

// GLOBALS
const ROWS_PER_FETCH = 50;
const ROW_HEIGHT = 128 + 8;
const PHOTOS_PER_ROW = 2;
const PHOTO_WIDTH = Math.round((100 / PHOTOS_PER_ROW) * 100) / 100.0;

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    height: 26,
    width: 32,
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius
    },
    '& span': {
      fontWeight: 600,
      lineHeight: '1em'
    }
  }
}))(ToggleButtonGroup);

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    height: '100%',
    width: '100%'
  },
  divider: {
    margin: theme.spacing(1, 0.5)
  },
  paper: {
    display: 'flex',
    border: `1px solid ${theme.palette.divider}`,
    flexWrap: 'wrap'
  },
  scroll: {
    position: 'relative',
    height: '100%',
    width: '100%',
    margin: '0 auto'
  },
  normal: {
    border: `1px solid ${alpha(theme.palette.secondary.dark, 0.0)}`
  },
  row: {
    padding: theme.spacing(1),
    cursor: 'pointer',
    height: '128px !important',

    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.dark, 0.1),
      border: `1px solid ${alpha(theme.palette.secondary.dark, 1.0)}`
    }
  },
  // for Results in Photo Grid
  imgBox: {
    position: 'absolute',
    cursor: 'pointer',
    height: ROW_HEIGHT - theme.spacing(1),
    width: `calc(${PHOTO_WIDTH}% - ${theme.spacing(1)}px)`,
    margin: `0px ${theme.spacing(0.5)}px`,
    borderRadius: 4,
    '&:hover': {
      opacity: 0.8,
      '& > div': {
        visibility: 'inherit'
      }
    }
  },
  // for OSM POI elements (photo grid)
  selectedPlaceImgBox: {
    border: `2px solid ${theme.palette.secondary.main} !important`,
    backgroundColor: alpha(theme.palette.secondary.main, 0.4)
  },
  osmPoiImgBox: {
    border: `2px solid ${theme.palette.primary.light}`,
    padding: theme.spacing(1)
  },
  osmPoiImgBoxLabel: {
    display: 'flex',
    marginBottom: theme.spacing(1)
  },
  img: {
    borderRadius: 4,
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },
  imgInlay: {
    position: 'absolute',
    top: 2,
    left: 2,
    visibility: 'hidden',
    padding: theme.spacing(0.5),
    backgroundColor: 'black',
    borderRadius: 4,
    opacity: 0.8,
    color: 'white'
  }
});
const DATA_SENSITIVE_PROPS = ['sort', 'sortDirection', 'resultPanelListMode', 'dataKey'];
class ResultPanel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      recountLoading: false,
      accurateTotal: false,

      // support filters
      availableCategories: null,
      topicCountLookup: {}
    };
    this.actualTotal = 0;
    this.total = 0;
    this.data = [];

    // BIND
    this.onManualRecount = this.onManualRecount.bind(this);
    this.onListModeChanged = this.onListModeChanged.bind(this);
    this.loadMoreRows = this.loadMoreRows.bind(this);
    this.rowRenderer = this.rowRenderer.bind(this);
    this.isRowLoaded = this.isRowLoaded.bind(this);
    this.onSelect = this.onSelect.bind(this);

    // REF
    this._autoSizerInfinityLoaderRef = React.createRef();

    // THROTTLE & DEBOUNCE
    this.loadMoreRowsDebounced = debounce(this.loadMoreRowsDebounced, 666, { leading: false, trailing: true });

    // XHR
    this._xhrRecount = {};
  }
  componentWillUnmount() {
    // XHR
    this._xhrRecount.cancel && this._xhrRecount.cancel();

    // DEBOUNCE
    this.loadMoreRowsDebounced.cancel();
  }
  loadMoreRowsDebounced(startIndex, stopIndex, callback) {
    const { resultPanelListMode, dataQueryObject } = this.props;

    const start = resultPanelListMode === 'photo' ? startIndex * 2 : startIndex;
    const stop = resultPanelListMode === 'photo' ? stopIndex * 2 + 1 : stopIndex + 1;

    const body = { ...dataQueryObject.body, start, stop };
    genericPost(`${API_HOST}/places/v0/places/papers/within/${dataQueryObject.mapZoom}`, body, null, callback);
  }
  loadMoreRows({ startIndex, stopIndex }, reset) {
    // LOAD
    return new Promise((resolve, reject) => {
      this.loadMoreRowsDebounced(startIndex, stopIndex, (e, r) => {
        if (e) return reject(e);
        if (this.loadRows(r, startIndex, stopIndex, reset || this.data.length === 0)) {
          resolve();
        }
      });
    }).catch((e) => {
      console.error(e);
      throw e;
    });
  }
  loadRows({ rows, actualTotal, userdataVersion }, startIndex, stopIndex, reset = false) {
    const { resultPanelListMode, userdataMetadataQuery } = this.props;

    if (userdataVersion && userdataMetadataQuery.data && userdataMetadataQuery.data.version !== userdataVersion) {
      this.props.invalidateTags(['UserdataMetadata']);
      console.warn('user uploads metadata out of sync with data, updating...');
      return false;
    }

    if (resultPanelListMode === 'list') {
      if (reset) {
        this.actualTotal = actualTotal;
        this.total = Math.min(actualTotal, 1e4);
        this.data = [];
      } else {
        this.data = [...this.data, ...Array(Math.max(stopIndex - 1 - this.data.length, 0))];
      }
      for (let i = 0, j = startIndex; i < rows.length; i++, j++) {
        this.data[j] = rows[i];
      }
    } else {
      if (reset) {
        this.actualTotal = actualTotal;
        this.total = Math.ceil(Math.min(Number(actualTotal), 1e4) / PHOTOS_PER_ROW);
        this.data = [];
      } else {
        // TODO: I think this is wrong:
        this.data = [...this.data, ...Array(Math.max(stopIndex - 1 - this.data.length, 0))];
      }
      const si = startIndex * PHOTOS_PER_ROW;
      for (let i = 0; i < rows.length; i++) {
        const j = Math.floor((si + i) / PHOTOS_PER_ROW);
        if (!this.data[j]) this.data[j] = [];
        this.data[j].push(rows[i]);
      }
    }

    return true;
  }

  componentDidMount() {
    this.componentDidUpdate({}, {});
  }
  componentDidUpdate(prevProps) {
    const { selectedPlace, dataQueryObject } = this.props;
    if (!dataQueryObject) return;

    let refreshData = false;
    for (const k of DATA_SENSITIVE_PROPS) {
      if (prevProps[k] !== this.props[k]) {
        refreshData = true;
      }
    }
    if (refreshData) {
      this.setState({ loading: true });
      this.loadMoreRows({ startIndex: 0, stopIndex: ROWS_PER_FETCH }, true)
        .then(() => {
          this.setState({ loading: false, loadedMapPolygon: dataQueryObject.body.polygon, accurateTotal: true });
        })
        .catch(() => {
          this.setState({ loading: false, loadedMapPolygon: dataQueryObject.body.polygon, accurateTotal: true });
        });
    } else if (selectedPlace !== prevProps.selectedPlace && this._autoSizerInfinityLoaderRef.current) {
      // If the user selects a place paper, we have to manually update the grid
      // to re-render the paper as "hover === true"
      this._autoSizerInfinityLoaderRef.current.forceUpdateGrid();
    }

    // if the map moved a little, but we didn't update data: mark the count as
    // inaccurate. ( the map can ignore small changes in movement )
    if (this.state.accurateTotal && !refreshData && dataQueryObject.body.polygon !== this.state.loadedMapPolygon) {
      this.setState({
        accurateTotal: false
      });
    }
  }
  onManualRecount() {
    const { dataQueryObject, pushSnackbar } = this.props;
    this._xhrRecount.cancel && this._xhrRecount.cancel();
    this.setState({ recountLoading: true });

    genericPost(`${API_HOST}/places/v0/places/papers/count/${dataQueryObject.mapZoom}`, dataQueryObject.body, this._xhrRecount, (e, r) => {
      if (e) {
        pushSnackbar({ type: 'error', message: 'Failed to recount data within map bounds...' });
        return this.setState({ recountLoading: false });
      }

      this.actualTotal = r.actualTotal;
      this.setState({ accurateTotal: true, loadedMapPolygon: dataQueryObject.body.polygon, recountLoading: false });
    });
  }
  onListModeChanged(_, v) {
    const { resultPanelListMode, setResultPanelListMode } = this.props;
    if (resultPanelListMode === v) return;

    // if we switch modes; empty the data cache:
    this.data = [];
    setResultPanelListMode(v);
  }
  onSelect(place, withShiftKey = false) {
    if (['osm', 'internal'].includes(place.type)) {
      this.props.onSelect(place.place_id, withShiftKey && place.type === 'internal');
    } else {
      this.props.onSelectUserData(place.place_id);
    }
  }
  rowRenderer({ key, index, style }) {
    const { classes, onHover, selectedPlace, resultPanelListMode, userdataMetadataQuery } = this.props;
    const d = this.data[index];

    if (!d) {
      return (
        <Paper key={key} style={{ ...style, /* give some padding */ height: ROW_HEIGHT - 8 }} className={clsx(classes.row, classes.normal)}>
          <Waiting />
        </Paper>
      );
    }
    const active = selectedPlace && d.place_id === selectedPlace.place_id;
    if (resultPanelListMode === 'list') {
      return (
        <PlacePaper
          key={key}
          style={style}
          onClick={this.onSelect}
          place={d}
          active={active}
          onMouseIn={() => onHover(d.place_id)}
          onMouseOut={() => onHover()}
          userdataLayerMetadata={userdataMetadataQuery.data?.layersById?.[d.type]}
        />
      );
    } else {
      return (
        <div key={key} style={style}>
          {d.map((d1, i1) => (
            <Box
              className={clsx(classes.imgBox, !d1.photo && classes.osmPoiImgBox, selectedPlace && d1.place_id === selectedPlace.place_id && classes.selectedPlaceImgBox)}
              key={d1.place_id}
              onClick={({ shiftKey }) => {
                this.onSelect(d1, shiftKey);
              }}
              onMouseOver={() => onHover(d1.place_id)}
              onMouseOut={() => onHover()}
              style={{ left: `${PHOTO_WIDTH * i1}%` }}
            >
              {d1.type === 'osm' && (
                <div className={classes.osmPoiImgBoxLabel}>
                  <OSMIcon fontSize="small" />
                  <Typography variant="subtitle2" style={{ marginLeft: 4 }}>
                    OSM POI
                  </Typography>
                </div>
              )}
              {d1.photo ? (
                <img alt={d1.place_name} src={d1.photo} className={classes.img} />
              ) : (
                <Typography variant="caption" component="div">
                  {d1.place_name}
                </Typography>
              )}
              {d1.topic && (
                <Box className={classes.imgInlay}>
                  <Typography variant="caption">{capitalCase(d1.topic)}</Typography>
                </Box>
              )}
            </Box>
          ))}
        </div>
      );
    }
  }
  isRowLoaded({ index }) {
    return !!this.data[index];
  }
  render() {
    const { loading, accurateTotal, recountLoading } = this.state;
    const { classes, resultPanelListMode, dataQueryObject } = this.props;
    let numActiveFilters = 0;
    if (dataQueryObject) {
      numActiveFilters += Object.keys(dataQueryObject.body.filters).length;
      if (dataQueryObject.body.enable_osm) {
        numActiveFilters++;
      }
    }
    return (
      <Box className={classes.container}>
        <ResultSettings
          label="places"
          resultCountAccurate={accurateTotal}
          resultCount={this.actualTotal}
          numActiveFilters={numActiveFilters}
          visibleChildren={
            <>
              <Paper elevation={0} className={classes.paper}>
                <StyledToggleButtonGroup size="small" value={resultPanelListMode} exclusive={true} onChange={this.onListModeChanged}>
                  <ToggleButton value={'list'} size="small">
                    <ListIcon fontSize="small" />
                  </ToggleButton>
                  <ToggleButton value={'photo'} size="small">
                    <PhotoIcon fontSize="small" />
                  </ToggleButton>
                </StyledToggleButtonGroup>
              </Paper>
            </>
          }
          loading={recountLoading || loading}
          onManualRecount={this.onManualRecount}
        >
          <FilterChips />
        </ResultSettings>
        <Box className={classes.scroll} id="places_results">
          <VirtualizedList
            loading={loading}
            ref={this._autoSizerInfinityLoaderRef}
            rowRenderer={this.rowRenderer}
            loadMoreRows={this.loadMoreRows}
            isRowLoaded={this.isRowLoaded}
            rowCount={this.total}
            rowHeight={ROW_HEIGHT}
            minimumBatchSize={ROWS_PER_FETCH}
          />
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  resultPanelListMode: state.places.resultPanelListMode,

  user: state.app.user,

  // RTK: Queries
  userdataMetadataQuery: irisApi.endpoints.getUserdataMetadata.select()(state),
  // getCategoriesQuery: placesApi.endpoints.getCategories.select()(state),

  // RTK: State
  dataQueryObject: placesSelectors.dataQueryObject(state, true /* enable zoom */),
  showOSM: state.places.showOSM,
  sort: state.places.sort,
  sortDirection: state.places.sortDirection
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // RTK Actions
      setResultPanelListMode: setResultPanelListModeAction,

      // RTK: Queries
      invalidateTags: irisApi.util.invalidateTags,

      // OLD REDUX
      setGlobal: setGlobalAction,
      pushSnackbar: pushSnackbarAction
    },
    dispatch
  );

ResultPanel.propTypes = {
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,

  // PROPS
  toPlace: PropTypes.func.isRequired,
  dataKey: PropTypes.number,

  // OLD REDUX: Data
  user: PropTypes.object,

  // OLD REDUX: Actions
  pushSnackbar: PropTypes.func,
  setGlobal: PropTypes.func,

  onHover: PropTypes.func.isRequired,
  selectedPlace: PropTypes.object,

  onSelect: PropTypes.func.isRequired,
  onHoverUserData: PropTypes.func.isRequired,
  onSelectUserData: PropTypes.func.isRequired,

  // RTK: Queries: Actions
  invalidateTags: PropTypes.func.isRequired,

  // RTK: Queries: Data
  userdataMetadataQuery: PropTypes.object,
  sort: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,

  // RTK: State
  showOSM: PropTypes.bool,
  dataQueryObject: PropTypes.object,
  resultPanelListMode: PropTypes.string,

  // RTK: Actions
  setResultPanelListMode: PropTypes.func.isRequired
};
ResultPanel.defaultProps = {};

const withRedux = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: false });
export default withRedux(withTheme(withStyles(styles)(ResultPanel)));
