import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

// MUI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

// visx
import { Bar, Line } from '@visx/shape';
import { Group } from '@visx/group';

// Ours
import { Waiting } from '@premisedata/iris-components';

const useStyles = makeStyles(() => ({
  detailRow: {
    paddingBottom: '5px',
    borderBottom: 'unset'
  },
  barContainer: {
    paddingTop: 0,
    paddingBottom: '5px',
    borderBottom: 'unset'
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100px'
  }
}));

const ResponseBarChart = ({ loading, data, question, colorsByAnswer }) => {
  const classes = useStyles();
  const theme = useTheme();

  const columns = [
    {
      label: 'Response',
      key: 'response',
      minWidth: 140
    },
    {
      label: '% Estimate (count)',
      key: 'estimate'
    },
    {
      label: '95% Confidence',
      key: 'confidence'
    }
  ];

  const barWithWhiskers = (width, color, lineColor, height, lowerBound, upperBound) => {
    // TO-DO: port to iris-data-components
    return (
      <svg width={'100%'} height={height}>
        <Group>
          <Bar width={width} fill={color} height={height} x={0} rx={4} />
          <Line from={{ x: lowerBound, y: 4 }} to={{ x: lowerBound, y: height - 4 }} stroke={lineColor} strokeWidth={2} />
          <Line from={{ x: lowerBound, y: height / 2 }} to={{ x: upperBound, y: height / 2 }} stroke={lineColor} strokeWidth={2} />
          <Line from={{ x: upperBound, y: 4 }} to={{ x: upperBound, y: height - 4 }} stroke={lineColor} strokeWidth={2} />
        </Group>
      </svg>
    );
  };

  const renderRow = (row) => {
    const estimatedPercentage = `${numeral(row.estimatedPercentage * 100).format('0,0.00')}%`;
    const lowerBound = `${numeral(row.lowerBoundConfPercentage * 100).format('0,0.00')}%`;
    const upperBound = `${numeral(row.upperBoundConfPercentage * 100).format('0,0.00')}%`;

    return (
      <React.Fragment key={row.answer}>
        <TableRow>
          <TableCell className={classes.detailRow}>{row.answer}</TableCell>
          <TableCell className={classes.detailRow}>
            {estimatedPercentage} ({row.count})
          </TableCell>
          <TableCell className={classes.detailRow}>{numeral(row.marginOfError * 100).format('0,0.00')}%</TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={3} className={classes.barContainer}>
            {barWithWhiskers(estimatedPercentage, colorsByAnswer[question][row.answer], theme.palette.text.primary, 20, lowerBound, upperBound)}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <Paper style={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer style={{ maxHeight: '400px' }}>
        <Table stickyHeader={true}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.key} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{data && !loading && data.map(renderRow)}</TableBody>
        </Table>
      </TableContainer>
      {loading && (
        <Box className={classes.messageContainer}>
          <Waiting />
        </Box>
      )}
      {!loading && !data && (
        <Box className={classes.messageContainer}>
          <Typography>No answer data available</Typography>
        </Box>
      )}
    </Paper>
  );
};

ResponseBarChart.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  question: PropTypes.string,
  colorsByAnswer: PropTypes.object
};

export default React.memo(ResponseBarChart);
