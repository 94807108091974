import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

/**
 * This is the base query definition for the Iris platform. All queries injected into this service
 * will use the 'baseURL' as the base url for requests.
 */
const baseQuery = fetchBaseQuery({
  /** This is not compatible with local api overrides as it will force all queries to use this url. For dev
   * this should be fine but we will need to accommodate for changing URLS on a per-product basis.
   */
  baseUrl: process.env.REACT_APP_API_HOST, // Temp value for demographics testing
  prepareHeaders: async (headers) => {
    const firebaseToken = await window.getFirebaseToken?.();
    if (firebaseToken) headers.set('Authorization', `Bearer ${firebaseToken}`);
    headers.set('Content-Type', 'application/json');
    return headers;
  }
});

export const irisApi = createApi({
  tagTypes: ['UserdataMetadata', 'SentimentQuestions'],
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    resendEmailVerification: builder.query({
      query: (uid) => ({
        url: '/admin/v0/users/email-verification',
        method: 'POST',
        body: { uid }
      })
    })
  })
});

export const { resendEmailVerification } = irisApi.endpoints;
