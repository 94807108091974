import React, { Component } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

import ChipPopover from './ChipPopover';

const styles = (/* theme */) => ({});
class ChipPopoverCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      doneDisabled: props.doneDisabled ? props.doneDisabled(props.value) : false
    };

    this.onDone = this.onDone.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value
      });
    }
    if (prevState.value !== this.state.value) {
      this.setState((s) => ({
        doneDisabled: this.props.doneDisabled ? this.props.doneDisabled(s.value) : false
      }));
    }
  }
  onChange(item, checked) {
    const { value } = this.state;
    const { onChange } = this.props;
    const idx = value.indexOf(item);
    let newValue;
    if (checked && idx === -1) {
      newValue = [...value, item];
    } else if (!checked && idx > -1) {
      newValue = [...value.slice(0, idx), ...value.slice(idx + 1)];
    }
    this.setState({ value: newValue });
    onChange?.(newValue);
  }
  onDone() {
    this.props.onCommit?.(this.state.value);
  }
  onClear() {
    this.setState({
      value: []
    });
  }
  onCancel() {
    const { value, clearDone, onCommit } = this.props;
    if (!clearDone) {
      onCommit?.(this.state.value);
    } else {
      this.setState({
        value
      });
    }
  }
  render() {
    const { options, maxWidth, label, clearDone, disabled, className, popoverOrigins } = this.props;
    const { value, doneDisabled } = this.state;

    return (
      <ChipPopover
        className={clsx(className)}
        onClear={this.onClear}
        onDone={this.onDone}
        onCancel={this.onCancel}
        active={'active' in this.props ? this.props.active : this.props.value.length > 0}
        label={label}
        clearDone={clearDone}
        maxWidth={maxWidth}
        disabled={disabled}
        doneDisabled={doneDisabled}
        popoverOrigins={popoverOrigins}
      >
        <FormControl component="fieldset">
          <FormGroup>
            {options.map((item) => (
              <FormControlLabel key={item} control={<Checkbox checked={value.includes(item)} onChange={(e) => this.onChange(item, e.target.checked)} />} label={item} />
            ))}
          </FormGroup>
        </FormControl>
      </ChipPopover>
    );
  }
}
ChipPopoverCheck.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  label: PropTypes.string,
  maxWidth: PropTypes.number,
  options: PropTypes.array,
  value: PropTypes.array,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  clearDone: PropTypes.bool,
  className: PropTypes.string,
  popoverOrigins: PropTypes.object,

  onClear: PropTypes.func,
  onCommit: PropTypes.func,
  doneDisabled: PropTypes.func,
  onChange: PropTypes.func
};

ChipPopoverCheck.defaultProps = {
  options: [],
  value: [],
  clearDone: true
};
export default withTheme(withStyles(styles)(ChipPopoverCheck));
