const KEY = 'premise_id';

export default (immutableMapboxStyleDoc, layer_id, data, rgb, data_index, color_opacity, filter_key, sampleSizeMin, color_scale, outline_key) => {
  // deep copy
  const mapboxStyleDoc = JSON.parse(JSON.stringify(immutableMapboxStyleDoc));

  // PREMISE_ID/HASC CODE based paint instructions:
  const hascExpressions = data.flatMap(({ colorCode, hascCode, ...attrs }) => {

    // determine opacity:
    let opacity = color_opacity;
    if (filter_key && !attrs[filter_key]) opacity = 0.1;
    if (Number.isFinite(sampleSizeMin) && attrs.sampleSize[data_index] < sampleSizeMin) opacity = 0.1;

    return [hascCode, colorCode[data_index] === 2147483647 ? 'rgba(100,100,100,0.25)' : `rgba(${rgb(colorCode[data_index])},${opacity})`];
  });

  // build expression:
  const expression = ['match', ['get', 'premise_id']].concat(hascExpressions);

  // any polygons not matching expressions:
  expression.push('rgba(0,0,0,0)');

  for (const layer of mapboxStyleDoc.layers) {
    if (layer.id === 'outline') {
      if (outline_key != null) {
        layer.paint['line-color'] = ['match', ['get', KEY], outline_key, 'rgba(0,0,255,1)', 'rgba(0,0,0,0)'];
      } else {
        // paint everything transparent
        layer.paint['line-color'] = 'rgba(0,0,0,0)';
      }
    } else if (layer.id === layer_id[2]) {
      layer.paint['fill-color'] = expression;
      layer.paint['fill-outline-color'] = expression.map((d, i) => {
        if (i === expression.length - 1) {
          // last expression is transparent:
          return 'rgba(0,0,0,0)';
        } else {
          if (typeof d === 'string' && d.startsWith('rgb')) {
            return 'rgba(0,0,0,1)';
          } else {
            // if not, its a specifier:
            return d;
          }
        }
      });
    }
  }

  return mapboxStyleDoc;
};
