// essentials
import PropTypes from 'prop-types';
import React from 'react';
import { withTheme } from '@material-ui/core/styles';

// ui/ux
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined';

class HelpTooltip extends React.Component {
  render() {
    const { theme, label, definition, tooltipPlacement, iconStyle } = this.props;

    const iconStyle_default = { fontSize: '1rem', verticalAlign: 'text-top', marginLeft: theme.spacing(0.5) };

    return (
      <React.Fragment>
        {label}
        <Tooltip placement={tooltipPlacement || 'right'} title={definition}>
          <HelpIcon style={iconStyle || iconStyle_default} />
        </Tooltip>
      </React.Fragment>
    );
  }
}

HelpTooltip.propTypes = {
  // Material UI
  theme: PropTypes.object.isRequired,
  label: PropTypes.string,
  definition: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  iconStyle: PropTypes.object
};

HelpTooltip.defaultProps = {};

export default withTheme(HelpTooltip);
