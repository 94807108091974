import { createSlice } from '@reduxjs/toolkit';

// DEFINE INITIAL SLICE STATE
const initialState = {
  mapLevel: 1,
  selectedQuestion: 'including_yourself_how_many_people_live_in_your_household',
  activeQuestions: ['including_yourself_how_many_people_live_in_your_household'],
  selectedCountry: null,
  selectedDetail: null,
  numResponsesRange: [],
  maxResponses: 0,
  sortChip: {
    active: false,
    sortOptionName: null,
    sortDirection: null
  },
  answerFilterChip: {
    active: false,
    filterAnswers: {},
    onlyMostPrevalent: false,
    answerInclusionSetting: 'any'
  },
  qaFilterChip: {
    active: false,
    filterSets: {}
  },
  countryFilterChip: {
    active: false,
    filterCountries: {}
  },
  colorsByAnswer: null,
  displayedRegions: null,
  visibleSummaryRegions: null,
  choroplethAnswer: null,
  legendContent: []
};

// GENERATE STATE SLICE
const demographicsSlice = createSlice({
  name: 'demographics',
  initialState,
  reducers: {
    loadState(state, action) {
      Object.keys(action.payload).forEach((key) => (state[key] = action.payload[key]));
    },
    setMapLevel(state, action) {
      state.mapLevel = action.payload;
    },
    setSelectedQuestion(state, action) {
      state.selectedQuestion = action.payload;
      state.activeQuestions = [state.selectedQuestion, ...Object.keys(state.qaFilterChip?.filterSets ?? {})];

      // Things that are reset by a change in question
      state.choroplethAnswer = null;
    },
    setSelectedCountry(state, action) {
      state.selectedCountry = action.payload;
    },
    setSelectedDetail(state, action) {
      state.selectedDetail = action.payload;
    },
    setNumResponsesRange(state, action) {
      state.numResponsesRange = action.payload;
    },
    setMaxResponses(state, action) {
      state.maxResponses = action.payload;
    },
    setColorsByAnswer(state, action) {
      state.colorsByAnswer = action.payload;
    },
    setDisplayedRegions(state, action) {
      state.displayedRegions = action.payload;
    },
    setVisibleSummaryRegions(state, action) {
      state.visibleSummaryRegions = action.payload;
    },
    setLegendContent(state, action) {
      state.legendContent = action.payload;
    },

    /**
     * WIP: Will document in a bit
     * @param state
     * @param sortOptionName
     * @param sortDirection
     */
    setSortChipSortOption(state, { payload: { sortOptionName, sortDirection } }) {
      // If clicked same option, toggle the option on or off
      if (state.sortChip.sortOptionName === sortOptionName && state.sortChip.sortDirection === sortDirection) {
        state.sortChip.active = false;
        state.sortChip.sortOptionName = null;
        state.sortChip.sortDirection = null;
      } else {
        // If not same, simply set the new option to on, and the old option to off
        state.sortChip.active = true;
        state.sortChip.sortOptionName = sortOptionName;
        state.sortChip.sortDirection = sortDirection;
      }
    },
    setChoroplethAnswer(state, action) {
      state.choroplethAnswer = action.payload;
    },
    clickedQAFilterChipAdd(state, action) {
      state.qaFilterChip.filterSets[action.payload.filterQuestion] = {
        onlyMostPrevalent: action.payload.onlyMostPrevalent,
        answerInclusionSetting: action.payload.answerInclusionSetting,
        filterAnswers: action.payload.filterAnswers
      };
      state.qaFilterChip.active = Object.keys(state.qaFilterChip.filterSets).length > 0;
      state.activeQuestions = [state.selectedQuestion, ...Object.keys(state.qaFilterChip?.filterSets ?? {})];
    },
    clickedQAFilterChipDelete(state, action) {
      delete state.qaFilterChip.filterSets[action.payload.filterQuestion];
      state.qaFilterChip.active = Object.keys(state.qaFilterChip.filterSets).length > 0;
      state.activeQuestions = [state.selectedQuestion, ...Object.keys(state.qaFilterChip?.filterSets ?? {})];
    },
    clickedQAFilterChipSave(state, action) {
      if (action.payload.oldFilterQuestion !== action.payload.filterQuestion) {
        delete state.qaFilterChip.filterSets[action.payload.oldFilterQuestion];
      }
      if (action.payload.filterQuestion && action.payload.filterAnswers) {
        // This should check if any of the options are chosen, not if an
        state.qaFilterChip.filterSets[action.payload.filterQuestion] = {
          answerInclusionSetting: action.payload.answerInclusionSetting,
          onlyMostPrevalent: action.payload.onlyMostPrevalent,
          filterAnswers: action.payload.filterAnswers
        };
      }
      state.activeQuestions = [state.selectedQuestion, ...Object.keys(state.qaFilterChip?.filterSets ?? {})];
    },
    clickedCountryFilterChipDone(state, action) {
      state.countryFilterChip.filterCountries = action.payload;
      state.countryFilterChip.active = Object.keys(state.countryFilterChip.filterCountries).length > 0;
    },
    clickedCountryFilterChipClear(state) {
      state.countryFilterChip.filterCountries = {};
      state.countryFilterChip.active = false;
    },
    clickedAnswerFilterChipDone(state, action) {
      // Need to add more to this but this is a simple first implementation
      state.answerFilterChip.filterAnswers = action.payload.filterAnswers;
      state.answerFilterChip.onlyMostPrevalent = action.payload.onlyMostPrevalent;
      state.answerFilterChip.answerInclusionSetting = action.payload.answerInclusionSetting;
      state.answerFilterChip.active = Object.keys(state.answerFilterChip.filterAnswers).length > 0;
    },
    clickedAnswerFilterChipClear(state) {
      state.answerFilterChip.filterAnswers = {};
      state.answerFilterChip.onlyMostPrevalent = false;
      state.answerFilterChip.answerInclusionSetting = 'any';
      state.answerFilterChip.active = Object.keys(state.answerFilterChip.filterAnswers).length > 0;
    }
  }
});

/**
 * SELECTORS -> Subscribe to these by importing them where they are needed and use redux's 'useSelector
 * ex: 'import { demoSelectors } from '..../demographicsSlice' '
 *     'const minimumResponses = useSelector(demoSelectors.selectMinimumResponses);
 */

export const demoSelectors = {
  // Map
  selectMapLevel: (state) => state.demographics.mapLevel,
  selectColorsByAnswer: (state) => state.demographics.colorsByAnswer,
  selectDisplayedRegions: (state) => state.demographics.displayedRegions,
  selectVisibleSummaryRegions: (state) => state.demographics.visibleSummaryRegions,
  selectChoroplethAnswer: (state) => state.demographics.choroplethAnswer,

  selectVisibleSummaries: (state) => state.demographics.visibleSumaries,
  selectLegendContent: (state) => state.demographics.legendContent,

  // Config Panel
  selectSelectedQuestion: (state) => state.demographics.selectedQuestion,
  selectSelectedCountry: (state) => state.demographics.selectedCountry,

  // Chips

  selectConfigChipActiveByName: (state, name) => state.demographics[name].active, // Name corresponds to chip property name in demographics slice state

  // -- Sort
  selectSortChipSortOptionName: (state) => state.demographics.sortChip.sortOptionName,
  selectSortChipSortDirection: (state) => state.demographics.sortChip.sortDirection,

  // -- QA Filter
  selectQAFilterChipSets: (state) => state.demographics.qaFilterChip.filterSets,

  // -- Answer Filter
  selectAnswerFilterChipAnswers: (state) => state.demographics.answerFilterChip.filterAnswers,
  selectAnswerFilterChipOnlyMostPrevalent: (state) => state.demographics.answerFilterChip.onlyMostPrevalent,
  selectAnswerFilterChipInclusionSetting: (state) => state.demographics.answerFilterChip.answerInclusionSetting,

  // -- Country Filter
  selectCountryFilterChipCountries: (state) => state.demographics.countryFilterChip.filterCountries,
  selectCountryFilterChipActive: (state) => state.demographics.countryFilterChip.active,

  // Result Panel
  selectSelectedDetail: (state) => state.demographics.selectedDetail,
  selectNumResponsesRange: (state) => state.demographics.numResponsesRange,
  selectMaxResponses: (state) => state.demographics.maxResponses,
  getAgeAndGenderQuery: (state) => {
    if (
      // \n
      state.demographics.mapLevel === null ||
      !state.demographics.selectedDetail
    ) {
      return null;
    }
    const { region } = state.demographics.selectedDetail;
    if (!region) return null;
    return {
      level: state.demographics.mapLevel,
      gid: region,
      gid_0: region?.split('.')[0]
    };
  },
  getDetailsQuery: (state) => {
    // ⚠️ we are re-using this function in <View /> to pre-fetch
    // network requests. `state` or `state.demographics` could be
    // null / falsy.
    if (
      // \n
      !state?.demographics?.selectedQuestion ||
      !state?.demographics?.selectedDetail ||
      isNaN(state?.demographics?.mapLevel)
    ) {
      return null;
    }
    const { region, name } = state.demographics.selectedDetail;
    if (!region || !name) {
      return null;
    }

    return {
      question: state.demographics.selectedQuestion,
      level: state.demographics.mapLevel,
      gid: region,
      gid_0: region?.split('.')[0]
    };
  },
  answerDistributionParams: (state) => {
    if (!state.demographics.selectedQuestion || !state.app.mapPolygonBuffered || !state.demographics.choroplethAnswer) {
      return null;
    }
    return {
      bbox: state.app.mapPolygonBuffered,
      level: state.demographics.mapLevel,
      question: state.demographics.selectedQuestion,
      answer: state.demographics.choroplethAnswer
    };
  },
  boundedRegionAnswerInfoParams: (state) => {
    if (!state.demographics.selectedQuestion || !state.app.mapPolygonBuffered) return null;

    return {
      bbox: state.app.mapPolygonBuffered,
      level: state.demographics.mapLevel,
      questions: state.demographics.activeQuestions
    };
  },
  currentQuestionRegionSummariesParams: (state) => {
    if (!state.demographics.selectedQuestion || !state.app.mapPolygonBuffered) return null;
    return {
      bbox: state.app.mapPolygonBuffered,
      level: state.demographics.mapLevel,
      question: state.demographics.selectedQuestion
    };
  }
};

export const dumpState = (store) => {
  // grabs global redux data we need for serialization
  const reduxState = store.getState();

  const keys = [
    'mapLevel',
    'selectedQuestion',
    'activeQuestions',
    'selectedCountry',
    'selectedDetail',
    'numResponsesRange',
    'sortChip',
    'answerFilterChip',
    'qaFilterChip',
    'countryFilterChip',
    'choroplethAnswer',
    'visibleSummaryRegions'
  ];

  return keys.reduce((a, b) => ({ ...a, [b]: reduxState.demographics[b] }), {});
};
export const restoreState = (serializedState) => {
  const state = {
    ...serializedState.productState
  };
  return state;
};

/**
 * ACTIONS -> Use these by importing them where they are needed and use redux's 'useDispatch'
 * ex: 'import { setMapLevel } from '..../demographicsSlice' '
 *     'const dispatch = useDispatch();
 *     'dispatch(setMapLevel(<payload>))
 */
export const demoActions = demographicsSlice.actions;

/**
 * REDUCERS -> Use these by importing them into the store file and placing them in the configureStore's 'reducer'
 * property with the others.
 */
export default demographicsSlice.reducer;
